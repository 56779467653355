import { ColumnTableConfig } from "./column-table-config";

export class ColumnTable {
    code: string;
    description: string;
    type: string;
    cellFn: ((param: any) => any);
    actions: {
        fn: ((param: any) => any),
        icon: string,
        tooltip: string,
        isDisabled: boolean,
        disabledFn?: ((param: any) => boolean),
        isPrivileged?: boolean,
        privilege?: string
    }[];
    cellTooltip: string;
    valueMap: Map<string, string>;
    editable: boolean;
    required: boolean;
    valueMapKeys: Array<string>;
    iconClass: string;
    isIcon: boolean;
    isSmall: boolean;
    isSelfComboBoxDataSource:boolean;
    constructor(code: string, description: string, config?: ColumnTableConfig) {
        let actions = config?.actions || { fn: (() => { }), icon: "", tooltip: "", isDisabled: false, disabledFn: (() => { return false; }), isPrivileged: false, privilege: "" };
        this.code = code;
        this.description = description;
        this.type = config?.type || "text";
        this.actions = !("length" in actions) ? [actions] : actions;
        this.actions.forEach(f => {
            f.disabledFn = f.disabledFn || (() => { return f.isDisabled; })
        })
        this.cellFn = config?.cellFn || (() => { });
        this.cellTooltip = config?.cellTooltip || "";
        this.valueMap = config?.valueMap || new Map<string, string>();
        this.editable = config?.editable || false;
        this.required = config?.required || false;
        this.valueMapKeys = Array.from(this.valueMap.keys()) || [];
        this.iconClass = config?.iconClass || "";
        this.isIcon = config?.isIcon || false;
        this.isSmall = config?.isSmall || false;
        this.isSelfComboBoxDataSource = config?.isSelfComboBoxDataSource || false;
    }
}
