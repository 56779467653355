<div class="modal-content">
    <form #login="ngForm" (ngSubmit)="login.valid && confirm()" novalidate class="needs-validation">
    <div class="modal-header">
      <h4 class="modal-title">Inicio de Sesión Supervisor</h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="icon-user"></i></span>
            </div>
            <input 
            name="username"
            type="text" 
            class="form-control" 
            placeholder="Usuario" 
            autocomplete="username" 
            [(ngModel)]="username"
            #user="ngModel"
            required
            [class.is-invalid]="(user.touched || user.dirty) && user.invalid"
            >
            <div class="invalid-feedback ">
              Seleccione un usuario
            </div>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="icon-lock"></i></span>
            </div>
            <input 
            name="password"
            type="password" 
            class="form-control" 
            placeholder="Contraseña" 
            autocomplete="current-password" 
            required 
            [(ngModel)]="password" 
            #pass="ngModel"
            [class.is-invalid]="(pass.touched || pass.dirty) && pass.invalid"
            >
            <div class="invalid-feedback ">
              Ingrese una contraseña
            </div>
          </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
      <button type="submit" class="btn btn-primary">Login</button>
    </div>
</form>
  </div><!-- /.modal-content -->