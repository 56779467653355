import { Roles } from './models/role';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from './@core/constants';
import { AuthGuard } from './@core/guards/auth.guard';
import { AppComponent } from './app.component';
import { LayoutComponent } from './containers';
import { ConfigurationsComponent } from './views/configurations/configurations.component';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ChangeForgetPasswordComponent } from './views/change-forget-password/change-forget-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sales',
    pathMatch: 'full',
  },

  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page',
      id: 'LoginComponent_login'
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      title: 'Logout Page',
      id: 'LogoutComponent_logout'
    },
  },
  {
    path: 'config',
    component: ConfigurationsComponent,
    data: {
      title: 'Configuración',
      id: 'ConfigurationsComponent_config'
    }
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent,
    data: {
      title: 'Cambiar contraseña',
      id: 'ChangePasswordComponent_changepassword'
    }
  },
  {
    path: 'changepassword/:code',
    component: ChangePasswordComponent,
    data: {
      title: 'Cambiar contraseña',
      id: 'ChangePasswordComponent_changepassword'
    }
  },
  {
    path: 'changeforgetpassword',
    component: ChangeForgetPasswordComponent,
    data: {
      title: 'Cambiar contraseña olvidada',
      id: "ChangeForgetPasswordComponent"
    }
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      title: '',
    },
    children: [
      {
        path: 'sales',
        loadChildren: () =>
          import('./views/sales/sales.module').then((m) => m.SalesModule),
        data: {
          title: 'Ventas',
          id: 'SalesModule_sales'
        }
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./views/orders/orders.module').then((m) => m.OrdersModule),
        data: {
          title: 'Ordenes',
          id: 'OrdersModule_orders'
        },
      },
      {
        path: 'creditRequestTracker',
        loadChildren: () =>
          import('./views/credit-request/credit-request.module').then(
            (m) => m.CreditRequestModule
          ),
        data: {
          title: 'Solicitudes de credito',
          id: 'CreditRequestModule_creditRequestTracker'
        },
      },
      {
        path: 'customer',
        loadChildren: () =>
          import('./views/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
        data: {
          title: 'Clientes',
          id: 'CustomersModule_customer'
        },
      },
      {
        path: 'backOfficeOrders',
        loadChildren: () => import('./views/back-office-orders/back-office-orders.module').then(m => m.BackOfficeOrdersModule),
        data: {
          title: 'Cola de trabajo',
          id: 'BackOfficeOrdersModule_backOfficeOrders'
        }
      },
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule),
        data: {
          title: 'Productos',
          id: 'ProductsModule_products'
        }
      },
      {
        path: 'registerclosing',
        loadChildren: () => import('./views/register-closing/register-closing.module').then(m => m.RegisterClosingModule),
        data: {
          title: 'Cierre de cajas',
          id: 'RegisterClosingModule_registerclosing'
        }
      },
      // {
      //   path: 'changepassword',
      //   component: ChangePasswordComponent,
      //   data: {
      //     title: 'Cambiar contraseña',
      //     id: 'ChangePasswordComponent_changepassword'
      //   }
      // },
      {
        path: 'equipmentRegistrationTicket',
        loadChildren: () =>
          import('./views/equipment-registration-ticket/equipment-registration-ticket.module').then((m) => m.EquipmentRegistrationTicketModule),
        data: {
          title: 'Gestión de Matrículas',
          id: 'EquipmentRegistrationTicketModule_equipmentRegistrationTicket'
        }
      },
      {
        path: 'biometry',
        loadChildren: () => import('./views/biometry/biometry.module').then((m) => m.BiometryModule),
        data: {
          title: 'Biometría',
          id: "BiometryModule_biometry"
        }
      }
    ],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
