<div class="row justify-content-between">
  <div class="col-md col-sm-3">
    <div class="card">
      <div class="card-body p-0 clearfix">
        <i class="fa fa-money bg-primary p-4 font-2xl mr-3 float-left"></i>
        <div class="h6 text-primary mb-0 pt-3">
          {{totals.grossTotal | currency: 'HNL ':'symbol' : '1.2-2'}}</div>
        <div class="text-muted text-uppercase font-weight-bold font-xs">Sub-Total</div>
      </div>
    </div>
  </div>
  <div class="col-md col-sm-3">
    <div class="card">
      <div class="card-body p-0 clearfix">
        <i class="fa fa-credit-card bg-primary p-4 font-2xl mr-3 float-left"></i>
        <div class="h6 text-primary mb-0 pt-3">
          {{totals.discountTotal | currency: 'HNL ':'symbol' : '1.2-2'}}</div>
        <div class="text-muted text-uppercase font-weight-bold font-xs">Descuento</div>
      </div>
    </div>
  </div>
  <div class="col-md col-sm-3">
    <div class="card">
      <div class="card-body p-0 clearfix">
        <i class="fa fa-info bg-warning p-4 font-2xl mr-3 float-left"></i>
        <div class="h6 text-primary mb-0 pt-3">
          {{totals.taxTotal | currency: 'HNL ':'symbol' : '1.2-2'}}</div>
        <div class="text-muted text-uppercase font-weight-bold font-xs">Impuesto</div>
      </div>
    </div>
  </div>
  <div class="col-md col-sm-3">
    <div class="card">
      <div class="card-body p-0 clearfix">
        <i class="fa fa-shopping-cart bg-success p-4 font-2xl mr-3 float-left"></i>
        <div class="h6 text-primary mb-0 pt-3">
          {{totals.netTotal | currency: 'HNL ':'symbol' : '1.2-2'}}</div>
        <div class="text-muted text-uppercase font-weight-bold font-xs">Total</div>
      </div>
    </div>
  </div>
</div>
