
  <div class="row mb-4">
    <div class="col-12 col-md-6">
      <div class="btn-group mb-3">
        <button id="btnCaptureFingerPrint" class="btn btn-primary" (click)="capture()"><i
            class="cil-fingerprint"></i>&nbsp;Capturar</button>
        <button id="btnValidate FingerPrint" class="btn btn-primary" (click)="sendFingerPrint()"><i
            class="fa fa-check"></i>&nbsp;Validar
          huella
        </button>
      </div>
    </div>
    <div class="col-8 col-md-4">
      <h5>Resultado</h5>
      <h5 [hidden]="!result.valid || !result.errorMessage" class="bg-success"><i
          class="fa fa-check "></i>Proceda a ingresar facturación ó solicitud de crédito</h5>
      <h5 [hidden]="result.valid || !result.errorMessage" class="bg-danger"> Revisión con el
        área de crédito</h5>
      <h5 [hidden]="result.errorMessage" class="bg-warning">Pendiente</h5>
    </div>
  </div>
  <div class="container-fluid row">
    <div class="col-10 col-md-8 mb-4">
      <h5>Seleccione la mano</h5>
      <div class="row">
        <div class="md-radio md-radio-inline d-flex mr-2">
          <input name="hands" type="radio" id="hand-left" class="radioButtonFingerPrint" value="LEFT"
            (click)="switchHand($event)" />
          <label for="hand-left"><img src="assets\hands\hand-left.png" width="90px" class="cursor" />Izquierda</label>
        </div>
        <div class="md-radio md-radio-inline d-flex">
          <input name="hands" type="radio" id="hand-right" class="radioButtonFingerPrint" value="RIGHT"
            (click)="switchHand($event)" />
          <label for="hand-right"><img src="assets\hands\hand-right.png" width="90px" class="cursor" />Derecha</label>
        </div>
      </div>
      <br /><br /><br />
      <div class="row" *ngIf="biometryHand==_biometryHand.LEFT">
        <div class="col-8 col-md-12 mb-6">
          <h5>Seleccione el dedo</h5>
          <div class="row">
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-left-finger-thumb" class="radioButtonFingerPrint"
                value="THUMB" (click)="switchFinger($event)" />
              <label for="hand-left-finger-thumb"><img src="assets\hands\hand-left-finger-thumb.png" width="90px"
                  class="cursor" /> Pulgar</label>
            </div>
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-left-finger-index" class="radioButtonFingerPrint"
                value="INDEX" (click)="switchFinger($event)" />
              <label for="hand-left-finger-index"><img src="assets\hands\hand-left-finger-index.png" width="90px"
                  class="cursor" />Indice</label>
            </div>
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-left-finger-middle" class="radioButtonFingerPrint"
                value="MIDDLE" (click)="switchFinger($event)" />
              <label for="hand-left-finger-middle"><img src="assets\hands\hand-left-finger-middle.png" width="90px"
                  class="cursor" />Medio</label>
            </div>
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-left-finger-ring" class="radioButtonFingerPrint" value="RING"
                (click)="switchFinger($event)" />
              <label for="hand-left-finger-ring"><img src="assets\hands\hand-left-finger-ring.png" width="90px"
                  class="cursor" />Anular</label>
            </div>
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-left-finger-little" class="radioButtonFingerPrint"
                value="LITTLE" (click)="switchFinger($event)" />
              <label for="hand-left-finger-little"><img src="assets\hands\hand-left-finger-little.png" width="90px"
                  class="cursor" />Meñique</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="biometryHand==_biometryHand.RIGHT">
        <div class="col-8 col-md-12 mb-6">

          <h5>Seleccione el dedo</h5>
          <div class="row">
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-right-finger-thumb" class="radioButtonFingerPrint"
                value="THUMB" (click)="switchFinger($event)" />
              <label for="hand-right-finger-thumb"><img src="assets\hands\hand-right-finger-thumb.png" width="90px"
                  class="cursor" /> Pulgar</label>
            </div>
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-right-finger-index" class="radioButtonFingerPrint"
                value="INDEX" (click)="switchFinger($event)" />
              <label for="hand-right-finger-index"><img src="assets\hands\hand-right-finger-index.png" width="90px"
                  class="cursor" />Indice</label>
            </div>
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-right-finger-middle" class="radioButtonFingerPrint"
                value="MIDDLE" (click)="switchFinger($event)" />
              <label for="hand-right-finger-middle"><img src="assets\hands\hand-right-finger-middle.png" width="90px"
                  class="cursor" />Medio</label>
            </div>
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-right-finger-ring" class="radioButtonFingerPrint" value="RING"
                (click)="switchFinger($event)" />
              <label for="hand-right-finger-ring"><img src="assets\hands\hand-right-finger-ring.png" width="90px"
                  class="cursor" />Anular</label>
            </div>
            <div class="md-radio md-radio-inline col-md-2 d-flex">
              <input name="fingers" type="radio" id="hand-right-finger-little" class="radioButtonFingerPrint"
                value="LITTLE" (click)="switchFinger($event)" />
              <label for="hand-right-finger-little"><img src="assets\hands\hand-right-finger-little.png" width="90px"
                  class="cursor" />Meñique</label>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-8 col-md-2">
      <h5>Huella</h5>
      <img class="border-style-container" [src]="imageFingerPrint" width="200" height="300" />
    </div>
  </div>

