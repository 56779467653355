<div class="animated fadeIn rounded">
  <div class="card bg-white">
    <div class="card-body">
      <pos-stepper #cdkStepper>
        <cdk-step label="Aplicación" [optional]="false">
          <app-pos-credit-installments-application 
            [minimun]="minimun" 
            [maximun]="maximun" 
            [customer]="customer"
            (onSuccessfulApplication)="onSuccessfulApplication($event)">
          </app-pos-credit-installments-application>
        </cdk-step>
        <cdk-step label="Cálculo" [optional]="false">
          <credit-condition-step [hideTermsAndDates]="true" [order]="generatedOrder" [customerCreditConditions]="customerCreditConditions" [fromCustomer]="true"></credit-condition-step>
        </cdk-step>
      </pos-stepper>
    </div>
  </div>
</div>
