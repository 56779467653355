import { CustomerUpdateJobInfoRequest } from './../models/requests/customer-update-jobInfo-request';
import { ReadCustomerParams } from './../models/params/read-customers-params';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerType, Personality } from '../@core/constants';
import { Customer } from '../models/customer';
import { Filter } from '../models/filter';
import { CustomerFilter } from '../models/filters/customer-filter';
import { EmployeeFilter } from '../models/filters/employee-filter';
import { Paging } from '../models/page';
import { CreateCustomerRequestModel } from '../models/request/createCustomerRequestModel';
import { UpdateCustomerRequestModel } from '../models/request/updateCustomerRequestModel';
import { ResponseModel } from '../models/response-model';
import { CustomerCreateResponseModel } from '../models/response/customerCreateResponseModel';
import { CustomerTableModel } from '../models/table-models/customer-table';
import { CustomerUpdate } from '../models/request/CustomerUpdate';
import { ConfigurationService } from './configuration.service';
import { CustomerUpdateResponse } from '../models/response/CustomerUpdateResponse';
import { VerifyCustomerCheckResponse } from '../models/response/VerifyCustomerCheckResponse';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private url = `${environment.url}/customers`;
  private ilpUrl = `${environment.url}/ilpCustomers`;

  constructor(private http: HttpClient, private configService: ConfigurationService) { }

  readCustomers(
    filter: Filter<CustomerFilter>
  ): Observable<ResponseModel<Paging<Array<CustomerTableModel>>>> {
    let params = new HttpParams();
    params = params
      .set('page', filter.page.toString() || '0')
      .set('pageSize', filter.pageSize.toString() || '20');

    params = filter.value.code ? params.set('code', filter.value.code) : params;
    params = filter.value.fiscalId
      ? params.set('fiscalId', filter.value.fiscalId)
      : params;
    params = filter.value.text ? params.set('text', filter.value.text) : params;
    params = filter.value.customerType
      ? params.set('customerType', filter.value.customerType)
      : params;
    params = filter.value.personality
      ? params.set('personality', filter.value.personality)
      : params;

    return this.http.get<ResponseModel<Paging<Array<CustomerTableModel>>>>(
      `${this.url}/franchised`,
      { params }
    );
  }

  readEmployees(
    filter: Filter<EmployeeFilter>
  ): Observable<ResponseModel<Paging<Array<CustomerTableModel>>>> {
    let params = new HttpParams();
    params = params
      .set('page', filter.page.toString() || '0')
      .set('pageSize', filter.pageSize.toString() || '20')
      .set('customerType', CustomerType.EMPLOYEE);

    params = filter.value.code ? params.set('code', filter.value.code) : params;
    params = filter.value.text ? params.set('text', filter.value.text) : params;
    return this.http.get<ResponseModel<Paging<Array<CustomerTableModel>>>>(
      this.url,
      { params }
    );
  }

  readCustomerByCode(
    customerCode: string,
    params?: ReadCustomerParams
  ): Observable<Customer> {
    if (!customerCode) {
      return new Observable((s) => {
      });
    }
    return this.http
      .get<ResponseModel<Customer>>(`${this.url}/${customerCode}`, { params })
      .pipe(map((response: ResponseModel<Customer>) => response.response));
  }

  validateCustomerEmail(customerCode: string) {
    return this.http.get<ResponseModel<Boolean>>(`${this.url}/hasEmail/${customerCode}`);
  }

  createCustomer(
    request: CreateCustomerRequestModel
  ): Observable<ResponseModel<CustomerCreateResponseModel>> {
    return this.http.post<ResponseModel<CustomerCreateResponseModel>>(
      this.url,
      request
    );
  }

  updateCustomer(
    code: string,
    request: CustomerUpdate,
    files: Map<string, FileList>
  ): Observable<ResponseModel<CustomerUpdateResponse>> {
    const path = `${this.ilpUrl}/${code}`;

    const formData = new FormData();
    formData.append('body', JSON.stringify(request));
    formData.append('salesOrgCode', this.configService.readSelectedBranchAndSalesOrg().salesOrg.code);
    formData.append('branchCode', this.configService.readSelectedBranchAndSalesOrg().branch.code);
    let i = 0;
    files.forEach((file, index) => {
      formData.append(`file${index.split("-")[0]}`, file[0] as Blob, file[0].name);
      formData.append(`file${index.split("-")[0]}Type`, index.split("-")[1]);
      i++;
    });

    return this.http.put<ResponseModel<CustomerUpdateResponse>>(
      path,
      formData
    );
  }

  updateJobInfoCustomer(
    code: string,
    request: CustomerUpdateJobInfoRequest
  ): Observable<ResponseModel<CustomerTableModel>> {
    const url = `${this.url}/${code}`;
    return this.http.put<ResponseModel<CustomerTableModel>>(url, request);
  }

  updateRtnFiscalId(customerCode: string, rtnFiscalId: string) {
    let params = new HttpParams();
    params = params.set("fiscalId", rtnFiscalId).set("replicate", "true");

    return this.http.put<ResponseModel<Customer>>(`${this.url}/${customerCode}/updateFiscalId`, {}, { params });
  }

  readCustomerFranchised(customerCode: string): Observable<ResponseModel<CustomerTableModel>> {
    return this.http.get<ResponseModel<CustomerTableModel>>(`${this.url}/${customerCode}/franchised`);
  }

  customerNeedUpdate(customerCode: String, branchCode: String): Observable<ResponseModel<{ customerNeedUpdate: boolean; message: string; verifyData: VerifyCustomerCheckResponse }>> {

    let params = new HttpParams();
    params = params
      .set('branchCode', branchCode.toString());

    return this.http.get<ResponseModel<{ customerNeedUpdate: boolean; message: string; verifyData: VerifyCustomerCheckResponse }>>(`${this.url}/${customerCode}/needUpdate`, { params });
  }
}
