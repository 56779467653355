import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosOrderObservationsService } from 'src/app/@core/services/pos-order-observations.service';
import { PosOrderObservationsComponent } from './pos-order-observations.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [PosOrderObservationsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  providers: [PosOrderObservationsService],
  exports: [PosOrderObservationsComponent]
})
export class PosOrderObservationsModule { }
