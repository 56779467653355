<div class="app-body animated fadeIn">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="card">
        <div class="card-header bg-primary">
          <i class="fa fa-street-view"></i> Cambiar contraseña
        </div>
        <div class="card-body">
          <div *ngIf="!sendPassword">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="icon-user"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Correo electronico" autocomplete="email"
                [(ngModel)]="email" />
            </div>
            <pos-captcha [(ngModel)]="captcha" name="captcha" (getCaptchaId)="getCaptchaId($event)"></pos-captcha>
            <div>
              <button class="btn btn-primary" (click)="sendEmail()">Enviar código</button>
            </div>
          </div>
          <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" novalidate>
            <div *ngIf="sendPassword">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-lock"></i></span>
                </div>
                <input formControlName="emailCodeForgetPassword" type="emailCodeForgetPassword" class="form-control"
                  placeholder="Codigo de seguridad" autocomplete="emailCodeForgetPassword"
                  [class.is-invalid]="(passwordForm.get('emailCodeForgetPassword')?.touched || passwordForm.get('emailCodeForgetPassword')?.dirty) && passwordForm.get('emailCodeForgetPassword')?.invalid">
                <div class="invalid-feedback" *ngIf="passwordForm.get('emailCodeForgetPassword')?.errors?.required">
                  Este campo es requerido
                </div>
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-user"></i></span>
                </div>
                <input formControlName="username" type="text" class="form-control" placeholder="Usuario"
                  autocomplete="username"
                  [class.is-invalid]="(passwordForm.get('username')?.touched || passwordForm.get('username')?.dirty) && passwordForm.get('username')?.invalid">
                <div class="invalid-feedback" *ngIf="passwordForm.get('username')?.errors?.required">
                  Este campo es requerido
                </div>
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-lock"></i></span>
                </div>
                <input formControlName="new" type="password" class="form-control" placeholder="Nueva contraseña"
                  autocomplete="new-password"
                  [class.is-invalid]="(passwordForm.get('new')?.touched || passwordForm.get('new')?.dirty) && passwordForm.get('new')?.invalid">
                <div class="invalid-feedback" *ngIf="passwordForm.get('new')?.errors?.required">
                  Este campo es requerido
                </div>
              </div>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-lock"></i></span>
                </div>
                <input formControlName="confirmation" type="password" class="form-control"
                  placeholder="Confirmar contraseña" autocomplete="new-password"
                  [class.is-invalid]="(passwordForm.get('confirmation')?.touched || passwordForm.get('confirmation')?.dirty) && passwordForm.errors?.mismatch">
                <div class="invalid-feedback">
                  Las contraseñas no coinciden
                </div>
              </div>
              <div class="form-actions">
                <button type="submit" class="btn btn-primary" [disabled]="!passwordForm.valid">Cambiar
                  contraseña</button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </main>
</div>