import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProductCart } from 'src/app/models/product-cart';
import { Stock } from 'src/app/models/stock';
import { PosSerialModalComponent } from 'src/app/shared/pos-serial-modal/pos-serial-modal.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosSerialModalService {
  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  show(product: ProductCart, confirmAction: (serial: Stock, product: ProductCart) => any): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.modalRef = this.modalService.show(PosSerialModalComponent, {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        animated: true
      })

      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY}`)
      this.modalRef.content.product = product;
      this.modalRef.content.confirmAction = confirmAction;
      let result = await this.modalRef.content.load();
      if (!result) {
        setTimeout(() => { this.hide() }, 100)

        reject("Producto no tiene series disponibles");
      }
      if (product.serial.trim()) {
        await this.modalRef.content.loadEquipmentFromSerialNumber(product.serial)
      }
      resolve("");
    })
  }

  hide() {
    this.modalRef?.hide();
  }
}
