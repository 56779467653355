import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDialogType } from '../@core/constants';
import { PosOrderRejectModalComponent } from '../shared/pos-order-reject-modal/pos-order-reject-modal.component';

@Injectable({
  providedIn: 'root'
})
export class PosOrderRejectModalService {

  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  show(title: string, message: string, orderCode: string, dialogType: ModalDialogType, yesFn: (() => any), noFn?: (() => any)): Promise<any> {

    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(PosOrderRejectModalComponent)
      this.modalRef.setClass(`modal-dialog ${dialogType}`)
      this.modalRef.content.title = title;
      this.modalRef.content.message = message;
      this.modalRef.content.orderCode = orderCode;
      this.modalRef.content.yesFn = yesFn;
      this.modalRef.content.noFn = noFn || (() => { });
      resolve(true);
    })
  }
}
