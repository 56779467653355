

<accordion [isAnimated]="true">
  <accordion-group [isOpen]="true">
    <span accordion-heading>
      <i class='cil-cart'></i> Factura
    </span>
    <pos-totals [totals]="receipt.totals"></pos-totals>
<div class="row">
  <div class="col-12">
    <pos-table tableFormId="productsCartTable" [data]="productsCart" [headers]="getProductCartHeaders()" [filters]="[]"
      [itemsPerPage]="10" [pagination]="false" [disableHighlight]="true">
    </pos-table>
  </div>
</div>
  </accordion-group>
<accordion-group [isOpen]="true">
  <span accordion-heading>
    <i class='fa fa-credit-card'></i> Pagos
  </span>
  <div class="row">
    <div class="col-12">
      <app-pos-payment-method [disablePaymentButton]="true" [totalAmount]="receipt.totals.netTotal" [customerCode]="receipt.customerCode" (doPayment)="getPayment($event)" id="receiptId"></app-pos-payment-method>
    </div>
  </div>
</accordion-group>
</accordion>