import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderType, RepairshopOrderSubType } from 'src/app/@core/constants';
import { ColumnTable } from 'src/app/models/column-table';
import { Filter } from 'src/app/models/filter';
import { FilterTable } from 'src/app/models/filter-table';
import { CreditNotesFilter } from 'src/app/models/filters/credit-notes-filter';
import { Invoice } from 'src/app/models/invoice';
import { Paging } from 'src/app/models/page';
import { CreditNoteTableModel } from 'src/app/models/table-models/credit-note-table';
import { InvoiceService } from 'src/app/services/invoice.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { CreditNote } from '../../../../models/credit-note';

@Component({
  selector: 'app-customer-credit-notes',
  templateUrl: './customer-credit-notes.component.html',
  styleUrls: ['./customer-credit-notes.component.sass']
})
export class CustomerCreditNotesComponent implements OnInit {

  public creditNotes: Paging<Array<CreditNoteTableModel>> = new Paging([]);
  private customerCode = '';
  private readonly CURRENCY_CODE = 'HNL';

  constructor(
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    this.customerCode = this.route.snapshot.params.id;
    this.getCreditRequestInProcess({ page: 0, pageSize: 10, value: {} });
  }

  async getCreditRequestInProcess(params: Filter<CreditNotesFilter>): Promise<void> {
    const filters = params;
    filters.value.customerCode = this.customerCode;
    filters.value.orderType = OrderType.RETURN;
    let creditNotesResponse = (await
      this.invoiceService.readCreditNotes(filters).toPromise())
      .response;

    this.creditNotes = new Paging(creditNotesResponse.content.map(note => {
      let response = new CreditNoteTableModel();
      response.fullNumber = note.fullNumber || "";;
      response.usableAsPayment = note.usableAsPayment;
      response.sellerName = note.sellerName;
      response.branchName = note.branchName
      response.timeStamp = note.timeStamp
      response.netTotal = note.totals.netTotal;
      response.amountAvailable = note.usableAsPayment ? note.amountUsedAsPayment : 0;
      response.amountUsed = response.netTotal - response.amountAvailable;
      return response;
    }));
  }

  getCreditNotesHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('fullNumber', 'N° Nota de crédito'),
      new ColumnTable('usableAsPayment', 'Usable para Pago', { type: "switch" }),
      new ColumnTable('sellerName', 'Vendedor'),
      new ColumnTable('branchName', 'Sucursal'),
      new ColumnTable('timeStamp', 'Fecha de Creación', { type: "date" }),
      new ColumnTable('netTotal', 'Monto', { type: "currency" }),
      new ColumnTable('amountUsed', 'Monto usado para pago', { type: "currency" }),
      new ColumnTable('amountAvailable', 'Monto disponible para pago', { type: "currency" }),
    ];
  }

  getCreditNotesFilters(): Array<FilterTable> {
    return [
      new FilterTable('fullNumber', 'N° Nota de crédito'),
      new FilterTable('usableAsPayment', 'Usable para Pago', {
        type: 'switch'

      }),
      new FilterTable('repairshopOrderSubType', 'Tipo', {
        type: 'combobox',
        comboBoxFilterValues: this.getRepairShopOrderSubTypeMap()
      }),
      new FilterTable('sellerName', 'Vendedor'),
      new FilterTable('startDate', 'Fecha desde', { type: 'date' }),
      new FilterTable('endDate', 'Fecha hasta', { type: 'date' }),
    ];
  }

  getRepairShopOrderSubTypeMap(): Map<string, string> {
    const result = new Map<string, string>();
    result.set(RepairshopOrderSubType.UNSPECIFIED, 'SIN ESPECIFICAR');
    result.set(RepairshopOrderSubType.TIENDA, 'TIENDA');
    result.set(RepairshopOrderSubType.TALLER, 'TALLER');

    return result;
  }



  getCreditNotesData(params: Filter<CreditNotesFilter>): void {
    this.getCreditRequestInProcess(params);
  }

  copyToClipboard(idElement: any): void {
    try {
      const el = document.getElementById(idElement);
      el?.setAttribute('contenteditable', 'true');
      el?.focus();
      document.execCommand('selectAll');
      document.execCommand('copy');
      el?.setAttribute('contenteditable', 'false');
      el?.blur();
    } catch (e) {
      console.log(e);
    }
  }
}
