export class Ip800 {
    code: string;
    customerCode: string;
    creationDate: Date;
    city: string;
    department: string;
    municipality: string;
    neighbourhoodColonyOrvillage: string;
    streetOrAvenue: string;
    blockNumber: string;
    houseNumber: string;
    cellularPhone: string;
    otherPhone: string;
    emailAddress: string;

    constructor() {
        this.code = "";
        this.customerCode = "";
        this.creationDate = new Date();
        this.city = "";
        this.department = "";
        this.municipality = "";
        this.neighbourhoodColonyOrvillage = "";
        this.streetOrAvenue = "";
        this.blockNumber = "";
        this.houseNumber = "";
        this.cellularPhone = "";
        this.otherPhone = "";
        this.emailAddress = "";
    }
}