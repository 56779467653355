import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@coreui/icons-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PosCreditInstallmentsModule } from 'src/app/shared/pos-credit-installments/pos-credit-installments.module';
import { PosStepperModule } from 'src/app/shared/pos-stepper/pos-stepper.module';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { PosCustomerTableModalModule } from './../../shared/pos-customer-table-modal/pos-customer-table-modal.module';
import { PosCustomerTableModule } from './../../shared/pos-customer-table/pos-customer-table.module';
import * as fromComponents from './components';
import { CustomersRoutingModule } from './customers-routing.module';
import { AdvanceComponent } from './components/advance/advance.component';
import { CustomerAdvancesComponent } from './components/customer-advances/customer-advances.component';
import { PosPaymentMethodModule } from 'src/app/shared/pos-payment-method/pos-payment-method.module';
import { HasPrivilegeModule } from 'src/app/@core/directives/has-privilege/has-privilege.module';
import { HasAccessModule } from 'src/app/@core/directives/has-access/has-access.module';

@NgModule({
  declarations: [...fromComponents.components, AdvanceComponent, CustomerAdvancesComponent],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    PosCustomerTableModule,
    AccordionModule.forRoot(),
    ReactiveFormsModule,
    PosCustomerTableModalModule,
    PosStepperModule,
    CdkStepperModule,
    IconModule,
    NgSelectModule,
    PosCreditInstallmentsModule,
    PosTableModule,
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PosPaymentMethodModule,
    HasPrivilegeModule,
    HasAccessModule
  ],
  exports: [fromComponents.components]
})
export class CustomersModule { }
