<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Imprimir Documentos</h4>
    <button type="button" class="close" (click)="cancel()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #form="ngForm" class="form" novalidate>

      <div class="form-group" *ngFor="let quotation of quotations">
        <div *ngIf="processedOrder.order.orderType === 'QUOTATION'">
          <ui-switch size="small" [(ngModel)]="quotation.print" name="QUOTATION"></ui-switch>
          <span class="m-4">Cotización: {{processedOrder.order.code}}</span>
        </div>
      </div>
      <div class="form-group">
        <ui-switch size="small" [(ngModel)]="fiscalIdFile.print" name="fiscalIdFile"></ui-switch>
        <span class="m-4">Identidad</span>
      </div>
      <div class="form-group">
        <ui-switch size="small" [(ngModel)]="rtnFiscalIdFile.print" name="rtnFiscalIdFile"></ui-switch>
        <span class="m-4">RTN</span>
      </div>

      <div class="form-group" [hidden]="processedOrder.order.status == 'FINAL'
        || (processedOrder.order.orderCondition != UNTIL_30_DAYS_FRANCHISED && processedOrder.order.orderCondition != MORE_THAN_30_DAYS_FRANCHISED)">
        <ui-switch size="small" [(ngModel)]="purchaseOrder.print" name="purchaseOrder"></ui-switch>
        <span class="m-4">Generar reporte orden de compra:</span>
      </div>

      <div class="form-group">
        <ui-switch size="small" [(ngModel)]="proofOfPaymentFile.print" name="proofOfPayment"></ui-switch>
        <span class="m-4">Comprobante de pago</span>
      </div>

      <div class="form-group" [hidden]="processedOrder.order.orderCondition != UNTIL_30_DAYS_FRANCHISED
        && processedOrder.order.orderCondition != MORE_THAN_30_DAYS_FRANCHISED">
        <ui-switch size="small" [(ngModel)]="purchaseOrderFile.print" name="purchaseOrderFile"></ui-switch>
        <span class="m-4">Orden de compra</span>
      </div>

      <!-- <div class="form-group" *ngIf="!canShowDigitalSignatureDocs">
        <div *ngFor="let invoice of invoices">
          <ui-switch size="small" [(ngModel)]="invoice.print" name="ïnvoice{{invoice.code}}"></ui-switch>
          <span class="m-4">Factura: {{invoice.fullNumber}}</span>
        </div>
      </div> -->

      <div class="form-group" *ngIf="!canShowDigitalSignatureDocs">
        <div class="form-group" *ngFor="let invoice of invoices; let i = index">
          <ui-switch size="small" [(ngModel)]="invoice.print" name="ïnvoice{{invoice.code}}"></ui-switch>
          <span class="m-4">Factura {{invoice.printType === 'ORIGINAL' ? 'Original' : 'Copia'}}: {{invoice.fullNumber}}</span>
        </div>
      </div>
      <!-- <div class="form-group" *ngIf="!canShowDigitalSignatureDocs">
        <div *ngFor="let invoice of invoices">

        </div>
      </div> -->

      <div *ngIf="!canShowDigitalSignatureDocs && !processedOrder.order.isThirtyDays">
        <div class="form-group" *ngFor="let loanr of loan">
          <div>
            <ui-switch size="small" [(ngModel)]="loanr.print" name="loan{{loanr.code}}"></ui-switch>
            <span class="m-4">Contrato: {{loanr.code}}</span>
          </div>
        </div>

        <div class="form-group">
          <div *ngFor="let plan of paymentPlan">
            <ui-switch size="small" [(ngModel)]="plan.print" name="plan{{plan.code}}"></ui-switch>
            <span class="m-4">Plan de pago: {{plan.code}}</span>
          </div>
        </div>


      </div>

      <div *ngIf="!canShowDigitalSignatureDocs">
        <div *ngFor="let debit of debitNote">
          <div class="form-group">
            <ui-switch size="small" [(ngModel)]="debit.print" name="receipt{{debit.code}}"></ui-switch><span
              class="m-4">Nota de cargo: {{debit.code}}</span>
          </div>
        </div>
      </div>


      <div *ngFor="let receipt of receipts">
        <div class="form-group">
          <ui-switch size="small" [(ngModel)]="receipt.print" name="receipt{{receipt.code}}"></ui-switch><span
            class="m-4">Recibo: {{receipt.code}}</span>
        </div>
      </div>

      <div class="form-group" *ngIf="!canShowDigitalSignatureDocs">
        <div *ngFor="let note of promissoryNotes">
          <ui-switch size="small" [(ngModel)]="note.print" name="note{{note.attachment.filename}}"></ui-switch><span
            class="m-4">Pagaré: {{note.attachment.filename}}</span>
        </div>
      </div>

      <div *ngIf="canShowDigitalSignatureDocs && isDigitalSignatureDone">
        <div class="form-group" *ngFor="let doc of digitalSignature.documents">
          <ui-switch size="small" [(ngModel)]="doc.print" name="receipt{{doc.code}}"></ui-switch>
          <span
            *ngIf="doc.documentType !== 'INVOICE' else isInvoice"
            class="m-4">{{ getDigitalSignatureDocName(doc.documentType) }}: {{doc.fileName}}</span>
            <ng-template #isInvoice>
              <span
                class="m-4">{{ getDigitalSignatureDocName(doc.documentType) }} {{doc.printType === 'ORIGINAL' ? 'Original' : 'Copia'}} : {{doc.fileName}}</span>
            </ng-template>

        </div>
      </div>

      <div *ngIf="vehicleRegistration">
        <div class="form-group" *ngFor="let circulationPermit of circulationPermits">
          <ui-switch size="small" [(ngModel)]="circulationPermit.print" name="checklist"></ui-switch><span
            class="m-4">Permiso de circulación: {{circulationPermit.code}}</span>
        </div>

        <div class="form-group">
          <ui-switch size="small" [(ngModel)]="checklist.print" name="checklist"></ui-switch><span
            class="m-4">Checklist</span>
        </div>
        <div class="form-group">
          <ui-switch size="small" [(ngModel)]="ip800File.print" name="ip800"></ui-switch><span class="m-4">IP800</span>
        </div>

        <div>
          <div class="form-group" *ngFor="let domainTradition of domainTraditionFiles">
            <ui-switch size="small" [(ngModel)]="domainTradition.print" name="{{domainTradition.invoiceNumber}}"></ui-switch><span
              class="m-4">Tradición de dominio: {{domainTradition.invoiceNumber}}</span>
          </div>
        </div>

        <div class="form-group">
          <ui-switch size="small" [(ngModel)]="registrationRefusal.print" name="registrationRefusal"></ui-switch>
          <span class="m-4">Rechazo de matricula</span>

          <div class="row" *ngIf="registrationRefusal.print">
            <div class="col-md-12 mb-3 mt-2">
              <label>Ubicación</label>
              <input class="form-control" type="text" name="identity" [(ngModel)]="registrationRefusal.location"
                [ngClass]="registrationRefusal.location.length == 0 ? 'is-invalid' : 'is-valid'" />
            </div>
          </div>


        </div>
        <div class="form-group" *ngFor="let warranty of warrantyCertificate">
          <ui-switch size="small" [(ngModel)]="warranty.print" name="warranty"></ui-switch><span class="m-4">Garantía
            para factura: {{warranty.invoiceNumber}}</span>
        </div>

        <div class="form-group">
          <ui-switch size="small" [(ngModel)]="thirdPartyAuthoriztion.print" name="thirdPartyAuthoriztion"></ui-switch>
          <span class="m-4">Autorización de terceros</span>

          <div class="row" *ngIf="thirdPartyAuthoriztion.print">
            <div class="col-md-6 mb-3 mt-2">
              <label>Identificación</label>
              <input class="form-control" type="text" name="identity" [(ngModel)]="thirdPartyForm.identity"
                [ngClass]="thirdPartyForm.identity.length < 13 ? 'is-invalid' : 'is-valid'" />
            </div>
            <div class="col-md-6 mb-3 mt-2">
              <label>Nombre</label>
              <input class="form-control" type="text" name="name" [(ngModel)]="thirdPartyForm.name"
                [ngClass]="thirdPartyForm.name.length == 0 ? 'is-invalid' : 'is-valid'" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="selectAllDocuments()">Seleccionar todos</button>
    <button type="button" class="btn btn-success" [disabled]="validatePrintButton()" (click)="print()">Imprimir
      seleccionados</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
  </div>
</div><!-- /.modal-content -->
