<app-header
  [navbarBrandRouterLink]="['']"
  [fixed]="true"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
  [mobileAsideMenuToggler]="false"
  [navbarBrandFull]="{src: 'assets/logoPosV1.svg', width: 100, height: 60, alt: 'Pos Logo'}"
  [navbarBrandMinimized]="{src: 'assets/logoPosV1.svg', width: 30, height: 30, alt: 'Pos Logo'}"
>

<ul class="nav navbar-nav ml-auto">
  <li class="nav-item dropdown" dropdown placement="bottom right">
    <a class="user-text-clickable  px-3 dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
      <b>[{{profile.username}}] {{profile.fullname}}</b>
    </a>
    <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
      <div class="dropdown-header text-center"><strong>Opciones</strong></div>
      <a class="dropdown-item"  href="javascript:void(0);" [routerLink]="['/changepassword', profile.username]"><i class="icon-lock"></i> Cambiar contraseña</a>
    </div>
  </li>
</ul>
</app-header>
<div class="app-body">
  <app-sidebar 
  #appSidebar 
  [display]="'lg'" 
  [fixed]="true" 
  [minimized]="sidebarMinimized" 
  (minimizedChange)="toggleMinimize($event)">
  <div>
    <label class="nav-title bg-muted text-white col-sm mt-2"><i class="fa fa-money"></i>&nbsp;USD-HNL: {{exchangeRate.rate}}</label>
  <label class="nav-title bg-muted text-white col-sm"><i class="fa fa-building"></i>&nbsp;{{salesOrgObject.code}} - {{salesOrgObject.name}}</label>
  <label class="nav-title bg-muted text-white col-sm"><i class="fa fa-home"></i>&nbsp;{{branchObject.code}} - {{branchObject.name}}</label>

  <ng-template #popTemplateHtml>
    <form #f="ngForm" (ngSubmit)="f.valid && changeBranch()">
    <div class="card-body">
    <div class="input-group mb-3 mr-3">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fa fa-building-o"></i></span>
      </div>
        <select id="salesOrg" name="salesOrg" class="form-control" [(ngModel)]="salesOrg" (change)="filterBranches()">
          <option *ngFor="let salesOrg of salesOrgs" [value]="salesOrg.code">{{salesOrg.code}} - {{salesOrg.name}}</option>
        </select>
      
    </div>
    <div class="input-group mb-3 mr-3">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="cil-home"></i></span>
      </div>
        <select id="select1" name="select1" class="form-control" [(ngModel)]="branch">
          <option value="">Seleccione una Sucursal</option>
          <option *ngFor="let branch of filteredBranches" [value]="branch.code">{{branch.code}} - {{branch.name}}</option>
        </select>
       
    </div>
  
      
        <button type="submit" class="form control btn btn-primary">Cambiar TIenda</button>
      
   
  </div>
</form>
  </ng-template>
  <a class="nav-link" href="javascript:void(0);" placement="right" container="body"  [popover]="popTemplateHtml" popoverTitle="Cambio de Tienda">
    <i class="nav-icon cil-home"></i> Cambiar Tienda
  </a>
</div>
    
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized">
    </app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>

  <main class="main">
    <cui-breadcrumb>
      <!-- Breadcrumb Menu-->
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>
<app-footer [fixed]="true">
  <span>POS Franquicias v1.0.0</span>
  <span class="ml-auto">Inversiones La Paz</span>
</app-footer>