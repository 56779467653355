import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PosVerifyCustomerService } from 'src/app/services/pos-verify-customer.service';
import { CustomersModule } from 'src/app/views/customers/customers.module';
import { PosVerifyCustomerComponent } from './pos-verify-customer.component';

@NgModule({
  declarations: [PosVerifyCustomerComponent],
  imports: [CommonModule, CustomersModule],
  providers: [PosVerifyCustomerService],
  exports: [PosVerifyCustomerComponent],
})
export class PosVerifyCustomerModule {}
