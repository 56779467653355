import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RepairshopOrderSubType } from '../@core/constants';
import { Filter } from '../models/filter';
import { CreditNotesFilter } from '../models/filters/credit-notes-filter';
import { InvoiceFilter } from '../models/filters/invoice-filter';
import { Invoice } from '../models/invoice';
import { Paging } from '../models/page';
import { PaymentTransactionRequestModel } from '../models/request/PaymentTransactionRequestModel';
import { ResponseModel } from '../models/response-model';
import { PaymentTransactionResponse } from '../models/response/paymentTransactionResponse';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private url = `${environment.url}/invoices`;
  private readonly TRUE_STRING = 'true';
  private readonly FALSE_STRING = 'false';
  constructor(private http: HttpClient, private configService: ConfigurationService) { }

  proccessTransaction(transaction: Array<PaymentTransactionRequestModel>): Observable<ResponseModel<Array<PaymentTransactionResponse>>> {
    return this.http.post<ResponseModel<Array<PaymentTransactionResponse>>>(`${this.url}/paymentTransaction`, transaction);
  }

  simulatePaymentTransaction(transaction: PaymentTransactionRequestModel) {
    return this.http.post<ResponseModel<PaymentTransactionResponse>>(`${this.url}/simulatePaymentTransaction`, transaction);
  }
  readInvoices(invoiceFilter: Filter<InvoiceFilter>): Observable<ResponseModel<Paging<Array<Invoice>>>> {
    let params = new HttpParams();
    params = params
      .set("page", invoiceFilter.page.toString() || "0")
      .set("pageSize", invoiceFilter.pageSize.toString() || "20");

    params = invoiceFilter.value.salesOrg ? params.set("salesOrg", invoiceFilter.value.salesOrg) : params;
    params = invoiceFilter.value.orderCode ? params.set("orderCode", invoiceFilter.value.orderCode) : params;
    params = invoiceFilter.value.fiscalEstablishment ? params.set("fiscalEstablishment", invoiceFilter.value.fiscalEstablishment) : params;
    params = invoiceFilter.value.fiscalEmisionPoint ? params.set("fiscalEmisionPoint", invoiceFilter.value.fiscalEmisionPoint) : params;
    params = invoiceFilter.value.fiscalDocumentType ? params.set("fiscalDocumentType", invoiceFilter.value.fiscalDocumentType) : params;
    params = invoiceFilter.value.number ? params.set("number", invoiceFilter.value.number) : params;
    params = invoiceFilter.value.branchCode ? params.set("branchCode", invoiceFilter.value.branchCode) : params;

    return this.http.get<ResponseModel<Paging<Array<Invoice>>>>(`${this.url}`, { params });
  }

  readCreditNotes(filter: Filter<CreditNotesFilter>): Observable<ResponseModel<Paging<Array<Invoice>>>> {

    let params = new HttpParams();
    params = params
      .set("page", filter.page.toString() || "0")
      .set("pageSize", filter.pageSize.toString() || "20")
      .set("salesOrg", this.configService.readSelectedBranchAndSalesOrg().salesOrg.code)
      .set("branchCode", this.configService.readSelectedBranchAndSalesOrg().branch.code);

    params = filter.value.customerCode ? params.set("customerCode", filter.value.customerCode) : params;
    params = filter.value.orderType ? params.set("orderType", filter.value.orderType) : params;
    params = filter.value.sellerName ? params.set("sellerName", filter.value.sellerName) : params;
    params = filter.value.startDate ? params.set("startDate", filter.value.startDate) : params;
    params = filter.value.endDate ? params.set("endDate", filter.value.endDate) : params;
    params = filter.value.fullNumber ? params.set("fullNumber", filter.value.fullNumber) : params;
    params = filter.value.usableAsPayment ? params.set("usableAsPayment", "true") : params;
    params = filter.value.repairshopOrderSubType ? params.set("repairshopOrderSubType", filter.value.repairshopOrderSubType) : params.set('repairshopOrderSubType', RepairshopOrderSubType.UNSPECIFIED);

    return this.http.get<ResponseModel<Paging<Array<Invoice>>>>(this.url, { params });

  }
}
