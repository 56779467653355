import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NO_FISCAL_REGISTER_PARAM_CODE, RegisterStatus, Role, UserStatus } from 'src/app/@core/constants';
import { Branch } from 'src/app/models/branch';
import { BranchRegion } from 'src/app/models/branch-region';
import { Paging } from 'src/app/models/page';
import { PhysicalEstablishment } from 'src/app/models/physical-establishment';
import { Region } from 'src/app/models/region';
import { Register } from 'src/app/models/register';
import { ResponseModel } from 'src/app/models/response-model';
import { SalesOrg } from 'src/app/models/sales-org';
import { User } from 'src/app/models/user';
import { BranchRegionService } from 'src/app/services/branch-region.service';
import { BranchesService } from 'src/app/services/branches.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { ParamsService } from 'src/app/services/params.service';
import { PhysicalEstablishmentService } from 'src/app/services/physical-establishment.service';
import { RegionService } from 'src/app/services/region.service';
import { RegisterService } from 'src/app/services/register.service';
import { SalesOrgService } from 'src/app/services/sales-org.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.sass']
})
export class ConfigurationsComponent implements OnInit {

  public establishments: Paging<Array<PhysicalEstablishment>> = new Paging([]);
  //public physicalEstablishment: PhysicalEstablishment = new PhysicalEstablishment();
  public physicalEstablishment: string = "";

  public registers: Array<Register> = [];
  private unfilteredRegisters: Paging<Array<Register>> = new Paging([]);
  //public register: Register = new Register();
  public register: string = "";
  private noFiscalRegister = new Register();

  public salesOrgs: Paging<Array<SalesOrg>> = new Paging([]);
  public salesOrg: Array<SalesOrg> = [];
  public regions: Array<BranchRegion> = [];
  //public region: BranchRegion = new BranchRegion();
  public regionSelected: Array<BranchRegion> = [];
  public branches: Paging<Array<Branch>> = new Paging([]);
  public branch: any = {};

  public users: Paging<Array<User>> = new Paging([]);
  public username: string = "";
  constructor(
    private physicalEstablishmentService: PhysicalEstablishmentService,
    private configurationService: ConfigurationService,
    private registerService: RegisterService,
    private salesOrgService: SalesOrgService,
    private branchesService: BranchesService,
    private usersService: UsersService,
    private router: Router,
    private authService: AuthService,
    private regionService: BranchRegionService,
    private paramsService: ParamsService) {

  }

  async ngOnInit(): Promise<void> {
    const params = (await this.paramsService.readParams().toPromise()).response;
    this.noFiscalRegister = (await this.registerService.readRegister(params[NO_FISCAL_REGISTER_PARAM_CODE]).toPromise()).response;
    this.establishments = (await this.physicalEstablishmentService.readPhysicalEstablishments().toPromise()).response;
    this.unfilteredRegisters = (await this.registerService.readRegisters().toPromise()).response;
    this.salesOrgs = (await this.salesOrgService.readSalesOrgs().toPromise()).response;
    this.branches = (await this.branchesService.readBranches().toPromise()).response;
    this.users = (await this.usersService.readUsersByRoleSupervisor().toPromise()).response;
    this.regions = (await this.regionService.readRegions().toPromise()).response.content;
    this.loadCurrentConfiguration();
  }

  loadCurrentConfiguration() {
    this.physicalEstablishment = this.configurationService.readPhysicalEstablishment().code;
    this.registers = [this.noFiscalRegister].concat(this.unfilteredRegisters.content.filter(f => f.physicalEstablishmentCode == this.physicalEstablishment));
    this.register = this.configurationService.readRegister().code;
    this.salesOrg = this.configurationService.readSalesOrg();
    this.branch = this.configurationService.readBranch();
    this.regionSelected = this.configurationService.readRegion();

    this.salesOrg.map(m => {
      if (this.branch[m.code]) {
        this.branch[m.code] = this.branch[m.code].map((x: { code: any; }) => x.code);
      }
    })

    this.username = this.configurationService.readSupervisor().username;
  }

  saveChanges(): void {
    this.salesOrg.map(m => {
      if (this.branch[m.code]) {
        this.branch[m.code] = this.branches.content.filter(f => this.branch[m.code].includes(f.code));
      }
    });

    this.regionSelected.map(rs => {
      this.regions.filter(r => rs.code == r.code);
    });

    this.configurationService.createPhysicalEstablishment(this.establishments.content.find(f => f.code == this.physicalEstablishment))
      .createRegister(this.registers.find(f => f.code == this.register))
      .createSalesOrg(this.salesOrg)
      .createBranch(this.branch)
      .createRegion(this.regionSelected)
      .createSupervisor(this.users.content.find(f => f.username == this.username));
    this.cancel();
  }

  resetConfig(): void {
    this.configurationService.removeConfiguration();
    this.physicalEstablishment = "";
    this.register = "";
    this.salesOrg = new Array<SalesOrg>();
    this.regionSelected = [];
    this.branch = {};
    this.username = "";
  }

  changePhysicalEstablishment(event: any) {

    this.registers = [this.noFiscalRegister].concat(this.unfilteredRegisters.content.filter(f => f.physicalEstablishmentCode == event));
  }

  filterBranches(salesOrg: SalesOrg): Array<Branch> {
    if (!salesOrg) {
      return [];
    }
    return this.branches.content.filter(f => f.salesOrg == salesOrg.code);
  }

  selectSalesOrg(salesOrg: Array<SalesOrg>) {

    Array.from(this.branch.keys()).forEach(branchKey => {
      if (!salesOrg.some(s => this.branch[s.code])) {
        this.branch.delete(branchKey);
      }
    });
  }

  selectBranch(salesOrg: SalesOrg, branch: Array<Branch>) {
    this.branch[salesOrg.code] = branch;
  }

  cancel() {
    this.authService.removeTokenFromStorage();
    this.router.navigateByUrl("login");
  }
}
