import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddPaymentModalComponent } from 'src/app/shared/pos-payment-method/add-payment-modal/add-payment-modal.component';
import { ModalDialogType } from 'src/app/@core/constants';
import { PaymentMethod } from 'src/app/models/payment-method';

@Injectable({
  providedIn: 'root'
})
export class AddPaymentModalService {
  private modalRef?: BsModalRef;

  constructor(private modalService: BsModalService) { }

  show(
    payment: PaymentMethod,
    totalAmount: number,
    paymentsAmount: number,
    customerCode: string,
    paymentList: Array<PaymentMethod>,
    creditLimitAmount: number,
    confirmAction: () => any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(AddPaymentModalComponent, {
        ignoreBackdropClick: true,
        initialState: { paymentMethod: payment, totalAmount, paymentsAmount, customerCode, paymentList, creditLimitAmount }
      });
      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY}`);
      this.modalRef.content.confirmAction = confirmAction;

      resolve(true);
    });
  }
}
