import { HttpClient, HttpParams } from '@angular/common/http';
import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Branch } from '../models/branch';
import { BranchFilter } from '../models/filters/branch-filter';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BranchesService {
  private url = `${environment.url}/branches`;
  constructor(private http: HttpClient,
    private configurationService: ConfigurationService) { }

  readBranches(): Observable<ResponseModel<Paging<Array<Branch>>>> {
    let params = new HttpParams();
    params = params
      .set("page", "0")
      .set("pageSize", "5000")
    return this.http.get<ResponseModel<Paging<Array<Branch>>>>(this.url, { params });
  }

  readBranch(branchCode: string): Observable<ResponseModel<Branch>> {
    return this.http.get<ResponseModel<Branch>>(`${this.url}/${branchCode}`);
  }

  readFilteredBranches(filter: BranchFilter): Observable<ResponseModel<Paging<Array<Branch>>>> {
    let params = new HttpParams();

    params = filter.regionCodes ? params.set("regionCode", filter.regionCodes) : params;
    params = filter.isFranchised ? params.set("isFranchised", String(filter.isFranchised)) : params;

    params = params
      .set("page", "0")
      .set("pageSize", "5000");
    return this.http.get<ResponseModel<Paging<Array<Branch>>>>(this.url, { params });
  }
}
