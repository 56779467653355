import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'pos-promotions-modal',
  templateUrl: './pos-promotions-modal.component.html',
  styleUrls: ['./pos-promotions-modal.component.sass']
})
export class PosPromotionsModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {

  }
  cancel() {
    this.bsModalRef.hide();
  }
}
