import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Loan } from '../models/loan';
import { Paging } from '../models/page';
import { LoanSimulationRequest } from '../models/requests/loan-simulation-request';
import { ResponseModel } from '../models/response-model';
import { Filter } from 'src/app/models/filter';
import { LoansFilter } from '../models/filters/loans-filter';

@Injectable({
  providedIn: 'root'
})
export class LoanService {
  private url = `${environment.url}/loans`;
  constructor(private http: HttpClient) { }

  createCalculateConditionsSimulate(parameters: LoanSimulationRequest): Observable<ResponseModel<Loan>> {
    let params = new HttpParams();
    params = params.set("simulate", "true");
    return this.http.post<ResponseModel<Loan>>(`${this.url}/calculateConditions`, parameters, { params });
  }

  getCustomerLoans(
    filter: Filter<LoansFilter>): Observable<ResponseModel<Paging<Array<Loan>>>> {

    const url = `${environment.url}/loansFranchised`;

    let params = new HttpParams();
    params = params.set("page", filter.page.toString() || "0")
      .set("pageSize", filter.pageSize.toString() || "20")


    params = filter.value.customerCode ? params.set("customer", filter.value.customerCode) : params;
    params = filter.value.contractNumber ? params.set('contractNumber', filter.value.contractNumber) : params;
    params = filter.value.startDate ? params.set('startDate', filter.value.startDate) : params;
    params = filter.value.endDate ? params.set('endDate', filter.value.endDate) : params;
    params = filter.value.hasContractNumber ? params.set('hasContractNumber', "true") : params;

    return this.http.get<ResponseModel<Paging<Array<Loan>>>>(url, { params });
  }

  getCurrentCreditsByCustomer(customerCode: string): Observable<ResponseModel<Loan[]>> {
    let params = new HttpParams();
    params = params.set("customer", customerCode);

    return this.http.get<ResponseModel<Loan[]>>(`${this.url}`, { params });
  }
}
