import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDialogType, OrderAttachmentType, OrderConditions, OrderConditionsType, OrderStatus, OrderTransactionStatus, OrderType } from 'src/app/@core/constants';
import { ColumnTable } from 'src/app/models/column-table';
import { Loan } from 'src/app/models/loan';
import { OrderAttachment } from 'src/app/models/order-attachment';
import { OrderCompleteProcess } from 'src/app/models/order-complete-process';
import { Paging } from 'src/app/models/page';
import { ProductCart } from 'src/app/models/product-cart';
import { OrderService } from 'src/app/services/order.service';
import { DateUtils } from 'src/app/@core/utils/date-utils';
import { PaymentResponsible } from 'src/app/models/payment-responsible';
import { BranchesService } from 'src/app/services/branches.service';
import { PaymentTransactionRequestModel } from 'src/app/models/request/PaymentTransactionRequestModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { InvoiceRequestModel } from 'src/app/models/request/InvoiceRequestModel';

import { InvoiceService } from 'src/app/services/invoice.service';
import { LoanCondition } from 'src/app/models/loan-condition';
import { ToastrService } from 'ngx-toastr';
import { PosPrintDocumentsModalService } from 'src/app/@core/services/pos-print-documents-modal.service';
import { Franchise } from 'src/app/models/franchised';
import { FileUtils } from 'src/app/@core/utils/file-utils';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { PosOrderRejectModalService } from 'src/app/services/pos-order-reject-modal.service';
import { Branch } from 'src/app/models/branch';
import { StockRequestModel } from 'src/app/models/requests/stock-request-model';
import { OrderEntry } from 'src/app/models/order-entry';
import { DigitalSignature } from 'src/app/models/digital-signature';
import { PaymentMethodType } from 'src/app/models/payment-method-type';
import { PaymentService } from 'src/app/services/payment.service';
import { Order } from 'src/app/models/order';
import Big from 'big.js';
import { OrderTransaction } from 'src/app/models/order-transation';
import { TransactionService } from 'src/app/services/transaction.service';
import { PosOrderObservationsService } from 'src/app/@core/services/pos-order-observations.service';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-order-approval',
  templateUrl: './order-approval.component.html',
  styleUrls: ['./order-approval.component.sass']
})
export class OrderApprovalComponent implements OnInit {

  public dateUtils = DateUtils;
  public paymentResponsible: string = "";
  public franchised = new Franchise();
  public observations = "";
  orderApprovalInfo: OrderCompleteProcess = new OrderCompleteProcess();
  public orderEntryList: Paging<Array<ProductCart>> = new Paging<Array<ProductCart>>([]);
  public loans: Array<Loan> = [];
  fiscalIdFile: OrderAttachment = new OrderAttachment();
  rtnFiscalIdFile: OrderAttachment = new OrderAttachment();
  ip800File: OrderAttachment = new OrderAttachment();
  promissoryNote: OrderAttachment = new OrderAttachment();
  paymentMethods: Array<PaymentMethodType> = [];
  public paymentResponsibleList: Array<PaymentResponsible> = new Array<PaymentResponsible>();
  public invoiced = OrderStatus.FINAL;
  public isDelivery: boolean = false;
  public branch: Branch = new Branch();
  public stockRequestModel: StockRequestModel = new StockRequestModel();

  public invoices: Paging<Array<{ code: string, fullNumber?: string, netTotal: number }>> = new Paging([]);
  public receipts: Paging<Array<{ code: string, fullNumber?: string, netTotal: number }>> = new Paging([]);
  public payments = new Paging<Array<{ name: string, amount: number, identifier: string }>>([]);
  public transactions: Paging<Array<OrderTransaction>> = new Paging([]);
  digitalSignature: DigitalSignature = new DigitalSignature();
  customer: Customer = new Customer();

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    private branchService: BranchesService,
    private configurationService: ConfigurationService,
    private invoiceService: InvoiceService,
    private toasterService: ToastrService,
    private posPrintService: PosPrintDocumentsModalService,
    private confirmationService: PosConfirmationService,
    private posOrderRejectService: PosOrderRejectModalService,
    private router: Router,
    private paymentMethodService: PaymentService,
    private transactionService: TransactionService,
    private posObservationService: PosOrderObservationsService,
    private customerService: CustomerService) {
  }

  async ngOnInit(): Promise<void> {
    await this.loadOrder();
    if (this.order) {
      if (this.canSign) {
        this.digitalSignature = await this.orderService.readDigitalSignatureByOrderCode(this.order.code).toPromise() || new DigitalSignature();
      }
      this.customer = (await this.customerService.readCustomerByCode(this.order.customerCode).toPromise());
    }
  }

  async loadOrder() {
    this.paymentMethods = (await this.paymentMethodService.getPaymentMethods(this.configurationService.readSelectedBranchAndSalesOrg().salesOrg.code).toPromise()).response.content;
    let param = this.route.snapshot.params.code;
    this.orderApprovalInfo = (await this.orderService.readerOrderCompleteProcess(param).toPromise()).response;
    this.stockRequestModel.productCode = this.orderApprovalInfo.order.orderEntryList
      .filter(f => this.applyDelivery(f)).map(m => {
        return m.productCode;
      });
    this.loans = this.orderApprovalInfo.loans;
    this.fiscalIdFile = this.orderApprovalInfo.attachments.find(f => f.type == OrderAttachmentType.FISCALID) || new OrderAttachment();
    this.rtnFiscalIdFile = this.orderApprovalInfo.attachments.find(f => f.type == OrderAttachmentType.RTNFISCALID) || new OrderAttachment();
    this.ip800File = this.orderApprovalInfo.attachments.find(f => f.type == OrderAttachmentType.IP800) || new OrderAttachment();
    this.promissoryNote = this.orderApprovalInfo.attachments.find(f => f.type == OrderAttachmentType.PROMISSORY_NOTE) || new OrderAttachment();
    this.branch = (await (this.branchService.readBranch(this.orderApprovalInfo.order.branchCode).toPromise())).response;
    this.franchised = this.branch.franquicia ? this.branch.franquicia : new Franchise();
    this.paymentResponsibleList.push({ code: this.orderApprovalInfo.order.customerCode, name: this.orderApprovalInfo.order.fullName })

    this.invoices = new Paging(this.orderApprovalInfo.invoices.map(invoice => {
      return {
        code: invoice.code,
        fullNumber: invoice.fullNumber,
        netTotal: invoice.totals.netTotal
      }
    }));

    this.receipts = new Paging(this.orderApprovalInfo.receipts.map(receipt => {
      return {
        code: receipt.code,
        fullNumber: receipt.code,
        netTotal: receipt.totals.netTotal
      }
    }));

    this.payments = new Paging(this.orderApprovalInfo.payments.map(payment => {
      return {
        name: this.getPaymentMethod(payment.paymentMethodCode).displayName,
        amount: payment.amount,
        identifier: payment.identifier
      }
    }));

    this.orderEntryList = new Paging(this.orderApprovalInfo.order.orderEntryList.map(entry => {
      let model = new ProductCart();
      return model
        .setProductCode(entry.productCode)
        .setSerial(entry.serialNumber)
        .setDescription(entry.productName)
        .setPrice(entry.grossTotal)
        .setAmount(entry.totalQuantity)
        .setTotal(entry.netTotal)
        .setApplyDelivery(this.applyDelivery(entry));
    }));
    this.transactions = new Paging(this.orderApprovalInfo.transactions);
    if (window.history.state?.print) {
      await this.printReports();
    }
  }

  get order(): Order | null {
    return this.orderApprovalInfo ? this.orderApprovalInfo.order : null;
  }

  get canSign(): boolean {
    return this.order ? this.order.digitalSignature && this.order.status === 'FINAL' : false;
  }

  applyDelivery(entry: OrderEntry) {
    if (entry.tax.code == 'A6') {
      return false;
    }
    if ((entry.serialProfile)) {
      return false;
    }
    return true;

  }

  getPaymentMethod(paymentMethodCode: string): PaymentMethodType {
    return this.paymentMethods.find(f => f.code == paymentMethodCode) || new PaymentMethodType();
  }

  hidePaymentResponsible(): boolean {
    var result = this.orderApprovalInfo.order.orderCondition != OrderConditions.UNTIL_30_DAYS_FRANCHISED
    return result;
  }
  getProductCartHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable("amount", "#", { type: "number", iconClass: "fa fa-shopping-cart", isIcon: true }),
      new ColumnTable("productCode", "Código", { isSmall: true }),
      new ColumnTable("description", "Descripción", { isSmall: true }),
      new ColumnTable("serial", "Serie", { type: "badge" }),
      new ColumnTable("returnedAmount", "Devuelto", { type: "number" }),
      new ColumnTable("applyDelivery", "Aplica a domicilio", { type: "switch" }),
      new ColumnTable("price", "Sub-Total", { type: "currency" }),
      new ColumnTable("total", "Total", { type: "currency" })
    ]
  }

  getInvoicesHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable("fullNumber", "Número de Factura"),
      new ColumnTable("netTotal", "Total", { type: "currency" }),
    ]
  }

  getReceiptsHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable("fullNumber", "Número de Recibo"),
      new ColumnTable("netTotal", "Total", { type: "currency" }),
    ]
  }

  async approve() {

    await this.confirmationService.show("Aprobación de órden", "¿Esta seguro de aprobar la orden?", ModalDialogType.WARNING, async () => {
      if (this.orderApprovalInfo.order.isThirtyDays) {
        let payment = [this.createPayment()];
        let response = (await this.invoiceService.proccessTransaction(payment).toPromise()).response;
        if (response) {
          this.toasterService.success("Factura creada con éxito", "Facturación");
          await this.loadOrder();
          await this.printReports();
        }
      }
      else if (this.orderApprovalInfo.order.condition == "contado") {
        this.router.navigate(['backOfficeOrders/invoices', this.orderApprovalInfo.order.code],
          {
            state: {
              deliveryBranch: this.isDelivery ? this.stockRequestModel.branchCode[0] || "" : ""
            }
          });
      }
      else if (this.orderApprovalInfo.order.condition == "credito") {
        this.router.navigate(['backOfficeOrders/creditinvoices', this.orderApprovalInfo.order.code],
          {
            state: {
              deliveryBranch: this.isDelivery ? this.stockRequestModel.branchCode[0] || "" : ""
            }
          });
      }
    });
  }

  async reject() {

    await this.posOrderRejectService.show("Rechazo de orden", "Ingrese observación de rechazo",
      this.orderApprovalInfo.order.code,
      ModalDialogType.WARNING, async () => {
        await this.loadOrder();
      });

  }

  createPayment(): PaymentTransactionRequestModel {
    let paymentRequest = new PaymentTransactionRequestModel();

    paymentRequest.paymentResponsible = this.franchised.code;
    paymentRequest.paymentResponsibleName = this.franchised.name;
    paymentRequest.orderCode = this.orderApprovalInfo.order.code;
    paymentRequest.branchCode = this.orderApprovalInfo.order.branchCode;
    paymentRequest.registerCode = this.configurationService.readRegister().code;
    paymentRequest.observations = this.observations;
    paymentRequest.invoiceDetails = this.orderApprovalInfo.order.orderEntryList.map(detail => {
      let invoiceModel = new InvoiceRequestModel();
      invoiceModel.orderEntryCode = detail.code;
      invoiceModel.quantity = detail.totalQuantity;
      return invoiceModel;
    });
    paymentRequest.loanCode = this.loans[0].code;

    paymentRequest.loanRequestModel.loanCode = this.loans[0].code;
    paymentRequest.loanRequestModel.signedInCity = this.loans[0].signedInCity;
    paymentRequest.loanRequestModel.signedInDepartment = this.loans[0].signedInDepartment;
    paymentRequest.loanRequestModel.intermediaries = this.loans[0].intermediaries.map(inter => {
      return inter.customerCode.toString();
    });

    paymentRequest.paymentsRequestModel.branchCode = this.orderApprovalInfo.order.branchCode;
    paymentRequest.paymentsRequestModel.orderCode = this.orderApprovalInfo.order.code;
    paymentRequest.paymentsRequestModel.registerCode = this.configurationService.readRegister().code;
    paymentRequest.paymentsRequestModel.salesOrgCode = this.orderApprovalInfo.order.salesOrgCode;

    paymentRequest.isForDelivery = this.isDelivery;
    paymentRequest.deliveryBranchCode = this.stockRequestModel.branchCode[0] || "";

    paymentRequest.replicateInExternalSystem = true;
    return paymentRequest;
  }


  getLoanFeeCondition(loan: Loan) {
    return loan.conditions.find(f => f.conditionType == "0261") || loan.conditions.find(f => f.conditionType == "0262") || new LoanCondition();
  }

  getLoanInterestCOndition(loan: Loan) {
    return loan.conditions.find(f => f.conditionType == "0203") || new LoanCondition();
  }

  getFeeWithInterest(loan: Loan) {

    var fee = this.getLoanFeeCondition(loan);
    var interest = this.getLoanInterestCOndition(loan);

    return fee.amount + interest.amount;
  }

  getOrderStatusClass(status: OrderStatus) {
    switch (status) {
      case OrderStatus.FINAL:
        return "badge-success";
      case OrderStatus.INVOICEABLE:
        return "badge-warning"
      case OrderStatus.INITIAL:
        return "badge-secondary"
      case OrderStatus.DELETED:
        return "badge-danger"
      case OrderStatus.NONE:
        return "badge-danger"
      case OrderStatus.REJECTED:
        return "badge-danger"
      default:
        return "";
    }
  }

  getOrderTypeClass(orderType: OrderType) {
    switch (orderType) {
      case OrderType.REGULAR:
        return "badge-success";
      case OrderType.QUOTATION:
        return "badge-warning";
      case OrderType.RETURN:
        return "badge-warning";
      case OrderType.REPAIRSHOP:
        return "badge-secondary";
      case OrderType.CANCELLED:
        return "badge-danger";
      default:
        return "";
    }
  }

  getOrderStatusText(status: string) {
    let result = new Map<string, string>();

    result.set("INITIAL", "Inicial");
    result.set("FINAL", "Facturado");
    result.set("INVOICEABLE", "Facturable");
    result.set("REJECTED", "Rechazado");
    return result.get(status);
  }

  getOrderTypeText(type: string) {
    let result = new Map<string, string>();

    result.set("REGULAR", "Regular");
    result.set("QUOTATION", "Cotización");
    return result.get(type);
  }

  getConditionText(condition: number) {
    let result = new Map<number, string>();
    result.set(OrderConditions.CASH_BILLLING, "Contado");
    result.set(OrderConditions.FINAL_CUSTOMER_CREDIT, "Crédito");
    result.set(OrderConditions.UNTIL_30_DAYS_FRANCHISED, "Crédito a 30 días");
    result.set(OrderConditions.MORE_THAN_30_DAYS_FRANCHISED, "Financiamiento con responsable de pago franquiciado");
    return result.get(condition);
  }

  getDigitalSignatureBadge(digitalSignature: boolean) {
    let result = new Map<string, string>();
    result.set("Si", "Si");
    result.set("No", "No");

    return digitalSignature ? result.get('Si') : result.get('No');
  }

  async printReports() {

    this.validatePayments();
    if (this.validatePayments()) {
      this.posPrintService.show(this.orderApprovalInfo, this.customer, this.digitalSignature);
    }
  }

  validatePayments() {
    if (this.payments.content.length > 0) {
      let paymentTotal = this.payments.content.reduce((a, b) => {
        return { amount: a.amount + b.amount, identifier: "total", name: "total" };
      })
      if ((!Big(paymentTotal.amount).round(2).eq(Big(this.order?.totals.netTotal || 0.0).round(2)))) {
        this.toasterService.warning("Los pagos no coinciden con la orden, no se puede imprimir los documentos. Reprocese manualmente la orden.", "¡Advertencia!")
        return false;
      }
    }
    return true;
  }

  downloadFiscalIdFile() {
    let blob = FileUtils.dataURLtoBlob(this.fiscalIdFile.data)
    window.open(URL.createObjectURL(blob));
  }
  downloadrtnFiscalIdFile() {
    let blob = FileUtils.dataURLtoBlob(this.rtnFiscalIdFile.data)
    window.open(URL.createObjectURL(blob));
  }
  downloadip800File() {
    let blob = FileUtils.dataURLtoBlob(this.ip800File.data)
    window.open(URL.createObjectURL(blob));
  }

  downloadPromissoryNoteFile() {
    let blob = FileUtils.dataURLtoBlob(this.promissoryNote.data)
    window.open(URL.createObjectURL(blob));
  }

  validateApproval(): boolean {
    if (this.orderApprovalInfo.order.status == this.invoiced
      || this.franchised.name == ''
      || this.orderApprovalInfo.order.status == OrderStatus.REJECTED) {
      return true;
    }
    return false;
  }

  selectedDeliveryBranch(event: any) {
    this.stockRequestModel.branchCode.push(event.code);
  }

  showPaymentResponsible(): boolean {
    if (this.orderApprovalInfo.order.orderCondition == 2
      || this.orderApprovalInfo.order.orderCondition == 1) {
      return true
    }
    return false;
  }

  showNeedDigitalSignature(orderStatus: OrderStatus) {
    return orderStatus == OrderStatus.FINAL.toString();
  }

  getTransactionsHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable("code", "Código"),
      new ColumnTable("status", "Estado", { type: "badge", valueMap: this.getOrderTransactionStatusMap() }),
      new ColumnTable("code", "Reprocesar", {

        type: "action",
        actions: [
          {
            fn: async (transaction: OrderTransaction) => {
              await this.confirmationService.show(`Reenvio de transacción ${transaction.code}`, "¿Está seguro de reenviar esta transacción?", ModalDialogType.SUCCESS, async () => {
                await this.transactionService.processTransaction(transaction.code).toPromise();
                window.location.reload();
              });
            },
            icon: "fa fa-refresh text-warning",
            tooltip: "Reprocesar",
            isDisabled: false,
            disabledFn: (transaction: OrderTransaction) => transaction.status == OrderTransactionStatus.FINISHED
          }
        ]
      })
    ]
  }
  getOrderTransactionStatusMap(): Map<string, string> {
    let result = new Map<string, string>();
    result.set("NEW", "Nuevo");
    result.set("INVOICES", "Creando Factura");
    result.set("LOANS", "Creando Contrato");
    result.set("NOTE_CHARGE", "Creando Recibo");
    result.set("NOTE_CHARGE_PAYMENT", "Creando Pago de Recibo");
    result.set("PAYMENTS", "Creando Pago de Factura");
    result.set("DOCUMENT_ATTACHMENTS", "Creando Archivos");
    result.set("DOWNPAYMENT_RECEIPT", "Creando Recibo de Prima");
    result.set("FINISHED", "Terminado");
    result.set("NO_STATUS", "Sin Estado");

    return result;
  }
  async showObservations() {
    await this.posObservationService.show(this.order?.observations || "", (o: string) => { }, true);
  }
}
