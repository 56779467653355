export class Equipment {
    code: string;
    vehicle: string;
    denomination: string;
    make: string;
    part: string;
    year?: number;
    plate: string;
    makeId: string;
    serial: string;
    engineSerial: string;
    cc: string;
    policyCode: string;
    policyDate: string;
    policyItem: string;

    constructor() {
        this.code = "";
        this.vehicle = "";
        this.denomination = "";
        this.make = "";
        this.part = "";
        this.plate = "";
        this.makeId = "";
        this.serial = "";
        this.engineSerial = "";
        this.cc = "";
        this.policyCode = "";
        this.policyDate = "";
        this.policyItem = "";
    }
}