<div class="animated fadeIn rounded">
  <div class="card bg-white">
    <div class="card-header">
      <i class="cil-cart"></i> Ventas
      <div class="card-header-actions btn-group" dropdown placement="bottom left">
        <!-- <button class="btn btn-sm btn-success" type="button"><i class="fa fa-floppy-o"></i>&nbsp;Guardar Orden</button> -->
        <button class="btn btn-sm btn-primary" type="button" (click)="setTaxExemption()"><i
            class="fa fa-lightbulb-o"></i>&nbsp;Exonerar</button>
        <button class="btn btn-sm btn-primary" type="button" appPrivilege="setOrderDiscountFranchised"
          (appPrivilegeClick)="setDiscount()">
          <i class="fa fa-credit-card"></i> &nbsp;Rebaja de Orden
        </button>
        <button class="btn btn-sm btn-primary" type="button"
          (click)="setObservations()">
          <i class="fa fa-edit"></i> &nbsp;Observaciones
        </button>
      </div>
    </div>
    <div class="card-body card-body-z-index">

      <pos-stepper #cdkStepper linear="true">
        <cdk-step label="Cliente" [stepControl]="formCustomer">
          <customer-step [formCustomer]="formCustomer" [customer]="customer"
            (getCustomerStepInfo)="getCustomerStepInfo($event)">
          </customer-step>
        </cdk-step>

        <cdk-step label="Tipo de Orden" [stepControl]="formOrderType">
          <app-order-type-step [customerCode]="customerCode" [actualBranch]="actualBranch" [order]="order"
            (selectOrderType)="onSelectOrderType($event)">
          </app-order-type-step>
        </cdk-step>


        <cdk-step label="Carrito" [stepControl]="formCart">
          <cart-step [customer]="customer" [seller]="employee" [taxExemption]="taxExemption" [orderDiscount]="discount"
           (getOrder)="getOrder($event)" (getTableFormExternal)="getCartForm($event)"
            [productsCart]="productsCartFormServer" [orderTypeId]="orderTypeId" [orderSimulate]="order" [activePromotions]="activePromotions" [observations]="observations"
            (removeDiscount)="removeDiscount()" (getCustomerStepInfo)="getCreditInfo($event)">
          </cart-step>
        </cdk-step>

        <div *ngIf="orderTypeId == 3 || orderTypeId == 2 || orderTypeId == 5">
          <cdk-step label="Condiciones de Crédito"  [stepControl]="calculatedFeesFormArray">
            <app-credit-calculator-step (creditConditionsChange)="handleCreditConditionChange($event)" [orderTypeId]="orderTypeId" [loans]="this.loansSimulated" [result]="resultCompleteOrderProcess" [order]="this.order" [customerCreditConditions]="creditConditionsResult" [orderRequest]="this.orderRequest"  [isEditingMode]="true" [calculatedFeesFormArray]="calculatedFeesFormArray" [isPromotionOrDiscountApplied]="isPromotionOrDiscountApplied" #creditCalculatorStepComponent>
            </app-credit-calculator-step >
          </cdk-step>
        </div>
 
        <cdk-step label="Documentos Pre-Requisitos" [stepControl]="documentsForm" [optional]="true">
          <documents-step [documentsForm]="documentsForm" [fiscalIdFileName]="fiscalIdFile.filename" 
            [rtnFiscalIdFileName]="rtnFiscalIdFile.filename" [ip800FileName]="ip800File.filename"
            [proofOfPaymentName]="proofOfPaymentFile.filename" [purchaseOrderName]="purchaseOrderFile.filename" [customer]="customer"
            [productsCart]="productsCart">
          </documents-step>
        </cdk-step>

        <cdk-step label="Guardar">
          <save-step [customer]="customer" [employee]="employee" [taxExemption]="taxExemption"
            [orderDiscount]="discount" [loanParameters]="loanSimulationRequest" [productsCart]="productsCart"
            [order]="order" [orderTypeId]="this.orderTypeId || 0" [fiscalIdFile]="getFileInfo('fiscalIdFile')"
            [rtnFiscalIdFile]="getFileInfo('rtnFiscalIdFile')" [ip800File]="getFileInfo('ip800File')"
            [proofOfPaymentFile]="getFileInfo('proofOfPaymentFile')" [purchaseOrderFile]="getFileInfo('purchaseOrderFile')" [isModify]="true" [observations]="observations">
          </save-step>
        </cdk-step>
      </pos-stepper>

    </div>
  </div>
</div>
