import { ServiceType } from "../@core/constants";
import { Price } from "./price";
import { ProductQuantityUnits } from "./product-quantity-units";
import { StockAmount } from "./stock-amount";

export class Product {
    id: string;
    code: string;
    setMembersCodes: string;
    salesOrg: string;
    branchCode: string;
    variant: string;
    eans: Set<string>;
    shortName: string;
    name: string;
    description: string;
    features: { [value: string]: string };
    taxCode: string;
    timeStamp: Date;
    baseUnit: string;
    price: Price;
    cost: Price;
    imagePath: string;
    thumbnailPath: string;
    materialGroup: string;
    weightGross: number;
    weightNet: number;
    weightUnit: string;
    volume: number;
    volumeUnit: string;
    length: number;
    width: number;
    height: number;
    dimUnit: string;
    wharehouseLocation: string;
    wharehouseNumber: string;
    warranty: number;
    extraWarranties: Array<number>;
    serviceType: ServiceType;
    isService: boolean;
    sellable: boolean;
    deliverable: boolean;
    invoiceable: boolean;
    installable: boolean;
    requiresSerial: boolean;
    plate: boolean;
    uniqueInvoice: boolean;
    quantityUnits: ProductQuantityUnits;
    carStock: number;
    carTotalStock: number;
    stockBranches: Array<StockAmount>;
    serialProfile: boolean;

    constructor() {
        this.id = "";
        this.code = "";
        this.setMembersCodes = "";
        this.salesOrg = "";
        this.branchCode = "";
        this.variant = "";
        this.eans = new Set();;
        this.shortName = "";
        this.name = "";
        this.description = "";
        this.features = {};
        this.taxCode = "";
        this.timeStamp = new Date();
        this.baseUnit = "";
        this.price = new Price();
        this.cost = new Price();
        this.imagePath = "";
        this.thumbnailPath = "";
        this.materialGroup = "";
        this.weightGross = 0.0;
        this.weightNet = 0.0;
        this.weightUnit = "";
        this.volume = 0.0;
        this.volumeUnit = "";
        this.length = 0.0;
        this.width = 0.0;
        this.height = 0.0;
        this.dimUnit = "";
        this.wharehouseLocation = "";
        this.wharehouseNumber = "";
        this.warranty = 0.0;
        this.extraWarranties = [];
        this.serviceType = ServiceType.OTHER;
        this.isService = false;
        this.sellable = true;
        this.deliverable = true;
        this.invoiceable = true;
        this.installable = false;
        this.requiresSerial = false;
        this.plate = false;
        this.uniqueInvoice = false;
        this.quantityUnits = new ProductQuantityUnits();
        this.carStock = 0;
        this.carTotalStock = 0;
        this.stockBranches = [];
        this.serialProfile = false;
    }
}