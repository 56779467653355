import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCustomerCreateModule } from '../pos-customer-create/pos-customer-create.module';
import { PosCreateCustomerModalComponent } from './pos-create-customer-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';



@NgModule({
  declarations: [PosCreateCustomerModalComponent],
  imports: [
    CommonModule,
    PosCustomerCreateModule,
    ModalModule.forRoot()
  ],
  exports: [PosCreateCustomerModalComponent]
})
export class PosCreateCustomerModalModule { }
