import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentToken = this.authenticationService.readTokenFromStorage().token;
        let newRequest = request.clone();
        if (this.authenticationService.isAuthenticated()) {
            if (!request.headers.has("Authorization") && currentToken) {
                newRequest = newRequest.clone({ headers: request.headers.set(`Authorization`, `Bearer ${currentToken}`) })
            }

        }
        return next.handle(newRequest);
    }
}
