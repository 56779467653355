<div class="animated fadeIn rounded">
  <div class="card">
    <div class="card-header">
      <i class="fa fa-percent"></i> Promociones
    </div>
    <div class="card-body">
      <pos-table tableFormId="promotionTableForm" [data]="promotions" [itemsPerPage]="10"
        (dataChange)="getPromotionData($event)" [headers]="getPromotionsHeaders()" [filters]="getPromotionsFilters()"
        [disableHighlight]="true" [badgeWarningValues]="['DIRECTED','CREDIT']" [badgeSecondaryValues]="['RETAIL']"
        (rowClick)="promoRowClick($event)">
      </pos-table>
    </div>
  </div>
</div>
