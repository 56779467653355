<accordion [isAnimated]="true">
  <accordion-group [isOpen]="true">
    <span accordion-heading>
      <i class='fa fa-users'></i> Información de Cliente
    </span>
    <div class="form-group row">
      <label class="col-md-3 col-form-label"><b>Cliente</b></label>
      <div class="col-md-9">
        <input type="text" id="text-input" name="text-input" class="form-control" disabled
          [(ngModel)]="customer.fullName">
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3 col-form-label" for="text-input"><b>Vendedor</b></label>
      <div class="col-md-9">
        <input type="text" id="text-input" name="text-input" class="form-control" [(ngModel)]="employee.fullName"
          disabled>
      </div>
    </div>
  </accordion-group>
  <accordion-group [isOpen]="true">
    <span accordion-heading>
      <i class='cil-cart'></i> Orden
    </span>
    <div class="row justify-content-between">
      <div class="col-md col-sm-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-money bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3">
              {{order.totals.grossTotal | currency: 'HNL ':'symbol' : '1.2-2'}}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Sub-Total</div>
          </div>
        </div>
      </div>
      <div class="col-md col-sm-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-credit-card bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3">
              {{order.totals.discountTotal | currency: 'HNL ':'symbol' : '1.2-2'}}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Descuento</div>
          </div>
        </div>
      </div>
      <div class="col-md col-sm-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-info bg-warning p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3"> {{order.totals.taxTotal | currency: 'HNL ':'symbol' : '1.2-2'}}
            </div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Impuesto</div>
          </div>
        </div>
      </div>
      <div class="col-md col-sm-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-shopping-cart bg-success p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3"> {{order.totals.netTotal | currency: 'HNL ':'symbol' : '1.2-2'}}
            </div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Total</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <pos-table tableFormId="productsCartTable" [data]="productsCart" [headers]="getProductCartHeaders()"
          [filters]="[]" [itemsPerPage]="10" [pagination]="false" [disableHighlight]="true">
        </pos-table>
      </div>
    </div>
  </accordion-group>
  <div *ngIf="orderTypeId != 4">
  <accordion-group [isOpen]="true" *ngFor="let lp of loanParameters">
    <span accordion-heading>
      <i class='fa fa-credit-card'></i> Condiciones de crédito
    </span>
    <div class="row">
      <div class="col-6 text-left">
        <h6>Fecha primera Cuota:</h6>
      </div>
      <div class="col-6 text-left">
        {{lp.startDateRaw | date: 'longDate'}}
      </div>

    </div>
    <div class="row">
      <div class="col-6 text-left">
        <h6>Plazo:</h6>
      </div>
      <div class="col-6 text-left">
        {{lp.term}}
      </div>

    </div>
    <div class="row">
      <div class="col-6 text-left">
        <h6>Prima %:</h6>
      </div>
      <div class="col-6 text-left">
        {{lp.downPaymentPercentage | number: '1.2-2'}}%
      </div>
      <!--TODO: Fee value mas come from loan creditConditons but it's not needed for now -->
    </div>
    <div class="row">
      <div class="col-6 text-left">
        <h6>Prima:</h6>
      </div>
      <div class="col-6 text-left">
        {{lp.downPayment | currency: 'HNL ':'symbol' : '1.2-2'}}
      </div>
    </div>
    <div class="row">
      <div class="col-6 text-left">
        <h6>Cuota:</h6>
      </div>
      <div class="col-6 text-left">
        {{lp.feeAmount | currency: 'HNL ':'symbol' : '1.2-2'}}
      </div>

      <!--TODO: Fee value mas come from loan creditConditons but it's not needed for now -->
    </div>


  </accordion-group>
  </div>

  <accordion-group [isOpen]="true">
    <span accordion-heading>
      <i class='fa fa-file-text'></i> Documentos Pre-Requisitos
    </span>
    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="fiscalId"><b>Identidad</b></label>
      <div class="col-md-10">
        <input type="file" id="fiscalId" disabled>
        <label class="custom-file-label mb-2" for="fiscalId">{{fiscalIdFile?.name}}</label>
      </div>
    </div>
    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="rtnFiscalId"><b>RTN</b></label>
      <div class="col-md-10">
        <input type="file" id="rtnFiscalId" disabled>
        <label class="custom-file-label mb-2" for="rtnFiscalId">{{rtnFiscalIdFile?.name}}</label>
      </div>
    </div>
    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="prooOfPayment"><b>Comprobante de pago</b></label>
      <div class="col-md-10">
        <input type="file" id="prooOfPayment" disabled>
        <label class="custom-file-label mb-2" for="prooOfPayment">{{proofOfPaymentFile?.name}}</label>
      </div>
    </div>
    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="purchaseOrder"><b>Orden de compra</b></label>
      <div class="col-md-10">
        <input type="file" id="purchaseOrder" disabled>
        <label class="custom-file-label mb-2" for="purchaseOrder">{{purchaseOrderFile?.name}}</label>
      </div>
    </div>
    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="ip800"><b>IP-800</b></label>
      <div class="col-md-10">
        <input type="file" id="ip800" disabled>
        <label class="custom-file-label mb-2" for="ip800">{{ip800File?.name}}</label>
      </div>
    </div>
  </accordion-group>
</accordion>
<div class="form-actions" *ngIf="!isModify">
  <button type="button" [disabled]="orderTypeId == 5 || validateCreateOrder()" class="btn btn-success" (click)="createOrder()">Crear
    Orden</button>
  <button class="btn btn-primary" type="button" (click)="createQuotation()">Crear Cotización</button>
</div>
<div class="form-actions" *ngIf="isModify">
  <button type="button" class="btn btn-success" [disabled]="validateCreateOrder()" (click)="modifyOrder()">Modificar Orden</button>
  <button class="btn btn-primary" type="button" (click)="modifyQuotation()">Modificar Cotización</button>
</div>