import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysicalEstablishmentService } from 'src/app/services/physical-establishment.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { RegisterService } from 'src/app/services/register.service';
import { SalesOrgService } from 'src/app/services/sales-org.service';
import { BranchesService } from 'src/app/services/branches.service';
import { UsersService } from 'src/app/services/users.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BranchRegionService } from 'src/app/services/branch-region.service';
import { ConfigurationsComponent } from './configurations.component';



@NgModule({
  declarations: [ConfigurationsComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  providers: [
    PhysicalEstablishmentService,
    RegisterService,
    SalesOrgService,
    BranchesService,
    UsersService,
    ConfigurationService,
    BranchRegionService
  ],
  exports: [ConfigurationsComponent]
})
export class ConfigurationsModule { }
