import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Captcha } from '../models/captcha';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {
  private url = `${environment.url}/captcha`;
  constructor(private http: HttpClient) { }

  readCaptcha(): Observable<Captcha> {
    return this.http.get<Captcha>(this.url);
  }

  readIsValid(idCaptcha: string, captcha: string, origin: string): Observable<ResponseModel<boolean>> {
    let params = new HttpParams();
    params = params
      .set("idCaptcha", idCaptcha)
      .set("captcha", captcha)
      .set("origin", origin)
    return this.http.post<ResponseModel<boolean>>(`${this.url}/valid`, {}, { params });
  }
}
