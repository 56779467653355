<div class="animated fadeIn rounded">
    <div class="card bg-white">
        <div class="card-header">
            <i class="cil-cart"></i> Actualizar cliente
            <div class="card-header-actions btn-group" dropdown placement="bottom left">
          </div>
          </div>
        <div class="card-body card-body-z-index">
            <form [formGroup]="formCustomerUpdate" (ngSubmit)="updateCustomer()">
                <div class="form-row">
                    <legend class="scheduler-border">Información personal</legend>
                    <div class="col-md-3 mb-3">
                        <label>Primer Nombre</label>
                        <input class="form-control" type="text" formControlName="firstName"
                        [ngClass]="formCustomerUpdate.controls.firstName.errors
                                    ? 'is-invalid'
                                    : formCustomerUpdate.controls.firstName.value?.length > 0
                                        ? 'is-valid'
                                        : ''" 
                        />
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Segundo Nombre</label>
                        <input class="form-control" type="text" formControlName="middleName"
                        [ngClass]="formCustomerUpdate.controls.middleName.errors
                                    ? 'is-invalid'
                                    : formCustomerUpdate.controls.middleName.value?.length > 0
                                        ? 'is-valid'
                                        : ''" 
                        />
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Primer Apellido</label>
                        <input class="form-control" type="text" formControlName="firstLastName"
                        [ngClass]="formCustomerUpdate.controls.firstLastName.errors
                                    ? 'is-invalid'
                                    : formCustomerUpdate.controls.firstLastName.value?.length > 0
                                        ? 'is-valid'
                                        : ''" 
                        />
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Segundo Apellido</label>
                        <input class="form-control" type="text" formControlName="secondLastName"
                        [ngClass]="formCustomerUpdate.controls.secondLastName.errors
                                    ? 'is-invalid'
                                    : formCustomerUpdate.controls.secondLastName.value?.length > 0
                                        ? 'is-valid'
                                        : ''" 
                        />
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Estado civil</label>
                        <ng-select id="matiralStatus" [items]="maritalStatusList" bindLabel="value" bindValue="code"
                          [selectOnTab]="true" formControlName="maritalStatus">
                        </ng-select>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Correo electrónico</label>
                        <input 
                            class="form-control" 
                            type="text" 
                            formControlName="email" 
                            [ngClass]="formCustomerUpdate.controls.email.errors 
                                ? 'is-invalid' 
                                : formCustomerUpdate.controls.email.value?.length > 0
                                    ? 'is-valid'
                                    : ''"
                            />
                    </div>
                    <div class="col-md-2 mb-3">
                        <label>Celular</label>
                        <input class="form-control" type="text" formControlName="phone" [mask]="phoneMask" [dropSpecialCharacters]="false"
                            [showMaskTyped]="true" (keyup)="countryResidenceMinimunCharacters($event, 'phone')" [ngClass]="
                                formCustomerUpdate.controls.phone.errors
                                    ? 'is-invalid'
                                    : formCustomerUpdate.controls.phone.value?.length > 0
                                        ? 'is-valid'
                                        : ''" 
                        />
                        <div *ngIf="formCustomerUpdate.controls.phone.hasError('minlength')" class="text-danger">
                          El minimo de caracteres es {{ countryResidenceMinLength }}
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label>Teléfono de casa</label>
                        <input class="form-control" type="text" formControlName="phoneResidence" [mask]="phoneMask"
                            [dropSpecialCharacters]="false" [showMaskTyped]="true"
                            (keyup)="countryResidenceMinimunCharacters($event, 'phoneResidence')" />
                        <div *ngIf="formCustomerUpdate.controls.phoneResidence.hasError('minlength')" class="text-danger">
                            El minimo de caracteres es {{ countryResidenceMinLength }}
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <fieldset class="col-md-12">
                        <legend class="scheduler-border">Dirección de domicilio</legend>
                        <app-pos-customer-create-address #FormAddessCustomerPerson [streetInOneLine]="true"></app-pos-customer-create-address>
                    </fieldset>
                </div>
                <legend class="scheduler-border">Información de trabajo</legend>
                <div class="form-row">
                    <div class="col-md-2 mb-3">
                        <label>Teléfono de trabajo</label>
                        <input class="form-control" type="text" formControlName="phoneWork" [mask]="phoneMask"
                            [dropSpecialCharacters]="false" [showMaskTyped]="true"
                            (keyup)="countryResidenceMinimunCharacters($event, 'phoneWork')" />
                        <div *ngIf="formCustomerUpdate.controls.phoneWork.hasError('minlength')" class="text-danger">
                            El minimo de caracteres es {{ countryResidenceMinLength }}
                        </div>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label>Extensión</label>
                        <input class="form-control" type="text" formControlName="phoneWorkExtension" mask="0000" [showMaskTyped]="true" />
                    </div>
                    <div class="col-md-2 mb-3">
                        <label>Empresa donde trabaja</label>
                        <input class="form-control" type="text" formControlName="companyName" maxlength="50"/>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label>Departamento Donde Labora</label>
                        <input class="form-control" type="text" formControlName="workDepartment" maxlength="50"/>
                    </div>
                    <div class="col-md-2 mb-3">
                        <label>Cargo</label>
                        <input class="form-control" type="text" formControlName="workPosition" maxlength="50"/>
                    </div>
                </div>
                <div class="form-row">
                    <fieldset class="col-md-12">
                        <legend class="scheduler-border">Dirección de trabajo</legend>
                        <app-pos-customer-create-address #FormAddessWorkCustomerPerson [streetInOneLine]="true"></app-pos-customer-create-address>
                    </fieldset>
                </div>
                <legend class="scheduler-border">Archivos</legend>
                <div class="form-row">
                    <div class="col-md-3 mb-3">
                        <label>Pasaporte</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,1)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Carnet del seguro</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,2)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Carnet de residente</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,3)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Carnet de empleado</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,4)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Licencia</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,5)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Identificación (DNI)</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,6)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Nombramiento de cargo (funcionarios públicos)</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,7)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Copia de DNI valida</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,8)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>RTN</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,9)"/>
                    </div>
                    <div class="col-md-3 mb-3">
                        <label>Toma de foto insitu</label>
                        <input class="form-control" type="file" (change)="onFileChange($event,10)"/>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-2 mb-3">
                        <legend class="scheduler-border">Contactos</legend>
                    </div>
                    <div class="col-md-1 mb-3">
                        <button type="button" class="btn btn-primary form-control" (click)="showContacts()">Agregar</button>
                    </div>
                    <div class="col-md-12 mb-3">
                        <pos-table tableFormId="productsCartTable" [data]="references" [headers]="getReferencesHeaders()"
                        [filters]="[]" [itemsPerPage]="10" [pagination]="false" [disableHighlight]="true">
                        </pos-table>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-12 mb-3">
                        <label>Observaciones</label>
                        <textarea class="form-control" type="text" formControlName="observations" maxlength="1000" [ngClass]="formCustomerUpdate.controls.observations.errors 
                        ? 'is-invalid' 
                        : formCustomerUpdate.controls.observations.value?.length > 0
                            ? 'is-valid'
                            : ''"></textarea>
                    </div>
                </div>
                <div class="form-row buttons-group">
                    <div class="col-md-3" *ngIf="showBack">
                        <button type="button" class="btn btn-primary form-control" (click)="goBack()">Atras</button>
                    </div>
                    <div class="col-md-3">
                        <button type="submit" class="btn btn-primary form-control">
                            Actualizar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>