import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDialogType } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { ExonerationReason } from 'src/app/models/exoneration-reason';
import { TaxExemption } from 'src/app/models/tax-exemption';
import { ExonerationReasonService } from 'src/app/services/exoneration-reason.service';

@Component({
  selector: 'app-pos-exemption-modal',
  templateUrl: './pos-exemption-modal.component.html',
  styleUrls: ['./pos-exemption-modal.component.sass']
})
export class PosExemptionModalComponent implements OnInit {

  taxExemption: TaxExemption = new TaxExemption();
  reasonId: string = "";
  reasons: Array<ExonerationReason> = [];
  maxDate: Date = new Date();
  private confirmAction: (value: TaxExemption) => any = (value: TaxExemption) => { };
  constructor(public bsModalRef: BsModalRef, private exonerationReasonService: ExonerationReasonService, private posConfirmationService: PosConfirmationService) { }

  async ngOnInit(): Promise<void> {
    this.reasons = (await this.exonerationReasonService.readExonerations().toPromise()).response.content;
  }

  cancel() {
    this.bsModalRef.hide();
  }

  confirm() {
    this.taxExemption.reason = this.reasons.find(f => f.code == this.reasonId) || new ExonerationReason();

    this.taxExemption = TaxExemption.build(this.taxExemption.reason, this.taxExemption.documentId, this.taxExemption.date);
    this.confirmAction(this.taxExemption);
    this.bsModalRef.hide();
  }

  async remove(): Promise<void> {
    await this.posConfirmationService.show("Alerta",
      "¿Está seguro que desea eliminar la información de exoneración de la orden?",
      ModalDialogType.DANGER,
      () => {
        this.taxExemption = new TaxExemption();
        this.confirmAction(this.taxExemption);
        this.bsModalRef.hide();
      });

  }
}
