import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiscalIdTypes } from '../models/fiscalIdTypes';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class FiscalIdService {
  private url = `${environment.url}/fiscalIds`;
  
  constructor(private http: HttpClient) {

  }

  readFiscalIds():Observable<ResponseModel<Array<FiscalIdTypes>>>
  {

    return this.http.get<ResponseModel<Array<FiscalIdTypes>>>(this.url);
  }
}