<!-- Modal -->
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Busqueda de clientes</h5>
    <div>
      <button type="button" class="btn btn-primary" [routerLink]="['/customer/create']" (click)="close()">
        Crear Cliente
      </button>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <pos-customer-table (getCustomer)="handleCustomerSelect($event)"></pos-customer-table>
  </div>
</div>