<form [formGroup]="observationForm" novalidate (ngSubmit)="observationForm.valid && confirm()">
    <div class="modal-header">
        <h4 class="modal-title">Observaciones</h4>
        <button type="button" class="close" (click)="cancel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <textarea class="form-control"
    formControlName="observationText"
    rows="10"     
    [class.is-invalid]="(observationForm.get('observationText')?.touched || observationForm.get('observationText')?.dirty) && observationForm.get('observationText')?.invalid">
    </textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
        <button type="button" class="btn btn-primary" [disabled]="!observationForm.valid" (click)="confirm()">Confirmar</button>
      </div>
</form>
