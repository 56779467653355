<form #f="ngForm" novalidate (ngSubmit)="((f.valid && f.dirty) || serialNumber?.serialNumber?.trim()) && confirm()">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Series de {{product.productCode}}</h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <ng-select name="serialNumber" bindLabel="serialNumber" [items]="stocks" [(ngModel)]="serialNumber"
          [selectOnTab]="true" (change)="loadEquipment($event)" required>
        </ng-select>
      </div>
      <div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"><b>CC</b></label>
          <div class="col-md-9">
            <input type="text" id="text-input" name="text-input" class="form-control" [value]="equipment.cc" disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"><b>Denominación</b></label>
          <div class="col-md-9">
            <input type="text" id="text-input" name="text-input" class="form-control" [value]="equipment.denomination"
              disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"><b>Serie del motor</b></label>
          <div class="col-md-9">
            <input type="text" id="text-input" name="text-input" class="form-control" [value]="equipment.engineSerial"
              disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"><b>Marca</b></label>
          <div class="col-md-9">
            <input type="text" id="text-input" name="text-input" class="form-control" [value]="equipment.make" disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"><b>Placa</b></label>
          <div class="col-md-9">
            <input type="text" id="text-input" name="text-input" class="form-control" [value]="equipment.plate"
              disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"><b>Serie del chasis</b></label>
          <div class="col-md-9">
            <input type="text" id="text-input" name="text-input" class="form-control" [value]="equipment.makeId"
              disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"><b>Tipo</b></label>
          <div class="col-md-9">
            <input type="text" id="text-input" name="text-input" class="form-control" [value]="equipment.vehicle"
              disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="text-input"><b>Año</b></label>
          <div class="col-md-9">
            <input type="text" id="text-input" name="text-input" class="form-control" [value]="equipment.year || ''"
              disabled>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
      <button type="submit" class="btn btn-primary">Confirmar</button>
    </div>
  </div><!-- /.modal-content -->
</form>
