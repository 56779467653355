<div class="app-body animated fadeIn">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="card">
        <div class="card-header bg-primary">
          <i class="fa fa-street-view"></i> Cambiar contraseña
        </div>
        <div class="card-body">
          <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="icon-user"></i></span>
              </div>
              <input formControlName="username" type="text" class="form-control" placeholder="Usuario"
                autocomplete="username"
                [class.is-invalid]="(passwordForm.get('username')?.touched || passwordForm.get('username')?.dirty) && passwordForm.get('username')?.invalid">
              <div class="invalid-feedback" *ngIf="passwordForm.get('username')?.errors?.required">
                Este campo es requerido
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="icon-lock"></i></span>
              </div>
              <input formControlName="current" type="password" class="form-control" placeholder="Contraseña anterior"
                autocomplete="current-password"
                [class.is-invalid]="(passwordForm.get('current')?.touched || passwordForm.get('current')?.dirty) && passwordForm.get('current')?.invalid">
              <div class="invalid-feedback" *ngIf="passwordForm.get('current')?.errors?.required">
                Este campo es requerido
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="icon-lock"></i></span>
              </div>
              <input formControlName="new" type="password" class="form-control" placeholder="Nueva contraseña"
                autocomplete="new-password"
                [class.is-invalid]="(passwordForm.get('new')?.touched || passwordForm.get('new')?.dirty) && passwordForm.get('new')?.invalid">
              <div class="invalid-feedback" *ngIf="passwordForm.get('new')?.errors?.required">
                Este campo es requerido
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="icon-lock"></i></span>
              </div>
              <input formControlName="confirmation" type="password" class="form-control"
                placeholder="Confirmar contraseña" autocomplete="new-password"
                [class.is-invalid]="(passwordForm.get('confirmation')?.touched || passwordForm.get('confirmation')?.dirty) && passwordForm.errors?.mismatch">
              <div class="invalid-feedback">
                Las contraseñas no coinciden
              </div>
            </div>
            <pos-captcha formControlName="captcha" (getCaptchaId)="getCaptchaId($event)"></pos-captcha>
            <div class="form-actions">
              <button type="submit" class="btn btn-primary" [disabled]="!passwordForm.valid">Cambiar
                contraseña</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </main>
</div>