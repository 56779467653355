import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FiscalIdTypeRTN, ModalDialogType } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { Customer } from 'src/app/models/customer';
import { FiscalIdTypes } from 'src/app/models/fiscalIdTypes';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { CustomerService } from 'src/app/services/customer.service';
import { FiscalIdService } from 'src/app/services/fiscal-id.service';

@Component({
  selector: 'app-pos-update-rtn-fiscal-id-modal',
  templateUrl: './pos-update-rtn-fiscal-id-modal.component.html',
  styleUrls: ['./pos-update-rtn-fiscal-id-modal.component.sass']
})
export class PosUpdateRtnFiscalIdModalComponent implements OnInit {

  customer: CustomerTableModel = new CustomerTableModel();
  confirmAction: (customer: CustomerTableModel) => any = () => { };
  public fiscalId: string = "";
  fiscalIdTypes: Array<FiscalIdTypes> = [];
  maskRTN: string = "";
  constructor(public bsModalRef: BsModalRef, private customerService: CustomerService, private fiscalIdService: FiscalIdService, private confirmService: PosConfirmationService) { }

  async ngOnInit(): Promise<void> {
    this.fiscalIdTypes = (await this.fiscalIdService.readFiscalIds().toPromise()).response;
    this.maskRTN = this.fiscalIdTypes?.find((x) => x.code == FiscalIdTypeRTN)?.mask || '';
  }

  async confirm(): Promise<void> {
    await this.confirmService.show("¡Advertencía!", "¿Está seguro de actualizar el RTN?", ModalDialogType.WARNING, async () => {
      await this.customerService.updateRtnFiscalId(this.customer.code, this.fiscalId).toPromise();
      this.customer.fiscalId2 = this.fiscalId;
      this.confirmAction(this.customer);
      this.bsModalRef.hide();
    })

  }

  cancel() {
    this.bsModalRef.hide();
  }
}
