<div class="animated fadeIn rounded">
  <div class="card">
    <div class="card-header">
      <i class="cil-info"></i> Detalle de Promoción {{promotion.code}}
    </div>
    <div class="card-body">

      <accordion [isAnimated]="true">
        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-chevron-down'></i> Información General
          </span>

          <div class="row">
            <div class="col-2 text-left">
              <h6>Código:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.code}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Descripción:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.description}}
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-2 text-left">
              <h6>Tipo:</h6>
            </div>
            <div class="col-4 text-left">
              <span [ngClass]="{'badge large-font-size':true, 
              'badge-success': getTypeSuccessClass(promotion.type), 
              'badge-warning': getTypeWarningClass(promotion.type),
              'badge-danger': getTypeDangerClass(promotion.type),
              'badge-secondary': getTypeSecondaryClass(promotion.type)
            }">{{getType(promotion.type)}}</span>

            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Tipo de Orden:</h6>
            </div>
            <div class="col-4 text-left">
              <span [ngClass]="{'badge large-font-size':true, 
              'badge-success': getPaymentTypeSuccessClass(promotion.paymentType), 
              'badge-warning': getPaymentTypeWarningClass(promotion.paymentType),
              'badge-danger': getPaymentTypeDangerClass(promotion.paymentType),
              'badge-secondary': getPaymentTypeSecondaryClass(promotion.paymentType)
            }">{{getPaymentType(promotion.paymentType)}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Moneda:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.currency}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Fecha Inicial:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.starDateRaw | date:'medium'}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Fecha Final:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.endDateRaw | date:'medium'}}
            </div>
          </div>
        </accordion-group>
        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-chevron-down'></i> Sociedades de Venta
          </span>
          <pos-list [data]="salesOrgs" [pagination]="false" [config]="{title:'code'}"></pos-list>
        </accordion-group>
        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-chevron-down'></i> Información de Crédito
          </span>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Tipo de Promoción Crédito:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.creditPromoType}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Edad mínima:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.minAge || 0}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Edad máxima:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.maxAge || 0}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Plazo Mínimo:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.buy?.minTerm || 0}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Plazo Máximo:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.buy?.maxTerm || 0}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Tasa:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.buy?.rate || 0 | number: '1.2-2'}}%
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Prima Mínima:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.buy?.downPayment || 0 | number: '1.2-2'}}%
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Periodo de Gracia (días):</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.buy?.gracePeriod || 0}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Con Interés:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.buy?.noInterest ? "Si" : "No"}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Mora Mínima:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.minArrears || 0}}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Mora Máxima:</h6>
            </div>
            <div class="col-4 text-left mb-1">
              {{promotion.maxArrears || 0}}
            </div>
          </div>


        </accordion-group>
        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-chevron-down'></i> Procedimientos de Crédito:
          </span>
          <pos-list [data]="creditProcedures" [pagination]="false" [config]="{title:'code'}"></pos-list>
        </accordion-group>
        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-chevron-down'></i> Promociones Accumables
          </span>
          <pos-list [data]="accumulableWith" [pagination]="false"></pos-list>
        </accordion-group>
        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-chevron-down'></i> Condiciones de Compra
          </span>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Mínimo de Compra:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.buy?.totalMinimum || 0 | number: '1.2-2'}}
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-2 text-left">
              <h6>Máximo de Compra:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.buy?.totalMaximum || 0 | number: '1.2-2'}}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <accordion-group>
                <span accordion-heading>
                  <i class='fa fa-chevron-down'></i> Productos
                </span>
                <pos-list [data]="buyProducts" [pagination]="false" [config]="{title:'code'}"></pos-list>
              </accordion-group>
            </div>
          </div>
        </accordion-group>
        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-chevron-down'></i> Obtener
          </span>
          <div class="row">
            <div class="col-2 text-left">
              <h6>Descuento Total de Orden:</h6>
            </div>
            <div class="col-4 text-left">
              {{promotion.get?.totalDiscount?.discountValue || 0 | number: '1.2-2'}}
              <!-- {{promotion.get?.totalDiscount?.discountType == "PERCENTAGE" ? "%" : "" }} -->
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-2 text-left">
              <h6>Tipo de Descuento de Orden:</h6>
            </div>
            <div class="col-4 text-left">
              {{getDiscountType(promotion.get?.totalDiscount?.discountType)}}
            </div>
          </div>
          <accordion-group [isOpen]="true">
            <span accordion-heading>
              <i class='fa fa-chevron-down'></i> Condiciones
            </span>
            <div class="row">
              <div class="col-2 text-left">
                <h6>Descuento:</h6>
              </div>
              <div class="col-4 text-left">
                {{getPromotionCondition().discount?.discountValue || 0 | number: '1.2-2'}}
                <!-- {{promotion.get?.totalDiscount?.discountType == "PERCENTAGE" ? "%" : "" }} -->
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-2 text-left">
                <h6>Tipo de Descuento:</h6>
              </div>
              <div class="col-4 text-left">
                {{getDiscountType(getPromotionCondition().discount?.discountType)}}
              </div>
            </div>
            <accordion-group>
              <span accordion-heading>
                <i class='fa fa-chevron-down'></i> Productos
              </span>
              <pos-list [data]="getProducts" [pagination]="false" [config]="{title:'code'}"></pos-list>
            </accordion-group>
          </accordion-group>

        </accordion-group>
      </accordion>
    </div>
  </div>
</div>
