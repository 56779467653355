import { Discount } from "../discount";
import { Price } from "../price";
import { Tax } from "../tax";

export class OrderEntryRequest {
    code: string;
    productCode: string;
    productName: string;
    tax: Tax;
    taxProduct: Tax;
    serialNumber: string;
    associatedSerialNumber: string;
    price: Price;
    discounts: Array<Discount>;
    totalQuantity: number;
    quantityUnit: string;
    services: Array<string>;
    isForDelivery: boolean;
    rateType: string;
    rate: number | null;
    downPayment: number | null;
    term: number | null;
    minTerm: number | null;
    maxTerm: number | null;
    deliveryBranch: string;
    mobileRechargePhone: string;
    creditPromotionCode: string;
    extraWarrantyInvoiceCode: string;
    extraWarrantyInvoiceLine: string;
    associatedOrder: string;
    associatedOrderEntry: string;
    isPriceChange: boolean;
    setCode: string;
    serialProfile: boolean;


    constructor() {
        this.code = "";
        this.productCode = "";
        this.productName = "";
        this.tax = new Tax();
        this.taxProduct = new Tax();
        this.serialNumber = "";
        this.associatedSerialNumber = "";
        this.price = new Price();
        this.discounts = [];
        this.totalQuantity = 0.0;
        this.quantityUnit = "";
        this.services = [];
        this.isForDelivery = false;
        this.rateType = "";
        this.rate = 0.0;
        this.downPayment = 0.0;
        this.term = 0.0;
        this.minTerm = 0.0;
        this.maxTerm = 0.0;
        this.deliveryBranch = "";
        this.mobileRechargePhone = "";
        this.creditPromotionCode = "";
        this.extraWarrantyInvoiceCode = "";
        this.extraWarrantyInvoiceLine = "";
        this.associatedOrder = "";
        this.associatedOrderEntry = "";
        this.isPriceChange = false;
        this.setCode = "";
        this.serialProfile = false;
    }
}