<div class="animated fadeIn rounded">
    <div class="card">
      <div class="card-header">
        <i class="cil-object-group"></i> Cola de trabajo
      </div>
      <div class="card-body">
        <pos-table tableFormId="backOfficeOrderTable" [headers]="getOrderTableColumns()" [filters]="getOrderFilterColumns()"
          [data]="orders" 
          [badgeSuccessValues]="['FINAL','REGULAR','DONE','WAITING_FOR_CLIENT','NA']"
          [badgeWarningValues]="['INVOICEABLE','credito','UNPROCESSED','PROCESSING']"
          [badgeDangerValues]="['DELETED','CANCELLED','REJECTED','FAILED','SIGNATURE_FAILED']" [itemsPerPage]="10" (dataChange)="getOrderData($event)">
        </pos-table>
      </div>
    </div>
  </div>