import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DigitalSignatureRequestStatusInOrder, OrderStatus } from 'src/app/@core/constants';
import { Branch } from 'src/app/models/branch';
import { ColumnTable } from 'src/app/models/column-table';
import { Filter } from 'src/app/models/filter';
import { FilterTable } from 'src/app/models/filter-table';
import { BranchFilter } from 'src/app/models/filters/branch-filter';
import { OrderFilter } from 'src/app/models/filters/order-filter';
import { Order } from 'src/app/models/order';
import { Paging } from 'src/app/models/page';
import { Region } from 'src/app/models/region';
import { BranchesService } from 'src/app/services/branches.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-back-office-order-list',
  templateUrl: './back-office-order-list.component.html',
  styleUrls: ['./back-office-order-list.component.sass']
})
export class BackOfficeOrderListComponent implements OnInit {

  public orders: Paging<Array<Order>> = new Paging([]);
  public branches: Array<Branch> = [];

  constructor(
    private orderService: OrderService,
    private branchService: BranchesService,
    private datePipe: DatePipe,
    private router: Router,
    private configutationService: ConfigurationService) { }

  async ngOnInit(): Promise<void> {
    await this.getOrderData({ page: 0, pageSize: 10, value: {} });
    await this.loadBranches();
  }

  getOrderTableColumns(): Array<ColumnTable> {

    return [
      new ColumnTable('code', "Número de Orden"),
      new ColumnTable('fullName', "Cliente", { isSmall: true }),
      new ColumnTable('vendorName', "Vendedor", { isSmall: true }),
      new ColumnTable('timeStamp', "Fecha de creación", { type: "date" }),
      new ColumnTable('status', "Estado de la Orden", { type: "badge", valueMap: this.getStatusMap() }),
      new ColumnTable('condition', "Condición", { type: "badge", valueMap: this.getConditionMap() }),
      new ColumnTable('orderType', "Tipo de Orden", { type: "badge", valueMap: this.getOrderTypeMap() }),
      new ColumnTable('processingStatusDigitalSignature', "Estado de firma digital", { type: "badge", valueMap: this.getDigitalSignatureStatusMap() }),
      new ColumnTable('salesOrgCode', "Organización"),
      new ColumnTable('branchCode', "Sucursal"),
      new ColumnTable('netTotal', "Monto", { type: "currency" }),
      new ColumnTable("code",
        "Opciones",
        {

          type: "action",
          actions: [
            {
              fn: (order: Order) => {
                this.router.navigate(['backOfficeOrders', order.code],
                  {
                    state: {
                      order
                    }
                  })
              },
              icon: "fa fa-check-circle-o text-success",
              tooltip: "Aprobación de orden",
              isDisabled: false
            }
          ]
        }
      )
    ];
  }

  getOrderFilterColumns(): Array<FilterTable> {
    return [
      new FilterTable('text', "Búsqueda General"),
      new FilterTable('invoiceNumber', "Número de Factura"),
      new FilterTable('customerCode', "Código de Cliente"),
      new FilterTable('sellerCode', "Código de Vendedor"),
      new FilterTable('branchCode', "Sucursal", { type: "combobox", comboBoxFilterValues: this.getBranchesMap() }),
      new FilterTable('status', "Estado de Orden", { type: "combobox", comboBoxFilterValues: this.getStatusMap() }),
      new FilterTable('condition', "Condición", { type: "combobox", comboBoxFilterValues: this.getConditionMap() }),
      new FilterTable("start", "Fecha Inicial", { type: "date" }),
      new FilterTable("end", "Fecha Final", { type: "date" }),
      new FilterTable('digitalSignatureStatus', "Estado de firma digital", { type: "combobox", comboBoxFilterValues: this.getDigitalSignatureStatusMap() }),
    ];
  }

  private getDigitalSignatureStatusMap(): Map<string, string> {
    let result = new Map<string, string>();

    result.set(DigitalSignatureRequestStatusInOrder.UNPROCESSED, "No procesado");
    result.set(DigitalSignatureRequestStatusInOrder.PROCESSING, "Procesando");
    result.set(DigitalSignatureRequestStatusInOrder.FAILED, "Firma fallida");
    result.set(DigitalSignatureRequestStatusInOrder.WAITING_FOR_CLIENT, "Esperando por cliente");
    result.set(DigitalSignatureRequestStatusInOrder.DONE, "Firmado");
    result.set(DigitalSignatureRequestStatusInOrder.NA, "No aplica firma");

    return result;
  }

  private async loadBranches() {
    let regions = this.configutationService.readRegionCodesList();
    let branchFilter: BranchFilter = {};
    branchFilter.regionCodes = regions;
    branchFilter.isFranchised = true;
    branchFilter.salesOrg = this.configutationService.readSelectedBranchAndSalesOrg().salesOrg.code;

    this.branches = (await this.branchService
      .readFilteredBranches(branchFilter).toPromise()).response.content;
  }


  private getBranchesMap(): Map<string, string> {
    let result = new Map<string, string>();
    this.branches.map(branch => {
      result.set(branch.code, `[${branch.code}] ${branch.name}`);
    });
    return result;
  }

  private getOrderTypeMap(): Map<string, string> {
    let result = new Map<string, string>();

    result.set("REGULAR", "Regular");
    return result;
  }

  async getOrderData(params: Filter<OrderFilter>): Promise<void> {
    params.value.start = this.datePipe.transform(params.value.start, "yyyy-MM-dd HH:mm:ssZ") || params.value.start;
    params.value.end = this.datePipe.transform(params.value.end, "yyyy-MM-dd 23:59:59Z") || params.value.end;

    params.value.status = params.value.status || OrderStatus.FINAL_INVOICEABLE;

    let regionCodes = "";
    this.configutationService.readRegion().map(r => { regionCodes = `${regionCodes}, ${r.code}` });
    params.value.regionCode = regionCodes;

    let salesOrgs = "";
    this.configutationService.readSalesOrg().map(salesOrg => {
      salesOrgs = `${salesOrgs}, ${salesOrg.code}`
    });

    params.value.salesOrgList = salesOrgs;
    params.value.isFranchised = true;
    this.orders = (await this.orderService.readOrders(params).toPromise()).response;
  }

  private getStatusMap(): Map<string, string> {
    let result = new Map<string, string>();

    result.set("INVOICEABLE", "Facturable");
    result.set("FINAL", "Facturado");
    result.set("REJECTED", "Rechazado");
    return result;
  }

  private getConditionMap(): Map<string, string> {
    let result = new Map<string, string>();

    result.set("contado", "Contado");
    result.set("credito", "Crédito");

    return result;
  }

}
