import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosStepperComponent } from './pos-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';



@NgModule({
  declarations: [PosStepperComponent],
  imports: [
    CommonModule,
    CdkStepperModule
  ],
  exports: [PosStepperComponent]
})
export class PosStepperModule { }
