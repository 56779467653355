<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Agregar contacto</h4>
    <button type="button" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <form [formGroup]="formContact" (ngSubmit)="addContact()">
        <div class="form-row">
            <legend class="scheduler-border">Información personal</legend>
            <div class="col-md-3 mb-3">
                <label>Primer Nombre</label>
                <input class="form-control" type="text" formControlName="firstName"
                [ngClass]="formContact.controls.firstName.errors
                            ? 'is-invalid'
                            : formContact.controls.firstName.value?.length > 0
                                ? 'is-valid'
                                : ''" 
                />
            </div>
            <div class="col-md-3 mb-3">
                <label>Segundo Nombre</label>
                <input class="form-control" type="text" formControlName="middleName"
                [ngClass]="formContact.controls.middleName.errors
                            ? 'is-invalid'
                            : formContact.controls.middleName.value?.length > 0
                                ? 'is-valid'
                                : ''" 
                />
            </div>
            <div class="col-md-3 mb-3">
                <label>Primer Apellido</label>
                <input class="form-control" type="text" formControlName="firstLastName"
                [ngClass]="formContact.controls.firstLastName.errors
                            ? 'is-invalid'
                            : formContact.controls.firstLastName.value?.length > 0
                                ? 'is-valid'
                                : ''" 
                />
            </div>
            <div class="col-md-3 mb-3">
                <label>Segundo Apellido</label>
                <input class="form-control" type="text" formControlName="secondLastName"
                [ngClass]="formContact.controls.secondLastName.errors
                            ? 'is-invalid'
                            : formContact.controls.secondLastName.value?.length > 0
                                ? 'is-valid'
                                : ''" 
                />
            </div>
            <div class="col-md-3 mb-3">
                <label>Relacion</label>
                <ng-select id="naturalRelation" [items]="naturalRelationList" bindLabel="value" bindValue="code"
                  [selectOnTab]="true" formControlName="naturalRelation">
                </ng-select>
            </div>
            <div class="col-md-3 mb-3">
                <label>Correo electrónico</label>
                <input 
                    class="form-control" 
                    type="text" 
                    formControlName="email" 
                    [ngClass]="formContact.controls.email.errors 
                        ? 'is-invalid' 
                        : formContact.controls.email.value?.length > 0
                            ? 'is-valid'
                            : ''"
                    />
            </div>
            <div class="col-md-2 mb-3">
                <label>Celular</label>
                <input class="form-control" type="text" formControlName="phone" [mask]="phoneMask" [dropSpecialCharacters]="false"
                    [showMaskTyped]="true" [ngClass]="
                        formContact.controls.phone.errors
                            ? 'is-invalid'
                            : formContact.controls.phone.value?.length > 0
                                ? 'is-valid'
                                : ''" 
                />
                <div *ngIf="formContact.controls.phone.hasError('minlength')" class="text-danger">
                  El minimo de caracteres es {{ countryResidenceMinLength }}
                </div>
            </div>
            <div class="col-md-2 mb-3">
                <label>Teléfono de casa</label>
                <input class="form-control" type="text" formControlName="phoneResidence" [mask]="phoneMask"
                    [dropSpecialCharacters]="false" [showMaskTyped]="true" />
                <div *ngIf="formContact.controls.phoneResidence.hasError('minlength')" class="text-danger">
                    El minimo de caracteres es {{ countryResidenceMinLength }}
                </div>
            </div>
            <div class="col-md-2 mb-3">
                <label>Teléfono de trabajo</label>
                <input class="form-control" type="text" formControlName="workPhoneNumber" [mask]="phoneMask"
                    [dropSpecialCharacters]="false" [showMaskTyped]="true" />
                <div *ngIf="formContact.controls.workPhoneNumber.hasError('minlength')" class="text-danger">
                    El minimo de caracteres es {{ countryResidenceMinLength }}
                </div>
            </div>
            
            <div class="col-md-2 mb-3">
              <label>Extensión</label>
              <input class="form-control" type="text" formControlName="phoneResidenceExtension" mask="0000" [showMaskTyped]="true" />
          </div>
        </div>

        <div class="form-row buttons-group">
            <div class="col-md-3">
                <button type="submit" class="btn btn-primary form-control">
                    Agregar
                </button>
            </div>
        </div>
    </form>
  </div>
</div>