<div class="card">
  <div class="card-header">
    <i class="fa fa-street-view"></i> Solicitudes de crédito en proceso
  </div>
  <div class="card-body card-body-z-index">
    <pos-table tableFormId="creditRequestsTable" [data]="creditRequests" [headers]="getCreditHeaders()"
      (dataChange)="getCreditsData($event)" [itemsPerPage]="10" (rowClick)="selectCredit($event)">
    </pos-table>
  </div>
</div>
