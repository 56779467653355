import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosStockValidateModalComponent } from './pos-stock-validate-modal.component';



@NgModule({
  declarations: [PosStockValidateModalComponent],
  imports: [
    CommonModule
  ]
})
export class PosStockValidateModalModule { }
