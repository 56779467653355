<div class="card">
  <div class="card-body card-body-z-index">

    <div class="form-row">
      <div class="col-md col-sm-4">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-credit-card bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3">
              {{( totalAmount > paymentsAmount ? (totalAmount - paymentsAmount) : 0.00) |
              currency:'HNL':'code':'1.0-2'}}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Por pagar:</div>
          </div>
        </div>
      </div>
      <div class="col-md col-sm-4">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-credit-card-alt bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3">
              {{ paymentsAmount | currency:'HNL':'code':'1.0-2' }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Pagado:</div>
          </div>
        </div>
      </div>
      <div class="col-md col-sm-4">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-money bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3">
              {{ restCashPayment | currency:'HNL':'code':'1.0-2'}}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Cambio:</div>
          </div>
        </div>
      </div>

    </div>

    <div class="horizontal-padding">
      <h3>Detalle de pagos:</h3>
      <pos-table [data]="paymentList" [headers]="getPaymentHeaders()" [disableHighlight]="true"></pos-table>
    </div>

    <div class="buttons-container">
      <div class="button btn btn-primary" *ngFor="let payment of directPaymentMethods" (click)="addPayment(payment)">
        {{ payment.name }}
      </div>

    </div>

    <div class="other-payments">
      <select id="payment" name="payment" class="form-control" [(ngModel)]="otherPayment"
        (ngModelChange)="addPayment(otherPayment)">
        <option [value]="{}" disabled="true">Otros metodos de pago</option>
        <option *ngFor="let payment of otherPaymentMethods" [ngValue]="payment">{{payment.name}}</option>
      </select>
    </div>

    <div class="offset-sm-8 col-sm-4 other-payments" [hidden]="disablePaymentButton">
      <button type="submit" class="btn btn-primary form-control" (click)="pay()">
        Pagar
      </button>
    </div>
  </div>
</div>