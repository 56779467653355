import { RegisterStatus } from "../@core/constants";

export class Register {
    closeTimeStamp: Date
    code: string
    currency: string
    defaultCash: number
    failedToClose: boolean
    lastPeriod: string
    name: string
    openTimeStamp: Date
    openingTotals: Map<string, number>
    operator: string
    physicalEstablishmentCode: string
    status: RegisterStatus
    totals: Map<string, number>
    constructor() {
        this.closeTimeStamp = new Date(0);
        this.code = "";
        this.currency = "";
        this.defaultCash = 0;
        this.failedToClose = false;
        this.lastPeriod = "";
        this.name = "";
        this.openTimeStamp = new Date(0);
        this.openingTotals = new Map<string, number>();
        this.operator = "";
        this.physicalEstablishmentCode = "";
        this.status = RegisterStatus.INACTIVE;
        this.totals = new Map<string, number>()
    }
}