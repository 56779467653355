import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDialogType } from 'src/app/@core/constants';
import { PaymentMethod } from 'src/app/models/payment-method';
import { CustomerUpdateContact } from 'src/app/models/request/CustomerUpdateContact';
import { AddContactModalComponent } from 'src/app/shared/pos-customer-update/add-contact-modal/add-contact-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AddContactModalService {
  private modalRef?: BsModalRef;
  private resolve: any;
  constructor(private modalService: BsModalService) { }

  show(
    confirmAction: (customerUpdateContact: CustomerUpdateContact) => any): Promise<CustomerUpdateContact> {
    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(AddContactModalComponent, {
        ignoreBackdropClick: true,
      });
      this.modalRef.setClass(`modal-lg ${ModalDialogType.PRIMARY}`);
      this.modalRef.content.confirmAction = confirmAction;
    });
  }
}
