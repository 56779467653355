import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Customer } from 'src/app/models/customer';
import { CreditStatusFranchisedResponseModel } from 'src/app/models/response/customerCreditStatusResponseModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CreditService } from 'src/app/services/credit.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrderConditions } from 'src/app/@core/constants';
import { OrderType } from 'src/app/models/order-type';
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-order-type-step',
  templateUrl: './order-type-step.component.html',
  styleUrls: ['./order-type-step.component.sass']
})
export class OrderTypeStepComponent implements OnInit, OnChanges {

  @Input() customerCode: string = "";
  @Input() actualBranch: Branch = new Branch();
  @Input() order: Order | null = null;
  @Output() selectOrderType = new EventEmitter<number>();
  customer: Customer = new Customer();
  customerCreditConditions: CreditStatusFranchisedResponseModel = new CreditStatusFranchisedResponseModel();
  branchCreditConditions: CreditStatusFranchisedResponseModel = new CreditStatusFranchisedResponseModel();
  isCreditLineActive: boolean = false;
  isBranchCreditLineActive: boolean = false;
  orderTypes: OrderType[] = [
    new OrderType(OrderConditions.UNTIL_30_DAYS_FRANCHISED.toString(), "Crédito a 30 días con responsable de pago franquiciado", true, false, true),
    new OrderType(OrderConditions.MORE_THAN_30_DAYS_FRANCHISED.toString(), "Financiamiento con responsable de pago franquiciado", true, false, true),
    new OrderType(OrderConditions.FINAL_CUSTOMER_CREDIT.toString(), "Financiamiento a cliente final", false, true, false),
    new OrderType(OrderConditions.CASH_BILLLING.toString(), "Contado", false, false, false),
    new OrderType(OrderConditions.CREDIT_QUOTATION.toString(), "Cotización de crédito con condiciones de referencia", false, false, false)
  ];
  availableOrderTypes: OrderType[] = [];
  selectedOrderTypeId: string = "0";

  constructor(
    private configurationService: ConfigurationService,
    private authenticationService: AuthService,
    private creditService: CreditService,
    private customerService: CustomerService,
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // console.log(`en el onchange de componente de tipo de orden`,this.customer,this.customerCode);
    // console.log(`changes:`,changes);


    if (this.customer.code !== this.customerCode || (this.order?.code !== '' && this.order?.orderCondition !== 0)) {
      await this.fetchCustomerInfo();
      await this.fetchCustomerCreditInfo();
      await this.fetchBranchFranchisedCreditInfo();

      this.filterOrderTypes();

    }
  }

  ngOnInit(): void { }

  // async ngOnChanges(): Promise<void> {
  //  
  // }

  private async fetchCustomerCreditInfo(): Promise<void> {
    const { username } = this.authenticationService.readProfileInStorage();

    this.customerCreditConditions = await this.creditService
      .getCustomerCreditStatus(this.customer, this.actualBranch.code, username)
      .toPromise();

    this.isCreditLineActive = this.customerCreditConditions.creditLineActive;
  }

  private async fetchBranchFranchisedCreditInfo(): Promise<void> {
    if (this.actualBranch && this.actualBranch.franquicia) {
      const { username } = this.authenticationService.readProfileInStorage();
      const branchCustomer = (await this.customerService.readCustomerByCode(this.actualBranch.franquicia.code).toPromise());

      this.branchCreditConditions = await this.creditService
        .getCustomerCreditStatus(branchCustomer, this.actualBranch.code, username)
        .toPromise();

      this.isBranchCreditLineActive = this.branchCreditConditions.creditLineActive;
    } else {
      this.branchCreditConditions = new CreditStatusFranchisedResponseModel();
      this.isBranchCreditLineActive = false;
    }
  }

  private async fetchCustomerInfo(): Promise<void> {
    this.customer = await this.customerService
      .readCustomerByCode(this.customerCode)
      .toPromise();
  }

  private filterOrderTypes(): void {
    this.availableOrderTypes = this.orderTypes.filter(ot =>
      this.validPaymentResponsible(ot) && this.validCreditLine(ot) && this.validBranchCreditLine(ot)
    );
    // console.log(this.availableOrderTypes, `estos son los tipos de ordenes disponibles`);

    if (this.availableOrderTypes && this.availableOrderTypes.length) {
      // console.log(`esta es la orden`,this.order);

      if (this.order !== null) {
        this.selectedOrderTypeId = this.order.orderCondition?.toString() || '';
      } else {

        this.selectedOrderTypeId = this.availableOrderTypes[0].id;
      }
      this.sendOrderType();
    }
  }

  validPaymentResponsible(orderType: any) {
    return !orderType.hasPaymentResponsible || (this.actualBranch.franquicia && this.actualBranch.franquicia.code);
  }

  validCreditLine(orderType: any) {
    return !orderType.hasCreditLineActive || this.isCreditLineActive;
  }

  validBranchCreditLine(orderType: any) {
    return !orderType.hasBranchCreditLineActive || this.isBranchCreditLineActive;
  }

  async sendOrderType() {
    // console.log(`emitiendo el tipo de orden seleccionado ${this.selectedOrderTypeId}`);
    if (this.selectedOrderTypeId == "3") {
      await this.creditService.validatePastContracts(this.customerCode).toPromise().catch(e => {

      });
    }
    this.selectOrderType.emit(+this.selectedOrderTypeId);
  }
}
