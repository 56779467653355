import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDialogType, ParamPhoneMaskHn, REGEX } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { FileUtils } from 'src/app/@core/utils/file-utils';
import { City } from 'src/app/models/city';
import { Colony } from 'src/app/models/colony';
import { Ip800 } from 'src/app/models/ip800';
import { Region } from 'src/app/models/region';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { CityService } from 'src/app/services/city.service';
import { ColonyService } from 'src/app/services/colony.service';
import { Ip800Service } from 'src/app/services/ip800.service';
import { ParamsService } from 'src/app/services/params.service';
import { RegionService } from 'src/app/services/region.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-pos-ip800-modal',
  templateUrl: './pos-ip800-modal.component.html',
  styleUrls: ['./pos-ip800-modal.component.sass']
})
export class PosIp800ModalComponent implements OnInit {
  @Input()
  customer: CustomerTableModel = new CustomerTableModel();
  private confirmAction: (value: Blob) => any = (value: Blob) => { };
  regions: Array<Region> = new Array<Region>();
  cities: Array<City> = new Array<City>();
  colonies: Array<Colony> = new Array<Colony>();

  region: Region = new Region();
  city: City = new City();
  colony: Colony = new Colony();

  ip800Form: FormGroup = this.formBuilder.group({
    name: new FormControl(),
    region: new FormControl("", Validators.required),
    city: new FormControl("", Validators.required),
    colony: new FormControl("", Validators.required),
    streetOrAvenue: new FormControl("", Validators.required),
    blockNumber: new FormControl(),
    houseNumber: new FormControl("", Validators.required),
    cellPhone: new FormControl("", [Validators.required]),
    otherPhone: new FormControl(""),
    email: new FormControl("", Validators.pattern(REGEX.email))
  });

  params: { [value: string]: string } = {};
  phoneMask: string = "";
  ip800: Ip800 = new Ip800();
  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private regionService: RegionService, private cityService: CityService, private colonyService: ColonyService, private paramsService: ParamsService, private ip800Service: Ip800Service, private confirmService: PosConfirmationService, private reportService: ReportService) {

  }

  async ngOnInit(): Promise<void> {

    //await this.load();


  }

  async load() {
    this.ip800 = (await this.ip800Service.readIP800Requests(this.customer.code).toPromise()).response.content[0] || new Ip800();

    if (this.customer.code && this.ip800.code) {
      this.confirmService.show("Solicitud IP800", "Ya existe una solicitud IP800 para este cliente, ¿Desea utilizar la existente?", ModalDialogType.SUCCESS, () => {
        this.confirm(this.ip800.code);
      }, async () => {
        await this.loadForms();
      })
    }
    else {
      await this.loadForms();
    }
  }

  async loadForms() {
    this.params = (await this.paramsService.readParams().toPromise()).response;
    this.ip800Form.get("name")?.disable();
    await this.getRegion();
    this.phoneMask = this.params[ParamPhoneMaskHn]
    this.ip800Form.get("name")?.setValue(this.customer.fullName);
  }

  async getRegion() {
    //TODO: Customer Nationality filter
    this.regions = (await (await this.regionService.readRegions("HN")).toPromise()).response.content;
    this.regions = this.regions.sort((a, b) => a.name.localeCompare(b.name));

    this.cities = new Array<City>();
    this.colonies = new Array<Colony>();
  }

  async changeRegion(region: Region) {
    await this.getCities(region.country, region.region);
  }

  async getCities(countrySelected: string, regionSelected: string) {
    this.colonies = new Array<Colony>();
    this.cities = (await this.cityService.readCities(countrySelected, regionSelected).toPromise()).response.content;
    this.cities = this.cities.sort((a, b) => a.name.localeCompare(b.name));
  }

  async changeCity(region: City) {
    await this.getColonies(region.cityCode);
  }

  async getColonies(citySelected: string) {
    this.colonies = (await this.colonyService.readColonies(citySelected).toPromise()).response.content;
  }

  async createIp800() {
    let result = new Ip800();

    result.customerCode = this.customer.code;
    result.creationDate = new Date();
    result.city = (this.ip800Form.get("region")?.value as Region).name;
    result.department = (this.ip800Form.get("region")?.value as Region).name;
    result.municipality = (this.ip800Form.get("city")?.value as City).name;
    result.neighbourhoodColonyOrvillage = (this.ip800Form.get("colony")?.value as Colony).name;
    result.streetOrAvenue = this.ip800Form.get("streetOrAvenue")?.value;
    result.blockNumber = this.ip800Form.get("blockNumber")?.value;
    result.houseNumber = this.ip800Form.get("houseNumber")?.value;
    result.cellularPhone = this.ip800Form.get("cellPhone")?.value;
    result.otherPhone = this.ip800Form.get("otherPhone")?.value;
    result.emailAddress = this.ip800Form.get("email")?.value;


    if (!this.ip800.code) {
      result = (await this.ip800Service.createIP800(result).toPromise()).response;
    }
    else {
      result.code = this.ip800.code;
      await this.ip800Service.updateIP800(result).toPromise();
    }

    this.confirm(result.code);
  }

  private async confirm(code: string) {
    let pdf = await this.reportService.ip800RequestPdf(code).toPromise() as Blob;
    this.confirmAction(pdf);
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide()
  }
}
