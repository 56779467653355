import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';
import { Tax } from '../models/tax';

@Injectable({
  providedIn: 'root'
})
export class TaxesService {
  private url = `${environment.url}/taxes`;
  constructor(private http: HttpClient) { }

  readTaxes(): Observable<ResponseModel<Paging<Array<Tax>>>> {
    let params = new HttpParams();
    params = params
      .set("page", "0")
      .set("pageSize", "1000");
    return this.http.get<ResponseModel<Paging<Array<Tax>>>>(this.url, { params });

  }
}
