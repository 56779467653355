import { OrderConditions } from "src/app/@core/constants";
import { Bomb } from "../bomb";
import { Branch } from "../branch";
import { CustomerCreditValidation } from "../customer-credit-validation";
import { Order } from "../order";
import { SalesOrg } from "../sales-org";
import { LoanFormRequest } from "./loan-form-request";

export class LoanSimulationRequest {

    salesOrg: string;
    customerCode: string;
    amount: number;
    currency: string;
    branchCode: string;
    promotion: string;
    diluted: Boolean;
    startDate: string;
    startDateRaw: Date;
    bombs: Array<Bomb>;
    rate: string;
    downPayment: number;
    downPaymentPercentage: number;
    term: string;
    calculatedDate: string;
    rateType: string;
    cashLoan: Boolean;
    orderCode?: string;
    gracePeriod: number;
    noInterest: Boolean;
    sellerCode: string;
    intermediaries: Array<string>;
    isAgreementCustomer: Boolean;
    isThirtyDaysOrder: Boolean;
    feeAmount?: number;
    loanCode?: string;
    minTerm?: number;
    maxTerm?: number;
    minDownPayment?: number;
    maxDownPayment?: number;

    constructor() {
        this.salesOrg = "";
        this.customerCode = "";
        this.amount = 0.0;
        this.currency = "HNL";
        this.branchCode = "";
        this.promotion = "";
        this.diluted = false;
        this.startDate = "";
        this.startDateRaw = new Date();
        this.bombs = [];
        this.rate = "";
        this.downPayment = 0.0;
        this.downPaymentPercentage = 0.0;
        this.term = "";
        this.calculatedDate = "";
        this.rateType = "";
        this.cashLoan = false;
        this.gracePeriod = 0;
        this.noInterest = false;
        this.sellerCode = "";
        this.intermediaries = [];
        this.isAgreementCustomer = false;
        this.isThirtyDaysOrder = false;
        this.feeAmount = 0;
        this.loanCode = "";
    }

    static buildRequest(
        order: Order,
        customerCreditConditions: CustomerCreditValidation,
        loanFormValues: LoanFormRequest,
        config: { salesOrg: SalesOrg, branch: Branch },
        intermediaries?: Array<string>,
        isMoreThanThirtyDays?: boolean,
        financedAmount?: number,
        rate?: string,
    ): LoanSimulationRequest {

        let result = new LoanSimulationRequest();

        if (isMoreThanThirtyDays === true) {
            result.salesOrg = config.salesOrg.code;
            result.customerCode = order.orderCondition == OrderConditions.MORE_THAN_30_DAYS_FRANCHISED ? order.paymentResponsible.toString() : order.customerCode;
            result.amount = financedAmount || 0 - loanFormValues.downPaymenyCurrency;
            result.branchCode = config.branch.code;
            result.startDate = loanFormValues.firstFeeDate;
            result.startDateRaw = loanFormValues.firstFeeDateRaw;
            result.rate = rate || customerCreditConditions.rate.toString();
            result.downPayment = loanFormValues.downPaymenyCurrency;
            result.downPaymentPercentage = loanFormValues.downPaymentPercent;
            result.term = loanFormValues.term.toString();
            result.calculatedDate = loanFormValues.initialDate;
            result.sellerCode = order.vendor;
            result.isThirtyDaysOrder = false;
            result.feeAmount = loanFormValues.fee;
            result.loanCode = loanFormValues.loanCode || "";

            if (intermediaries) {
                result.intermediaries = intermediaries;
            }
        } else {

            result.salesOrg = config.salesOrg.code;
            result.customerCode = order.customerCode;
            result.amount = order.totals.netTotal;
            result.branchCode = config.branch.code;
            result.startDate = loanFormValues.firstFeeDate;
            result.startDateRaw = loanFormValues.firstFeeDateRaw;
            result.rate = customerCreditConditions.rate.toString();
            result.downPayment = loanFormValues.downPaymenyCurrency;
            result.downPaymentPercentage = loanFormValues.downPaymentPercent;
            result.term = loanFormValues.term.toString();
            result.calculatedDate = loanFormValues.initialDate;
            result.sellerCode = order.vendor;
            result.isThirtyDaysOrder = true;
            result.feeAmount = loanFormValues.fee;
            result.loanCode = loanFormValues.loanCode || "";

            if (intermediaries) {
                result.intermediaries = intermediaries;
            }
        }

        return result;
    }
}