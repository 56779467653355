import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiscountType, PromotionPaymentType, PromotionType } from 'src/app/@core/constants';
import { DateUtils } from 'src/app/@core/utils/date-utils';
import { Condition } from 'src/app/models/condition';
import { Paging } from 'src/app/models/page';
import { Promotion } from 'src/app/models/promotion';
import { PromotionsService } from 'src/app/services/promotions.service';

@Component({
  selector: 'promotion-details',
  templateUrl: './promotion-details.component.html',
  styleUrls: ['./promotion-details.component.sass']
})
export class PromotionDetailsComponent implements OnInit {

  @Input()
  promotion: Promotion = new Promotion();

  @Input()
  accumulableWith: Paging<Array<{ code: string }>> = new Paging([]);

  @Input()
  salesOrgs: Paging<Array<{ code: string }>> = new Paging([]);

  @Input()
  creditProcedures: Paging<Array<{ code: string }>> = new Paging([]);

  @Input()
  buyProducts: Paging<Array<{ code: string }>> = new Paging([]);

  @Input()
  getProducts: Paging<Array<{ code: string }>> = new Paging([]);

  constructor(private promotionService: PromotionsService, private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    if (this.route.snapshot.params.code) {
      this.promotion = (await this.promotionService.readPromotion(this.route.snapshot.params.code).toPromise()).response;
      this.loadPromotionInformation();
    }

  }
  private loadPromotionInformation() {
    this.promotion.startTime = this.promotion.startTime || "000000";
    this.promotion.endTime = this.promotion.endTime || "235959";
    this.promotion.starDateRaw = DateUtils.getDateAndTimeFromString(this.promotion.startDate, this.promotion.startTime);
    this.promotion.endDateRaw = DateUtils.getDateAndTimeFromString(this.promotion.endDate, this.promotion.endTime);
    this.accumulableWith = new Paging(this.promotion.accumulableWith.map(m => { return { code: m }; }));
    this.salesOrgs = new Paging(this.promotion.salesOrgs.map(m => { return { code: m }; }));
    this.creditProcedures = new Paging(this.promotion.creditProcedures.map(m => { return { code: m }; }));
    this.buyProducts = new Paging(this.promotion.buy?.conditions[0]?.productCodes.map(m => { return { code: m }; }) || []);
    this.getProducts = new Paging(this.promotion.get?.conditions[0]?.productCodes.map(m => { return { code: m }; }) || []);
  }

  getType(type: PromotionType) {
    let result = new Map<string, string>();
    result.set(PromotionType.GENERAL, "General");
    result.set(PromotionType.DIRECTED, "Dirigida");
    return result.get(type);

  }
  getPaymentType(type: PromotionPaymentType) {
    let result = new Map<string, string>();
    result.set(PromotionPaymentType.EITHER, "General");
    result.set(PromotionPaymentType.CREDIT, "Crédito");
    result.set(PromotionPaymentType.RETAIL, "Contado");
    return result.get(type);
  }

  getTypeSuccessClass(type: PromotionType) {
    return type == PromotionType.GENERAL
  }
  getTypeWarningClass(type: PromotionType) {
    return type == PromotionType.DIRECTED
  }
  getTypeDangerClass(type: PromotionType) {
    return false;
  }
  getTypeSecondaryClass(type: PromotionType) {
    return false;
  }

  getPaymentTypeSuccessClass(type: PromotionPaymentType) {
    return type == PromotionPaymentType.EITHER
  }
  getPaymentTypeWarningClass(type: PromotionPaymentType) {
    return type == PromotionPaymentType.CREDIT
  }
  getPaymentTypeDangerClass(type: PromotionPaymentType) {
    return false;
  }
  getPaymentTypeSecondaryClass(type: PromotionPaymentType) {
    return type == PromotionPaymentType.RETAIL
  }

  getDiscountType(type?: DiscountType) {
    let result = new Map<string, string>();
    result.set("PRICE", "Precio");
    result.set("ABSOLUTE", "Absoluto");
    result.set("PERCENTAGE", "Porcentaje");

    return result.get(type || "");
  }
  getPromotionCondition() {
    return this.promotion.get?.conditions[0] || new Condition();
  }
}
