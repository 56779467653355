import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductsComponent } from './products.component';
import { Role } from 'src/app/@core/constants';
import { ProductListComponent } from './product-list/product-list.component';
import { PromotionsComponent } from '../promotions/promotions.component';
import { PromotionListComponent } from '../promotions/promotion-list/promotion-list.component';
import { PromotionDetailsComponent } from '../promotions/promotion-details/promotion-details.component';

const routes: Routes = [
  {
    path: '',
    component: ProductsComponent,
    children:
      [
        {
          path: '',
          component: ProductListComponent,
          data: {
            title: 'Lista de productos',
            id: 'ProductListComponent_products'
          }
        },
        {
          path: 'promotions',
          component: PromotionsComponent,
          data: {
            title: 'Promociones',
            id: 'PromotionsComponent_products_promotions'
          },
          children: [
            {
              path: '',
              component: PromotionsComponent,
              data: {
                title: "Promociones",
                id: 'PromotionsComponent_promotions'
              },
              children: [
                {
                  path: '',
                  component: PromotionListComponent,
                  data: {
                    title: "Listado de Promociones",
                    id: 'PromotionListComponent_promotions'
                  }
                },
                {
                  path: ':code',
                  component: PromotionDetailsComponent,
                  data: {
                    title: "Detalle de Promoción",
                    id: 'PromotionDetailsComponent_promotions_code'
                  }
                }
              ]
            }


          ]
        },
        {
          path: ':code',
          component: ProductDetailsComponent,
          data: {
            title: 'Detalle de producto',
            id: 'ProductDetailsComponent_products_code'
          }
        },

      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
