import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OrderStatus, OrderType } from '../@core/constants';
import { FileUtils } from '../@core/utils/file-utils';
import { DigitalSignature } from '../models/digital-signature';
import { Filter } from '../models/filter';
import { OrderFilter } from '../models/filters/order-filter';
import { Order } from '../models/order';
import { OrderCompleteProcess } from '../models/order-complete-process';
import { Paging } from '../models/page';
import { LoanSimulationRequest } from '../models/requests/loan-simulation-request';
import { OrderRequest } from '../models/requests/order-request';
import { RejectOrderRequest } from '../models/requests/reject-order-request';
import { ResponseModel } from '../models/response-model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private url = `${environment.url}/orders`;

  constructor(private http: HttpClient, private configService: ConfigurationService) {


  }

  readOrders(filter: Filter<OrderFilter>): Observable<ResponseModel<Paging<Array<Order>>>> {

    let params = new HttpParams();
    params = params
      .set("page", filter.page.toString() || "0")
      .set("pageSize", filter.pageSize.toString() || "20")
      .set("salesOrgCode", this.configService.readSelectedBranchAndSalesOrg().salesOrg.code);

    params = filter.value.text ? params.set("text", filter.value.text) : params;
    params = filter.value.salesOrgList ? params.set("salesOrgList", filter.value.salesOrgList) : params;
    params = filter.value.regionCode ? params.set("regionCode", filter.value.regionCode) : params;
    params = filter.value.branchCode ? params.set("branch", filter.value.branchCode) : params;
    params = filter.value.customerCode ? params.set("customer", filter.value.customerCode) : params;
    params = filter.value.sellerCode ? params.set("vendor", filter.value.sellerCode) : params;
    params = filter.value.invoiceNumber ? params.set("invoiceNumber", filter.value.invoiceNumber) : params;
    params = filter.value.status ? params.set("statusList", filter.value.status) : params.set("statusList", `${OrderStatus.INVOICEABLE},${OrderStatus.INITIAL},${OrderStatus.FINAL},${OrderStatus.REJECTED}`);
    params = filter.value.start ? params.set("start", filter.value.start) : params;
    params = filter.value.end ? params.set("end", filter.value.end) : params;
    params = filter.value.isFranchised ? params.set("isFranchised", String(filter.value.isFranchised)) : params;
    params = filter.value.orderType ? params.set("orderType", filter.value.orderType) : params.set("orderType", `${OrderType.REGULAR},${OrderType.QUOTATION}`);
    params = filter.value.condition ? params.set("condition", filter.value.condition) : params;
    params = filter.value.digitalSignatureStatus ? params.set("digitalSignatureStatus", filter.value.digitalSignatureStatus) : params;
    return this.http.get<ResponseModel<Paging<Array<Order>>>>(this.url, { params });
  }

  createOrderSimulate(
    orderRequest: OrderRequest
  ): Observable<ResponseModel<Order>> {
    return this.createOrder(orderRequest, true);
  }

  createOrder(
    orderRequest: OrderRequest,
    simulate: boolean
  ): Observable<ResponseModel<Order>> {
    let params = new HttpParams();
    params = params.set('simulate', simulate ? 'true' : 'false');
    return this.http.post<ResponseModel<Order>>(this.url, orderRequest, {
      params: params,
    });
  }

  createOrderCompleteProcess(
    orderRequest: OrderRequest,
    loanSimulationRequest: Array<LoanSimulationRequest>,
    files: { fiscalId: string; rtnFiscalId?: string; ip800FiscalId: string, proofOfPayment?: string },
    fileName: { fiscalId: string; rtnFiscalId?: string; ip800: string, proofOfPayment?: string }
  ): Observable<ResponseModel<OrderCompleteProcess>> {
    console.log(`order request desde el servicio ===>`, orderRequest);
    let body = {
      order: orderRequest,
      loan: loanSimulationRequest,
      fiscalIdFile: files.fiscalId,
      rtnIdFile: files.rtnFiscalId,
      ip800File: files.ip800FiscalId,
      proofOfPaymentFile: files.proofOfPayment,
      fiscalIdFileName: fileName.fiscalId,
      rtnIdFileName: fileName.rtnFiscalId,
      ip800FileName: fileName.ip800,
      proofOfPaymentName: fileName.proofOfPayment
    };

    return this.http.post<ResponseModel<OrderCompleteProcess>>(
      `${this.url}/complete`,
      body
    );
  }

  updateOrderStatus(
    orderCode: string,
    rejectOrderRequest: RejectOrderRequest
  ): Observable<ResponseModel<Order>> {
    return this.http.put<ResponseModel<Order>>(
      `${this.url}/${orderCode}/modifyStatus`,
      rejectOrderRequest
    );
  }

  readOrder(orderCode: string): Observable<ResponseModel<Order>> {
    return this.http.get<ResponseModel<Order>>(`${this.url}/${orderCode}`);
  }

  readerOrderCompleteProcess(
    orderCode: string
  ): Observable<ResponseModel<OrderCompleteProcess>> {
    return this.http.get<ResponseModel<OrderCompleteProcess>>(
      `${this.url}/${orderCode}/complete`
    );
  }

  updateOrderCompleteProcess(
    orderCode: string,
    orderRequest: OrderRequest,
    loanSimulationRequest: Array<LoanSimulationRequest>,
    files: { fiscalId: string; rtnFiscalId?: string; ip800FiscalId: string, proofOfPayment?: string, purchaseOrder?: string },
    fileName: { fiscalId: string; rtnFiscalId?: string; ip800: string, proofOfPayment?: string, purchaseOrder?: string }
  ): Observable<ResponseModel<OrderCompleteProcess>> {


    let body = {
      order: orderRequest,
      loan: loanSimulationRequest,
      fiscalIdFile: files.fiscalId,
      rtnIdFile: files.rtnFiscalId,
      ip800File: files.ip800FiscalId,
      proofOfPaymentFile: files.proofOfPayment,
      purchaseOrderFile: files.purchaseOrder,
      fiscalIdFileName: fileName.fiscalId,
      rtnIdFileName: fileName.rtnFiscalId,
      ip800FileName: fileName.ip800,
      proofOfPaymentName: fileName.proofOfPayment,
      purchaseOrderName: fileName.purchaseOrder
    };

    return this.http.put<ResponseModel<OrderCompleteProcess>>(
      `${this.url}/${orderCode}/complete`,
      body
    );
  }

  simulateOrderWithLoans(
    orderRequest: OrderRequest
  ): Observable<ResponseModel<OrderCompleteProcess>> {
    return this.http.post<ResponseModel<OrderCompleteProcess>>(
      `${this.url}/complete/simulate`,
      orderRequest
    );
  }

  readDigitalSignatureByOrderCode(orderCode: string): Observable<DigitalSignature> {
    return this.http
      .get<ResponseModel<DigitalSignature>>(`${this.url}/${orderCode}/digitalSignature`, {})
      .pipe(map((response: ResponseModel<DigitalSignature>) => response.response));
  }

  createSigningRequest(orderCode: string): Observable<ResponseModel<DigitalSignature>> {
    return this.http.post<ResponseModel<DigitalSignature>>(`${this.url}/${orderCode}/createSigningRequest`, {});
  }

  signDocuments(requestCode: number, otpCode: string): Observable<ResponseModel<DigitalSignature>> {
    let params = new HttpParams();
    params = params
      .set("requestCode", requestCode.toString())
      .set("otpCode", otpCode);

    return this.http.post<ResponseModel<DigitalSignature>>(`${this.url}/signDocuments`, {}, { params });
  }

  getPriceForVehicleRegister(productCode:string, branchCode:string):Observable<ResponseModel<number>>{
    let params = new HttpParams();
    params = params
      .set("productCode", productCode)
      .set("branchCode", branchCode);

    return this.http.get<ResponseModel<number>>(`${this.url}/priceEquipmentRegistration`, {params} );
  }
}
