import { OrderCompleteProcess } from './../../../models/order-complete-process';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Big from 'big.js';
import {
  CustomerType,
  LoanClass,
  OrderAttachmentType,
  OrderConditions,
  OrderConditionsType,
  Personality,
} from 'src/app/@core/constants';
import { PosDiscountModalService } from 'src/app/@core/services/pos-discount-modal.service';
import { PosExemptionModalService } from 'src/app/@core/services/pos-exemption-modal.service';
import { FileUtils } from 'src/app/@core/utils/file-utils';
import { Branch } from 'src/app/models/branch';
import { Customer } from 'src/app/models/customer';
import { CustomerCreditValidation } from 'src/app/models/customer-credit-validation';
import { Discount } from 'src/app/models/discount';
import { ExonerationReason } from 'src/app/models/exoneration-reason';
import { Loan } from 'src/app/models/loan';
import { Order } from 'src/app/models/order';
import { OrderAttachment } from 'src/app/models/order-attachment';
import { Paging } from 'src/app/models/page';
import { ProductCart } from 'src/app/models/product-cart';
import { Promotion } from 'src/app/models/promotion';
import { LoanFormRequest } from 'src/app/models/requests/loan-form-request';
import { LoanSimulationRequest } from 'src/app/models/requests/loan-simulation-request';
import { OrderRequest } from 'src/app/models/requests/order-request';
import { ResponseModel } from 'src/app/models/response-model';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { TaxExemption } from 'src/app/models/tax-exemption';
import { BranchesService } from 'src/app/services/branches.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CreditService } from 'src/app/services/credit.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ExonerationReasonService } from 'src/app/services/exoneration-reason.service';
import { OrderService } from 'src/app/services/order.service';
import { PromotionsService } from 'src/app/services/promotions.service';
import { PosOrderObservationsService } from 'src/app/@core/services/pos-order-observations.service';

@Component({
  selector: 'app-modify-sales',
  templateUrl: './modify-sales.component.html',
  styleUrls: ['./modify-sales.component.sass'],
})
export class ModifySalesComponent implements OnInit {
  formCustomer: FormGroup;
  formCart: FormGroup;
  formCreditConditions: FormGroup;
  documentsForm: FormGroup;
  formOrderType: FormGroup;

  customer: CustomerTableModel = new CustomerTableModel();
  employee: CustomerTableModel = new CustomerTableModel();

  taxExemption: TaxExemption = new TaxExemption();
  discount: Discount = new Discount();
  exemptionReasons: Paging<Array<ExonerationReason>> = new Paging<
    Array<ExonerationReason>
  >([]);
  orderCondition: string = 'credito'; //WIP: to add conditons from other types of orders for next iteration
  creditConditionsResult: CustomerCreditValidation =
    new CustomerCreditValidation();
  order: Order = new Order();
  loanSimulationRequest: Array<LoanSimulationRequest> = new Array();
  productsCart: Paging<Array<ProductCart>> = new Paging([]);
  productsCartFormServer: Paging<Array<ProductCart>> = new Paging([]);
  private orderAttachments: Array<OrderAttachment> = [];
  fiscalIdFile: OrderAttachment = new OrderAttachment();
  rtnFiscalIdFile: OrderAttachment = new OrderAttachment();
  ip800File: OrderAttachment = new OrderAttachment();
  proofOfPaymentFile: OrderAttachment = new OrderAttachment();
  purchaseOrderFile: OrderAttachment = new OrderAttachment();
  activePromotions: Paging<Array<Promotion>> = new Paging([]);

  actualBranch: Branch = new Branch();

  orderTypeId: number = 0;

  calculatedFeesFormArray: FormArray = new FormArray([]);

  get customerCode(): string {
    return this.customer ? this.customer.code : '';
  }

  loansSimulated: Array<Loan> = new Array();

  endorsementIntermediary: Customer | null = null;

  orderRequest: OrderRequest = new OrderRequest();

  resultCompleteOrderProcess: ResponseModel<OrderCompleteProcess> | null = null;

  isPromotionOrDiscountApplied: boolean = false;

  observations: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private exonerationReasonService: ExonerationReasonService,
    private posExemptionModalService: PosExemptionModalService,
    private posDiscountModalService: PosDiscountModalService,
    private creditService: CreditService,
    private configService: ConfigurationService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private promotionService: PromotionsService,
    private customerService: CustomerService,
    private configurationService: ConfigurationService,
    private branchesService: BranchesService,
    private posObservationsService: PosOrderObservationsService
  ) {
    this.formCustomer = this.formBuilder.group({
      sellerName: '',
      customerFullName: '',
      rtnFiscalId: '',
    });

    this.formCreditConditions = this.formBuilder.group({
      term: new FormControl(),
      downPaymentPercent: new FormControl(),
      downPaymenyCurrency: new FormControl(),
      initialDate: new FormControl(),
      firstFeeDate: new FormControl(),
      fee: new FormControl(),
    });

    this.documentsForm = this.formBuilder.group({
      fiscalIdFile: new FormControl('', Validators.required),
      rtnFiscalIdFile: new FormControl(''),
      ip800File: new FormControl('', Validators.required),
      proofOfPaymentFile: new FormControl(''),
      purchaseOrderFile: new FormControl('')
    });

    this.formCart = this.formBuilder.group({});

    this.formOrderType = this.formBuilder.group({
      orderTypeId: new FormControl(0, [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.route.snapshot.params.code) {
      await this.loadOrder();
    }

    this.exemptionReasons = (
      await this.exonerationReasonService.readExonerations().toPromise()
    ).response;

    const { branch } =
      this.configurationService.readSelectedBranchAndSalesOrg();

    this.actualBranch = (
      await this.branchesService.readBranch(branch.code).toPromise()
    ).response;
  }

  private async loadOrder() {
    this.resultCompleteOrderProcess = await this.orderService
      .readerOrderCompleteProcess(this.route.snapshot.params.code)
      .toPromise();

    let result = this.resultCompleteOrderProcess.response;

    this.order = result.order;
    this.loansSimulated = result.loans;

    this.loanSimulationRequest =
      result.loans?.map((l) => {
        l.parameters.loanCode = l.code;
        return l.parameters;
      }) || new LoanSimulationRequest();

    this.customer = (
      await this.customerService
        .readCustomerFranchised(result.order.customerCode)
        .toPromise()
    ).response;

    this.customer.useFiscalId2 =
      this.customer.fiscalId2 == this.order.customerFiscalId;
    this.employee.fullName = result.order.vendorName;
    this.employee.code = result.order.vendor;

    this.activePromotions = (
      await this.promotionService
        .readPromotionsList({
          page: 0,
          pageSize: 1000,
          value: { promotionCodes: Array.from(this.order.appliedPromotions) },
        })
        .toPromise()
    ).response;

    this.productsCartFormServer = new Paging(
      this.order.orderEntryList.map((m) => {
        return (
          new ProductCart()
            .setCode(parseInt(m.code))
            .setProductCode(m.productCode)
            .setDescription(m.productName)
            .setTotal(m.price.amount)
            //.setPrice(m.totals.grossTotal)
            .setPrice(m.price.amount)
            .setDiscountTotal(m.totals.discountTotal)
            //.setStock(m.carStock || 0)
            .setAmount(m.totalQuantity)
            .setTax(m.tax.value)
            .setTaxCode(m.tax.code)
            .setSerial(m.serialNumber)
            .setDiscount(m.discounts.find((f) => f) || new Discount())
            .setRequiredSerial(m.serialProfile)
        );
      })
    );

    this.order.orderEntryList
      .filter((f) => f.services && f.services.length > 0)
      .forEach((f) => {
        f.services.forEach((x) => {
          let pr = this.productsCartFormServer.content.find(
            (a) => a.code.toString() == x
          );

          if (pr) {
            pr.referenceCode = parseInt(f.code);
          }
        });
      });

    this.discount =
      this.order.discounts.find((f) => !f.promotionCode) || new Discount();
    this.orderAttachments = result.attachments;
    this.fiscalIdFile =
      this.orderAttachments.find(
        (f) => f.type == OrderAttachmentType.FISCALID
      ) || new OrderAttachment();
    this.rtnFiscalIdFile =
      this.orderAttachments.find(
        (f) => f.type == OrderAttachmentType.RTNFISCALID
      ) || new OrderAttachment();
    this.ip800File =
      this.orderAttachments.find((f) => f.type == OrderAttachmentType.IP800) ||
      new OrderAttachment();
    this.proofOfPaymentFile =
      this.orderAttachments.find((f) => f.type == OrderAttachmentType.PROOF_OF_PAYMENT) ||
      new OrderAttachment();
    this.purchaseOrderFile =
      this.orderAttachments.find((f) => f.type == OrderAttachmentType.PURCHASE_ORDER) ||
      new OrderAttachment();

    this.loadForms();

    if (this.orderTypeId == OrderConditions.MORE_THAN_30_DAYS_FRANCHISED) {

      this.creditConditionsResult = (
        (await this.creditService
          .getCustomerCreditConditions(
            this.orderRequest.paymentResponsible,
            OrderConditionsType.CREDIT_WITH_PAYMENT_RESPONSIBLE
          )
          .toPromise()) as ResponseModel<CustomerCreditValidation>
      ).response;

      this.loadCreditConfigForm(this.creditConditionsResult);
    }// }else
    // this.creditConditionsResult = (
    //   (await this.creditService
    //     .getCustomerCreditConditions(
    //       this.customer.code,
    //       OrderConditionsType.CREDIT
    //     )
    //     .toPromise()
    //     .catch((s) => {
    //       this.customer = new CustomerTableModel();
    //       this.employee = new CustomerTableModel();
    //       this.formCustomer.reset();
    //     })) as ResponseModel<CustomerCreditValidation>
    // ).response;

    this.observations = this.order.observations;
  }

  async getCreditInfo(event: any) {
    if (this.orderTypeId == OrderConditions.MORE_THAN_30_DAYS_FRANCHISED) {

      this.creditConditionsResult = (
        (await this.creditService
          .getCustomerCreditConditions(
            this.orderRequest.paymentResponsible,
            OrderConditionsType.CREDIT_WITH_PAYMENT_RESPONSIBLE
          )
          .toPromise()) as ResponseModel<CustomerCreditValidation>
      ).response;

      this.loadCreditConfigForm(this.creditConditionsResult);
    }
  }

  private loadForms() {
    this.formCustomer = this.formBuilder.group({
      sellerName: new FormControl(this.employee.fullName),
      customerFullName: new FormControl(this.customer.fullName),
      rtnFiscalId: new FormControl(
        this.customer.fiscalId2 == this.order.customerFiscalId
          ? this.customer.fiscalId2
          : ''
      ),
    });

    this.formCustomer.get('sellerName')?.markAsDirty();
    this.formCustomer.get('customerFullName')?.markAsDirty();
    this.formCustomer.get('rtnFiscalId')?.markAsDirty();

    this.formCustomer.get('sellerName')?.markAsTouched();
    this.formCustomer.get('customerFullName')?.markAsTouched();
    this.formCustomer.get('rtnFiscalId')?.markAsTouched();

    this.formCart.markAsDirty();

    this.documentsForm.patchValue({
      fiscalIdFile: FileUtils.dataURLtoFile(
        this.fiscalIdFile.data,
        this.fiscalIdFile.filename
      ),
    });
    if (this.rtnFiscalIdFile.data) {
      this.documentsForm.patchValue({
        rtnFiscalIdFile: FileUtils.dataURLtoFile(
          this.rtnFiscalIdFile.data,
          this.rtnFiscalIdFile.filename
        ),
      });
    }
    this.documentsForm.patchValue({
      proofOfPaymentFile: FileUtils.dataURLtoFile(
        this.proofOfPaymentFile.data,
        this.proofOfPaymentFile.filename
      ),
    });
    this.documentsForm.patchValue({
      purchaseOrderFile: FileUtils.dataURLtoFile(
        this.purchaseOrderFile.data,
        this.purchaseOrderFile.filename
      ),
    });
    this.documentsForm.patchValue({
      ip800File: FileUtils.dataURLtoFile(
        this.ip800File.data,
        this.ip800File.filename
      ),
    });
    this.documentsForm.markAsDirty();
  }

  async getCustomerStepInfo(result: {
    customer: CustomerTableModel;
    employee: CustomerTableModel;
  }): Promise<void> {
    this.customer = result.customer;
    this.employee = result.employee;

    // this.creditConditionsResult = (await this.creditService.getCustomerCreditConditions(result.customer.code)
    //   .toPromise().catch(s => {
    //     this.customer = new CustomerTableModel();
    //     this.employee = new CustomerTableModel();
    //     this.formCustomer.reset();
    //   }) as ResponseModel<CustomerCreditValidation>).response;

    //this.loadCreditConfigForm(creditConditionsResult, new Order())
  }

  async setTaxExemption(): Promise<void> {
    await this.posExemptionModalService.show(
      this.taxExemption,
      (value: TaxExemption) => {
        this.taxExemption = value;
      }
    );
  }

  async setDiscount(): Promise<void> {
    await this.posDiscountModalService.show(
      this.discount,
      (discount: Discount) => {
        this.discount = discount;
        this.isPromotionOrDiscountApplied = true;
        this.calculatedFeesFormArray.reset();
      }
    );
  }

  async getOrder(result: {
    order: Order;
    orderRequest?: OrderRequest;
    productsCart: Paging<Array<ProductCart>>;
  }) {
    this.order = result.order;
    let firstLoanCodeThirtyDays = this.loanSimulationRequest[0] || new LoanSimulationRequest();
    this.loanSimulationRequest = new Array();
    this.loadCreditConfigForm(this.creditConditionsResult);
    this.productsCart = new Paging(result.productsCart.content);
    this.orderRequest = result.orderRequest || new OrderRequest();
    this.calculatedFeesFormArray.reset();

    if (
      this.orderTypeId === OrderConditions.MORE_THAN_30_DAYS_FRANCHISED ||
      this.orderTypeId === OrderConditions.FINAL_CUSTOMER_CREDIT ||
      this.orderTypeId === OrderConditions.CREDIT_QUOTATION
    ) {
      for (const [i, ls] of this.loansSimulated.entries()) {
        const loanSimulationRequest =
          await this.calculateMoreThanThirtyDaysLoanSimulation(ls);

        this.loanSimulationRequest.push(loanSimulationRequest);
        if (this.calculatedFeesFormArray.at(i)) {
          this.calculatedFeesFormArray
            .at(i)
            .setValue(loanSimulationRequest.feeAmount || null);
          this.calculatedFeesFormArray.at(i).markAsDirty();
          this.calculatedFeesFormArray.at(i).markAsTouched();
        }
        console.log(this.calculatedFeesFormArray.valid);
      }
    } else {
      this.calculateThirtyDaysLoanSimulation(firstLoanCodeThirtyDays.loanCode || "");
    }
  }

  async calculateMoreThanThirtyDaysLoanSimulation(simulatedLoan: Loan) {
    let loanFormValues: LoanFormRequest =
      this.getLoanFormValuesMoreThanThirtyDays(simulatedLoan);

    console.log(`este es el contrato simulado ===>`, simulatedLoan);
    console.log(
      `estos son los valores para mandar a crear ==>`,
      loanFormValues
    );

    this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_THIRTY_DAYS;

    switch (this.orderTypeId as OrderConditions) {
      case OrderConditions.CREDIT_QUOTATION:
        if (this.customer.personality == Personality.PERSON) {
          this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_PERSON;
        }

        if (this.customer.customerType == CustomerType.EMPLOYEE) {
          this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_EMPLOYEE;
        }

        loanFormValues = await this.getLoanFormValuesCreditQuotation(
          this.creditConditionsResult.loanClass
        );
        break;
    }

    const intermediaries = [];

    if (this.endorsementIntermediary !== null) {
      intermediaries.push(this.endorsementIntermediary.code);
    }

    if (this.orderCondition != undefined) {
      this.order.orderCondition = this.orderTypeId;
    }

    return LoanSimulationRequest.buildRequest(
      this.order,
      this.creditConditionsResult,
      loanFormValues,
      this.configService.readSelectedBranchAndSalesOrg(),
      intermediaries,
      true,
      simulatedLoan.amount,
      simulatedLoan.rate
    );
  }

  private getLoanFormValuesMoreThanThirtyDays(simulatedLoan: Loan) {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    const loanFormValues: LoanFormRequest = new LoanFormRequest();

    if (this.loansSimulated.length > 0) {
      loanFormValues.downPaymentPercent =
        simulatedLoan.parameters.downPaymentPercentage;
      loanFormValues.downPaymenyCurrency = simulatedLoan.parameters.downPayment;
      loanFormValues.fee =
        (simulatedLoan.conditions.find((c) => c.conditionType === '0261')
          ?.amount || 0) +
        (simulatedLoan.conditions.find((c) => c.conditionType === '0203')
          ?.amount || 0);
      loanFormValues.term = Number(simulatedLoan.term);

      loanFormValues.initialDate = simulatedLoan.parameters.calculatedDate || '';
      loanFormValues.firstFeeDate = simulatedLoan.parameters.startDate || '';
      loanFormValues.firstFeeDateRaw = simulatedLoan.parameters.startDateRaw || today;

      loanFormValues.loanCode = simulatedLoan.code;
    }

    return loanFormValues;
  }

  loadCreditConfigForm(creditConditionsResult: CustomerCreditValidation) {
    CustomerCreditValidation.setDownpaymentLimitsCurrency(
      creditConditionsResult,
      this.order.totals.netTotal
    );
    this.formCreditConditions = this.formBuilder.group({
      term: new FormControl('', [
        Validators.required,
        Validators.min(creditConditionsResult.minTerm),
        Validators.max(creditConditionsResult.maxTerm),
      ]),
      downPaymentPercent: new FormControl('', [
        Validators.required,
        Validators.min(creditConditionsResult.minDownPayment),
        Validators.max(creditConditionsResult.maxDownPayment),
      ]),
      downPaymenyCurrency: new FormControl('', [
        Validators.required,
        Validators.min(creditConditionsResult.minDownPaymentCurrency),
        Validators.max(creditConditionsResult.maxDownPaymentCurrency),
      ]),
      initialDate: new FormControl(
        this.datePipe.transform(new Date(), 'MM/dd/YYYY')
      ),
      firstFeeDate: new FormControl('', Validators.required),
      fee: new FormControl('', Validators.required),
    });
  }

  async calculateThirtyDaysLoanSimulation(firstLoanCodeThirtyDays: string) {
    let loanFormValues: LoanFormRequest = this.getLoanFormValuesThirtyDays(firstLoanCodeThirtyDays);
    this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_THIRTY_DAYS;

    switch (this.order.orderCondition as OrderConditions) {
      case OrderConditions.CREDIT_QUOTATION:
        if (this.customer.personality == Personality.PERSON) {
          this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_PERSON;
        }
        if (this.customer.customerType == CustomerType.EMPLOYEE) {
          this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_EMPLOYEE;
        }
        loanFormValues = await this.getLoanFormValuesCreditQuotation(
          this.creditConditionsResult.loanClass
        );
        break;
    }
    this.loanSimulationRequest.push(
      LoanSimulationRequest.buildRequest(
        this.order,
        this.creditConditionsResult,
        loanFormValues,
        this.configService.readSelectedBranchAndSalesOrg()
      )
    );
  }

  private async getLoanFormValuesCreditQuotation(loanClass: string) {
    let creditConditions = (
      await this.creditService
        .getCreditReferences(
          this.configurationService.readSelectedBranchAndSalesOrg().salesOrg
            .code,
          undefined,
          loanClass
        )
        .toPromise()
    ).response;

    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    today.setMonth(today.getMonth() + 1);

    let loanFormValues: LoanFormRequest = new LoanFormRequest();
    loanFormValues.downPaymentPercent = creditConditions[0].minDownPayment;
    loanFormValues.downPaymenyCurrency = 0;
    loanFormValues.fee = this.order.totals.netTotal;
    loanFormValues.term = creditConditions[0].minTerm;
    loanFormValues.initialDate =
      this.datePipe.transform(today, 'YYYYMMdd') || '';
    loanFormValues.firstFeeDate =
      this.datePipe.transform(today, 'YYYYMMdd') || '';
    loanFormValues.firstFeeDateRaw = today;
    return loanFormValues;
  }

  private getLoanFormValuesThirtyDays(firstLoanCodeThirtyDays: string) {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    today.setMonth(today.getMonth() + 1);

    let loanFormValues: LoanFormRequest = new LoanFormRequest();
    loanFormValues.downPaymentPercent = 0;
    loanFormValues.downPaymenyCurrency = 0;
    loanFormValues.fee = this.order.totals.netTotal;
    loanFormValues.term = 1;
    loanFormValues.initialDate =
      this.datePipe.transform(today, 'YYYYMMdd') || '';
    loanFormValues.firstFeeDate =
      this.datePipe.transform(today, 'YYYYMMdd') || '';
    loanFormValues.firstFeeDateRaw = today;
    loanFormValues.loanCode = firstLoanCodeThirtyDays;
    return loanFormValues;
  }

  getFileInfo(file: string) {
    return this.documentsForm.get(file)?.value;
  }

  getCartForm(tableForm: FormGroup) {
    this.formCart = tableForm;
  }

  removeDiscount() {
    this.discount = new Discount();
  }

  async onSelectOrderType(orderTypeId: number) {
    this.orderTypeId = orderTypeId;
    this.formOrderType.get('orderTypeId')?.setValue(orderTypeId);
    this.formOrderType.get('orderTypeId')?.markAsDirty();

    let conditionType = this.getOrderConditionTypeByOrderTypeId(orderTypeId);
    if (conditionType != undefined) {
      await this.calculateCreditConditions(conditionType);
    }
  }

  private async calculateCreditConditions(conditionType: OrderConditionsType) {
    this.creditConditionsResult = (
      (await this.creditService
        .getCustomerCreditConditions(
          this.customer.code,
          conditionType
        )
        .toPromise()
        .catch((s) => {
          this.customer = new CustomerTableModel();
          this.employee = new CustomerTableModel();
          this.formCustomer.reset();
        })) as ResponseModel<CustomerCreditValidation>
    ).response;
  }

  private getOrderConditionTypeByOrderTypeId(orderTypeId: OrderConditions): OrderConditionsType | undefined {

    switch (orderTypeId) {
      case OrderConditions.UNTIL_30_DAYS_FRANCHISED:
        return OrderConditionsType.CREDIT_30_DAYS;
      case OrderConditions.FINAL_CUSTOMER_CREDIT:
        return OrderConditionsType.CREDIT;
      case OrderConditions.MORE_THAN_30_DAYS_FRANCHISED:
        return OrderConditionsType.CREDIT_WITH_PAYMENT_RESPONSIBLE;
      case OrderConditions.CREDIT_QUOTATION:
        return OrderConditionsType.REFERENCE;
      default:
        return undefined;
    }
  }

  handleCreditConditionChange(conditions: {
    loans: Array<Loan>;
    endorsementIntermediary: Customer | null;
  }): void {
    this.loansSimulated = conditions.loans;
    this.endorsementIntermediary = conditions.endorsementIntermediary;

    this.buildFeesFormArray(this.loansSimulated);

    this.getOrder({
      order: this.order,
      orderRequest: this.orderRequest,
      productsCart: this.productsCart,
    });
  }

  buildFeesFormArray(loans: Array<Loan>): void {
    this.calculatedFeesFormArray = this.formBuilder.array([]);
    this.loansSimulated.forEach((l) => {
      this.calculatedFeesFormArray.push(
        this.formBuilder.control(l.parameters.feeAmount, Validators.required)
      );
    });
  }

  async setObservations() {
    await this.posObservationsService.show(this.observations, (observation: string) => {
      this.observations = observation;
    });
  }
}
