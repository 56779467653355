// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   url: `http://localhost:8080/mqapos`,
//   creditRequestUrl: `http://localhost:3000`,
//   fingerPrintDeviceUrl: `https://localhost:5001`
// };

export const environment = {
  production: false,
  url: "https://apachepos.invilp.com/mqapos-5.0",
  creditRequestUrl: `https://entrenaposfranquicia.invilp.com/onbasenew`,
  fingerPrintDeviceUrl: `https://localhost:5001`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
