import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BackOfficeOrdersComponent } from './back-office-orders.component';
import { OrderApprovalComponent } from './order-approval/order-approval.component';
import { Role } from 'src/app/@core/constants';
import { BackOfficeOrderListComponent } from './back-office-order-list/back-office-order-list.component';
import { InvoiceStepperComponent } from './invoice-stepper/invoice-stepper.component';
import { CreditInvoiceStepperComponent } from './credit-invoice-stepper/credit-invoice-stepper.component';


const routes: Routes = [
  {
    path: '',
    component: BackOfficeOrdersComponent,
    children: [
      {
        path: '',
        component: BackOfficeOrderListComponent,
        data: {
          title: 'Lista de ordenes',
          id: 'BackOfficeOrderListComponent_backOfficeOrders'
        }
      },
      {
        path: ':code',
        component: OrderApprovalComponent,
        data: {
          title: 'Aprobación de orden',
          id: 'OrderApprovalComponent_backOfficeOrders_code'
        }
      },
      {
        path: 'invoices/:code',
        component: InvoiceStepperComponent,
        data: {
          title: 'Facturas',
          id: 'InvoicesStepperComponent_invoices_code'
        }
      },
      {
        path: 'creditinvoices/:code',
        component: CreditInvoiceStepperComponent,
        data: {
          title: 'Facturas crédito',
          id: 'CreditInvoicesStepperComponent_invoices_code'
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackOfficeOrdersRoutingModule { }
