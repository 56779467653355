import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDialogType, Role, UserStatus } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { Branch } from 'src/app/models/branch';
import { ExchangeRate } from 'src/app/models/exchange-rate';
import { INavDataRoles } from 'src/app/models/nav-data-roles';
import { SalesOrg } from 'src/app/models/sales-org';
import { User } from 'src/app/models/user';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ExchangeRateService } from 'src/app/services/exchange-rate.service';
import { AuthService } from 'src/app/services/auth.service';
import { ParamsService } from 'src/app/services/params.service';
import { navItemsWithRoles } from '../../../nav';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.sass']
})
export class LayoutComponent implements OnInit, AfterViewInit {

  public sidebarMinimized = false;
  public navItems: INavDataRoles[] = [];
  public profile: User = new User();

  public salesOrgs: Array<SalesOrg> = [];
  public salesOrg: string = "";
  public salesOrgObject: SalesOrg = new SalesOrg();
  public branches: any = {};
  public filteredBranches: Array<Branch> = [];
  public branch: string = "";
  public branchObject: Branch = new Branch();

  public exchangeRate: ExchangeRate = new ExchangeRate();
  constructor(private authService: AuthService,
    private configurationService: ConfigurationService,
    private confirmService: PosConfirmationService,
    private exchangeRateService: ExchangeRateService) {
    let userRoles = Array.from(this.authService.readRolesInStorage());
    navItemsWithRoles.forEach((f: INavDataRoles) => {
      if (f.roles && f.roles.some((s) => userRoles.includes(s)) || !f.roles) {
        this.navItems.push(f);
      }
    });
  }

  ngOnInit() {
    this.loadConfiguration();
  }

  async loadConfiguration(): Promise<void> {
    this.setMenus();

    this.exchangeRate = await this.exchangeRateService.readLatestExchangeRate();
    this.salesOrgs = this.configurationService.readSalesOrg();
    this.branches = this.configurationService.readBranch();
    this.salesOrgObject = this.configurationService.readSelectedBranchAndSalesOrg().salesOrg;
    this.salesOrg = this.salesOrgObject.code;
    this.branchObject = this.configurationService.readSelectedBranchAndSalesOrg().branch;
    this.branch = this.branchObject.code;
    this.filteredBranches = this.branches[this.salesOrg] || [];
  }

  setMenus() {
    this.navItems = [];
    const menus = Array.from(this.authService.readMenusInStorage());
    this.navItems.push(navItemsWithRoles[0]);
    this.navItems = this.navItems.concat(menus.map(m => {
      return {
        name: m.name,
        url: `/${m.redirectTo}`,
        icon: m.icon,
      }
    }))
    this.navItems.push(navItemsWithRoles[navItemsWithRoles.length - 1]);
  }

  filterBranches() {
    if (!this.salesOrg) {
      this.filteredBranches = [];
    }
    this.filteredBranches = this.branches[this.salesOrg];
  }
  async ngAfterViewInit() {
    this.profile = (await this.authService.readProfileInStorage());
  }

  toggleMinimize(e: boolean) {
    this.sidebarMinimized = e;
  }

  async changeBranch(): Promise<void> {
    let selectedBranch = this.filteredBranches.find(f => f.code == this.branch);
    let selectedSalesOrg = this.salesOrgs.find(f => f.code == this.salesOrg);
    await this.confirmService.show("Cambio de tienda",
      `Está seguro que desea cambiar a la tienda ${selectedBranch?.code} - ${selectedBranch?.name}`,
      ModalDialogType.INFO,
      () => {
        this.configurationService.createSelectedBranchAndSalesOrg({ salesOrg: selectedSalesOrg, branch: selectedBranch || new Branch() });
        window.location.reload();
      })
  }

  changePassword() {

  }

}
