export class CreditNoteTableModel {
    fullNumber: string;
    usableAsPayment: boolean;
    sellerName: string;
    branchName: string;
    timeStamp: string;
    netTotal: number;
    amountUsed: number;
    amountAvailable: number;
    constructor() {
        this.fullNumber = "";
        this.usableAsPayment = false;
        this.sellerName = "";
        this.branchName = "";
        this.timeStamp = "";
        this.netTotal = 0.0;
        this.amountUsed = 0.0;
        this.amountAvailable = 0.0;
    }
}