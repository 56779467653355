import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { PosCustomerCreateAddressComponent } from './pos-customer-create-address.component';



@NgModule({
  declarations: [PosCustomerCreateAddressComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports:[PosCustomerCreateAddressComponent]
})
export class PosCustomerCreateAddressModule { }