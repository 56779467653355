import { BranchRegion } from "./branch-region";
import { DeliveryBranch } from "./delivery-branch";
import { Franchise } from "./franchised";
import { Warehouse } from "./warehouse";

export class Branch {
    code: string;
    name: string;
    salesOrg: string;
    address: string;
    currency: string;
    ableToSell: boolean;
    warehouses: Array<Warehouse>;
    deliveryBranches: Array<DeliveryBranch>;
    region: BranchRegion;
    franquicia: Franchise;

    constructor() {
        this.code = "";
        this.name = "";
        this.salesOrg = "";
        this.address = "";
        this.currency = "";
        this.ableToSell = false;
        this.warehouses = [];
        this.deliveryBranches = [];
        this.region = new BranchRegion();
        this.franquicia = new Franchise();
    }
}
