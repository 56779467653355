export class ExonerationReason {
    code: string;
    reason: string;
    taxType: string;
    notReplace: string;

    constructor() {
        this.code = "";
        this.reason = "";
        this.taxType = "";
        this.notReplace = "";
    }
}