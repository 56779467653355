import { ConfigurationService } from './../../../../services/configuration.service';
import { OnbaseService } from 'src/app/services/onbase.service';
import { CustomerCreditPlansComponent } from './../customer-credit-plans/customer-credit-plans.component';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Customer } from 'src/app/models/customer';
import { Param } from 'src/app/models/param';
import { PreApprovalChangeEvent } from 'src/app/models/preApprovalChangeEvent';
import { CreditStatusFranchisedResponseModel } from 'src/app/models/response/customerCreditStatusResponseModel';
import { CustomerPreApprovalFormComponent } from './../customer-pre-approval-form/customer-pre-approval-form.component';
import { OnbaseProcedureResponseModel } from 'src/app/models/response/onbaseProcedureResponseModel';
import { Paging } from 'src/app/models/page';
import { CustomerValidationsService } from '../../services/customer-validations.service';
import { LimitRulesType, Personality } from 'src/app/@core/constants';

@Component({
  selector: 'app-customer-credit-request-stepper',
  templateUrl: './customer-credit-request-stepper.component.html',
  styleUrls: ['./customer-credit-request-stepper.component.sass'],
})
export class CustomerCreditRequestStepperComponent implements OnInit {
  @ViewChild('customerPreApprovalFormComponent')
  customerPreApprovalFormComponent!: CustomerPreApprovalFormComponent;
  @ViewChild('customerCreditPlansComponent')
  customerCreditPlansComponent!: CustomerCreditPlansComponent;
  @ViewChild('cdkStepper') stepper!: CdkStepper;

  @Input() homeTypes: Array<Param> = new Array();
  @Input() currentCustomer: Customer = new Customer();
  @Input() preApprovalForm: FormGroup = new FormGroup({});
  @Input()
  currentCustomerCreditConditions: CreditStatusFranchisedResponseModel = new CreditStatusFranchisedResponseModel();

  alreadyPreApproved = false;
  preApprovalFormValue: any;
  plans: Paging<Array<OnbaseProcedureResponseModel>> = new Paging([]);
  private isCanceled = false;
  constructor(
    private customerValidationsService: CustomerValidationsService,
    private onbaseService: OnbaseService,
    private configurationService: ConfigurationService
  ) {}

  async ngOnInit(): Promise<void> {
    if (
      this.currentCustomer.mainPersonalInfo?.personality ===
      Personality.LEGAL_ENTITY
    ) {
      await this.fetchCreditPlans();
    }
  }

  handlePreApprovalInfoChange(
    preApprovalData: PreApprovalChangeEvent,
    stepper?: CdkStepper
  ): void {
    this.preApprovalFormValue = preApprovalData.preApprovalFormValue;
    this.customerCreditPlansComponent.pathPreApprovalValueForm(
      preApprovalData.preApprovalFormValue
    );
    this.plans = preApprovalData.plans;
    this.alreadyPreApproved = true;
  }

  async handleChangeStep(event: StepperSelectionEvent): Promise<void> {
    if(this.currentCustomer.mainPersonalInfo?.personality !== Personality.LEGAL_ENTITY){
      if (event.selectedIndex === 1) {
        const customerInDefaultContinue = await this.customerValidationsService.validateCustomerInDefault(
          this.currentCustomer.code
        );

        const onBaseCreditStatusContinue = await this.customerValidationsService.validateOnBaseCreditStatus(
          this.currentCustomer.code
        );
        
        if (!customerInDefaultContinue || !onBaseCreditStatusContinue) {
          this.isCanceled = true;
          this.stepper.previous();
        } else if (!this.alreadyPreApproved) {
          await this.customerPreApprovalFormComponent.handlePreApproveClick();
        }
      } else if (!this.alreadyPreApproved) {
        if (this.isCanceled) {
          this.isCanceled = false;
        } else {
          await this.customerPreApprovalFormComponent.handlePreApproveClick();
        }
      }
    }
  }

  resetStepper(): void {
    this.stepper.reset();
    this.alreadyPreApproved = false;
    this.plans = new Paging([]);
  }

  async fetchCreditPlans(): Promise<void> {
    const { branch, salesOrg } =
      this.configurationService.readSelectedBranchAndSalesOrg();

    this.plans = (
      await this.onbaseService
        .readPreApproveCreditPlans(
          this.currentCustomer.code,
          branch.code,
          LimitRulesType.BURO,
          salesOrg.code
        )
        .toPromise()
    ).response;
  }
}
