export class CustomerUpdateContact {
    index: number;
    firstName: string;
    middleName: string;
    firstLastName: string;
    secondLastName: string;
    mobilePhone: string;
    homePhone: string;
    workPhoneNumber: string;
    extension: string;
    email: string;
    tipoRelacion: string;

    constructor() {
        this.index = 0;
        this.firstName = "";
        this.middleName = "";
        this.firstLastName = "";
        this.secondLastName = "";
        this.mobilePhone = "";
        this.homePhone = "";
        this.workPhoneNumber = "";
        this.extension = "";
        this.email = "";
        this.tipoRelacion = "";
    }
}