import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreditProcessStatus, CreditStatus } from 'src/app/@core/constants';
import { ColumnTable } from 'src/app/models/column-table';
import { CreditInProcess } from 'src/app/models/credit-in-process';
import { Paging } from 'src/app/models/page';
import { OnbaseService } from 'src/app/services/onbase.service';

@Component( {
  selector: 'app-customer-credit-request-in-process',
  templateUrl: './customer-credit-request-in-process.component.html',
  styleUrls: [ './customer-credit-request-in-process.component.sass' ]
} )
export class CustomerCreditRequestInProcessComponent implements OnInit {

  public creditRequests: Paging<Array<CreditInProcess>> = new Paging( [] );
  private readonly JURIDICA = 'JURIDICA';

  constructor( private onBaseService: OnbaseService, private route: ActivatedRoute, private datePipe: DatePipe ) { }

  ngOnInit(): void {
    const code = this.route.snapshot.params.id;
    this.getCreditRequests( code );
  }

  async getCreditRequests( code: string ): Promise<void> {
    this.creditRequests = ( await this.onBaseService.getRequests( code ).toPromise() ).response;

    this.creditRequests.content.forEach( request => {
      request.company = request.requestData.Empresa;
      request.division = request.requestData.Division;
      request.identification = request.requestData.Identificacion;
      request.requestDataProcedure = request.requestData.Tramite;
      request.sellerName = request.seller.fullname;
      request.firstStatus = request.status[ 0 ] ?? '';

      const date = this.getDateFromString( request.created );
      request.createdDate = this.datePipe.transform( date, 'dd-MM-yyyy' ) ?? '';

      let name = '';
      if ( request.requestData.Tipo_Cliente === this.JURIDICA ) {
        name = request.requestData.Nombre_Legal;
      } else {
        name = `${request.requestData.Primer_Nombre} ${request.requestData.Segundo_Nombre} ${request.requestData.Primer_Apellido} ${request.requestData.Segundo_Apellido}`;
      }
      request.clientName = name;
    } );
    console.log( this.creditRequests );
  }

  getCreditHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable( 'code', 'Código de Solicitud' ),
      new ColumnTable( 'company', 'Sociedad' ),
      new ColumnTable( 'division', 'Sucursal' ),
      new ColumnTable( 'identification', 'No. Identidad' ),
      new ColumnTable( 'clientName', 'Nombre del Cliente' ),
      new ColumnTable( 'requestDataProcedure', 'Plan de Crédito' ),
      new ColumnTable( 'createdDate', 'Fecha de Solicitud' ),
      new ColumnTable( 'firstStatus', 'Estado Onbase', {
        type: 'badge',
        valueMap: this.getCreditStatusMap()
      } ),
      new ColumnTable( 'processingStatus', 'Estado del Proceso', {
        type: 'badge',
        valueMap: this.getCreditProcessStatusMap()
      } ),
      new ColumnTable( 'sellerName', 'Nombre del Vendedor' ),
    ];
  }

  getCreditsData( event: any ) {
    console.log( event );
  }

  selectCredit( event: any ) {
    console.log( event );
  }

  getCreditStatusMap(): Map<string, string> | undefined {
    const result = new Map<string, string>();
    result.set( CreditStatus.NO_STATUS, 'Sin Estado' );
    result.set( CreditStatus.APPROVED, 'Aprobada' );
    result.set( CreditStatus.REJECTED, 'Rechazada' );
    result.set( CreditStatus.CONTROL_TABLE, 'Mesa de Control' );
    result.set( CreditStatus.TELEPHONE_INVESTIGATION, 'Investigación Telefónica' );
    result.set( CreditStatus.HOME_DOMESTIC_INVESTIGATION, 'Investigación Domiciliaria Casa' );
    result.set( CreditStatus.WORK_DOMESTIC_INVESTIGATION, 'Investigación Domiciliaria Trabajo' );
    result.set( CreditStatus.HUMAN_RESOURCES, 'Recursos Humanos' );
    result.set( CreditStatus.ANALISYS_AND_APPROVAL, 'Análisis y Aprobación' );
    result.set( CreditStatus.WITH_REQUIREMENTS, 'En Requerimiento' );
    result.set( CreditStatus.REPROGRAMMING_IT, 'En reprogramacion IT' );
    result.set( CreditStatus.REPROGRAMMING_HOUSE_ID, 'En reprogramación ID Casa' );
    result.set( CreditStatus.REPROGRAMMING_WORK_ID, 'En reprogramación ID Trabajo' );
    return result;
  }

  getCreditProcessStatusMap(): Map<string, string> | undefined {
    const result = new Map<string, string>();
    result.set( CreditProcessStatus.PROCESSED, 'Procesada' );
    result.set( CreditProcessStatus.FAILED, 'Error' );
    result.set( CreditProcessStatus.UNPROCESSED, 'No procesada' );
    result.set( CreditProcessStatus.DRAFT, 'En Borradores' );
    result.set( CreditProcessStatus.PROCESSING, 'Procesando' );

    return result;
  }

  private getDateFromString( stringDate: string ): Date {

    const year = +stringDate.substring( 0, 4 );
    const month = +stringDate.substring( 4, 6 );
    const day = +stringDate.substring( 6, 8 );
    const date = new Date( year, month - 1, day );

    return date;
  }

}
