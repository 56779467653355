import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionsComponent } from './promotions.component';
import { RouterModule } from '@angular/router';
import { PromotionsRoutingModule } from './promotions-routing.module';
import { PromotionListModule } from './promotion-list/promotion-list.module';
import { PromotionDetailsModule } from './promotion-details/promotion-details.module';




@NgModule({
  declarations: [PromotionsComponent],
  imports: [
    CommonModule,
    RouterModule,
    PromotionsRoutingModule,
    PromotionListModule,
    PromotionDetailsModule
  ],
  exports: [PromotionsComponent]
})
export class PromotionsModule { }
