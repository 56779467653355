import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderStatus } from 'src/app/@core/constants';
import { RejectOrderRequest } from 'src/app/models/requests/reject-order-request';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-pos-order-reject-modal',
  templateUrl: './pos-order-reject-modal.component.html',
  styleUrls: ['./pos-order-reject-modal.component.sass']
})
export class PosOrderRejectModalComponent implements OnInit {

  public title: string = "";

  public message: string = "";

  public orderCode: string = "";

  public observations: string = "";

  public yesFn: (() => any) = (() => { });

  public noFn: (() => any) = (() => { });

  constructor(public bsModalRef: BsModalRef, private orderService: OrderService) {
  }

  ngOnInit(): void {
  }

  async confirm() {
    let rejectOrderRequest: RejectOrderRequest = {
      orderStatus: OrderStatus.REJECTED,
      observations: this.observations
    };
    let response = (await this.orderService
      .updateOrderStatus(this.orderCode, rejectOrderRequest).toPromise()).response;
    if(response) {
      this.yesFn();
      this.bsModalRef.hide();
    }
  }

  cancel() {
    this.noFn();
    this.bsModalRef.hide();
  }

  valdiateObservations() : boolean{
    if(this.observations.length > 0){
      return false;
    }
    return true;
  }

}
