import { Component, Input, OnInit, Output, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Captcha } from 'src/app/models/captcha';
import { CaptchaService } from 'src/app/services/captcha.service';

export const CUSTOM_CONROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PosCaptchaComponent),
  multi: true,
};

@Component({
  selector: 'pos-captcha',
  templateUrl: './pos-captcha.component.html',
  styleUrls: ['./pos-captcha.component.sass'],
  providers: [CUSTOM_CONROL_VALUE_ACCESSOR]
})
export class PosCaptchaComponent implements OnInit, ControlValueAccessor {
  @Input() name: string = "inputCatcha";
  @Input() id: string = "inputCatcha";

  constructor(private captchaService: CaptchaService) { }

  private onChange: any = () => { };
  private onTouch: any = () => { };
  captcha: Captcha = new Captcha();
  private _passwordCaptcha: string = "";

  set value(val: string) {
    if (val !== undefined && this._passwordCaptcha !== val) {
      this._passwordCaptcha = val;
      this.onChange(this._passwordCaptcha);
      this.onTouch(this._passwordCaptcha);
    }
  }

  get value() {
    return this._passwordCaptcha;
  }

  @Output() getCaptchaId = new EventEmitter<string>();

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Leaving this method empty
  }


  async ngOnInit(): Promise<void> {
    this.captcha = (await this.captchaService.readCaptcha().toPromise());
    this.getCaptchaId.emit(this.captcha.code);
  }

  async updateCaptcha() {
    this.captcha = (await this.captchaService.readCaptcha().toPromise());
    this.getCaptchaId.emit(this.captcha.code);
    this.value = "";
  }
}
