<div class="animated fadeIn rounded">
  <div class="card">
    <div class="card-header">
      <i class="cil-fingerprint"></i> Biometría
    </div>
    <div class="card-body">
      <div class="row mb-3">
      </div>
      <div class="mb-3 border">
        <app-customer-information (currentCustomerChange)="handleSelectedCustomerChange($event)" [hideButtons]="true"
          [hideCreditInformation]="true">
        </app-customer-information>
      </div>

      <tabset>
        <!-- <tab>
          <ng-template tabHeading><i class="fa fa-upload"></i> Subir fotografía</ng-template>
          <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-1">
              <label class="mr-3 col-form-label" [for]="'attachment'">
                <b>Imagen</b>
                <span class="text-danger">*</span>
              </label>
            </div>
              <div class="col-4">
                <input type="file" [id]="'attachment'" (change)="onFileChange($event)">
                <label class="custom-file-label mb-2"
                  [for]="'attachment'">{{ filename ? filename : 'Elija el archivo' }}</label>
            </div>
            <div class="col-2">
              <button id="btnSearchCustomer" class="btn btn-outline-primary" (click)="validatePicture()"><i
                class="fa fa-check"></i>&nbsp;Validar
              Fotografía</button>
            </div>
          
          </div>
            <div class="row">
              <canvas #uploadCanvas class="border" width="640" height="480"></canvas>
            </div>
          </div>
        </tab> -->
        <tab>
          <ng-template tabHeading><i class="fa fa-camera"></i> Tomar fotografía</ng-template>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="btn-group mb-3">
                <button id="btnSearchCustomer" class="btn btn-primary" (click)="validatePicture()"><i
                    class="fa fa-check"></i>&nbsp;<span>Validar Fotografía</span></button>
                <button class="btn btn-primary" type="button" [hidden]="toggleVideoFlag" (click)="toggleVideo(true)"
                  title="Activar Cámara">
                  <i class="fa fa-play"></i> Activar Cámara
                </button>
                <button class="btn btn-primary" type="button" [hidden]="!toggleVideoFlag" (click)="toggleVideo(false)"
                  title="Desactivar Cámara">
                  <i class="fa fa-pause"></i> Desactivar Cámara
                </button>
                <button class="btn btn-primary" type="button" (click)="capturePhoto()" title="Tomar Foto">
                  <i class="fa fa-camera"></i> Tomar Foto
                </button>
                <button class="btn btn-primary" type="button" (click)="deletePicture()" title="Limpiar">
                  <i class="fa fa-trash"></i> Limpiar
                </button>
              </div>
            </div>
            <div class="col-8 col-md-4">
              <h5>Resultado</h5>
              <h5 [hidden]="!result.valid || !result.errorMessage" class="bg-success"><i
                  class="fa fa-check "></i>Proceda a ingresar facturación ó solicitud de crédito</h5>
              <h5 [hidden]="result.valid || !result.errorMessage" class="bg-danger">
                Revisión con el área de crédito</h5>
              <h5 [hidden]="result.errorMessage" class="bg-warning">Pendiente</h5>
            </div>

          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <video class="border" #hardwareVideo width="640" height="480" [hidden]="pictureTaken"></video>
                  <canvas #takenCanvas class="border" width="640" height="480" [hidden]="!pictureTaken"></canvas>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <tab>
          <ng-template tabHeading><i class="fa fa-camera"></i> Tomar huella digital</ng-template>
          <app-biometry-finger-print #biometryFingerPrint [customerSelected]="customer"></app-biometry-finger-print>
        </tab>

      </tabset>
    </div>
  </div>
</div>

<!-- Nav tabs -->


<!--/.col-->
