import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseModel } from '../models/response-model';
import { Paging } from '../models/page';
import { PaymentMethod } from '../models/payment-method';
import { Observable } from 'rxjs';
import { PaymentMethodType } from '../models/payment-method-type';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private url = `${environment.url}/paymentTypes`;

  constructor(private http: HttpClient) { }

  getPaymentsType(): Observable<ResponseModel<Paging<Array<PaymentMethod>>>> {

    return this.http.get<ResponseModel<Paging<Array<PaymentMethod>>>>(this.url);
  }

  getPaymentMethods(salesOrg?:string): Observable<ResponseModel<Paging<Array<PaymentMethodType>>>> {

    const url = `${environment.url}/paymentMethods`;

    let params = new HttpParams();

    if(salesOrg){
      params = params.set(`salesOrg`,salesOrg);
    }

    return this.http.get<ResponseModel<Paging<Array<PaymentMethodType>>>>(url,{params});
  }
}
