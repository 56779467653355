<div class="row">
  <div class="col-md-5 col-sm-12">
    <div class="card">
      <div class="card-header d-flex align-items-center">
        <i class="fa fa-address-card mr-2"></i> Información general
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <div class="row align-items-center">
              <span class="mr-2">
                <i class="fa fa-user "></i>
              </span>
              <span>
                {{ currentCustomer?.mainPersonalInfo?.fullName }}
              </span>
            </div>
            <div class="row align-items-center">
              <span class="mr-2">
                <i class="fa fa-id-card "></i>
              </span>
              <span>
                {{
                currentCustomer?.mainPersonalInfo?.fiscalId
                ? currentCustomer?.mainPersonalInfo?.fiscalId
                : ""
                }}
              </span>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="row align-items-center">
              <span class="mr-2">
                <i class="fa fa-building "></i>
              </span>
              <span class="mr-1">Tipo:</span>
              <span>
                {{
                currentCustomer?.mainPersonalInfo?.personality === "PERSON"
                ? "NATURAL"
                : "JURIDICO"
                }}
              </span>
            </div>
            <div class="row align-items-center">
              <span class="mr-2">
                <i class="fa fa-calendar "></i>
              </span>
              <span class="mr-1">Edad:</span>
              <span>
                {{
                getCalculateAge(
                currentCustomer?.mainPersonalInfo?.birthdate || ""
                ) || ""
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3 col-sm-12">
    <div class="card">
      <div class="card-header d-flex align-items-center">
        <i class="fa fa-credit-card "></i> Información crediticia
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="row align-items-center">
              <span class="mr-2">
                <i class="fa fa-user "></i>
              </span>
              <span class="mr-1">Estado:</span>
              <span>
                {{ currentCustomerCreditConditions?.estadoCliente }}
              </span>
            </div>
            <div class="row align-items-center">
              <span class="mr-2">
                <i class="fa fa-info-circle "></i>
              </span>
              <span>
                {{ currentCustomerCreditConditions?.mensajeTipoAccion }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-sm-12">
    <app-customer-phone-and-mail [customer]="this.currentCustomer"></app-customer-phone-and-mail>
  </div>
</div>
