import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosUpdateRtnFiscalIdModalComponent } from './pos-update-rtn-fiscal-id-modal.component';
import { CustomerService } from 'src/app/services/customer.service';
import { PosUpdateRtnFiscalIdModalService } from 'src/app/@core/services/pos-update-rtn-fiscal-id-modal.service';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxMaskModule } from 'ngx-mask';



@NgModule({
  declarations: [PosUpdateRtnFiscalIdModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    NgxMaskModule
  ],
  providers: [CustomerService, PosUpdateRtnFiscalIdModalService],
  exports: [PosUpdateRtnFiscalIdModalComponent]
})
export class PosUpdateRtnFiscalIdModalModule { }
