import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  ModalDialogType,
  OrderConditions,
  OrderStatus,
  OrderType
} from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { FileUtils } from 'src/app/@core/utils/file-utils';
import { ColumnTable } from 'src/app/models/column-table';
import { Discount } from 'src/app/models/discount';
import { Franchise } from 'src/app/models/franchised';
import { Loan } from 'src/app/models/loan';
import { LoanCondition } from 'src/app/models/loan-condition';
import { Order } from 'src/app/models/order';
import { Paging } from 'src/app/models/page';
import { ProductCart } from 'src/app/models/product-cart';
import { LoanSimulationRequest } from 'src/app/models/requests/loan-simulation-request';
import { OrderRequest } from 'src/app/models/requests/order-request';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { Tax } from 'src/app/models/tax';
import { TaxExemption } from 'src/app/models/tax-exemption';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerService } from 'src/app/services/customer.service';
import { OrderService } from 'src/app/services/order.service';
import { TaxesService } from 'src/app/services/taxes.service';

@Component({
  selector: 'save-step',
  templateUrl: './save-step.component.html',
  styleUrls: ['./save-step.component.sass'],
})
export class SaveStepComponent implements OnInit {
  @Input()
  customer: CustomerTableModel = new CustomerTableModel();

  @Input()
  employee: CustomerTableModel = new CustomerTableModel();

  @Input()
  taxExemption: TaxExemption = new TaxExemption();

  @Input()
  orderDiscount: Discount = new Discount();

  @Input()
  productsCart: Paging<Array<ProductCart>> = new Paging<Array<ProductCart>>([]);

  @Input()
  loanParameters: Array<LoanSimulationRequest> = new Array();

  @Input()
  order: Order = new Order();

  @Input()
  fiscalIdFile?: any;

  @Input()
  rtnFiscalIdFile?: any;

  @Input()
  ip800File?: any;

  @Input()
  proofOfPaymentFile?: any;

  @Input()
  purchaseOrderFile?: any;

  @Input()
  orderCondition = 'credito';
  taxes: Array<Tax> = [];

  @Input()
  isModify: boolean = false;

  @Input()
  orderTypeId: number = 0;

  @Input()
  observations: string = "";

  franchised = new Franchise();

  constructor(
    private orderService: OrderService,
    private taxService: TaxesService,
    private configService: ConfigurationService,
    private confirmService: PosConfirmationService,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    this.taxes = (
      await this.taxService.readTaxes().toPromise()
    ).response.content;
    this.franchised =
      this.configService.readSelectedBranchAndSalesOrg().branch.franquicia;
    this.orderTypeId = this.order.orderCondition!;
  }

  getProductCartHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('amount', '#', {
        type: 'number',
        iconClass: 'fa fa-shopping-cart',
        isIcon: true,
      }),
      new ColumnTable('productCode', 'Código', { isSmall: true }),
      new ColumnTable('description', 'Descripción', { isSmall: true }),
      new ColumnTable('serial', 'Serie', { type: 'badge' }),
      new ColumnTable('price', 'Precio', { type: 'currency' }),
    ];

  }

  private async createOrderCompleteProcess(orderType?: OrderType) {
    let orderRequest = new OrderRequest().buildOrderRequest(
      this.productsCart.content,
      this.taxes,
      this.customer,
      this.employee,
      this.configService.readSelectedBranchAndSalesOrg().branch.code,
      this.configService.readSelectedBranchAndSalesOrg().salesOrg.code,
      this.taxExemption,
      this.orderDiscount,
      Array.from(this.order.appliedPromotions),
      this.configService.readPhysicalEstablishment().code,
      this.observations,
      orderType,
      OrderStatus.INITIAL,
      this.orderTypeId,
      this.franchised
    );

    orderRequest.orderEntryList.forEach((p, index) => {
      const { term, maxTerm, minTerm, rate, rateType, downPayment } =
        this.order.orderEntryList[index];
      p.term = term;
      p.maxTerm = maxTerm;
      p.minTerm = minTerm;
      p.rate = rate;
      p.rateType = rateType;
      p.downPayment = downPayment;
    });

    if (this.orderTypeId == OrderConditions.UNTIL_30_DAYS_FRANCHISED) {
      let params = this.loanParameters[0];
      orderRequest.orderEntryList.forEach(f => {
        f.term = parseInt(params.term);
        f.rate = parseInt(params.rate);
        f.minTerm = params.minTerm!;
        f.maxTerm = params.maxTerm!;
        f.rateType = params.rateType;
        f.downPayment = params.downPayment;
      })

    }

    if (!this.validateTax(orderRequest)) {
      return;
    }

    let result = await this.orderService
      .createOrderCompleteProcess(
        orderRequest,
        this.loanParameters,
        {
          fiscalId: await FileUtils.getBase64(this.fiscalIdFile),
          rtnFiscalId: await FileUtils.getBase64(this.rtnFiscalIdFile),
          ip800FiscalId: await FileUtils.getBase64(this.ip800File),
          proofOfPayment: await FileUtils.getBase64(this.proofOfPaymentFile),
        },
        {
          fiscalId: this.fiscalIdFile.name,
          rtnFiscalId: this.rtnFiscalIdFile?.name,
          ip800: this.ip800File.name,
          proofOfPayment: this.proofOfPaymentFile?.name
        }
      )
      .toPromise()
      .then((t) => {
        this.toastrService.success('La Órden fue creada satisfactoriamente.');
        return t;
      });
    this.router.navigate(['orders', result.response.order.code]);
  }

  async createOrder(): Promise<void> {
    await this.validateCustomerEmailAndCreateOrder();
  }

  async validateCustomerEmailAndCreateOrder() {
    let customerCode = this.customer.code;
    if (this.orderTypeId == 1 || this.orderTypeId == 2) {
      customerCode = this.order.paymentResponsible;
    }

    let hasEmail = (await this.customerService.validateCustomerEmail(customerCode).toPromise()).response;
    if ((this.orderTypeId == 1 || this.orderTypeId == 2 || this.orderTypeId == 3)
      && !hasEmail) {
      this.confirmService.show(
        '¡Advertencia!',
        '¿Se requiere un correo electrónico para continuar con el proceso, desea hacer la actualización del correo ahora? Si confirma se guardara la cotización.',
        ModalDialogType.WARNING,
        async () => {
          if (this.taxExemption.documentId.length > 0) {
            await this.toastrService.error("No se puede generar la cotización ya que cuenta con una exoneración");
            return;
          }
          await this.createOrderCompleteProcess(OrderType.QUOTATION);
          this.router.navigate(['customer/update', customerCode]);
        });
    }
    else {
      this.confirmService.show(
        '¡Advertencia!',
        '¿Está seguro de crear la órden con los datos solicitados?',
        ModalDialogType.WARNING,
        async () => {
          await this.createOrderCompleteProcess();
        }
      );
    }
  }

  async createQuotation(): Promise<void> {
    this.confirmService.show(
      '¡Advertencia!',
      '¿Está seguro de crear la cotización con los datos solicitados?',
      ModalDialogType.WARNING,
      async () => {
        if (this.taxExemption.documentId.length > 0) {
          await this.toastrService.error("No se puede generar la cotización ya que cuenta con una exoneración");
          return;
        }
        await this.createOrderCompleteProcess(OrderType.QUOTATION);
      }
    );
  }

  private async updateOrderCompleteProcess(orderType?: OrderType) {
    let orderRequest = new OrderRequest().buildOrderRequest(
      this.productsCart.content,
      this.taxes,
      this.customer,
      this.employee,
      this.configService.readSelectedBranchAndSalesOrg().branch.code,
      this.configService.readSelectedBranchAndSalesOrg().salesOrg.code,
      this.taxExemption,
      this.orderDiscount,
      Array.from(this.order.appliedPromotions),
      this.configService.readPhysicalEstablishment().code,
      this.observations,
      orderType || OrderType.REGULAR,
      OrderStatus.INITIAL,
      this.orderTypeId,
      this.franchised
    );

    orderRequest.orderEntryList.forEach((p, index) => {
      const { term, maxTerm, minTerm, rate, rateType, downPayment } =
        this.order.orderEntryList[index];
      p.term = term;
      p.maxTerm = maxTerm;
      p.minTerm = minTerm;
      p.rate = rate;
      p.rateType = rateType;
      p.downPayment = downPayment;
    });

    if (!this.validateTax(orderRequest)) {
      return;
    }


    let result = await this.orderService
      .updateOrderCompleteProcess(
        this.route.snapshot.params.code,
        orderRequest,
        OrderConditions.CASH_BILLLING !== this.orderTypeId ? this.loanParameters : [],
        {
          fiscalId: await FileUtils.getBase64(this.fiscalIdFile),
          rtnFiscalId: await FileUtils.getBase64(this.rtnFiscalIdFile),
          ip800FiscalId: await FileUtils.getBase64(this.ip800File),
          proofOfPayment: await FileUtils.getBase64(this.proofOfPaymentFile),
          purchaseOrder: await FileUtils.getBase64(this.purchaseOrderFile),
        },
        {
          fiscalId: this.fiscalIdFile?.name,
          rtnFiscalId: this.rtnFiscalIdFile?.name,
          ip800: this.ip800File?.name,
          proofOfPayment: this.proofOfPaymentFile?.name,
          purchaseOrder: this.purchaseOrderFile?.name
        }
      )
      .toPromise()
      .then((t) => {
        this.toastrService.success(
          'La Órden fue modificada satisfactoriamente.'
        );
        return t;
      });
    this.router.navigate(['orders', result.response.order.code]);
  }

  async modifyOrder(): Promise<void> {
    this.confirmService.show(
      '¡Advertencia!',
      `¿Está seguro de modificar la órden ${this.route.snapshot.params.code} con los datos solicitados?`,
      ModalDialogType.WARNING,
      async () => {
        await this.updateOrderCompleteProcess();
      }
    );
  }
  validateTax(orderRequest: OrderRequest) {
    let valid = true;
    orderRequest.orderEntryList.forEach(f => {
      if (!f.tax.code) {
        valid = false;
        return;
      }
    });

    if (!valid) {
      this.toastrService.warning("Esta orden no cuenta con un impuesto válido, vuelva a crear la orden");

    }
    return valid;

  }

  async modifyQuotation(): Promise<void> {
    this.confirmService.show(
      '¡Advertencia!',
      '¿Está seguro de crear la cotización con los datos solicitados?',
      ModalDialogType.WARNING,
      async () => {
        await this.updateOrderCompleteProcess(OrderType.QUOTATION);
      }
    );
  }

  getLoanFeeCondition(loan: Loan) {
    return (
      loan.conditions.find((f) => f.conditionType == '0261') ||
      loan.conditions.find((f) => f.conditionType == '0262') ||
      new LoanCondition()
    );
  }

  validateCreateOrder() {
    if (this.orderTypeId == 1 || this.orderTypeId == 2) {
      if (!this.purchaseOrderFile) {
        return true;
      }
    }
    return false;
  }
}
