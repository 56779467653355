import { CustomerType } from "src/app/@core/constants";

export class CustomerCreateResponseModel {
    code: string;
    customerType: CustomerType;
    personalInfo: PersonalInfo[];
    fullName: string;

    constructor() {
        this.code = "";
        this.customerType = CustomerType.RETAIL;
        this.personalInfo = new Array<PersonalInfo>();
        this.fullName = "";
    }
}

export interface Address {
    area: string;
    block: string;
    city: string;
    country: string;
    department: string;
    fullStreet: string;
    homeType: string;
    houseNumber: string;
    houseType: string;
    startingDate: Date;
    state: string;
    street: string;
    street2: string;
    street3: string;
    street4: string;
    street5: string;
    type: string;
    zip: string;
}

export interface Phone {
    areaCode: string;
    city: string;
    country: string;
    countryCode: string;
    department: string;
    ext: string;
    phoneNumber: string;
    type: string;
}

export interface PersonalInfo {
    addresses: Address[];
    age: number;
    birthdate: Date | String;
    dependents: number;
    email: string;
    email2: string;
    familyName: string;
    fiscalId: string;
    fiscalId2: string;
    fiscalIdType: string;
    fiscalIdType2: string;
    fullName: string;
    gender: string;
    incomeSource: string;
    lastDegree: string;
    lastName: string;
    lastName2: string;
    maritalStatus: string;
    middleName: string;
    name: string;
    nationality: string;
    ownCar: boolean;
    personality: string;
    phones: Phone[];
    profession: string;
    relationship: string;
    universityDegree: string;
}