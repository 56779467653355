import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PosPaymentDetailsComponent } from '../pos-payment-details.component';
import { PosTableModule } from '../../pos-table/pos-table.module';



@NgModule({
  declarations: [PosPaymentDetailsComponent],
  imports: [
    CommonModule,
    AccordionModule,
    PosTableModule
  ],
  exports:[PosPaymentDetailsComponent]
})
export class PosPaymentDetailsModule { }
