export class Totals {
    netTotal: number;
    grossTotal: number;
    grossTotalInWriting: string;
    taxed15Amount: number;
    taxed18Amount: number;
    exemptAmount: number;
    exoneratedAmount: number;
    taxed15Discount: number;
    taxed18Discount: number;
    exemptDiscount: number;
    exoneratedDiscount: number;
    discountTotal: number;
    surchargeTotal: number;
    taxTotal: number;
    taxedAmount: number;
    aliquoteAmount: number;
    aliquoteTaxTotal: number;

    constructor() {
        this.netTotal = 0.0;
        this.grossTotal = 0.0;
        this.grossTotalInWriting = "";
        this.taxed15Amount = 0.0;
        this.taxed18Amount = 0.0;
        this.exemptAmount = 0.0;
        this.exoneratedAmount = 0.0;
        this.taxed15Discount = 0.0;
        this.taxed18Discount = 0.0;
        this.exemptDiscount = 0.0;
        this.exoneratedDiscount = 0.0;
        this.discountTotal = 0.0;
        this.surchargeTotal = 0.0;
        this.taxTotal = 0.0;
        this.taxedAmount = 0.0;
        this.aliquoteAmount = 0.0;
        this.aliquoteTaxTotal = 0.0;
    }
}