import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Advance } from 'src/app/models/advance';
import { ColumnTable } from 'src/app/models/column-table';
import { Paging } from 'src/app/models/page';
import { AdvanceService } from 'src/app/services/advance.service';
import { Filter } from 'src/app/models/filter';
import { AdvanceFilter } from 'src/app/models/filters/advance-filter';
import { AdvanceReturnType, ModalDialogType } from 'src/app/@core/constants';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/app/services/report.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Customer } from 'src/app/models/customer';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';

@Component({
  selector: 'app-customer-advances',
  templateUrl: './customer-advances.component.html',
  styleUrls: ['./customer-advances.component.sass']
})
export class CustomerAdvancesComponent implements OnInit {
  private customerCode = '';
  private filter: Filter<AdvanceFilter> = { page: 0, pageSize: 10, value: { isReturn: false } };
  customer: Customer;
  totalAmount: number;

  advances: Paging<Array<Advance>> = new Paging([]);

  constructor(private advanceService: AdvanceService, private route: ActivatedRoute, private datePipe: DatePipe, private toastrService: ToastrService, private reportService: ReportService, private customerService: CustomerService, private configurationService: ConfigurationService, private confirmationService: PosConfirmationService) {
    this.customer = new Customer();
    this.totalAmount = 0;
  }

  async ngOnInit(): Promise<void> {
    const code = this.route.snapshot.params.id;
    this.customerCode = code;
    this.filter.value.customerCode = code;
    this.getAdvances();
    this.getCustomerInfo();
  }

  async getAdvances(): Promise<void> {
    this.advances = (await this.advanceService.getAdvances(this.filter).toPromise()).response;
    this.advances.content.forEach(advance => {
      const date = new Date(advance.timeStamp);
      advance.dateString = this.datePipe.transform(date, 'dd/MM/yyyy') ?? "";
      if (advance.returnAdvanceCode && advance.returnType == null) {
        advance.returnType = AdvanceReturnType.ADVANCE_REFUND;
      }
      if (advance.returnAdvanceCode == undefined) {
        advance.returnType = "";
      }
    });
  }

  getAdvanceHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('receiptCode', 'Código'),
      new ColumnTable('dateString', 'Fecha'),
      new ColumnTable('totalAmount', 'Total'),
      new ColumnTable('returnType', 'Estado', { type: "badge", valueMap: this.getReturnType() }),
      new ColumnTable('code', 'Opciones', {
        type: 'action',
        actions: [
          {
            fn: advance => this.printAdvance(advance),
            icon: 'fa fa-print',
            tooltip: 'Imprimir recibo',
            isDisabled: false,
            isPrivileged: true,
            privilege: 'printAdvance_Before24H',
            disabledFn: (advance: Advance) => advance.returnType !== AdvanceReturnType.ADVANCE_REFUND
          },
          {
            fn: advance => this.requestRefund(advance),
            icon: 'fa fa-money',
            tooltip: 'Solicitud de cheque',
            isDisabled: false,
            disabledFn: (advance: Advance) => advance.returnAdvanceCode ? true : false
          }
        ]
      }),
    ];
  }

  async printAdvance(advance: Advance) {
    if (advance.returnAdvanceCode) {
      this.reportService.printReport(await this.reportService.printItemLiquidation(advance.returnAdvanceCode).toPromise());
    } else {
      this.reportService.printReport(await this.reportService.itemReservationPdf(advance.returnAdvanceCode, advance.salesOrg).toPromise());
    }
  }

  async requestRefund(advance: Advance) {
    await this.confirmationService.show("Solicitud de cheque", "¿Esta seguro que desea continuar con la solicitud de cheque?", ModalDialogType.WARNING, async () => {
      if (advance.salesOrg == this.configurationService.readSelectedBranchAndSalesOrg().salesOrg.code) {
        this.advanceService.requestRefund(advance).subscribe(
          data => {
            this.toastrService.success(`Solicitud de cheque`, `Operacion Exitosa`);
            this.getAdvances();
          }
        );
      }
      else {
        this.toastrService.warning(`Solicitud de cheque`, `No se puede hacer la solicitud de cheque en otra sociedad`);
      }
    });
  }

  getAdvancesByPage(event: Filter<AdvanceFilter>): void {
    this.filter = event;
    this.filter.value.isReturn = false;
    this.filter.value.customerCode = this.customerCode;
    this.getAdvances();
  }

  private async getCustomerInfo(): Promise<void> {

    this.customer = (await this.customerService.readCustomerByCode(this.customerCode).toPromise());
    const response = (await this.advanceService.getAdvancesBalance(this.customerCode).toPromise()).response;
    this.totalAmount = response.totalAmount;
  }


  private getReturnType(): Map<string, string> {
    let result = new Map<string, string>();
    result.set("", "Activo");
    result.set("ADVANCE_REFUND", "Devuelto");
    result.set("ADVANCE_REQUEST_REFUND", "Solicitud de cheque realizada");

    return result;
  }
}
