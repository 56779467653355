export class OrderType {
  id: string;
  description: string;
  hasPaymentResponsible: boolean;
  hasCreditLineActive: boolean;
  hasBranchCreditLineActive: boolean;

  constructor(
    id: string, 
    description: string, 
    hasPaymentResponsible: boolean, 
    hasCreditLineActive: boolean, 
    hasBranchCreditLineActive: boolean
  ) {
    this.id = id;
    this.description = description;
    this.hasPaymentResponsible = hasPaymentResponsible;
    this.hasCreditLineActive = hasCreditLineActive;
    this.hasBranchCreditLineActive = hasBranchCreditLineActive;
  }
}
