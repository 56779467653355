import { DiscountType, OrderStatus, OrderType, OrderConditions } from "src/app/@core/constants";
import { Customer } from "../customer";
import { Discount } from "../discount";
import { Franchise } from "../franchised";
import { ProductCart } from "../product-cart";
import { CustomerTableModel } from "../table-models/customer-table";
import { Tax } from "../tax";
import { TaxExemption } from "../tax-exemption";
import { OrderEntryRequest } from "./order-entry-request";

export class OrderRequest {
    code: string;
    salesOrgCode: string;
    branchCode: string;
    customerCode: string;
    customerName: string;
    customerLastName: string;
    customerFiscalId: string;
    exonerationReason?: string;
    exonerationDocumentId?: string;
    exonerationDocumentExpirationDate?: string;
    exemptionReason?: string;
    exemptionDocumentId?: string;
    exemptionExpirationDate?: string;
    routeCode?: string;
    cancellationReasonCode?: string;
    invoiceCode?: string;
    invoiceNumber?: string;
    receiptCode?: string;
    orderType: OrderType;
    salesRequestNumber: string;
    vendor: string;
    vendorName: string;
    discounts?: Array<Discount>;
    shippingCost?: number;
    insuranceCost: number;
    currency?: string;
    orderEntryList: Array<OrderEntryRequest>;
    status: OrderStatus;
    extraFinancingPercentage?: number;
    condition: string;
    customerCredityType?: string;
    creditProcedure?: string;
    appliedPromotions: Array<string>;
    printingAuthorizationKey?: string;
    originalInvoiceDate?: Date;
    originalExternalInvoices?: string;
    reserved?: Boolean;
    repairOrder?: string;
    repairSerial?: string;
    repairMakeId?: string;
    repairColor?: string;
    repairKms?: string;
    repairModel?: string;
    selfInvoiceSurcharge?: number;
    tax?: number;
    paymentCondition?: string;
    isDummy?: Boolean;
    contractNumber?: string;
    isThirtyDays: boolean;
    paymentResponsible: string;
    paymentResponsibleName: string;
    orderCondition?: number;
    physicalEstablishmentCode: string;
    observations: string;
    constructor() {
        this.insuranceCost = 0;
        this.orderEntryList = [];
        this.orderType = OrderType.REGULAR;
        this.vendor = "";
        this.vendorName = "";
        this.salesOrgCode = "";
        this.branchCode = "";
        this.customerCode = "";
        this.customerName = "";
        this.customerLastName = "";
        this.customerFiscalId = "";
        this.condition = "contado";
        this.code = "";
        this.appliedPromotions = [];
        this.salesRequestNumber = "";
        this.status = OrderStatus.NONE;
        this.isThirtyDays = false;
        this.paymentResponsible = "";
        this.paymentResponsibleName = "";
        this.physicalEstablishmentCode = "";
        this.observations = "";
    }


    buildOrderRequest(productCart: Array<ProductCart>, taxes: Array<Tax>, customer: CustomerTableModel, seller: CustomerTableModel, branchCode: string, salesOrgCode: string, exoneration: TaxExemption, discount: Discount, activePromotions: Array<string>, physicalEstablishmentCode: string, observations: string, orderType?: OrderType, orderStatus?: OrderStatus, orderTypeId?: number, franchise?: Franchise) {
        let result = new OrderRequest();

        result.insuranceCost = 0;
        productCart.forEach(f => {
            let services = productCart.filter(x => x.referenceCode == f.code)
            let entry = new OrderEntryRequest();
            entry.productCode = f.productCode;
            entry.productName = f.description;
            entry.totalQuantity = f.amount;
            entry.price.amount = f.total;
            entry.price.currency = "HNL";
            entry.tax = this.getTax(taxes, f, exoneration);
            entry.taxProduct = this.getTax(taxes, f, exoneration);
            entry.code = f.code.toString();
            entry.serialNumber = f.serial;
            entry.services = services.map(m => m.code.toString())
            f.discount.discountType != DiscountType.NONE && entry.discounts.push(f.discount);
            result.orderEntryList.push(entry);
        })
        result.orderType = orderType || OrderType.REGULAR;
        result.vendor = seller.code;
        result.vendorName = seller.fullName;
        result.customerCode = customer.code;
        result.customerName = customer.fullName;
        result.customerFiscalId = customer.useFiscalId2 ? customer.fiscalId2 : customer.fiscalId;
        //result.customerLastName = customer.getMainPersonalInfo().lastName;
        result.branchCode = branchCode;
        result.salesOrgCode = salesOrgCode;
        result.salesRequestNumber = "";
        result.status = orderStatus || OrderStatus.INITIAL
        result.exonerationDocumentExpirationDate = exoneration.date;
        result.exonerationDocumentId = exoneration.documentId;
        result.exonerationReason = exoneration.reason.reason;
        result.appliedPromotions = activePromotions;
        if (!(discount.discountType == DiscountType.NONE)) {
            result.discounts = [discount];
        }

        result.orderCondition = orderTypeId;
        switch (orderTypeId as OrderConditions) {
            case OrderConditions.UNTIL_30_DAYS_FRANCHISED:
                result.condition = "credito";
                result.isThirtyDays = true;
                result.paymentResponsible = franchise!.code;
                result.paymentResponsibleName = franchise!.name;
                break;
            case OrderConditions.MORE_THAN_30_DAYS_FRANCHISED:
                result.condition = "credito";
                result.isThirtyDays = false;
                result.paymentResponsible = franchise!.code;
                result.paymentResponsibleName = franchise!.name;
                break;
            case OrderConditions.FINAL_CUSTOMER_CREDIT:
                result.condition = "credito";
                result.isThirtyDays = false;
                break;
            case OrderConditions.CREDIT_QUOTATION:
                result.condition = "credito";
                result.isThirtyDays = false;
                break;
            case OrderConditions.CASH_BILLLING:
                result.isThirtyDays = false;
                break;
        }
        result.physicalEstablishmentCode = physicalEstablishmentCode;

        result.observations = observations;
        return result;
    }

    private getTax(taxes: Tax[], f: ProductCart, exoneration: TaxExemption): Tax {
        let tax = exoneration.reason.taxType ? (exoneration.reason.notReplace == f.taxCode ? f.taxCode : exoneration.reason.taxType) : f.taxCode;

        return taxes.find(t => t.code == tax) || new Tax();
    }
}