export class FileUtils {
    static blobToFile(blob: Blob, fileName: string) {
        let result: any = blob;
        result.lastModifiedDate = new Date();
        result.name = fileName;
        return <File>result;
    }
    static getBase64(file: any): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!file) {
                resolve("");
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result?.toString() || "");
            }
        })

    }
    static dataURLtoFile(dataurl: string, filename: string) {

        if(dataurl !== ''){

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)![1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
    
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
    
            return new File([u8arr], filename, { type: mime });
        }

        return null;
    }

    static dataURLtoBlob(dataurl: string) {
        if (!dataurl) {
            return new Blob();
        }
        var byteString = atob(dataurl.split(',')[1]);
        var mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0]
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var uIntArray = new Uint8Array(arrayBuffer);
        for (var i = 0; i < byteString.length; i++) {
            uIntArray[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([arrayBuffer], { type: mimeString });
        return blob;
    }

    static showFile() {

    }
}