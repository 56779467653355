import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BiometryType } from 'src/app/@core/constants';
import { FileUtils } from 'src/app/@core/utils/file-utils';
import { Customer } from 'src/app/models/customer';
import { BiometryValidationResponse } from 'src/app/models/response/biometry-validation-response';
import { CreditStatusFranchisedResponseModel } from 'src/app/models/response/customerCreditStatusResponseModel';
import { BiometryService } from 'src/app/services/biometry.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { BiometryFingerPrintComponent } from '../biometryFingerPrint/biometryFingerprint.component';

@Component({
  selector: 'app-biometry',
  templateUrl: './biometry.component.html',
  styleUrls: ['./biometry.component.sass']
})
export class BiometryComponent implements OnInit {


  public filename: string = "";
  public customer: Customer = new Customer();
  @ViewChild("uploadCanvas") uploadCanvas: any;
  @ViewChild('hardwareVideo') hardwareVideo: any;
  @ViewChild("takenCanvas") takenCanvas: any;
  public picture: string = "";
  public toggleVideoFlag: boolean = false;
  public pictureTaken: boolean = false;
  public result: BiometryValidationResponse = new BiometryValidationResponse();

  @ViewChild('biometryFingerPrint') biometryFingerPrintComponent!: BiometryFingerPrintComponent;

  constructor(private datePipe: DatePipe, private biometryService: BiometryService, private configService: ConfigurationService, private authService: AuthService) { }
  ngOnInit(): void {
  }

  async onFileChange(event: any) {
    let img = new Image();
    let ctx = this.uploadCanvas?.nativeElement.getContext("2d");
    ctx.clearRect(0, 0, 640, 480);
    img.onload = () => {

      var hRatio = 640 / img.width;
      var vRatio = 480 / img.height;
      var ratio = Math.min(hRatio, vRatio);
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width * ratio, img.height * ratio);
      this.picture = this.uploadCanvas.nativeElement.toDataURL("image/jpeg");
    };
    img.src = await FileUtils.getBase64(event.target.files[0]);
    this.filename = event.target.files[0].name;
  }

  async handleSelectedCustomerChange(customerData: {
    customer: Customer;
    customerCreditConditions: CreditStatusFranchisedResponseModel;
  }): Promise<void> {
    this.customer = customerData.customer;
    this.deletePicture();
    this.clear();
  }

  private clear() {
    this.result = new BiometryValidationResponse();
    this.biometryFingerPrintComponent.clean();
  }

  toggleVideo(toggle: boolean, pictureTaken?: boolean) {
    let video = this.hardwareVideo.nativeElement;

    var n = navigator as any;

    n.getUserMedia = (n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia);

    n.mediaDevices.getUserMedia({ video: true }).then(function (stream: any) {
      if (toggle) {
        video.srcObject = stream;
        video.play();
      }
      else {
        video.srcObject = null;
        video.stop();
      }
    });
    this.toggleVideoFlag = toggle;
    this.pictureTaken = pictureTaken == true;
    // NOTE: For Video + Audio
    // n.mediaDevices.getUserMedia({video: true, audio: true}).then(function(stream) {
    //     video.src = window.URL.createObjectURL(stream);
    //     video.play();
    // });



  }
  public capturePhoto() {
    if (!this.hardwareVideo || !this.toggleVideoFlag) {
      return;
    }
    var context = this.takenCanvas.nativeElement.getContext("2d").drawImage(this.hardwareVideo.nativeElement, 0, 0, 640, 480);
    this.picture = this.takenCanvas.nativeElement.toDataURL("image/jpeg");
    this.filename = `${this.customer.code}_${this.datePipe.transform(new Date(), "YYYYMMDDHHmmSS")}.jpg`
    this.toggleVideo(false, true);

  }

  public deletePicture() {
    this.picture = "";
    this.toggleVideo(false);
    this.clear();
  }

  public async validatePicture() {
    this.clear();
    this.result = (await this.biometryService.validateBiometry({ branchCode: this.configService.readSelectedBranchAndSalesOrg().branch.code, customerCode: this.customer.code, imageFileName: this.filename, imageStringBase64: this.picture, type: BiometryType.PHOTO, user: (await this.authService.readProfile()).username }).toPromise()).response;
  }

}
