import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { promise } from 'protractor';
import { filter } from 'rxjs/operators';
import { ColumnTable } from 'src/app/models/column-table';
import { Filter } from 'src/app/models/filter';
import { FilterTable } from 'src/app/models/filter-table';
import { ProductFilter } from 'src/app/models/filters/product-filter';
import { Paging } from 'src/app/models/page';
import { Product } from 'src/app/models/product';
import { ProductTableModel } from 'src/app/models/table-models/product-table';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.sass']
})
export class ProductListComponent implements OnInit {

  constructor(
    private productService: ProductsService,
    private router: Router) { }

  private productResults: Paging<Array<Product>> = new Paging([]);
  public products: Paging<Array<ProductTableModel>> = new Paging([]);

  async ngOnInit(): Promise<void> {
    await this.getProductData({ page: 0, pageSize: 10, value: {} })
  }

  getProductTableColumns(): Array<ColumnTable> {

    return [
      new ColumnTable('code', "Código de producto"),
      new ColumnTable('description', "Descripción"),
      new ColumnTable('price', "Precio", { type: "number" }),
      new ColumnTable('stock', "Disponible", { type: "number" }),
      new ColumnTable("code",
        "Opciones", {
        type: "action", actions: [{
          fn: (a: Product) => {
            let product = this.productResults.content.find(p => p.code == a.code && p.description == a.description);
            this.router.navigate(['products', a.code],
              {
                state: {
                  product
                }
              });
          },
          icon: "fa fa-arrow-circle-o-right",
          tooltip: "Detalle del producto",
          isDisabled: false
        }]
      }
      )
    ];
  }

  getProductFilterColumns(): Array<FilterTable> {
    return [
      new FilterTable('text', "General"),
      new FilterTable('ean', "EAN"),
      new FilterTable("hasExistence", "Existencia", { type: "switch" })
    ];
  }

  async getProductData(params: Filter<ProductFilter>): Promise<void> {
    this.productResults = (await this.productService.readProducts(params).toPromise()).response;

    this.products = new Paging(this.productResults.content.map(m => {
      let model = new ProductTableModel();
      let price = m.price.amount;

      return model.setCode(m.code)
        .setDescription(m.description)
        .setTotal(m.price.amount)
        .setPrice(price)
        .setStock(m.carStock || 0)
        .setRequiredSerial(m.serialProfile);
    })).setValues(this.productResults);
  }
  showPromotions() {
    this.router.navigate(["products/promotions"])
  }
}
