import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDialogType } from 'src/app/@core/constants';
import { Customer } from 'src/app/models/customer';
import { SelectEndorsementComponent } from './select-endorsement.component';

@Injectable({
  providedIn: 'root',
})
export class SelectEndorsementService {
  constructor(private modalService: BsModalService) {}

  show(customerCode: string, onConfirmSelection: ((customer: Customer) => void)): BsModalRef<SelectEndorsementComponent> {
    return this.modalService.show(SelectEndorsementComponent, {
      class: `modal-dialog ${ModalDialogType.PRIMARY}`,
      ignoreBackdropClick: true,
      animated: true,
      initialState: { 
        baseCustomerCode: customerCode,
        onConfirmSelection: onConfirmSelection
      }
    });
  }
}