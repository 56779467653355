import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './change-forget-password.component.html',
  styleUrls: ['./change-forget-password.component.sass']
})
export class ChangeForgetPasswordComponent implements OnInit {

  passwordForm: FormGroup = new FormGroup({});
  sendPassword: boolean = false;
  email: string = "";
  captcha: string = "";
  captchaId: string = '';
  constructor(private authService: AuthService, private toastrService: ToastrService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {

    let currentUser = this.route.snapshot.params.code || "";


    this.passwordForm = new FormGroup({
      username: new FormControl("", Validators.required),
      emailCodeForgetPassword: new FormControl('', Validators.required),
      new: new FormControl('', Validators.required),
      confirmation: new FormControl('', [Validators.required])
    });

    if (currentUser.length > 0) {

      this.passwordForm.get("username")?.disable();
      this.passwordForm.get("username")?.markAsDirty();
      this.passwordForm.get("username")?.markAsTouched();
      this.passwordForm.get("username")?.setValue(currentUser);
    }

    this.passwordForm.setValidators(() => this.passwordMatchValidator());
  }

  async onSubmit(): Promise<void> {
    const value = this.passwordForm.getRawValue();

    await this.authService.changeForgetPassword(value.username, value.emailCodeForgetPassword, value.new).toPromise();
    this.toastrService.success('Contraseña cambiada con éxito');
    this.passwordForm.reset();
    this.router.navigate(["login"]);
  }

  private passwordMatchValidator(): ValidationErrors | null {
    return this.passwordForm.get('confirmation')?.value === this.passwordForm.get('new')?.value ? null : { mismatch: true };
  }

  async sendEmail() {
    await this.authService.sendEmailCodeForgetPassword(this.email.toString(), this.captchaId, this.captcha).toPromise();
    this.sendPassword = true;
  }

  getCaptchaId(captchaId: string) {
    this.captchaId = captchaId;
  }
}
