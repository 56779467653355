import { Pipe, PipeTransform } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table';

@Pipe({
  name: 'hiddenFilter',
  pure: false
})
export class TableHiddenFilterPipe implements PipeTransform {

  transform(items: Array<ColumnTable>, filter?: Object): Array<ColumnTable> {
    return items.filter(f => f.type != "hidden");
  }

}
