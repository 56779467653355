import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DateUtils } from 'src/app/@core/utils/date-utils';
import { Paging } from 'src/app/models/page';
import { Promotion } from 'src/app/models/promotion';

@Component({
  selector: 'app-pos-promotions-details-modal',
  templateUrl: './pos-promotions-details-modal.component.html',
  styleUrls: ['./pos-promotions-details-modal.component.sass']
})
export class PosPromotionsDetailsModalComponent implements OnInit {

  promotion: Promotion = new Promotion();
  accumulableWith: Paging<Array<{ code: string }>> = new Paging([]);
  salesOrgs: Paging<Array<{ code: string }>> = new Paging([]);
  creditProcedures: Paging<Array<{ code: string }>> = new Paging([]);
  buyProducts: Paging<Array<{ code: string }>> = new Paging([]);
  getProducts: Paging<Array<{ code: string }>> = new Paging([]);
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancel() {
    this.bsModalRef.hide()
  }

  loadPromotionInformation() {
    this.promotion.startTime = this.promotion.startTime || "000000";
    this.promotion.endTime = this.promotion.endTime || "235959";
    this.promotion.starDateRaw = DateUtils.getDateAndTimeFromString(this.promotion.startDate, this.promotion.startTime);
    this.promotion.endDateRaw = DateUtils.getDateAndTimeFromString(this.promotion.endDate, this.promotion.endTime);
    this.accumulableWith = new Paging(this.promotion.accumulableWith.map(m => { return { code: m }; }));
    this.salesOrgs = new Paging(this.promotion.salesOrgs.map(m => { return { code: m }; }));
    this.creditProcedures = new Paging(this.promotion.creditProcedures.map(m => { return { code: m }; }));
    this.buyProducts = new Paging(this.promotion.buy?.conditions[0]?.productCodes.map(m => { return { code: m }; }) || []);
    this.getProducts = new Paging(this.promotion.get?.conditions[0]?.productCodes.map(m => { return { code: m }; }) || []);
  }
}
