import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/response-model';
import { Paging } from '../models/page';
import { ElectoralRegister } from '../models/electoral-register';

@Injectable( {
  providedIn: 'root'
} )
export class ElectoralRegistersService {
  private url = `${environment.url}/electoralRegisters`;

  constructor( private http: HttpClient ) { }

  retrieveElectoralRegisters( fiscalId: string ): Observable<ResponseModel<Paging<Array<ElectoralRegister>>>> {

    const url = `${this.url}?fiscalId=${fiscalId}`;
    return this.http.get<ResponseModel<Paging<Array<ElectoralRegister>>>>( url );

  }
}

