import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PosConfirmationComponent } from '../../shared/pos-confirmation/pos-confirmation.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosConfirmationService {
  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  show(title: string, message: string, dialogType: ModalDialogType, yesFn: (() => any), noFn?: (() => any)): Promise<any> {

    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(PosConfirmationComponent)
      this.modalRef.setClass(`modal-dialog ${dialogType}`)
      this.modalRef.content.title = title;
      this.modalRef.content.message = message;
      this.modalRef.content.yesFn = yesFn;
      this.modalRef.content.noFn = noFn || (() => { });
      resolve(true);
    })
  }

  showAsync(title: string, message: string, dialogType: ModalDialogType): Promise<boolean> {
    this.modalRef = this.modalService.show(PosConfirmationComponent, {
      initialState: { title, message }, backdrop: true,
      ignoreBackdropClick: true,
      keyboard: false,
      animated: true
    });

    this.modalRef.setClass(`modal-dialog ${dialogType}`);

    return this.modalRef.content.userResponse.toPromise();
  }
}
