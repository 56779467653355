import { VerifyCustomerSendTokenRequest } from './../models/request/verify-customer-send-token-request';
import { VerifyCustomerCheckResponse } from '../models/response/VerifyCustomerCheckResponse';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ResponseModel } from '../models/response-model';
import { VerifyCustomerSendTokenResponse } from '../models/response/verify-customer-send-token-response';
import { VerifyCustomerValidateTokenRequest } from '../models/request/verify-customer-validate-token-request';
import { VerifyCustomerValidateTokenResponse } from '../models/response/verify-customer-validate-token-response';
import { VerifyCustomerValidateTokenType } from '../@core/constants';
import { VerifyCustomerResendTokenResponse } from '../models/response/verify-customer-resend-token-response';
import { ReasonLogRequest } from '../models/request/reason-log-request-model';
import { ReasonLog } from '../models/response/reason-log-response-model';

@Injectable({
  providedIn: 'root',
})
export class CustomerVerificationService {
  private url = `${environment.url}/customers`;
  private urlReasonLog = `${environment.url}/reasonLog`;

  constructor(private http: HttpClient) {}

  getCustomerContactInformationVerification(
    customerCode: string
  ): Observable<ResponseModel<VerifyCustomerCheckResponse>> {
    // const responseModel: ResponseModel<VerifyCustomerCheckResponse> = {
    //   code: 200,
    //   currentInterest: '',
    //   message: 'mockData',
    //   response: {
    //     data: {
    //       email: {
    //         date_verified: '20200410',
    //         email: 'chemasantos63@gmail.com',
    //         phone_number: '32714581',
    //         verified: false,
    //       },
    //       phone: {
    //         date_verified: '20200410',
    //         email: 'chemasantos63@gmail.com',
    //         phone_number: '32714581',
    //         verified: false,
    //       },
    //     },
    //   },
    //   optional: '',
    // };
    // return of(responseModel);
    return this.http.get<ResponseModel<VerifyCustomerCheckResponse>>(
      `${this.url}/${customerCode}/verify`
    );
  }

  sendTokenToVerifyCustomer(
    customerCode: string,
    body: VerifyCustomerSendTokenRequest
  ): Observable<ResponseModel<VerifyCustomerSendTokenResponse>> {
    return this.http.post<ResponseModel<VerifyCustomerSendTokenResponse>>(
      `${this.url}/${customerCode}/verify/sendToken`,
      body
    );
  }

  validateToken(
    customerCode: string,
    body: VerifyCustomerValidateTokenRequest
  ): Observable<ResponseModel<VerifyCustomerValidateTokenResponse>> {
    return this.http.post<ResponseModel<VerifyCustomerValidateTokenResponse>>(
      `${this.url}/${customerCode}/verify/validateToken`,
      body
    );
  }

  reSendTokenToVerifyCustomer(
    customerCode: string,
    type: VerifyCustomerValidateTokenType
  ): Observable<ResponseModel<VerifyCustomerResendTokenResponse>> {
    const params: HttpParams = new HttpParams().append('type', type);
    return this.http.post<ResponseModel<VerifyCustomerResendTokenResponse>>(
      `${this.url}/${customerCode}/verify/resendToken`,
      undefined,
      { params }
    );
  }

  saveReasonToNotUpdateCustomer(reasonLogRequest:ReasonLogRequest):Observable<ResponseModel<ReasonLog>>{
    return this.http.post<ResponseModel<ReasonLog>>(`${this.urlReasonLog}`,reasonLogRequest);
  }
}
