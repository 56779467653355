import { CardType, DocumentType, PaymentStatus } from "../@core/constants";
import { ContractPaymentRequestModel } from "./contract-payment-request-model";
import { DocumentDetail } from "./document-detail";
import { ExchangeRate } from "./exchange-rate";
import { MakePaymentRequestDetailModel } from "./makePaymentRequestDetail";
import { RecollectionResponse } from "./recollection-response";

export class Payment {
  code: string;
  groupingNo: string;
  customerCode: string;
  customerName: string;
  customerLastName: string;
  orderCode: string;
  invoiceCode: string;
  registerCode: string;
  amount: number;
  currency: string;
  paymentMethodCode: string;
  identifier: string;
  number: string;
  name: string;
  account: string;
  expirationDate: string;
  address: string;
  phone: string;
  cardType: CardType;
  emitter: string;
  bankName: string;
  receiptCode: string;
  createdBy: string;
  branchCode: string;
  salesOrgCode: string;
  destinationSalesOrgCode: string;
  accountedFor: boolean;
  documentNo?: Array<string>;
  documents: Array<RecollectionResponse>;
  contractPayments: Array<ContractPaymentRequestModel>;
  makePayments: Array<MakePaymentRequestDetailModel>;
  documentType: DocumentType;
  documentDetails: Array<DocumentDetail>;
  exchangeRate: ExchangeRate;
  createdOn: Date;
  timeStamp: Date;
  downPaymentReceiptCode: string;
  status: PaymentStatus;
  loanCode: string;
  receiptNumber: string;

  constructor() {
    this.code = "";
    this.groupingNo = "";
    this.customerCode = "";
    this.customerName = "";
    this.customerLastName = "";
    this.orderCode = "";
    this.invoiceCode = "";
    this.registerCode = "";
    this.amount = 0;
    this.currency = "";
    this.paymentMethodCode = "";
    this.identifier = "";
    this.number = "";
    this.name = "";
    this.account = "";
    this.expirationDate = "";
    this.address = "";
    this.phone = "";
    this.emitter = "";
    this.bankName = "";
    this.receiptCode = "";
    this.createdBy = "";
    this.branchCode = "";
    this.salesOrgCode = "";
    this.destinationSalesOrgCode = "";
    this.accountedFor = false;
    this.createdOn = new Date();
    this.timeStamp = new Date();
    this.cardType = CardType.DEBIT;
    this.documentType = DocumentType.REGULAR;
    this.loanCode = "";
    this.documents = new Array<RecollectionResponse>();
    this.makePayments = Array<MakePaymentRequestDetailModel>();
    this.downPaymentReceiptCode = "";
    this.exchangeRate = new ExchangeRate();
    this.status = PaymentStatus.ACTIVE
    this.documentDetails = new Array<DocumentDetail>();
    this.contractPayments = Array<ContractPaymentRequestModel>();
    this.receiptNumber = "";
  }
}
