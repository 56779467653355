import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class OpenDocumentService {
  private url = `${environment.url}/fi/openDocuments`;
  constructor(private http: HttpClient) { }

  getOpenDocumentsByCustomer(customerCode: string): Observable<ResponseModel<any[]>> {
    let params = new HttpParams();
    params = params.set("customerCode", customerCode);

    return this.http.get<ResponseModel<any[]>>(`${this.url}`, { params });
  }
}
