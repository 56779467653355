<div class="container-fluid">
    <form class="w-100" [formGroup]="creditAndBillForm">
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="txtAmountToBill">Monto a facturar</label>
                    <input type="number" class="form-control" id="txtAmountToBill" formControlName="amountToBill"
                        (keyup.enter)="this.handlePreApproveCreditPlan(this.seletectCreditPlan)" required>
                    <div class="invalid-feedback">
                        Por favor ingrese un monto valido
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="txtAmountToBill">Prima (Politica de referencia)</label>
                    <input type="number" class="form-control" id="txtDownPayment" formControlName="downPayment"
                        (keyup.enter)="this.handlePreApproveCreditPlan(this.seletectCreditPlan)" required>
                    <div class="invalid-feedback">
                        Por favor ingrese un monto valido
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-sm-12">
            <div class="w-100">
                <h5>Planes de crédito pre-aprobados</h5>
            </div>
            <pos-table class="w-100" tableFormId="creditPlansTable" (dataChange)="this.getCreditPlansData()"
                [data]="creditPlans" [headers]="getCreditPlansHeaders()" [itemsPerPage]="10">
            </pos-table>
        </div>
    </div>


</div>