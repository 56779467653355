import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BiometryFinger, BiometryHand, BiometryType } from 'src/app/@core/constants';
import { Customer } from 'src/app/models/customer';
import { BiometryValidationResponse } from 'src/app/models/response/biometry-validation-response';
import { CaptureFingerPrintResponse } from 'src/app/models/response/CaptureFingerPrintResponse';
import { BiometryService } from 'src/app/services/biometry.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-biometry-finger-print',
  templateUrl: './biometryFingerPrint.component.html',
  styleUrls: ['./biometryFingerPrint.component.sass']
})
export class BiometryFingerPrintComponent implements OnInit {

  public _biometryFinger = BiometryFinger;
  public _biometryHand = BiometryHand;

  public biometryHand?: BiometryHand;
  public biometryFinger: BiometryFinger = BiometryFinger.INDEX;
  public imageFingerPrint: string = "";
  private device: string = "";

  @Input() customerSelected: Customer = new Customer();

  public result: BiometryValidationResponse = new BiometryValidationResponse();
  constructor(private datePipe: DatePipe, private biometryService: BiometryService, private configService: ConfigurationService, private authService: AuthService, private toastrService: ToastrService,) { }

  ngOnInit(): void {
  }

  async capture() {
    this.clean();
    this.imageFingerPrint = "assets/hands/fingerPrintCapture.gif";

    let data: CaptureFingerPrintResponse = (await this.biometryService.captureFingerprint(this.device).toPromise());

    if (data.capture) {
      this.imageFingerPrint = "data:image/jpeg;base64," + data.imageBase64;
    }
    else {
      this.toastrService.error("No se capturo huella vuelva a intentarlo");
      this.imageFingerPrint = "";
    }
  }

  async sendFingerPrint() {
    this.clean();
    if (this.customerSelected.code != "") {
      let response = this.result = (await this.biometryService.validateBiometry({ branchCode: this.configService.readSelectedBranchAndSalesOrg().branch.code, customerCode: this.customerSelected.code, imageFileName: `${this.customerSelected.code}.jpg`, imageStringBase64: this.imageFingerPrint, type: BiometryType.FINGERPRINT, user: (await this.authService.readProfile()).username, hand: this.biometryHand, finger: this.biometryFinger }).toPromise()).response;
      if (response != undefined) {
        this.result = response;
      }
    }
    else {
      this.toastrService.warning("Seleccione un cliente antes de validar la huella");
    }
  }

  async switchHand(event: MouseEvent) {
    this.clean();
    this.biometryHand = (event.target as HTMLInputElement).value as BiometryHand;
    let activeFingerprint = (await this.biometryService.activeFingerprint().toPromise());
    if (activeFingerprint.active) {
      this.device = activeFingerprint.device.toString();
    }
    else {
      this.toastrService.error("Error al activar el dispositivo");
    }
  }

  async switchFinger(event: MouseEvent) {
    this.clean();
    this.biometryFinger = (event.target as HTMLInputElement).value as BiometryFinger;
  }

  clean() {
    this.result = new BiometryValidationResponse();
    this.imageFingerPrint = "";
  }
}
