import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ConfigurationService]
})
export class LoginModule { }
