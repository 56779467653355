import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosExemptionModalComponent } from './pos-exemption-modal.component';
import { PosExemptionModalService } from 'src/app/@core/services/pos-exemption-modal.service';
import { ExonerationReasonService } from 'src/app/services/exoneration-reason.service';
import { FormsModule } from '@angular/forms';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';



@NgModule({
  declarations: [PosExemptionModalComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers: [PosExemptionModalService, ExonerationReasonService, PosConfirmationService],
  exports: [PosExemptionModalComponent]
})
export class PosExemptionModalModule { }
