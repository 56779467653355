import { DateUtils } from 'src/app/@core/utils/date-utils';
import { OnbaseProcedureResponseModel } from './../models/response/onbaseProcedureResponseModel';
import { Paging } from 'src/app/models/page';
import { ResponseModel } from 'src/app/models/response-model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnBaseCatalogueType, Personality } from '../@core/constants';
import { filter, map } from 'rxjs/operators';
import { Param } from '../models/param';
import { CreditInProcess } from '../models/credit-in-process';
import { OnbaseRequestFilter } from '../models/filters/onbase-request-filter';
import { Filter } from '../models/filter';
import { Customer } from '../models/customer';
import { CreditRequest } from '../models/credit-request';

@Injectable({
  providedIn: 'root',
})
export class OnbaseService {
  private urlOnBaseCatalogue: string = `${environment.url}/onbaseCatalogue`;
  private urlOnBaseProcedure: string = `${environment.url}/onbaseProcedures`;
  private urlOnBaseRequest: string = `${environment.url}/onbaserequests`;

  constructor(private http: HttpClient) { }

  readOnBaseCatalogueObject(
    catalogType: OnBaseCatalogueType,
    personality: Personality | undefined
  ): Observable<Param[]> {
    let httpParams: HttpParams = new HttpParams();
    if (personality != undefined)
      httpParams.set(`personality`, personality!);
    return this.http
      .get<ResponseModel<Param[]>>(
        `${this.urlOnBaseCatalogue}/${catalogType}`,
        {
          params: httpParams,
        }
      )
      .pipe(map((res: ResponseModel<Param[]>) => res.response));
  }

  readPreApproveCreditPlans(
    customerCode: string,
    branchCode: string,
    limitRule: string,
    salesOrg: string
  ): Observable<ResponseModel<Paging<Array<OnbaseProcedureResponseModel>>>> {
    return this.http
      .get<ResponseModel<Paging<Array<OnbaseProcedureResponseModel>>>>(
        `${this.urlOnBaseProcedure}/${customerCode}`,
        {
          params: new HttpParams()
            .append(`branchCode`, branchCode)
            .append(`limitRule`, limitRule)
            .append(`salesOrg`, salesOrg),
        }
      )
      .pipe(
        map(
          (
            response: ResponseModel<Paging<Array<OnbaseProcedureResponseModel>>>
          ) => {
            response.response.content.forEach((op) => {
              op.writtenDuration = DateUtils.parseMinutesToDays(op.duration);
            });

            return response;
          }
        )
      );
  }

  getRequests(
    customerCode: string,
    text?: string,
    page?: number,
    pageSize?: number,
    salesOrg?: string,
    brach?: string): Observable<ResponseModel<Paging<Array<CreditInProcess>>>> {

    let params = new HttpParams();

    params = params.appendAll({
      customerCode,
      page: String(page ?? '0'),
      pageSize: String(pageSize ?? '100'),
      orderBy: 'REQUESTED',
      ascending: 'false'
    });

    return this.http.get<ResponseModel<Paging<Array<CreditInProcess>>>>(`${environment.url}/onbaserequests`, { params });
  }

  readOnbaseRequestByCode(
    code: string
  ): Observable<ResponseModel<Paging<Array<any>>>> {
    return this.http.get<ResponseModel<Paging<Array<any>>>>(
      this.urlOnBaseRequest,
      {
        params: new HttpParams().append('requestCode', code),
      }
    );
  }

  readOnbaseRequestByCustomerCode(
    customerCode: string
  ): Observable<ResponseModel<Paging<Array<CreditRequest>>>> {
    return this.http.get<ResponseModel<Paging<Array<CreditRequest>>>>(
      this.urlOnBaseRequest,
      {
        params: new HttpParams().append('customerCode', customerCode),
      }
    );
  }

  readOnbaseRequestIntermediariesByCustomerCode(customerCode: string): Observable<ResponseModel<Customer[]>> {
    const url = `${this.urlOnBaseRequest}/${customerCode}/intermediaries`;
    return this.http.get<ResponseModel<Customer[]>>(url);
  }

  readOnbaseRequestByFilters(
    filters: Filter<OnbaseRequestFilter>
  ): Observable<ResponseModel<Paging<Array<any>>>> {
    let params = new HttpParams();

    Object.keys(filters.value).forEach((k) => {
      if (filters.value[k] !== undefined) {
        params = params.append(k, filters.value[k]!);
      }
    });

    return this.http.get<ResponseModel<Paging<Array<any>>>>(
      this.urlOnBaseRequest,
      { params }
    );
  }

  readCreditPlans(): Observable<
    ResponseModel<Paging<Array<OnbaseProcedureResponseModel>>>
  > {
    return this.http.get<
      ResponseModel<Paging<Array<OnbaseProcedureResponseModel>>>
    >(this.urlOnBaseProcedure);
  }

  getServerDateTime(): Observable<ResponseModel<string>> {
    const url = `${environment.url}/onbaserequests/serverDateTime`;
    return this.http.get<ResponseModel<string>>(url);
  }

}
