<div class="card">
  <div class="card-header">
    <i class="fa fa-street-view"></i> Contratos activos
  </div>
  <div class="card-body card-body-z-index">

    <pos-table tableFormId="loansTable" [data]="loans" [headers]="getLoansHeaders()" [filters]="getLoansFilters()"
      (dataChange)="getLoansData($event)" [itemsPerPage]="10">
    </pos-table>

  </div>
</div>
