import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosSerialModalComponent } from './pos-serial-modal.component';
import { PosSerialModalService } from '../../@core/services/pos-serial-modal.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StockService } from 'src/app/services/stock.service';
import { EquipmentService } from 'src/app/services/equipment.service';



@NgModule({
  declarations: [PosSerialModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    NgSelectModule,
    NgOptionHighlightModule
  ],
  providers: [PosSerialModalService, StockService, EquipmentService],
  exports: [PosSerialModalComponent]
})
export class PosSerialModalModule { }
