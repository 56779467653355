export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum Role {
  ROLE_VENDOR_FRANCHISED = 'ROLE_VENDOR_FRANCHISED',
  ROLE_CASHIER_FRANCHISED = 'ROLE_CASHIER_FRANCHISED',
  ROLE_SUPERVISOR_FRANCHISED = 'ROLE_SUPERVISOR_FRANCHISED',
  ROLE_PRICING_FRANCHISED = 'ROLE_PRICING_FRANCHISED',
  NONE = 'NONE',
  ROLE_ADMIN_FRANCHISED = 'ROLE_ADMIN_FRANCHISED',
  ROLE_CREDIT_REQUEST_FRANCHISED = 'ROLE_CREDIT_REQUEST_FRANCHISED'
}

export enum RegisterStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DiscountType {
  PRICE = 'PRICE',
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
  NONE = 'NONE',
}

export enum CardType {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT"
}

export enum PaymentStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED"
}

export enum DocumentType {
  REGULAR = "REGULAR",
  CANCELLATION = "CANCELLATION",
  REGULAR_LOAN = "REGULAR_LOAN",
  CANCELLATION_LOAN = "CANCELLATION_LOAN",
  SUBSCRIPTION_PAYMENT = "SUBSCRIPTION_PAYMENT",
  CANCELLATION_SUBSCRIPTION_PAYMENT = "CANCELLATION_SUBSCRIPTION_PAYMENT",
  CREDIT_PAYMENT = "CREDIT_PAYMENT",
  GIFTCARD = "GIFTCARD",
  GIFTCARD_REFUND = "GIFTCARD_REFUND",
  ADVANCE = "ADVANCE",
  ADVANCE_REFUND = "ADVANCE_REFUND",
  ADVANCE_RETURN = "ADVANCE_RETURN",
  EMIT_CREDIT_NOTE = "EMIT_CREDIT_NOTE",
  VOID_CREDIT_NOTE = "VOID_CREDIT_NOT",
}

export enum InitialPaymentType {
  ZE01 = "ZE01",
  ZE02 = "ZE02",
  ZH01 = "ZH01",
  ZH02 = "ZH02",
  ZT01 = "ZT01",
  ZT02 = "ZT02",
  ZT03 = "ZT03",
  ZT04 = "ZT04",
  ZT05 = "ZT05",
  ZT06 = "ZT06",
  ZT07 = "ZT07",
  ZT08 = "ZT08",
  ZT09 = "ZT09",
  ZT10 = "ZT10",
  ZT11 = "ZT11",
  ZT12 = "ZT12",
  ZT13 = "ZT13",
  ZT14 = "ZT14",
  ZT15 = "ZT15",
  ZT16 = "ZT16",
  ZT17 = "ZT17",
  ZT18 = "ZT18",
  ZD01 = "ZD01",
  ZTD1 = "ZTD1",
  ZTD2 = "ZTD2",
  ZTD3 = "ZTD3",
  ZTD4 = "ZTD4",
  ZTC1 = "ZTC1",
  ZTC2 = "ZTC2",
  ZTC3 = "ZTC3",
  ZTC4 = "ZTC4",
  ZG01 = "ZG01",
  ZA02 = "ZA02",
  ZP01 = "ZP01",
  ZC01 = "ZC01",
  ZA01 = "ZA01",
  ZN01 = "ZN01",
  ZN02 = "ZN02",
  ZVD1 = "ZVD1",
  ZR01 = "ZR01",
  ZR02 = "ZR02",
  ZR03 = "ZR03",
  ZPL1 = "ZPL1",
}

export enum OrderType {
  REGULAR = 'REGULAR',
  CREDIT = 'CREDIT',
  RETURN = 'RETURN',
  QUOTATION = 'QUOTATION',
  CANCELLED = 'CANCELLED',
  REPAIRSHOP = 'REPAIRSHOP',
}

export enum CustomerType {
  CORPORATE = 'CORPORATE',
  RETAIL = 'RETAIL',
  EMPLOYEE = 'EMPLOYEE',
  INTERCOMPANY = 'INTERCOMPANY',
  FRANCHISED = 'FRANCHISED'
}

export enum OrderStatus {
  NONE = '',
  DELETED = 'DELETED',
  INITIAL = 'INITIAL',
  FINAL = 'FINAL',
  INVOICEABLE = 'INVOICEABLE',
  REJECTED = 'REJECTED',
  FINAL_INVOICEABLE = "INVOICEABLE,REJECTED,FINAL"
}


export enum OrderProcessingStatus {
  NONE = 'NONE',
  UNAPPROVED = 'UNAPPROVED',
  APPROVED = 'APPROVED',
}

export enum OrderDiscountStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  APPROVED_UNDER_MARGIN = 'APPROVED_UNDER_MARGIN',
  REJECTED = 'REJECTED',
}

export enum ModalDialogType {
  NONE = '',
  PRIMARY = 'modal-primary',
  SUCCESS = 'modal-success',
  WARNING = 'modal-warning',
  DANGER = 'modal-danger',
  INFO = 'modal-info',
}

export enum Personality {
  PERSON = 'PERSON',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
}
/* component pos-create-customer-person */
export const CurrentCountry: String = 'HN';
export const CountryHN: String = 'HN';
export const FiscalIdTypeRTN: String = 'ZHN004';
export const FiscalIdTypeAlwaysShow: Array<String> = ['ZHN002', 'ZHN003'];
export const ParamPhoneMaskHn: string = 'phone-mask-hn';
export const ParamPhoneMaskNoHn: string = 'phone-mask-no-hn';
export const phoneMinimumCharacters: string = 'phone-minimum-characters';
export const CURRENT_NATIONALITY = 'Hondureña';
export const CountrySV = 'SV';
export const CountryCR = 'CR';
export const HN_FISCAL_TYPE_CODE = 'ZHN004';
export const SV_FISCAL_TYPE_CODE = 'ZSV004';
export const CR_FISCAL_TYPE_CODE = 'ZCR004';
export const MIN_LENGTH_HN = 9;
export const DEFAULT_HN_PHONE_CODE = "+(504)";
export const REGEX = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
};

export enum ServiceType {
  EXTRA_WARRANTY = '01',
  INTALLATION = '02',
  TOTAL_PROTECTION = '03',
  TECHNICAL_ASSISTANCE = '04',
  PREVENTIVE_MANTEINANCE = '05',
  UNLIMITED_LABOR = '06',
  PLATE = '07',
  TOP_UP = '08',
  REPRINT_INVOICE = '09',
  OTHER = '10',
}

export enum RoleType {
  TR0100 = 'TR0100',
  TR0200 = 'TR0200',
}

export enum PrintType {
  ORIGINAL = "ORIGINAL",
  COPY = "COPY",
  REGISTRATION = "REGISTRATION"
}

export enum OriginLoan {
  POS = "POS", SAP = "SAP"
}

export enum OrderAttachmentType {
  FISCALID = "FISCALID",
  RTNFISCALID = "RTNFISCALID",
  IP800 = "IP800",
  PROMISSORY_NOTE = "PROMISSORY_NOTE",
  DOMAIN_TRADITION = "DOMAIN_TRADITION",
  PROOF_OF_PAYMENT = "PROOF_OF_PAYMENT",
  PURCHASE_ORDER = "PURCHASE_ORDER"
}

export enum OnBaseCatalogueType {
  HOME = 'HOME_TYPE',
  MARITALSTATUS = 'MARITAL_STATUS',
  NATURALRELATION = "NATURAL_RELATION"
  // TODO:falta agregar los demas tipos de catalogos
}

export enum LimitRulesType {
  BURO = 'ILP-BURO',
  // TODO:falta agregar los demas reglas
}

export enum PromotionType {
  GENERAL = "GENERAL",
  DIRECTED = "DIRECTED"
}

export enum PromotionPaymentType {
  RETAIL = "RETAIL", CREDIT = "CREDIT", EITHER = "EITHER"
}

export enum LinkCategory {
  AND = "AND", OR = "OR"
}

export enum ScaleType {
  FROM = "FROM", UP_TO = "UP_TO", EQUAL = "EQUAL"

}

export enum RepairshopOrderType {

  ZFFT = "ZFFT", ZFAU = "ZFAU", ZFST = "ZFST",
  S1 = "S1", S2 = "S2", BOTH = "BOTH", RETAIL = "RETAIL"
}

export enum VoidOrderOperationType {
  VOID = "VOID", CANCEL = "CANCEL", RETURN = "RETURN"
}

export enum OrderConditionsType {
  CREDIT = 'Credit',
  CREDIT_30_DAYS = 'Credit30Days',
  CREDIT_WITH_PAYMENT_RESPONSIBLE = 'CreditWithPaymentResponsible',
  REFERENCE = 'references'
}
export enum LoanClass {
  CLASS_FOR_PERSON = '001',
  CLASS_FOR_EMPLOYEE = '002',
  CLASS_FOR_COVENANT = '005',
  CLASS_FOR_THIRTY_DAYS = '008'
}

export enum CustomerCreditStatus {
  ACTIVE = 'ACT',
  POTENTIAL = 'POT',
  INACTIVE = 'INA',
  COVENANT = 'CVN',
}

export enum RepairshopOrderSubType {
  UNSPECIFIED = 'BOTH',
  TIENDA = 'RETAIL',
  TALLER = 'ZFST'
}

export enum CreditStatus {
  NO_STATUS = 'NO_STATUS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CONTROL_TABLE = 'CONTROL_TABLE',
  TELEPHONE_INVESTIGATION = 'TELEPHONE_INVESTIGATION',
  HOME_DOMESTIC_INVESTIGATION = 'HOME_DOMESTIC_INVESTIGATION',
  WORK_DOMESTIC_INVESTIGATION = 'WORK_DOMESTIC_INVESTIGATION',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  ANALISYS_AND_APPROVAL = 'ANALISYS_AND_APPROVAL',
  WITH_REQUIREMENTS = 'WITH_REQUIREMENTS',
  REPROGRAMMING_IT = 'REPROGRAMMING_IT',
  REPROGRAMMING_HOUSE_ID = 'REPROGRAMMING_HOUSE_ID',
  REPROGRAMMING_WORK_ID = 'REPROGRAMMING_WORK_ID'
}

export enum CreditProcessStatus {
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
  UNPROCESSED = 'UNPROCESSED',
  DRAFT = 'DRAFT',
  PROCESSING = 'PROCESSING'
}

export enum TicketProcessingStatus {
  FAILED = 'FAILED'
}

export enum CreditPlansCodes {
  PAC = '0006',
  CVN = '0008',
  NOR = '0005',
  JUR = '0010',
}

export enum AdvanceReturnType {
  ADVANCE_REQUEST_REFUND = 'ADVANCE_REQUEST_REFUND',
  ADVANCE_REFUND = 'ADVANCE_REFUND'
}

export enum AutomaticClosingRegisterStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export enum OrderConditions {
  UNTIL_30_DAYS_FRANCHISED = 1,
  MORE_THAN_30_DAYS_FRANCHISED = 2,
  FINAL_CUSTOMER_CREDIT = 3,
  CASH_BILLLING = 4,
  CREDIT_QUOTATION = 5
}
export const DATE_PATTERN = "YYYYMMDD";
export const NO_FISCAL_REGISTER_PARAM_CODE = 'no-fiscal-register-code';

export enum EquipmentRegistrationTicketFilesTitles {
  IDENTITY = 'Identidad',
  RTN = 'RTN',
  IP_800 = 'IP-800',
  THIRD_PARTY_AUTORIZATION = 'Autorización a terceros',
  ENROLLMENT_REJECT = 'Rechazo de matrícula',
  DOMAIN_TRADITION_1 = "Tradición de Dominio 1",
  DOMAIN_TRADITION_2 = "Tradición de Dominio 2"
}

export const TICKET_TYPE_ZMA5 = 'ZMA5';
export const TICKET_TYPE_ZMA5_DESCRIPTION = 'Matricula de motocicleta';
export const FEATUREPRODUCTREQUIREREGISTRATION = 'matricula1';

export enum DigitalSignatureRequestStatus {
  UNPROCESSED = 'UNPROCESSED',
  PROCESSED = 'PROCESSED',
  INITIAL_REQUEST = 'INITIAL_REQUEST',
  UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT',
  UPLOAD_INVOICES = 'UPLOAD_INVOICES',
  UPLOAD_LOAN = 'UPLOAD_LOAN',
  FAILED = 'FAILED',
  SIGNATURE_FAILED = 'SIGNATURE_FAILED',
  WAITING_FOR_CLIENT = 'WAITING_FOR_CLIENT',
  DONE = 'DONE'
}

export enum DigitalSignatureRequestStatusInOrder {
  NA = 'NA',
  UNPROCESSED = 'UNPROCESSED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  WAITING_FOR_CLIENT = 'WAITING_FOR_CLIENT',
  DONE = 'DONE'
}

export enum OrderCondition {
  CREDIT = 'credito',
  CASH = 'contado'
}
export const CURRENCY_HNL = 'HNL';
export const CURRENCY_USD = 'USD';

export const PAYMENTTYPESAVAILABLEFRANCHISED = "payment-types-available-franchised";

export enum OrderTransactionStatus {
  NEW = "NEW", INVOICES = "INVOICES", LOANS = "LOANS", NOTE_CHARGE = "NOTE_CHARGE", NOTE_CHARGE_PAYMENT = "NOTE_CHARGE_PAYMENT", PAYMENTS = "PAYMENTS", DOCUMENT_ATTACHMENTS = "DOCUMENT_ATTACHMENTS", DOWNPAYMENT_RECEIPT = "DOWNPAYMENT_RECEIPT",
  FINISHED = "FINISHED", NO_STATUS = "NO_STATUS"
};

export enum DomainTraditionReportType {
  PERSON_TO_PERSON = "PERSON_TO_PERSON", PERSON_TO_JURIDIC = "PERSON_TO_JURIDIC", JURIDIC_TO_PERSON = "JURIDIC_TO_PERSON", JURIDIC_TO_JURIDIC = "JURIDIC_TO_JURIDIC"
};


export enum BiometryType {
  PHOTO = "PHOTO", FINGERPRINT = "FINGERPRINT"
}

export enum BiometryHand {
  LEFT = "LEFT", RIGHT = "RIGHT"
}

export enum BiometryFinger {
  THUMB = "THUMB", INDEX = "INDEX", MIDDLE = "MIDDLE", RING = "RING", LITTLE = "LITTLE"
}

export enum VerifyCustomerValidateTokenType{
  PHONE="PHONE", EMAIL="EMAIL"
}

export const PARAM_TO_KNOW_BRANCHED_SKIP_VERIFYCATION_VALKYRIA = 'verification-valkyria-skipped-branches';
