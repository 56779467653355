import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Personality } from 'src/app/@core/constants';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';

@Component({
  selector: 'app-pos-customer-create',
  templateUrl: './pos-customer-create.component.html',
  styleUrls: ['./pos-customer-create.component.sass']
})
export class PosCustomerCreateComponent implements OnInit {

  personality: Personality = Personality.PERSON;

  @Output()
  saveCustomerEmitter = new EventEmitter<CustomerTableModel>();

  @Input()
  redirect: boolean = true;
  constructor() {

  }

  ngOnInit(): void {
  }

  setPersonalityType(type: Personality): void {
    this.personality = type;
  }

  get personalityType(): typeof Personality {
    return Personality;
  }

  saveCustomer(customer: CustomerTableModel) {
    this.saveCustomerEmitter.emit(customer);
  }
}
