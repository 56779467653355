import { CirculationPermitRequest } from "./circulation-permit-request";
import { Invoice } from "./invoice";
import { Loan } from "./loan";
import { Order } from "./order";
import { OrderAttachment } from "./order-attachment";
import { OrderTransaction } from "./order-transation";
import { Payment } from "./payment";
import { Receipt } from "./receipt";

export class OrderCompleteProcess {
    order: Order;
    loans: Array<Loan>;
    attachments: Array<OrderAttachment>;
    invoices: Array<Invoice>;
    payments: Array<Payment>;
    receipts: Array<Receipt>;
    circulationPermitRequests: Array<CirculationPermitRequest>;
    customerEmailDigitalSignature: String;
    customerPhoneDigitalSignature: String;
    transactions: Array<OrderTransaction>;
    constructor() {
        this.order = new Order();
        this.loans = new Array<Loan>();
        this.attachments = new Array<OrderAttachment>();
        this.invoices = new Array<Invoice>();
        this.receipts = new Array<Receipt>();
        this.payments = new Array<Payment>();
        this.circulationPermitRequests = [];
        this.customerEmailDigitalSignature = "";
        this.customerPhoneDigitalSignature = "";
        this.transactions = [];
    }
}
