import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LimitRulesType } from 'src/app/@core/constants';
import { Customer } from 'src/app/models/customer';
import { JobInfo } from 'src/app/models/customer-job-info';
import { Param } from 'src/app/models/param';
import { PreApproveCustomerParams } from 'src/app/models/params/preApprove-customer-params';
import { PreApprovalChangeEvent } from 'src/app/models/preApprovalChangeEvent';
import { OnbaseProcedureResponseModel } from 'src/app/models/response/onbaseProcedureResponseModel';
import { PreApproveCustomerResponseModel } from 'src/app/models/response/preApproveCustomerResponseModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthService } from 'src/app/services/auth.service';
import { OnbaseService } from 'src/app/services/onbase.service';
import { CustomerUpdateJobInfoRequest } from './../../../../models/requests/customer-update-jobInfo-request';
import { CreditService } from './../../../../services/credit.service';

@Component({
  selector: 'app-customer-pre-approval-form',
  templateUrl: './customer-pre-approval-form.component.html',
  styleUrls: ['./customer-pre-approval-form.component.sass'],
})
export class CustomerPreApprovalFormComponent implements OnInit {
  @Input() currentCustomer: Customer = new Customer();
  @Input() preApprovalForm: FormGroup = new FormGroup({
    amountToBill: new FormControl(0, [Validators.required]),
    monthIncome: new FormControl(0, [Validators.required]),
    workSeniority: new FormControl(undefined, [Validators.required]),
    homeType: new FormControl(undefined, [Validators.required]),
    ownVehicle: new FormControl(false, [Validators.required]),
  });
  @Input() homeTypes: Array<Param> = new Array();

  @Output() preApprovalChange: EventEmitter<PreApprovalChangeEvent> =
    new EventEmitter<PreApprovalChangeEvent>();

  constructor(
    private creditService: CreditService,
    private configurationService: ConfigurationService,
    private authenticationService: AuthService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private toastrService: ToastrService,
    private onbaseService: OnbaseService
  ) {
    this.homeTypes = new Array();
  }

  ngOnInit(): void { }

  async handlePreApproveClick(): Promise<void> {
    if (this.preApprovalForm.touched && this.preApprovalForm.valid) {
      await this.updateJobCustomerInfo();
      await this.preApproveCustomer();
    }
  }

  private async updateJobCustomerInfo(): Promise<void> {
    const request = this.buildUpdateJobInfoRequestModel();
    const response = await this.customerService
      .updateJobInfoCustomer(this.currentCustomer.code, request)
      .toPromise();
    console.log(response);
  }

  private buildUpdateJobInfoRequestModel(): CustomerUpdateJobInfoRequest {
    const updateJoInfoRequestModel: CustomerUpdateJobInfoRequest =
      new CustomerUpdateJobInfoRequest();

    updateJoInfoRequestModel.countryId = this.currentCustomer.mainPersonalInfo
      ?.nationality
      ? this.currentCustomer.mainPersonalInfo?.nationality
      : '';

    updateJoInfoRequestModel.houseType = this.preApprovalForm.value.homeType;

    updateJoInfoRequestModel.ownCar = this.preApprovalForm.value.ownVehicle;
    updateJoInfoRequestModel.personality = this.currentCustomer.mainPersonalInfo
      ?.personality
      ? this.currentCustomer.mainPersonalInfo?.personality
      : '';
    const currentCustomerJobInfo: JobInfo = new JobInfo();
    const transformWorkSeniority = this.datePipe.transform(
      this.preApprovalForm.value.workSeniority,
      `YYYYMMdd`
    )
      ? this.datePipe.transform(
        this.preApprovalForm.value.workSeniority,
        `YYYYMMdd`
      )
      : '';

    currentCustomerJobInfo.admissionDate = transformWorkSeniority
      ? transformWorkSeniority
      : '';

    currentCustomerJobInfo.currency = 'HNL';
    currentCustomerJobInfo.monthlySalary =
      this.preApprovalForm.value.monthIncome;
    updateJoInfoRequestModel.jobInfoList = [currentCustomerJobInfo];

    return updateJoInfoRequestModel;
  }

  private async preApproveCustomer(): Promise<void> {
    const { branch, salesOrg } =
      this.configurationService.readSelectedBranchAndSalesOrg();

    const preApproveParams: PreApproveCustomerParams =
      new PreApproveCustomerParams();

    preApproveParams.branchCode = branch.code;
    preApproveParams.salesOrg = salesOrg.code;
    preApproveParams.customerCode = this.currentCustomer.code;
    preApproveParams.fiscalId = this.currentCustomer.mainPersonalInfo?.fiscalId
      ? this.currentCustomer.mainPersonalInfo?.fiscalId
      : '';
    preApproveParams.fiscalIdType = this.currentCustomer.mainPersonalInfo
      ?.fiscalIdType
      ? this.currentCustomer.mainPersonalInfo?.fiscalIdType
      : '';
    preApproveParams.menu = `CUSTOMERS`;
    preApproveParams.sellerCode =
      this.authenticationService.readProfileInStorage().username;
    preApproveParams.limitRule = LimitRulesType.BURO;

    const plans = await this.onbaseService
      .readPreApproveCreditPlans(
        this.currentCustomer.code,
        branch.code,
        LimitRulesType.BURO,
        salesOrg.code
      )
      .toPromise();

    this.toastrService.success(`Pre Aprobacion Exitosa`, `Operacion Exitosa`);

    this.preApprovalChange.emit({
      plans: plans.response,
      preApprovalFormValue: this.preApprovalForm.getRawValue(),
    });
  }
}
