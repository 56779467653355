import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';
import { SalesOrg } from '../models/sales-org';

@Injectable({
  providedIn: 'root'
})
export class SalesOrgService {

  private url = `${environment.url}/salesOrgs`;
  constructor(private http: HttpClient) { }

  readSalesOrgs(): Observable<ResponseModel<Paging<Array<SalesOrg>>>> {
    let params = new HttpParams();
    params = params
      .set("page", "0")
      .set("pageSize", "1000");
    return this.http.get<ResponseModel<Paging<Array<SalesOrg>>>>(this.url, { params });
  }

}
