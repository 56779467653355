import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/response-model';
import { Advance } from '../models/advance';
import { Paging } from '../models/page';
import { Filter } from 'src/app/models/filter';
import { AdvanceFilter } from '../models/filters/advance-filter';
import { AuthService } from './auth.service';
import { AdvanceBalance } from '../models/advance-balance';

@Injectable({
  providedIn: 'root'
})
export class AdvanceService {
  private url = `${environment.url}/advances`;

  constructor(private http: HttpClient, private configurationService: ConfigurationService, private authService: AuthService) { }

  getAdvances(filter: Filter<AdvanceFilter>): Observable<ResponseModel<Paging<Array<Advance>>>> {

    const salesOrgObject = this.configurationService.readSelectedBranchAndSalesOrg().salesOrg;
    const salesOrg = salesOrgObject.code;

    let params = new HttpParams();
    params = params.set('page', filter.page.toString() || '0');
    params = params.set('pageSize', filter.pageSize.toString() || '20');
    params = params.set("salesOrg", salesOrg);

    params = filter.value.customerCode ? params.set("customerCode", filter.value.customerCode) : params;
    params = filter.value.isReturn == true || filter.value.isReturn == false ? params.set("isReturn", String(filter.value.isReturn)) : params;
    params = filter.value.receiptCode ? params.set("receiptCode", filter.value.receiptCode) : params;

    return this.http.get<ResponseModel<Paging<Array<Advance>>>>(this.url, { params });
  }

  requestRefund(advance: Advance): Observable<Advance> {
    const url = `${environment.url}/advancerefund`;

    const salesBranchObject = this.configurationService.readSelectedBranchAndSalesOrg();
    const salesOrg = salesBranchObject.salesOrg;
    const branch = salesBranchObject.branch;

    const body = {
      customerCode: advance.customerCode,
      salesOrgCode: salesOrg.code,
      amount: advance.totalAmount * -1,
      branchCode: branch.code,
      oldAdvanceCode: advance.code,
      originalAdvanceCode: advance.code
    };

    return this.http.post<Advance>(url, body);
  }

  getAdvancesBalance(customerCode: string): Observable<ResponseModel<AdvanceBalance>> {
    const url = `${environment.url}/advancesbalance`;

    let params = new HttpParams();
    params = customerCode ? params.set("customerCode", customerCode) : params;

    return this.http.get<ResponseModel<AdvanceBalance>>(url, { params });
  }
}
