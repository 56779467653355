import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Customer } from 'src/app/models/customer';
import { Order } from 'src/app/models/order';
import { ValidateCreditLimitForInvoicingResponseModel } from 'src/app/models/response/validateCreditLimitForInvoicingResponseModel';
import { Totals } from 'src/app/models/totals';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CreditService } from 'src/app/services/credit.service';

@Component({
  selector: 'app-pos-credit-installments-application',
  templateUrl: './pos-credit-installments-application.component.html',
  styleUrls: ['./pos-credit-installments-application.component.sass']
})
export class PosCreditInstallmentsApplicationComponent implements OnInit, OnChanges 
{
  formApplication: FormGroup = this.fb.group({});
  @Input() minimun: number = 0;
  @Input() maximun: number = 0;
  @Input() customer: Customer = new Customer();
  @Output()
  public onSuccessfulApplication = new EventEmitter<Order>();

  constructor(private fb: FormBuilder, private toastr: ToastrService, private configService: ConfigurationService,private creditService: CreditService) 
  { 
    this.buildForm();
  }

  ngOnInit(): void 
  {
    
  }

  ngOnChanges(): void {
    this.onInvoiceAmountChange();
  }

  buildForm()
  {
    this.formApplication=this.fb.group({
      invoiceAmount:[0, [Validators.required]],
      minimun: [`0% -> 0 ${this.currency}`],
      maximun: [`0% -> 0 ${this.currency}`],
      amount:[0, [Validators.required]]
    });
  }

  get currency(): string {
    return this.configService.readSelectedBranchAndSalesOrg().branch.currency;
  }

  get amount(): number {
    return this.formApplication.controls.amount?.value || 0;
  }

  get total(): number {
    return this.invoiceAmount - this.amount;
  }

  get invoiceAmount(): number {
    return this.formApplication.controls.invoiceAmount?.value || 0;
  }

  get minimunAmount(): number {
    return this.minimun * this.invoiceAmount / 100;
  }

  get maximunAmount(): number {
    return this.maximun * this.invoiceAmount / 100;
  }

  onInvoiceAmountChange() {
    const minimunStr = `${this.minimun}% -> ${this.minimunAmount} ${this.currency}`;
    this.formApplication.controls.minimun.setValue(minimunStr);
    const maximunStr = `${this.maximun}% -> ${this.maximunAmount} ${this.currency}`;
    this.formApplication.controls.maximun.setValue(maximunStr);
  }

  async apply() 
  {
    if (this.amount > this.maximunAmount || this.amount < this.minimunAmount) {
      this.toastr.warning(
        "El monto de la prima no puede ser menor a la Prima Mínima ni mayor a la Prima Máxima", 
        "Error al aplicar monto", 
        { closeButton: true, tapToDismiss: false }
      );
    } else {
      const personalInfo = this.customer?.personalInfo && this.customer?.personalInfo.length 
        ? this.customer?.personalInfo[0] 
        : null;
      let validateCreditLimitForInvoicingResponseModel = new ValidateCreditLimitForInvoicingResponseModel();
  
      if (personalInfo) {
        validateCreditLimitForInvoicingResponseModel = await this.creditService.getValidateCreditLimitForInvoicing(
          this.customer.code,
          this.configService.readSelectedBranchAndSalesOrg().salesOrg.code,
          personalInfo.fiscalId,
          personalInfo.fiscalIdType,
          this.invoiceAmount,
          this.amount
        ).toPromise();
      }
  
      if (!validateCreditLimitForInvoicingResponseModel.creditLimitAvaibleForInvoicing) {
        this.toastr.warning(validateCreditLimitForInvoicingResponseModel.message, "Error al aplicar monto", { closeButton: true, tapToDismiss: false });
      }  else {
        const order = new Order();
        order.totals.netTotal = this.invoiceAmount;
        order.totals.discountTotal = this.amount;
        this.onSuccessfulApplication.emit(order);
      }
    }
  }
}
