import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../models/response-model';

@Injectable( {
  providedIn: 'root'
} )
export class VoucherService {
  private url = `${environment.url}/voucher`;

  constructor( private http: HttpClient ) { }

  getVoucherByCode( voucherCode: string ): Observable<ResponseModel<any>> {
    const path = `${this.url}/getByCode`;

    let params = new HttpParams();
    params = params.appendAll( { voucherCode } );

    return this.http.get<ResponseModel<any>>( path, { params } );
  }
}
