<div class="animated fadeIn rounded">
  <div class="card bg-white">
    <div class="card-header">
      <i class="cil-cart"></i> Crear cliente
      <div class="card-header-actions btn-group" dropdown placement="bottom left">
      </div>
    </div>
    <div class="card-body card-body-z-index">
      <div class="form-row">
        <div class="col-md-12 mb-2">
          <label for="nationality" class="form-label">Elija el tipo de cliente</label>
        </div>
        <div class="col-md-3 mb-4">
          <button type="button" class="form-control btn"
            [ngClass]="personality==personalityType.PERSON ? 'btn-primary' : 'btn-outline-primary'"
            (click)="setPersonalityType(personalityType.PERSON)">Natural</button>
        </div>
        <div class="col-md-3 mb-4">
          <button type="button" class="form-control btn"
            [ngClass]="personality==personalityType.LEGAL_ENTITY ? 'btn-primary' : 'btn-outline-primary'"
            (click)="setPersonalityType(personalityType.LEGAL_ENTITY)">Juridico</button>
        </div>
      </div>
      <fieldset class="col-md-12">
        <legend class="scheduler-border">
          {{personality==personalityType.PERSON? 'Crear cliente natural' : 'Crear cliente jurídico'}}</legend>


        <app-pos-customer-create-person *ngIf="personality === personalityType.PERSON"
          (saveCustomerEmitter)="saveCustomer($event)" [redirect]="redirect">
        </app-pos-customer-create-person>
        <app-pos-customer-create-legal-entity *ngIf="personality === personalityType.LEGAL_ENTITY"
          (saveCustomerEmitter)="saveCustomer($event)" [redirect]="redirect">
        </app-pos-customer-create-legal-entity>
      </fieldset>
    </div>
  </div>
</div>
