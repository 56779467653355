<div class="animated fadeIn rounded">
  <div class="card">
    <div class="card-header">
      <i class="cil-object-group"></i>Facturas
    </div>
    <div class="card-body">
      <pos-stepper #cdkStepper>
        <cdk-step *ngFor="let invoice of invoices; let i = index" label="Factura" [stepControl]="invoiceCompletePayments[i]">
          <invoice-details-step [invoice]="invoice" (doPayment)="getPaymentInvoices($event)"  [id]="i.toString()"></invoice-details-step>
        </cdk-step>
        <cdk-step label="Recibo" *ngIf="chargeNote.code" [stepControl]="receiptCompletePayments">
          <receipt-details-step [receipt]="chargeNote" (doPayment)="getPaymentReceipts($event)"></receipt-details-step>
        </cdk-step>
        <cdk-step label="Pagar">
          <app-pay-invoice-step [paymentRequest]="requestWithPayments" [order]="order"></app-pay-invoice-step>
        </cdk-step>
      </pos-stepper>
    </div>
  </div>
</div>
