import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Customer } from 'src/app/models/customer';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { PosIp800ModalComponent } from 'src/app/shared/pos-ip800-modal/pos-ip800-modal.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosIp800ModalService {

  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  show(customer: CustomerTableModel, confirmAction: (value: Blob) => any): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.modalRef = this.modalService.show(PosIp800ModalComponent,
        {
          ignoreBackdropClick: true
        })

      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY}`)
      this.modalRef.content.customer = customer;
      this.modalRef.content.confirmAction = confirmAction;
      await this.modalRef.content.load();
      resolve(true)
    });
  }


}
