import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BranchRegion } from '../models/branch-region';
import { Paging } from '../models/page';
import { Region } from '../models/region';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class BranchRegionService {

  private url = `${environment.url}/regionsOfBranch`;
  constructor(private http: HttpClient) { }

  readRegions(): Observable<ResponseModel<Paging<Array<BranchRegion>>>> {
    let params = new HttpParams();
    params = params
      .set("page", "0")
      .set("pageSize", "20");
    return this.http.get<ResponseModel<Paging<Array<BranchRegion>>>>(this.url,{params});
  }
}