import { AuthService } from 'src/app/services/auth.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PosDiscountModalService } from 'src/app/@core/services/pos-discount-modal.service';
import { PosExemptionModalService } from 'src/app/@core/services/pos-exemption-modal.service';
import { PosOrderObservationsService } from 'src/app/@core/services/pos-order-observations.service';
import { Branch } from 'src/app/models/branch';
import { Customer } from 'src/app/models/customer';
import { CustomerCreditValidation } from 'src/app/models/customer-credit-validation';
import { Discount } from 'src/app/models/discount';
import { ExonerationReason } from 'src/app/models/exoneration-reason';
import { Loan } from 'src/app/models/loan';
import { Order } from 'src/app/models/order';
import { Paging } from 'src/app/models/page';
import { ProductCart } from 'src/app/models/product-cart';
import { LoanFormRequest } from 'src/app/models/requests/loan-form-request';
import { LoanSimulationRequest } from 'src/app/models/requests/loan-simulation-request';
import { OrderRequest } from 'src/app/models/requests/order-request';
import { ResponseModel } from 'src/app/models/response-model';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { TaxExemption } from 'src/app/models/tax-exemption';
import { BranchesService } from 'src/app/services/branches.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CreditService } from 'src/app/services/credit.service';
import { ExonerationReasonService } from 'src/app/services/exoneration-reason.service';
import {
  CustomerType,
  LoanClass,
  OrderConditions,
  OrderConditionsType,
  Personality,
  RoleType,
} from './../../../@core/constants';
import { CreditCalculatorStepComponent } from './../credit-calculator-step/credit-calculator-step.component';

@Component({
  selector: 'app-create-sales',
  templateUrl: './create-sales.component.html',
  styleUrls: ['./create-sales.component.sass'],
})
export class CreateSalesComponent implements OnInit {
  @ViewChild('creditCalculatorStepComponent')
  creditCalculatorStepComponent!: CreditCalculatorStepComponent;

  formCustomer: FormGroup;
  formOrderType: FormGroup;
  formCart: FormGroup;
  formCreditConditions: FormGroup;
  documentsForm: FormGroup;

  actualBranch: Branch = new Branch();
  customer: CustomerTableModel = new CustomerTableModel();
  employee: CustomerTableModel = new CustomerTableModel();
  orderTypeId: number = 0;

  taxExemption: TaxExemption = new TaxExemption();
  discount: Discount = new Discount();
  exemptionReasons: Paging<Array<ExonerationReason>> = new Paging<
    Array<ExonerationReason>
  >([]);
  orderCondition: string = 'credito'; //WIP: to add conditons from other types of orders for next iteration
  creditConditionsResult: CustomerCreditValidation =
    new CustomerCreditValidation();
  order: Order = new Order();
  orderRequest: OrderRequest = new OrderRequest();
  loanSimulationRequest: Array<LoanSimulationRequest> = new Array();
  productsCart: Paging<Array<ProductCart>> = new Paging([]);

  loansSimulated: Array<Loan> = new Array();

  endorsementIntermediary: Customer | null = null;

  calculatedFeesFormArray: FormArray = new FormArray([]);

  observations: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private exonerationReasonService: ExonerationReasonService,
    private posExemptionModalService: PosExemptionModalService,
    private posDiscountModalService: PosDiscountModalService,
    private creditService: CreditService,
    private configService: ConfigurationService,
    private branchesService: BranchesService,
    private configurationService: ConfigurationService,
    private posObservationsService: PosOrderObservationsService
  ) {
    this.formCustomer = this.formBuilder.group({
      sellerName: '',
      customerFullName: '',
      rtnFiscalId: '',
    });

    this.formOrderType = this.formBuilder.group({
      orderTypeId: new FormControl(0, [Validators.required]),
    });

    this.formCreditConditions = this.formBuilder.group({
      term: new FormControl(),
      downPaymentPercent: new FormControl(),
      downPaymenyCurrency: new FormControl(),
      initialDate: new FormControl(),
      firstFeeDate: new FormControl(),
      fee: new FormControl(),
    });

    this.documentsForm = this.formBuilder.group({
      fiscalIdFile: new FormControl('', Validators.required),
      rtnFiscalIdFile: new FormControl(''),
      ip800File: new FormControl('', Validators.required),
      proofOfPaymentFile: new FormControl(''),
    });

    this.formCart = this.formBuilder.group({});

  }

  async ngOnInit(): Promise<void> {
    this.exemptionReasons = (
      await this.exonerationReasonService.readExonerations().toPromise()
    ).response;
    const { branch } =
      this.configurationService.readSelectedBranchAndSalesOrg();
    this.actualBranch = (
      await this.branchesService.readBranch(branch.code).toPromise()
    ).response;
  }

  async getCustomerStepInfo(result: {
    customer: CustomerTableModel;
    employee: CustomerTableModel;
  }): Promise<void> {
    this.customer = result.customer;
    this.employee = result.employee;

    let code = '';
    let orderConditionType: OrderConditionsType;

    if (this.orderTypeId == OrderConditions.MORE_THAN_30_DAYS_FRANCHISED) {
      code = this.orderRequest.paymentResponsible;
      orderConditionType = OrderConditionsType.CREDIT_WITH_PAYMENT_RESPONSIBLE;
    } else if (this.orderTypeId == OrderConditions.CREDIT_QUOTATION) {
      code = result.customer.code;
      orderConditionType = OrderConditionsType.REFERENCE;
    } else {
      code = result.customer.code;
      orderConditionType = OrderConditionsType.CREDIT;
    }

    this.creditConditionsResult = (
      (await this.creditService
        .getCustomerCreditConditions(
          code,
          orderConditionType
        )
        .toPromise()
        .catch((s) => {
          this.customer = new CustomerTableModel();
          this.employee = new CustomerTableModel();
          this.formCustomer.reset();
        })) as ResponseModel<CustomerCreditValidation>
    ).response;

    this.loadCreditConfigForm(this.creditConditionsResult);
  }

  async getCreditInfo(event: any) {
    if (this.orderTypeId == OrderConditions.MORE_THAN_30_DAYS_FRANCHISED) {

      this.creditConditionsResult = (
        (await this.creditService
          .getCustomerCreditConditions(
            this.orderRequest.paymentResponsible,
            OrderConditionsType.CREDIT_WITH_PAYMENT_RESPONSIBLE
          )
          .toPromise()) as ResponseModel<CustomerCreditValidation>
      ).response;

      this.loadCreditConfigForm(this.creditConditionsResult);
    }
  }

  async setTaxExemption(): Promise<void> {
    await this.posExemptionModalService.show(
      this.taxExemption,
      (value: TaxExemption) => {
        this.taxExemption = value;
      }
    );
  }

  async setDiscount(): Promise<void> {
    await this.posDiscountModalService.show(
      this.discount,
      (discount: Discount) => {
        this.discount = discount;
      }
    );
  }

  async getOrder(result: {
    order: Order;
    orderRequest?: OrderRequest;
    productsCart: Paging<Array<ProductCart>>;
  }): Promise<void> {
    this.order = result.order;
    this.orderRequest = result.orderRequest!;
    this.loanSimulationRequest = new Array();
    this.loadCreditConfigForm(this.creditConditionsResult);
    this.productsCart = new Paging(result.productsCart.content);
    this.calculatedFeesFormArray.reset();

    if (
      this.orderTypeId === OrderConditions.MORE_THAN_30_DAYS_FRANCHISED ||
      this.orderTypeId === OrderConditions.FINAL_CUSTOMER_CREDIT ||
      this.orderTypeId === OrderConditions.CREDIT_QUOTATION
    ) {
      for (const [i, ls] of this.loansSimulated.entries()) {
        const loanSimulationRequest =
          await this.calculateMoreThanThirtyDaysLoanSimulation(ls);

        this.loanSimulationRequest.push(loanSimulationRequest);

        this.calculatedFeesFormArray
          .at(i)
          .setValue(loanSimulationRequest.feeAmount || null);
        this.calculatedFeesFormArray.at(i).markAsDirty();
        this.calculatedFeesFormArray.at(i).markAsTouched();
        console.log(this.calculatedFeesFormArray.valid);
      }
    } else {
      this.calculateThirtyDaysLoanSimulation();
    }
  }

  loadCreditConfigForm(creditConditionsResult: CustomerCreditValidation) {
    CustomerCreditValidation.setDownpaymentLimitsCurrency(
      creditConditionsResult,
      this.order.totals.netTotal
    );
    this.formCreditConditions = this.formBuilder.group({
      term: new FormControl('', [
        Validators.required,
        Validators.min(creditConditionsResult.minTerm),
        Validators.max(creditConditionsResult.maxTerm),
      ]),
      downPaymentPercent: new FormControl('', [
        Validators.required,
        Validators.min(creditConditionsResult.minDownPayment),
        Validators.max(creditConditionsResult.maxDownPayment),
      ]),
      downPaymenyCurrency: new FormControl('', [
        Validators.required,
        Validators.min(creditConditionsResult.minDownPaymentCurrency),
        Validators.max(creditConditionsResult.maxDownPaymentCurrency),
      ]),
      initialDate: new FormControl(
        this.datePipe.transform(new Date(), 'MM/dd/YYYY')
      ),
      firstFeeDate: new FormControl('', Validators.required),
      fee: new FormControl('', Validators.required),
    });
  }

  async calculateThirtyDaysLoanSimulation() {
    let loanFormValues: LoanFormRequest = this.getLoanFormValuesThirtyDays();
    this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_THIRTY_DAYS;

    switch (this.orderTypeId as OrderConditions) {
      case OrderConditions.CREDIT_QUOTATION:
        if (this.customer.personality == Personality.PERSON) {
          this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_PERSON;
        }
        if (this.customer.customerType == CustomerType.EMPLOYEE) {
          this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_EMPLOYEE;
        }
        loanFormValues = await this.getLoanFormValuesCreditQuotation(
          this.creditConditionsResult.loanClass
        );
        break;
    }
    this.loanSimulationRequest.push(
      LoanSimulationRequest.buildRequest(
        this.order,
        this.creditConditionsResult,
        loanFormValues,
        this.configService.readSelectedBranchAndSalesOrg()
      )
    );
  }

  async calculateMoreThanThirtyDaysLoanSimulation(simulatedLoan: Loan) {
    let loanFormValues: LoanFormRequest =
      this.getLoanFormValuesMoreThanThirtyDays(simulatedLoan);
    this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_THIRTY_DAYS;

    switch (this.orderTypeId as OrderConditions) {
      case OrderConditions.CREDIT_QUOTATION:
        if (this.customer.personality == Personality.PERSON) {
          this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_PERSON;
        }

        if (this.customer.customerType == CustomerType.EMPLOYEE) {
          this.creditConditionsResult.loanClass = LoanClass.CLASS_FOR_EMPLOYEE;
        }

        break;
    }

    const intermediaries = [];

    if (this.endorsementIntermediary !== null) {
      intermediaries.push(this.endorsementIntermediary.code);
    }

    if (this.orderCondition != undefined) {
      this.order.orderCondition = this.orderTypeId;
    }

    return LoanSimulationRequest.buildRequest(
      this.order,
      this.creditConditionsResult,
      loanFormValues,
      this.configService.readSelectedBranchAndSalesOrg(),
      intermediaries,
      true,
      simulatedLoan.amount,
      simulatedLoan.rate
    );
  }

  private async getLoanFormValuesCreditQuotation(loanClass: string) {
    let creditConditions = (
      await this.creditService
        .getCreditReferences(
          this.configurationService.readSelectedBranchAndSalesOrg().salesOrg
            .code,
          undefined,
          loanClass
        )
        .toPromise()
    ).response;

    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    today.setMonth(today.getMonth() + 1);

    let loanFormValues: LoanFormRequest = new LoanFormRequest();
    loanFormValues.downPaymentPercent = creditConditions[0].minDownPayment;
    loanFormValues.downPaymenyCurrency = 0;
    loanFormValues.fee = this.order.totals.netTotal;
    loanFormValues.term = creditConditions[0].minTerm;
    loanFormValues.initialDate =
      this.datePipe.transform(today, 'YYYYMMdd') || '';
    loanFormValues.firstFeeDate =
      this.datePipe.transform(today, 'YYYYMMdd') || '';
    loanFormValues.firstFeeDateRaw = today;
    return loanFormValues;
  }

  private getLoanFormValuesThirtyDays() {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    today.setMonth(today.getMonth() + 1);

    let loanFormValues: LoanFormRequest = new LoanFormRequest();
    loanFormValues.downPaymentPercent = 0;
    loanFormValues.downPaymenyCurrency = 0;
    loanFormValues.fee = this.order.totals.netTotal;
    loanFormValues.term = 1;
    loanFormValues.initialDate =
      this.datePipe.transform(today, 'YYYYMMdd') || '';
    loanFormValues.firstFeeDate =
      this.datePipe.transform(today, 'YYYYMMdd') || '';
    loanFormValues.firstFeeDateRaw = today;
    return loanFormValues;
  }

  private getLoanFormValuesMoreThanThirtyDays(simulatedLoan: Loan) {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    const loanFormValues: LoanFormRequest = new LoanFormRequest();

    if (this.loansSimulated.length > 0) {
      loanFormValues.downPaymentPercent =
        simulatedLoan.parameters.downPaymentPercentage;
      loanFormValues.downPaymenyCurrency = simulatedLoan.parameters.downPayment;
      loanFormValues.fee =
        (simulatedLoan.conditions.find((c) => c.conditionType === '0261')
          ?.amount || 0) +
        (simulatedLoan.conditions.find((c) => c.conditionType === '0203')
          ?.amount || 0);

      loanFormValues.term = Number(simulatedLoan.term);
      loanFormValues.initialDate =
        simulatedLoan.parameters.calculatedDate || '';
      loanFormValues.firstFeeDate = simulatedLoan.parameters.startDate || '';
      loanFormValues.firstFeeDateRaw =
        simulatedLoan.parameters.startDateRaw || today;
    }

    return loanFormValues;
  }

  getFileInfo(file: string) {
    return this.documentsForm.get(file)?.value;
  }

  getCartForm(tableForm: FormGroup) {
    this.formCart = tableForm;
  }

  get customerCode(): string {
    return this.customer ? this.customer.code : '';
  }

  async onSelectOrderType(orderTypeId: number) {
    this.orderTypeId = orderTypeId;
    this.formOrderType.get('orderTypeId')?.setValue(orderTypeId);
    this.formOrderType.get('orderTypeId')?.markAsDirty();

    let conditionType = this.getOrderConditionTypeByOrderTypeId(orderTypeId);
    if (conditionType != undefined) {
      await this.calculateCreditConditions(conditionType);
    }
  }

  private async calculateCreditConditions(conditionType: OrderConditionsType) {
    this.creditConditionsResult = (
      (await this.creditService
        .getCustomerCreditConditions(
          this.customer.code,
          conditionType
        )
        .toPromise()
        .catch((s) => {
          this.customer = new CustomerTableModel();
          this.employee = new CustomerTableModel();
          this.formCustomer.reset();
        })) as ResponseModel<CustomerCreditValidation>
    ).response;
  }

  async handleChangeStep(event: StepperSelectionEvent): Promise<void> {
    if (
      this.orderTypeId === OrderConditions.FINAL_CUSTOMER_CREDIT ||
      this.orderTypeId === OrderConditions.MORE_THAN_30_DAYS_FRANCHISED ||
      this.orderTypeId === OrderConditions.CREDIT_QUOTATION
    ) {
      if (event.selectedIndex === 3) {
        await this.creditCalculatorStepComponent.simulateOrder(
          true,
          'createSalesComponent'
        );
      }
    }
  }
  removeDiscount() {
    this.discount = new Discount();
  }

  handleCreditConditionChange(conditions: {
    loans: Array<Loan>;
    endorsementIntermediary: Customer | null;
  }): void {
    this.loansSimulated = conditions.loans;
    this.endorsementIntermediary = conditions.endorsementIntermediary;

    this.buildFeesFormArray(this.loansSimulated);

    this.getOrder({
      order: this.order,
      orderRequest: this.orderRequest,
      productsCart: this.productsCart,
    });
  }

  buildFeesFormArray(loans: Array<Loan>): void {
    this.calculatedFeesFormArray = this.formBuilder.array([]);
    this.loansSimulated.forEach((l) => {
      this.calculatedFeesFormArray.push(
        this.formBuilder.control(l.parameters.feeAmount, Validators.required)
      );
    });
  }
  private getOrderConditionTypeByOrderTypeId(orderTypeId: OrderConditions): OrderConditionsType | undefined {

    switch (orderTypeId) {
      case OrderConditions.UNTIL_30_DAYS_FRANCHISED:
        return OrderConditionsType.CREDIT_30_DAYS;
      case OrderConditions.FINAL_CUSTOMER_CREDIT:
        return OrderConditionsType.CREDIT;
      case OrderConditions.MORE_THAN_30_DAYS_FRANCHISED:
        return OrderConditionsType.CREDIT;
      case OrderConditions.CREDIT_QUOTATION:
        return OrderConditionsType.REFERENCE;
      default:
        return undefined;
    }
  }


  async setObservations() {
    await this.posObservationsService.show(this.observations, (observation: string) => {
      this.observations = observation;
    });
  }
}


