<form [formGroup]="documentsForm">
  <div class="container mt-2">
    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="fiscalId"><b>Identidad</b></label>
      <div class="col-md-9">
        <input type="file" #fiscalId id="fiscalId" (change)="getFiscalIdFile($event)">
        <label class="custom-file-label mb-2" for="fiscalId">{{fiscalIdFileName}}</label>
      </div>
      <div class="col-md-1"><button class="btn btn-danger" (click)="deleteFile('IDENTIDAD')" title="Eliminar"><i class="fa fa-trash"></i></button></div>
    </div>

    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="rtnFiscalId"><b>RTN</b></label>
      <div class="col-md-9">
        <input type="file" #rtnFiscalId id="rtnFiscalId" (change)="getRtnFiscalIdFile($event)">
        <label class="custom-file-label mb-2" for="rtnFiscalId">{{rtnFiscalIdFileName}}</label>
      </div>
      <div class="col-md-1"><button class="btn btn-danger" (click)="deleteFile('RTN')" title="Eliminar"><i class="fa fa-trash"></i></button></div>
    </div>

    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="proofOfPayment"><b>Comprobante de pago</b></label>
      <div class="col-md-9">
        <input type="file" #proofOfPayment id="proofOfPayment" (change)="getProofOfPaymentFile($event)">
        <label class="custom-file-label mb-2" for="proofOfPayment">{{proofOfPaymentName}}</label>
      </div>
      <div class="col-md-1"><button class="btn btn-danger" (click)="deleteFile('PROOF_OF_PAYMENT')" title="Eliminar"><i class="fa fa-trash"></i></button></div>
    </div>

    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="purchaseOrder"><b>Orden de compra</b></label>
      <div class="col-md-9">
        <input type="file" #purchaseOrder id="purchaseOrder" (change)="getpurchaseOrderFile($event)">
        <label class="custom-file-label mb-2" for="purchaseOrder">{{purchaseOrderName}}</label>
      </div>
      <div class="col-md-1"><button class="btn btn-danger" (click)="deleteFile('PURCHASE_ORDER')" title="Eliminar"><i class="fa fa-trash"></i></button></div>
    </div>

    <div class="form-group row mb-3">
      <label class="col-md-2 col-form-label" for="ip800"><b>IP-800</b></label>
      <div class="col-md-9">
        <div class="input-group p-0">
          <input type="text" id="ip800File" class="form-control" disabled [value]="ip800FileName">
          <span class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="getIp800File()"
              [disabled]="disableGenerateIP800()">
              Generar
            </button>
          </span>
        </div>
      </div>
      <div class="col-md-1"><button class="btn btn-danger" title="Eliminar" (click)="deleteFile('IP800')"><i class="fa fa-trash"></i></button></div>
      <small class="text-info">
        * El documento IP800 solo se puede generar si hay un equipo en la orden
      </small>
    </div>

  </div>
</form>
