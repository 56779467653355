<div class="row">
  <div class="col-12">
    <div class="row d-flex">
      <div class="col-md col-sm-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-money bg-success p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3"> {{order.totals.netTotal | currency: 'HNL ':'symbol' : '1.2-2'}}
            </div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Total a Facturar</div>
          </div>
        </div>
      </div>
      <div class="col-md col-sm-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-credit-card bg-warning p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3"> {{getTotalToFinance() | currency: 'HNL ':'symbol' : '1.2-2'}}
            </div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Total a Financiar</div>
          </div>
        </div>
      </div>
      <div class="col-md col-sm-3">
        <div class="card">
          <div class="card-body p-0 clearfix">
            <i class="fa fa-male bg-primary p-4 font-2xl mr-3 float-left"></i>
            <div class="h6 text-primary mb-0 pt-3">
              <p class="col-10 selection" (click)="openEndorsementSelection()">{{
                !!endorsementIntermediary
                ? '[' + endorsementIntermediary.code + ']' + endorsementIntermediary.fullName
                : 'Seleccione un aval'
                }}</p>
            </div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">Aval</div>
          </div>
        </div>
      </div>
    </div>
    <accordion [isAnimated]="true" class="mt-2">

      <accordion-group [isOpen]="true" *ngFor="let tform of this.tableForm.controls; let i = index">

        <span accordion-heading>
          <i class="fa fa-file"></i> Contrato {{i + 1}}
        </span>


        <credit-condition-step [creditForm]="getCreditConditionsForm(i)" [order]="order"
          [customerCreditConditions]="customerCreditConditions" [loan]="loans[i]"
          [endorsementIntermediary]="endorsementIntermediary" [creditPromotion]="this.getAppliedPromotion(i)" [isEditingMode]="isEditingMode" (quotaCalculated)="handleQuotaCalculated(i,$event)"
          [calculatedFeesFormArray]="calculatedFeesFormArray"
          (termChange)="handleTermChangeEmit(i,$event)" (downpaymentChange)="handleDownpaymentChange(i,$event)"
          (firstPaymentDateChange)="handleFirstPaymentDateChange(i,$event)"[isPromotionOrDiscountApplied]="isPromotionOrDiscountApplied" #creditConditionStep>
        </credit-condition-step>

        <form class="w-100 table-responsive" [formGroup]="this.getFormGroupByIndex(i)">

          <table class="table table-hover">

            <thead class="thead-dark">

              <tr>
                <th>Codigo</th>
                <th>Descripcion</th>
                <th>Precio Unitario</th>
                <th>Sub-Total</th>
                <th>Prima</th>
                <th>Plazo</th>
                <th>Politica</th>
              </tr>

            </thead>

            <tbody>

              <tr *ngFor="let pform of this.getFormGroupOfProducts(i).controls; let j=index"
                [formGroup]="this.castAbstractControlToFormGroup(pform)">
                <td>{{pform.value.productCode}}</td>
                <td>{{pform.value.productName}}</td>
                <td><b>{{pform.value.price | currency:'HNL '}}</b></td>
                <td><b>{{this.loans[i].products[j].netTotal | currency:'HNL '}}</b></td>
                <td>
                  <div class="form-group">
                    <input type="number" class="form-control" id="txtDownPayment{{j}}" formControlName="downPayment">
                  </div>
                </td>
                <td>
                  <div [hidden]="pform.value.isService" class="form-group">
                    <select class="form-control" name="cmbTerm{{j}}" id="cmbTerm{{j}}" (change)="handleTermChange(i,j)"
                      formControlName="term">
                      <option *ngFor="let key of getArrayOfKeysFromMap(this.loans[i].products[j].dataSourceComboBox)"
                        [value]="key">{{this.loans[i].products[j].dataSourceComboBox?.get(key)}}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div [hidden]="pform.value.isService" class="form-group">

                    <ng-select id="cmbPolicy{{j}}" [items]="this.promotions" bindLabel="description" bindValue="code"
                      [selectOnTab]="true" (change)="handlePolicyChange(i,j)" formControlName="policy">
                    </ng-select>
                
                  </div>
                </td>
              </tr>

            </tbody>

          </table>

        </form>


      </accordion-group>

    </accordion>
  </div>

</div>