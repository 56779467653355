export class ProductQuantityUnits {
    selector: string;
    name: string;
    factor: string;

    constructor() {
        this.selector = "";
        this.name = "";
        this.factor = "";
    }

}