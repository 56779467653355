import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Customer } from 'src/app/models/customer';
import { VerifyCustomerCheckResponse } from 'src/app/models/response/VerifyCustomerCheckResponse';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-pos-customer-update-modal',
  templateUrl: './pos-customer-update-modal.component.html',
  styleUrls: ['./pos-customer-update-modal.component.sass']
})
export class PosCustomerUpdateModalComponent implements OnInit {

  @Input() customerTableModel:CustomerTableModel = new CustomerTableModel();
  @Input() customerVerifyData:VerifyCustomerCheckResponse | undefined;

  @Output() customerUpdated = new EventEmitter<boolean>();

  customer:Customer = new Customer();

  constructor(private bsModalRef: BsModalRef, private customerService:CustomerService) { }

  async ngOnInit(): Promise<void> {
    this.customer = await this.customerService.readCustomerByCode(this.customerTableModel.code).toPromise();
  }

  close(): void {
    this.bsModalRef.hide();
    this.customerUpdated.emit(false);
    this.customerUpdated.complete();
  }

  handleCompleteUpdate(responseUpdate:boolean){
    this.bsModalRef.hide();
    this.customerUpdated.emit(responseUpdate);
    this.customerUpdated.complete();
  }

}
