import { ToastrService } from 'ngx-toastr';
import { CreditStatusFranchisedResponseModel } from './../../../../models/response/customerCreditStatusResponseModel';
import { OnbaseService } from './../../../../services/onbase.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from 'src/app/models/customer';
import { OnbaseProcedureResponseModel } from 'src/app/models/response/onbaseProcedureResponseModel';
import { CreditPlansCodes, Personality } from 'src/app/@core/constants';
import { CustomerValidationsService } from '../../services/customer-validations.service';

@Component({
  selector: 'app-fast-actions-buttons',
  templateUrl: './fast-actions-buttons.component.html',
  styleUrls: ['./fast-actions-buttons.component.sass'],
})
export class FastActionsButtonsComponent implements OnInit {
  @Input()
  currentCustomerCreditConditions: CreditStatusFranchisedResponseModel = new CreditStatusFranchisedResponseModel();
  @Input() currentCustomer: Customer = new Customer();
  creditPlans!: Array<OnbaseProcedureResponseModel>;

  private delayedAmountByContract: any[] = [];

  constructor(
    private router: Router,
    private onbaseService: OnbaseService,
    private toastr: ToastrService,
    private customerValidationsService: CustomerValidationsService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.fetchCreditPlans();
  }

  private async fetchCreditPlans(): Promise<void> {
    this.creditPlans = (
      await this.onbaseService.readCreditPlans().toPromise()
    ).response.content;
  }

  private getCreditPlanByCode(
    code: CreditPlansCodes
  ): OnbaseProcedureResponseModel {
    return (
      this.creditPlans.find((c) => c.code === code) ||
      new OnbaseProcedureResponseModel()
    );
  }

  async handlePacClick(): Promise<void> {
      this.redirectToCreateCreditRequest(CreditPlansCodes.PAC);
  }

  async handleCvnClick(): Promise<void> {
    this.redirectToCreateCreditRequest(CreditPlansCodes.CVN);
  }

  async handleRaiseLimitClick(): Promise<void> {
    if (
      this.currentCustomer.mainPersonalInfo?.personality ===
      Personality.LEGAL_ENTITY
    ) {
      const continueToCreate = await this.customerValidationsService.validateCustomerInDefault(this.currentCustomer.code);
      if (continueToCreate) {
        this.redirectToCreateCreditRequest(CreditPlansCodes.JUR);
      }
    }

    if (
      this.currentCustomer.mainPersonalInfo?.personality === Personality.PERSON
    ) {
      const continueToCreate = await this.customerValidationsService.validateCustomerInDefault(this.currentCustomer.code);
      if (continueToCreate) {
        this.redirectToCreateCreditRequest(CreditPlansCodes.NOR);
      }
    }
  }

  async handleInvoiceClick(): Promise<void> {
    this.toastr.info(
      'Funcionalidad en proceso de desarrollo',
      'Opcion en desarrollo'
    );
  }

  private redirectToCreateCreditRequest(
    creditPlanCode: CreditPlansCodes
  ): void {
    this.router.navigate(['customer/createcreditrequest'], {
      state: {
        creditPlan: this.getCreditPlanByCode(creditPlanCode),
        customer: this.currentCustomer,
        customerCreditConditions: this.currentCustomerCreditConditions
      },
      replaceUrl: true,
    });
  }

  toAdvance() {
    this.router.navigate(['customer/advances', this.currentCustomer.code]);
  }
}
