import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../models/response-model';
import { Observable } from 'rxjs';
import { CatalogResponseWrapper } from '../models/catalogs';

@Injectable({
  providedIn: 'root',
})
export class CatalogsService {
  private url = `${environment.url}/catalogs`;

  constructor(private http: HttpClient) {}

  getCatalogByCatalogCode(
    catalogCode: string
  ): Observable<ResponseModel<CatalogResponseWrapper>> {
    let params = new HttpParams();

    params = params.append(`catalogCode`, catalogCode);

    return this.http.get<ResponseModel<CatalogResponseWrapper>>(this.url,{params});
  }
}

