<div class="modal-content">
  <form #fiscalIdForm="ngForm" (ngSubmit)="fiscalIdForm.valid && confirm()" novalidate class="needs-validation">
    <div class="modal-header">
      <h4 class="modal-title">Actualizar RTN</h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="discountType"><b>RTN</b></label>
        <div class="col-md-9">
          <input type="text" id="fiscalId" name="fiscalId" class="form-control" [(ngModel)]="fiscalId"
            #fiscalIdInput="ngModel" [mask]="maskRTN"
            [class.is-invalid]="(fiscalIdInput.touched || fiscalIdInput.dirty) && fiscalIdInput.invalid" required>

        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
      <button type="submit" class="btn btn-success">Confirmar</button>
    </div>
  </form>
</div><!-- /.modal-content -->
