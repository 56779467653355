import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CURRENCY_HNL, CURRENCY_USD, PAYMENTTYPESAVAILABLEFRANCHISED } from 'src/app/@core/constants';
import { AddPaymentModalService } from 'src/app/@core/services/add-payment-modal.service';
import { ColumnTable } from 'src/app/models/column-table';
import { Paging } from 'src/app/models/page';
import { Payment } from 'src/app/models/payment';
import { PaymentMethod } from 'src/app/models/payment-method';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ExchangeRateService } from 'src/app/services/exchange-rate.service';
import { ParamsService } from 'src/app/services/params.service';
import { PaymentService } from 'src/app/services/payment.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-pos-payment-method',
  templateUrl: './pos-payment-method.component.html',
  styleUrls: ['./pos-payment-method.component.sass']
})
export class PosPaymentMethodComponent implements OnInit {

  @Input() totalAmount = 1000;
  @Input() contractAmount = 0;
  @Output() doPayment = new EventEmitter<{ payments: Array<Payment>, paymentsAmount: number, id: string }>();
  @Input() disablePaymentButton: boolean = false;
  @Input() id: string = '';
  @Input() customerCode: string = "";
  directPaymentMethods: Array<PaymentMethod> = [];
  otherPaymentMethods: Array<PaymentMethod> = [];
  otherPayment: PaymentMethod;

  paymentList: Paging<Array<PaymentMethod>> = new Paging([]);
  restCashPayment = 0;
  validatedPayments: Array<Payment> = [];
  exchangeRate = 0;
  paymentsAmount = 0;
  contractPayment = new PaymentMethod();
  private readonly CASH_CODE = "ZE01";
  private readonly CONTRACT_CODE = "CT";
  private readonly DIRECT_PAYMENTS_COUNT = 9;
  private readonly MULTIDESCRIPTION = 'MULTIDESCRIPTION';
  private readonly USD = "USD";

  constructor(
    private paymentService: PaymentService,
    private addPaymentModalService: AddPaymentModalService,
    private configurationService: ConfigurationService,
    private route: ActivatedRoute,
    private exchangeRateService: ExchangeRateService,
    private currencyPipe: CurrencyPipe,
    private paramsService: ParamsService) {
    this.otherPayment = new PaymentMethod();
  }

  async ngOnInit(): Promise<void> {
    await this.getPaymentMethods();
    this.exchangeRate = (await this.exchangeRateService.readLatestExchangeRate()).rate;
    if (this.contractAmount) {
      this.paymentList.content.push(this.contractPayment);
      this.calculateRest();
    }
  }

  public setAmountContract() {

  }

  private async getPaymentMethods(): Promise<void> {

    const salesOrgObject = this.configurationService.readSelectedBranchAndSalesOrg().salesOrg;
    const salesOrg = salesOrgObject.code;

    let paymentMethods = (await this.paymentService.getPaymentsType().toPromise()).response.content;
    paymentMethods = paymentMethods.sort((a, b) => a.position > b.position ? 1 : -1);

    let param = await (await this.paramsService.readParam(PAYMENTTYPESAVAILABLEFRANCHISED).toPromise()).response;
    let paymenTypesAvailable = param != undefined ? param.value.split(',') : [];


    if (this.contractAmount) {
      const index = paymentMethods.findIndex(element => element.code === this.CONTRACT_CODE);
      if (index !== -1) {
        this.contractPayment = paymentMethods[index];
        this.contractPayment.amount = this.contractAmount;
        this.contractPayment.applied = this.contractAmount;
        this.contractPayment.paymentMethodCode = "ZC01";
      }
    }

    paymentMethods = paymentMethods.filter(p => paymenTypesAvailable.some(ptl => ptl == p.code));
    let paymentMethodTypes = (await this.paymentService.getPaymentMethods(salesOrg).toPromise()).response.content;

    paymentMethodTypes.forEach(type => {
      const index = paymentMethods.findIndex(element => element.code === type.paymentTypeCode);
      if (index !== -1) {
        const paymentMethod = paymentMethods[index];
        if (paymentMethod.style === this.MULTIDESCRIPTION && type.bankAccounts[salesOrg]) {
          type.name += ` ${type.bankAccounts[salesOrg]}`;
        }

        if (paymentMethod.methods?.length) {
          paymentMethod.methods.push(type);
        } else {
          paymentMethod.methods = [type];
        }
      }
    });

    this.directPaymentMethods = paymentMethods.slice(0, this.DIRECT_PAYMENTS_COUNT);
    this.otherPaymentMethods = paymentMethods.slice(this.DIRECT_PAYMENTS_COUNT)
      .filter(element => element.code !== 'AP' && element.code !== 'PAC' && element.code !== 'CT');
  }

  async addPayment(payment: PaymentMethod): Promise<void> {
    if(payment.methods == undefined){
      Swal.fire({
        title: 'Tipo de pago sin metodo asociado',
        text: 'Este tipo de pago no tiene metodo asociado',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });

      return;
    }
    await this.addPaymentModalService.show(payment, this.totalAmount, this.paymentsAmount, this.customerCode, this.paymentList.content, 0,
      () => {
        this.calculateRest();
      });
  }

  pay(): void {

    const payments = this.paymentList.content.map(paymentMethod => {
      const payment = new Payment();
      payment.amount = paymentMethod.currency == CURRENCY_USD ? paymentMethod.usdAmount : paymentMethod.amount;
      payment.bankName = paymentMethod.bank;
      payment.currency = paymentMethod.currency;
      payment.paymentMethodCode = paymentMethod.paymentMethodCode;
      payment.receiptNumber = paymentMethod.identifier ?? "";
      payment.number = paymentMethod.number ?? "";
      payment.name = paymentMethod.personName ?? "";

      return payment;
    });

    if (this.restCashPayment > 0) {
      const paymentRest: Payment = new Payment();
      paymentRest.amount = -this.restCashPayment.toFixed(2);
      paymentRest.currency = CURRENCY_HNL;
      paymentRest.paymentMethodCode = this.CASH_CODE;
      payments.push(paymentRest);
    }

    console.log(payments);
    this.validatedPayments = payments;
    this.doPayment.emit({ payments, paymentsAmount: this.paymentsAmount, id: this.id });
  }

  calculateRest(): void {
    let amount = 0;
    this.paymentsAmount = 0;

    this.paymentList.content.forEach(payment => {
      if (payment.code === this.CASH_CODE) {
        const finalAmount = this.round(this.totalAmount - (this.paymentsAmount + payment.amount));
        let rest = 0;

        if (finalAmount < 0) {
          rest = -finalAmount;
          amount = this.round(amount + rest);
        }

        payment.applied = this.round(payment.amount - rest);
        this.paymentsAmount += payment.amount;
      } else if (payment.currency === this.USD) {
        if (!payment.usdAmount) {
          payment.usdAmount = payment.amount;
        }
        const resultChange = this.round(payment.usdAmount * this.exchangeRate);
        const finalAmount = this.round(this.totalAmount - (this.paymentsAmount + resultChange));
        let rest = 0;

        if (finalAmount < 0) {
          rest = -finalAmount;
          amount = this.round(amount + rest);
        }
        payment.amount = resultChange;
        payment.exchangeRate = this.exchangeRate;

        const appliedAmountUSD = this.round((resultChange - rest) / this.exchangeRate);
        payment.applied = appliedAmountUSD;
        this.paymentsAmount += resultChange;
      } else {
        this.paymentsAmount += payment.amount;
      }
    });

    this.restCashPayment = amount;
    this.parseAmountsToShow();
    if (this.disablePaymentButton) {
      this.pay();
    }
  }

  parseAmountsToShow(): void {
    this.paymentList.content.forEach(payment => {
      if (payment.currency === this.USD) {
        payment.amountToShow = this.currencyPipe.transform(this.round(payment.amount / payment.exchangeRate), CURRENCY_USD, 'code', '1.0-2') ?? "";
        const usdAmount = this.currencyPipe.transform(payment.applied, CURRENCY_USD, 'code', '1.0-2') ?? "";
        const appliedHnl = this.round(payment.applied * payment.exchangeRate);
        payment.appliedToShow = `${usdAmount} (${appliedHnl})`;
      } else {
        payment.amountToShow = this.currencyPipe.transform(payment.amount, CURRENCY_HNL, 'code', '1.0-2') ?? "";
        payment.appliedToShow = this.currencyPipe.transform(payment.applied, CURRENCY_HNL, 'code', '1.0-2') ?? "";
      }
    });
  }

  removePaymentMethod(method: PaymentMethod): void {
    if (method.code != this.CONTRACT_CODE) {
      const index = this.paymentList.content.findIndex(payment => payment.code === method.code);
      if (index !== -1) {
        const payment = this.paymentList.content[index];
        payment.amount = 0;
        payment.amountToShow = '';
        payment.applied = 0;
        payment.appliedToShow = '';
        payment.usdAmount = 0;
        this.paymentList.content.splice(index, 1);
        this.calculateRest();
      }
    }
  }

  getPaymentHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('name', 'Tipo Pago'),
      new ColumnTable('amountToShow', 'Total'),
      new ColumnTable('appliedToShow', 'Aplicado'),
      new ColumnTable('identifier', 'Identificador'),
      new ColumnTable('code', 'Opciones', {
        type: 'action', actions: [
          {
            fn: (payment) => this.removePaymentMethod(payment),
            icon: 'fa fa-times text-danger',
            tooltip: "Eliminar pago",
            isDisabled: false,
          }
        ],
        iconClass: "fa fa-cogs",
        isIcon: true
      }),
    ];
  }

  private round(amount: number): number {
    return Math.round(amount * 100) / 100;
  }

}
