import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PromotionPaymentType, PromotionType } from 'src/app/@core/constants';
import { PosPromotionsDetailsModalService } from 'src/app/@core/services/pos-promotions-details-modal.service';
import { PosPromotionsModalService } from 'src/app/@core/services/pos-promotions-modal.service';
import { DateUtils } from 'src/app/@core/utils/date-utils';
import { ColumnTable } from 'src/app/models/column-table';
import { Filter } from 'src/app/models/filter';
import { FilterTable } from 'src/app/models/filter-table';
import { PromotionFilter } from 'src/app/models/filters/promotion-filter';
import { Paging } from 'src/app/models/page';
import { Promotion } from 'src/app/models/promotion';
import { PromotionsService } from 'src/app/services/promotions.service';

@Component({
  selector: 'promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.sass']
})
export class PromotionListComponent implements OnInit {

  promotions: Paging<Array<Promotion>> = new Paging([]);
  customerCode: string = "";;

  @Input()
  isModal: boolean = false;
  constructor(private promotionService: PromotionsService, private router: Router, private promotionsDetailsModalService: PosPromotionsDetailsModalService) {

  }

  async ngOnInit(): Promise<void> {
    await this.getPromotionData({ page: 0, pageSize: 10, value: { promotionCodes: [] } })
  }

  async getPromotionData(filter: Filter<PromotionFilter>): Promise<void> {
    filter.value.promotionCodes = [];
    filter.value.customerCode = this.customerCode;
    this.promotions = await (await this.promotionService.readPromotions(filter).toPromise()).response;

    this.promotions.content = this.promotions.content.map(m => {
      m.startTime = m.startTime || "000000";
      m.endTime = m.endTime || "235959";
      m.starDateRaw = DateUtils.getDateAndTimeFromString(m.startDate, m.startTime);
      m.endDateRaw = DateUtils.getDateAndTimeFromString(m.endDate, m.endTime);
      return m;
    })

    // await this.promotionModalService.showDetails(this.promotions.content[0]);
  }

  getPromotionsHeaders() {
    let result = [
      new ColumnTable("code", "Código"),
      new ColumnTable("description", "Descripción", { isSmall: true }),
      new ColumnTable("type", "Tipo", { type: "badge", valueMap: this.getTypeMap() }),
      new ColumnTable("paymentType", "Condición", { type: "badge", valueMap: this.getPaymentTypeMap() }),
      new ColumnTable("starDateRaw", "Fecha Inicial", { type: "date" }),
      new ColumnTable("endDateRaw", "Fecha Final", { type: "date" })
    ];
    if (!this.isModal) {
      result.push(new ColumnTable("code", "Opciones", {
        type: "action", actions: [{
          fn: async (promotion: Promotion) => {
            if (this.isModal) {
              await this.promotionsDetailsModalService.show(promotion);
            }
            else {
              this.router.navigate(['products/promotions', promotion.code])
            }
          }, isDisabled: false, tooltip: "Ver Detalles", icon: "fa fa-arrow-circle-o-right"
        }]
      }))
    }
    return result;

  }

  getPromotionsFilters() {
    return [
      new FilterTable("searchText", "Búsqueda General"),
      new FilterTable("promotionCode", "Código de Promoción"),
      new FilterTable("current", "Vigente", { type: "switch" }),
      new FilterTable("forCustomer", "Aplicable al Cliente", { type: "switch" }),
    ]
  }

  getTypeMap() {
    let result = new Map<string, string>();
    result.set(PromotionType.GENERAL, "General");
    result.set(PromotionType.DIRECTED, "Dirigida");
    return result;
  }

  getPaymentTypeMap() {
    let result = new Map<string, string>();
    result.set(PromotionPaymentType.EITHER, "General");
    result.set(PromotionPaymentType.CREDIT, "Crédito");
    result.set(PromotionPaymentType.RETAIL, "Contado");
    return result;
  }

  async promoRowClick(promotion: Promotion) {
    if (this.isModal) {
      await this.promotionsDetailsModalService.show(promotion);
    }
  }

}
