import { INavData } from '@coreui/angular';
import { Role } from './app/@core/constants';
import { INavDataRoles } from './app/models/nav-data-roles';

export const navItemsWithRoles: INavDataRoles[] = [
  {
    name: "Menú Principal",
    title: true
  },
  {
    name: 'Salir',
    icon: 'fa fa-lock',
    class: 'mt-auto',
    url: '/logout',
  },

]
