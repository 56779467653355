<accordion [isAnimated]="true">
  <accordion-group [isOpen]="true">
    <span accordion-heading>
      <i class='fa fa-chevron-down'></i> Información General
    </span>
    <div class="container">
      <div class="row">
        <div class="col-2 text-left">
          <h6>Código:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.code}}
        </div>
        <div class="col-2 text-left">
          <h6>Nombre:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.name}}
        </div>
      </div>
      <div class="row">
        <div class="col-2 text-left">
          <h6>Descripción:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.description}}
        </div>
        <div class="col-2 text-left">
          <h6>Precio:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.price.currency}} {{product.price.amount | number:'1.2-2'}}
        </div>
      </div>
      <div class="row">
        <div class="col-2 text-left">
          <h6>Impuesto:</h6>
        </div>
        <div class="col-4 text-left">
          {{(tax.value/100) | percent}}
        </div>
        <div class="col-2 text-left">
          <h6>Ubicación del Almacén:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.wharehouseLocation}}
        </div>
      </div>
      <div class="row">
        <div class="col-2 text-left">
          <h6>Número de Almacén:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.wharehouseNumber}}
        </div>
        <div class="col-2 text-left">
          <h6>Grupo de Material:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.materialGroup}}
        </div>
      </div>
      <div class="row">
        <div class="col-2 text-left">
          <h6>EAN:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.eans }}
        </div>
        <div class="col-2 text-left">
          <h6>Total Stock:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.carStock }}
        </div>
      </div>
      <div class="row">
        <div class="col-2 text-left">
          <h6>Garantía:</h6>
        </div>
        <div class="col-4 text-left">
          {{product.warranty }}
        </div>
      </div>

    </div>
  </accordion-group>
  <accordion-group>
    <span accordion-heading>
      <i class='fa fa-chevron-down'></i> EANS
    </span>
    <ul class="list-group">
      <div *ngFor="let item of product.eans" >
        <li class="list-group-item">{{item}}</li>
      </div>
    </ul>
  </accordion-group>
  <accordion-group>
    <span accordion-heading>
      <i class='fa fa-chevron-down'></i> Caracteristicas
    </span>
    <table class="table table-striped">
      <thead class="thead-dark">
        <th>Caracteristica</th>
        <th>Valor</th>
      </thead>
      <tbody>
        <tr *ngFor="let featureProp of featureProperties" >
        <td>{{featureProp}}</td>
        <td>{{product.features[featureProp]}}</td>
        </tr>
      </tbody>
    </table>
  </accordion-group>
</accordion>
