import { Customer } from './../../../../models/customer';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DATE_PATTERN } from 'src/app/@core/constants';
import { CreditStatusFranchisedResponseModel } from 'src/app/models/response/customerCreditStatusResponseModel';

@Component({
  selector: 'app-customer-information-detail',
  templateUrl: './customer-information-detail.component.html',
  styleUrls: ['./customer-information-detail.component.sass']
})
export class CustomerInformationDetailComponent implements OnInit {
  @Input() currentCustomer: Customer;
  @Input() hideCreditInformation: boolean = false;
  @Input() currentCustomerCreditConditions: CreditStatusFranchisedResponseModel;
  constructor() {
    this.currentCustomer = new Customer();
    this.currentCustomerCreditConditions =
      new CreditStatusFranchisedResponseModel();
  }

  ngOnInit(): void {
  }

  getCalculateAge(birthdate: String): number | undefined {
    let age = 0;
    if (birthdate != undefined)
      age = moment().diff(moment(birthdate.toString(), DATE_PATTERN), 'years');

    return isNaN(age) ? undefined : age;
  }

}
