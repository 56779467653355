export class LoanCondition {
    conditionType: string;
    validFromstring: string;
    percentageRate: string;
    amount: number;
    currency: string;
    periodicity: string;
    calculatingstring: string;
    expirationstring: string;
    percentage: string;
    payment: string;

    constructor() {
        this.conditionType = "";
        this.validFromstring = "";
        this.percentageRate = "";
        this.amount = 0.0;
        this.currency = "";
        this.periodicity = "";
        this.calculatingstring = "";
        this.expirationstring = "";
        this.percentage = "";
        this.payment = "";
    }
}