import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosOrderRejectModalService } from 'src/app/services/pos-order-reject-modal.service';
import { PosOrderRejectModalComponent } from './pos-order-reject-modal.component';
import { OrderService } from 'src/app/services/order.service';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [PosOrderRejectModalComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers:[PosOrderRejectModalService,OrderService],
  exports:[PosOrderRejectModalComponent]
})
export class PosOrderRejectModalModule { }
