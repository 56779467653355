import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { PosCustomerCreateAddressModule } from '../pos-customer-create-address/pos-customer-create-address.module';
import { PosCustomerCreateLegalEntityComponent } from './pos-customer-create-legal-entity.component';


@NgModule( {
  declarations: [ PosCustomerCreateLegalEntityComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule,
    PosCustomerCreateAddressModule
  ],
  exports: [ PosCustomerCreateLegalEntityComponent ]
} )
export class PosCustomerCreateLegalEntityModule { }
