import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from 'src/app/@core/constants';
import { BiometryComponent } from './biometry.component';


const routes: Routes = [
  {
    path: '',
    component: BiometryComponent,
    data: {
      title: 'Biometría',
      id: 'BiometryModule_biometry'
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BiometryRoutingModule { }
