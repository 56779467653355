<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Crear Cliente</h4>
    <button type="button" class="close" (click)="cancel()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-pos-customer-create (saveCustomerEmitter)="saveCustomer($event)" [redirect]="false"></app-pos-customer-create>
  </div>

</div><!-- /.modal-content -->
