import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { PosTotalsComponent } from './pos-totals.component';



@NgModule({
  declarations: [PosTotalsComponent],
  imports: [
    CommonModule
  ],
  providers: [CurrencyPipe],
  exports: [PosTotalsComponent]
})
export class PosTotalsModule { }
