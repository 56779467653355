import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Customer } from 'src/app/models/customer';
import { DigitalSignature } from 'src/app/models/digital-signature';
import { OrderCompleteProcess } from 'src/app/models/order-complete-process';
import { PosPrintDocumentsModalComponent } from 'src/app/shared/pos-print-documents-modal/pos-print-documents-modal.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosPrintDocumentsModalService {

  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  show(order: OrderCompleteProcess, customer: Customer, digitalSignature: DigitalSignature) {
    return new Promise(async (resolve, reject) => {
      this.modalRef = this.modalService.show(PosPrintDocumentsModalComponent,
        {
          ignoreBackdropClick: true
        })

      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY} modal-md`)
      this.modalRef.content.processedOrder = order;
      this.modalRef.content.customer = customer;
      this.modalRef.content.digitalSignature = digitalSignature;
      this.modalRef.content.loadInfo();
      resolve(true);
    });
  }
}
