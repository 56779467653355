import { PaymentMethodType } from './payment-method-type';

export class PaymentMethod {
  code: string;
  currency: string;
  isAccountable: boolean;
  name: string;
  position: number;
  style: string;
  methods: PaymentMethodType[];
  // Custom
  amount: number;
  cardType: string;
  bank: string;
  identifier: string;
  number: string;
  personName: string;
  applied: number;
  amountToShow: string;
  appliedToShow: string;
  exchangeRate: number;
  usdAmount: number;
  paymentMethodCode: string;

  constructor() {
    this.code = "";
    this.currency = "";
    this.isAccountable = false;
    this.name = "";
    this.position = 0;
    this.style = "";
    this.methods = [];
    this.amount = 0;
    this.cardType = "";
    this.bank = "";
    this.identifier = "";
    this.number = "";
    this.personName = "";
    this.applied = 0;
    this.amountToShow = "";
    this.appliedToShow = "";
    this.exchangeRate = 1;
    this.usdAmount = 0;
    this.paymentMethodCode = "";
  }

  public buildPaymentMethod(paymentMethod: PaymentMethod){
    this.code = paymentMethod.code;
    this.currency = paymentMethod.currency;
    this.isAccountable = paymentMethod.isAccountable;
    this.name = paymentMethod.name;
    this.position = paymentMethod.position;
    this.style = paymentMethod.style;
    this.methods = paymentMethod.methods;
    this.amount = paymentMethod.amount;
    this.cardType = paymentMethod.cardType;
    this.bank = paymentMethod.bank;
    this.identifier = paymentMethod.identifier;
    this.number = paymentMethod.number;
    this.personName = paymentMethod.personName;
    this.applied = paymentMethod.applied;
    this.amountToShow = paymentMethod.amountToShow;
    this.appliedToShow = paymentMethod.appliedToShow;
    this.exchangeRate = paymentMethod.exchangeRate;
    this.usdAmount = paymentMethod.usdAmount;
    this.paymentMethodCode = paymentMethod.paymentMethodCode;
    return this;
  }
}
