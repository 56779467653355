import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { rejects } from 'node:assert';
import { ModalDialogType } from '../@core/constants';
import { StockRequestModel } from '../models/requests/stock-request-model';
import { PosStockValidateModalComponent } from '../shared/pos-stock-validate-modal/pos-stock-validate-modal.component';
import { StockService } from './stock.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateStockModalService {

  private modalRef?: BsModalRef;

  constructor(private modalService: BsModalService, private stockService: StockService, private toastrService: ToastrService) { }

  show(stockRequestModel: StockRequestModel): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (stockRequestModel.productCode.length == 0) {
        this.toastrService.warning("Ningún producto aplica a domicilio en la orden actual.", "¡Advertencia!");
        resolve(true);
        return;
      }
      this.modalRef = this.modalService.show(PosStockValidateModalComponent);
      let stock = (await this.stockService.readSelectedStocks(stockRequestModel).toPromise()).response;
      stockRequestModel.productCode.forEach(s => {
        let result = stock.filter(f => f.productCode == s);
        if (result.length == 0) {
          stock.push({ productCode: s, quantityNumber: '0', branchCode: stockRequestModel.branchCode[0], branchName: '' });
        }
      });

      this.modalRef.content.stock = stock;
      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY}`);
      resolve(true);
    });
  }
}
