import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Equipment } from 'src/app/models/equipment';
import { ProductCart } from 'src/app/models/product-cart';
import { ResponseModel } from 'src/app/models/response-model';
import { Stock } from 'src/app/models/stock';
import { EquipmentService } from 'src/app/services/equipment.service';
import { StockService } from 'src/app/services/stock.service';

@Component({
  selector: 'app-pos-serial-modal',
  templateUrl: './pos-serial-modal.component.html',
  styleUrls: ['./pos-serial-modal.component.sass']
})
export class PosSerialModalComponent implements OnInit {



  public product: ProductCart = new ProductCart();
  public serialNumber?: Stock;
  public confirmAction: (stock: Stock, product: ProductCart) => any = (stock: Stock, product: ProductCart) => { };
  public stocks: Array<Stock> = []
  public equipment: Equipment = new Equipment();

  constructor(public bsModalRef: BsModalRef, private stockService: StockService, private equipmentService: EquipmentService) {
  }

  ngOnInit(): void {

  }

  async load(): Promise<boolean> {
    this.stocks = (await this.stockService.readStocks(this.product.productCode, false).toPromise()).response.content.filter(x => x.availableForSale);

    if (this.stocks.length == 0) {
      return false;
    }
    return true;
  }

  confirm() {
    this.confirmAction(this.serialNumber!, this.product);
    this.bsModalRef.hide();
  }

  cancel() {
    this.serialNumber = new Stock();
    this.bsModalRef.hide();
  }

  async loadEquipment(stock: Stock): Promise<void> {
    (await this.equipmentService.readEquipment(stock.equipmentCode).toPromise().then((x: ResponseModel<Equipment>) => {
      this.equipment = x.response;
    }).catch(x => {
      this.serialNumber = undefined;
    }));
  }

  async loadEquipmentFromSerialNumber(serial: string) {
    this.serialNumber = this.stocks.find(f => f.serialNumber == serial) || new Stock();
    (await this.equipmentService.readEquipment(this.serialNumber.equipmentCode).toPromise().then((x: ResponseModel<Equipment>) => {
      this.equipment = x.response;
    }).catch(x => {
      this.serialNumber = undefined;
    }));
  }

}
