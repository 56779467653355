import { PreApproveCreditPlanResponseModel } from './../models/response/preApproveCreditPlanResponseModel';
import { CreditReferencesResponseModel } from './../models/response/creditReferencesResponseModel';
import { CreditStatusFranchisedResponseModel } from './../models/response/customerCreditStatusResponseModel';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreditStatus } from '../models/credit-status';
import { Customer } from '../models/customer';
import { CustomerCreditValidation } from '../models/customer-credit-validation';
import { PreApproveCustomerParams } from '../models/params/preApprove-customer-params';
import { ResponseModel } from '../models/response-model';
import { PreApproveCustomerResponseModel } from '../models/response/preApproveCustomerResponseModel';
import { ConfigurationService } from './configuration.service';
import { ValidateCreditLimitForInvoicingResponseModel } from '../models/response/validateCreditLimitForInvoicingResponseModel';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  private url = `${environment.url}/cm`;
  private urlCreditReferences = `${environment.url}/creditReferences`;
  constructor(
    private http: HttpClient,
    private configService: ConfigurationService
  ) { }

  getCustomerCreditConditions(
    customerCode: string,
    type: string
  ): Observable<ResponseModel<CustomerCreditValidation>> {
    let params = new HttpParams();
    params = params.set(
      'salesOrg',
      this.configService.readSelectedBranchAndSalesOrg().salesOrg.code
    );
    params = params.set('orderCondition', type);

    return this.http.get<ResponseModel<CustomerCreditValidation>>(
      `${this.url}/getCustomerCreditConditions/${customerCode}`,
      { params }
    );
  }

  getCustomerCreditStatus(
    customer: Customer,
    branchCode: string,
    sellerCode: string
  ): Observable<CreditStatusFranchisedResponseModel> {
    const params = new HttpParams()
      .append(`fiscalId`, customer.mainPersonalInfo?.fiscalId || '')
      .append(`fiscalIdType`, customer.mainPersonalInfo?.fiscalIdType || '')
      .append(`branchCode`, branchCode)
      .append(`sellerCode`, sellerCode)
      .append(`menu`, `CUSTOMERS`);

    return this.http.post<CreditStatusFranchisedResponseModel>(
      `${this.url}/getCreditStatusFranchised`,
      undefined,
      { params }
    );
  }

  getPreApproveCreditInfo(
    preApproveParams: PreApproveCustomerParams
  ): Observable<PreApproveCreditPlanResponseModel> {
    const params = new HttpParams()
      .append(`customerCode`, preApproveParams.customerCode)
      .append(`branchCode`, preApproveParams.branchCode)
      .append(`fiscalId`, preApproveParams.fiscalId)
      .append(`fiscalIdType`, preApproveParams.fiscalIdType)
      .append(`limitRule`, preApproveParams.limitRule)
      .append(`menu`, preApproveParams.menu)
      .append(`salesOrg`, preApproveParams.salesOrg)
      .append(`sellerCode`, preApproveParams.sellerCode)
      .append(`amountToInvoice`, `${preApproveParams.amountToInvoice}`)
      .append(`downPayment`, `${preApproveParams.downPayment}`)
      .append(`onbaseProcedureCode`, preApproveParams.onbaseProcedureCode)
      .append(`productCode`,``);

    return this.http.get<PreApproveCreditPlanResponseModel>(
      `${this.url}/preApproveCreditFranchised`,
      {
        params,
      }
    );
  }

  getCustomerCreditStatusWithOutSeller(
    fiscalId: string,
    fiscalIdType: string,
    branchCode: string
  ): Observable<CreditStatus> {
    let params = new HttpParams();
    params = params.set('fiscalId', fiscalId);
    params = params.set('fiscalIdType', fiscalIdType);
    params = params.set('branchCode', branchCode);
    return this.http.post<CreditStatus>(`${this.url}/getCreditStatus`, null, {
      params,
    });
  }

  getCreditReferences(
    salesOrg?: string,
    rateType?: string,
    loanClass?: string
  ): Observable<ResponseModel<Array<CreditReferencesResponseModel>>> {
    let params = new HttpParams();

    if (salesOrg) {
      params = params.append(`salesOrg`, salesOrg);
    }

    if (rateType) {
      params = params.append(`rateType`, rateType);
    }

    if (loanClass) {
      params = params.append(`loanClass`, loanClass);
    }
    return this.http.get<ResponseModel<Array<CreditReferencesResponseModel>>>(
      this.urlCreditReferences,
      {
        params,
      }
    );
  }

  getValidateCreditLimitForInvoicing(
    customerCode: string,
    salesOrg: string,
    fiscalId: string,
    fiscalIdType: string,
    amountTotal: number,
    downPayment: number
  ): Observable<ValidateCreditLimitForInvoicingResponseModel> {
    const params = new HttpParams()
      .append(`customerCode`, customerCode)
      .append(`fiscalId`, fiscalId)
      .append(`fiscalIdType`, fiscalIdType)
      .append(`salesOrg`, salesOrg)
      .append(`amountTotal`, amountTotal.toString())
      .append(`downPayment`, downPayment.toString());

    return this.http.post<ValidateCreditLimitForInvoicingResponseModel>(
      `${this.url}/validateCreditLimitForInvoicing`,
      undefined,
      {
        params,
      }
    );
  }


  validatePastContracts(customerCode: string): Observable<ResponseModel<boolean>> {
    const params = new HttpParams().set("customerCode", customerCode);
    return this.http.post<ResponseModel<boolean>>(`${this.url}/validateArrears`, {}, {
      params
    });
  }
}
