import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerStepComponent } from './customer-step.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { CustomerService } from 'src/app/services/customer.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PosCustomerTableModule } from 'src/app/shared/pos-customer-table/pos-customer-table.module';
import { PosEmployeeTableModule } from 'src/app/shared/pos-employee-table/pos-employee-table.module';
import { PosUpdateRtnFiscalIdModalModule } from 'src/app/shared/pos-update-rtn-fiscal-id-modal/pos-update-rtn-fiscal-id-modal.module';
import { PosCreateCustomerModalModule } from 'src/app/shared/pos-create-customer-modal/pos-create-customer-modal.module';
import { AuthService } from 'src/app/services/auth.service';



@NgModule({
  declarations: [CustomerStepComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TabsModule,
    PosCustomerTableModule,
    PosEmployeeTableModule,
    PosUpdateRtnFiscalIdModalModule,
    PosCreateCustomerModalModule
  ],
  providers: [CustomerService, AuthService],
  exports: [CustomerStepComponent]
})
export class CustomerStepModule { }
