import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { ExonerationReasonService } from 'src/app/services/exoneration-reason.service';
import { PosPromotionsModalModule } from 'src/app/shared/pos-promotions-modal/pos-promotions-modal.module';
import { PromotionsModule } from '../promotions/promotions.module';
import { CreateSalesModule } from './create-sales/create-sales.module';
import { CreditCalculatorStepComponent } from './credit-calculator-step/credit-calculator-step.component';
import { ModifySalesModule } from './modify-sales/modify-sales.module';
import { SalesRoutingModule } from './sales-routing.module';
import { SalesComponent } from './sales.component';
import { SelectEndorsementComponent } from './select-endorsement/select-endorsement.component';

@NgModule({
  declarations: [SalesComponent, SelectEndorsementComponent,],
  imports: [
    CommonModule,
    FormsModule,
    SalesRoutingModule,
    ModifySalesModule,
    CreateSalesModule,
    PromotionsModule,
    PosPromotionsModalModule
  ],
  providers: [PosConfirmationService, ExonerationReasonService]
})
export class SalesModule { }
