import { PosCreditCalendarComponent } from './../../../shared/pos-credit-calendar/pos-credit-calendar.component';
import { Promotion } from './../../../models/promotion';
import { DatePipe, DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import Big from 'big.js';
import { Customer } from 'src/app/models/customer';
import { CustomerCreditValidation } from 'src/app/models/customer-credit-validation';
import { Loan } from 'src/app/models/loan';
import { Order } from 'src/app/models/order';
import { LoanFormRequest } from 'src/app/models/requests/loan-form-request';
import { LoanSimulationRequest } from 'src/app/models/requests/loan-simulation-request';
import { ResponseModel } from 'src/app/models/response-model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LoanService } from 'src/app/services/loan.service';
import { CreditCalculatorStepComponent } from '../credit-calculator-step/credit-calculator-step.component';
import { LoanAvailableDay } from 'src/app/models/loan-available-day';
import { LoanAvailableDaysService } from 'src/app/services/loan-available-days.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'credit-condition-step',
  templateUrl: './credit-condition-step.component.html',
  styleUrls: ['./credit-condition-step.component.sass'],
})
export class CreditConditionStepComponent implements OnInit, OnChanges {
  @ViewChild('creditCalendar')
  creditCalendar!: PosCreditCalendarComponent;

  @Input()
  creditForm: FormGroup = new FormGroup({
    downPaymenyCurrency: new FormControl(),
    downPaymentPercent: new FormControl(),
    initialDate: new FormControl(),
    firstFeeDate: new FormControl(),
    fee: new FormControl(),
    term: new FormControl(),
  });

  @Input()
  order: Order = new Order();

  @Input()
  hideTermsAndDates: boolean = false;

  @Input()
  customerCreditConditions: CustomerCreditValidation = new CustomerCreditValidation();

  @Input()
  loan: Loan = new Loan();

  @Input()
  creditPromotion: Promotion = new Promotion();

  @Input()
  isEditingMode: boolean = false;

  @Output()
  quotaCalculated = new EventEmitter<Loan>();

  @Output()
  termChange = new EventEmitter<String>();

  @Output()
  downpaymentChange = new EventEmitter<{
    downpaymentValue: number;
    downpaymentPercentage: number;
  }>();

  @Output()
  firstPaymentDateChange = new EventEmitter<Date>();

  termArray: Array<number> = [];

  @Input()
  endorsementIntermediary: Customer | null = new Customer();

  @Input()
  financedAmount: number = 0;

  initialDate: Date = new Date();

  @Input()
  fromCustomer: boolean = false;

  @Input()
  calculatedFeesFormArray = new FormArray([]);

  @Input()
  isPromotionOrDiscountApplied: boolean = false;

  constructor(
    private loanService: LoanService,
    private configService: ConfigurationService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private fb: FormBuilder,
    private loanAvailableDaysService: LoanAvailableDaysService
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const { loan, isEditingMode } = changes;

    this.generateTermArray();

    if (
      this.order &&
      this.order.totals &&
      loan !== undefined &&
      loan.isFirstChange()
    ) {
      this.customerCreditConditions.maxDownPayment =
        this.loan.parameters.maxDownPayment ||
        this.customerCreditConditions.maxDownPayment;
      this.customerCreditConditions.minDownPayment =
        this.loan.parameters.minDownPayment ||
        this.customerCreditConditions.minDownPayment;
      this.customerCreditConditions.maxTerm =
        this.loan.parameters.maxTerm || this.customerCreditConditions.maxTerm;
      this.customerCreditConditions.minTerm =
        this.loan.parameters.minTerm || this.customerCreditConditions.minTerm;
      this.customerCreditConditions.minDownPaymentCurrency = Number(
        new Big(this.loan.products.reduce((acc, act) => acc + act.netTotal, 0))
          .mul(new Big(this.customerCreditConditions.minDownPayment).div(100))
          .round(2)
      );

      this.customerCreditConditions.maxDownPaymentCurrency = Number(
        new Big(this.loan.products.reduce((acc, act) => acc + act.netTotal, 0))
          .mul(new Big(this.customerCreditConditions.maxDownPayment).div(100))
          .round(2)
      );
      this.customerCreditConditions.rate = parseFloat(
        this.loan.parameters.rate
      );

      this.generateTermArray();

      this.creditForm = this.fb.group({
        downPaymenyCurrency: [
          this.isEditingMode === true && !this.isPromotionOrDiscountApplied ? this.loan.parameters.downPayment : null,
          Validators.compose([
            Validators.required,
            Validators.min(
              this.customerCreditConditions.minDownPaymentCurrency
            ),
            Validators.max(
              this.customerCreditConditions.maxDownPaymentCurrency
            ),
          ]),
        ],
        downPaymentPercent: [
          this.isEditingMode === true && !this.isPromotionOrDiscountApplied
            ? this.loan.parameters.downPaymentPercentage
            : null,
          Validators.compose([
            Validators.required,
            Validators.min(this.customerCreditConditions.minDownPayment),
            Validators.max(this.customerCreditConditions.maxDownPayment),
          ]),
        ],
        initialDate: [
          this.isEditingMode === true
            ? this.loan.parameters.calculatedDate
            : null,
          Validators.required,
        ],
        firstFeeDate: [
          this.isEditingMode === true ? this.loan.parameters.startDate : null,
          Validators.required,
        ],
        fee: [null],
        term: [
          this.isEditingMode === true ? this.loan.term : null,
          Validators.required,
        ],
      });

      const calculatedDate = await this.calculateInitialDate();

      this.creditForm
        .get('initialDate')
        ?.setValue(this.datePipe.transform(calculatedDate, 'EEEE, MMMM d, y'));

      this.initialDate = calculatedDate;

      this.creditForm.get('firstFeeDate')?.setValue(calculatedDate);

      this.calculatedFeesFormArray.reset();
    }
    // }
  }

  ngOnInit(): void {
    this.creditForm.get('initialDate')?.disable();
    this.creditForm.get('fee')?.disable();
    this.creditForm.get('term')?.setValue(this.loan.term);

    if (this.fromCustomer) {
      this.creditPromotion.code = '1';
    }
  }

  generateTermArray() {
    this.termArray = [];

    let minTerm = this.customerCreditConditions.minTerm;
    let maxTerm = this.customerCreditConditions.maxTerm;

    // if (this.creditPromotion.code !== '1') {
    //   minTerm = this.creditPromotion.buy.minTerm;
    //   maxTerm = this.creditPromotion.buy.maxTerm;
    // }

    for (let i = minTerm; i <= maxTerm; i++) {
      this.termArray.push(i);
    }
  }

  getFirstFeeDateFormControl(): FormControl {
    return this.creditForm.get('firstFeeDate') as FormControl;
  }

  getTotalToFinance() {
    let value = this.creditForm.get('downPaymenyCurrency')?.value;
    let downPaymenyCurrency = Number.parseFloat(value || '0');
    return Big(this.order.totals.netTotal)
      .minus(Big(downPaymenyCurrency))
      .toFixed(2);
  }

  async calculateFee(): Promise<void> {
    if (
      this.datePipe.transform(new Date(), 'dd/MM/yyyy') ===
      this.datePipe.transform(
        this.creditForm.get('firstFeeDate')?.value,
        'dd/MM/yyyy'
      )
    ) {
      Swal.fire({
        title: 'Atencion!',
        text: 'Fecha de primera cuota no pueden ser iguales a fecha del dia.',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });

      return;
    }

    let loanFormValues = new LoanFormRequest();

    loanFormValues.term = this.creditForm.get('term')?.value;

    loanFormValues.downPaymentPercent =
      this.creditForm.get('downPaymentPercent')?.value;

    loanFormValues.downPaymenyCurrency = this.creditForm.get(
      'downPaymenyCurrency'
    )?.value;

    let initDate = this.initialDate;

    loanFormValues.initialDate =
      this.datePipe.transform(initDate, 'YYYYMMdd') || '';

    loanFormValues.firstFeeDate =
      this.datePipe.transform(
        this.creditForm.get('firstFeeDate')?.value,
        'YYYYMMdd'
      ) || '';

    if (this.fromCustomer) {
      const calculatedDate = await this.calculateInitialDate();
      loanFormValues.firstFeeDate =
        this.datePipe.transform(calculatedDate, 'YYYYMMdd') || '';
      loanFormValues.initialDate =
        this.datePipe.transform(calculatedDate, 'YYYYMMdd') || '';
      loanFormValues.downPaymentPercent =
        (this.order.totals.discountTotal * 100) / this.order.totals.netTotal;
      loanFormValues.downPaymenyCurrency = this.order.totals.discountTotal;
    }
    let loanRate = 0;
    if(this.fromCustomer){
      loanRate = this.customerCreditConditions.rate;
    }else{
      loanRate = parseInt(this.loan.rate);
    }
    if (loanRate != this.customerCreditConditions.rate) {
      this.customerCreditConditions.rate = loanRate;
    }
    let result = (
      (await this.loanService
        .createCalculateConditionsSimulate(
          LoanSimulationRequest.buildRequest(
            this.order,
            this.customerCreditConditions,
            loanFormValues,
            this.configService.readSelectedBranchAndSalesOrg(),
            !this.isEndorsementNotSelected
              ? [this.endorsementIntermediary?.code || '']
              : [],
            true,
            this.fromCustomer
              ? this.getAmountFinaceFromCustomer()
              : this.loan.products.reduce((acc, act) => acc + act.netTotal, 0)
          )
        )
        .toPromise()
        .catch((e) => {
          this.creditForm.get('fee')?.setValue('');
          this.creditForm.get('fee')?.markAsUntouched();
          this.creditForm.get('fee')?.markAsPristine();
        })) as ResponseModel<Loan>
    ).response;

    let interest =
      result.conditions.find((f) => f.conditionType == '0203')?.amount || 0;

    let fee =
      result.conditions.find((f) => f.conditionType == '0261')?.amount || 0;

    let feeCalculated = Big(interest).plus(fee).round(2).toString();

    this.creditForm
      .get('fee')
      ?.setValue(this.decimalPipe.transform(feeCalculated));

    this.creditForm.get('fee')?.markAsDirty();

    if (this.loan) {
      if (this.loan.code !== '') {
        result.parameters.loanCode = this.loan.code;
      }
    }

    if (this.creditForm.get('firstFeeDate')?.value <= this.initialDate) {
      this.creditCalendar.reConfigureSelectDate();
    }

    result.parameters.startDateRaw = this.creditForm.get('firstFeeDate')?.value;

    this.quotaCalculated.emit(result);
  }

  getAmountFinaceFromCustomer() {
    return this.order.totals.netTotal;
  }

  changeCurrencyDownPayment() {
    let downPaymentCurrency =
      this.creditForm.get('downPaymenyCurrency')?.value || 0;
    let result = Big(downPaymentCurrency)
      .div(this.loan.products.reduce((acc, act) => acc + act.netTotal, 0))
      .mul(100)
      // .round(2)
      .toString();
    this.creditForm.get('downPaymentPercent')?.setValue(result);
    this.creditForm.get('fee')?.markAsUntouched();
    this.creditForm.get('fee')?.markAsPristine();
    this.downpaymentChange.emit({
      downpaymentPercentage: this.creditForm.get('downPaymentPercent')?.value,
      downpaymentValue: this.creditForm.get('downPaymenyCurrency')?.value,
    });
  }

  changePercentDownPayment() {
    let downPaymentPercent =
      this.creditForm.get('downPaymentPercent')?.value || 0;
    let result = Big(downPaymentPercent)
      .div(100)
      .mul(this.loan.products.reduce((acc, act) => acc + act.netTotal, 0))
      .round(2)
      .toString();
    this.creditForm.get('downPaymenyCurrency')?.setValue(result);
    this.creditForm.get('fee')?.markAsUntouched();
    this.creditForm.get('fee')?.markAsPristine();
    this.downpaymentChange.emit({
      downpaymentPercentage: this.creditForm.get('downPaymentPercent')?.value,
      downpaymentValue: this.creditForm.get('downPaymenyCurrency')?.value,
    });
  }

  get isEndorsementNotSelected(): Boolean {
    return !this.endorsementIntermediary;
  }

  handleTermChange(): void {
    this.loan.parameters.term = this.creditForm.get('term')?.value;
    this.loan.term = this.creditForm.get('term')?.value;
    this.loan.products.forEach(
      (p) => (p.term = this.creditForm.get('term')?.value)
    );

    this.termChange.emit(this.creditForm.get('term')?.value);
  }

  handleFirstDateQuotaChange(firstDatePayment: Date): void {
    this.firstPaymentDateChange.emit(firstDatePayment);
  }

  getTextDownPayment(): string {
    let minDownPaymentValue = new Big(0);
    let maxDownPaymentValue = new Big(0);

    if (this.creditPromotion.code !== '1') {
      minDownPaymentValue = new Big(this.creditPromotion?.buy?.downPayment || 0)
        .div(100)
        .mul(this.loan.products.reduce((acc, act) => acc + act.netTotal, 0))
        .round(2);
      maxDownPaymentValue = new Big(
        this.customerCreditConditions?.maxDownPayment || 0
      )
        .div(100)
        .mul(this.loan.products.reduce((acc, act) => acc + act.netTotal, 0))
        .round(2);
    } else {
      minDownPaymentValue = new Big(
        this.customerCreditConditions?.minDownPayment || 0
      )
        .div(100)
        .mul(this.loan.products.reduce((acc, act) => acc + act.netTotal, 0))
        .round(2);
      maxDownPaymentValue = new Big(
        this.customerCreditConditions?.maxDownPayment || 0
      )
        .div(100)
        .mul(this.loan.products.reduce((acc, act) => acc + act.netTotal, 0))
        .round(2);
    }

    return `La prima debe ser entre ${minDownPaymentValue} a ${maxDownPaymentValue}`;
  }

  configureSelectedDate(days: LoanAvailableDay): Date {
    let currentDay = new Date(Date.now()).getDate();
    let month = new Date(Date.now()).getMonth() + 1;
    let year = new Date(Date.now()).getFullYear();
    let calculatedDate = new Date(year, month, currentDay);
    // if (!days.availableDays[currentDay]) {
    let tempDate = new Date();
    let i = true;
    while (i) {
      let tempDay = tempDate.getDate();
      let tempMonth = tempDate.getMonth();
      let tempYear = tempDate.getFullYear();

      if (days.availableDays[tempDay] && tempDate > calculatedDate) {
        i = false;
        return new Date(tempYear, tempMonth, tempDay);
      }
      if (!Object.values(days.availableDays).includes(true)) {
        i = false;
        return new Date();
      }
      tempDate.setDate(tempDay + 1);
    }
    //}
    return new Date();
  }

  public async calculateInitialDate(): Promise<Date> {
    let currentDate = new Date();

    const loanAvailableDays = (
      await this.loanAvailableDaysService.readLoanAvailableDays().toPromise()
    ).response;

    const days =
      loanAvailableDays.find(
        (f) =>
          f.loanClass == this.customerCreditConditions.loanClass &&
          f.productClass == this.customerCreditConditions.productClass &&
          f.salesOrg ==
          this.configService.readSelectedBranchAndSalesOrg().salesOrg.code
      ) || new LoanAvailableDay();

    let result = this.configureSelectedDate(days);

    currentDate = currentDate >= result ? currentDate : result;

    return currentDate;
  }
}
