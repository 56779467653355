export class DateUtils {
  static daysInMonth(month: number, year: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  static parseMinutesToDays(duration: number): string {
    let NAS = '';

    let millares = 0;
    let decimales = 0;
    let horas = 0;
    let dias = 0;
    let minutos = 0;

    if (duration < 60) {
      NAS = duration + ' Minutos';
    } else if (duration === 60) {
      NAS = '1 Hora ';
    } else if (duration > 60 && duration < 1140) {
      millares = duration / 60;
      decimales = millares - Math.floor(millares);
      horas = Math.floor(millares);

      NAS = horas + ' Hora y ' + Math.round(decimales * 60) + ' Min';
    } else if (duration === 1140) {
      NAS = '1 Dia ';
    } else if (duration > 1140) {
      millares = duration / 1440;
      decimales = millares - Math.floor(millares);
      dias = Math.floor(millares);
      minutos = decimales * 1440;

      if (minutos > 60) {
        millares = minutos / 60;
        decimales = millares - Math.floor(millares);
        horas = Math.floor(millares);
      }

      NAS =
        dias +
        ' Dias ' +
        horas +
        ' horas y ' +
        Math.round(decimales * 60) +
        ' Min';
    }

    return NAS;
  }

  static getDateFromString(date: string): Date {
    if (!date) {
      return new Date();
    }
    var pattern = /(\d{4})(\d{2})(\d{2})/;
    return new Date(`${date.replace(pattern, '$1-$2-$3')}`);
  }

  static getDateFromStringWithoutTime(date: string): Date {
    if (!date) {
      return new Date();
    }
    var pattern = /(\d{4})(\d{2})(\d{2})/;
    return new Date(`${date.replace(pattern, '$1-$2-$3')}T06:00:00`);
  }

  static getDateAndTimeFromString(date: string, time: string): Date {

    if (!date || !time) {
      return new Date();
    }
    var pattern = /(\d{4})(\d{2})(\d{2})\-(\d{2})(\d{2})(\d{2})/;
    return new Date(`${date}-${time}`.replace(pattern, '$1-$2-$3 $4:$5:$6'));
  }

  static getDateFromStringForReports(date: string): Date {
    if (!date) {
      return new Date();
    }
    var patternWithHours = /(\d{4})-(\d{2})-(\d{2})\ (\d{2}):(\d{2}):(\d{2})/;
    var patternWithOuthours = /(\d{4})(\d{2})(\d{2})/;
    let dateReport: Date = new Date();

    if (new RegExp(patternWithHours).test(date)) {
      dateReport = new Date(`${date.replace(patternWithHours, '$1-$2-$3')}T06:00:00`);
    }
    else if (new RegExp(patternWithOuthours).test(date)) {
      dateReport = new Date(`${date.replace(patternWithOuthours, '$1-$2-$3')}T06:00:00`);
    }

    if (dateReport != undefined) {
      dateReport.setHours(23);
      dateReport.setMinutes(59);
      dateReport.setSeconds(59);
    }
    return dateReport;
  }

  static isValidDate(date: Date) {
    return !isNaN(date.getTime());
  }
}
