import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsStepComponent } from './documents-step.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PosIp800ModalModule } from 'src/app/shared/pos-ip800-modal/pos-ip800-modal.module';



@NgModule({
  declarations: [DocumentsStepComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PosIp800ModalModule
  ],
  exports: [DocumentsStepComponent]
})
export class DocumentsStepModule { }
