import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Customer } from 'src/app/models/customer';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { PosUpdateRtnFiscalIdModalComponent } from 'src/app/shared/pos-update-rtn-fiscal-id-modal/pos-update-rtn-fiscal-id-modal.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosUpdateRtnFiscalIdModalService {

  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  show(customer: CustomerTableModel, confirmAction: (customer: CustomerTableModel) => any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(PosUpdateRtnFiscalIdModalComponent)

      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY}`)
      this.modalRef.content.customer = customer;
      this.modalRef.content.fiscalId = customer.fiscalId2;
      this.modalRef.content.confirmAction = confirmAction;
      resolve(true);
    })
  }

}
