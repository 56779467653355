import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { PosCreditInstallmentsComponent } from './pos-credit-installments.component';
import { PosStepperModule } from '../pos-stepper/pos-stepper.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PosCreditInstallmentsApplicationComponent } from './pos-credit-installments-application/pos-credit-installments-application.component';
import { CreditConditionStepModule } from 'src/app/views/sales/credit-condition-step/credit-condition-step.module';

@NgModule( {
  declarations: [ PosCreditInstallmentsComponent, PosCreditInstallmentsApplicationComponent ],
  exports: [PosCreditInstallmentsComponent],
  imports: [
    CommonModule,
    PosStepperModule,
    CdkStepperModule,
    ReactiveFormsModule,
    CreditConditionStepModule
  ]
} )
export class PosCreditInstallmentsModule { }