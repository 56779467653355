import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pos-customer',
  templateUrl: './pos-customer.component.html',
  styleUrls: ['./pos-customer.component.sass']
})
export class PosCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
