import { Component, OnInit } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table';
import { FilterTable } from 'src/app/models/filter-table';
import { ActivatedRoute } from '@angular/router';
import { LoanService } from 'src/app/services/loan.service';
import { Loan } from 'src/app/models/loan';
import { Paging } from 'src/app/models/page';
import { DatePipe, DecimalPipe } from '@angular/common';
import { LoansFilter } from 'src/app/models/filters/loans-filter';
import { Filter } from 'src/app/models/filter';

@Component({
  selector: 'app-customer-loans-active',
  templateUrl: './customer-loans-active.component.html',
  styleUrls: ['./customer-loans-active.component.sass']
})
export class CustomerLoansActiveComponent implements OnInit {

  public loans: Paging<Array<Loan>> = new Paging([]);
  private customerCode = '';
  private readonly CURRENCY_CODE = 'HNL';
  private readonly YES = 'SI';
  private readonly NO = 'NO';

  constructor(
    private route: ActivatedRoute,
    private loanService: LoanService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit(): void {
    const code = this.route.snapshot.params.id;
    this.customerCode = code;
    this.getCustomerLoans({ page: 0, pageSize: 10, value: { customerCode: code } });
  }

  async getCustomerLoans(filter: Filter<LoansFilter>): Promise<void> {
    filter.value.hasContractNumber = true;
    this.loans = (await this.loanService.getCustomerLoans(filter).toPromise()).response;
    this.loans.content = this.loans.content.filter(loan => loan.contractNumber);
    this.loans.content.forEach((loan) => {
      const date = this.getDateFromString(loan.creationDate);
      loan.creationDate = this.datePipe.transform(date, 'yyyy-MM-dd') ?? '';
      loan.delayedAmount = loan.delayedAmount || 0;
    });
  }

  getLoansHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('salesOrg', 'Código Empresa'),
      new ColumnTable('creationDate', 'Fecha de creación', { type: "date" }),
      new ColumnTable('contractNumber', 'Numero Contrato'),
      new ColumnTable('totalFinanced', 'Total de Contrato', { type: "currency" }),
      new ColumnTable('delayedAmount', 'Valor Vencido', { type: "currency" }),
    ];
  }

  getLoansFilters(): Array<FilterTable> {
    return [
      new FilterTable('contractNumber', 'Contrato'),
      new FilterTable('startDate', 'Fecha desde', { type: 'date' }),
      new FilterTable('endDate', 'Fecha hasta', { type: 'date' })
    ];
  }

  getLoansData(params: Filter<LoansFilter>): void {
    const filter = params;
    filter.value.customerCode = this.customerCode;

    if (filter.value.startDate) {
      filter.value.startDate = `${filter.value.startDate} 00:00:00 UTC`;
    }

    if (filter.value.endDate) {
      filter.value.endDate = `${filter.value.endDate} 00:00:00 UTC`;
    }
    console.log(filter);
    this.getCustomerLoans(filter);
  }

  viewDetails(loan: Loan): void {
    console.log(loan);
  }

  private getDateFromString(stringDate: string): Date {

    const year = +stringDate.substring(0, 4);
    const month = +stringDate.substring(4, 6);
    const day = +stringDate.substring(6);
    const date = new Date(year, month - 1, day);

    return date;
  }
}
