import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
} from '@coreui/angular';
import { IconModule, IconSetModule } from '@coreui/icons-angular';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';
// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { HasPrivilegeModule } from './@core/directives/has-privilege/has-privilege.module';
import { AuthGuard } from './@core/guards/auth.guard';
import { AuthInterceptor } from './@core/interceptor/auth.interceptor';
import { ErrorInterceptor } from './@core/interceptor/error.interceptor';
import { HttpHeadersInterceptor } from './@core/interceptor/httpHeaders.interceptor';
import { Spinnernterceptor as SpinnerInterceptor } from './@core/interceptor/spinner.interceptor';
import { PosSupervisorLoginService } from './@core/services/pos-supervisor-login.service';
// Import routing module
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { ExchangeRateService } from './services/exchange-rate.service';
import { LocalStorageService } from './services/local-storage.service';
import { AuthService } from './services/auth.service';
import { PosConfirmationModule } from './shared/pos-confirmation/pos-confirmation.module';
import { BackOfficeOrdersModule } from './views/back-office-orders/back-office-orders.module';
import { ConfigurationsModule } from './views/configurations/configurations.module';
import { LoginComponent } from './views/login/login.component';
import { LoginModule } from './views/login/login.module';
import { LogoutComponent } from './views/logout/logout.component';
import { LogoutModule } from './views/logout/logout.module';
import { ProductsModule } from './views/products/products.module';
import { PosCustomerTableModule } from './shared/pos-customer-table/pos-customer-table.module';
import { PosEmployeeTableModule } from './shared/pos-employee-table/pos-employee-table.module';
import { PosCustomerCreateModule } from './shared/pos-customer-create/pos-customer-create.module';
import { PosCustomerUpdateModule } from './shared/pos-customer-update/pos-customer-update.module';
import { PosPrintDocumentsModalComponent } from './shared/pos-print-documents-modal/pos-print-documents-modal.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PosOrderRejectModalModule } from './shared/pos-order-reject-modal/pos-order-reject-modal.module';
import { PosStockValidateModalModule } from './shared/pos-stock-validate-modal/pos-stock-validate-modal.module';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { HasAccessModule } from './@core/directives/has-access/has-access.module';
import { ChangePasswordModule } from './views/change-password/change-password.module';
import { PosOrderObservationsComponent } from './shared/pos-order-observations/pos-order-observations.component';
import { BiometryModule } from './views/biometry/biometry.module';
import { BiometryFingerPrintModule } from './views/biometryFingerPrint/biometryFingerPrint.module';
import { ChangeForgetPasswordModule } from "./views/change-forget-password/change-forget-password.module";
import { PosCaptchaModule } from './shared/pos-captcha/pos-captcha.module';
import { PosVerifyCustomerModule } from './shared/pos-verify-customer/pos-verify-customer.module';
import { PosCustomerUpdateModalModule } from './shared/pos-customer-update-modal/pos-customer-update-modal.module';
import { SalesModule } from './views/sales/sales.module';
import { PosReasonSelectModalModule } from './shared/pos-reason-select-modal/pos-reason-select-modal.module';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const APP_CONTAINERS = [LayoutComponent];

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    LogoutComponent,
    PosPrintDocumentsModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    HttpClientModule,
    LoginModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ConfigurationsModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot({ autoDismiss: false, timeOut: 5000 }),
    LogoutModule,
    PopoverModule.forRoot(),
    PosConfirmationModule,
    PosOrderRejectModalModule,
    PosStockValidateModalModule,
    HasPrivilegeModule,
    ProductsModule,
    BackOfficeOrdersModule,
    PosCustomerTableModule,
    PosEmployeeTableModule,
    PosCustomerCreateModule,
    PosCustomerUpdateModule,
    NgxMaskModule.forRoot(maskConfig),
    HasPrivilegeModule,
    UiSwitchModule,
    ConfigurationsModule,
    ReactiveFormsModule,
    HasAccessModule,
    ChangePasswordModule,
    BiometryModule,
    BiometryFingerPrintModule,
    ChangeForgetPasswordModule,
    PosCaptchaModule,
    PosVerifyCustomerModule,
    PosCustomerUpdateModalModule,
    SalesModule,
    PosReasonSelectModalModule
  ],
  providers: [
    LocalStorageService,
    // NgxSpinnerService,
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    AuthGuard,
    AuthService,
    DatePipe,
    ExchangeRateService,
    PosSupervisorLoginService,
    DecimalPipe,
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
