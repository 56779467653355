import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Colony } from '../models/colony';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class ColonyService {
  private url = `${environment.url}/colonies`;
  
  constructor(private http: HttpClient) {
    
  }

  readColonies(cityCode:string):Observable<ResponseModel<Paging<Array<Colony>>>>
  {
    let params = new HttpParams();
    params = params
      .set("city", cityCode)
      .set("pageSize", "50000");
    return this.http.get<ResponseModel<Paging<Array<Colony>>>>(this.url, { params: params });
  }
}