import { CreditStatusFranchisedResponseModel } from './../../../../models/response/customerCreditStatusResponseModel';
import { AuthService } from './../../../../services/auth.service';
import { ConfigurationService } from './../../../../services/configuration.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { CustomerCreditValidation } from 'src/app/models/customer-credit-validation';
import { PosCustomerTableModalService } from './../../../../@core/services/pos-customer-table-modal.service';
import { Customer } from './../../../../models/customer';
import { CreditService } from './../../../../services/credit.service';
import { CustomerService } from './../../../../services/customer.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DATE_PATTERN } from 'src/app/@core/constants';
import * as moment from 'moment';
@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.sass'],
})
export class CustomerInformationComponent implements OnInit, OnDestroy {
  currentCustomer: Customer;
  searchForm: FormGroup;
  currentCustomerCreditConditions: CreditStatusFranchisedResponseModel;
  @Output() currentCustomerChange: EventEmitter<{
    customer: Customer;
    customerCreditConditions: CreditStatusFranchisedResponseModel;
  }>;

  @Input() hideButtons: boolean = false;
  @Input() hideCreditInformation: boolean = false;
  constructor(
    private customerService: CustomerService,
    private customerTableModalService: PosCustomerTableModalService,
    private formBuilder: FormBuilder,
    private creditService: CreditService,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    private authenticationService: AuthService,
    private localStorageService: LocalStorageService
  ) {
    this.searchForm = this.formBuilder.group({
      searchCriteria: this.formBuilder.control(''),
    });

    this.currentCustomer = new Customer();
    this.currentCustomerCreditConditions =
      new CreditStatusFranchisedResponseModel();
    this.currentCustomerChange = new EventEmitter();
  }

  ngOnInit(): void {
    this.route.params.subscribe(async (params: Params) => {
      let code = '';

      if (params.id) {
        code = params.id;
      } else {
        code = this.localStorageService.get('selectedCustomer') ?? '';
      }

      if (code) {
        this.searchForm.patchValue({ searchCriteria: code });
        this.searchForm.markAsDirty();
        await this.handleSubmit();
      }
    });
  }

  ngOnDestroy() {
    const code = this.searchForm.get('searchCriteria');
    this.localStorageService.set('selectedCustomer', code?.value);
  }

  async handleSubmit(): Promise<void> {
    this.resetCurrentCustomerInfo();

    if (this.searchForm.valid && this.searchForm.dirty) {
      await this.fetchCustomerInfo();

      await this.fetchCustomerCreditInfo(this.currentCustomer);

      this.emitSelectedCustomerChange();
    }
  }

  async handleSearchCustomerClick() {
    const modalRef = this.customerTableModalService.show();

    modalRef.content?.customerSelectedEvent.subscribe(async (customerTableModel) => {
      this.resetCurrentCustomerInfo();
      let customer: Customer = await this.customerService.readCustomerByCode(customerTableModel.code).toPromise();
      try {
        await this.fetchCustomerCreditInfo(customer);
      } catch (e) {
        this.searchForm.patchValue({ searchCriteria: '' });
        throw e;
      }
      this.currentCustomer = customer;
      this.searchForm.patchValue({ searchCriteria: this.currentCustomer.code });
      this.searchForm.markAsTouched();
      this.emitSelectedCustomerChange();
    });
  }

  private emitSelectedCustomerChange(): void {
    this.currentCustomerChange.emit({
      customer: this.currentCustomer,
      customerCreditConditions: this.currentCustomerCreditConditions,
    });
  }

  private resetCurrentCustomerInfo(): void {
    this.currentCustomerCreditConditions =
      new CreditStatusFranchisedResponseModel();
    this.currentCustomer = new Customer();
  }

  private async fetchCustomerCreditInfo(customer: Customer): Promise<void> {
    if (!this.hideCreditInformation) {
      const { branch } =
        this.configurationService.readSelectedBranchAndSalesOrg();
      const { username } = this.authenticationService.readProfileInStorage();

      this.currentCustomerCreditConditions = await this.creditService
        .getCustomerCreditStatus(customer, branch.code, username)
        .toPromise();
    }

  }

  private async fetchCustomerInfo(): Promise<void> {
    this.currentCustomer = await this.customerService
      .readCustomerByCode(this.searchForm.value.searchCriteria)
      .toPromise();
  }

  getCalculateAge(birthdate: String): number | undefined {
    let age = 0;
    if (birthdate != undefined)
      age = moment().diff(moment(birthdate.toString(), DATE_PATTERN), 'years');

    return isNaN(age) ? undefined : age;
  }
}
