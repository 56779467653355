import {
  NgModule
} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {
  PosCustomerCreateComponent
} from 'src/app/shared/pos-customer-create/pos-customer-create.component';
import {
  PosCustomerUpdateComponent
} from 'src/app/shared/pos-customer-update/pos-customer-update.component';
import { PosPaymentMethodComponent } from 'src/app/shared/pos-payment-method/pos-payment-method.component';
import {
  CreateCreditRequestComponent
} from '../credit-request/components/create-credit-request/create-credit-request.component';
import * as fromComponents from './components';
import {
  AdvanceComponent
} from './components/advance/advance.component';

const routes: Routes = [{
  path: '',
  component: fromComponents.PosCustomerComponent,
  children: [{
      path: 'createcreditrequest',
      component: CreateCreditRequestComponent,
      data: {
        title: 'Crear solicitud de crédito',
        id: 'CreateCreditRequestComponent_createcreditrequest'
      },
    },
    {
      path: 'createcreditrequest/:creditRequestNumber',
      component: CreateCreditRequestComponent,
      data: {
        title: 'Crear solicitud de crédito',
        id: 'CreateCreditRequestComponent_createcreditrequest_creditRequestNumber'
      },
    },
    {
      path: '',
      component: fromComponents.CustomerIdentificationComponent,
      data: {
        title: 'Clientes',
        id: 'CustomerIdentificationComponent_customer'
      },
    },
    {
      path: 'create',
      component: PosCustomerCreateComponent,
      data: {
        title: 'Crear cliente',
        id: 'PosCustomerCreateComponent_create'
      },
    },
    {
      path: ':id',
      component: fromComponents.CustomerIdentificationComponent,
      data: {
        title: 'Clientes',
        id: 'CustomerIdentificationComponent_id'
      },
    },
    {
      path: 'update/:id',
      component: PosCustomerUpdateComponent,
      data: {
        title: 'Modificar cliente',
        id: 'PosCustomerUpdateComponent_update_id'
      },
    },
    {
      path: 'advance/:clientId',
      component: AdvanceComponent,
      data: {
        title: 'Anticipos por cliente',
        id: 'AdvanceComponent_advance_clientId'
      }
    }, {
      path: 'loansactive/:id',
      component: fromComponents.CustomerLoansActiveComponent,
      data: {
        title: 'Prestamos activos',
        id: 'CustomerLoansActiveComponent_loansactive'
      },
    },
    {
      path: 'creditnotes/:id',
      component: fromComponents.CustomerCreditNotesComponent,
      data: {
        title: 'Notas de credito',
        id: 'CustomerCreditNotesComponent_creditnotes_id'
      },
    },
    {
      path: 'creditrequestinprocess/:id',
      component: fromComponents.CustomerCreditRequestInProcessComponent,
      data: {
        title: 'Solicitudes de credito en proceso',
        id: 'CustomerCreditRequestInProcessComponent_creditrequestinprocess_id'
      },
    },
    {
      path: 'advances/:id',
      component: fromComponents.CustomerAdvancesComponent,
      data: {
        title: 'Anticipos de clientes',
        id: 'CustomerAdvancesComponent_advances_id'
      }
    },
    {
      path: 'payment/:id',
      component: PosPaymentMethodComponent,
      data: {
        title: 'Metodos de pago',
        id: 'PosPaymentMethodComponent_payment_id'
      }
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomersRoutingModule { }
