import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CountryHN } from 'src/app/@core/constants';
import { Bank } from 'src/app/models/bank';
import { AdvanceFilter } from 'src/app/models/filters/advance-filter';
import { PaymentMethod } from 'src/app/models/payment-method';
import { AdvanceService } from 'src/app/services/advance.service';
import { BankService } from 'src/app/services/bank.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ExchangeRateService } from 'src/app/services/exchange-rate.service';
import { GiftCardService } from 'src/app/services/gift-card.service';
import { LoyaltyPointsService } from 'src/app/services/loyalty-points.service';
import { OpenItemsService } from 'src/app/services/open-items.service';
import { SalesService } from 'src/app/services/sales.service';
import { VoucherService } from 'src/app/services/voucher.service';
import { Filter } from '../../../models/filter';

@Component({
  selector: 'app-add-payment-modal',
  templateUrl: './add-payment-modal.component.html',
  styleUrls: ['./add-payment-modal.component.sass']
})
export class AddPaymentModalComponent implements OnInit {

  readonly CASH_CODE = "ZE01";
  readonly CASH_CODE_USD = "ZE02";
  private readonly USD = "USD";
  private readonly LOYALTY = "PL";
  private readonly GIFT_CARD = "GC";
  private readonly ADVANCE = "AT";
  private readonly CHEQUE = "CH";
  private readonly NOTA_CAMBIO = "NCA";
  private readonly VALE = "VDC";
  private readonly PAC = "PAC";
  private readonly NCR = "NCR";
  private readonly MAX_POINTS_PERCENTAGE = 0.2;
  paymentMethod: PaymentMethod = new PaymentMethod();
  paymentList: Array<PaymentMethod> = [];
  totalAmount = 0;
  creditLimitAmount = 0;
  customerCode = "";
  totalIsvPaymentCard = 0;
  totalPaymentCard = 0;
  totalWithIsvPaymentCard = 0;
  paymentsAmount = 0;
  exchangeRate = 0;
  totalPointsOfSelectedClient = 0;
  maxQuantityPointsCanUse = 0;
  amount?: number = undefined;
  bankList: Array<Bank> = [];

  creditCardTypesArray = [
    { code: 'AMEX', nameToShow: 'American Express' },
    { code: 'MASTERCARD', nameToShow: 'MasterCard' },
    { code: 'VISA', nameToShow: 'Visa' },
    { code: 'DISCOVER', nameToShow: 'Discover' },
    { code: 'DINERS', nameToShow: 'Diners' },
    { code: 'OTRA', nameToShow: 'Otra' }
  ];
  confirmAction: () => any = () => { };

  constructor(
    private bsModalRef: BsModalRef,
    private exchangeRateService: ExchangeRateService,
    private loyaltyPointsService: LoyaltyPointsService,
    private giftCardService: GiftCardService,
    private advanceService: AdvanceService,
    private configurationService: ConfigurationService,
    private openItemsService: OpenItemsService,
    private voucherService: VoucherService,
    private salesService: SalesService,
    private toastrService: ToastrService,
    private bankService: BankService) { }

  async ngOnInit(): Promise<void> {
    this.paymentMethod.paymentMethodCode = this.paymentMethod.methods[0]?.code || '';
    if (this.paymentMethod.currency === this.USD) {
      this.exchangeRate = (await this.exchangeRateService.readLatestExchangeRate()).rate;
    }

    if (this.paymentMethod.code === this.LOYALTY) {
      const customer = (await this.loyaltyPointsService.getLoyaltyPointsByCustomerCode(this.customerCode).toPromise()).response.content;
      console.log(customer);
      const today = new Date();
      customer[0]?.points.forEach(point => {
        // format: YYYYMMDD
        const expiration = point.expirationDate;
        const year = +expiration.substring(0, 4);
        const month = +expiration.substring(4, 6);
        const day = +expiration.substring(6);
        const expirationDate = new Date(year, month - 1, day);
        if (today <= expirationDate) {
          this.totalPointsOfSelectedClient += point.amount;
        }
      });

      if (this.totalPointsOfSelectedClient >= (this.totalAmount * 0.2)) {
        this.maxQuantityPointsCanUse = this.round(this.MAX_POINTS_PERCENTAGE * this.totalAmount);
      } else {
        this.maxQuantityPointsCanUse = this.totalPointsOfSelectedClient;
      }
    }

    if (this.paymentMethod.code === this.CHEQUE) {
      this.bankList = (await this.bankService.getBankList().toPromise()).response.content;
      this.bankList = this.bankList.filter(bank => bank.country === CountryHN);
    }
  }

  cancel(): void {
    this.bsModalRef.hide();
  }

  onlyNumbers(event: any): boolean {

    const key = event.keyCode || event.which;
    const tecla = String.fromCharCode(key);
    const numeros = "0123456789.";

    const regexp: RegExp = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{0,2})?\s*$/);
    const val: string = event.target.value + tecla;

    if (numeros.indexOf(tecla) === -1 || regexp.test(val) === false) {
      return false;
    }
    return true;
  }

  calculateISVPayment(event: any): void {
    this.totalIsvPaymentCard = 0;
    this.totalPaymentCard = 0;
    this.totalWithIsvPaymentCard = 0;

    if (event.target.value) {
      const total: number = parseFloat(event.target.value);
      const per: number = 10;
      this.totalWithIsvPaymentCard = total;
      this.totalIsvPaymentCard = total - (total / (1 + (per / 100)));
      this.totalPaymentCard = total / (1 + (per / 100));
    }
  }

  getPaymentUSD(): number {
    const amount = this.totalAmount - this.paymentsAmount;
    const total = amount / this.exchangeRate;
    const ceil = this.ceilNumber(total, 2);
    const newNumber = this.round(ceil);
    return newNumber;
  }

  switchCardType(event: any, value: string): void {
    event.preventDefault();
    this.paymentMethod.cardType = value;
  }

  ceilNumber(rnum: number, rlength: number): number {
    const newnumber = Math.ceil(rnum * Math.pow(10, rlength)) / Math.pow(10, rlength);
    return newnumber;
  }

  async addPayment(): Promise<void> {

    const idx = this.paymentList.filter(f => f.code != "AT").findIndex(payment => payment.code === this.paymentMethod.code);
    if (idx !== -1) {
      this.toastrService.error('Ya existe un método de pago con este identificador.');
      return;
    }

    if (this.paymentMethod.code === this.GIFT_CARD) {
      const giftCardList = (await this.giftCardService.getGiftCard(this.paymentMethod.identifier).toPromise()).response.content;

      if (giftCardList[0]) {
        if (this.amount! > giftCardList[0].amount) {
          this.toastrService.error(`El monto que ingreso ${this.amount} es mayor a la giftcard ${giftCardList[0].amount}.`);
          return;
        }
      } else {
        this.toastrService.error('La Giftcard no existe.');
        return;
      }
    }

    if (this.paymentMethod.code === this.ADVANCE) {
      const salesOrgObject = this.configurationService.readSelectedBranchAndSalesOrg().salesOrg;
      const filter: Filter<AdvanceFilter> = {
        page: 0,
        pageSize: 1,
        value: {
          receiptCode: this.paymentMethod.identifier,
          isReturn: false
        }
      };
      const advanceList = (await this.advanceService.getAdvances(filter).toPromise()).response.content;

      if (advanceList[0]) {
        advanceList[0].totalAmount = this.round(advanceList[0].totalAmount);
        if (advanceList[0].returnAdvanceCode) {
          this.toastrService.error('El anticipo fue devuelto');
          return;
        }
        // if ( advanceList[ 0 ].totalAmount > this.amount ) {
        //   this.toastrService.error( 'El monto del anticipo es incorrecto' );
        //   return;
        // }
        if (advanceList[0].salesOrg !== salesOrgObject.code) {
          this.toastrService.error(`El anticipo no pertence a ${salesOrgObject.name}`);
          return;
        }
        if (advanceList[0].customerCode !== this.customerCode) {
          this.toastrService.error(`El anticipo no pertence a este cliente, pertenece al BP ${advanceList[0].customerCode}`);
          return;
        }
      } else {
        this.toastrService.error('El anticipo no existe');
        return;
      }
    }

    if (!this.amount) {
      this.toastrService.error('Hubo un error al tratar de agregar el tipo de pago.');
      return;
    }

    if (this.paymentMethod.code === this.CHEQUE) {
      if (!this.paymentMethod.bank) {
        this.toastrService.error('Debe seleccionar un banco');
        return;
      }

      if (!this.paymentMethod.identifier) {
        this.toastrService.error('Agregue un número de cheque');
        return;
      }
    }

    if (!this.verifyTotalAmount()) {
      this.toastrService.error(`El monto ${this.amount} supera el permitido para esta compra`);
      return;
    }

    if (this.paymentMethod.code === this.NOTA_CAMBIO) {
      const today = new Date();
      const todayString = today.toISOString().split("T")[0].replace(/-/g, "");

      const response = await this.openItemsService.retrieveOpenItems(todayString, this.customerCode).toPromise();
      this.createNewNotaDeCambioPayment(response);

      return;
    }

    if (this.paymentMethod.code === this.VALE) {
      if (this.paymentMethod.identifier && this.amount) {
        const salesOrgObject = this.configurationService.readSelectedBranchAndSalesOrg().salesOrg;
        const voucher = (await this.voucherService.getVoucherByCode(this.paymentMethod.identifier).toPromise()).response;
        const voucherTotalAmount = voucher.totalAmount;

        if (voucher.salesOrgCode !== salesOrgObject.code) {
          this.toastrService.error(`El vale no pertence a ${salesOrgObject.name}`);
          return;
        }

        if (voucherTotalAmount != undefined) {
          if (this.amount > voucherTotalAmount) {
            this.toastrService.error(`El monto ingresado ( L.${this.amount} ), supera el total del vale ${this.paymentMethod.identifier}. Monto actual del vale L.${voucherTotalAmount}`);
            return;
          }
        } else {
          this.toastrService.error('Intentalo de  nuevo');
          return;
        }

        if (voucher.customerCode !== this.customerCode) {
          this.toastrService.error('El voucher no es del mismo cliente.');
          return;
        }

      } else {
        this.toastrService.error('Verifica que se ingresó indentificador o monto');
        return;
      }
    }

    if (this.paymentMethod.code === this.PAC) {
      if (this.creditLimitAmount < this.amount) {
        this.toastrService.error(`El monto ${this.amount} es mayor que el límite de crédito actual del cliente ${this.creditLimitAmount}`);
        return;
      }
    }

    if (this.paymentMethod.code === this.NCR) {
      const numberSplit = this.paymentMethod.identifier.split("-");
      if (numberSplit.length === 4) {

        const resp = await
          this.salesService.getInvoiceById(numberSplit[0], numberSplit[1], numberSplit[2], numberSplit[3]).toPromise();

        if (resp.response.content.length) {
          if (resp.response.content[0].orderType !== "RETURN") {
            this.toastrService.error('El metodo de pago tiene que ser una Nota de Credito');
            return;
          }
          if (resp.response.content[0].customerCode !== this.customerCode) {
            this.toastrService.error('La nota de credito no es del mismo cliente.');
            return;
          }

          if (!resp.response.content[0].usableAsPayment) {
            this.toastrService.error('No se puede utilizar esta nota de credito');
            return;
          }

          if (!resp.response.content[0].isActivated) {
            this.toastrService.error('No se puede utilizar la nota de credito de taller, por favor realizar proceso de activacion.');
            return;
          }

          const amountUsedAsPayment = resp.response.content[0].amountUsedAsPayment;
          if (amountUsedAsPayment === 0) {
            this.toastrService.error('Esta nota de crédito ya ha sido utilizada');
            return;
          }

          if (this.amount > amountUsedAsPayment) {
            this.toastrService.error(`La cantidad a pagar sobrepasa el valor disponible de la nota de crédito. Disponible: HNL ${amountUsedAsPayment}`);
            return;
          }

          this.paymentMethod.amount = this.amount;
          this.addPaymentToList();
          this.cancel();

        } else {
          this.toastrService.error('No existe la nota de crédito.');
          return;
        }

      }
    } else {
      this.paymentMethod.amount = this.amount;
      this.addPaymentToList();
      this.cancel();
    }
  }

  private verifyTotalAmount(): boolean {
    const total = this.round(this.totalAmount);
    const totalP = this.round(this.paymentsAmount);
    const difer = totalP + (this.paymentMethod.currency !== this.USD ?
      this.amount! : this.round(this.amount! * this.exchangeRate));
    const finalAmount = this.round(total - difer);

    let continuePayment = false;

    if (this.amount! > 0) {

      if (this.paymentMethod.code === this.LOYALTY) {
        if (this.amount! <= this.maxQuantityPointsCanUse) {
          this.totalPointsOfSelectedClient = +this.totalPointsOfSelectedClient - +this.amount!;
          this.maxQuantityPointsCanUse = +this.maxQuantityPointsCanUse - +this.amount!;
          this.maxQuantityPointsCanUse = this.round(this.maxQuantityPointsCanUse);
          continuePayment = true;
        }
      } else {

        if (finalAmount < 0) {
          if (this.paymentMethod.code === this.CASH_CODE || this.paymentMethod.code === this.CASH_CODE_USD) {
            if (this.totalAmount > this.paymentsAmount) {
              continuePayment = true;
            }
          }
          else {
            if (this.paymentMethod.currency === this.USD) {
              const totalPermitDollar = this.ceilNumber(((this.totalAmount - this.paymentsAmount) / this.exchangeRate), 2);
              const paymentAmount = this.amount;

              if (paymentAmount! <= totalPermitDollar) {
                continuePayment = true;
              }
            }
          }
        }
        else {
          continuePayment = true;
        }
      }
    }

    return continuePayment;
  }

  private createNewNotaDeCambioPayment(result: any): void {
    if (!result.response.documents) {
      this.toastrService.error('Hubo un error al leer los datos de la nota de cambio.');
      return;
    }

    const nca = result.response.documents.find((document: any) => document.reference === this.paymentMethod.identifier);

    if (!nca) {
      this.toastrService.error('No hay nota de cambio con este numero.');
      return;
    }
    if (Number.parseFloat(nca.amount) < this.amount!) {
      this.toastrService.error(`El monto ${this.amount} supera el permitido para esta compra`);
      return;
    }

    this.paymentMethod.amount = this.amount!;
    this.addPaymentToList();
    this.cancel();
  }

  private addPaymentToList(): void {

    if (this.paymentMethod.code !== this.CASH_CODE && this.paymentMethod.currency !== this.USD) {
      const finalAmount = this.totalAmount - this.paymentsAmount + this.paymentMethod.amount;
      if (finalAmount >= 0) {
        this.paymentMethod.applied = this.paymentMethod.amount;
      } else {
        this.toastrService.error('Este tipo de pago no permite cambio');
        return;
      }
    }

    let payment = new PaymentMethod().buildPaymentMethod(this.paymentMethod);
    this.paymentList.push(payment);
    this.confirmAction();
    this.paymentMethod.identifier = "";
  }

  private round(amount: number): number {
    return Math.round(amount * 100) / 100;
  }
}
