import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  CustomerCreditStatus,
  CustomerType,
  LimitRulesType,
  LoanClass,
  Personality,
} from 'src/app/@core/constants';
import { ColumnTable } from 'src/app/models/column-table';
import { Customer } from 'src/app/models/customer';
import { Paging } from 'src/app/models/page';
import { PreApproveCustomerParams } from 'src/app/models/params/preApprove-customer-params';
import { CreditStatusFranchisedResponseModel } from 'src/app/models/response/customerCreditStatusResponseModel';
import { PreApproveCreditPlanResponseModel } from 'src/app/models/response/preApproveCreditPlanResponseModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { OnbaseService } from 'src/app/services/onbase.service';
import Swal from 'sweetalert2';
import { CustomerValidationsService } from '../../services/customer-validations.service';
import { CreditPlanRequirementsListModalService } from './../../../../@core/services/credit-plan-requirements-list-modal.service';
import { CreditReferencesResponseModel } from './../../../../models/response/creditReferencesResponseModel';
import { OnbaseProcedureResponseModel } from './../../../../models/response/onbaseProcedureResponseModel';
import { CreditService } from './../../../../services/credit.service';

@Component({
  selector: 'app-customer-credit-plans',
  templateUrl: './customer-credit-plans.component.html',
  styleUrls: ['./customer-credit-plans.component.sass'],
})
export class CustomerCreditPlansComponent implements OnInit {
  @Input() currentCustomer: Customer = new Customer();
  @Input() currentCustomerCreditStatus: CreditStatusFranchisedResponseModel =
    new CreditStatusFranchisedResponseModel();

  @Input() creditPlans: Paging<Array<OnbaseProcedureResponseModel>> =
    new Paging([]);
  seletectCreditPlan!: OnbaseProcedureResponseModel;
  creditReferences: CreditReferencesResponseModel =
    new CreditReferencesResponseModel();
  creditAndBillForm: FormGroup;

  constructor(
    private onbaseService: OnbaseService,
    private configurationService: ConfigurationService,
    private creditService: CreditService,
    private authService: AuthService,
    private creditPlanRequirementsListModalService: CreditPlanRequirementsListModalService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
    private customerValidationsService: CustomerValidationsService
  ) {
    this.creditAndBillForm = this.fb.group({
      amountToBill: new FormControl(0, [Validators.required]),
      downPayment: new FormControl(0, [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    // await this.getCreditPlansData();

    this.creditReferences = (
      await this.creditService
        .getCreditReferences(
          this.configurationService.readSelectedBranchAndSalesOrg().salesOrg
            .code,
          undefined,
          this.getLoanClass()
        )
        .toPromise()
    ).response[0];
  }

  getCreditPlansHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('name', 'Plan'),
      new ColumnTable('writtenDuration', 'NAS'),
      new ColumnTable('code', 'Opciones', {
        type: 'action',
        actions: [
          {
            fn: (creditPlan: OnbaseProcedureResponseModel) =>
              this.handleViewRequirements(creditPlan),
            icon: 'cil-check-circle',
            tooltip: 'Ver requisitos',
            isDisabled: false,
          },
          {
            fn: (creditPlan: OnbaseProcedureResponseModel) =>
              this.handlePreApproveCreditPlan(creditPlan),
            icon: 'cil-list-high-priority',
            tooltip: 'Pre aprobar plan',
            isDisabled: false,
            disabledFn: (creditPlan: OnbaseProcedureResponseModel) =>
              creditPlan.preApprove,
          },
          {
            fn: (creditPlan: OnbaseProcedureResponseModel) =>
              this.handleCreditRequestClick(creditPlan),
            icon: 'cil-pen',
            tooltip: 'Ingresar Solicitud',
            isDisabled: true,
            disabledFn: (creditPlan: OnbaseProcedureResponseModel) =>
              !creditPlan.preApprove,
          },
        ],
      }),
    ];
  }

  async getCreditPlansData(): Promise<void> {
    this.creditPlans = (
      await this.onbaseService
        .readPreApproveCreditPlans(
          this.currentCustomer.code,
          this.configurationService.readSelectedBranchAndSalesOrg().branch.code,
          LimitRulesType.BURO,
          this.configurationService.readSelectedBranchAndSalesOrg().salesOrg
            .code
        )
        .toPromise()
    ).response;
  }

  public async handlePreApproveCreditPlan(
    creditPlan: OnbaseProcedureResponseModel
  ): Promise<void> {
    if (creditPlan) {
      this.seletectCreditPlan = creditPlan;
      const canContinue =
        await this.customerValidationsService.validateOnBaseCreditStatus(
          this.currentCustomer.code
        );
      if (canContinue) {
        const preApproveCustomerParams: PreApproveCustomerParams =
          this.buildPreApproveParams(creditPlan);

        const preResponse = await this.preApprovePlan(preApproveCustomerParams);

        if (preResponse.canCreateCreditRequest) {
          this.toastr.success(preResponse.message, `Operación Exitosa`);
        } else {
          Swal.fire({
            title: 'Atencion!',
            text: preResponse.message,
            icon: 'warning',
            confirmButtonText: 'Aceptar',
          });
        }

        creditPlan.preApprove = preResponse.canCreateCreditRequest;
      }
    }
  }

  private async preApprovePlan(
    preApproveCustomerParams: PreApproveCustomerParams
  ): Promise<PreApproveCreditPlanResponseModel> {
    return await this.creditService
      .getPreApproveCreditInfo(preApproveCustomerParams)
      .toPromise();
  }

  private buildPreApproveParams(
    creditPlan: OnbaseProcedureResponseModel
  ): PreApproveCustomerParams {
    return {
      customerCode: this.currentCustomer.code,
      fiscalId: this.currentCustomer.mainPersonalInfo?.fiscalId
        ? this.currentCustomer.mainPersonalInfo?.fiscalId
        : '',
      fiscalIdType: this.currentCustomer.mainPersonalInfo?.fiscalIdType
        ? this.currentCustomer.mainPersonalInfo?.fiscalIdType
        : '',
      branchCode:
        this.configurationService.readSelectedBranchAndSalesOrg().branch.code,
      limitRule: creditPlan.limitRule,
      salesOrg:
        this.configurationService.readSelectedBranchAndSalesOrg().salesOrg.code,
      sellerCode: this.authService.readProfileInStorage().username,
      menu: 'CUSTOMERS',
      amountToInvoice: this.creditAndBillForm.value.amountToBill,
      downPayment: this.creditAndBillForm.value.downPayment,
      onbaseProcedureCode: creditPlan.code,
    };
  }

  private async handleViewRequirements(
    creditPlan: OnbaseProcedureResponseModel
  ): Promise<void> {
    this.creditPlanRequirementsListModalService.currentCredidPlan = creditPlan;
    this.creditPlanRequirementsListModalService.show();
  }

  private async handleCreditRequestClick(
    creditPlan: OnbaseProcedureResponseModel
  ): Promise<void> {
    this.router.navigate(['customer/createcreditrequest'], {
      state: {
        creditPlan,
        customer: this.currentCustomer,
        customerCreditConditions: this.currentCustomerCreditStatus,
      },
      replaceUrl: true,
    });
  }
  private getLoanClass(): string {
    let loanClass = ``;

    if (
      this.currentCustomerCreditStatus.estadoCliente ===
      CustomerCreditStatus.COVENANT
    ) {
      return LoanClass.CLASS_FOR_COVENANT;
    }

    if (this.currentCustomer.customerType === CustomerType.EMPLOYEE) {
      return LoanClass.CLASS_FOR_EMPLOYEE;
    }

    if (
      this.currentCustomer.mainPersonalInfo?.personality === Personality.PERSON
    ) {
      return LoanClass.CLASS_FOR_PERSON;
    } else if (
      this.currentCustomer.mainPersonalInfo?.personality ===
      Personality.LEGAL_ENTITY
    ) {
      // TODO: ver porque para juridico no se pone clase de contrato??
      loanClass = ``;
    }
    return loanClass;
  }

  public pathPreApprovalValueForm(formValue: any): void {
    this.creditAndBillForm.patchValue(formValue);
    this.creditAndBillForm.controls.downPayment.patchValue(
      `${(this.creditReferences.minDownPayment / 100) *
      this.creditAndBillForm.value.amountToBill
      }`
    );
  }
}
