import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { LocalStorageService } from './services/local-storage.service';
@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'frontend-pos-franchised';

  constructor(private router: Router,
    public iconSet: IconSetService,
    private localStorageService: LocalStorageService) {
    iconSet.icons = { ...freeSet };
  }
  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if ( evt instanceof NavigationEnd && !evt.url.includes( 'customer' ) ) {
        this.localStorageService.remove( 'selectedCustomer' );
      }
      window.scrollTo(0, 0);
    });
  }
}