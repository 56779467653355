import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductInfoComponent } from './product-info.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';



@NgModule({
  declarations: [ProductInfoComponent],
  imports: [
    CommonModule,
    AccordionModule
  ],
  exports:[ProductInfoComponent]
})
export class ProductInfoModule { }
