import { OrderConditions, PrintType } from './../../@core/constants';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DigitalSignatureRequestStatus, DigitalSignatureRequestStatusInOrder, DocumentType, DomainTraditionReportType, OrderAttachmentType, OrderCondition, OrderStatus, OrderType, Personality } from 'src/app/@core/constants';
import { DateUtils } from 'src/app/@core/utils/date-utils';
import { FileUtils } from 'src/app/@core/utils/file-utils';
import { Customer } from 'src/app/models/customer';
import { DigitalSignature, DigitalSignatureDocumentType } from 'src/app/models/digital-signature';
import { OrderAttachment } from 'src/app/models/order-attachment';
import { OrderCompleteProcess } from 'src/app/models/order-complete-process';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-pos-print-documents-modal',
  templateUrl: './pos-print-documents-modal.component.html',
  styleUrls: ['./pos-print-documents-modal.component.sass']
})
export class PosPrintDocumentsModalComponent implements OnInit {

  public processedOrder: OrderCompleteProcess = new OrderCompleteProcess();

  // quotation: boolean = false;
  invoices: Array<{ code: string, fullNumber?: string, print: boolean, printType: PrintType }> = new Array<{ code: string, fullNumber?: string, print: boolean, printType: PrintType }>();
  receipts: Array<{ code: string, print: boolean }> = new Array<{ code: string, print: boolean }>();
  debitNote: Array<{ code: string, print: boolean }> = new Array<{ code: string, print: boolean }>();
  promissoryNotes: Array<{ attachment: OrderAttachment, print: boolean }> = [];
  vehicleRegistration: boolean = false;
  thirdPartyForm = { identity: "", name: "" };
  thirdPartyAuthoriztion = { code: "", print: false };
  checklist = { code: "", print: false };
  purchaseOrder = { code: "", print: false };
  registrationRefusal = { code: "", location: "", print: false };
  warrantyCertificate: Array<{ invoiceCode: string, invoiceNumber: string, print: boolean }> = [];
  fiscalIdFile: { attachment: OrderAttachment, print: boolean } = { attachment: new OrderAttachment(), print: false };
  rtnFiscalIdFile: { attachment: OrderAttachment, print: boolean } = { attachment: new OrderAttachment(), print: false };
  ip800File: { attachment: OrderAttachment, print: boolean } = { attachment: new OrderAttachment(), print: false };
  proofOfPaymentFile: { attachment: OrderAttachment, print: boolean } = { attachment: new OrderAttachment(), print: false };
  purchaseOrderFile: { attachment: OrderAttachment, print: boolean } = { attachment: new OrderAttachment(), print: false };
  domainTraditionFiles: Array<{ code: string, invoiceNumber: string, type: DomainTraditionReportType, print: boolean }> = [];
  circulationPermits: Array<{ code: string, print: boolean }> = [];
  digitalSignature: DigitalSignature = new DigitalSignature();
  paymentPlan: Array<{ code: string, print: boolean }> = [];
  loan: Array<{ code: string, print: boolean }> = [];
  quotations: Array<{ loanCode?: string, print: boolean }> = [];
  UNTIL_30_DAYS_FRANCHISED = 1;
  MORE_THAN_30_DAYS_FRANCHISED = 2;

  customer: Customer = new Customer();
  constructor(
    public bsModalRef: BsModalRef,
    private reportService: ReportService) { }

  ngOnInit(): void { }

  loadInfo() {
    this.fiscalIdFile = { attachment: this.processedOrder.attachments.find(f => f.type == OrderAttachmentType.FISCALID) || new OrderAttachment(), print: false };
    this.rtnFiscalIdFile = { attachment: this.processedOrder.attachments.find(f => f.type == OrderAttachmentType.RTNFISCALID) || new OrderAttachment(), print: false };
    this.proofOfPaymentFile = { attachment: this.processedOrder.attachments.find(f => f.type == OrderAttachmentType.PROOF_OF_PAYMENT) || new OrderAttachment(), print: false };
    this.purchaseOrderFile = { attachment: this.processedOrder.attachments.find(f => f.type == OrderAttachmentType.PURCHASE_ORDER) || new OrderAttachment(), print: false };

    this.purchaseOrder.code = this.processedOrder.order.code;
    this.purchaseOrder.print = false;
    if (this.processedOrder.order.orderType != OrderType.QUOTATION) {

      this.invoices = this.processedOrder.invoices.map(invoice => {
        return { code: invoice.code, fullNumber: invoice.fullNumber, print: true, printType: PrintType.ORIGINAL };
      });

      if (!this.canShowDigitalSignatureDocs) {
        // adding one invoice record for copies
        this.invoices = [...this.invoices, ...this.processedOrder.invoices.map(invoice => {
          return { code: invoice.code, fullNumber: invoice.fullNumber, print: true, printType: PrintType.COPY };
        })];
      }

      if (this.processedOrder.order.condition == 'credito' && !this.processedOrder.order.isThirtyDays && this.processedOrder.order.status == OrderStatus.FINAL) {
        this.paymentPlan = this.processedOrder.loans.map(loan => {
          return { code: loan.contractNumber, print: true };
        });
        this.loan = this.processedOrder.loans.map(loan => {
          return { code: loan.contractNumber, print: true };
        });
      }

      this.receipts = this.processedOrder.receipts.filter(f => !f.debitNote).map(receipt => {
        return { code: receipt.code, print: true };
      });

      this.debitNote = this.processedOrder.receipts.filter(f => f.debitNote).map(receipt => {
        return { code: receipt.code, print: true };
      });

      this.promissoryNotes = this.processedOrder.attachments.filter(f => f.type == OrderAttachmentType.PROMISSORY_NOTE).map(m => {
        return { attachment: m, print: true };
      });



      let services = this.processedOrder.order.orderEntryList
        .filter(f => f.productCode === "PAGO_MATRICULA" || f.productCode === "PAGO_PLACA")
        .map(m => m.code);

      if (services.length > 0 && this.processedOrder.order.status == OrderStatus.FINAL) {
        this.vehicleRegistration = true;

        let productOnInvoice = this.processedOrder.order.orderEntryList.filter(f => f.services.length > 0);
        let invoice = this.processedOrder.invoices.filter(f => f.deliveryInvoiceLines.filter(s => s.productCode == productOnInvoice[0].code));
        this.ip800File = { attachment: this.processedOrder.attachments.find(f => f.type == OrderAttachmentType.IP800) || new OrderAttachment(), print: true };
        this.checklist.code = invoice[0].code;
        this.thirdPartyAuthoriztion.code = invoice[0].code;
        this.registrationRefusal.code = invoice[0].code;
        this.generateDomainTraditionInformation();
        this.circulationPermits = this.processedOrder.circulationPermitRequests.map(m => { return { code: m.code, print: true } });
        if (this.processedOrder.order.salesOrgCode == "1600") {
          this.warrantyCertificate = this.processedOrder.invoices.filter(f => f.deliveryInvoiceLines.some(x => x.serialProfile)).map(x => {
            return {
              invoiceCode: x.code,
              invoiceNumber: x.fullNumber || "",
              print: true
            }
          })
        }
      }
    }
    else {
      this.quotations = this.processedOrder.loans.map(m => {

        return { loanCode: m.code, print: false };
      });

      if (this.quotations.length == 0) {
        this.quotations.push({ loanCode: undefined, print: false });
      }
    }

    if (this.canShowDigitalSignatureDocs) {
      this.digitalSignature.documents.forEach(document => {
        document.print = true;
      });

      this.digitalSignature.documents.filter(document => document.documentType === DigitalSignatureDocumentType.INVOICE).forEach(invoice => {
        invoice.printType = PrintType.ORIGINAL;
        this.digitalSignature.documents.push({ ...invoice, printType: PrintType.COPY });
      });
    }
  }

  private generateDomainTraditionInformation() {
    let orderEntriesWithServices = this.processedOrder.order.orderEntryList.filter(f => f.services.length > 0).map(m => m.code);
    this.processedOrder.invoices.filter(m => m.deliveryInvoiceLines.some(s => orderEntriesWithServices.includes(s.orderEntryCode))).map(m => {

      switch (this.customer.mainPersonalInfo?.personality) {
        case Personality.PERSON:
          this.domainTraditionFiles.push({
            code: m.code, invoiceNumber: `${m.fullNumber || ""} Persona a Persona`, print: true, type: DomainTraditionReportType.PERSON_TO_PERSON
          });
          this.domainTraditionFiles.push({
            code: m.code, invoiceNumber: `${m.fullNumber || ""} Persona a Jurídico`, print: true, type: DomainTraditionReportType.PERSON_TO_JURIDIC
          });
          this.domainTraditionFiles.push({
            code: m.code, invoiceNumber: `${m.fullNumber || ""} Jurídico a Jurídico`, print: false, type: DomainTraditionReportType.JURIDIC_TO_JURIDIC
          });
          this.domainTraditionFiles.push({
            code: m.code, invoiceNumber: `${m.fullNumber || ""} Jurídico a Persona`, print: false, type: DomainTraditionReportType.JURIDIC_TO_PERSON
          });
          break;
        case Personality.LEGAL_ENTITY:
          this.domainTraditionFiles.push({
            code: m.code, invoiceNumber: `${m.fullNumber || ""} Jurídico a Jurídico`, print: true, type: DomainTraditionReportType.JURIDIC_TO_JURIDIC
          });
          this.domainTraditionFiles.push({
            code: m.code, invoiceNumber: `${m.fullNumber || ""} Jurídico a Persona`, print: true, type: DomainTraditionReportType.JURIDIC_TO_PERSON
          });
          this.domainTraditionFiles.push({
            code: m.code, invoiceNumber: `${m.fullNumber || ""} Persona a Persona`, print: false, type: DomainTraditionReportType.PERSON_TO_PERSON
          });
          this.domainTraditionFiles.push({
            code: m.code, invoiceNumber: `${m.fullNumber || ""} Persona a Jurídico`, print: false, type: DomainTraditionReportType.PERSON_TO_JURIDIC
          });
          break;
        default:
          break;
      }



    });
  }

  get canShowDigitalSignatureDocs(): boolean {
    return this.processedOrder.order.digitalSignature;
  }

  get isDigitalSignatureDone(): boolean {
    return this.digitalSignature && this.digitalSignature.processingStatus === DigitalSignatureRequestStatus.DONE;
  }

  getDigitalSignatureDocName(docType: string): string {
    switch (docType) {
      case DigitalSignatureDocumentType.PROMISSORY_NOTE:
        return 'Pagaré';
      case DigitalSignatureDocumentType.INVOICE:
        return 'Factura';
      case DigitalSignatureDocumentType.LOAN:
        return 'Contrato';
      case DigitalSignatureDocumentType.DOMINANCE_TRADITION:
        return 'Tradición de dominio';
      case DigitalSignatureDocumentType.DEBIT_NOTE:
        return 'Nota de cargo';
      case DigitalSignatureDocumentType.LOAN_APPENDIX:
        return 'Anexo contrato';
      case DigitalSignatureDocumentType.PAYMENT_PLAN:
        return 'Plan de pagos';
      default:
        return 'Desconocido'
    }
  }

  cancel() {
    this.bsModalRef.hide();
    this.digitalSignature.documents = this.digitalSignature.documents.filter(doc => doc.printType !== PrintType.COPY);
  }

  async print() {

    //if (this.processedOrder.order.orderType == OrderType.QUOTATION) {
    let quotationReports = await Promise.all(this.quotations.filter(f => f.print).map(async quotation => {
      let blob = await await this.reportService.quotationPDF(this.processedOrder.order.code, this.processedOrder.order.salesOrgCode, quotation.loanCode).toPromise()
      return blob;
    }));
    // await this.reportService.printReports(...quotationReports);
    // return;


    let invoiceReports: Blob[] = [];
    let invoicesCopy: Blob[] = [];
    let promissoryNotesReports: Blob[] = [];
    let paymentPlanReports: Blob[] = [];
    let loanReports: Blob[] = [];
    let reports = new Array<Blob>();
    let debitNoteReports: Blob[] = [];

    if (!this.canShowDigitalSignatureDocs) {
      invoiceReports = await Promise.all(this.invoices.filter(s => s.print && s.printType === PrintType.ORIGINAL).map(async invoice => {
        let blob = (await this.reportService.invoicePdf(invoice.code, this.processedOrder.order.salesOrgCode).toPromise());
        return blob;
      }));

      promissoryNotesReports = this.promissoryNotes.filter(s => s.print).map(m => {
        let blob = FileUtils.dataURLtoBlob(m.attachment.data);
        return blob;
      });

      paymentPlanReports = await Promise.all(this.paymentPlan.filter(s => s.print).map(async m => {
        let blob = (await this.reportService.paymentPlanPdf(m.code, this.processedOrder.order.salesOrgCode).toPromise())
        return blob;
      }));

      loanReports = await Promise.all(this.loan.filter(s => s.print).map(async m => {
        let blob = (await this.reportService.loanPdf(m.code, this.processedOrder.order.salesOrgCode).toPromise())
        return blob;
      }));

      debitNoteReports = await Promise.all(this.debitNote.filter(s => s.print).map(async debitNote => {
        let blob = (await this.reportService.debitNotePdf(debitNote.code, this.processedOrder.order.salesOrgCode).toPromise());
        return blob;
      }));
    }

    invoicesCopy = await Promise.all(this.invoices.filter(s => s.print && s.printType === PrintType.COPY).map(async invoice => {
      let blob = (await this.reportService.invoiceCopyPdf(invoice.code, this.processedOrder.order.salesOrgCode).toPromise());
      return blob;
    }));

    await Promise.all(this.domainTraditionFiles.map(async item => {
      if (item.print) {
        reports.push((await this.reportService.domainTraditionPdf(item.code, this.processedOrder.order.salesOrgCode, item.type).toPromise()));
      }
    }));

    let receiptReports = await Promise.all(this.receipts.filter(s => s.print).map(async receipt => {
      let blob = (await this.reportService.receiptPdf(receipt.code).toPromise());
      return blob;
    }));

    if (this.checklist.print) {
      reports.push(await this.reportService.checklistPdf(this.checklist.code, this.processedOrder.order.salesOrgCode).toPromise());
    }

    if (this.purchaseOrder.print) {
      reports.push(await this.reportService.purchaseOrderPdf(this.purchaseOrder.code).toPromise());
    }


    let warrantyReports = await Promise.all(this.warrantyCertificate.filter(s => s.print).map(async item => {
      let blob = (await this.reportService.warrantyCertificate(item.invoiceCode, this.processedOrder.order.salesOrgCode).toPromise());
      return blob;
    }));

    let circulationPermitReports = await Promise.all(this.circulationPermits.filter(s => s.print).map(async permit => {
      let blob = (await this.reportService.circulationPermit(permit.code, this.processedOrder.order.salesOrgCode).toPromise());
      return blob;
    }))

    if (this.thirdPartyAuthoriztion.print) {
      reports.push(await this.reportService.thirdPartyAuthorizationPdf(this.thirdPartyAuthoriztion.code, this.thirdPartyForm.identity, this.thirdPartyForm.name, this.processedOrder.order.salesOrgCode).toPromise());
    }

    if (this.registrationRefusal.print) {
      reports.push(await this.reportService.registrationRefusalPdf(this.registrationRefusal.code, this.registrationRefusal.location, this.processedOrder.order.salesOrgCode).toPromise());
    }

    let attachedReports = new Array<Blob>();
    if (this.fiscalIdFile.print) {
      attachedReports.push(FileUtils.dataURLtoBlob(this.fiscalIdFile.attachment.data));
    }
    if (this.rtnFiscalIdFile.print) {
      attachedReports.push(FileUtils.dataURLtoBlob(this.rtnFiscalIdFile.attachment.data));
    }
    if (this.ip800File.print) {
      attachedReports.push(FileUtils.dataURLtoBlob(this.ip800File.attachment.data))
    }
    if (this.proofOfPaymentFile.print) {
      attachedReports.push(FileUtils.dataURLtoBlob(this.proofOfPaymentFile.attachment.data))
    }
    if (this.purchaseOrderFile.print) {
      attachedReports.push(FileUtils.dataURLtoBlob(this.purchaseOrderFile.attachment.data))
    }

    if (this.canShowDigitalSignatureDocs) {
      for (let i = 0; i < this.digitalSignature.documents.filter(x => x.print).length; i++) {
        const doc = this.digitalSignature.documents.filter(x => x.print)[i];
        if (doc.documentType == DigitalSignatureDocumentType.INVOICE) {
          if (doc.printType !== PrintType.COPY) {
            invoiceReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());
          } else {
            invoicesCopy.push(
              await this.invoices.filter(s => s.print && s.printType === PrintType.ORIGINAL && doc.fileName.includes(s.fullNumber || '')).map(async invoice => {
                let blob = (await this.reportService.invoiceCopyPdf(invoice.code, this.processedOrder.order.salesOrgCode).toPromise());
                return blob;
              })[0]
            );
          }
        }


        if (doc.documentType == DigitalSignatureDocumentType.LOAN)
          loanReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());

        if (doc.documentType == DigitalSignatureDocumentType.PAYMENT_PLAN)
          paymentPlanReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());

        if (doc.documentType == DigitalSignatureDocumentType.PROMISSORY_NOTE)
          promissoryNotesReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());

        if (doc.documentType == DigitalSignatureDocumentType.DEBIT_NOTE)
          debitNoteReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());

        if (doc.documentType == DigitalSignatureDocumentType.LOAN_APPENDIX)
          debitNoteReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());
      }
    }

    await this.print24hReports(invoiceReports, invoicesCopy, receiptReports, promissoryNotesReports, loanReports, paymentPlanReports, circulationPermitReports);
    await this.reportService.printReports(
      ...reports,
      // ...invoicesCopy,
      // ...receiptReports,
      ...debitNoteReports,
      ...attachedReports,
      ...warrantyReports,
      //...circulationPermitReports,
      //...paymentPlanReports,
      //...loanReports,
      ...quotationReports
      //...promissoryNotesReports
    );
  }

  async printAll() {

    if (this.processedOrder.order.orderType == OrderType.QUOTATION) {
      let quotationReports = await Promise.all(this.quotations.map(async quotation => {
        let blob = await await this.reportService.quotationPDF(this.processedOrder.order.code, this.processedOrder.order.salesOrgCode, quotation.loanCode).toPromise()
        return blob;
      }));
      let fiscalId = FileUtils.dataURLtoBlob(this.fiscalIdFile.attachment.data);
      let rtnFiscalId = FileUtils.dataURLtoBlob(this.rtnFiscalIdFile.attachment.data);
      await this.reportService.printReports(...quotationReports, fiscalId, rtnFiscalId);
    }
    if (this.processedOrder.order.orderType == OrderType.REGULAR && this.processedOrder.order.status == OrderStatus.FINAL) {
      let reports: Blob[] = [];
      let invoiceCopyReports: Blob[] = [];
      let promissoryNotesReports: Blob[] = [];
      let loanReports: Blob[] = [];
      let paymentPlanReports: Blob[] = [];
      let debitNoteReports: Blob[] = [];

      if (!this.canShowDigitalSignatureDocs) {
        reports = await Promise.all(this.invoices.filter(invoice => invoice.printType === PrintType.ORIGINAL).map(async invoice => {
          let blob = (await this.reportService.invoicePdf(invoice.code, this.processedOrder.order.salesOrgCode).toPromise());
          return blob;
        }));

        promissoryNotesReports = await Promise.all(this.promissoryNotes.map(async promissoryNote => {
          let blob = FileUtils.dataURLtoBlob(promissoryNote.attachment.data);
          return blob;
        }));

        loanReports = await Promise.all(this.loan.filter(s => s.print).map(async m => {
          let blob = (await this.reportService.loanPdf(m.code, this.processedOrder.order.salesOrgCode).toPromise())
          return blob;
        }));

        paymentPlanReports = await Promise.all(this.paymentPlan.filter(s => s.print).map(async m => {
          let blob = (await this.reportService.paymentPlanPdf(m.code, this.processedOrder.order.salesOrgCode).toPromise())
          return blob;
        }));

        debitNoteReports = await Promise.all(this.debitNote.filter(s => s.print).map(async debitNote => {
          let blob = (await this.reportService.debitNotePdf(debitNote.code, this.processedOrder.order.salesOrgCode).toPromise());
          return blob;
        }));
      }

      invoiceCopyReports = await Promise.all(this.invoices.filter(invoice => invoice.printType === PrintType.COPY).map(async invoice => {
        let blob = (await this.reportService.invoiceCopyPdf(invoice.code, this.processedOrder.order.salesOrgCode).toPromise());
        return blob;
      }));

      let receipReports = await Promise.all(this.receipts.map(async receipt => {
        let blob = (await this.reportService.receiptPdf(receipt.code).toPromise());
        return blob;
      }));

      let ip800Report = new Blob();
      if (this.ip800File.attachment.data) {

        ip800Report = FileUtils.dataURLtoBlob(this.ip800File.attachment.data);
      }

      let fiscalId = new Blob();
      if (this.fiscalIdFile.attachment.data) {

        fiscalId = FileUtils.dataURLtoBlob(this.fiscalIdFile.attachment.data);
      }

      let rtnFiscalId = new Blob();
      if (this.rtnFiscalIdFile.attachment.data) {

        rtnFiscalId = FileUtils.dataURLtoBlob(this.rtnFiscalIdFile.attachment.data);
      }

      let proofOfPayment = new Blob();
      if (this.proofOfPaymentFile.attachment.data) {
        proofOfPayment = FileUtils.dataURLtoBlob(this.proofOfPaymentFile.attachment.data);
      }

      let purchaseOrder = new Blob();
      if (this.purchaseOrderFile.attachment.data) {
        purchaseOrder = FileUtils.dataURLtoBlob(this.purchaseOrderFile.attachment.data);
      }

      if (this.canShowDigitalSignatureDocs) {
        for (let i = 0; i < this.digitalSignature.documents.length; i++) {
          const doc = this.digitalSignature.documents[i];
          // if (doc.documentType == DigitalSignatureDocumentType.INVOICE)
          //   if(doc.printType!==PrintType.COPY)
          //     reports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());
          if (doc.documentType == DigitalSignatureDocumentType.INVOICE) {
            if (doc.printType !== PrintType.COPY) {
              reports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());
            } else {
              invoiceCopyReports.push(
                await this.invoices.filter(s => s.print && s.printType === PrintType.ORIGINAL && doc.fileName.includes(s.fullNumber || '')).map(async invoice => {
                  let blob = (await this.reportService.invoiceCopyPdf(invoice.code, this.processedOrder.order.salesOrgCode).toPromise());
                  return blob;
                })[0]
              );
            }
          }

          if (doc.documentType == DigitalSignatureDocumentType.LOAN)
            loanReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());

          if (doc.documentType == DigitalSignatureDocumentType.PAYMENT_PLAN)
            paymentPlanReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());

          if (doc.documentType == DigitalSignatureDocumentType.PROMISSORY_NOTE)
            promissoryNotesReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());

          if (doc.documentType == DigitalSignatureDocumentType.DEBIT_NOTE)
            debitNoteReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());

          if (doc.documentType == DigitalSignatureDocumentType.LOAN_APPENDIX)
            debitNoteReports.push(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());
        }
      }

      let warrantyReports = await Promise.all(this.warrantyCertificate.filter(s => s.print).map(async item => {
        let blob = (await this.reportService.warrantyCertificate(item.invoiceCode, this.processedOrder.order.salesOrgCode).toPromise());
        return blob;
      }));

      let circulationPermitReports = await Promise.all(this.circulationPermits.filter(s => s.print).map(async permit => {
        let blob = (await this.reportService.circulationPermit(permit.code, this.processedOrder.order.salesOrgCode).toPromise());
        return blob;
      }))

      this.domainTraditionFiles.forEach(async item => {
        reports.push((await this.reportService.domainTraditionPdf(item.code, this.processedOrder.order.salesOrgCode, item.type).toPromise()));
      });

      await this.print24hReports(reports, invoiceCopyReports, receipReports, promissoryNotesReports, loanReports, paymentPlanReports, circulationPermitReports);
      await this.reportService.printReports(
        //...reports,
        //...invoiceCopyReports,
        //...receipReports,
        //...promissoryNotesReports,
        ip800Report,
        fiscalId,
        rtnFiscalId,
        proofOfPayment,
        purchaseOrder,
        ...debitNoteReports,
        ...warrantyReports,
        //...circulationPermitReports
      );
    }
    if (this.processedOrder.order.orderType == OrderType.REGULAR && this.processedOrder.order.status != OrderStatus.FINAL) {
      let fiscalId = FileUtils.dataURLtoBlob(this.fiscalIdFile.attachment.data);
      let rtnFiscalId = FileUtils.dataURLtoBlob(this.rtnFiscalIdFile.attachment.data);
      let proofOfPayment = FileUtils.dataURLtoBlob(this.proofOfPaymentFile.attachment.data);
      let purchaseOrder = FileUtils.dataURLtoBlob(this.purchaseOrderFile.attachment.data);
      await this.reportService.printReports(fiscalId, rtnFiscalId, proofOfPayment, purchaseOrder);
    }

  }

  private async printDigitalSignatureDocs(printAll: boolean) {
    for (let i = 0; i < this.digitalSignature.documents.length; i++) {
      const doc = this.digitalSignature.documents[i];
      if (printAll) {
        this.reportService.printReport(await this.reportService.digitalSignaturePrintPDF(this.digitalSignature.requestNumber, doc.code).toPromise());
      }
    }
  }

  validatePrintButton(): boolean {
    if (this.thirdPartyAuthoriztion.print) {
      if (this.thirdPartyForm.identity.length < 13 || this.thirdPartyForm.name.length == 0) {
        return true;
      }
    }
    if (this.registrationRefusal.print) {
      return this.registrationRefusal.location.length == 0 ? true : false;
    }
    return false;
  }

  async print24hReports(invoiceOriginal: Array<Blob>, invoicesCopy: Array<Blob>, receiptReports: Array<Blob>, promissoryNotesReports: Array<Blob>, loan: Array<Blob>, paymentPlanloan: Array<Blob>, circulationPermitReports: Array<Blob>) {
    let today = new Date();
    let reports = new Array<Blob>();

    let privileges = Array<string>();
    this.setPrivilegesForReports(today, invoiceOriginal, invoicesCopy, privileges, reports, receiptReports, promissoryNotesReports, loan, paymentPlanloan, circulationPermitReports);

    //  await this.reportService.printReports(...reportsBefore);
    await this.reportService.printReportsSupervisor(privileges,
      ...reports);


  }

  private setPrivilegesForReports(today: Date, invoiceOriginal: Blob[], invoicesCopy: Blob[], privileges: string[], reports: Blob[], receiptReports: Blob[], promissoryNotesReports: Blob[], loan: Array<Blob>, paymentPlanloan: Array<Blob>, circulationPermitReports: Array<Blob>) {

    if (this.processedOrder.invoices.some(s => today.getTime() > DateUtils.getDateFromStringForReports(s.timeStamp).getTime()) && (invoiceOriginal.length > 0 || invoicesCopy.length > 0)) {
      privileges.push("reprintInvoiceWithOutPayment_After24H");
      reports.push(...invoiceOriginal, ...invoicesCopy);
    } else if (this.processedOrder.invoices.some(s => DateUtils.getDateFromStringForReports(s.timeStamp).getTime() >= today.getTime()) && (invoiceOriginal.length > 0 || invoicesCopy.length > 0)) {
      privileges.push("reprintInvoiceWithOutPayment_Before24H");
      reports.push(...invoiceOriginal, ...invoicesCopy);
    }

    if (this.processedOrder.receipts.some(s => today.getTime() > DateUtils.getDateFromStringForReports(s.timeStamp).getTime()) && receiptReports.length > 0) {
      privileges.push("reprintReceipt_After24H");
      reports.push(...receiptReports);
    } else if (this.processedOrder.receipts.some(s => DateUtils.getDateFromStringForReports(s.timeStamp).getTime() >= today.getTime()) && receiptReports.length > 0) {
      privileges.push("reprintReceipt_Before24H");
      reports.push(...receiptReports);
    }

    if (this.processedOrder.loans.some(s => today.getTime() > DateUtils.getDateFromStringForReports(s.creationDate).getTime())) {
      if (promissoryNotesReports.length > 0 || loan.length > 0 || paymentPlanloan.length > 0)
        privileges.push("reprintDocumentsCredit_After24H");
      promissoryNotesReports.length > 0 ? reports.push(...promissoryNotesReports) : "";
      loan.length > 0 ? reports.push(...loan) : "";
      paymentPlanloan.length > 0 ? reports.push(...paymentPlanloan) : "";

    }
    else if (this.processedOrder.loans.some(s => DateUtils.getDateFromStringForReports(s.creationDate).getTime() >= today.getTime())) {
      if (promissoryNotesReports.length > 0 || loan.length > 0 || paymentPlanloan.length > 0)
        privileges.push("reprintDocumentsCredit_Before24H");
      promissoryNotesReports.length > 0 ? reports.push(...promissoryNotesReports) : "";
      loan.length > 0 ? reports.push(...loan) : "";
      paymentPlanloan.length > 0 ? reports.push(...paymentPlanloan) : "";
    }

    if (this.processedOrder.order.orderCondition !== OrderConditions.CASH_BILLLING && invoiceOriginal.length > 0) {
      privileges.push("printOriginalCreditInvoice");
    }

    if (this.processedOrder.circulationPermitRequests.some(s => !s.timestamp || today.getTime() > DateUtils.getDateFromStringForReports(s.timestamp).getTime())) {
      privileges.push("printCirculationPermit_After24H");
      reports.push(...circulationPermitReports);
    }

    if (this.processedOrder.circulationPermitRequests.some(s => s.timestamp && DateUtils.getDateFromStringForReports(s.timestamp).getTime() >= today.getTime())) {
      privileges.push("printCirculationPermit_Before24H");
      reports.push(...circulationPermitReports);
    }
  }

  selectAllDocuments(): void {
    this.quotations.forEach(c => c.print = true);
    this.invoices.forEach(invoice => invoice.print = true);
    this.loan.forEach(loan => loan.print = true);
    this.receipts.forEach(r => r.print = true);
    this.debitNote.forEach(d => d.print = true);
    this.paymentPlan.forEach(pp => pp.print = true);
    this.promissoryNotes.forEach(pn => pn.print = true);
    this.digitalSignature.documents.forEach(doc => doc.print = true);
    this.circulationPermits.forEach(c => c.print = true);
    this.domainTraditionFiles.forEach(dt => dt.print = true);
    this.warrantyCertificate.forEach(w => w.print = true);


    this.fiscalIdFile.print = true;
    this.rtnFiscalIdFile.print = true;

    if (!(this.processedOrder.order.orderCondition != this.UNTIL_30_DAYS_FRANCHISED &&
      this.processedOrder.order.orderCondition != this.MORE_THAN_30_DAYS_FRANCHISED)) {
      this.purchaseOrder.print = true;
    }

    this.proofOfPaymentFile.print = true;
    this.purchaseOrderFile.print = true;
    this.ip800File.print = true;
    // this.registrationRefusal.print = true;
    // this.thirdPartyAuthoriztion.print = true;


  }
}
