import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ip800 } from '../models/ip800';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class Ip800Service {

  private url = `${environment.url}/ip800request`;

  constructor(private http: HttpClient) {
  }

  createIP800(ip800: Ip800): Observable<ResponseModel<Ip800>> {
    console.log(ip800);
    return this.http.post<ResponseModel<Ip800>>(this.url, ip800);
  }

  readIP800Requests(customerCode: string) {
    let params = new HttpParams().set("text", customerCode);
    return this.http.get<ResponseModel<Paging<Array<Ip800>>>>(this.url, { params });
  }

  updateIP800(ip800: Ip800) {
    return this.http.put<ResponseModel<Ip800>>(`${this.url}/${ip800.code}`, ip800);
  }
}
