export class BiometryValidationResponse {
    customerCode: string;
    valid: string;
    timestamp: string;
    user: string;
    errorMessage: string;


    constructor() {
        this.customerCode = "";
        this.valid = "";
        this.timestamp = "";
        this.user = "";
        this.errorMessage = "";
    }
}