export class Colony {
    city: string;
    code: string;
    country: string;
    dangerousness: string;
    dangerousnessCode: string;
    id: string;
    name: string;
    state: string;
    zone: string;

    constructor()
    {
        this.city="";
        this.code="";
        this.country="";
        this.dangerousness="";
        this.dangerousnessCode="";
        this.id="";
        this.name="";
        this.state="";
        this.zone="";
    }
}