import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Token } from '../models/token';
import { LocalStorageService } from './local-storage.service';
import { ResponseModel } from '../models/response-model';
import { User } from '../models/user';
import { Role, UserStatus } from '../@core/constants';
import { Roles } from '../models/role';
import { Paging } from '../models/page';
import { OnbaseService } from './onbase.service';
import { Menu } from '../models/menu';
import { CustomEncoder } from '../@core/encoders/custom-encoder';
import { JWTPayload, decodeJwt } from 'jose';
@Injectable()
export class AuthService {
  private url = `${environment.url}/jwt`;
  private currentSuperToken$: BehaviorSubject<string> = new BehaviorSubject(``);
  public currentToken = '';

  public get currentSuperTokenValue(): string {
    return this.currentSuperToken$.value;
  }

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  saveProfileInStorage(user: User) {
    this.localStorageService.set('user', JSON.stringify(user));
  }

  readProfileInStorage(): User {
    let user = this.localStorageService.get('user');
    return user ? (JSON.parse(user) as User) : new User();
  }

  readBranchCodesInStorage(): Set<string> {
    return this.readProfileInStorage().branchCodes;
  }

  readRolesInStorage(): Set<Role> {
    return new Set<Role>(this.readProfileInStorage().roles);
  }

  saveTokenInStorage(response: string) {
    let token = this.newToken(response, decodeJwt(response));
    this.localStorageService.set('access_token', JSON.stringify(token));
  }

  private newToken(token: string, payload: JWTPayload): Token {

    return { exp: payload.exp ?? 0, iat: payload.iat ?? 0, iss: payload.iss ?? "self", scope: payload.scope as string ?? "user", sub: payload.sub ?? "", token };
  }
  isAuthenticated() {
    return (
      new Date(this.readTokenFromStorage().exp * 1000).getTime() -
      Date.now() >
      5000
    );
  }

  readToken(username: string, password: string): Observable<string> {
    const basic = btoa(`${username}:${password}`);
    const headers = new HttpHeaders().append("Authorization", `Basic ${basic}`);
    return this.http.post<string>(`${this.url}/token`, {}, { headers });
  }

  readTokenFromStorage(): Token {
    let token = this.localStorageService.get('access_token');

    return token
      ? (JSON.parse(token) as Token)
      : {
        token: '',
        exp: 0,
        iat: 0,
        iss: "",
        scope: "",
        sub: ""
      };
  }

  async readProfile(): Promise<User> {
    return (
      await this.http
        .get<ResponseModel<User>>(`${environment.url}/profile`)
        .toPromise()
    ).response;
  }

  async readProfileFromAccessToken(accessToken: string): Promise<User> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${accessToken}`);

    return (
      await this.http
        .get<ResponseModel<User>>(`${environment.url}/profile`, { headers })
        .toPromise()
    ).response;
  }

  removeTokenFromStorage() {
    this.localStorageService.remove('access_token');
  }

  invalidateToken() {
    return this.http.post(`${this.url}/invalidateToken`, ``);
  }

  async readPrivileges(): Promise<Set<string>> {
    let params = new HttpParams();
    this.readProfileInStorage().roles.forEach((role) => {
      params = params
        //.set("permit", "FRONTPERMIT")
        .append('rols', role);
    });

    let privileges = (
      await this.http
        .get<ResponseModel<Paging<Array<Roles>>>>(`${environment.url}/roles`, {
          params,
        })
        .toPromise()
    ).response.content.flatMap((f) => f.privileges);
    return new Set<string>(privileges);
  }

  async readAccess(): Promise<Set<string>> {
    let params = new HttpParams();
    this.readProfileInStorage().roles.forEach((role) => {
      params = params
        //.set("permit", "FRONTPERMIT")
        .append('rols', role);
    });

    let accessList = (
      await this.http
        .get<ResponseModel<Paging<Array<Roles>>>>(`${environment.url}/roles`, {
          params,
        })
        .toPromise()
    ).response.content.flatMap((f) => f.accessList);
    return new Set<string>(accessList);
  }

  async readMenus(): Promise<Set<Menu>> {
    let params = new HttpParams();
    this.readProfileInStorage().roles.forEach((role) => {
      params = params
        //.set("permit", "FRONTPERMIT")
        .append('rols', role);
    });

    let menus = (
      await this.http
        .get<ResponseModel<Paging<Array<Roles>>>>(`${environment.url}/roles`, {
          params,
        })
        .toPromise()
    ).response.content.flatMap((f) => f.franchisedMenus);
    return new Set<Menu>(menus);
  }

  async readPrivilegesFromParameter(roles: Set<string>) {
    let params = new HttpParams();
    roles.forEach((role) => {
      params = params.append('rols', role);
    });

    let privileges = (
      await this.http
        .get<ResponseModel<Paging<Array<Roles>>>>(`${environment.url}/roles`, {
          params,
        })
        .toPromise()
    ).response.content.flatMap((f) => f.privileges);
    return new Set<string>(privileges);
  }

  async savePrivilegesInStorage(): Promise<void> {
    let privileges = Array.from(await this.readPrivileges());
    this.localStorageService.set('privileges', JSON.stringify(privileges));
  }

  async saveAccessInStorage(): Promise<void> {
    let accessList = Array.from(await this.readAccess());
    this.localStorageService.set('accessList', JSON.stringify(accessList));
  }

  async saveMenusInStorage(): Promise<void> {
    const setOfMenus = await this.readMenus();
    const menus = Array.from(setOfMenus);
    const uniqueMenus = Array.from(
      new Set(menus.map((m) => JSON.stringify(m)))
    ).map((x) => JSON.parse(x));
    this.localStorageService.set('menus', JSON.stringify(uniqueMenus));
  }

  readPrivilegesInStorage(): Set<string> {
    let privileges = this.localStorageService.get('privileges');
    return privileges
      ? new Set<string>(JSON.parse(privileges))
      : new Set<string>();
  }

  readAccessInStorage(): Set<string> {
    let accessList = this.localStorageService.get('accessList');
    return accessList
      ? new Set<string>(JSON.parse(accessList))
      : new Set<string>();
  }

  readMenusInStorage(): Set<Menu> {
    let menus = this.localStorageService.get('menus');
    return menus ? new Set<Menu>(JSON.parse(menus)) : new Set<Menu>();
  }

  hasPrivilege(privilege: string): boolean {
    return this.readPrivilegesInStorage().has(privilege);
  }

  async hasPrivilegesInRoles(
    roles: Set<string>,
    privileges: Set<string>
  ): Promise<boolean> {
    let privilegesFromRoles = await this.readPrivilegesFromParameter(roles);
    let existsAllPrivileges: boolean = true;
    Array.from(privileges).forEach((s) => {
      if (!Array.from(privilegesFromRoles).includes(s))
        existsAllPrivileges = false;
    });

    if (privileges == undefined || privileges.size == 0)
      existsAllPrivileges = false;

    return existsAllPrivileges;
  }

  changePassword(user: string, oldPassword: string, newPassword: string, idCaptcha: string, captcha: string) {
    const url = `${environment.url}/changePassword`;
    let params = new HttpParams({ encoder: new CustomEncoder() });

    params = params.appendAll({
      user,
      oldPassword,
      newPassword,
      idCaptcha,
      captcha
    });

    return this.http.post(url, null, { params });
  }

  changeForgetPassword(user: string, emailCodeForgetPassword: string, newPassword: string): any {
    const url = `${environment.url}/changeForgetPassword`;
    let params = new HttpParams({ encoder: new CustomEncoder() });

    params = params.appendAll({
      user,
      emailCodeForgetPassword,
      newPassword,
    });

    return this.http.post(url, null, { params });
  }

  sendEmailCodeForgetPassword(email: string, idCaptcha: string, captcha: string): any {
    const url = `${environment.url}/sendEmailCodeForgetPassword`;
    let params = new HttpParams({ encoder: new CustomEncoder() });

    params = params.appendAll({
      email,
      idCaptcha,
      captcha
    });

    return this.http.post(url, null, { params });
  }

  readRoles(roles: Set<string>): Promise<ResponseModel<Paging<Array<Roles>>>> {
    let params = new HttpParams({ encoder: new CustomEncoder() });
    roles.forEach((role) => {
      params = params.append('rols', role);
    });

    return this.http
      .get<ResponseModel<Paging<Array<Roles>>>>(`${environment.url}/roles`, {
        params,
      })
      .toPromise();
  }

  setSuperToken(superToken: string): void {
    this.currentSuperToken$.next(superToken);
  }

  async hasSomePrivilegeInRoles(
    roles: Set<string>,
    privileges: Set<string>
  ): Promise<boolean> {
    let privilegesFromRoles = await this.readPrivilegesFromParameter(roles);
    let existsOnePrivileges: boolean = false;
    Array.from(privileges).forEach((s) => {
      if (Array.from(privilegesFromRoles).includes(s))
        existsOnePrivileges = true;
    });

    if (privileges == undefined || privileges.size == 0)
      existsOnePrivileges = false;

    return existsOnePrivileges;
  }
}
