import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosStepperModule } from 'src/app/shared/pos-stepper/pos-stepper.module';
import { ModifySalesComponent } from './modify-sales.component';
import { CustomerStepModule } from '../customer-step/customer-step.module';
import { CartStepModule } from '../cart-step/cart-step.module';
import { DocumentsStepModule } from '../documents-step/documents-step.module';
import { SaveStepModule } from '../save-step/save-step.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ReactiveFormsModule } from '@angular/forms';
import { HasPrivilegeModule } from 'src/app/@core/directives/has-privilege/has-privilege.module';
import { OrderTypeStepModule } from '../order-type-step/order-type-step.module';
import { CreditCalculatorStepModule } from '../credit-calculator-step/credit-calculator-step.module';


@NgModule({
  declarations: [ModifySalesComponent],
  imports: [
    CommonModule,
    PosStepperModule,
    CustomerStepModule,
    CartStepModule,
    DocumentsStepModule,
    SaveStepModule,
    CdkStepperModule,
    ReactiveFormsModule,
    HasPrivilegeModule, 
    OrderTypeStepModule,
    CreditCalculatorStepModule
  ],
  exports: [ModifySalesComponent]
})
export class ModifySalesModule { }
