import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPrivilegeDirective } from './has-privilege.directive';



@NgModule({
  declarations: [HasPrivilegeDirective],
  imports: [
    CommonModule
  ],
  exports: [HasPrivilegeDirective]
})
export class HasPrivilegeModule { }
