import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditInvoiceDetailsStepComponent } from './credit-invoice-details-step.component';
import { PosTotalsModule } from 'src/app/shared/pos-totals/pos-totals.module';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { PosPaymentMethodModule } from 'src/app/shared/pos-payment-method/pos-payment-method.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';



@NgModule({
  declarations: [CreditInvoiceDetailsStepComponent],
  imports: [
    CommonModule,
    PosTotalsModule,
    PosTableModule,
    PosPaymentMethodModule,
    AccordionModule
  ],
  exports: [CreditInvoiceDetailsStepComponent]
})
export class CreditInvoiceDetailsStepModule { }
