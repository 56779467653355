import { InvoiceRequestModel } from "./InvoiceRequestModel";
import { LoanRequestModel } from "./LoanRequestModel";
import { PaymentsRequestModel } from "./PaymentRequestModel";

export class PaymentTransactionRequestModel {
    orderCode: string;
    branchCode: string;
    registerCode: string;
    replicateInExternalSystem: boolean;
    usableAsPayment: boolean;
    loanCode: string;
    observations: string;
    downPaymentInvoice: number;
    invoiceDetails: Array<InvoiceRequestModel>;
    loanRequestModel: LoanRequestModel;
    chargeNotePaymentsRequestModel: PaymentsRequestModel;
    paymentsRequestModel: PaymentsRequestModel;
    debitNote: boolean;
    paymentResponsible: String;
    paymentResponsibleName: String;
    isForDelivery: boolean;
    deliveryBranchCode: string;
    id?: string;
    constructor() {
        this.orderCode = "";
        this.branchCode = "";
        this.registerCode = "";
        this.replicateInExternalSystem = false;
        this.usableAsPayment = false;
        this.loanCode = "";
        this.observations = "";
        this.downPaymentInvoice = 0;
        this.debitNote = false;
        this.invoiceDetails = Array<InvoiceRequestModel>();
        this.loanRequestModel = new LoanRequestModel();
        this.chargeNotePaymentsRequestModel = new PaymentsRequestModel();
        this.paymentsRequestModel = new PaymentsRequestModel();
        this.paymentResponsible = "";
        this.paymentResponsibleName = "";
        this.isForDelivery = false;
        this.deliveryBranchCode = "";
    }
}