import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCreditCalendarComponent } from './pos-credit-calendar.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoanAvailableDaysService } from 'src/app/services/loan-available-days.service';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [PosCreditCalendarComponent],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule
  ],
  exports: [PosCreditCalendarComponent],
  providers: [LoanAvailableDaysService]
})
export class PosCreditCalendarModule { }
