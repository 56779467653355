import { FastActionsButtonsComponent } from './fast-actions-buttons/fast-actions-buttons.component';
import { CreditPlanRequirementsListModalComponent } from './credit-plan-requirements-list-modal/credit-plan-requirements-list-modal.component';
import { CustomerCreditPlansComponent } from './customer-credit-plans/customer-credit-plans.component';
import { CustomerCreditRequestStepperComponent } from './customer-credit-request-stepper/customer-credit-request-stepper.component';
import { CustomerIdentificationComponent } from './customer-identification/customer-identification.component';
import { CustomerInformationComponent } from './customer-information/customer-information.component';
import { CustomerPreApprovalFormComponent } from './customer-pre-approval-form/customer-pre-approval-form.component';
import { PosCustomerComponent } from './pos-customer/pos-customer.component';
import { CustomerLoansActiveComponent } from './customer-loans-active/customer-loans-active.component';
import { CustomerCreditNotesComponent } from './customer-credit-notes/customer-credit-notes.component';
import { CustomerCreditRequestInProcessComponent } from './customer-credit-request-in-process/customer-credit-request-in-process.component';
import { CustomerAdvancesComponent } from './customer-advances/customer-advances.component';
import { CustomerInformationDetailComponent } from './customer-information-detail/customer-information-detail.component';
import { CustomerPhoneAndMailComponent } from './customer-phone-and-mail/customer-phone-and-mail.component';
import { CustomerVerifyPhoneAndEmailComponent } from './customer-verify-phone-and-email/customer-verify-phone-and-email.component';

export const components = [
  CustomerIdentificationComponent,
  CustomerInformationComponent,
  CustomerCreditRequestStepperComponent,
  CustomerPreApprovalFormComponent,
  PosCustomerComponent,
  CustomerCreditPlansComponent,
  CustomerLoansActiveComponent,
  CustomerCreditNotesComponent,
  CustomerCreditRequestInProcessComponent,
  CreditPlanRequirementsListModalComponent,
  FastActionsButtonsComponent,
  CustomerAdvancesComponent,
  CustomerPhoneAndMailComponent,
  CustomerInformationDetailComponent,
  CustomerVerifyPhoneAndEmailComponent
];

export * from './../components/customer-identification/customer-identification.component';
export * from './customer-credit-plans/customer-credit-plans.component';
export * from './customer-credit-request-stepper/customer-credit-request-stepper.component';
export * from './customer-information/customer-information.component';
export * from './customer-pre-approval-form/customer-pre-approval-form.component';
export * from './pos-customer/pos-customer.component';
export * from './customer-loans-active/customer-loans-active.component';
export * from './customer-credit-notes/customer-credit-notes.component';
export * from './customer-credit-request-in-process/customer-credit-request-in-process.component';
export * from './credit-plan-requirements-list-modal/credit-plan-requirements-list-modal.component';
export * from './fast-actions-buttons/fast-actions-buttons.component';
export * from './customer-advances/customer-advances.component';
export * from './customer-phone-and-mail/customer-phone-and-mail.component';
export * from './customer-information-detail/customer-information-detail.component';
export * from './customer-verify-phone-and-email/customer-verify-phone-and-email.component';
