<div class="">
  <pos-totals [totals]="orderSimulate.totals"></pos-totals>
  <div class="row">

    <div class="col-12 col-sm-6 col-md-8">
      <pos-table tableFormId="productsCartTable" [data]="productsCart" [headers]="getProductCartHeaders()"
        [filters]="[]" [itemsPerPage]="10" [pagination]="false" (dataSave)="saveProductCart($event)"
        (getTableForm)="getTableForm($event)" [disableHighlight]="true">

      </pos-table>
    </div>
    <div class="col-6 col-md-4">
      <tabset>
        <tab heading="Productos">
          <pos-list [data]="products" [filters]="getProductFilters()"
            [config]="{title:'code',description:'description',smallTitle:'total',badge:'stock'}" [itemsPerPage]="5"
            (dataChange)="getProductData($event)" (rowClick)="productRowClick($event)"></pos-list>
        </tab>
        <tab heading="Promociones">
          <button class="btn btn-primary mb-2" type="button" (click)="showPromotionList()">Todas las
            Promociones</button>
          <tabset>
            <tab heading="Potenciales">
              <pos-list [data]="promotions" [config]="{title:'code',description:'description'}" [itemsPerPage]="5"
                (dataChange)="getPromotionData($event)" (rowClick)="promotionRowClick($event)"
                [filters]="getPromotionTableFilters()">
              </pos-list>
            </tab>
            <tab heading="Activas">
              <pos-list [data]="activePromotions" [config]="{title:'code',description:'description'}" [itemsPerPage]="5"
                (dataChange)="getPromotionData($event)" (rowClick)="removePromotionRowClick($event)"
                [pagination]="false">
              </pos-list>

            </tab>
          </tabset>


        </tab>

      </tabset>
    </div>
    <!-- <button type="button" (click)="test()" class="btn btn-primary"></button> -->
  </div>
</div>
