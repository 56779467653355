<div class="container mb-3">
  <!-- row para texto -->
  <div class="row ">
    <div class="col-md-12 col-sm-12">
      <div [ngSwitch]="verifyTokenType">
        <ng-template [ngSwitchCase]="'PHONE'">
          <p class="m-0">Verificación de Token Telefono Celular</p>
          <p>{{ this.customerDataToVerify?.phone?.phone_number || "" }}</p>
        </ng-template>
        <ng-template [ngSwitchCase]="'EMAIL'">
          <p class="m-0">Verificación de Token Correo Electronico</p>
          <p>{{ this.customerDataToVerify?.email?.email || "" }}</p>
        </ng-template>
      </div>
    </div>
  </div>
  <!-- row para inputs y botones -->
  <div class="row">
    <div class="col-md-7 col-sm-12">
      <form [formGroup]="tokenForm" class="d-flex">
        <ng-container *ngFor="let control of getControls(); let i = index">
          <input type="number" class="form-control mx-1 text-center" [formControlName]="i" (input)="onInput($event, i)"
            (keydown)="onKeyPress($event, i)" maxlength="1" />
        </ng-container>
      </form>
      <small *ngIf="hasRequestToken && timeRemaining > 0" class="form-text">
        ¿No se ha recibido el token? Espera <b>{{formatTimeRemaining()}}</b> para solicitar el reenvío.
      </small>

      <small *ngIf="hasRequestToken && timeRemaining <= 0" class="form-text">
        <span>
          ¿No se ha recibido el token?
        </span>
        <span style="cursor: pointer; text-decoration: underline; color: #20a8d8" (click)="resendToken()">
          <b>
            solicitar el reenvío
          </b>
        </span>
        <span>.</span>
      </small>
    </div>
    <div class="col-md-5 col-sm-12">
      <div class="d-flex" style="justify-content: space-between">
        <button [disabled]="hasRequestToken" type="button" class="btn btn-secondary" (click)="sendToken()">
          Enviar Token
        </button>
        <button [disabled]="hasVerifyToken" type="submit" class="btn btn-primary" (click)="verifyToken()">
          Verificar
        </button>
      </div>
    </div>
  </div>
</div>
