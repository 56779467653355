<!-- Modal -->
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      Actualizar Datos de Cliente {{customer.fullName}}
    </h5>
    <div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <app-pos-customer-update
      [showBack]="false"
      [redirect]="false"
      [showHeader]="false"
      [customer]="customer"
      [customerVerifyData]="customerVerifyData"
      (updateCompleted)="handleCompleteUpdate($event)"
    ></app-pos-customer-update>
  </div>
</div>
