import { Injectable } from '@angular/core';
import { CreditProcessStatus, CreditStatus, ModalDialogType } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { CreditRequest } from 'src/app/models/credit-request';
import { LoanService } from 'src/app/services/loan.service';
import { OnbaseService } from 'src/app/services/onbase.service';
import { OpenDocumentService } from 'src/app/services/open-document.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerValidationsService {
  constructor(private confirmDialogService: PosConfirmationService,
    private loanService: LoanService,
    private onbaseService: OnbaseService,
    private openDocumentService: OpenDocumentService) { }

  public async validateCustomerInDefault(customerCode: string) {
    const delayedAmountByContract = await this.calcDelayedAmountByContract(customerCode);
    const request = await this.loanService
      .getCurrentCreditsByCustomer(customerCode)
      .toPromise();

    if (request.response) {
      const loans = request.response.filter(l => l.contractNumber).map(l => {
        let loan = { ...l };

        const contract = delayedAmountByContract.find((ct: any) => ct.contractNumber === loan.contractNumber);
        loan.delayedAmount = contract
          ? contract.delayedAmount
          : 0;

        return loan;
      });
      const isCustomerInDefault = loans.some(el => el.delayedAmount > 0);

      if (isCustomerInDefault) {
        let result = await this.confirmDialogService.showAsync(
          "Cliente en mora",
          "El cliente esta actualmente en mora. ¿Deseas continuar realizando el proceso?",
          ModalDialogType.WARNING
        );

        return result;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  private async calcDelayedAmountByContract(customerCode: string) {
    const today = new Date();

    const request = await this.openDocumentService.getOpenDocumentsByCustomer(customerCode).toPromise();
    let documents = request.response ? request.response : [];

    documents = documents.filter(document => document.contractNumber != undefined
      && this.getDocDate(document.date.toString()).getTime() < today.getTime()
      && document.documentType !== "CN"
      && document.documentType !== "PE");

    const delayedAmountByContract = documents.reduce((acc, act) => {
      const { contractNumber, amount } = act;
      const index = acc.findIndex((ct: any) => ct.contractNumber === contractNumber);
      if (index > -1) {
        acc[index].delayedAmount += +amount;
      }
      else {
        act.delayedAmount = +act.amount;
        acc = [...acc, { contractNumber: act.contractNumber, delayedAmount: act.delayedAmount }];
      }
      return acc;
    }, []);

    return delayedAmountByContract;
  }

  private getDocDate(date: string): Date {
    const year = +date.substring(0, 4);
    const month = +date.substring(4, 6);
    const day = +date.substring(6);
    return new Date(year, month - 1, day);
  }

  async validateOnBaseCreditStatus(customerCode: string) {
    const requests = await this.onbaseService
      .readOnbaseRequestByCustomerCode(customerCode)
      .toPromise();
    const requestInProcess = requests.response.content.filter(f => f.procedure != "0011").find(s => s.processingStatus == CreditProcessStatus.PROCESSED && s.status.some(x => x != CreditStatus.APPROVED && x != CreditStatus.REJECTED));
    const requestDrafted = requests.response.content.filter(f => f.procedure != "0011").find(s => s.processingStatus == CreditProcessStatus.DRAFT);
    const requestRejected = requests.response.content.filter(f => f.procedure != "0011").find(s => s.status.some(x => x == CreditStatus.REJECTED));
    if (requestInProcess) {
      await this.confirmDialogService.showAsync('Cliente con solicitud en proceso',
        `Existe una solicitud de crédito en proceso en la Empresa ${requestInProcess.requestData.Empresa
        } y sucursal ${requestInProcess.requestData.Division}`, ModalDialogType.WARNING);
      return false;
    }
    if (requestRejected) {
      let result = await this.confirmDialogService.showAsync('Cliente con Solicitudes Rechazadas',
        `Existe una solicitud de crédito con status final que ha sido rechazada en la Empresa ${requestRejected.requestData.Empresa
        } y sucursal ${requestRejected.requestData.Division} la razon del dictamen final fue: ${requestRejected.comments.join(' - ')}`, ModalDialogType.WARNING);
      return result;
    }
    if (requestDrafted) {
      let result = await this.confirmDialogService.showAsync('Cliente con solicitud en proceso',
        `Existe una solicitud de crédito en proceso con estado borrador en la Empresa ${requestDrafted.requestData.Empresa
        } y sucursal ${requestDrafted.requestData.Division}`, ModalDialogType.WARNING);
      return result;
    }


    return true;
  }
}
