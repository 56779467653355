import { Location } from '@angular/common';
import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  CountryCR,
  CountryHN,
  CountrySV,
  CR_FISCAL_TYPE_CODE,
  CurrentCountry,
  HN_FISCAL_TYPE_CODE,
  MIN_LENGTH_HN,
  ParamPhoneMaskHn,
  ParamPhoneMaskNoHn,
  Personality,
  phoneMinimumCharacters,
  REGEX,
  SV_FISCAL_TYPE_CODE,
} from 'src/app/@core/constants';
import { Customer } from 'src/app/models/customer';
import { FiscalIdTypes } from 'src/app/models/fiscalIdTypes';
import { Nationality } from 'src/app/models/nationality';
import { CreateCustomerRequestModel } from 'src/app/models/request/createCustomerRequestModel';
import { CustomerCreateResponseModel } from 'src/app/models/response/customerCreateResponseModel';
import { ResponseFormAddress } from 'src/app/models/responseFormAddress';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { CustomerService } from 'src/app/services/customer.service';
import { FiscalIdService } from 'src/app/services/fiscal-id.service';
import { NationalityService } from 'src/app/services/nationality.service';
import { ParamsService } from 'src/app/services/params.service';
import { PosCustomerCreateAddressComponent } from '../pos-customer-create-address/pos-customer-create-address.component';

@Component({
  selector: 'app-pos-customer-create-legal-entity',
  templateUrl: './pos-customer-create-legal-entity.component.html',
  styleUrls: ['./pos-customer-create-legal-entity.component.sass'],
})
export class PosCustomerCreateLegalEntityComponent implements OnInit {

  @Input() showBack = true;

  legalEntityForm: FormGroup = new FormGroup({});
  nationalities: Array<Nationality> = new Array<Nationality>();
  fiscalIdTypesToShow: Array<FiscalIdTypes> = new Array<FiscalIdTypes>();
  fiscalIdTypes: Array<FiscalIdTypes> = new Array<FiscalIdTypes>();
  maskRTN = '';
  minLengthRTN = 0;
  phoneMaskHn = '';
  phoneMaskNoHn = '';
  phoneMask = '';
  countryResidenceMinLength = 0;
  minNumber = 0;
  created = false;
  customerCreated: CustomerCreateResponseModel =
    new CustomerCreateResponseModel();
  private REGION_CODE = '504';
  private HN_NUMBER = 9;
  private OTHER_NUMBER = 15;
  @ViewChild('FormAddressCustomerLegalEntity')
  formAddressCustomerLegalEntity!: PosCustomerCreateAddressComponent;

  @Output()
  saveCustomerEmitter = new EventEmitter<CustomerTableModel>();

  constructor(
    private nationalityServices: NationalityService,
    private paramsService: ParamsService,
    private toastrService: ToastrService,
    private customerService: CustomerService,
    private fiscalIdService: FiscalIdService,
    private router: Router,
    private location: Location
  ) { }

  @Input()
  redirect: boolean = true;
  ngOnInit(): void {
    this.legalEntityForm = new FormGroup({
      nationality: new FormControl(''),
      fiscalType: new FormControl(null, Validators.required),
      rtn: new FormControl('', Validators.required),
      legalName: new FormControl('', Validators.required),
      legalName2: new FormControl(''),
      legalName3: new FormControl(''),
      legalName4: new FormControl(''),
      countryResidence: new FormControl(''),
      countryCode: new FormControl(this.REGION_CODE),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(MIN_LENGTH_HN),
      ]),
      firstName: new FormControl('', Validators.required),
      secondName: new FormControl(''),
      firstLastName: new FormControl('', Validators.required),
      secondLastName: new FormControl(''),
      relationship: new FormControl(null, Validators.required),
      contactPhone: new FormControl(''),
      contactEmail: new FormControl('', Validators.pattern(REGEX.email)),
    });

    this.listenControlChanges();
    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    this.nationalities = (
      await this.nationalityServices.readNationalities().toPromise()
    ).response.content;
    this.nationalities = this.nationalities.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    this.legalEntityForm.get('nationality')?.patchValue(CurrentCountry);
    this.legalEntityForm.get('countryResidence')?.patchValue(CurrentCountry);
    this.getFiscalIds(CurrentCountry as string);

    const params = (await this.paramsService.readParams().toPromise()).response;
    this.phoneMaskHn = params[ParamPhoneMaskHn];
    this.phoneMaskNoHn = params[ParamPhoneMaskNoHn];
    this.countryResidenceMinLength = Number(params[phoneMinimumCharacters]);
    this.legalEntityForm
      .get('phone')
      ?.setValidators([
        Validators.required,
        Validators.minLength(this.countryResidenceMinLength),
      ]);
    this.addMaskInput(CurrentCountry as string);

    // Fix para colocar borde rojo a los select
    this.legalEntityForm.get('relationship')?.markAsDirty();
    this.legalEntityForm.get('fiscalType')?.markAsDirty();
  }

  changeNationality(nationalityCode: string): void {
    this.legalEntityForm.get('fiscalType')?.patchValue('');
    this.getFiscalIds(nationalityCode);
    this.legalEntityForm.get('rtn')?.patchValue('');
    this.maskRTN = '';
  }

  changeFiscalIdType(code: string): void {
    const type = this.fiscalIdTypesToShow.find(
      (element) => element.code === code
    );
    if (type) {
      const nationality = this.legalEntityForm.get('nationality')?.value;
      const minLength =
        nationality === CountryHN ||
          nationality === CountrySV ||
          nationality === CountryCR
          ? type.minLength
          : 0;
      const control = this.legalEntityForm.get('rtn');
      control?.clearValidators();
      control?.setValidators([
        Validators.required,
        Validators.minLength(minLength),
      ]);
      this.minLengthRTN = minLength;
      this.maskRTN = type.mask ?? '';
    }
  }

  changeCountryResidence(nationalityCode: string): void {
    this.addMaskInput(nationalityCode);
  }

  addMaskInput(countryCode: string): void {
    this.legalEntityForm.get('phone')?.patchValue('');
    this.legalEntityForm.get('contactPhone')?.patchValue('');
    const codeControl = this.legalEntityForm.get('countryCode');
    if (countryCode === CountryHN) {
      this.phoneMask = this.phoneMaskHn;
      this.minNumber = this.HN_NUMBER;
      codeControl?.patchValue(this.REGION_CODE);
    } else {
      this.phoneMask = this.phoneMaskNoHn;
      this.minNumber = this.OTHER_NUMBER;
      codeControl?.patchValue('');
    }
  }

  async saveCustomerLegalEntity(): Promise<void> {
    if (
      this.legalEntityForm.valid &&
      this.formAddressCustomerLegalEntity.formCustomerAddress.valid
    ) {
      const request: CreateCustomerRequestModel = this.createCustomerObject();

      this.customerCreated = (
        await this.customerService.createCustomer(request).toPromise()
      ).response;
      this.created = true;
      this.toastrService.success('Cliente creado correctamente');

      this.legalEntityForm.reset();
      this.formAddressCustomerLegalEntity.formCustomerAddress.reset();
      this.fetchData();
      this.saveCustomerOutput();
      if (this.redirect) {
        this.router.navigate(
          ['/customer', this.customerCreated.code],
          { replaceUrl: true }
        );
      }
    } else {
      this.toastrService.warning('Ingrese todos los campos obligatorios');
    }
  }
  private saveCustomerOutput() {
    let customerObject = this.customerCreated as unknown as Customer;
    let result = new CustomerTableModel();
    result.code = customerObject.code;
    result.customerType = customerObject.customerType;
    result.fiscalId = customerObject.personalInfo[0].fiscalId
    result.fiscalId2 = customerObject.personalInfo[0].fiscalId2;
    result.fullName = customerObject.fullName;
    result.personality = customerObject.personalInfo[0].personality;
    result.useFiscalId2 = false;
    this.saveCustomerEmitter.emit(result);
  }
  private async getFiscalIds(nationalityCode: string): Promise<void> {
    this.fiscalIdTypes = (
      await this.fiscalIdService.readFiscalIds().toPromise()
    ).response;

    if (nationalityCode === CountrySV) {
      this.fiscalIdTypesToShow = this.fiscalIdTypes.filter(
        (x) => x.code === SV_FISCAL_TYPE_CODE && x.country === nationalityCode
      );
    } else if (nationalityCode === CountryCR) {
      this.fiscalIdTypesToShow = this.fiscalIdTypes.filter(
        (x) => x.code === CR_FISCAL_TYPE_CODE && x.country === nationalityCode
      );
    } else {
      this.fiscalIdTypesToShow = this.fiscalIdTypes.filter(
        (x) => x.code === HN_FISCAL_TYPE_CODE && x.country === CountryHN
      );
    }
  }

  createCustomerObject(): CreateCustomerRequestModel {
    const formValue = this.legalEntityForm.value;
    const customer = new CreateCustomerRequestModel();
    customer.contactEmail = formValue.contactEmail.toUpperCase();
    customer.contactPhone = formValue.contactPhone;
    customer.contactRelationship = formValue.relationship;
    customer.contactName = formValue.firstName.toUpperCase();
    customer.contactMiddleName = formValue.secondName.toUpperCase();
    customer.contactLastName = formValue.firstLastName.toUpperCase();
    customer.contactLastName2 = formValue.secondLastName.toUpperCase();
    customer.orgPhone = formValue.phone;
    customer.countryId = formValue.countryResidence;
    customer.orgName1 = formValue.legalName.toUpperCase();
    customer.orgName2 = formValue.legalName2.toUpperCase();
    customer.orgName3 = formValue.legalName3.toUpperCase();
    customer.orgName4 = formValue.legalName4.toUpperCase();
    customer.fiscalId = formValue.rtn;
    customer.fiscalIdType = formValue.fiscalType;
    customer.personality = Personality.LEGAL_ENTITY;
    customer.nationality = formValue.nationality;

    const addressForm: ResponseFormAddress =
      this.formAddressCustomerLegalEntity.mapRequestAddress();
    customer.municipality = addressForm.municipality?.toUpperCase();
    customer.municipalityName = addressForm.municipalityName?.toUpperCase();
    customer.neighborhood = addressForm.neighborhood?.toUpperCase();
    customer.neighborhoodName = addressForm.neighborhoodName?.toUpperCase();
    customer.street = addressForm.street?.toUpperCase();
    customer.street2 = addressForm.street2?.toUpperCase();
    customer.street3 = addressForm.street3?.toUpperCase();
    customer.street4 = addressForm.street4?.toUpperCase();
    customer.street5 = addressForm.street5?.toUpperCase();
    customer.department = addressForm.department?.toUpperCase();
    customer.addressCountryId = addressForm.addressCountryId?.toUpperCase();

    return customer;
  }

  goBack() {
    this.location.back();
  }

  private listenControlChanges() {

    const firstName = this.legalEntityForm.get('firstName')!;
    const secondName = this.legalEntityForm.get('secondName')!;
    const firstLastName = this.legalEntityForm.get('firstLastName')!;
    const secondLastName = this.legalEntityForm.get('secondLastName')!;

    firstName.valueChanges.subscribe(() => {
      this.controlOnlyLetters(firstName);
    });
    secondName.valueChanges.subscribe(() => {
      this.controlOnlyLetters(secondName);
    });
    firstLastName.valueChanges.subscribe(() => {
      this.controlOnlyLetters(firstLastName);
    });
    secondLastName.valueChanges.subscribe(() => {
      this.controlOnlyLetters(secondLastName);
    });
  }

  private controlOnlyLetters(control: AbstractControl) {
    const value: string = control.value;
    if (!value.match(/^[a-zA-Z]+$/)) {
      control.patchValue(value.replace(/[\d]+/g, ''), { emitEvent: false });
    }
  }
}
