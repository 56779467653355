import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DiscountType, ModalDialogType } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { Discount } from 'src/app/models/discount';

@Component({
  selector: 'pos-discount-modal',
  templateUrl: './pos-discount-modal.component.html',
  styleUrls: ['./pos-discount-modal.component.sass']
})
export class PosDiscountModalComponent implements OnInit {

  discount: Discount = new Discount();
  private confirmAction: (value: Discount) => any = (value: Discount) => { };

  discountForm: FormGroup = this.formBuilder.group(
    {
      discountType: new FormControl("", Validators.required),
      discountValue: new FormControl("", Validators.min(1))
    }
  );
  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private confirmDialogService: PosConfirmationService) {
  }

  ngOnInit(): void {
    this.changeDiscountValueStatus();
  }

  private changeDiscountValueStatus() {
    (this.discountForm.get("discountType")?.value == DiscountType.NONE.toString() || this.discountForm.get("discountType")?.value == "") ?
      this.discountForm.get("discountValue")?.disable() :
      this.discountForm.get("discountValue")?.enable();


    //this.discountForm.get("discountValue")?.markAsTouched();
  }

  confirm() {
    this.discount = Discount.build(this.discountForm.get("discountType")?.value, this.discountForm.get("discountValue")?.value, "");
    this.confirmAction(this.discount);
    this.bsModalRef.hide();
    //this.discountForm.
  }

  cancel() {
    this.bsModalRef.hide();
  }

  async remove(): Promise<void> {
    await this.confirmDialogService.show("¡Advertencia!", "¿Está seguro que desea eliminar la rebaja para esta orden?", ModalDialogType.DANGER, () => {

      this.discount = new Discount();
      this.confirmAction(this.discount);
      this.bsModalRef.hide();
    });
  }

  changeType() {
    let type = this.discountForm.get("discountType")?.value;
    let discountValue = this.discountForm.get("discountValue")?.value;
    this.changeDiscountValueStatus();
    this.discountForm = this.formBuilder.group(
      {
        discountType: new FormControl(type, Validators.required),
        discountValue: new FormControl(discountValue, this.getdiscountValueValidator(type))
      }
    );
  }

  load() {
    this.discountForm.get("discountType")?.setValue(this.discount.discountType);
    this.discountForm.get("discountValue")?.setValue(this.discount.discountValue);
    this.changeType();
  }
  getdiscountValueValidator(type: DiscountType): ValidatorFn | ValidatorFn[] {

    if (type == DiscountType.PERCENTAGE) {
      return [Validators.min(1), Validators.max(100), Validators.required];
    }
    else if (type == DiscountType.ABSOLUTE) {
      return [Validators.min(1), Validators.required];
    }
    else {
      return [Validators.min(1), Validators.required];
    }
  }
}
