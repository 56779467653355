import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Param } from '../models/param';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  private url = `${environment.url}/params`;
  constructor(private http: HttpClient) { }

  readParam(code: string): Observable<ResponseModel<Param>> {
    return this.http.get<ResponseModel<Param>>(`${this.url}/${code}`);
  }
  readParams(): Observable<ResponseModel<any>> {
    return this.http.get<ResponseModel<any>>(this.url);
  }
}
