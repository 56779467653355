import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HasPrivilegeModule } from 'src/app/@core/directives/has-privilege/has-privilege.module';
import { PosConfirmationModule } from 'src/app/shared/pos-confirmation/pos-confirmation.module';
import { PosCreditCalendarModule } from 'src/app/shared/pos-credit-calendar/pos-credit-calendar.module';
import { PosDiscountModalModule } from 'src/app/shared/pos-discount-modal/pos-discount-modal.module';
import { PosExemptionModalModule } from 'src/app/shared/pos-exemption-modal/pos-exemption-modal.module';
import { PosStepperModule } from 'src/app/shared/pos-stepper/pos-stepper.module';
import { PosSupervisorLoginModule } from 'src/app/shared/pos-supervisor-login/pos-supervisor-login.module';
import { CartStepModule } from '../cart-step/cart-step.module';
import { CreditConditionStepModule } from '../credit-condition-step/credit-condition-step.module';
import { CustomerStepModule } from '../customer-step/customer-step.module';
import { DocumentsStepModule } from '../documents-step/documents-step.module';
import { OrderTypeStepModule } from '../order-type-step/order-type-step.module';
import { SaveStepModule } from '../save-step/save-step.module';
import { PosTableModule } from './../../../shared/pos-table/pos-table.module';
import { CreditCalculatorStepComponent } from './credit-calculator-step.component';

@NgModule({
  declarations: [CreditCalculatorStepComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    CdkStepperModule,
    PosStepperModule,
    PosCreditCalendarModule,
    PosConfirmationModule,
    PosSupervisorLoginModule,
    CustomerStepModule,
    CartStepModule,
    PosExemptionModalModule,
    PosDiscountModalModule,
    HasPrivilegeModule,
    CreditConditionStepModule,
    DocumentsStepModule,
    SaveStepModule,
    OrderTypeStepModule,
    AccordionModule.forRoot(),
    PosTableModule,
    NgSelectModule
  ],
  exports: [CreditCalculatorStepComponent],
})
export class CreditCalculatorStepModule {}
