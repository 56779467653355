import { LinkCategory } from "../@core/constants";
import { Condition } from "./condition";
import { Discount } from "./discount";

export class Get {
    linkCategory: LinkCategory;
    totalDiscount: Discount;
    conditions: Array<Condition>;

    constructor() {
        this.linkCategory = LinkCategory.AND;
        this.totalDiscount = new Discount();
        this.conditions = [];
    }
}