import { Component, Input, OnInit } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table';
import { Paging } from 'src/app/models/page';

@Component({
  selector: 'pos-payment-details',
  templateUrl: './pos-payment-details.component.html',
  styleUrls: ['./pos-payment-details.component.sass']
})
export class PosPaymentDetailsComponent implements OnInit {

  
  
  @Input() payments = new Paging<Array<{ name: string, amount: number, identifier: string }>>([]);

  constructor() { }

  async ngOnInit(): Promise<void> {
  }

  getPaymentHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('name', 'Tipo Pago'),
      new ColumnTable('amount', 'Total', { type: 'currency' }),
      new ColumnTable('identifier', 'Identificador')
    ];
  }
}
