import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomerType, Personality } from 'src/app/@core/constants';
import { PosCreateCustomerModalService } from 'src/app/@core/services/pos-create-customer-modal.service';
import { PosUpdateRtnFiscalIdModalService } from 'src/app/@core/services/pos-update-rtn-fiscal-id-modal.service';
import { ColumnTable } from 'src/app/models/column-table';
import { Filter } from 'src/app/models/filter';
import { FilterTable } from 'src/app/models/filter-table';
import { CustomerFilter } from 'src/app/models/filters/customer-filter';
import { EmployeeFilter } from 'src/app/models/filters/employee-filter';
import { Paging } from 'src/app/models/page';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'customer-step',
  templateUrl: './customer-step.component.html',
  styleUrls: ['./customer-step.component.sass']
})
export class CustomerStepComponent implements OnInit {

  @Input() formCustomer: FormGroup = this.formBuilder.group({
    customerFullName: "",
    customerCode: "",
    sellerCode: "",
    sellerName: "",
    rtnFiscalId: ""
  });

  @Input()
  customer: CustomerTableModel = new CustomerTableModel();

  private employee: CustomerTableModel = new CustomerTableModel();

  @Output()
  getCustomerStepInfo = new EventEmitter<any>();
  constructor(
    private formBuilder: FormBuilder,
    private rtnFiscalIdService: PosUpdateRtnFiscalIdModalService,
    private createCustomerModalService: PosCreateCustomerModalService,
    private authService: AuthService) {
    this.formCustomer.get("rtnFiscalId")?.markAsDirty();
    this.formCustomer.get("rtnFiscalId")?.markAsTouched();
  }

  async ngOnInit(): Promise<void> {
    this.formCustomer.get("customerFullName")?.disable();
    this.formCustomer.get("customerCode")?.disable();
    this.formCustomer.get("sellerCode")?.disable();
    this.formCustomer.get("sellerName")?.disable();
    this.formCustomer.get("rtnFiscalId")?.disable();

  }

  selectCustomer(customer: CustomerTableModel) {
    this.customer = customer;
    this.formCustomer.get("customerFullName")?.setValue(customer.fullName);
    this.formCustomer.get("customerFullName")?.markAsDirty();
    this.selectEmployee();
    this.formCustomer.get("rtnFiscalId")?.setValue("");
    this.getCustomerStepInfo.emit({ customer: this.customer, employee: this.employee })
  }


  selectEmployee() {

    this.employee.fullName = this.authService.readProfileInStorage().fullname;
    this.employee.code = this.authService.readProfileInStorage().username;
    this.formCustomer.get("sellerName")?.setValue(this.employee.fullName);
    this.formCustomer.get("sellerName")?.markAsDirty();
    //this.getCustomerStepInfo.emit({ customer: this.customer, employee: this.employee })
  }

  disableRtnButton() {
    return this.formCustomer.get("customerFullName")?.pristine || this.customer.personality == Personality.LEGAL_ENTITY;
  }

  async updateRtnFiscalId() {
    await this.rtnFiscalIdService.show(this.customer, (customer: CustomerTableModel) => {
      this.setRtnFiscalId(customer);

    })
  }

  private setRtnFiscalId(customer: CustomerTableModel) {
    this.customer.useFiscalId2 = true;
    this.formCustomer.get("rtnFiscalId")?.setValue(customer.fiscalId2);
  }

  async createCustomer() {
    await this.createCustomerModalService.show((customer: CustomerTableModel) => {
      this.selectCustomer(customer);
    });
  }
}
