import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PosReasonSelectModalComponent } from 'src/app/shared/pos-reason-select-modal/pos-reason-select-modal.component';
import { ModalDialogType } from '../constants';
import { CatalogsResponse } from 'src/app/models/catalogs';

@Injectable({
  providedIn: 'root',
})
export class PosReasonSelectModalService {
  private modalRef?: BsModalRef<PosReasonSelectModalComponent>;
  constructor(private modalService: BsModalService) {}

  async show(catalogCode: string): Promise<CatalogsResponse | undefined> {
    this.modalRef = this.modalService.show(PosReasonSelectModalComponent, {
      initialState: { catalogCode },
      class: `modal-dialog modal-md ${ModalDialogType.PRIMARY}`,
      ignoreBackdropClick:true,
      keyboard:false
    });

    await this.modalRef.content?.loadReasons();

    return this.modalRef.content!.selectedReasonChange.toPromise();
  }
}
