import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Big from 'big.js';
import { OrderConditions } from 'src/app/@core/constants';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceLine } from 'src/app/models/invoice-line';
import { Order } from 'src/app/models/order';
import { Paging } from 'src/app/models/page';
import { Payment } from 'src/app/models/payment';
import { Receipt } from 'src/app/models/receipt';
import { ReceiptLine } from 'src/app/models/receipt-line';
import { InvoiceRequestModel } from 'src/app/models/request/InvoiceRequestModel';
import { LoanRequestModel } from 'src/app/models/request/LoanRequestModel';
import { PaymentsRequestModel } from 'src/app/models/request/PaymentRequestModel';
import { PaymentTransactionRequestModel } from 'src/app/models/request/PaymentTransactionRequestModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-credit-invoice-stepper',
  templateUrl: './credit-invoice-stepper.component.html',
  styleUrls: ['./credit-invoice-stepper.component.sass']
})
export class CreditInvoiceStepperComponent implements OnInit {

  constructor(
    private router: ActivatedRoute,
    private invoiceService: InvoiceService,
    private configurationService: ConfigurationService,
    private orderService: OrderService,
    private formBuilder: FormBuilder) { }

  invoices: Array<Invoice> = [];
  chargeNote: Receipt = new Receipt();
  order: Order = new Order();
  payments: Set<Payment> = new Set();
  request = new PaymentTransactionRequestModel();
  requestWithPayments: Array<PaymentTransactionRequestModel> = [];
  invoiceCompletePayments: FormGroup[] = [];

  async ngOnInit(): Promise<void> {
    this.order = (await this.orderService.readOrder(this.router.snapshot.params.code).toPromise()).response;
    let response = await (await this.invoiceService.simulatePaymentTransaction(this.buildPaymentTransactionRequest()).toPromise()).response;
    this.invoices = response.invoices;
    this.chargeNote = response.chargeNote || new Receipt();

    this.invoices.forEach(invoice => {
      this.invoiceCompletePayments.push(
        this.formBuilder.group({
          complete: new FormControl(false, Validators.required)
        })
      );
    });
  }

  buildPaymentTransactionRequest() {

    this.request = new PaymentTransactionRequestModel();
    this.request.orderCode = this.router.snapshot.params.code;
    this.request.branchCode = this.order.branchCode;
    this.request.registerCode = this.configurationService.readRegister().code;

    this.request.invoiceDetails = this.order.orderEntryList.map(detail => {
      let invoiceModel = new InvoiceRequestModel();
      invoiceModel.orderEntryCode = detail.code;
      invoiceModel.quantity = detail.totalQuantity;
      return invoiceModel;
    });

    this.request.paymentsRequestModel.branchCode = this.order.branchCode;
    this.request.paymentsRequestModel.orderCode = this.request.orderCode;
    this.request.paymentsRequestModel.registerCode = this.configurationService.readRegister().code;
    this.request.paymentsRequestModel.salesOrgCode = this.order.salesOrgCode;
    this.request.paymentsRequestModel.payments = Array.from(this.payments);

    return this.request;
  }




  getPaymentInvoices(payments: { payments: Array<Payment>, paymentsAmount: number, invoiceDetails: Array<InvoiceLine>, id: string, loanCode: string }) {
    var newRequest = this.requestWithPayments.find(f => f.id == payments.id) || this.buildPaymentTransactionRequest();
    newRequest.paymentsRequestModel.payments = payments.payments;
    newRequest.id = payments.id;
    if(this.order.orderCondition == OrderConditions.MORE_THAN_30_DAYS_FRANCHISED){
      newRequest.paymentResponsible = this.order.paymentResponsible;
      newRequest.paymentResponsibleName = this.order.paymentResponsibleName;
    }else {
      newRequest.paymentResponsible = this.order.customerCode;
      newRequest.paymentResponsibleName = this.order.customerName;
    }
    newRequest.debitNote = true;
    newRequest.deliveryBranchCode = window.history.state?.deliveryBranch;
    newRequest.isForDelivery = window.history.state?.deliveryBranch?.length > 0
    newRequest.invoiceDetails = payments.invoiceDetails.map(m => {
      let invoiceModel = new InvoiceRequestModel();
      invoiceModel.orderEntryCode = m.orderEntryCode;
      invoiceModel.quantity = m.quantity;
      return invoiceModel;
    });

    newRequest.loanCode = payments.loanCode;

    newRequest.loanRequestModel = new LoanRequestModel();
    newRequest.loanRequestModel.loanCode = payments.loanCode;
    //newRequest.loanRequestModel.intermediaries = [];

    var creditPayment = payments.payments.find(f => f.paymentMethodCode == "ZC01") || new Payment();
    var amountReceipt = 0;
    this.order.orderEntryList.filter(f => payments.invoiceDetails.some(x => x.orderEntryCode == f.code)).forEach(f => {
      if (f.serialProfile) {
        f.services.forEach(x => {
          amountReceipt = this.order.orderEntryList.find(f => f.code == x)?.totals?.netTotal! + amountReceipt;
        });
      }
    });
    var receiptPayment = new Payment();
    receiptPayment.amount = amountReceipt;
    receiptPayment.currency = "HNL";
    receiptPayment.paymentMethodCode = "ZC01";

    if (amountReceipt > 0) {
      creditPayment.amount = Number.parseFloat(Big(creditPayment.amount - amountReceipt).round(2).toPrecision());
      newRequest.chargeNotePaymentsRequestModel.branchCode = this.order.branchCode;
      newRequest.chargeNotePaymentsRequestModel.orderCode = this.request.orderCode;
      newRequest.chargeNotePaymentsRequestModel.registerCode = this.configurationService.readRegister().code;
      newRequest.chargeNotePaymentsRequestModel.salesOrgCode = this.order.salesOrgCode;
      newRequest.chargeNotePaymentsRequestModel.payments = [receiptPayment];

      newRequest.paymentsRequestModel.payments = payments.payments.filter(f => f.paymentMethodCode != "ZC01");
      newRequest.paymentsRequestModel.payments.push(creditPayment);
    }


    this.requestWithPayments = this.requestWithPayments.filter(f => f.id != payments.id);
    this.requestWithPayments.push(newRequest);

    let index: number = parseInt(payments.id);
    //Disable next step
    this.invoiceCompletePayments[index].controls['complete'].setValue(false);
    this.invoiceCompletePayments[index].controls['complete'].markAsPending();

    if (this.invoices[index].totals.netTotal <= parseFloat(Big(payments.paymentsAmount).round(2).toString())) {
      //Enabled next step
      this.invoiceCompletePayments[index].controls['complete'].setValue(true);
      this.invoiceCompletePayments[index].controls['complete'].markAsDirty();
      this.invoiceCompletePayments[index].controls['complete'].markAsTouched();
    }
  }
}
