export class LoanFormRequest {
  term: number;
  downPaymentPercent: number;
  downPaymenyCurrency: number;
  initialDate: string;
  firstFeeDate: string;
  firstFeeDateRaw: Date;
  fee: number;
  loanCode: string;

  constructor() {
    this.term = 0.0;
    this.downPaymentPercent = 0.0;
    this.downPaymenyCurrency = 0.0;
    this.initialDate = '';
    this.firstFeeDate = '';
    this.firstFeeDateRaw = new Date();
    this.fee = 0.0;
    this.loanCode = '';
  }
}
