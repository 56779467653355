import { CustomerType } from '../@core/constants';
import { Attachments } from './attachments';
import { IncomingInfo } from './incoming-info';
import { JobsInfo } from './jobs-info';
import { PersonalInfo } from './personal-info';

export class Customer {
  code: string;
  customerType: CustomerType;
  tax: string;
  loyaltyPoints: number;
  loyaltyProgram: boolean;
  prospect: boolean;
  personalInfo: Array<PersonalInfo>;
  jobsInfo: Array<JobsInfo>;
  incomingInfo: Array<IncomingInfo>;
  attachments: Array<Attachments>;
  existsInSAP: boolean;
  mainPersonalInfo: PersonalInfo | undefined;
  locked: boolean;
  fullName: string;
  constructor() {
    this.code = '';
    this.customerType = CustomerType.RETAIL;
    this.tax = '';
    this.loyaltyPoints = 0.0;
    this.loyaltyProgram = false;
    this.prospect = false;
    this.personalInfo = [];
    this.jobsInfo = [];
    this.incomingInfo = [];
    this.attachments = [];
    this.existsInSAP = false;
    this.locked = false;
    this.fullName = "";
  }

  getMainPersonalInfo(): PersonalInfo {
    return this.personalInfo[0];
  }
}
