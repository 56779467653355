
<accordion [isAnimated]="true">
      <accordion-group [isOpen]="true">
        <span accordion-heading>
            <i class='fa fa-credit-card'></i> Pagos
          </span>
          <pos-table tableFormId="paymentTable" [data]="payments" [headers]="getPaymentHeaders()" [filters]="[]"
          [itemsPerPage]="10" [pagination]="false" [disableHighlight]="true">
        </pos-table>
      </accordion-group>
</accordion>


  
