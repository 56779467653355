import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackOfficeOrdersComponent } from './back-office-orders.component';
import { BackOfficeOrdersRoutingModule } from './back-office-orders-routing.module';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { OrderApprovalModule } from './order-approval/order-approval.module';
import { BackOfficeOrderListModule } from './back-office-order-list/back-office-order-list.module';
import { InvoiceStepperModule } from './invoice-stepper/invoice-stepper.module';
import { CreditInvoiceStepperModule } from './credit-invoice-stepper/credit-invoice-stepper.module';


@NgModule({
  declarations: [BackOfficeOrdersComponent],
  imports: [
    BackOfficeOrderListModule,
    CommonModule,
    BackOfficeOrdersRoutingModule,
    PosTableModule,
    OrderApprovalModule,
    InvoiceStepperModule,
    CreditInvoiceStepperModule
  ]
})
export class BackOfficeOrdersModule { }
