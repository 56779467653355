import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoanAvailableDay } from '../models/loan-available-day';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class LoanAvailableDaysService {
  private url = `${environment.url}/loanAvailableDays`;
  constructor(private http: HttpClient) { }

  readLoanAvailableDays(salesOrg?: string, productClass?: string, loanClass?: string): Observable<ResponseModel<Array<LoanAvailableDay>>> {
    let params = new HttpParams();
    params = params.set("pageSize", "5000");
    params = salesOrg ? params.set("productClass", salesOrg) : params;
    params = productClass ? params.set("productClass", productClass) : params;
    params = loanClass ? params.set("loanClass", loanClass) : params;
    return this.http.get<ResponseModel<Array<LoanAvailableDay>>>(this.url, { params });
  }
}
