export class LoanRequestModel {
    loanCode: string;
	signedInCity: string;
	signedInDepartment: string;
	invoiceCodes: Array<string>;
	receiptCodes: Array<string>;
	intermediaries: Array<string>;

    constructor() {
        this.loanCode = "";
	    this.signedInCity = "";
	    this.signedInDepartment = "";
	    this.invoiceCodes = new Array<string>();
	    this.receiptCodes = new Array<string>();
	    this.intermediaries = new Array<string>();
    }
}