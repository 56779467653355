import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Order } from 'src/app/models/order';
import { PosOrderObservationsComponent } from 'src/app/shared/pos-order-observations/pos-order-observations.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosOrderObservationsService {

  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) {


  }


  async show(observations: string, confirmAction: (observation: string) => any, isDisabled: boolean = false) {
    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(PosOrderObservationsComponent, {
        ignoreBackdropClick: true
      });
      this.modalRef.setClass(`modal-dialog modal-md modal-md-height ${ModalDialogType.PRIMARY}`)
      this.modalRef.content.observations = observations;
      this.modalRef.content.isDisabled = isDisabled;
      this.modalRef.content.load();
      this.modalRef.content.confirmAction = confirmAction;

      resolve(true);
    })
  }
}
