import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BiometryComponent } from './biometry.component';
import { BiometryRoutingModule } from './biometry-routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CustomersModule } from '../customers/customers.module';
import { BiometryService } from 'src/app/services/biometry.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BiometryFingerPrintComponent } from '../biometryFingerPrint/biometryFingerprint.component';
import { BiometryFingerPrintModule } from '../biometryFingerPrint/biometryFingerPrint.module';



@NgModule({
  declarations: [BiometryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BiometryRoutingModule,
    TabsModule,
    CustomersModule,
    BiometryFingerPrintModule
  ],
  providers: [DatePipe, BiometryService, ConfigurationService, AuthService],
  exports: [BiometryComponent]
})
export class BiometryModule { }
