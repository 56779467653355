import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';

@Component({
  selector: 'app-pos-create-customer-modal',
  templateUrl: './pos-create-customer-modal.component.html',
  styleUrls: ['./pos-create-customer-modal.component.sass']
})
export class PosCreateCustomerModalComponent implements OnInit {

  constructor(private bsModalRef: BsModalRef) { }

  confirmAction = (customer: CustomerTableModel) => { };
  ngOnInit(): void {
  }

  cancel() {
    this.bsModalRef.hide();
  }

  saveCustomer(customer: CustomerTableModel) {
    this.confirmAction(customer);
    this.bsModalRef.hide();
    //this.saveCustomerEmitter.emit(customer);
  }
}
