import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paging } from '../models/page';
import { PhysicalEstablishment } from '../models/physical-establishment';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class PhysicalEstablishmentService {
  private url = `${environment.url}/physicalEstablishments`;
  constructor(private http: HttpClient) { }

  public readPhysicalEstablishments(): Observable<ResponseModel<Paging<Array<PhysicalEstablishment>>>> {
    let params = new HttpParams();
    params = params
      .set("page", "0")
      .set("pageSize", "1000");

    return this.http.get<ResponseModel<Paging<Array<PhysicalEstablishment>>>>(this.url, { params });
  }
}
