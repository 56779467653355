import { OrderType, RepairshopOrderType, VoidOrderOperationType } from "../@core/constants";
import { InvoiceLine } from "./invoice-line";
import { Totals } from "./totals";

export class Invoice {
    code: string;
    timeStamp: string;
    sellerName: string;
    taxRegistration: string;
    taxFreeOrderNumber: string;
    taxFreeRecordNumber: string;
    secretaryOfAgricultureAndLivestockRegistry: string;
    cancellationCode: string;
    guaranteeConditions: string;
    generatedBytes: number;
    bytesDiscount: number;
    creditArrangementNumber: string;
    orderCode: string;
    orderType: OrderType;
    repairshopOrderSubType?: RepairshopOrderType;
    originalOrderCode: string;
    salesOrg: string;
    salesOrgName: string;
    salesOrgPhone: number;
    condition: string;
    branchCode: string;
    branchName: string;
    branchAddress: string;
    customerCode: string;
    customerLastName: string;
    customerName: string;
    customerFullName: string;
    customerFiscalId: string;
    registerCode: string;
    currency: string;
    fiscalEstablishment: string;
    fiscalEmisionPoint: string;
    fiscalDocumentType: string;
    amountUsedAsPayment: number;
    serialNumber: string;
    number: number;
    sequence: number;
    invoiceRange: number;
    initialInvoiceRange: number;
    endInvoiceRange: number;
    initialDate: string;
    endDate: string;
    printingAuthorizationKey: string;
    extraFinancingPercentage: number;
    deliveryInvoiceLines: Array<InvoiceLine>;
    totals: Totals;
    partialDelivery: boolean;
    usableAsPayment: boolean;
    loanCode: string;
    isReturnAndNotFiscal: boolean;
    downPaymentInvoice: number;
    voidOrderOperationType?: VoidOrderOperationType;
    isVoidRepairshopCreditNote: boolean = false;
    isActivated: boolean = true;
    createdBy: string;
    fullNumber?: string;
    amountToPayDownPaymentSimulate: number;
    constructor() {
        this.code = "";
        this.timeStamp = "";
        this.sellerName = "";
        this.taxRegistration = "";
        this.taxFreeOrderNumber = "";
        this.taxFreeRecordNumber = "";
        this.secretaryOfAgricultureAndLivestockRegistry = "";
        this.cancellationCode = "";
        this.guaranteeConditions = "";
        this.generatedBytes = 0;
        this.bytesDiscount = 0;
        this.creditArrangementNumber = "";
        this.orderCode = "";
        this.orderType = OrderType.REGULAR;
        this.originalOrderCode = "";
        this.salesOrg = "";
        this.salesOrgName = "";
        this.salesOrgPhone = 0;
        this.condition = "";
        this.branchCode = "";
        this.branchName = "";
        this.branchAddress = "";
        this.customerCode = "";
        this.customerLastName = "";
        this.customerName = "";
        this.customerFullName = "";
        this.customerFiscalId = "";
        this.registerCode = "";
        this.currency = "";
        this.fiscalEstablishment = "";
        this.fiscalEmisionPoint = "";
        this.fiscalDocumentType = "";
        this.amountUsedAsPayment = 0;
        this.serialNumber = "";
        this.number = 0;
        this.sequence = 0;
        this.invoiceRange = 0;
        this.initialInvoiceRange = 0;
        this.endInvoiceRange = 0;
        this.initialDate = "";
        this.endDate = "";
        this.printingAuthorizationKey = "";
        this.extraFinancingPercentage = 0;
        this.deliveryInvoiceLines = [];
        this.totals = new Totals();
        this.partialDelivery = false;
        this.usableAsPayment = false;
        this.loanCode = "";
        this.isReturnAndNotFiscal = false;
        this.downPaymentInvoice = 0;
        this.isVoidRepairshopCreditNote = false;
        this.isActivated = true;
        this.createdBy = "";
        this.amountToPayDownPaymentSimulate = 0.0;
    }
}