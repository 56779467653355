import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Invoice } from '../models/invoice';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';
import { ConfigurationService } from './configuration.service';

@Injectable( {
  providedIn: 'root'
} )
export class SalesService {
  private url = `${environment.url}/invoices`;

  constructor( private http: HttpClient, private configurationService: ConfigurationService ) { }

  getInvoiceById( fiscalEstablishment: string, fiscalEmisionPoint: string, fiscalDocumentType: string, number: string ): Observable<ResponseModel<Paging<Array<Invoice>>>> {
    const salesOrgObject = this.configurationService.readSelectedBranchAndSalesOrg().salesOrg;
    const salesOrg = salesOrgObject.code;

    let params = new HttpParams();
    params = params.appendAll( { fiscalEstablishment, fiscalEmisionPoint, fiscalDocumentType, number, salesOrg } );

    return this.http.get<ResponseModel<Paging<Array<Invoice>>>>( this.url, { params } );
  }
}
