import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosTableComponent } from './pos-table.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TableHiddenFilterModule } from 'src/app/@core/pipes/table-hidden-filter/table-hidden-filter.module';
import { HasPrivilegeModule } from 'src/app/@core/directives/has-privilege/has-privilege.module';



@NgModule({
  declarations: [PosTableComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    TableHiddenFilterModule,
    HasPrivilegeModule
  ],
  exports: [PosTableComponent]
})
export class PosTableModule { }
