export class ProductTableModel {
    code: string;
    description: string;
    price: number;
    tax: number;
    taxCode: string;
    stock: number;
    total: number;
    requiredSerial: boolean;
    delivereable: string;
    features: { [value: string]: string };
    constructor() {
        this.code = "";
        this.description = "";
        this.price = 0.0;
        this.stock = 0;
        this.tax = 0.0;
        this.total = 0.0;
        this.taxCode = "";
        this.requiredSerial = false;
        this.delivereable = "";
        this.features = {};
    }

    setCode(code: string) {
        this.code = code;
        return this;
    }

    setDescription(description: string) {
        this.description = description;
        return this;
    }

    setPrice(price: number) {
        this.price = price;
        return this;
    }

    setTax(tax: number) {
        this.tax = tax;
        return this;
    }
    setStock(stock: number) {
        this.stock = stock;
        return this;
    }

    setTotal(total: number) {
        this.total = total;
        return this;
    }

    setTaxCode(taxCode: string) {
        this.taxCode = taxCode;
        return this;
    }

    setDelivereable(delivereable: string) {
        this.delivereable = delivereable;
        return this;
    }

    setRequiredSerial(requiredSerial: boolean) {
        this.requiredSerial = requiredSerial;
        return this;
    }

    setFeatures(features: { [value: string]: string }) {
        this.features = features;
        return this;
    }
}