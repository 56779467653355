import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosEmployeeTableComponent } from './pos-employee-table.component';
import { CustomerService } from 'src/app/services/customer.service';
import { PosTableModule } from '../pos-table/pos-table.module';



@NgModule({
  declarations: [PosEmployeeTableComponent],
  imports: [
    CommonModule,
    PosTableModule
  ],
  providers: [CustomerService],
  exports: [PosEmployeeTableComponent]
})
export class PosEmployeeTableModule { }
