export class JobInfo {
  admissionDate: string;
  currency: string;
  monthlySalary: string;

  constructor() {
    this.admissionDate = '';
    this.currency = '';
    this.monthlySalary = '';
  }
}
