<form [formGroup]="formCustomerPerson" (ngSubmit)="saveCustomerPerson()">
  <div class="form-row">
    <div class="col-md-3 mb-3">
      <label class="form-label" for="nationality">Nacionalidad</label>
      <ng-select id="nationality" bindLabel="name" bindValue="code" [selectOnTab]="true"
        [(ngModel)]="nationalitySelected" [items]="nationalities" (change)="changeNationality($event)"
        formControlName="nationality">
      </ng-select>
    </div>
    <div class="col-md-3 mb-3">
      <label>Tipo de Identificación</label>
      <ng-select id="fiscalType" [items]="fiscalIdTypes" bindLabel="name" bindValue="code" [selectOnTab]="true"
        [(ngModel)]="fiscalIdTypeSelected" (change)="changeItemFiscalIdType($event)" formControlName="fiscalIdType"
        [ngClass]="
          formCustomerPerson.controls.fiscalIdType.errors
            ? 'is-invalid'
            : 'is-valid'
        ">
      </ng-select>
    </div>
    <div class="col-md-3 mb-3">
      <label>Identificación</label>
      <input class="form-control" type="text" formControlName="fiscalId" (keydown.enter)="findCustomer($event)"
        [mask]="mask" [showMaskTyped]="true" [placeholder]="mask" [dropSpecialCharacters]="false" [ngClass]="
          formCustomerPerson.controls.fiscalId.errors
            ? 'is-invalid'
            : 'is-valid'
        " />
      <div *ngIf="formCustomerPerson.controls.fiscalId.hasError('minlength')" class="text-danger">
        El minimo de caracteres es {{ minLength }}
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label>RTN</label>
      <input class="form-control" type="text" (keyup)="fiscalId2Required($event)" formControlName="fiscalId2"
        [mask]="maskRTN" [dropSpecialCharacters]="false" [showMaskTyped]="true" [ngClass]="
          formCustomerPerson.controls.fiscalId2.errors
            ? 'is-invalid'
            : formCustomerPerson.controls.fiscalId2.value?.length > 0
            ? 'is-valid'
            : ''
        " />
      <div *ngIf="formCustomerPerson.controls.fiscalId2.hasError('minlength')" class="text-danger">
        El minimo de caracteres es {{ minLengthRTN }}
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-3 mb-3">
      <label>Pimer nombre</label>
      <input class="form-control" type="text" formControlName="firstName" [ngClass]="
          formCustomerPerson.controls.firstName.errors
            ? 'is-invalid'
            : 'is-valid'
        " />
    </div>
    <div class="col-md-3 mb-3">
      <label>Segundo nombre</label>
      <input class="form-control" type="text" formControlName="secondName" />
    </div>
    <div class="col-md-3 mb-3">
      <label>Primer apellido</label>
      <input class="form-control" type="text" formControlName="firstLastName" [ngClass]="
          formCustomerPerson.controls.firstLastName.errors
            ? 'is-invalid'
            : 'is-valid'
        " />
    </div>
    <div class="col-md-3 mb-3">
      <label>Segundo apellido</label>
      <input class="form-control" type="text" formControlName="secondLastName" />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Fecha de nacimiento</label>
      <input class="form-control" bsDatepicker
        [bsConfig]="{ isAnimated: true, adaptivePosition: true, containerClass:'theme-blue', displayOneMonthRange: true, selectFromOtherMonth:true }"
        [bsValue]="currentDate" placement="bottom" formControlName="birthDate" name="birthDate" [ngClass]="
        formCustomerPerson.controls.birthDate.errors
          ? 'is-invalid'
          : 'is-valid'
      ">

    </div>
    <div class="col-md-4 mb-3">
      <label>Sexo</label>
      <select class="form-control" formControlName="gender" [ngClass]="
          formCustomerPerson.controls.gender.errors ? 'is-invalid' : 'is-valid'
        ">
        <option value="M">Masculino</option>
        <option value="F">Femenino</option>
      </select>
    </div>
    <div class="col-md-4 mb-3">
      <label>Pais de residencia</label>
      <ng-select id="countryResidence" [items]="nationalities" bindLabel="country" bindValue="code" [selectOnTab]="true"
        [(ngModel)]="countryResidenceSelected" (change)="changeCountryResidence($event)"
        formControlName="countryResidence">
      </ng-select>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Celular</label>
      <input class="form-control" type="text" formControlName="phone" [mask]="phoneMask" [dropSpecialCharacters]="false"
        [showMaskTyped]="true" (keyup)="countryResidenceMinimunCharacters($event, 'phone')" [ngClass]="
          formCustomerPerson.controls.phone.errors
            ? 'is-invalid'
            : formCustomerPerson.controls.phone.value?.length > 0
            ? 'is-valid'
            : ''
        " />
      <div *ngIf="formCustomerPerson.controls.phone.hasError('minlength')" class="text-danger">
        El minimo de caracteres es {{ countryResidenceMinLength }}
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <label>Teléfono de casa</label>
      <input class="form-control" type="text" formControlName="phoneResidence" [mask]="phoneMask"
        [dropSpecialCharacters]="false" [showMaskTyped]="true"
        (keyup)="countryResidenceMinimunCharacters($event, 'phoneResidence')" />
      <div *ngIf="formCustomerPerson.controls.phoneResidence.hasError('minlength')" class="text-danger">
        El minimo de caracteres es {{ countryResidenceMinLength }}
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <label>Correo electrónico</label>
      <input class="form-control" type="text" formControlName="email" [ngClass]="
          formCustomerPerson.controls.email.errors
            ? 'is-invalid'
            : formCustomerPerson.controls.email.value?.length > 0
            ? 'is-valid'
            : ''
        " />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-3 mb-3">
      <label>Teléfono de trabajo</label>
      <input class="form-control" type="text" formControlName="phoneWork" [mask]="phoneMask"
        [dropSpecialCharacters]="false" [showMaskTyped]="true"
        (keyup)="countryResidenceMinimunCharacters($event, 'phoneWork')" />
      <div *ngIf="formCustomerPerson.controls.phoneWork.hasError('minlength')" class="text-danger">
        El minimo de caracteres es {{ countryResidenceMinLength }}
      </div>
    </div>
    <div class="col-md-3 mb-3">
      <label>Extensión</label>
      <input class="form-control" type="text" formControlName="phoneWorkExtention" mask="0000" [showMaskTyped]="true" />
    </div>
  </div>
  <div class="form-row">
    <div *ngIf="needInputRequired" class="alert alert-danger" role="alert">
      Es oligatorio al menos uno de los siguientes campos:
      <strong>celular</strong>, <strong>teléfono de casa</strong>,
      <strong>correo electrónico</strong>
    </div>
    <div *ngIf="created" class="alert alert-success" role="alert">
      Cliente creado correctamente: <strong>{{ customerCreated.code }}</strong>
    </div>
  </div>

  <div class="form-row">
    <fieldset class="col-md-12">
      <legend class="scheduler-border">Dirección de domicilio</legend>
      <app-pos-customer-create-address #FormAddessCustomerPerson></app-pos-customer-create-address>
    </fieldset>
  </div>

  <div class="form-row buttons-group">
    <div class="col-md-3" *ngIf="showBack">
      <button type="button" class="btn btn-primary form-control" (click)="goBack()">Atras</button>
    </div>
    <div class="col-md-3" *ngIf="created">
      <button type="button" class="btn btn-primary form-control">
        Ir a clientes
      </button>
    </div>
    <div class="col-md-3" *ngIf="!created">
      <button type="submit" class="btn btn-primary form-control">
        Guardar
      </button>
    </div>
  </div>
</form>