import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { Directionality } from '@angular/cdk/bidi';

import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'pos-stepper',
  templateUrl: './pos-stepper.component.html',
  styleUrls: ['./pos-stepper.component.sass'],
  providers: [{ provide: CdkStepper, useExisting: PosStepperComponent }],
})
export class PosStepperComponent extends CdkStepper implements OnInit {

  @Input()
  activeClass = 'active';

  constructor(dir: Directionality, cdr: ChangeDetectorRef, private toastrService: ToastrService) {
    super(dir, cdr);
  }
  ngOnInit(): void {

  }

  onClick(index: number): void {
    //(selected.stepControl && !(selected.stepControl.dirty && selected.stepControl.valid) && !selected.optional) || !selected.optional

    let step = this.steps.get(index) || new CdkStep(this);
    if (this.selected.optional ||
      (step.editable && this.selectedIndex > index) ||
      (this.selected.stepControl && (this.selected.stepControl.dirty && (this.selected.stepControl.valid || this.selected.stepControl.disabled)) && !this.selected.optional)) {
      this.selectedIndex = index;
    }
  }


}
