import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCustomerCreatePersonModule } from './pos-customer-create-person/pos-customer-create-person.module';
import { PosCustomerCreateComponent } from './pos-customer-create.component';
import { PosCustomerCreateLegalEntityModule } from './pos-customer-create-legal-entity/pos-customer-create-legal-entity.module';

@NgModule({
  declarations: [PosCustomerCreateComponent],
  imports: [
    CommonModule,
    PosCustomerCreatePersonModule,
    PosCustomerCreateLegalEntityModule
  ],
  exports: [PosCustomerCreateComponent]
})
export class PosCustomerCreateModule { }
