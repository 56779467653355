export class ValidateCreditLimitForInvoicingResponseModel {
    downPaymentProposed: number;
    creditLimitAvaibleForInvoicing: boolean;
    message: string;

    constructor()
    {
        this.downPaymentProposed=0;
        this.creditLimitAvaibleForInvoicing=false;
        this.message="";
    
    }
}