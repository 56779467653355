import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosConfirmationComponent } from './pos-confirmation.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';



@NgModule({
  declarations: [PosConfirmationComponent],
  imports: [
    ModalModule.forRoot()
  ],
  providers: [PosConfirmationService],
  exports: [PosConfirmationComponent]
})
export class PosConfirmationModule { }
