export class City {
    cityCode: string;
    code: string;
    countryCode: string;
    name: string;
    regionCode: string;

    constructor()
    {
        this.cityCode="";
        this.code="";
        this.countryCode="";
        this.name="";
        this.regionCode="";
    }
}