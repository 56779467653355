export class Paging<T> {
    content: T;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
    sort?: any;
    constructor(content: T) {
        this.content = content;
        this.first = false;
        this.last = false;
        this.number = 0;
        this.numberOfElements = 0;
        this.size = 0;
        this.totalElements = 0;
        this.totalPages = 0;
    }

    setValues(paging: Paging<any>) {
        this.first = paging.first;
        this.last = paging.last;
        this.number = paging.number;
        this.numberOfElements = paging.numberOfElements;
        this.size = paging.size;
        this.totalElements = paging.totalElements;
        this.totalPages = paging.totalPages;
        return this;
    }
}