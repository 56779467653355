import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PosIp800ModalService } from 'src/app/@core/services/pos-ip800-modal.service';
import { FileUtils } from 'src/app/@core/utils/file-utils';
import { Paging } from 'src/app/models/page';
import { ProductCart } from 'src/app/models/product-cart';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';

@Component({
  selector: 'documents-step',
  templateUrl: './documents-step.component.html',
  styleUrls: ['./documents-step.component.sass']
})
export class DocumentsStepComponent implements OnInit {

  @Input()
  fiscalIdFileName: string = "";
  @Input()
  rtnFiscalIdFileName: string = "";
  @Input()
  ip800FileName: string = "";
  @Input()
  proofOfPaymentName: string = "";
  @Input()
  purchaseOrderName: string = "";

  @Input()
  documentsForm: FormGroup = this.formBuilder.group({
    fiscalIdFile: '',
    rtnFiscalIdFile: '',
    ip800File: '',
    proofOfPaymentFile: '',
    purchaseOrderFile: ''
  });

  @Input() customer: CustomerTableModel = new CustomerTableModel();

  @Input() productsCart: Paging<Array<ProductCart>> = new Paging([]);

  @ViewChild('fiscalId')
  fiscalIdViewChild?: ElementRef;

  @ViewChild('rtnFiscalId')
  rtnFiscalIdViewChild?: ElementRef;

  @ViewChild('proofOfPayment')
  proofOfPaymentViewChild?: ElementRef;

  @ViewChild('purchaseOrder')
  purchaseOrderViewChild?: ElementRef;


  constructor(private formBuilder: FormBuilder, private posIp800ModalService: PosIp800ModalService) {


  }
  ngOnInit(): void {
  }

  getFiscalIdFile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.documentsForm.patchValue({
        fiscalIdFile: file
      });
      this.documentsForm.get("fiscalIdFile")?.markAsDirty();
      this.fiscalIdFileName = file.name;
    }
  }

  getRtnFiscalIdFile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.documentsForm.patchValue({
        rtnFiscalIdFile: file
      });
      this.documentsForm.get("rtnFiscalIdFile")?.markAsDirty();
      this.rtnFiscalIdFileName = file.name;
    }
  }

  async getIp800File(): Promise<void> {
    // if (event.target.files.length > 0) {
    //   const file = event.target.files[0];
    //   this.documentsForm.patchValue({
    //     ip800File: file
    //   });
    //   this.documentsForm.get("ip800File")?.markAsDirty();
    //   this.ip800FileName = file.name;
    // }
    await this.posIp800ModalService.show(this.customer, (val: Blob) => {

      this.ip800FileName = `ip800${this.customer.code}.pdf`;
      this.documentsForm.patchValue({
        ip800File: FileUtils.blobToFile(val, this.ip800FileName)
      });
      this.documentsForm.get("ip800File")?.markAsDirty();
    })
  }

  getProofOfPaymentFile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.documentsForm.patchValue({
        proofOfPaymentFile: file
      });
      this.documentsForm.get("proofOfPaymentFile")?.markAsDirty();
      this.proofOfPaymentName = file.name;
    }
  }

  getpurchaseOrderFile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.documentsForm.patchValue({
        purchaseOrderFile: file
      });
      this.documentsForm.get("purchaseOrderFile")?.markAsDirty();
      this.purchaseOrderName = file.name;
    }
  }

  disableGenerateIP800() {
    return !this.productsCart.content.some(x => x.requiredSerial);
  }

  deleteFile(fileName: string) {
    switch (fileName) {
      case 'IDENTIDAD':
        this.documentsForm.patchValue({
          fiscalIdFile: ''
        });
        this.documentsForm.get("fiscalIdFile")?.markAsUntouched();
        this.fiscalIdFileName = '';
        this.fiscalIdViewChild!.nativeElement.value = "";
        break;
      case 'RTN':
        this.documentsForm.patchValue({
          rtnFiscalIdFile: ''
        });
        this.documentsForm.get("rtnFiscalIdFile")?.markAsUntouched();
        this.rtnFiscalIdFileName = '';
        this.rtnFiscalIdViewChild!.nativeElement.value = "";
        break;
      case 'IP800':
        this.documentsForm.patchValue({
          ip800File: ''
        });
        this.documentsForm.get("ip800File")?.markAsUntouched();
        this.ip800FileName = '';
        break;
      case 'PROOF_OF_PAYMENT':
        this.documentsForm.patchValue({
          proofOfPaymentFile: ''
        });
        this.documentsForm.get("proofOfPaymentFile")?.markAsUntouched();
        this.proofOfPaymentName = '';
        this.proofOfPaymentViewChild!.nativeElement.value = "";
        break;
      case 'PURCHASE_ORDER':
          this.documentsForm.patchValue({
            purchaseOrderFile: ''
          });
          this.documentsForm.get("purchaseOrderFile")?.markAsUntouched();
          this.purchaseOrderName = '';
          this.purchaseOrderViewChild!.nativeElement.value = "";
          break;
    }
  }
}
