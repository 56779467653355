import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCustomerCreatePersonComponent } from './pos-customer-create-person.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { PosCustomerCreateAddressModule } from '../pos-customer-create-address/pos-customer-create-address.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
@NgModule( {
  declarations: [ PosCustomerCreatePersonComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule,
    PosCustomerCreateAddressModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [ PosCustomerCreatePersonComponent ]
} )
export class PosCustomerCreatePersonModule { }