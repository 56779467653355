<form [formGroup]="legalEntityForm" (ngSubmit)="saveCustomerLegalEntity()">
  <div class="form-row">
    <div class="col-md-3 mb-3">
      <label for="nationality" class="form-label">Nacionalidad</label>
      <ng-select id="nationality" formControlName="nationality" [selectOnTab]="true"
        (change)="changeNationality($event)">
        <ng-option *ngFor="let nationality of nationalities" [value]="nationality.code">{{
          nationality.name?.toUpperCase() }}
        </ng-option>
      </ng-select>
    </div>

    <div class="col-md-3 mb-3">
      <label for="fiscalType">Tipo de Identificación</label>
      <ng-select id="fiscalType" formControlName="fiscalType" [selectOnTab]="true" (change)="changeFiscalIdType($event)"
        [ngClass]="
        legalEntityForm.controls.fiscalType.errors
          ? 'is-invalid'
          : 'is-valid'
      ">
        <ng-option *ngFor="let type of fiscalIdTypesToShow" [value]="type.code">{{ type.name.toUpperCase() }}
        </ng-option>
      </ng-select>
    </div>

    <div class="col-md-3 mb-3">
      <label for="rtn">RTN</label>
      <input id="rtn" class="form-control uppercase" type="text" formControlName="rtn" [mask]="maskRTN"
        [dropSpecialCharacters]="false" [showMaskTyped]="true" [ngClass]="
          legalEntityForm.controls.rtn.errors
            ? 'is-invalid'
            : legalEntityForm.controls.rtn.value?.length > 0
            ? 'is-valid'
            : ''
        " />
      <div *ngIf="legalEntityForm.controls.rtn.hasError('minlength')" class="text-danger">
        El minimo de caracteres es {{ minLengthRTN }}
      </div>
    </div>

  </div>

  <div class="form-row">

    <div class="col-md-3 mb-3">
      <label for="legalName">Nombre legal</label>
      <input id="legalName" class="form-control uppercase" type="text" formControlName="legalName" [ngClass]="
          legalEntityForm.controls.legalName.errors
            ? 'is-invalid'
            : 'is-valid'
        " />
    </div>

    <div class="col-md-3 mb-3">
      <label for="legalName2">Nombre legal 2</label>
      <input id="legalName2" class="form-control uppercase" type="text" formControlName="legalName2" />
    </div>

    <div class="col-md-3 mb-3">
      <label for="legalName3">Nombre legal 3</label>
      <input id="legalName3" class="form-control uppercase" type="text" formControlName="legalName3" />
    </div>

    <div class="col-md-3 mb-3">
      <label for="legalName4">Nombre legal 4</label>
      <input id="legalName4" class="form-control uppercase" type="text" formControlName="legalName4" />
    </div>

  </div>

  <div class="form-row">
    <div class="col-md-3 mb-3">
      <label for="countryResidence">Pais de residencia</label>
      <ng-select id="countryResidence" [selectOnTab]="true" (change)="changeCountryResidence($event)"
        formControlName="countryResidence">
        <ng-option *ngFor="let nationality of nationalities" [value]="nationality.code">{{
          nationality.country?.toUpperCase() }}
        </ng-option>
      </ng-select>
    </div>

    <div class="col-md-3 mb-3">
      <label for="countryCode">Código Pais</label>
      <input id="countryCode" class="form-control uppercase" type="text" formControlName="countryCode" readonly />
    </div>

    <div class="col-md-3 mb-3">
      <label for="phone">Teléfono de trabajo</label>
      <input id="phone" class="form-control uppercase" [mask]="phoneMask" [dropSpecialCharacters]="false"
        [showMaskTyped]="true" type="text" formControlName="phone" [ngClass]="
        legalEntityForm.controls.phone.errors
          ? 'is-invalid'
          : legalEntityForm.controls.phone.value?.length > 0
          ? 'is-valid'
          : ''
      " />
      <div *ngIf="legalEntityForm.controls.phone.hasError('minlength')" class="text-danger">
        El minimo de caracteres es {{ countryResidenceMinLength }}
      </div>
    </div>
  </div>

  <div class="form-row">
    <fieldset class="col-md-12">
      <legend class="scheduler-border">Persona de contacto</legend>

      <div class="form-row">
        <div class="col-md-3 mb-3">
          <label>Primer nombre</label>
          <input id="firstName" class="form-control uppercase" type="text" formControlName="firstName" [ngClass]="
              legalEntityForm.controls.firstName.errors
                ? 'is-invalid'
                : 'is-valid'
            " />
        </div>
        <div class="col-md-3 mb-3">
          <label>Segundo nombre</label>
          <input id="secondName" class="form-control uppercase" type="text" formControlName="secondName" />
        </div>
        <div class="col-md-3 mb-3">
          <label>Primer apellido</label>
          <input id="firstLastName" class="form-control uppercase" type="text" formControlName="firstLastName"
            [ngClass]="
              legalEntityForm.controls.firstLastName.errors
                ? 'is-invalid'
                : 'is-valid'
            " />
        </div>
        <div class="col-md-3 mb-3">
          <label>Segundo apellido</label>
          <input id="secondLastName" class="form-control uppercase" type="text" formControlName="secondLastName" />
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-3 mb-3">
          <label for="relationship">Relación</label>
          <ng-select id="relationship" [selectOnTab]="true" formControlName="relationship" [ngClass]="
          legalEntityForm.controls.relationship.errors
            ? 'is-invalid'
            : 'is-valid'">
            <ng-option value="Z027">EMPLEADOR - ORGANIZACION</ng-option>
            <ng-option value="Z026">REPRESENTANTE LEGAL</ng-option>
            <ng-option value="Z025">GERENTE DE PROYECTOS</ng-option>
            <ng-option value="Z024">GERENTE PROPIETARIO/Socio</ng-option>
            <ng-option value="Z023">GERENTE GENERAL</ng-option>
            <ng-option value="Z022">GERENTE DE COMPRAS</ng-option>
            <ng-option value="Z021">GERENTEDE INFRAESTRUCTURA</ng-option>
            <ng-option value="Z020">GERENTE TECNOLOGÍA</ng-option>
            <ng-option value="Z019">GERENTE FINANZAS</ng-option>
            <ng-option value="Z018">GERENTE ADMINISTRATIVO</ng-option>
            <ng-option value="Z017">DIRECTOR</ng-option>
            <ng-option value="Z016">ENCARGADO DE PAGOS</ng-option>
            <ng-option value="Z015">CONTACTO PRINCIPAL</ng-option>
          </ng-select>
        </div>

        <div class="col-md-3 mb-3">
          <label>Celular</label>
          <input id="contactPhone" class="form-control uppercase" type="text" formControlName="contactPhone"
            [mask]="phoneMask" [dropSpecialCharacters]="false" [showMaskTyped]="true" [ngClass]="
              legalEntityForm.controls.contactPhone.errors
                ? 'is-invalid'
                : legalEntityForm.controls.contactPhone.value?.length > 0
                ? 'is-valid'
                : ''
            " />
        </div>

        <div class="col-md-3 mb-3">
          <label>Correo electrónico</label>
          <input id="contactEmail" class="form-control" type="text" formControlName="contactEmail" [ngClass]="
              legalEntityForm.controls.contactEmail.errors
                ? 'is-invalid'
                : legalEntityForm.controls.contactEmail.value?.length > 0
                ? 'is-valid'
                : ''
            " />
        </div>
      </div>

    </fieldset>
  </div>

  <div class="form-row">
    <fieldset class="col-md-12">
      <legend class="scheduler-border">Dirección de domicilio</legend>
      <app-pos-customer-create-address #FormAddressCustomerLegalEntity></app-pos-customer-create-address>
    </fieldset>
  </div>

  <div class="form-row buttons-group">
    <div class="col-md-3" *ngIf="showBack">
      <button type="button" class="btn btn-primary form-control" (click)="goBack()">Atras</button>
    </div>
    <div class="col-md-3" *ngIf="created">
      <button type="button" class="btn btn-primary form-control">
        Ir a clientes
      </button>
    </div>
    <div class="col-md-3" *ngIf="!created">
      <button type="submit" class="btn btn-primary form-control">
        Guardar
      </button>
    </div>
  </div>

</form>