import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockSelectionComponent } from './stock-selection.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [StockSelectionComponent],
  exports:[StockSelectionComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ]
})
export class StockSelectionModule { }
