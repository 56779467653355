import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { Role } from '../../constants';
import { PosSupervisorLoginService } from '../../services/pos-supervisor-login.service';

@Directive({
  selector: '[appPrivilege]'
})
export class HasPrivilegeDirective implements OnInit {

  @Input()
  public appPrivilege: string = "";

  @Output()
  public appPrivilegeClick = new EventEmitter();
  isDisabled = false;
  constructor(private elementRef: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService,
    private supervisorLoginService: PosSupervisorLoginService) { }

  ngOnInit(): void {
    //console.log(this.appPrivilege)
  }

  async doPrivilegedAction() {
    if (this.authService.hasPrivilege(this.appPrivilege)) {
      this.appPrivilegeClick.emit();
    }
    else {
      await this.supervisorLoginService.show(() => {
        this.appPrivilegeClick.emit();
      }, [this.appPrivilege]);
    }
    return false;
  }
  @HostListener('click')
  onClick() {
    return this.doPrivilegedAction();
  }
}
