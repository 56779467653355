import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { StockRequestModel } from 'src/app/models/requests/stock-request-model';
import { ValidateStockModalService } from 'src/app/services/validate-stock-modal.service';

@Component({
  selector: 'stock-selection',
  templateUrl: './stock-selection.component.html',
  styleUrls: ['./stock-selection.component.sass']
})
export class StockSelectionComponent implements OnInit {

  public selectedBranch: Branch = new Branch();
  constructor(private validateStockService: ValidateStockModalService) { }

  @Input() 
  branch = new Branch();

  @Input() 
  disabled = false;

  @Input() 
  stockRequest = new StockRequestModel();

  @Output()
  selectedBranchEmitter: EventEmitter<Branch> = new EventEmitter<Branch>();

  ngOnInit(): void {
  }

  selectBranch(){
    this.selectedBranchEmitter.emit(this.selectedBranch);
  }

  async show(){
    await this.validateStockService.show(this.stockRequest);
  }

  disableButton(): boolean{
    if(this.disabled){
      return true;
    }
    if(this.selectedBranch != null){
      return this.selectedBranch.code.length == 0 ? true : false;
    }
    if(this.selectedBranch == null){
      return true;
    }
    return false;
  }
}
