import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosListComponent } from './pos-list.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';



@NgModule({
  declarations: [PosListComponent],
  imports: [
    CommonModule,
    PaginationModule,
    ReactiveFormsModule,
    FormsModule,
    PopoverModule
  ],
  exports: [PosListComponent]
})
export class PosListModule { }
