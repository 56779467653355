<div class="app-body animated fadeIn">
  <main class="main d-flex align-items-center">
    <div class="container ">
      <div class="row">
        <div class="col-md-8 mx-auto ">
          <div class="card-group ">
            <div class="card p-4 ">
              <div class="card-body ">
                <form #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()" novalidate class="needs-validation">
                  <h1>Inicio de sesión</h1>
                  <p>Iniciar sesión con su cuenta</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input name="username" type="text" class="form-control" placeholder="Usuario"
                      autocomplete="username" [(ngModel)]="form.username" #username="ngModel" required
                      [class.is-invalid]="(username.touched || username.dirty) && username.invalid">
                    <div class="invalid-feedback ">
                      Seleccione un usuario
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input name="password" type="password" class="form-control" placeholder="Contraseña"
                      autocomplete="current-password" required [(ngModel)]="form.password" #password="ngModel"
                      [class.is-invalid]="(password.touched || password.dirty) && password.invalid">
                    <div class="invalid-feedback ">
                      Ingrese una contraseña
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-building-o"></i></span>
                    </div>
                    <select id="salesOrg" name="salesOrg" class="form-control" [(ngModel)]="salesOrg"
                      (change)="filterBranches()">
                      <option value="">Seleccione una Organización</option>
                      <option *ngFor="let salesOrg of salesOrgs" [value]="salesOrg.code">{{salesOrg.code}} -
                        {{salesOrg.name}}</option>
                    </select>

                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="cil-home"></i></span>
                    </div>
                    <select id="select1" name="select1" class="form-control" [(ngModel)]="branch">
                      <option value="">Seleccione una Sucursal</option>
                      <option *ngFor="let branch of filteredBranches" [value]="branch.code">{{branch.code}} -
                        {{branch.name}}</option>
                    </select>

                  </div>
                  <pos-captcha name="captcha" id="captcha" [(ngModel)]="form.captcha"
                    (getCaptchaId)="getCaptchaId($event)"></pos-captcha>
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary px-4">Login</button>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-6 text-right">
                      <button type="button" class="btn btn-link px-0" (click)="goToChangePassword()">Cambiar
                        contraseña</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-right">
                      <button type="button" class="btn btn-link px-0" (click)="goToChangeForgetPassword()">Se me olvido
                        mi contraseña o mi usuario esta bloqueado</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card py-5 d-md-down-none text-center bg-primary" style="width:44%">
              <div class="card-body">
                <div>
                  <img src="assets/logoPosV1.svg" class="rounded img-fluid" alt="Responsive image">
                  <h4>Sistema de facturación POS</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>