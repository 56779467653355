import { Identification } from './identification';
import { JobInfoList } from './JobInfoList';

export class CreateCustomerRequestModel {
  addressCountryId:String;
  birthdate:String;
  cellularPhone:String;
  contactEmail:String;
  contactLastName:String;
  contactLastName2:String;
  contactMiddleName:String;
  contactName:String;
  contactPhone:String;
  contactRelationship:String;
  countryId:String;
  department:String;
  email:String;
  extensionPhone:String;
  fiscalId:String;
  fiscalId2:String;
  fiscalIdType:String;
  fiscalIdType2:String;
  gender:String;
  homePhone:String;
  houseType:String;
  identification: Identification[];
  jobInfoList: JobInfoList[];
  lastName:String;
  lastName2:String;
  middleName:String;
  municipality:String;
  municipalityName:String;
  name:String;
  nationality:String;
  neighborhood:String;
  neighborhoodName:String;
  orgName1:String;
  orgName2:String;
  orgName3:String;
  orgName4:String;
  orgPhone:String;
  ownCar: boolean;
  personality:String;
  street:String;
  street2:String;
  street3:String;
  street4:String;
  street5:String;
  workPhone:String;

  constructor()
  {
    this.addressCountryId="";
    this.birthdate="";
    this.cellularPhone="";
    this.contactEmail="";
    this.contactLastName="";
    this.contactLastName2="";
    this.contactMiddleName="";
    this.contactName="";
    this.contactPhone="";
    this.contactRelationship="";
    this.countryId="";
    this.department="";
    this.email="";
    this.extensionPhone="";
    this.fiscalId="";
    this.fiscalId2="";
    this.fiscalIdType="";
    this.fiscalIdType2="";
    this.gender="";
    this.homePhone="";
    this.houseType="";
    this.identification=new Array<Identification>();
    this.jobInfoList=new Array<JobInfoList>();
    this.lastName="";
    this.lastName2="";
    this.middleName="";
    this.municipality="";
    this.municipalityName="";
    this.name="";
    this.nationality="";
    this.neighborhood="";
    this.neighborhoodName="";
    this.orgName1="";
    this.orgName2="";
    this.orgName3="";
    this.orgName4="";
    this.orgPhone="";
    this.ownCar=false;
    this.personality="";
    this.street="";
    this.street2="";
    this.street3="";
    this.street4="";
    this.street5="";
    this.workPhone="";
  }
}
