import { ScaleType } from "../@core/constants";
import { Discount } from "./discount";

export class Condition {
    discount: Discount;
    productCodes: Array<string>;
    quantity: number;
    quantityUnit: string;
    scaleType: ScaleType;

    constructor() {
        this.discount = new Discount();
        this.productCodes = [];
        this.quantity = 0;
        this.quantityUnit = "";
        this.scaleType = ScaleType.EQUAL;
    }
}