<nav class="navbar navbar-expand-lg navbar-dark bg-dark" *ngIf="filterForm && filters.length > 0">
  <form class="form-horizontal" id="filterForm" [formGroup]="filterForm" (keydown.enter)="filterAction()" novalidate>
    <div class="form-group row">
      <div *ngFor="let filter of filters;trackBy:trackByIndex">
        <div class="form-group col" *ngIf="(filter.type == 'text')">
          <input type="text" [formControlName]="filter.code" class="form-control" [id]="filter.code"
            [name]="filter.code" [placeholder]="filter.description" (keydown.enter)="filterAction()" />
        </div>
        <div class="form-group col" *ngIf="(filter.type == 'combobox')">
          <select [formControlName]="filter.code" class="form-control" [id]="filter.code" [name]="filter.code"
            (change)="filterAction()">
            <option value="">{{filter.description}}</option>
            <option *ngFor="let cb of filter.comboBoxFilterKeys" [value]="cb">{{filter.comboBoxFilterValues.get(cb)}}
            </option>
          </select>
        </div>
        <div class="form-group col" *ngIf="(filter.type == 'date')">
          <input class="form-control" [id]="filter.code" [name]="filter.code" [formControlName]="filter.code"
            [popover]="filter.description" placement="top" type="date" triggers="mouseenter:mouseleave"
            placeholder="date" (change)="filterAction()">
        </div>
        <div class="form-group col" *ngIf="(filter.type == 'switch')">
          <div class="row">
            <label class="switch switch-sm switch-3d switch-primary col-form-label">
              <input type="checkbox" class="form-control switch-input" [formControlName]="filter.code"
                (change)="filterAction()">
              <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
            </label>
            <label class="col col-form-label"> {{filter.description}}</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</nav>
<form *ngIf="tableForm && headers.length > 0" [formGroup]="tableForm" novalidate [id]="tableFormId" [name]="tableFormId"
  (ngSubmit)="tableForm.valid && onSubmit()" (change)="tableForm.valid && onSubmit()">
  <table class="table table-responsive-sm table-hover table-outline mb-0 table-sm">
    <thead class="thead-dark">
      <tr>
        <th *ngFor="let header of headers | hiddenFilter:{}"
          [ngClass]="{'text-center ':true, 'small-number-input': header.type=='number'}">
          <i *ngIf="header.isIcon" [ngClass]="header.iconClass"></i>
          <div *ngIf="!header.isIcon">{{header.description}}</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="data.content.length == 0" class="text-center">
        <td [colSpan]="headers.length.toString()">
          <h6>No hay datos.</h6>

        </td>
      </tr>
      <tr *ngFor="let item of data.content; let itemIndex = index" (click)="rowFn(item, itemIndex)"
        [ngClass]="{'tr-active text-white':currentIndex == itemIndex && !disableHighlight}">
        <td *ngFor="let header of headers | hiddenFilter; let headerIndex = index;trackBy:trackByValue.bind(this,item)"
          class="text-center" (click)="header.cellFn(item)" data-toggle="popover" [title]="header.cellTooltip"
          placement="left">
          <div *ngIf="(header.type == 'hidden')">
            <input type="hidden" [formControlName]="header.code+'-'+itemIndex+'-'+headerIndex"
              [id]="header.code+'-'+itemIndex+'-'+headerIndex" [name]="header.code+'-'+itemIndex+'-'+headerIndex">
          </div>
          <div *ngIf="!header.editable">
            <div [hidden]="!(header.type == 'text')">
              <small *ngIf="header.isSmall">{{getRowData(item, header)}}</small>
              <div id="itemText" *ngIf="!header.isSmall">{{getRowData(item, header)}}</div>
            </div>
            <div *ngIf="(header.type == 'number')"> <b>{{getRowData(item, header) | number : '1.2-2'}}</b></div>
            <div *ngIf="(header.type == 'currency')">
              <b>{{getRowData(item, header) | currency: 'HNL ':'symbol' : '1.2-2'}}</b></div>
            <div *ngIf="(header.type == 'date')"> {{getRowData(item, header) | date:'medium'}}</div>
            <span id="badgeText" *ngIf="header.type == 'badge'" [ngClass]="obtainBadgeClass(header, item[header.code])">
              {{getRowData(item, header)}}
            </span>
            <div class="row-sm">
              <div *ngFor="let action of header.actions">

                <div class="col-sm" *ngIf="!action.isPrivileged">
                  <button [hidden]="!(header.type == 'action')" type="button" [name]="'action'+item[header.code]"
                    [ngClass]="{'btn btn-sm btn-pill btn-light':true, 'text-primary': action.disabledFn ? !action.disabledFn(item) : true, 'text-secondary' : action.disabledFn ? action.disabledFn(item) : false}"
                    (click)="action.fn(item)" data-toggle="popover" [title]="action.tooltip" placement="left"
                    [disabled]="action.disabledFn ? action.disabledFn(item) : false">
                    <i [ngClass]="action.icon+' fa-2x'"></i>
                  </button>
                </div>
                <div class="col-sm" *ngIf="action.isPrivileged">
                  <button [hidden]="!(header.type == 'action')" type="button" [name]="'action'+item[header.code]"
                    [ngClass]="{'btn btn-sm btn-pill btn-light':true, 'text-primary': action.disabledFn ? !action.disabledFn(item) : true, 'text-secondary' : action.disabledFn ? action.disabledFn(item) : false}"
                    (click)="doPrivilegedAction(action,item)" data-toggle="popover" [title]="action.tooltip"
                    placement="left" [disabled]="action.disabledFn ? action.disabledFn(item) : false">
                    <i [ngClass]="action.icon+' fa-2x'"></i>
                  </button>
                </div>
              </div>
            </div>

            <label class="switch switch-sm switch-3d switch-primary" *ngIf="header.type == 'switch'">
              <input type="checkbox" class="switch-input" [checked]="getRowData(item, header)" disabled>
              <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
            </label>
          </div>
          <div *ngIf="header.editable">
            <div [hidden]="!(header.type == 'text')">
              <input class="form-control" type="text" [placeholder]="header.description"
                [formControlName]="header.code+'-'+itemIndex+'-'+headerIndex"
                [id]="header.code+'-'+itemIndex+'-'+headerIndex" [name]="header.code+'-'+itemIndex+'-'+headerIndex"
                (blur)="header.cellFn(item)"
                [class.is-invalid]="(tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.touched || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.dirty || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.pristine) && tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.invalid" />
            </div>
            <div [hidden]="!(header.type == 'number')">
              <input class="form-control" type="number" [placeholder]="header.description"
                [formControlName]="header.code+'-'+itemIndex+'-'+headerIndex"
                [id]="header.code+'-'+itemIndex+'-'+headerIndex" [name]="header.code+'-'+itemIndex+'-'+headerIndex"
                (blur)="header.cellFn(item)"
                [class.is-invalid]="(tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.touched || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.dirty || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.pristine) && tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.invalid" />
            </div>
            <div [hidden]="!(header.type == 'badge')" *ngIf="!header.isSelfComboBoxDataSource">
              <select class="form-control" [formControlName]="header.code+'-'+itemIndex+'-'+headerIndex"
                [id]="header.code+'-'+itemIndex+'-'+headerIndex" [name]="header.code+'-'+itemIndex+'-'+headerIndex"
                [class.is-invalid]="(tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.touched || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.dirty || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.pristine) && tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.invalid"
                (change)="header.cellFn(item)">
                <option value="">{{header.description}}</option>
                <option *ngFor="let val of header.valueMapKeys" [value]="val">{{header.valueMap.get(val)}}
                </option>
              </select>
            </div>
            <div *ngIf="header.isSelfComboBoxDataSource">
              <select class="form-control" [formControlName]="header.code+'-'+itemIndex+'-'+headerIndex"
                [id]="header.code+'-'+itemIndex+'-'+headerIndex" [name]="header.code+'-'+itemIndex+'-'+headerIndex"
                [class.is-invalid]="(tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.touched || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.dirty || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.pristine) && tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.invalid"
                (change)="header.cellFn(item)">
                <option value="">{{header.description}}</option>
                <!-- TODO: falta agregar logica para mostrar el label del combo  -->
                <option *ngFor="let key of getKeyListFromMap(item.dataSourceComboBox.keys())" [value]="key">{{item.dataSourceComboBox.get(key)}}
                </option>
              </select>
            </div>
            <div [hidden]="!(header.type == 'date')">
              <input class="form-control" [formControlName]="header.code+'-'+itemIndex+'-'+headerIndex"
                [id]="header.code+'-'+itemIndex+'-'+headerIndex" [name]="header.code+'-'+itemIndex+'-'+headerIndex"
                type="date" dateFormat="yyyy-MM-dd"
                [class.is-invalid]="(tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.touched || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.dirty || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.pristine) && tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.invalid"
                placeholder="date" (change)="header.cellFn(item)">
            </div>
            <div [hidden]="!(header.type == 'switch')">
              <label class="switch switch-sm switch-3d switch-primary col-form-label">
                <input type="checkbox" class="form-control switch-input"
                  [formControlName]="header.code+'-'+itemIndex+'-'+headerIndex"
                  [id]="header.code+'-'+itemIndex+'-'+headerIndex" [name]="header.code+'-'+itemIndex+'-'+headerIndex"
                  [class.is-invalid]="(tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.touched || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.dirty || tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.pristine) && tableForm.get(header.code+'-'+itemIndex+'-'+headerIndex)?.invalid"
                  (change)="header.cellFn(item)">
                <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
              </label>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>
<div class="pagination ml-2" *ngIf="pagination">
  <pagination [totalItems]="data.totalElements" [(ngModel)]="currentPage" [maxSize]="5" class="pagination-sm"
    previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [boundaryLinks]="true"
    [rotate]="false" [itemsPerPage]="data.size" (pageChanged)="pageSelected($event)">
  </pagination>
</div>
