import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
    private httpOtions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })
    }
    private pdfOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/pdf',
            'Content-Type': 'application/pdf'
        })
    }

    private formDataOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json'
        })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authHeader = req.headers.get("Authorization") || "";
        if (req.url.includes("/jwt/token")) {
            req = new HttpRequest(req.method, req.url, req.body, { headers: req.headers, params: req.params, responseType: "text" })
        }
        else if (req.urlWithParams.toLowerCase().includes("pdf")) {
            req = new HttpRequest(req.method, req.url, req.body, { headers: this.pdfOptions.headers.append("Authorization", authHeader), params: req.params, responseType: 'blob' })
        }
        else if (req.body instanceof FormData) {
            req = new HttpRequest(req.method, req.url, req.body, { headers: this.formDataOptions.headers.append("Authorization", authHeader), params: req.params })
        }
        else {
            req = new HttpRequest(req.method, req.url, req.body, { headers: this.httpOtions.headers.append("Authorization", authHeader), params: req.params })
        }
        return next.handle(req);
    }
}