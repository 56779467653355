<div class="card">
  <div class="card-header">
    <i class="fa fa-street-view"></i> Identificacion de clientes
  </div>
  <div class="card-body card-body-z-index">
    <accordion [isAnimated]="true">
      <accordion-group [isOpen]="true">
        <span accordion-heading>
          <i class="fa fa-id-card"></i> Datos generales del cliente
        </span>

        <!-- <app-customer-information (currentCustomerChange)="handleSelectedCustomerChange($event)">
        </app-customer-information> -->
        <div class="row">
          <app-customer-information class="col-md-12"
            (currentCustomerChange)="handleSelectedCustomerChange($event)"
          >
          </app-customer-information>
        </div>
        <div class="row" *ngIf="this.currentCustomer.code != ''">
          <div class="col-sm-12">
            <app-customer-information-detail [currentCustomer]="this.currentCustomer"
            [hideCreditInformation]="false"
            [currentCustomerCreditConditions]="this.currentCustomerCreditConditions"
            ></app-customer-information-detail>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <app-fast-actions-buttons [currentCustomerCreditConditions]="this.currentCustomerCreditConditions"
            [currentCustomer]="this.currentCustomer" *ngIf="this.currentCustomer.code !== ''">
          </app-fast-actions-buttons>
          </div>
        </div>
      </accordion-group>
      <div *appHasAccess="'preApproval'" [hidden]="!validateCustomerStatus()">
        <accordion-group
          *ngIf="!this.currentCustomerCreditConditions.creditLineActive && this.currentCustomer.code !== ''"
          [isOpen]="true">
          <span accordion-heading>
            <i class="fa fa-check"></i> Pre Aprobación
          </span>
          <app-customer-credit-request-stepper [currentCustomer]="this.currentCustomer" [homeTypes]="this.homeTypes"
            [preApprovalForm]="this.preApprovalForm"
            [currentCustomerCreditConditions]="this.currentCustomerCreditConditions" #creditRequestStepper>
          </app-customer-credit-request-stepper>
        </accordion-group>
      </div>
      <accordion-group *ngIf="this.currentCustomerCreditConditions.creditLineActive && this.currentCustomer.code !==''"
        [isOpen]="true">
        <span accordion-heading>
          <i class="fa fa-check"></i> Calculadora de crédito
        </span>
        <app-pos-credit-installments [customer]="this.currentCustomer"></app-pos-credit-installments>
      </accordion-group>
      <accordion-group *ngIf="this.currentCustomer.code === ''" heading="Por favor seleccione un cliente"
        [isOpen]="false">

      </accordion-group>

    </accordion>
  </div>
</div>
