import { RequiredOnbaseDocument } from '../required-onbase-document';

export class OnbaseProcedureResponseModel {
  code: string;
  name: string;
  duration: number;
  writtenDuration?: string;
  documents?: { [key: string]: string[] };
  credit: number;
  cash: number;
  preApprove: boolean;
  limitRule: string;
  requiredDocuments: Array<RequiredOnbaseDocument>;
  constructor() {
    this.code = ``;
    this.name = ``;
    this.duration = 0;
    this.credit = 0;
    this.cash = 0;
    this.preApprove = false;
    this.limitRule = ``;
    this.requiredDocuments = new Array();
  }
}
