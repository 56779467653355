import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Directive({
  selector: '[appHasAccess]'
})
export class HasAccessDirective implements OnInit {

  @Input('appHasAccess') privilege: string = '';

  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    if (this.authService.hasPrivilege(this.privilege)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
