<form [formGroup]="preApprovalForm" class="needs-validation" novalidate>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label for="cmbOwnVehicle">
            ¿Posee Vehiculo?
          </label>
          <select [ngClass]="{'is-invalid': preApprovalForm.controls.ownVehicle?.errors?.required}" class="form-control"
            name="cmbOwnVehicle" id="cmbOwnVehicle" formControlName="ownVehicle">
            <option value="true">Si</option>
            <option value="false">No</option>
          </select>
          <div class="invalid-feedback">
            Por favor seleccione una opcion valida
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label for="txtAmountToBill">Monto a facturar</label>
          <input type="number" class="form-control"
            [ngClass]="{'is-invalid': preApprovalForm.controls.amountToBill?.errors?.required}" id="txtAmountToBill"
            formControlName="amountToBill" required>
          <div class="invalid-feedback">
            Por favor ingrese un monto valido
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label for="txtMonthIncome">Ingreso mensual</label>
          <input type="number" class="form-control"
            [ngClass]="{'is-invalid': preApprovalForm.controls.monthIncome?.errors?.required}" id="txtMonthIncome"
            formControlName="monthIncome">
          <div class="invalid-feedback">
            Por favor ingrese un monto valido
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label for="txtMonthIncome">Antiguedad Laboral</label>
          <input type="text" class="form-control" bsDatepicker
            [bsConfig]="{ isAnimated: true, adaptivePosition: true, containerClass:'theme-blue', displayOneMonthRange: true, selectFromOtherMonth:true }"
            [bsValue]="preApprovalForm.controls.workSeniority.value"
            [ngClass]="{'is-invalid': preApprovalForm.controls.workSeniority?.errors?.required}" id="txtWorkSeniority"
            formControlName="workSeniority">
          <div class="invalid-feedback">
            Por favor seleccione una fecha
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label for="cmbHomeType">Tipo de domicilio</label>
          <ng-select id="countryResidence" [items]="this.homeTypes" bindLabel="value" bindValue="code"
            [selectOnTab]="true" formControlName="homeType"
            [ngClass]="{'is-invalid': preApprovalForm.controls.homeType?.errors?.required}">
          </ng-select>
          <div class="invalid-feedback">
            Por favor seleccione una fecha
          </div>
        </div>
      </div>
    </div>

  </div>

</form>