import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveStepComponent } from './save-step.component';
import { FormsModule } from '@angular/forms';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { PosConfirmationModule } from 'src/app/shared/pos-confirmation/pos-confirmation.module';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [SaveStepComponent],
  imports: [
    CommonModule,
    FormsModule,
    PosTableModule,
    AccordionModule,
    PosConfirmationModule,
    ToastrModule
  ],
  exports: [SaveStepComponent]
})
export class SaveStepModule { }
