import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OnbaseProcedureResponseModel } from 'src/app/models/response/onbaseProcedureResponseModel';
import { CreditPlanRequirementsListModalComponent } from './../../views/customers/components/credit-plan-requirements-list-modal/credit-plan-requirements-list-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CreditPlanRequirementsListModalService {
  private creditPlan!: OnbaseProcedureResponseModel;

  public get currentCreditPlan(): OnbaseProcedureResponseModel {
    return this.creditPlan || {};
  }

  public set currentCredidPlan(creditPlan: OnbaseProcedureResponseModel) {
    this.creditPlan = creditPlan;
  }

  constructor(private modalService: BsModalService) {}

  show(): BsModalRef<CreditPlanRequirementsListModalComponent> {
    return this.modalService.show(CreditPlanRequirementsListModalComponent, {
      class: `modal-dialog`,
      ignoreBackdropClick: true,
      animated: true,
      initialState: { creditPlan: this.creditPlan },
    });
  }
}
