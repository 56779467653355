import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Discount } from 'src/app/models/discount';
import { PosDiscountModalComponent } from 'src/app/shared/pos-discount-modal/pos-discount-modal.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosDiscountModalService {
  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) {


  }

  async show(discount: Discount, confirmAction: (discount: Discount) => any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(PosDiscountModalComponent, {
        ignoreBackdropClick: true
      });
      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY}`)
      this.modalRef.content.discount = discount;
      this.modalRef.content.load();
      this.modalRef.content.confirmAction = confirmAction;

      resolve(true);
    })
  }
}
