import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerType, ModalDialogType, Personality } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { PosReasonSelectModalService } from 'src/app/@core/services/pos-reason-select-modal.service';
import { PosUpdateCustomerModalService } from 'src/app/@core/services/pos-update-customer-modal.service';
import { ColumnTable } from 'src/app/models/column-table';
import { Filter } from 'src/app/models/filter';
import { FilterTable } from 'src/app/models/filter-table';
import { CustomerFilter } from 'src/app/models/filters/customer-filter';
import { Paging } from 'src/app/models/page';
import { ReasonLogRequest } from 'src/app/models/request/reason-log-request-model';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerVerificationService } from 'src/app/services/customer-verification.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'pos-customer-table',
  templateUrl: './pos-customer-table.component.html',
  styleUrls: ['./pos-customer-table.component.sass'],
})
export class PosCustomerTableComponent implements OnInit {
  constructor(private customerService: CustomerService,
    private confirmationModalService: PosConfirmationService,
    private configService:ConfigurationService,
    private posUpdateCustomerModalService:PosUpdateCustomerModalService,
    private customerVerificationService: CustomerVerificationService,
    private authService: AuthService,
    private reasonSelectModalService:PosReasonSelectModalService) { }
  customers: Paging<Array<CustomerTableModel>> = new Paging([]);

  @Output()
  getCustomer = new EventEmitter<any>();

  @Input()
  showVerificationAlert = false;

  async ngOnInit(): Promise<void> {
    await this.getCustomerData({ page: 0, pageSize: 10, value: {} });
  }

  getCustomerHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('code', 'Código'),
      new ColumnTable('fullName', 'Nombre Completo'),
      new ColumnTable('fiscalId', 'Documento Fiscal'),
      new ColumnTable('fiscalId2', '', { type: "hidden" }),
      new ColumnTable('customerType', 'Tipo', {
        type: 'badge',
        valueMap: this.getCustomerTypeMap(),
      }),
      new ColumnTable('personality', 'Personalidad', {
        type: 'badge',
        valueMap: this.getPersonalityMap(),
      }),
    ];
  }

  getCustomerFilters(): Array<FilterTable> {
    return [
      new FilterTable('text', 'Búsqueda General'),
      new FilterTable('code', 'Código de Cliente'),
      new FilterTable('fiscalId', 'Identidad o RTN'),
      new FilterTable('customerType', 'Tipo de Cliente', {
        type: 'combobox',
        comboBoxFilterValues: this.getCustomerTypeMap(),
      }),
      new FilterTable('personality', 'Personalidad', {
        type: 'combobox',
        comboBoxFilterValues: this.getPersonalityMap(),
      }),
    ];
  }

  getCustomerTypeMap(): Map<string, string> | undefined {
    let result = new Map<string, string>();
    result.set(CustomerType.CORPORATE, 'Corporativo');
    result.set(CustomerType.RETAIL, 'Retail');
    result.set(CustomerType.EMPLOYEE, 'Empleado');
    result.set(CustomerType.INTERCOMPANY, 'Intercompany');
    result.set(CustomerType.FRANCHISED, 'Franquiciado');
    return result;
  }

  getPersonalityMap(): Map<string, string> | undefined {
    let result = new Map<string, string>();
    result.set(Personality.LEGAL_ENTITY, 'Juridico');
    result.set(Personality.PERSON, 'Natural');
    return result;
  }

  async getCustomerData(params: Filter<CustomerFilter>): Promise<void> {
    this.customers = (
      await this.customerService.readCustomers(params).toPromise()
    ).response;
  }

  async selectCustomer(customer: CustomerTableModel) {
    //this.customer = customer;

    if(this.showVerificationAlert){

      const customerNeedUpdateResponse = (await this.customerService
        .customerNeedUpdate(customer.code,this.configService.readSelectedBranchAndSalesOrg().branch.code)
        .toPromise())
        .response;

      if (customerNeedUpdateResponse.customerNeedUpdate) {

        const userWantsToUpdateCustomer = await this.confirmationModalService.showAsync(
          'Actualización de datos',
          `Este cliente necesita actualización de datos ¿Desea ir a actualización de clientes?, ${customerNeedUpdateResponse.message}`,
          ModalDialogType.DANGER,
        );

        if(userWantsToUpdateCustomer){
          await this.posUpdateCustomerModalService.show(customer,customerNeedUpdateResponse.verifyData);
        }else{

          const reason = await this.reasonSelectModalService.show('verifyCustomer');

          const requestToSaveReasonLog:ReasonLogRequest = {
            branchCode:this.configService.readSelectedBranchAndSalesOrg().branch.code,
            component:'SEARCH_CUSTOMER',
            customerCode:customer.code,
            description:reason?.description || '',
            reasonCode:reason?.itemCode || 'RA',
            sellerCode:(await this.authService.readProfile()).username,
            salesOrgCode:this.configService.readSelectedBranchAndSalesOrg().salesOrg.code,
            yesOrNo:'NO'
          };

          await this.customerVerificationService
            .saveReasonToNotUpdateCustomer(requestToSaveReasonLog)
            .toPromise();
        }
      }
    }

    this.getCustomer.emit(customer);
  }

  // selectCustomer old
  // selectCustomer(customer: CustomerTableModel) {
  //   //this.customer = customer;
  //   this.getCustomer.emit(customer);
  //   // this.formCustomer.get("customerFullName")?.setValue(customer.fullName);
  // }


}
