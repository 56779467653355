import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDialogType, VerifyCustomerValidateTokenType } from '../constants';
import { PosVerifyCustomerComponent } from 'src/app/shared/pos-verify-customer/pos-verify-customer.component';
import { VerifyCustomerCheckResponseData } from 'src/app/models/response/VerifyCustomerCheckResponse';

@Injectable({
  providedIn: 'root',
})
export class PosVerifyCustomerModalService {
  private modalRef?: BsModalRef<PosVerifyCustomerComponent>;
  constructor(private modalService: BsModalService) {
    this.modalService.config.backdrop = 'static';
  }

  async show(
    verifyCustomerCheckResponseData: VerifyCustomerCheckResponseData,
    customerCode: string
  ): Promise<boolean> {
    this.modalRef = this.modalService.show(PosVerifyCustomerComponent, {
      class: `modal-dialog modal-md-verification ${ModalDialogType.PRIMARY}`,
      initialState: {
        customerDataToVerify: verifyCustomerCheckResponseData,
        customerCode,
      },
    });

    return (
      this.modalRef.content?.verificationComplete.toPromise() ||
      new Promise(() => false)
    );
  }
}
