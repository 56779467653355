
<accordion [isAnimated]="true">
    <accordion-group [isOpen]="true">
        <span accordion-heading>
            <i class='fa fa-cog'></i> Opciones
          </span>

          <div class="form-group row">
            <div class="col-md-4">
              <button type="button" (click)="pay()"
                class="btn btn-success mr-2">Confirmar Pagos</button>
            </div>
          </div>
    </accordion-group>
      <accordion-group [isOpen]="true">
        <span accordion-heading>
            <i class='cil-cart'></i> Orden
          </span>
        <pos-totals [totals]="order.totals"></pos-totals>
<div class="row">
    <div class="col-12">
<pos-table tableFormId="productsCartTable" [data]="productsCart" [headers]="getProductCartHeaders()" [filters]="[]"
      [itemsPerPage]="10" [pagination]="false" [disableHighlight]="true">
    </pos-table>
    </div>
</div>
      </accordion-group>
      <accordion-group [isOpen]="true">
        <span accordion-heading>
            <i class='fa fa-credit-card'></i> Pagos
          </span>
          <pos-table tableFormId="paymentTable" [data]="payments" [headers]="getPaymentHeaders()" [filters]="[]"
          [itemsPerPage]="10" [pagination]="false" [disableHighlight]="true">
        </pos-table>
          
      </accordion-group>
</accordion>


  