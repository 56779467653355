import { OnbaseService } from 'src/app/services/onbase.service';
import { CreditStatusFranchisedResponseModel } from './../../../../models/response/customerCreditStatusResponseModel';
import { OnbaseProcedureResponseModel } from 'src/app/models/response/onbaseProcedureResponseModel';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Customer } from 'src/app/models/customer';
import { CreditService } from 'src/app/services/credit.service';

@Component({
  selector: 'app-create-credit-request',
  templateUrl: './create-credit-request.component.html',
  styleUrls: ['./create-credit-request.component.sass'],
})
export class CreateCreditRequestComponent implements OnInit {
  fromExternal = true;
  oldPosUrl: string = '';
  customerCreditConditions: CreditStatusFranchisedResponseModel = new CreditStatusFranchisedResponseModel();

  constructor(
    private onbaseService: OnbaseService,
    private authenticationService: AuthService,
    private configurationService: ConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    private creditService: CreditService
  ) { }

  ngOnInit(): void {
    localStorage.setItem('base_url', environment.url);
    this.route.params.subscribe(async (params) => {
      console.log(params);
      const creditRequestNumber = params.creditRequestNumber || -1;
      this.customerCreditConditions = window.history.state
        ?.customerCreditConditions as CreditStatusFranchisedResponseModel;


      if (creditRequestNumber > 0) {
        const request = await this.onbaseService
          .readOnbaseRequestByCode(creditRequestNumber)
          .toPromise();

        if (!this.customerCreditConditions) {
          await this.fetchCustomerCreditInfo(request.response.content[0].customer);
        }

        this.oldPosUrl = `${environment.creditRequestUrl
          }/fatherCreditPlan?fromExternal=${this.fromExternal}&token=${this.authenticationService.readTokenFromStorage().token
          }&customerCode=${request.response.content[0].customer?.code
          }&sellerCode=${this.authenticationService.readProfileInStorage().username
          }&sellerName=${this.authenticationService.readProfileInStorage().fullname
          }&creditPlanType=${request.response.content[0].procedure}&personality=${request.response.content[0].customer?.mainPersonalInfo?.personality
          }&fiscalIdType=${request.response.content[0].customer?.mainPersonalInfo?.fiscalIdType
          }&limitRule=${request.response.content[0].limitRoule
          }&quantity=0&causeOfRequest=0001&typeRequest=loadDraftOnbase&onBaseCode=${creditRequestNumber}&clientType=${request.response.content[0].customer?.mainPersonalInfo?.personality
          }&customerCreditStatus=${this.customerCreditConditions.estadoCliente}`;
      } else {
        const creditPlan = window.history.state
          ?.creditPlan as OnbaseProcedureResponseModel;
        const customer = window.history.state?.customer as Customer;
        const creditStatus = window.history.state
          ?.creditStatus as CreditStatusFranchisedResponseModel;

        if (creditPlan === undefined || customer === undefined) {
          this.router.navigate(['customer']);
        }

        this.oldPosUrl = `${environment.creditRequestUrl
          }/fatherCreditPlan?fromExternal=${this.fromExternal}&token=${this.authenticationService.readTokenFromStorage().token
          }&customerCode=${customer?.code
          }&sellerCode=${this.authenticationService.readProfileInStorage().username
          }&sellerName=${this.authenticationService.readProfileInStorage().fullname
          }&creditPlanType=${creditPlan?.code
          }&personality=${customer?.mainPersonalInfo?.personality}&fiscalIdType=${customer?.mainPersonalInfo?.fiscalIdType
          }&limitRule=${creditPlan?.limitRule}&quantity=0&causeOfRequest=0001
        &customerCreditStatus=${this.customerCreditConditions.estadoCliente}`;
      }

      this.addSelectedBranchAndSalesOrgToOldUrl();
    });

    //this.oldPosUrl = `${environment.creditRequestUrl}/fatherCreditPlan?fromExternal=${this.fromExternal}&token=${this.authenticationService.readTokenFromStorage().access_token}&customerCode=${this.route.snapshot.paramMap.get("customerCode")}&sellerCode=${this.route.snapshot.paramMap.get("sellerCode")}&sellerName=${this.route.snapshot.paramMap.get("sellerName")}&creditPlanType=${this.route.snapshot.paramMap.get("creditPlanType")}&personality=${this.route.snapshot.paramMap.get("personality")}&fiscalIdType=${this.route.snapshot.paramMap.get("fiscalIdType")}&limitRule=${this.route.snapshot.paramMap.get("limitRule")}&quantity=${this.route.snapshot.paramMap.get("quantity")}&causeOfRequest=${this.route.snapshot.paramMap.get("causeOfRequest")}`;
  }

  private addSelectedBranchAndSalesOrgToOldUrl() {
    this.oldPosUrl = `${this.oldPosUrl}&branch=${this.configurationService.readSelectedBranchAndSalesOrg().branch.code
      }&salesOrg=${this.configurationService.readSelectedBranchAndSalesOrg().salesOrg.code
      }&branchName=${this.configurationService.readSelectedBranchAndSalesOrg().branch.name
      }&salesOrgName=${this.configurationService.readSelectedBranchAndSalesOrg().salesOrg.name
      }`;
  }

  private async fetchCustomerCreditInfo(customerCode: Customer): Promise<void> {
    const { branch } =
      this.configurationService.readSelectedBranchAndSalesOrg();
    const { username } = this.authenticationService.readProfileInStorage();

    this.customerCreditConditions = await this.creditService
      .getCustomerCreditStatus(customerCode, branch.code, username)
      .toPromise();
  }
}
