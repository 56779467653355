import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Token } from 'src/app/models/token';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-pos-supervisor-login',
  templateUrl: './pos-supervisor-login.component.html',
  styleUrls: ['./pos-supervisor-login.component.sass']
})
export class PosSupervisorLoginComponent implements OnInit {

  public confirmAction: (() => any) = (() => { return; });

  public privileges: Array<string> = [];
  public username = '';
  public password = '';
  public isJustForLogin = false;
  public disabledUserName = false;
  public validateAllprivileges = true;

  @Output() isValidLoginInformation: EventEmitter<boolean> = new EventEmitter();
  @Output() loginInformation: EventEmitter<{ isValid: boolean, username: string }> = new EventEmitter();


  constructor(private bsModalRef: BsModalRef, private authService: AuthService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  async confirm(): Promise<void> {
    const value = (await this.authService.readToken(this.username, this.password).toPromise());
    const user = (await this.authService.readProfileFromAccessToken(value));
    this.authService.setSuperToken(value);

    if (this.validateAllprivileges ?
      await this.authService.hasPrivilegesInRoles(user.roles, new Set(this.privileges)) :
      await this.authService.hasSomePrivilegeInRoles(user.roles, new Set(this.privileges))
    ) {
      this.confirmAction();
      this.bsModalRef.hide();
      this.loginInformation.emit({ isValid: true, username: this.username });

    }
    else {
      this.toastr.warning('No tiene permiso para accesar este recurso.', '¡Advertencia!');
      this.loginInformation.emit({ isValid: false, username: this.username });

    }

    this.loginInformation.complete();
  }

  async confirmLogin(): Promise<void> {
    const token = await this.authService.readToken(this.username, this.password).toPromise();
    if (token) {
      this.isValidLoginInformation.emit(true);
      this.isValidLoginInformation.complete();
      this.bsModalRef.hide();
    }

  }

  cancel(): void {
    this.bsModalRef.hide();
    this.isValidLoginInformation.emit(false);
    this.isValidLoginInformation.complete();
    this.loginInformation.emit({ isValid: false, username: this.username });
    this.loginInformation.complete();
  }
}
