import { Customer } from 'src/app/models/customer';
import { PosCustomerTableModalComponent } from './../../shared/pos-customer-table-modal/pos-customer-table-modal.component';
import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class PosCustomerTableModalService {
  constructor(private modalService: BsModalService) {}

  public show(): BsModalRef<PosCustomerTableModalComponent> {
    return this.modalService.show(PosCustomerTableModalComponent, {
      class: `modal-dialog modal-lg`,
      ignoreBackdropClick: true,
      animated: true,
    });
  }
}
