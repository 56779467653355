import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCustomerUpdateComponent } from './pos-customer-update.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { PosCustomerCreateAddressModule } from '../pos-customer-create/pos-customer-create-address/pos-customer-create-address.module';
import { PosTableModule } from '../pos-table/pos-table.module';
import { AddContactModalComponent } from './add-contact-modal/add-contact-modal.component';

@NgModule({
  declarations: [PosCustomerUpdateComponent, AddContactModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgSelectModule,
    PosCustomerCreateAddressModule,
    PosTableModule
  ],
  exports:[PosCustomerUpdateComponent, AddContactModalComponent]
})
export class PosCustomerUpdateModule { }
