import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BiometryFingerPrintComponent } from './biometryFingerprint.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BiometryService } from 'src/app/services/biometry.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';



@NgModule({
  declarations: [BiometryFingerPrintComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TabsModule
  ],
  providers: [DatePipe, BiometryService, ConfigurationService, AuthService],
  exports: [BiometryFingerPrintComponent]
})
export class BiometryFingerPrintModule { }
