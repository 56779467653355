import { DatePipe, Location } from '@angular/common';
import { UnaryOperator } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {
  CountryHN,
  CurrentCountry,
  FiscalIdTypeAlwaysShow,
  FiscalIdTypeRTN,
  HN_FISCAL_TYPE_CODE,
  ParamPhoneMaskHn,
  ParamPhoneMaskNoHn,
  Personality,
  phoneMinimumCharacters,
} from 'src/app/@core/constants';
import { Customer } from 'src/app/models/customer';
import { FiscalIdTypes } from 'src/app/models/fiscalIdTypes';
import { Nationality } from 'src/app/models/nationality';
import { Paging } from 'src/app/models/page';
import { CreateCustomerRequestModel } from 'src/app/models/request/createCustomerRequestModel';
import { CustomerCreateResponseModel } from 'src/app/models/response/customerCreateResponseModel';
import { ResponseFormAddress } from 'src/app/models/responseFormAddress';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { CustomerService } from 'src/app/services/customer.service';
import { ElectoralRegistersService } from 'src/app/services/electoral-registers.service';
import { FiscalIdService } from 'src/app/services/fiscal-id.service';
import { NationalityService } from 'src/app/services/nationality.service';
import { ParamsService } from 'src/app/services/params.service';
import { PosCustomerCreateAddressComponent } from '../pos-customer-create-address/pos-customer-create-address.component';

@Component({
  selector: 'app-pos-customer-create-person',
  templateUrl: './pos-customer-create-person.component.html',
  styleUrls: ['./pos-customer-create-person.component.sass'],
})
export class PosCustomerCreatePersonComponent implements OnInit {

  @Input() showBack = true;
  formCustomerPerson: FormGroup = this.fb.group({});
  nationalities: Array<Nationality> = new Array<Nationality>();
  fiscalIdTypes: Array<FiscalIdTypes> = new Array<FiscalIdTypes>();
  currentDate: Date = new Date();

  nationalitySelected: String = CurrentCountry;
  countryResidenceSelected: String = CurrentCountry;
  fiscalIdTypeSelected: String = '';
  mask: string = '';
  minLength: number = 0;
  maskRTN: string = '';
  minLengthRTN: number = 0;

  phoneMaskHn: string = '';
  phoneMaskNoHn: string = '';
  phoneMask: string = '';
  countryResidenceMinLength: number = 0;

  needInputRequired: boolean = false;
  created: boolean = false;
  customerCreated: CustomerCreateResponseModel =
    new CustomerCreateResponseModel();

  customerRequestModel: CreateCustomerRequestModel =
    new CreateCustomerRequestModel();

  @ViewChild('FormAddessCustomerPerson')
  formAddessCustomerPerson!: PosCustomerCreateAddressComponent;

  @Output()
  saveCustomerEmitter = new EventEmitter<CustomerTableModel>();

  @Input()
  redirect: boolean = true;
  constructor(
    private fb: FormBuilder,
    private nationalityServices: NationalityService,
    private fiscalIdService: FiscalIdService,
    private paramsService: ParamsService,
    private datePipe: DatePipe,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private router: Router,
    private location: Location,
    private electoralRegistersService: ElectoralRegistersService
  ) {
    this.buildForm();
    this.listenControlChanges();
  }

  async ngOnInit(): Promise<void> {
    this.nationalities = (
      await this.nationalityServices.readNationalities().toPromise()
    ).response.content;
    this.nationalities = this.nationalities.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    await this.getFiscalIds(CurrentCountry);
    let params: any = (await this.paramsService.readParams().toPromise())
      .response;
    this.phoneMaskHn = params[ParamPhoneMaskHn];
    this.phoneMaskNoHn = params[ParamPhoneMaskNoHn];
    this.countryResidenceMinLength = Number(params[phoneMinimumCharacters]);
    this.addMaskInput(this.countryResidenceSelected.toString());
  }

  buildForm() {
    this.formCustomerPerson = this.fb.group({
      nationality: ['', Validators.required],
      fiscalIdType: { Validators: [Validators.required], updateOn: 'blur' },
      fiscalId: ['', Validators.required],
      fiscalId2: [''],
      firstName: ['', Validators.required],
      secondName: [''],
      firstLastName: ['', Validators.required],
      secondLastName: [''],
      birthDate: ['', Validators.required],
      gender: ['', Validators.required],
      countryResidence: ['', Validators.required],
      phone: ['', []],
      phoneResidence: [''],
      email: ['', [Validators.email]],
      phoneWork: [''],
      phoneWorkExtention: [''],
    });
  }

  async initializeForm() {
    this.formCustomerPerson
      .get('nationality')
      ?.setValidators([Validators.required]);
    this.formCustomerPerson
      .get('fiscalIdType')
      ?.setValidators([Validators.required]);
    this.formCustomerPerson
      .get('fiscalId')
      ?.setValidators([Validators.required]);
    this.formCustomerPerson.get('fiscalId2')?.setValidators([]);
    this.formCustomerPerson
      .get('firstName')
      ?.setValidators([Validators.required]);
    this.formCustomerPerson.get('secondName')?.setValidators([]);
    this.formCustomerPerson
      .get('firstLastName')
      ?.setValidators([Validators.required]);
    this.formCustomerPerson.get('secondLastName')?.setValidators([]);
    this.formCustomerPerson
      .get('birthDate')
      ?.setValidators([Validators.required]);
    this.formCustomerPerson.get('gender')?.setValidators([Validators.required]);
    this.formCustomerPerson
      .get('countryResidence')
      ?.setValidators([Validators.required]);
    this.formCustomerPerson.get('phone')?.setValidators([]);
    this.formCustomerPerson.get('phoneResidence')?.setValidators([]);
    this.formCustomerPerson.get('email')?.setValidators([Validators.email]);

    this.formCustomerPerson.get('fiscalId')?.reset();
    this.formCustomerPerson.get('fiscalIdType')?.reset();
    this.formCustomerPerson.get('fiscalId2')?.reset();
    this.formCustomerPerson.get('birthDate')?.reset();
    this.formCustomerPerson.get('firstName')?.reset();
    this.formCustomerPerson.get('secondName')?.reset();
    this.formCustomerPerson.get('firstLastName')?.reset();
    this.formCustomerPerson.get('secondLastName')?.reset();
    this.formCustomerPerson.get('phoneResidence')?.reset();
    this.formCustomerPerson.get('phone')?.reset();
    this.formCustomerPerson.get('phoneWork')?.reset();
    this.formCustomerPerson.get('phoneWorkExtention')?.reset();
    this.formCustomerPerson.get('email')?.reset();
    this.formCustomerPerson.get('gender')?.reset();
    this.nationalitySelected = CurrentCountry;
    this.countryResidenceSelected = CurrentCountry;
    await this.getFiscalIds(CurrentCountry);
    await this.addMaskInput(CurrentCountry.toString());
    this.formAddessCustomerPerson.reset();
  }

  async saveCustomerPerson() {
    if (
      this.formCustomerPerson.valid &&
      this.formAddessCustomerPerson.formCustomerAddress.valid
    ) {
      this.needInputRequired = this.validateNeedInputRequired();
      if (this.needInputRequired) return;

      let request: CreateCustomerRequestModel =
        this.mapFormToCustomerRequestModel();

      this.customerCreated = (
        await this.customerService.createCustomer(request).toPromise()
      ).response;
      this.created = true;
      this.toastr.success('Cliente creado correctamente');
      await this.initializeForm();

      this.saveCustomerOutput();
      if (this.redirect) {
        this.router.navigate(
          ['/customer', this.customerCreated.code],
          { replaceUrl: true }
        );
      }

    } else {
      this.toastr.warning('Ingrese todos los campos obligatorios');
    }
  }

  private saveCustomerOutput() {
    let customerObject = this.customerCreated as unknown as Customer;
    let result = new CustomerTableModel();
    result.code = customerObject.code;
    result.customerType = customerObject.customerType;
    result.fiscalId = customerObject.personalInfo[0].fiscalId
    result.fiscalId2 = customerObject.personalInfo[0].fiscalId2;
    result.fullName = customerObject.fullName;
    result.personality = customerObject.personalInfo[0].personality;
    result.useFiscalId2 = false;
    this.saveCustomerEmitter.emit(result);
  }

  validateNeedInputRequired(): boolean {
    return (
      String(this.formCustomerPerson.get('phone')?.value || '').length == 0 &&
      String(this.formCustomerPerson.get('phoneWork')?.value || '').length == 0 &&
      String(this.formCustomerPerson.get('email')?.value || '').length == 0
    );
  }

  changeItemFiscalIdType(fiscalIdTypes: FiscalIdTypes) {
    this.mask =
      this.fiscalIdTypes?.find((x) => x.code == fiscalIdTypes.code)?.mask || '';
    this.minLength =
      this.fiscalIdTypes?.find((x) => x.code == fiscalIdTypes.code)
        ?.minLength || 0;
    this.formCustomerPerson.clearValidators();
    if (this.mask.length > 0)
      this.formCustomerPerson
        .get('fiscalId')
        ?.setValidators([
          Validators.minLength(this.minLength),
          Validators.required,
        ]);
    else
      this.formCustomerPerson
        .get('fiscalId')
        ?.setValidators([Validators.required]);

    this.formCustomerPerson.get('fiscalId')?.updateValueAndValidity();
  }

  fiscalId2Required(event: KeyboardEvent) {
    let RTN = (event.target as HTMLInputElement).value;
    if (RTN.length > 0)
      this.formCustomerPerson
        .get('fiscalId2')
        ?.setValidators([Validators.minLength(this.minLengthRTN)]);
    else this.formCustomerPerson.get('fiscalId2')?.clearValidators();

    this.formCustomerPerson.get('fiscalId2')?.updateValueAndValidity();
  }

  async changeNationality(nationality: Nationality) {
    await this.getFiscalIds(nationality.code);
  }

  async getFiscalIds(countryCode: String) {
    this.fiscalIdTypeSelected = '';
    this.mask = '';

    this.fiscalIdTypes = (
      await this.fiscalIdService.readFiscalIds().toPromise()
    ).response;

    let countryFiscalId: Array<String> = this.fiscalIdTypes.map(
      (x) => x.country
    );

    if (countryFiscalId.some((x) => x == countryCode)) {
      this.maskRTN =
        this.fiscalIdTypes?.find((x) => x.code == FiscalIdTypeRTN)?.mask || '';
      this.minLengthRTN =
        this.fiscalIdTypes?.find((x) => x.code == FiscalIdTypeRTN)?.minLength ||
        0;
      this.fiscalIdTypes = this.fiscalIdTypes.filter(
        (x) => x.country == countryCode && x.showCreateCustomer
      );
    } else {
      this.fiscalIdTypes = this.fiscalIdTypes.filter((x) =>
        FiscalIdTypeAlwaysShow.some((y) => y == x.code)
      );
    }

    if (this.fiscalIdTypes.length > 0) {
      this.fiscalIdTypeSelected = this.fiscalIdTypes[0].code;
      this.mask = this.fiscalIdTypes[0].mask;
      this.minLength = this.fiscalIdTypes[0].minLength;
      this.formCustomerPerson
        .get('fiscalId')
        ?.setValidators([
          Validators.minLength(this.minLength),
          Validators.required,
        ]);
    }
  }

  changeCountryResidence(nationality: Nationality) {
    this.addMaskInput(nationality.code);
  }

  addMaskInput(countryCode: string) {
    if (countryCode == CountryHN) this.phoneMask = this.phoneMaskHn;
    else this.phoneMask = this.phoneMaskNoHn;
  }

  countryResidenceMinimunCharacters(event: KeyboardEvent, control: string) {
    this.setCountryResidenceMinimunCharacters(
      (event.target as HTMLInputElement).value,
      control
    );
  }

  setCountryResidenceMinimunCharacters(
    countryResidence: String,
    control: string
  ) {
    if (countryResidence.length > 0)
      this.formCustomerPerson
        .get(control)
        ?.setValidators([Validators.minLength(this.countryResidenceMinLength)]);
    else this.formCustomerPerson.get(control)?.clearValidators();

    this.formCustomerPerson.get(control)?.updateValueAndValidity();
  }

  mapFormToCustomerRequestModel(): CreateCustomerRequestModel {
    let customerRequestModel = new CreateCustomerRequestModel();
    customerRequestModel.fiscalId =
      this.formCustomerPerson.get('fiscalId')?.value;
    customerRequestModel.fiscalIdType =
      this.formCustomerPerson.get('fiscalIdType')?.value;
    customerRequestModel.fiscalId2 =
      this.formCustomerPerson.get('fiscalId2')?.value;

    if (customerRequestModel.fiscalId2) {
      customerRequestModel.fiscalIdType2 = HN_FISCAL_TYPE_CODE;
    }

    customerRequestModel.countryId =
      this.formCustomerPerson.get('countryResidence')?.value;
    customerRequestModel.personality = Personality.PERSON;
    customerRequestModel.birthdate =
      this.datePipe
        .transform(this.formCustomerPerson.get('birthDate')?.value, 'YYYYMMdd')
        ?.toString() || '';
    customerRequestModel.name = this.formCustomerPerson.get('firstName')?.value;
    customerRequestModel.middleName =
      this.formCustomerPerson.get('secondName')?.value;

    customerRequestModel.lastName =
      this.formCustomerPerson.get('firstLastName')?.value;
    customerRequestModel.lastName2 =
      this.formCustomerPerson.get('secondLastName')?.value;
    customerRequestModel.homePhone =
      this.formCustomerPerson.get('phoneResidence')?.value;
    customerRequestModel.cellularPhone =
      this.formCustomerPerson.get('phone')?.value;

    customerRequestModel.workPhone =
      this.formCustomerPerson.get('phoneWork')?.value;
    customerRequestModel.extensionPhone =
      this.formCustomerPerson.get('phoneWorkExtention')?.value;
    customerRequestModel.email = this.formCustomerPerson.get('email')?.value;
    customerRequestModel.gender = this.formCustomerPerson.get('gender')?.value;
    customerRequestModel.nationality =
      this.formCustomerPerson.get('nationality')?.value;

    //UpperCase
    customerRequestModel.name = customerRequestModel.name?.toUpperCase();
    customerRequestModel.middleName =
      customerRequestModel.middleName?.toUpperCase();
    customerRequestModel.lastName =
      customerRequestModel.lastName?.toUpperCase();
    customerRequestModel.lastName2 =
      customerRequestModel.lastName2?.toUpperCase();
    customerRequestModel.email = customerRequestModel.email?.toUpperCase();

    let formResponseAddress: ResponseFormAddress =
      this.formAddessCustomerPerson.mapRequestAddress();
    customerRequestModel.municipality =
      formResponseAddress.municipality?.toUpperCase();
    customerRequestModel.municipalityName =
      formResponseAddress.municipalityName?.toUpperCase();
    customerRequestModel.neighborhood =
      formResponseAddress.neighborhood?.toUpperCase();
    customerRequestModel.neighborhoodName =
      formResponseAddress.neighborhoodName?.toUpperCase();
    customerRequestModel.street = formResponseAddress.street?.toUpperCase();
    customerRequestModel.street2 = formResponseAddress.street2?.toUpperCase();
    customerRequestModel.street3 = formResponseAddress.street3?.toUpperCase();
    customerRequestModel.street4 = formResponseAddress.street4?.toUpperCase();
    customerRequestModel.street5 = formResponseAddress.street5?.toUpperCase();
    customerRequestModel.department =
      formResponseAddress.department?.toUpperCase();
    customerRequestModel.addressCountryId =
      formResponseAddress.addressCountryId?.toUpperCase();

    return customerRequestModel;
  }

  goBack() {
    this.location.back();
  }

  async findCustomer(event: any): Promise<void> {
    const response = await this.electoralRegistersService.retrieveElectoralRegisters(event.target.value).toPromise();
    const userList = response.response.content;

    if (userList.length) {
      const user = userList[0];
      this.formCustomerPerson.get('firstName')?.patchValue(user.name);
      this.formCustomerPerson.get('secondName')?.patchValue(user.secondName);
      this.formCustomerPerson.get('firstLastName')?.patchValue(user.lastName);
      this.formCustomerPerson.get('secondLastName')?.patchValue(user.secondLastName);
      this.formCustomerPerson.get('gender')?.patchValue(user.sex);

      if (user.birthdate) {
        // format: YYYYMMDD
        const year = +user.birthdate.substring(0, 4);
        const month = +user.birthdate.substring(4, 6);
        const day = +user.birthdate.substring(6);
        const birthdate = new Date(year, month - 1, day);
        this.formCustomerPerson.get('birthDate')?.patchValue(birthdate);
      }

    } else {
      this.formCustomerPerson.get('firstName')?.patchValue('');
      this.formCustomerPerson.get('secondName')?.patchValue('');
      this.formCustomerPerson.get('firstLastName')?.patchValue('');
      this.formCustomerPerson.get('secondLastName')?.patchValue('');
      this.formCustomerPerson.get('gender')?.patchValue('');
      this.formCustomerPerson.get('birthDate')?.patchValue('');
    }

  }

  private listenControlChanges() {

    const firstName = this.formCustomerPerson.get('firstName')!;
    const secondName = this.formCustomerPerson.get('secondName')!;
    const firstLastName = this.formCustomerPerson.get('firstLastName')!;
    const secondLastName = this.formCustomerPerson.get('secondLastName')!;

    firstName.valueChanges.subscribe(() => {
      this.controlOnlyLetters(firstName);
    });
    secondName.valueChanges.subscribe(() => {
      this.controlOnlyLetters(secondName);
    });
    firstLastName.valueChanges.subscribe(() => {
      this.controlOnlyLetters(firstLastName);
    });
    secondLastName.valueChanges.subscribe(() => {
      this.controlOnlyLetters(secondLastName);
    });
  }

  private controlOnlyLetters(control: AbstractControl) {
    const value: string = control.value;
    if (!value.match(/^[a-zA-Z]+$/)) {
      control.patchValue(value.replace(/[\d]+/g, ''), { emitEvent: false });
    }
  }
}
