import { PosCustomerTableModalService } from './../../@core/services/pos-customer-table-modal.service';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Customer } from 'src/app/models/customer';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';

@Component({
  selector: 'app-pos-customer-table-modal',
  templateUrl: './pos-customer-table-modal.component.html',
  styleUrls: ['./pos-customer-table-modal.component.sass'],
})
export class PosCustomerTableModalComponent implements OnInit {
  public customerSelectedEvent: EventEmitter<CustomerTableModel> = new EventEmitter();
  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  handleCustomerSelect(customer: CustomerTableModel): void {
    this.customerSelectedEvent.emit(customer);
    this.bsModalRef.hide();
  }

  close(): void {
    this.bsModalRef.hide();
  }
}
