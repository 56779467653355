<form [formGroup]="creditForm" (ngSubmit)="creditForm.valid && calculateFee()"
  (keydown.enter)="$event.preventDefault()">
  <div class="container p-2">
    <div class="form-group row mt-2">

      <label class="col-md-2 col-form-label" for="text-input"><b>Plazo</b></label>
      <div class="col-md-10">
        <select class="form-control" placeholder="Plazo" formControlName="term"
          [class.is-invalid]="(creditForm.get('term')?.touched || creditForm.get('term')?.dirty) && creditForm.get('term')?.invalid" (change)="handleTermChange()">
          <option value="0">Seleccione un plazo</option>
          <option *ngFor="let term of termArray" [value]="term">
            {{term}}&nbsp;{{term == 1 ? "Mes" : "Meses"}}

          </option>
        </select>

      </div>
    </div>
    <div class="form-group row mt-1" *ngIf="!hideTermsAndDates">
      <label class="col-md-2 col-form-label" for="text-input"><b>Prima %</b></label>
      <div class="col-md-10">
        <input type="number" min="0" class="form-control" formControlName="downPaymentPercent"
        (keyup.enter)="(creditForm.get('downPaymentPercent')?.touched || creditForm.get('downPaymentPercent')?.dirty) && creditForm.get('downPaymentPercent')?.valid && changePercentDownPayment()"
          (change)="(creditForm.get('downPaymentPercent')?.touched || creditForm.get('downPaymentPercent')?.dirty) && creditForm.get('downPaymentPercent')?.valid && changePercentDownPayment()"
          [class.is-invalid]="(creditForm.get('downPaymentPercent')?.touched || creditForm.get('downPaymentPercent')?.dirty) && creditForm.get('downPaymentPercent')?.invalid"
          mask="separator.2">
        <small class="text-muted">
          La prima % debe de ser entre {{creditPromotion.code !== '1' ? creditPromotion.buy.downPayment :  customerCreditConditions.minDownPayment }} a
          {{customerCreditConditions.maxDownPayment }}
        </small>
      </div>
    </div>
    <div class="form-group row mt-1" *ngIf="!hideTermsAndDates">
      <label class="col-md-2 col-form-label" for="text-input"><b>Prima <i class="fa fa-money"></i></b></label>
      <div class="col-md-10">
        <input type="number" min="0" class="form-control" formControlName="downPaymenyCurrency"
        (keyup.enter)="(creditForm.get('downPaymenyCurrency')?.touched || creditForm.get('downPaymenyCurrency')?.dirty) && creditForm.get('downPaymenyCurrency')?.valid && changeCurrencyDownPayment()"
          (change)="(creditForm.get('downPaymenyCurrency')?.touched || creditForm.get('downPaymenyCurrency')?.dirty) && creditForm.get('downPaymenyCurrency')?.valid && changeCurrencyDownPayment()"
          [class.is-invalid]="(creditForm.get('downPaymenyCurrency')?.touched || creditForm.get('downPaymenyCurrency')?.dirty) && creditForm.get('downPaymenyCurrency')?.invalid">
        <small class="text-muted">
         {{getTextDownPayment()}}
        </small>
      </div>
    </div>
    <div class="form-group row mt-1" *ngIf="!hideTermsAndDates">
      <label class="col-md-2 col-form-label" for="text-input"><b>Fecha Inicial</b></label>
      <div class="col-md-10">
        <input type="text" class="form-control" formControlName="initialDate">
      </div>
    </div>
    <div class="form-group row mt-1" *ngIf="!hideTermsAndDates">
      <label class="col-md-2 col-form-label" for="text-input"><b>Fecha de Primera Cuota</b></label>
      <div class="col-md-10">
        <pos-credit-calendar [loanClass]="customerCreditConditions.loanClass"
          [productClass]="customerCreditConditions.productClass" [blockFirstThirtyDays]="true" [gracePeriod]="creditPromotion.buy.gracePeriod"
          [creditForm]="getFirstFeeDateFormControl()" (valueChange)="handleFirstDateQuotaChange($event)" #creditCalendar>
        </pos-credit-calendar>
      </div>
    </div>
    <div class="form-group row mt-1">
      <label class="col-md-2 col-form-label" for="text-input"><b>Cuota</b></label>
      <div class="col-md-8">
        <input type="text" class="form-control" formControlName="fee">
      </div>
      <div class="col-md-2">
       
        <button class="btn btn-primary float-right"
          [disabled]="creditForm.get('term')?.invalid || creditForm.get('downPaymentPercent')?.invalid || creditForm.get('downPaymenyCurrency')?.invalid"
          (click)="calculateFee()">Calcular Cuota</button>
      </div>
    </div>
  </div>
</form>
