import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderConditionsType } from 'src/app/@core/constants';
import { Customer } from 'src/app/models/customer';
import { CustomerCreditValidation } from 'src/app/models/customer-credit-validation';
import { Order } from 'src/app/models/order';
import { CreditService } from 'src/app/services/credit.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-pos-credit-installments',
  templateUrl: './pos-credit-installments.component.html',
  styleUrls: ['./pos-credit-installments.component.sass']
})
export class PosCreditInstallmentsComponent implements OnInit, OnChanges
{
  @Input() customer: Customer=new Customer();
  @ViewChild('cdkStepper') cdkStepper: CdkStepper | null = null;
  creditConditions: CustomerCreditValidation | null = null;
  generatedOrder: Order = new Order();

  constructor(private creditService:CreditService) { }
  
  ngOnInit(): void 
  {

  }

  ngOnChanges(): void {
    const customerCode = this.customer?.code

    if (customerCode) {
      this.creditService.getCustomerCreditConditions(customerCode, OrderConditionsType.CREDIT).subscribe(respModel => {
        this.creditConditions = respModel.response;
      });
    }
  }

  get minimun(): number {
    return this.creditConditions?.minDownPayment || 0;
  }

  get maximun(): number {
    return this.creditConditions?.maxDownPayment || 0;
  }

  get customerCreditConditions(): CustomerCreditValidation {
    return this.creditConditions || new CustomerCreditValidation();
  }

  onSuccessfulApplication(order: Order) {
    order.customerCode = this.customer?.code || '';
    this.generatedOrder = order;
    this.cdkStepper?.next();
  }
}
