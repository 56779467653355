export class LoanAvailableDay {
    code: string;
    salesOrg: string;
    productClass: string;
    loanClass: string;
    availableDays: { [i: number]: boolean };

    constructor() {
        this.code = "";
        this.salesOrg = "";
        this.productClass = "";
        this.loanClass = "";

        this.availableDays = {};
        for (let i = 1; i <= 31; i++) {
            this.availableDays[i] = false;
        }
    }
}