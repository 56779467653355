import { PosCustomerTableModule } from './../pos-customer-table/pos-customer-table.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCustomerTableModalComponent } from './pos-customer-table-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PosCustomerTableModalComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    PosCustomerTableModule,
    RouterModule,
  ],
})
export class PosCustomerTableModalModule {}
