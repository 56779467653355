export class Region {
    country: string;
    id: string;
    name: string;
    region: string;

    constructor(){
        this.country = "";
        this.id = "";
        this.name = "";
        this.region = "";
    }
}
