import { CustomerType, Personality } from "src/app/@core/constants";

export class CustomerTableModel {

    code: string;
    fullName: string;
    personality: Personality;
    customerType: CustomerType;
    fiscalId: string;
    fiscalId2: string;
    useFiscalId2: boolean;
    constructor() {
        this.code = "";
        this.fullName = "";
        this.personality = Personality.PERSON;
        this.customerType = CustomerType.RETAIL;
        this.fiscalId = "";
        this.fiscalId2 = "";
        this.useFiscalId2 = false;
    }
}
