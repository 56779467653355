import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosPaymentMethodComponent } from './pos-payment-method.component';
import { FormsModule } from '@angular/forms';
import { AddPaymentModalComponent } from './add-payment-modal/add-payment-modal.component';
import { PosTableModule } from '../pos-table/pos-table.module';



@NgModule( {
  declarations: [
    PosPaymentMethodComponent,
    AddPaymentModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PosTableModule
  ],
  exports: [ PosPaymentMethodComponent ]
} )
export class PosPaymentMethodModule { }
