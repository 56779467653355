import { Customer } from 'src/app/models/customer';
import { CustomerVerificationService } from './../../../../services/customer-verification.service';
import { Component, Input, OnInit } from '@angular/core';
import {
  VerifyCustomerCheckResponse,
  VerifyCustomerCheckResponseData,
} from 'src/app/models/response/VerifyCustomerCheckResponse';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { VerifyCustomerValidateTokenType } from 'src/app/@core/constants';

@Component({
  selector: 'app-customer-phone-and-mail',
  templateUrl: './customer-phone-and-mail.component.html',
  styleUrls: ['./customer-phone-and-mail.component.sass'],
})
export class CustomerPhoneAndMailComponent implements OnInit {
  @Input('customer') set setCustomer(customer: Customer) {
    this.currentCustomer = customer;
    if (this.currentCustomer.code != '') {
      this.loadVerificationDataCustomer();
    }
  }

  public currentCustomer: Customer;
  public verifyCustomerData: VerifyCustomerCheckResponseData | undefined;
  constructor(
    private customerVerificationService: CustomerVerificationService,
    private configurationService: ConfigurationService
  ) {
    this.currentCustomer = new Customer();
  }

  ngOnInit() {}

  async loadVerificationDataCustomer(): Promise<void> {
    const customerVerificationResponse = await this.customerVerificationService
      .getCustomerContactInformationVerification(this.currentCustomer.code)
      .toPromise();
    this.verifyCustomerData = customerVerificationResponse.response.data;
    // const sendTokenVerifyCustomerResponse =
    //   await this.customerVerificationService
    //     .sendTokenToVerifyCustomer(this.currentCustomer.code, {
    //       branchCode:
    //         this.configurationService.readSelectedBranchAndSalesOrg().branch
    //           .code,
    //       email: 'jose.santos@grupoilp.hn',

    //       salesOrg:
    //         this.configurationService.readSelectedBranchAndSalesOrg().salesOrg
    //           .code,
    //     })
    //     .toPromise();
    // console.log(sendTokenVerifyCustomerResponse);
    // const validateTokenResponse = await this.customerVerificationService
    //   .validateToken(this.currentCustomer.code, {
    //     token: '573846',
    //     type: VerifyCustomerValidateTokenType.EMAIL,
    //   })
    //   .toPromise();
    // console.log(validateTokenResponse);
  }
}
