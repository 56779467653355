import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { DateUtils } from 'src/app/@core/utils/date-utils';
import { LoanAvailableDay } from 'src/app/models/loan-available-day';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LoanAvailableDaysService } from 'src/app/services/loan-available-days.service';
import { ParamsService } from 'src/app/services/params.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'pos-credit-calendar',
  templateUrl: './pos-credit-calendar.component.html',
  styleUrls: ['./pos-credit-calendar.component.sass'],
})
export class PosCreditCalendarComponent implements OnInit, OnChanges {
  constructor(
    private loanAvailableDaysService: LoanAvailableDaysService,
    private configurationService: ConfigurationService,
    private paramsService: ParamsService
  ) {}

  @Input() public currentDate: Date = new Date();

  @Input() public loanClass: string = '';

  @Input() public productClass: string = '';

  @Input() public daysOffset: number = 0;

  @Input() public blockFirstThirtyDays: boolean = true;

  @Input() public creditForm: FormControl = new FormControl();

  @Output() public valueChange = new EventEmitter<Date>();

  @Input() public gracePeriod: number = 0;

  public datesEnabled: Array<Date> = [];
  public dateCustomClasses: Array<DatepickerDateCustomClasses> = [];

  public minDate: Date = new Date();
  public maxDate: Date = new Date();
  private loanAvailableDays: LoanAvailableDay[] = [];

  async ngOnInit(): Promise<void> {
    //this.datesEnabled.push(new Date('2021-04-29'))
    this.loanAvailableDays = (
      await this.loanAvailableDaysService.readLoanAvailableDays().toPromise()
    ).response;

    let maxLoanDaysFirstPayment = '0';

    if (this.gracePeriod > 0) {
      maxLoanDaysFirstPayment = this.gracePeriod.toString();
    } else {
      maxLoanDaysFirstPayment = (
        await this.paramsService.readParams().toPromise()
      ).response['max-day-loans-first-payment'];
    }

    this.daysOffset =
      this.daysOffset > 0
        ? this.daysOffset
        : Number.parseInt(
            maxLoanDaysFirstPayment ? maxLoanDaysFirstPayment : '30'
          );
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    console.log(`[pos-credit-calendar-component] ====> changes ====>`, changes);

    this.loanAvailableDays = (
      await this.loanAvailableDaysService.readLoanAvailableDays().toPromise()
    ).response;

    let maxLoanDaysFirstPayment = (
      await this.paramsService.readParams().toPromise()
    ).response['max-day-loans-first-payment'];

    this.daysOffset =
      this.daysOffset > 0
        ? this.daysOffset
        : Number.parseInt(
            maxLoanDaysFirstPayment ? maxLoanDaysFirstPayment : 30
          );

    let days =
      this.loanAvailableDays.find(
        (f) =>
          f.loanClass == this.loanClass &&
          f.productClass == this.productClass &&
          f.salesOrg ==
            this.configurationService.readSelectedBranchAndSalesOrg().salesOrg
              .code
      ) || new LoanAvailableDay();

    //this.configurationService.readSelectedBranchAndSalesOrg().salesOrg;
    let result = this.configureSelectedDate(days);

    this.currentDate = this.currentDate >= result ? this.currentDate : result;

    this.datesEnabled = [];

    this.dateCustomClasses = [];

    this.configureCalendar(days);

    this.creditForm.setValue(this.currentDate);
  }

  configureSelectedDate(days: LoanAvailableDay): Date {
    let currentDay = new Date(Date.now()).getDate();
    let month = new Date(Date.now()).getMonth() + 1;
    let year = new Date(Date.now()).getFullYear();
    let calculatedDate = new Date(year, month, currentDay);
    // if (!days.availableDays[currentDay]) {
    let tempDate = new Date();
    let i = true;
    while (i) {
      let tempDay = tempDate.getDate();
      let tempMonth = tempDate.getMonth();
      let tempYear = tempDate.getFullYear();

      if (days.availableDays[tempDay] && tempDate > calculatedDate) {
        i = false;
        return new Date(tempYear, tempMonth, tempDay);
      }
      if (!Object.values(days.availableDays).includes(true)) {
        i = false;
        return new Date();
      }
      tempDate.setDate(tempDay + 1);
    }
    //}
    return new Date();
  }

  private async configureCalendar(days: LoanAvailableDay): Promise<void> {
    let currentDay = new Date(Date.now()).getDate();
    let month = new Date(Date.now()).getMonth() + 1;
    let year = new Date(Date.now()).getFullYear();
    let calculatedDate = new Date(year, month, currentDay);
    let dateWithParams = new Date();
    dateWithParams.setDate(dateWithParams.getDate() + this.daysOffset);

    this.minDate = new Date(
      this.blockFirstThirtyDays ? this.currentDate : new Date()
    );
    this.maxDate = new Date(dateWithParams);

    let tempDate = new Date();
    let i = true;
    while (i) {
      let tempDay = tempDate.getDate();
      let tempMonth = tempDate.getMonth();
      let tempYear = tempDate.getFullYear();
      let clazz = '';
      if (
        (days.availableDays[tempDay] && !this.blockFirstThirtyDays) ||
        (days.availableDays[tempDay] &&
          tempDate > calculatedDate &&
          this.blockFirstThirtyDays)
      ) {
        clazz = 'bg-success';
        this.datesEnabled.push(new Date(tempYear, tempMonth, tempDay));
      } else {
        clazz = 'bg-danger';
      }
      this.dateCustomClasses.push({
        date: new Date(tempYear, tempMonth, tempDay),
        classes: [clazz],
      });
      tempDate.setDate(tempDay + 1);

      if (tempDate > dateWithParams) {
        i = false;
      }
    }
  }

  handleChange(event: Date): void {
    this.valueChange.emit(event);
  }

  public reConfigureSelectDate(): void {
    let days =
      this.loanAvailableDays.find(
        (f) =>
          f.loanClass == this.loanClass &&
          f.productClass == this.productClass &&
          f.salesOrg ==
            this.configurationService.readSelectedBranchAndSalesOrg().salesOrg
              .code
      ) || new LoanAvailableDay();

    let result = this.configureSelectedDate(days);

    this.currentDate = this.currentDate >= result ? this.currentDate : result;

    this.creditForm.setValue(this.currentDate);
  }
}
