import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CountryHN, OnBaseCatalogueType, ParamPhoneMaskHn } from 'src/app/@core/constants';
import { Bank } from 'src/app/models/bank';
import { AdvanceFilter } from 'src/app/models/filters/advance-filter';
import { Param } from 'src/app/models/param';
import { PaymentMethod } from 'src/app/models/payment-method';
import { CustomerUpdateContact } from 'src/app/models/request/CustomerUpdateContact';
import { AdvanceService } from 'src/app/services/advance.service';
import { BankService } from 'src/app/services/bank.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ExchangeRateService } from 'src/app/services/exchange-rate.service';
import { GiftCardService } from 'src/app/services/gift-card.service';
import { LoyaltyPointsService } from 'src/app/services/loyalty-points.service';
import { OnbaseService } from 'src/app/services/onbase.service';
import { OpenItemsService } from 'src/app/services/open-items.service';
import { ParamsService } from 'src/app/services/params.service';
import { SalesService } from 'src/app/services/sales.service';
import { VoucherService } from 'src/app/services/voucher.service';
import { Filter } from '../../../models/filter';

@Component({
  selector: 'app-contact-payment-modal',
  templateUrl: './add-contact-modal.component.html',
  styleUrls: ['./add-contact-modal.component.sass']
})
export class AddContactModalComponent implements OnInit {

  confirmAction: (customerUpdateContact: CustomerUpdateContact) => any = () => { };
  add: (customerUpdateContact: CustomerUpdateContact) => any = () => { };

  formContact: FormGroup = this.fb.group({});
  countryResidenceMinLength: number = 9;
  phoneMask: string = "";
  naturalRelationList: Array<Param> = new Array<Param>();

  constructor(private bsModalRef: BsModalRef, private fb: FormBuilder, private paramsService: ParamsService, private onbaseService: OnbaseService) {



    this.formContact = this.fb.group({
      firstName: ['', Validators.required],
      middleName: [''],
      firstLastName: ['', Validators.required],
      secondLastName: [''],
      matiralStatus: [''],
      email: ['', [Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(this.countryResidenceMinLength)]],
      phoneResidence: ['', [Validators.minLength(this.countryResidenceMinLength)]],
      workPhoneNumber: ['', [Validators.minLength(this.countryResidenceMinLength)]],
      phoneResidenceExtension: [''],
      naturalRelation: [Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    this.naturalRelationList = (await this.onbaseService.readOnBaseCatalogueObject(OnBaseCatalogueType.NATURALRELATION, undefined).toPromise());
    let params: any = (await this.paramsService.readParams().toPromise()).response;
    this.phoneMask = params[ParamPhoneMaskHn];
  }

  cancel(): void {
    this.bsModalRef.hide();
  }

  onlyNumbers(event: any): boolean {

    const key = event.keyCode || event.which;
    const tecla = String.fromCharCode(key);
    const numeros = "0123456789.";

    const regexp: RegExp = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{0,2})?\s*$/);
    const val: string = event.target.value + tecla;

    if (numeros.indexOf(tecla) === -1 || regexp.test(val) === false) {
      return false;
    }
    return true;
  }

  public mapResponse(): CustomerUpdateContact {
    let contact: CustomerUpdateContact = new CustomerUpdateContact();
    contact.firstName = this.formContact.get('firstName')?.value;
    contact.middleName = this.formContact.get('middleName')?.value;
    contact.firstLastName = this.formContact.get('firstLastName')?.value;
    contact.secondLastName = this.formContact.get('secondLastName')?.value;
    contact.mobilePhone = this.formContact.get('phone')?.value;
    contact.homePhone = this.formContact.get('phoneResidence')?.value;
    contact.workPhoneNumber = this.formContact.get('workPhoneNumber')?.value;
    contact.extension = this.formContact.get('phoneResidenceExtension')?.value;
    contact.email = this.formContact.get('email')?.value;

    if (this.formContact.get('naturalRelation')?.value != undefined)
      contact.tipoRelacion = this.naturalRelationList.find(x => x.code == this.formContact.get('naturalRelation')?.value)?.value!;

    return contact;
  }

  addContact() {
    this.confirmAction(this.mapResponse());
    this.bsModalRef.hide();
  }
}
