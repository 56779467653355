import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                // this.authenticationService.invalidateToken().subscribe(async (response) => {
                //     this.authenticationService.removeTokenFromStorage();
                // });
                this.toastr.error("Usuario o contraseña erroneo", "Error al realizar la opperación", { closeButton: true, tapToDismiss: false });
            }
            else {
                const error = err.error ? (err.error.error_description || err.error.message || err.message || err.statusText) : err;
                this.toastr.error(error, "Error al realizar operación", { closeButton: true, tapToDismiss: false });

            }

            return throwError(err);
        }));
    }
}