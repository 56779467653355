import { FilterTableConfig } from "./filter-table-config";

export class FilterTable {
    code: string;
    description: string;
    type: string;
    comboBoxFilterValues: Map<string, string>;
    comboBoxFilterKeys: Array<string>;
    constructor(code: string,
        description: string,
        filterConfig?: FilterTableConfig) {
        this.code = code || "";
        this.description = description || "";
        this.type = filterConfig?.type || "text";
        this.comboBoxFilterValues = filterConfig?.comboBoxFilterValues || new Map<string, string>();
        this.comboBoxFilterKeys = Array.from(this.comboBoxFilterValues.keys()) || [];
    }
}