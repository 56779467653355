import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { Role } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, public router: Router, private configurationService: ConfigurationService, private toastr: ToastrService) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let userRoles = Array.from(this.authService.readRolesInStorage());

    const routeAccessId = route.data.id ? route.data.id : null;
    const canProceed = this.authService.isAuthenticated() && this.haveAccess(routeAccessId) && this.belongsToThisBranch(userRoles);

    if (!canProceed) {
      this.toastr.warning("No tiene permiso para accesar este recurso.", "¡Advertencia!")
      this.router.navigate(["login"]);
    } else if (
      this.authService.isAuthenticated() &&
      userRoles.includes(Role.ROLE_ADMIN_FRANCHISED) &&
      route.routeConfig &&
      route.routeConfig.path != "config"
    ) {
      this.router.navigate(["config"]);
    }
    return true;
  }

  haveAccess(routeAccessId: string): boolean {
    let result = true;

    if (routeAccessId) {
      const accessList = Array.from(this.authService.readAccessInStorage());
      result = !!accessList && !!accessList.length && accessList.includes(routeAccessId);
    }

    return result
  }

  belongsToThisBranch(userRoles: Array<Role>): boolean {
    const userBranches = Array.from(this.authService.readBranchCodesInStorage());
    return userBranches.includes(this.configurationService.readSelectedBranchAndSalesOrg().branch.code) ||
      userRoles.includes(Role.ROLE_ADMIN_FRANCHISED);
  }

}
