import Big from 'big.js';
import { Order } from './order';

export class CustomerCreditValidation {
  canInvoice: boolean;
  rate: number;
  minTerm: number;
  maxTerm: number;
  minDownPayment: number;
  maxDownPayment: number;
  minDownPaymentCurrency: number;
  maxDownPaymentCurrency: number;
  loanClass: string;
  productClass: string;
  customerStatus: string;

  constructor() {
    this.canInvoice = false;
    this.rate = 0;
    this.minTerm = 0;
    this.maxTerm = 0;
    this.minDownPayment = 0;
    this.maxDownPayment = 0;
    this.minDownPaymentCurrency = 0;
    this.maxDownPaymentCurrency = 0;
    this.productClass = '';
    this.loanClass = '';
    this.customerStatus = '';
  }

  static setDownpaymentLimitsCurrency(
    c: CustomerCreditValidation,
    total: number
  ): void {
    c.minDownPaymentCurrency = Number.parseFloat(
      Big(total).mul(Big(c.minDownPayment).div(100)).toFixed(2)
    );
    c.maxDownPaymentCurrency = Number.parseFloat(
      Big(total).mul(Big(c.maxDownPayment).div(100)).toFixed(2)
    );
  }
}
