export class ResponseFormAddress {
    street: String;
    street2: String;
    street3: String;
    street4: String;
    street5: String;
    municipality: String;
    municipalityName: String;
    neighborhood: String;
    neighborhoodName: String;
    department: String;
    departmentName: String;
    addressCountryId: String;

    constructor() {
        this.street = "";
        this.street2 = "";
        this.street3 = "";
        this.street4 = "";
        this.street5 = "";
        this.municipality = "";
        this.municipalityName = "";
        this.neighborhood = "";
        this.neighborhoodName = "";
        this.department = "";
        this.addressCountryId = "";
        this.departmentName = "";
    }
}