export class Subtotals {
    netTotal: number;
    grossTotalWithDiscount: number;
    grossTotal: number;
    discountTotal: number;
    surchargeTotal: number;
    taxTotal: number;
    orderDiscountTotal: number;
    orderSurchargeTotal: number;

    constructor() {
        this.netTotal = 0.0;
        this.grossTotalWithDiscount = 0.0;
        this.grossTotal = 0.0;
        this.discountTotal = 0.0;
        this.surchargeTotal = 0.0;
        this.taxTotal = 0.0;
        this.orderDiscountTotal = 0.0;
        this.orderSurchargeTotal = 0.0;
    }
}