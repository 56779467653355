import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PosCustomerUpdateComponent } from 'src/app/shared/pos-customer-update/pos-customer-update.component';
import { ModalDialogType } from '../constants';
import { Customer } from 'src/app/models/customer';
import { PosCustomerUpdateModalComponent } from 'src/app/shared/pos-customer-update-modal/pos-customer-update-modal.component';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { VerifyCustomerCheckResponse } from 'src/app/models/response/VerifyCustomerCheckResponse';

@Injectable({
  providedIn: 'root',
})
export class PosUpdateCustomerModalService {
  private modalRef?: BsModalRef<PosCustomerUpdateModalComponent>;
  constructor(private modalService: BsModalService) {}

  show(
    customerTableModel:CustomerTableModel | undefined,
    customerVerifyData:VerifyCustomerCheckResponse
  ) {
    this.modalRef = this.modalService.show(PosCustomerUpdateModalComponent, {
      initialState: {
        customerTableModel,
        customerVerifyData
      },
      class: `modal-dialog modal-lg ${ModalDialogType.PRIMARY} modal-dialog-scrollable`,
    });

    return this.modalRef.content?.customerUpdated.toPromise();
  }
}
