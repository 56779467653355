import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditInvoiceStepperComponent } from './credit-invoice-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { PosStepperModule } from 'src/app/shared/pos-stepper/pos-stepper.module';
import { CreditInvoiceDetailsStepModule } from './credit-invoice-details-step/credit-invoice-details-step.module';
import { PayCreditInvoiceStepComponent } from './credit-pay-invoice-step/pay-credit-invoice-step.component';
import { PayCreditInvoiceStepModule } from './credit-pay-invoice-step/pay-credit-invoice-step.module';

@NgModule({
  declarations: [CreditInvoiceStepperComponent],
  imports: [
    CommonModule,
    CdkStepperModule,
    PosStepperModule,
    CreditInvoiceDetailsStepModule,
    PayCreditInvoiceStepModule
  ],
  exports: [CreditInvoiceStepperComponent]
})
export class CreditInvoiceStepperModule { }
