import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paging } from '../models/page';
import { Region } from '../models/region';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  private url = `${environment.url}/regions`;
  
  constructor(private http: HttpClient) {
    
  }

  readRegions(countryCode:string, regionCode?: string):Observable<ResponseModel<Paging<Array<Region>>>>
  {
    let params = new HttpParams();
    params = params
      .set("countryCode", countryCode)
      .set("pageSize", "50000");

    params =regionCode ? params.set("regionCode", regionCode) : params;
    return this.http.get<ResponseModel<Paging<Array<Region>>>>(this.url, { params: params });
  }
}