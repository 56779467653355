import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExonerationReason } from '../models/exoneration-reason';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class ExonerationReasonService {

  private url = `${environment.url}/exoneration`;
  constructor(private http: HttpClient) { }

  readExonerations(): Observable<ResponseModel<Paging<Array<ExonerationReason>>>> {
    let params = new HttpParams();
    params = params.set("page", "0").set("pageSize", "5000");
    return this.http.get<ResponseModel<Paging<Array<ExonerationReason>>>>(this.url, { params });
  }
}
