import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomainTraditionReportType, PrintType } from '../@core/constants';
import { PosSupervisorLoginService } from '../@core/services/pos-supervisor-login.service';
import { ConfigurationService } from './configuration.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient, private configService: ConfigurationService, private supervisorService: PosSupervisorLoginService, private authService: AuthService) { }


  ip800RequestPdf(code: string) {
    let params = new HttpParams().set("pdf", "true").set("code", code).set("salesOrg", this.configService.readSelectedBranchAndSalesOrg().salesOrg.code);
    return this.http.get<Blob>(`${environment.url}/ip800request/pdf`, { params });
  }

  invoicePdf(code: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("salesOrgCode", salesOrgCode)
      .set("type", PrintType.ORIGINAL);

    return this.http.get<Blob>(`${environment.url}/invoices/${code}`, { params });
  }

  invoiceCopyPdf(code: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("salesOrgCode", salesOrgCode)
      .set("type", PrintType.COPY);

    return this.http.get<Blob>(`${environment.url}/invoices/${code}`, { params });
  }

  loanPdf(code: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("salesOrg", salesOrgCode)
      .set("code", code);
    return this.http.get<Blob>(`${environment.url}/loans/getPdf`, { params });
  }

  receiptPdf(code: string) {
    let params = new HttpParams()
      .set("pdf", "true")
    return this.http.get<Blob>(`${environment.url}/receipts/${code}`, { params });
  }

  debitNotePdf(code: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("salesOrg", salesOrgCode)
      .set("printType", "ORIGINAL");

    return this.http.get<Blob>(`${environment.url}/debitNote/${code}`, { params });
  }

  quotationPDF(orderCorde: string, salesOrgCode: string, loanCode?: string) {
    let params = new HttpParams().set("pdf", "true").set("quotationCode", orderCorde).set("salesOrg", salesOrgCode);
    params = loanCode ? params.set("loanCode", loanCode) : params;
    return this.http.get<Blob>(`${environment.url}/quotation`, { params });
  }

  promissoryNotePdf(contractNumber: string, salesOrgCode: string) {
    let params = new HttpParams().set("pdf", "true").set("contractNo", contractNumber).set("salesOrg", salesOrgCode);
    return this.http.get<Blob>(`${environment.url}/promissoryNote`, { params });
  }

  paymentPlanPdf(contractNumber: string, salesOrgCode: string) {
    let params = new HttpParams().set("pdf", "true").set("contractNo", contractNumber).set("salesOrg", salesOrgCode).set("deliveryType", "ACREDITACIÓN A CUENTA")
    return this.http.get<Blob>(`${environment.url}/paymentPlan/finaced`, { params });
  }

  checklistPdf(invoiceCode: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("invoiceCode", invoiceCode)
      .set("salesOrg", salesOrgCode);
    return this.http.get<Blob>(`${environment.url}/checklist/pdf`, { params });
  }

  domainTraditionPdf(invoiceCode: string, salesOrgCode: string, type: DomainTraditionReportType) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("invoiceCode", invoiceCode)
      .set("salesOrg", salesOrgCode)
      .set("reportType", type);
    return this.http.get<Blob>(`${environment.url}/dominanceTradition`, { params });
  }

  thirdPartyAuthorizationPdf(invoiceNumber: string, identity: string, name: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("invoiceNo", invoiceNumber)
      .set("salesOrg", salesOrgCode)
      .set("thirdPartyName", name)
      .set("thirdPartyId", identity);
    return this.http.get<Blob>(`${environment.url}/ip800request/thirdPartyAuthorizationPdf`, { params });
  }

  registrationRefusalPdf(invoiceCode: string, location: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("invoiceCode", invoiceCode)
      .set("salesOrg", salesOrgCode)
      .set("location", location);
    return this.http.get<Blob>(`${environment.url}/ip800request/voluntaryRegistrationRefusalPdf`, { params });
  }

  itemReservationPdf(receiptCode: string, salesOrgCode: string, accessToken?: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("receiptCode", receiptCode)
      .set("salesOrgCode", salesOrgCode);
    return this.http.get<Blob>(`${environment.url}/itemReservation`, { params });
  }

  printItemLiquidation(advanceCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("advanceCode", advanceCode)
      .set("salesOrgCode", this.configService.readSelectedBranchAndSalesOrg().salesOrg.code)
      .set("branchCode", this.configService.readSelectedBranchAndSalesOrg().branch.code);

    return this.http.get<Blob>(`${environment.url}/itemReservationLiquidation`, { params });
  }
  registerClose(code: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("salesOrg", salesOrgCode);

    return this.http.get<Blob>(`${environment.url}/registers/close/${code}`, { params })
  }

  warrantyCertificate(invoiceCode: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("salesOrgCode", salesOrgCode)
      .set("invoiceCode", invoiceCode);

    return this.http.get<Blob>(`${environment.url}/warrantyCertificate`, { params });
  }

  printContractReferral(fromDate: string, toDate: string, salesOrgCode: string, branchCode: string) {

    let params = new HttpParams()
      .set("pdf", "true")
      .set("salesOrg", salesOrgCode)
      .set("fromDate", fromDate)
      .set("toDate", toDate)
      .set("branchCode", branchCode)

    return this.http.get<Blob>(`${environment.url}/contractReferral`, { params });
  }

  circulationPermit(code: string, salesOrgCode: string) {
    let params = new HttpParams()
      .set("pdf", "true")
      .set("code", code)
      .set("salesOrg", salesOrgCode);

    return this.http.get<Blob>(`${environment.url}/circulationPermit/pdf/`, { params });
  }

  digitalSignaturePrintPDF(requestCode: number, documentCode: string) {
    let params = new HttpParams()
      .set("pdf", "true");
    return this.http.get<Blob>(`${environment.url}/orders/document/${documentCode}/request/${requestCode}`, { params });
  }

  purchaseOrderPdf(orderCode: string) {
    let params = new HttpParams()
      .set("pdf", "true");
    return this.http.get<Blob>(`${environment.url}/purchaseOrder/${orderCode}`, { params });
  }

  printReport(blob: Blob): Promise<void> {
    return new Promise((resolve, reject) => {
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    })
  }

  printReports(...blobs: Blob[]): Promise<void> {
    if (blobs.length == 0) {
      return new Promise((resolve, reject) => { resolve() });
    }
    return new Promise((resolve, reject) => {
      blobs.forEach(blob => {
        if (blob.size > 0) {

          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
          resolve();
        }
      })
    })
  }

  async printReportsSupervisor(privileges: Array<string>, ...blobs: Blob[]): Promise<void> {

    let user = (await this.authService.readProfileInStorage());
    if (await this.authService.hasPrivilegesInRoles(user.roles, new Set(privileges))) {
      await this.printReports(...blobs);
    }
    else if (blobs.length > 0) {
      await this.supervisorService.show(async () => {
        await this.printReports(...blobs);
      }, privileges);
    }
  }
}
