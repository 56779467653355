import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartStepComponent } from './cart-step.component';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { ProductsService } from 'src/app/services/products.service';
import { PosListModule } from 'src/app/shared/pos-list/pos-list.module';
import { TaxesService } from 'src/app/services/taxes.service';
import { OrderService } from 'src/app/services/order.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { PosSerialModalService } from 'src/app/@core/services/pos-serial-modal.service';
import { PosSerialModalModule } from 'src/app/shared/pos-serial-modal/pos-serial-modal.module';
import { PosDiscountModalModule } from 'src/app/shared/pos-discount-modal/pos-discount-modal.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PromotionsService } from 'src/app/services/promotions.service';
import { PosConfirmationModule } from 'src/app/shared/pos-confirmation/pos-confirmation.module';
import { RouterModule } from '@angular/router';
import { PosPromotionsModalModule } from 'src/app/shared/pos-promotions-modal/pos-promotions-modal.module';
import { PosPromotionsDetailsModalModule } from 'src/app/shared/pos-promotions-details-modal/pos-promotions-details-modal.module';
import { PosTotalsModule } from 'src/app/shared/pos-totals/pos-totals.module';
import { AuthService } from 'src/app/services/auth.service';
@NgModule({
  declarations: [CartStepComponent],
  imports: [
    CommonModule,
    PosTableModule,
    PosListModule,
    PosSerialModalModule,
    PosDiscountModalModule,
    TabsModule,
    PosConfirmationModule,
    RouterModule,
    PosPromotionsModalModule,
    PosPromotionsDetailsModalModule,
    PosTotalsModule,
  ],
  providers: [ProductsService, TaxesService, OrderService, ConfigurationService, PosSerialModalService, PromotionsService, AuthService],
  exports: [CartStepComponent]
})
export class CartStepModule { }
