import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosIp800ModalComponent } from './pos-ip800-modal.component';
import { PosIp800ModalService } from 'src/app/@core/services/pos-ip800-modal.service';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { Ip800Service } from 'src/app/services/ip800.service';




@NgModule({
  declarations: [PosIp800ModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule
  ],
  providers: [PosIp800ModalService, Ip800Service],
  exports: [PosIp800ModalComponent]
})
export class PosIp800ModalModule { }
