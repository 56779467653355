import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExchangeRate } from '../models/exchange-rate';
import { ResponseModel } from '../models/response-model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRateService {

  private url = `${environment.url}/exchangeRates`;
  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  async readLatestExchangeRate(): Promise<ExchangeRate> {
    return await this.readExchangeRate(new Date()) || new ExchangeRate();
  }

  async readExchangeRate(date: Date): Promise<ExchangeRate> {

    let lowerBoundDate = new Date(date);
    lowerBoundDate.setDate(lowerBoundDate.getDate() - 15);
    lowerBoundDate.setHours(0);
    lowerBoundDate.setMinutes(0);
    lowerBoundDate.setSeconds(0);
    let upperBoundDate = new Date(date);
    upperBoundDate.setHours(23);
    upperBoundDate.setMinutes(59);
    upperBoundDate.setSeconds(59);
    let params = new HttpParams();
    params = params.set("fromCurrency", "USD")
    params = params.set("toCurrency", "HNL")
    params = params.set("toCurrency", "HNL")
    params = params.set("lowerBoundDate", this.datePipe.transform(lowerBoundDate, "yyyy-MM-dd HH:mm:ss") || "")
    params = params.set("upperBoundDate", this.datePipe.transform(upperBoundDate, "yyyy-MM-dd HH:mm:ss") || "")
    return (await this.http.get<ResponseModel<Array<ExchangeRate>>>(this.url, { params }).toPromise()).response.sort((a, b) => a.validFrom > b.validFrom ? -1 : 1)[0];
  }
}
