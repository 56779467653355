
<div class="card">
  <div class="card-header">
    <i class="fa fa-street-view"></i> Anticipos por cliente
  </div>
  <div class="card-body card-body-z-index">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-address-card"></i> Información del cliente
      </div>
      <div class="card-body card-body-z-index">
        <div class="row">
          <div class="col-sm-3">
            <div class="item">
              <div class="item-label">
                Código del cliente:
              </div>
              <div class="item-info">
                {{ customer?.code }}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="item">
              <div class="item-label">
                Identificación:
              </div>
              <div class="item-info" *ngIf="customer && customer.personalInfo.length">
                {{ customer.personalInfo[0].fiscalId }}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="item">
              <div class="item-label">
                Nombre:
              </div>
              <div class="item-info">
                {{customer?.mainPersonalInfo?.fullName}}
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="item">
              <div class="item-label">
                Saldo:
              </div>
              <div class="item-info">
                {{ totalAmount | number:'1.0-2' }}
              </div>
            </div>
          </div>
    
        </div>
      </div>
    </div>
    <pos-table tableFormId="advanceTable" [data]="advances" (dataChange)="getAdvancesByPage($event)"
      [headers]="getAdvanceHeaders()" [itemsPerPage]="10">
    </pos-table>
  </div>
</div>