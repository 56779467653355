import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CurrentCountry } from 'src/app/@core/constants';
import { City } from 'src/app/models/city';
import { Colony } from 'src/app/models/colony';
import { ResponseFormAddress } from 'src/app/models/responseFormAddress';
import { Nationality } from 'src/app/models/nationality';
import { Region } from 'src/app/models/region';
import { CityService } from 'src/app/services/city.service';
import { ColonyService } from 'src/app/services/colony.service';
import { NationalityService } from 'src/app/services/nationality.service';
import { RegionService } from 'src/app/services/region.service';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-pos-customer-create-address',
  templateUrl: './pos-customer-create-address.component.html',
  styleUrls: ['./pos-customer-create-address.component.sass']
})
export class PosCustomerCreateAddressComponent implements OnInit {
  formCustomerAddress: FormGroup = this.fb.group({});
  countries: Array<Nationality> = new Array<Nationality>();
  regions: Array<Region> = new Array<Region>();
  cities: Array<City> = new Array<City>();
  colonies: Array<Colony> = new Array<Colony>();


  countrySelected: String = "";
  regionSelected: String = "";
  citySelected: String = "";
  colonySelected: String = "";
  @Input()
  streetInOneLine: boolean = false;

  constructor(private fb: FormBuilder, private nationalityServices: NationalityService, private regionService: RegionService, private cityService: CityService, private colonyService: ColonyService) { }

  async ngOnInit(): Promise<void> {
    this.formCustomerAddress = this.fb.group({
      addressCountry: [''],
      addressRegion: [''],
      addressCity: [''],
      addressColony: [''],
      addressStreet1: [''],
      addressStreet2: [''],
      addressStreet3: [''],
      addressStreet4: [''],
      addressStreet5: ['']
    });
    this.countrySelected = CurrentCountry;
    this.countries = await (await this.nationalityServices.readNationalities().toPromise()).response.content;
    this.countries = this.countries.sort((a, b) => a.name.localeCompare(b.name));
    await this.getRegion(this.countrySelected.toString());
  }

  async changeCountry(nationality: Nationality) {
    await this.getRegion(nationality.code);
  }

  async getRegion(nationalitySelected: string) {
    this.formCustomerAddress.get("addressRegion")?.reset();
    this.regions = (await (await this.regionService.readRegions(nationalitySelected)).toPromise()).response.content;
    this.regions = this.regions.sort((a, b) => a.name.localeCompare(b.name));

    this.formCustomerAddress.get("addressCity")?.reset();
    this.formCustomerAddress.get("addressColony")?.reset();
    this.cities = new Array<City>();
    this.colonies = new Array<Colony>();
  }

  async changeRegion(region: Region) {
    this.getCities(region.country, region.region);
    this.formCustomerAddress.get("addressCity")?.setValidators([Validators.required]);
    this.formCustomerAddress.get("addressColony")?.setValidators([Validators.required]);
    this.formCustomerAddress.get("addressStreet1")?.setValidators([Validators.required, this.noWhitespaceValidator]);
    this.formCustomerAddress.get("addressCity")?.updateValueAndValidity();
    this.formCustomerAddress.get("addressColony")?.updateValueAndValidity();
    this.formCustomerAddress.get("addressStreet1")?.updateValueAndValidity();
  }

  async getCities(countrySelected: string, regionSelected: string) {
    this.formCustomerAddress.get("addressCity")?.reset();
    this.formCustomerAddress.get("addressColony")?.reset();
    this.colonies = new Array<Colony>();
    this.cities = (await this.cityService.readCities(countrySelected, regionSelected).toPromise()).response.content;
    this.cities = this.cities.sort((a, b) => a.name.localeCompare(b.name));
  }

  async changeCity(region: City) {
    this.getColonies(region.cityCode);
  }

  async getColonies(citySelected: string) {
    this.formCustomerAddress.get("addressColony")?.reset();
    this.colonies = (await this.colonyService.readColonies(citySelected).toPromise()).response.content;
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length > 0 ? null : { 'whitespace': true };
  }

  mapRequestAddress(): ResponseFormAddress {
    let responseFormAddress: ResponseFormAddress = new ResponseFormAddress();

    if (this.formCustomerAddress.valid) {
      let cityCode: string = this.formCustomerAddress.get("addressCity")?.value;
      responseFormAddress.municipality = this.cities.find(x => x.cityCode == cityCode)?.code || "";
      responseFormAddress.municipalityName = this.cities.find(x => x.cityCode == cityCode)?.name || "";
      responseFormAddress.neighborhood = this.formCustomerAddress.get("addressColony")?.value;
      responseFormAddress.neighborhoodName = this.colonies.find(x => x.code == responseFormAddress.neighborhood)?.name || "";
      responseFormAddress.street = this.formCustomerAddress.get("addressStreet1")?.value;
      responseFormAddress.street2 = this.formCustomerAddress.get("addressStreet2")?.value;
      responseFormAddress.street3 = this.formCustomerAddress.get("addressStreet3")?.value;
      responseFormAddress.street4 = this.formCustomerAddress.get("addressStreet4")?.value;
      responseFormAddress.street5 = this.formCustomerAddress.get("addressStreet5")?.value;
      responseFormAddress.department = this.formCustomerAddress.get("addressRegion")?.value;
      responseFormAddress.departmentName = this.regions.find(x => x.region == responseFormAddress.department)?.name || "";
      responseFormAddress.addressCountryId = this.formCustomerAddress.get("addressCountry")?.value;
    }
    else {
      throw "Por favor llene los campos obligatorios";
    }

    return responseFormAddress;
  }

  reset() {
    this.regionSelected = "";
    this.citySelected = "";
    this.colonySelected = "";

    this.formCustomerAddress.get("addressCity")?.setValidators([]);
    this.formCustomerAddress.get("addressColony")?.setValidators([]);
    this.formCustomerAddress.get("addressStreet1")?.setValidators([]);

    this.formCustomerAddress.get("addressRegion")?.reset();
    this.formCustomerAddress.get("addressCity")?.reset();
    this.formCustomerAddress.get("addressColony")?.reset();
    this.formCustomerAddress.get("addressStreet1")?.reset();
    this.formCustomerAddress.get("addressStreet2")?.reset();
    this.formCustomerAddress.get("addressStreet3")?.reset();
    this.formCustomerAddress.get("addressStreet4")?.reset();
    this.formCustomerAddress.get("addressStreet5")?.reset();
    this.formCustomerAddress.updateValueAndValidity();
  }
}