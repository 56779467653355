<form [formGroup]="formCustomer" class="form-horizontal">
  <div class="form-group row">
    <label class="col-md-3 col-form-label"><b>Cliente</b></label>
    <div class="col-md-9">
      <div class="input-group">
        <input type="text" id="text-input" name="text-input" class="form-control" formControlName="customerFullName"
          [class.is-invalid]="(formCustomer.get('customerFullName')?.touched || formCustomer.get('customerFullName')?.dirty) && formCustomer.get('customerFullName')?.invalid"
          required>
        <span class="input-group-append">
          <button type="button" class="btn btn-primary" (click)="createCustomer()">
            Crear Cliente
          </button>
        </span>
      </div>

    </div>

  </div>
  <div class="form-group row">
    <label class="col-md-3 col-form-label" for="text-input"><b>Vendedor</b></label>
    <div class="col-md-9">
      <input type="text" id="text-input" name="text-input" class="form-control" formControlName="sellerName"
        [class.is-invalid]="(formCustomer.get('sellerName')?.touched || formCustomer.get('sellerName')?.dirty) && formCustomer.get('sellerName')?.invalid"
        required>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-3 col-form-label"><b>RTN</b></label>
    <div class="col-md-9">
      <div class="input-group">
        <input type="text" id="text-input" name="text-input" class="form-control" formControlName="rtnFiscalId"
          [class.is-invalid]="(formCustomer.get('rtnFiscalId')?.touched || formCustomer.get('rtnFiscalId')?.dirty) && formCustomer.get('customerFullName')?.invalid">
        <span class="input-group-append">
          <button type="button" class="btn btn-primary" [disabled]="disableRtnButton()"
            (click)="updateRtnFiscalId()">Utilizar
            RTN</button>
        </span>
      </div>
    </div>

  </div>
</form>
<pos-customer-table (getCustomer)="selectCustomer($event)" [showVerificationAlert]="true"></pos-customer-table>
