import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosPromotionsModalComponent } from './pos-promotions-modal.component';
import { PosPromotionsModalService } from 'src/app/@core/services/pos-promotions-modal.service';
import { PromotionListModule } from 'src/app/views/promotions/promotion-list/promotion-list.module';
@NgModule({
  declarations: [PosPromotionsModalComponent],
  imports: [
    CommonModule,
    PromotionListModule
  ],
  providers: [PosPromotionsModalService],
  exports: [PosPromotionsModalComponent]
})
export class PosPromotionsModalModule { }
