import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Equipment } from '../models/equipment';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  private url = `${environment.url}/equipment`;
  constructor(private http: HttpClient) { }

  readEquipment(equipmentCode: string): Observable<ResponseModel<Equipment>> {

    return this.http.get<ResponseModel<Equipment>>(`${this.url}/${equipmentCode}`);

  }

  readEquipmentBySerialByProductByBranch(
    serial: string, 
    productCode: string,
    branchCode: string
  ): Observable<ResponseModel<Paging<Array<Equipment>>>> {
    let params = new HttpParams();
    params = params.set("serial", serial)
      .set("productCode", productCode)
      .set("branchCode", branchCode);
    
    return this.http.get<ResponseModel<Paging<Array<Equipment>>>>(`${this.url}`, { params });
  }
}
