import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosPromotionsDetailsModalComponent } from './pos-promotions-details-modal.component';
import { PromotionDetailsModule } from 'src/app/views/promotions/promotion-details/promotion-details.module';
import { PosPromotionsDetailsModalService } from 'src/app/@core/services/pos-promotions-details-modal.service';



@NgModule({
  declarations: [PosPromotionsDetailsModalComponent],
  imports: [
    CommonModule,
    PromotionDetailsModule
  ],
  providers: [PosPromotionsDetailsModalService],
  exports: [PosPromotionsDetailsModalComponent]
})
export class PosPromotionsDetailsModalModule { }
