import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { OnbaseService } from 'src/app/services/onbase.service';

@Component({
  selector: 'app-select-endorsement',
  templateUrl: './select-endorsement.component.html',
  styleUrls: ['./select-endorsement.component.sass']
})
export class SelectEndorsementComponent implements OnInit {
  isRequesting: Boolean = false;
  baseCustomerCode!: string;
  intermediaries: Customer[] = [];
  selectedIntermediary: Customer|null = null;
  onConfirmSelection: ((customer: Customer) => void) = ((customer: Customer) => {});


  constructor(
    public bsModalRef: BsModalRef, 
    private onBaseService: OnbaseService, 
    private customerService: CustomerService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isRequesting = true;

    this.intermediaries = (await this.onBaseService.readOnbaseRequestIntermediariesByCustomerCode(this.baseCustomerCode).toPromise())
      .response;
    
    if (this.intermediaries && this.intermediaries.length) {
      this.selectedIntermediary = this.intermediaries[0];
    }

    this.isRequesting = false;
  }

  cancel() {
    this.bsModalRef.hide()
  }

  confirm() {
    if (!!this.selectedIntermediary) {
      this.onConfirmSelection(Object.assign({}, this.selectedIntermediary))
    }

    this.bsModalRef.hide();
  }
}
