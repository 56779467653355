<div class="modal-content">
  <form [formGroup]="ip800Form" id="ip800Form" name="ip800Form" class="form-horizontal" novalidate
    (ngSubmit)="ip800Form.valid && createIp800()">

    <div class="modal-header">
      <h4 class="modal-title">Formulario Ip800</h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <!-- <div class="form-group row">
    <div class="col-md-12">
      <label for="name"><b>Nombre Completo</b></label>
      <input id="name" name="name" type="text" class="form-control" formControlName="name">
    </div>

  </div> -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="name"><b>Nombre Completo</b></label>
        <div class="col-md-9">
          <input id="name" name="name" type="text" class="form-control" formControlName="name">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="region"><b>Departamento</b></label>
        <div class="col-md-9">
          <ng-select id="region" [items]="regions" bindLabel="name" [selectOnTab]="true" (change)="changeRegion($event)"
            formControlName="region">
          </ng-select>

        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="city"><b>Municipio</b></label>
        <div class="col-md-9">
          <ng-select id="city" [items]="cities" bindLabel="name" [selectOnTab]="true" (change)="changeCity($event)"
            formControlName="city">
          </ng-select>

        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="colony"><b>Colonia</b></label>
        <div class="col-md-9">
          <ng-select id="colony" [items]="colonies" bindLabel="name" [selectOnTab]="true" formControlName="colony">
          </ng-select>

        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="streetOrAvenue"><b>Calle o Avenida</b></label>
        <div class="col-md-9">
          <input id="streetOrAvenue" name="streetOrAvenue" type="text" class="form-control"
            formControlName="streetOrAvenue">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="blockNumber"><b>Bloque</b></label>
        <div class="col-md-9">
          <input id="blockNumber" name="blockNumber" type="text" class="form-control" formControlName="blockNumber">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="houseNumber"><b>Número Casa</b></label>
        <div class="col-md-9">
          <input id="houseNumber" name="houseNumber" type="text" class="form-control" formControlName="houseNumber"
            [class.is-invalid]="(ip800Form.get('houseNumber')?.touched || ip800Form.get('houseNumber')?.dirty) && ip800Form.get('houseNumber')?.invalid">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="cellPhone"><b>Celular</b></label>
        <div class="col-md-9">
          <input id="cellPhone" name="cellPhone" type="text" class="form-control" formControlName="cellPhone"
            [mask]="phoneMask"
            [class.is-invalid]="(ip800Form.get('cellPhone')?.touched || ip800Form.get('cellPhone')?.dirty) && ip800Form.get('cellPhone')?.invalid">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="otherPhone"><b>Teléfono de Casa</b></label>
        <div class="col-md-9">
          <input id="otherPhone" name="otherPhone" type="text" class="form-control" formControlName="otherPhone"
            [mask]="phoneMask">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="email"><b>Correo electrónico</b></label>
        <div class="col-md-9">
          <input id="email" name="email" type="text" class="form-control" formControlName="email"
            [class.is-invalid]="(ip800Form.get('email')?.touched || ip800Form.get('email')?.dirty) && ip800Form.get('email')?.invalid">
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
      <button type="submit" class="btn btn-success">Confirmar</button>
    </div>

  </form>
</div><!-- /.modal-content -->
