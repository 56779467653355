export class SalesOrg {
    code: string;
    name: string;
    businessName: string;
    fiscalId: string;
    phoneNumber: number;
    address: string;
    city: string;
    email: string;
    limitQuotationDays: string;
    legalRepresentativeName: string;
    legalRepresentativeFiscalId: string;

    constructor() {
        this.code = "";
        this.name = "";
        this.businessName = "";
        this.fiscalId = "";
        this.phoneNumber = 0;
        this.address = "";
        this.city = "";
        this.email = "";
        this.limitQuotationDays = "";
        this.legalRepresentativeName = "";
        this.legalRepresentativeFiscalId = "";
    }
}