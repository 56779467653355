<form class="mt-2 ml-2 mr-2 mb-2" [formGroup]="formApplication" (ngSubmit)="apply()">
  <div class="form-row">
    <div class="col-md-2 mb-3">
      <label>Monto a facturar</label>
      <input 
        class="form-control" 
        type="number" 
        formControlName="invoiceAmount" 
        [ngClass]="formApplication.controls.invoiceAmount.errors && formApplication.controls.invoiceAmount.touched
          ? 'is-invalid' 
          : formApplication.controls.invoiceAmount.value > 0
            ? 'is-valid'
            : ''"
        (keyup)="onInvoiceAmountChange()"
      />
    </div>
    <div class="col-md-3 mb-3">
      <label>Prima mínima</label>
      <input 
        class="form-control" 
        type="text" 
        readonly
        formControlName="minimun"
      />
    </div>
    <div class="col-md-3 mb-3">
      <label>Prima máxima</label>
      <input 
        class="form-control" 
        type="text" 
        readonly
        formControlName="maximun"
      />
    </div>
    <div class="col-md-2 mb-3">
      <label>Prima</label>
      <input 
        class="form-control" 
        type="number" 
        formControlName="amount" 
        [ngClass]="formApplication.controls.amount.errors && formApplication.controls.amount.touched
          ? 'is-invalid' 
          : formApplication.controls.amount.value > 0
            ? 'is-valid'
            : ''"
      />
    </div>
    <div class="col-md-1 m-t-45">
      <button type="submit" class="btn btn-primary form-control">
        Aplicar
      </button>
    </div>
  </div>  
</form>