import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table';
import { Filter } from 'src/app/models/filter';
import { FilterTable } from 'src/app/models/filter-table';
import { EmployeeFilter } from 'src/app/models/filters/employee-filter';
import { Paging } from 'src/app/models/page';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'pos-employee-table',
  templateUrl: './pos-employee-table.component.html',
  styleUrls: ['./pos-employee-table.component.sass'],
})
export class PosEmployeeTableComponent implements OnInit {
  employees: Paging<Array<CustomerTableModel>> = new Paging([]);

  @Output()
  getEmployee = new EventEmitter<any>();

  constructor(private customerService: CustomerService) { }

  async ngOnInit(): Promise<void> {
    await this.getEmployeeData({ page: 0, pageSize: 10, value: {} });
  }

  getEmployeeHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('code', 'Código'),
      new ColumnTable('fullName', 'Nombre Completo'),
    ];
  }

  getEmployeeFilters(): Array<FilterTable> {
    return [
      new FilterTable('text', 'Búsqueda General'),
      new FilterTable('code', 'Código de Empleado'),
    ];
  }

  async getEmployeeData(params: Filter<EmployeeFilter>): Promise<void> {

    this.employees = (await this.customerService.readEmployees(params).toPromise()).response;
  }
  selectEmployee(employee: CustomerTableModel) {
    this.getEmployee.emit(employee);
  }
}
