export class CreditReferencesResponseModel {
  code: string;
  loanClass: string;
  maxDownPayment: number;
  maxTerm: number;
  minDownPayment: number;
  minTerm: number;
  rate: number;
  rateType: string;
  salesOrg: string;

  constructor() {
    this.code = ``;
    this.loanClass = ``;
    this.maxDownPayment = 0;
    this.maxTerm = 0;
    this.minDownPayment = 0;
    this.minTerm = 0;
    this.rate = 0;
    this.rateType = ``;
    this.salesOrg = ``;
  }
}
