import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Nationality } from '../models/nationality';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class NationalityService {
  private url = `${environment.url}/nationalities`;
  
  constructor(private http: HttpClient) {
    
  }

  readNationalities():Observable<ResponseModel<Paging<Array<Nationality>>>>
  {
    let params = new HttpParams();
    params = params
      .set("page", "0")
      .set("pageSize", "50000");
    return this.http.get<ResponseModel<Paging<Array<Nationality>>>>(this.url, { params: params });
  }
}