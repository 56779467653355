<div class="input-group mb-3">

    <img class="img-fluid" src="data:image/png;base64,{{captcha.image}}" alt="Captcha">

    <div class="input-group-text btn-outline-primary" (click)="updateCaptcha()">
        <a><i class="fa fa-refresh"></i></a>
    </div>
</div>
<div class="input-group mb-3">

    <div class="input-group-prepend">
        <span class="input-group-text"><i class="fa fa-key"></i></span>
    </div>
    <input type="text" #captchaModel="ngModel" class="form-control" [(ngModel)]="value"
        placeholder="Ingrese los caracteres de la imagen">
</div>