<form [formGroup]="reasonForm" novalidate (ngSubmit)="reasonForm.valid && confirm()">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">
       Motivo de no actualizacion
      </h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="reasonCode"><b>Seleccione un motivo</b></label>
        <div class="col-md-9">
          <select type="text" id="reasonCode" name="reasonCode" class="form-control" formControlName="reasonCode"
            [class.is-invalid]="(reasonForm.get('reasonCode')?.touched || reasonForm.get('reasonCode')?.dirty) && reasonForm.get('reasonCode')?.invalid"
            (change)="changeType()">
            <option *ngFor="let reason of this.reasons"  [value]="reason.code" >{{reason.description}}</option>
          </select>
        </div>
        <div class="invalid-feedback">Por favor seleccione un motivo</div>
      </div>
      <div class="form-group row" *ngIf="selectedReason?.isTextFreeDescription">
        <label class="col-md-3 col-form-label" for="manualReasonDescription"><b>Ingrese un motivo</b></label>
        <div class="col-md-9">
            <input type="text" id="manualReasonDescription" name="manualReasonDescription" class="form-control" formControlName="manualReasonDescription"
            [class.is-invalid]="(reasonForm.get('manualReasonDescription')?.touched || reasonForm.get('manualReasonDescription')?.dirty) && reasonForm.get('manualReasonDescription')?.invalid"
            >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancelar</button>
      <button type="submit" class="btn btn-outline-primary">Confirmar</button>
    </div>
  </div>
</form>

