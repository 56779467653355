<div class="list-group">
  <a class="list-group-item flex-column align-items-start bg-dark text-white">
    <!-- <h4 class="mb-4">Lista de Productos</h4> -->
    <form class="" [formGroup]="filterForm" (keydown.enter)="filterAction()" novalidate>
      <div class="mb-2">
        <div *ngFor="let filter of filters;trackBy:trackByIndex" class="mb-2">
          <div class="" *ngIf="(filter.type == 'text')">
            <input type="text" [formControlName]="filter.code" class="form-control" [id]="filter.code"
              [name]="filter.code" [placeholder]="filter.description" (keydown.enter)="filterAction()" />
          </div>
          <div class="" *ngIf="(filter.type == 'combobox')">
            <select [formControlName]="filter.code" class="form-control" [id]="filter.code" [name]="filter.code"
              (change)="filterAction()">
              <option value="">{{filter.description}}</option>
              <option *ngFor="let cb of filter.comboBoxFilterKeys" [value]="cb">{{filter.comboBoxFilterValues.get(cb)}}
              </option>
            </select>
          </div>
          <div class="" *ngIf="(filter.type == 'date')">
            <input class="form-control" [id]="filter.code" [name]="filter.code" [formControlName]="filter.code"
              [popover]="filter.description" placement="top" type="date" triggers="mouseenter:mouseleave"
              placeholder="date" (change)="filterAction()">
          </div>
          <div class=" ml-2" *ngIf="(filter.type == 'switch')">
            <div class="row">
              <label class="switch switch-sm switch-3d switch-primary col-form-label">
                <input type="checkbox" class="form-control switch-input" [formControlName]="filter.code"
                  (change)="filterAction()">
                <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
              </label>
              <label class="col col-form-label"> {{filter.description}}</label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </a>


  <a *ngFor="let item of data.content" class="list-group-item list-group-item-action flex-column align-items-start"
    (click)="rowFn(item)">
    <div class="d-flex justify-content-between align-items-center">
      <h6 *ngIf="getTitle(item)" class="mb-1">{{getTitle(item)}}</h6>
      <span *ngIf="getBadge(item) !== ''" class="badge badge-success badge-pill"
        title="Cantidad Stock">{{getBadge(item)}}</span>
    </div>
    <p class="mb-1" *ngIf="getDescription(item)">{{getDescription(item)}}</p>
    <small *ngIf="getSmallTitle(item)"><b>{{getSmallTitle(item) | number: '1.2-2'}}</b></small>
  </a>
  <a *ngIf="data.content.length == 0"
    class="list-group-item list-group-item-action flex-column align-items-start text-center">
    <h6 class="mb-1">No hay datos.</h6>
  </a>

</div>
<div class="pagination ml-2" *ngIf="pagination">
  <pagination [totalItems]="data.totalElements" [(ngModel)]="currentPage" [maxSize]="3" class="pagination-sm"
    previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [boundaryLinks]="true"
    [rotate]="false" [itemsPerPage]="data.size" (pageChanged)="pageSelected($event)">
  </pagination>
</div>
