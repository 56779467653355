<form [formGroup]="formCustomerAddress">
<div class="form-row">
  <div class="col-md-3 mb-3">
    <label>Pais</label>
    <ng-select id="addressCountry" [items]="countries" bindLabel="country" bindValue="code"
      [selectOnTab]="true" (change)="changeCountry($event)" formControlName="addressCountry" [(ngModel)]="countrySelected">
    </ng-select>
  </div> 
<div class="col-md-3 mb-3">
    <label>Departamento</label>
    <ng-select id="addressRegion" [items]="regions" bindLabel="name" bindValue="region"
      [selectOnTab]="true" (change)="changeRegion($event)" formControlName="addressRegion" [(ngModel)]="regionSelected">
    </ng-select>
  </div> 

  <div class="col-md-3 mb-3">
    <label>Ciudad</label>
    <ng-select id="addressCity" [items]="cities" bindLabel="name" bindValue="cityCode"
      [selectOnTab]="true" (change)="changeCity($event)" formControlName="addressCity" [(ngModel)]="citySelected" [ngClass]="
          formCustomerAddress.controls.addressCity.errors
            ? 'is-invalid'
            :  formCustomerAddress.controls.addressCity.value?.length ? 'is-valid' : ''
        ">
    </ng-select>
  </div>

  <div class="col-md-3 mb-3">
    <label>Colonia</label>
    <ng-select id="addressColony" [items]="colonies" bindLabel="name" bindValue="code"
      [selectOnTab]="true" formControlName="addressColony" [(ngModel)]="colonySelected" [ngClass]="
          formCustomerAddress.controls.addressColony.errors
            ? 'is-invalid'
            :  formCustomerAddress.controls.addressColony.value?.length ? 'is-valid' : ''
        ">
    </ng-select>
  </div> 
  
  <div [ngClass]="streetInOneLine ? 'col-md-2 mb-3' : 'col-md-12 mb-3'">
      <label>Calle 1</label>
      <input class="form-control" type="text" formControlName="addressStreet1" maxlength="35" [ngClass]="
          formCustomerAddress.controls.addressStreet1.errors
            ? 'is-invalid'
            :  formCustomerAddress.controls.addressStreet1.value?.length ? 'is-valid' : ''
        "/>
    </div>
    <div [ngClass]="streetInOneLine ? 'col-md-2 mb-3' : 'col-md-12 mb-3'">
      <label>Calle 2</label>
      <input class="form-control" type="text" formControlName="addressStreet2" maxlength="35"/>
    </div>
    <div [ngClass]="streetInOneLine ? 'col-md-2 mb-3' : 'col-md-12 mb-3'">
      <label>Calle 3</label>
      <input class="form-control" type="text" formControlName="addressStreet3" maxlength="35"/>
    </div>
    <div [ngClass]="streetInOneLine ? 'col-md-2 mb-3' : 'col-md-12 mb-3'">
      <label>Calle 4</label>
      <input class="form-control" type="text" formControlName="addressStreet4" maxlength="35"/>
    </div>
    <div [ngClass]="streetInOneLine ? 'col-md-2 mb-3' : 'col-md-12 mb-3'">
      <label>Calle 5</label>
      <input class="form-control" type="text" formControlName="addressStreet5" maxlength="35"/>
    </div>
</div>
<form>