import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table';
import { Paging } from 'src/app/models/page';
import { Payment } from 'src/app/models/payment';
import { ProductCart } from 'src/app/models/product-cart';
import { Receipt } from 'src/app/models/receipt';
import { ReceiptLine } from 'src/app/models/receipt-line';

@Component({
  selector: 'receipt-details-step',
  templateUrl: './receipt-details-step.component.html',
  styleUrls: ['./receipt-details-step.component.sass']
})
export class ReceiptDetailsStepComponent implements OnInit {

  @Input()
  receipt: Receipt = new Receipt();
  productsCart: Paging<Array<ProductCart>> = new Paging([]);


  @Output() doPayment = new EventEmitter<{ payments: Array<Payment>, paymentsAmount: number, receiptLines: Array<ReceiptLine>, id: string }>();


  constructor() { }

  ngOnInit(): void {
    this.loadProductsCart();
  }

  getProductCartHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable("amount", "#", { type: "number", iconClass: "fa fa-shopping-cart", isIcon: true }),
      new ColumnTable("productCode", "Código", { isSmall: true }),
      new ColumnTable("description", "Descripción", { isSmall: true }),
      new ColumnTable("serial", "Serie", { type: "badge" }),
      new ColumnTable("price", "Sub-Total", { type: "currency" }),
      new ColumnTable("discountTotal", "Descuento", { type: "currency" }),
      new ColumnTable("taxTotal", "Impuesto", { type: "currency" }),
      new ColumnTable("total", "Total", { type: "currency" })
    ]
  }

  loadProductsCart() {
    this.productsCart = new Paging(this.receipt.details.map(o => {
      let result = new ProductCart();
      result.productCode = o.productCode;
      result.description = o.productName;
      result.amount = o.quantity
      result.serial = o.serialNumber;
      result.price = o.totals.grossTotal;
      result.discountTotal = o.totals.discountTotal;
      result.taxTotal = o.totals.taxTotal;
      result.total = o.totals.netTotal;

      return result;
    }))
  }

  getPayment(payments: { payments: Array<Payment>, paymentsAmount: number, id: string }) {
    this.doPayment.emit({ payments: payments.payments, paymentsAmount: payments.paymentsAmount, id: payments.id, receiptLines: this.receipt.details });
  }
}
