import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Promotion } from 'src/app/models/promotion';
import { PosPromotionsDetailsModalComponent } from 'src/app/shared/pos-promotions-details-modal/pos-promotions-details-modal.component';
import { PosPromotionsModalComponent } from 'src/app/shared/pos-promotions-modal/pos-promotions-modal.component';
import { PromotionListComponent } from 'src/app/views/promotions/promotion-list/promotion-list.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosPromotionsModalService {

  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }
  show(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.modalRef = this.modalService.show(PosPromotionsModalComponent,
        {
          ignoreBackdropClick: true
        })
      this.modalRef.setClass(`modal-dialog modal-lg ${ModalDialogType.PRIMARY}`)
      resolve(true);
    });
  }
}
