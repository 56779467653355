import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderApprovalComponent } from './order-approval.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { StockSelectionModule } from '../stock-selection/stock-selection.module';
import { PosTotalsModule } from 'src/app/shared/pos-totals/pos-totals.module';
import { PosPaymentDetailsModule } from 'src/app/shared/pos-payment-details/pos-payment-details/pos-payment-details.module';
import { TransactionService } from 'src/app/services/transaction.service';
import { HasPrivilegeModule } from 'src/app/@core/directives/has-privilege/has-privilege.module';

@NgModule({
  declarations: [OrderApprovalComponent],
  imports: [
    CommonModule,
    PosTableModule,
    AccordionModule,
    FormsModule,
    NgSelectModule,
    ToastrModule,
    UiSwitchModule,
    StockSelectionModule,
    PosTotalsModule,
    PosPaymentDetailsModule,
    HasPrivilegeModule
  ],
  providers: [TransactionService]
})
export class OrderApprovalModule { }
