<div class="container animated ">

  <header>
    <div class="row d-flex justify-content-between">
      <ng-container *ngFor="let step of steps; let i = index;">
        <div class="col-lg-2 stepper-padding" (click)="onClick(i)">
          <div *ngIf="step.stepLabel; else showLabelText">
            <ng-container [ngTemplateOutlet]="step.stepLabel.template">
            </ng-container>
          </div>

          <ng-template #showLabelText>
            <div>
              <div class="card text-white ">
                <div [ngClass]="{'card-body p-1 clearfix btn':true, 'btn-outline-danger': (step.hasError && !step.optional), 'btn-outline-primary': (selectedIndex == i && !(step.hasError && !step.optional)), 'btn-outline-success': (selectedIndex > i && !(step.hasError && !step.optional)), 'btn-outline-secondary': (selectedIndex < i && !(step.hasError && !step.optional))}">
                  <i class="fa fa-check-circle font-3xl float-left " *ngIf="(selectedIndex > i && !(step.hasError && !step.optional))"></i> 
                  <i class="fa fa-circle-o font-3xl float-left " *ngIf="(selectedIndex < i && !(step.hasError && !step.optional))"></i> 
                  <i class="fa fa-circle font-3xl float-left " *ngIf="(selectedIndex == i && !(step.hasError && !step.optional))"></i> 
                  <i class="fa fa-exclamation-circle font-3xl float-left " *ngIf="(step.hasError && !step.optional)"></i> 
                  <div [ngClass]="{' mt-2 text-center':true,'stepper-font-size': step.label.length > 8} ">
                    <b>{{ step.label }}</b>
                  </div>
                </div>
              </div>
              
            </div>
          </ng-template>
        </div>
        
      </ng-container>
    </div>
  </header>

</div>
<div class="card-body animated fadeIn border rounded mb-1 p-1" *ngIf="selected">
  <ng-container [ngTemplateOutlet]="selected.content">
  </ng-container>
</div>
<div class="row d-flex justify-content-between" *ngIf="selected">
<button cdkStepperPrevious class="btn btn-primary ml-3">
  <i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Paso Anterior
   </button>  
   <button cdkStepperNext class="btn btn-primary mr-3" [disabled]="(selected.stepControl && !(selected.stepControl.dirty && (selected.stepControl.valid || selected.stepControl.disabled)) && !selected.optional) || (!selected.stepControl && !selected.optional)">
    Siguiente Paso&nbsp;&nbsp;<i class="fa fa-arrow-right"></i>
  </button>
</div>