import { Injectable } from '@angular/core';
import { RegisterStatus, Role, UserStatus } from '../@core/constants';
import { Branch } from '../models/branch';
import { BranchRegion } from '../models/branch-region';
import { PhysicalEstablishment } from '../models/physical-establishment';
import { Region } from '../models/region';
import { Register } from '../models/register';
import { SalesOrg } from '../models/sales-org';
import { User } from '../models/user';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private localStorageService: LocalStorageService) { }

  readSelectedBranchAndSalesOrg(): { salesOrg: SalesOrg, branch: Branch } {
    let sBSO: any = this.localStorageService.get("selectedBranchAndSalesOrg");
    sBSO = sBSO ? JSON.parse(sBSO) as { salesOrg: SalesOrg, branch: Branch } : { salesOrg: new SalesOrg(), branch: new Branch() };

    return sBSO;
  }

  createSelectedBranchAndSalesOrg(selectedBranchAndSalesOrg: { salesOrg: SalesOrg | undefined, branch: Branch }): ConfigurationService {
    this.localStorageService.set("selectedBranchAndSalesOrg", JSON.stringify(selectedBranchAndSalesOrg));
    return this;
  }

  readSalesOrg(): Array<SalesOrg> {
    let sO = this.localStorageService.get("salesOrg");
    return sO ? JSON.parse(sO) as Array<SalesOrg> : [];
  }

  createSalesOrg(salesOrg: SalesOrg[]): ConfigurationService {
    this.localStorageService.set("salesOrg", JSON.stringify(salesOrg));
    return this;
  }

  readBranch(): any {
    let br = this.localStorageService.get("branch");
    return br ? JSON.parse(br) : {};

  }

  createBranch(branch: any): ConfigurationService {
    this.localStorageService.set("branch", JSON.stringify(branch));
    return this;
  }

  readPhysicalEstablishment(): PhysicalEstablishment {
    let pE = this.localStorageService.get("physicalEstablishment");
    return pE ? JSON.parse(pE) as PhysicalEstablishment : { address: "", city: "", code: "", country: "", name: "" };
  }

  createPhysicalEstablishment(physicalEstablishment?: PhysicalEstablishment): ConfigurationService {
    this.localStorageService.set("physicalEstablishment", JSON.stringify(physicalEstablishment));
    return this;
  }

  readRegister(): Register {
    let reg = this.localStorageService.get("register");
    return reg ? JSON.parse(reg) as Register : { closeTimeStamp: new Date(0), code: "", currency: "", defaultCash: 0, failedToClose: false, lastPeriod: "", name: "", openTimeStamp: new Date(0), openingTotals: new Map<string, number>(), operator: "", physicalEstablishmentCode: "", status: RegisterStatus.INACTIVE, totals: new Map<string, number>() };
  }
  createRegister(register?: Register): ConfigurationService {
    this.localStorageService.set("register", JSON.stringify(register));
    return this;
  }
  readSupervisor(): User {
    let sup = this.localStorageService.get("supervisor");
    return sup ? (JSON.parse(sup) as User) : { username: "", branchCodes: new Set<string>(), fullname: "", roles: new Set<Role>(), status: UserStatus.INACTIVE };
  }
  createSupervisor(user: User | undefined): ConfigurationService {
    this.localStorageService.set("supervisor", JSON.stringify(user))
    return this;
  }

  readRegion(): BranchRegion[] {
    let region = this.localStorageService.get("region");
    if(region!=undefined && !Array.isArray(JSON.parse(region)))
    {
      region=`[${region}]`;
    }

    return region ? JSON.parse(region) as BranchRegion[] : [];
  }

  readRegionCodesList(): string {
    let regions=this.readRegion();
    let regionCodes:string="";
    regions.forEach(r=>{
      regionCodes=`${regionCodes},${r.code}`;
    });

    return regionCodes;
  }

  createRegion(region?: BranchRegion[]): ConfigurationService {
    this.localStorageService.set("region", JSON.stringify(region));
    return this;
  }

  removeConfiguration() {
    this.localStorageService.remove("physicalEstablishment")
      .remove("register")
      .remove("salesOrg")
      .remove("branch")
      .remove("supervisor")
      .remove("selectedBranchAndSalesOrg")
      .remove("region");
  }
}
