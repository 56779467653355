import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StockRequestModel } from 'src/app/models/requests/stock-request-model';
import { StockResponseModel } from 'src/app/models/response/stockResponseModel';

@Component({
  selector: 'app-pos-stock-validate-modal',
  templateUrl: './pos-stock-validate-modal.component.html',
  styleUrls: ['./pos-stock-validate-modal.component.sass']
})
export class PosStockValidateModalComponent implements OnInit {

  public stock: Array<StockResponseModel> = new Array<StockResponseModel>();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  cancel(){
    this.bsModalRef.hide();
  }

}
