import { DiscountType } from "../@core/constants";

export class Discount {
    discountType: DiscountType;
    discountValue?: number;
    promotionCode?: string;

    constructor() {
        this.discountType = DiscountType.NONE;
        //this.discountValue = 0;
        //this.promotionCode = "";
    }

    static build(discountType: DiscountType, discountValue: number, promotionCode: string) {
        let discount = new Discount();
        discount.discountType = discountType;
        discount.discountValue = discountValue;
        discount.promotionCode = promotionCode
        return discount;
    }
}