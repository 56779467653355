import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCustomerTableComponent } from './pos-customer-table.component';
import { PosTableModule } from '../pos-table/pos-table.module';
import { CustomerService } from 'src/app/services/customer.service';



@NgModule({
  declarations: [PosCustomerTableComponent],
  imports: [
    CommonModule,
    PosTableModule
  ],
  providers: [CustomerService],
  exports: [PosCustomerTableComponent]
})
export class PosCustomerTableModule { }
