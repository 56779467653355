import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advance',
  templateUrl: './advance.component.html',
  styleUrls: ['./advance.component.sass']
})
export class AdvanceComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}

