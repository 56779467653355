import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OnbaseProcedureResponseModel } from 'src/app/models/response/onbaseProcedureResponseModel';

@Component({
  selector: 'app-credit-plan-requirements-list-modal',
  templateUrl: './credit-plan-requirements-list-modal.component.html',
  styleUrls: ['./credit-plan-requirements-list-modal.component.sass'],
})
export class CreditPlanRequirementsListModalComponent implements OnInit {
  creditPlan!: OnbaseProcedureResponseModel;
  
  constructor(public modalRef: BsModalRef) {}

  ngOnInit(): void {}
}
