import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DATE_PATTERN, ModalDialogType, OnBaseCatalogueType } from 'src/app/@core/constants';
import { Customer } from 'src/app/models/customer';
import { Param } from 'src/app/models/param';
import { CreditStatusFranchisedResponseModel } from 'src/app/models/response/customerCreditStatusResponseModel';
import { CustomerCreditRequestStepperComponent } from '..';
import { OnbaseService } from './../../../../services/onbase.service';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ReasonLogRequest } from 'src/app/models/request/reason-log-request-model';
import { CustomerVerificationService } from 'src/app/services/customer-verification.service';
import { AuthService } from 'src/app/services/auth.service';
import { PosReasonSelectModalService } from 'src/app/@core/services/pos-reason-select-modal.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
@Component({
  selector: 'app-customer-identification',
  templateUrl: './customer-identification.component.html',
  styleUrls: ['./customer-identification.component.sass'],
})
export class CustomerIdentificationComponent implements OnInit {
  @ViewChild('creditRequestStepper')
  creditRequestStepper!: CustomerCreditRequestStepperComponent;

  currentCustomer: Customer;
  currentCustomerCreditConditions: CreditStatusFranchisedResponseModel;
  homeTypes: Array<Param>;
  preApprovalForm: FormGroup;

  constructor(private onBaseService: OnbaseService,
    private fb: FormBuilder,
    private posConfirmationService: PosConfirmationService,
    public router: Router,
    private customerService: CustomerService,
    private configService: ConfigurationService,
    private customerVerificationService: CustomerVerificationService,
    private authService: AuthService,
    private reasonSelectModalService:PosReasonSelectModalService,
    private localStorageService:LocalStorageService
  ) {
    this.currentCustomer = new Customer();
    this.currentCustomerCreditConditions =
      new CreditStatusFranchisedResponseModel();
    this.homeTypes = new Array();

    this.preApprovalForm = this.fb.group({
      amountToBill: new FormControl(0, [Validators.required]),
      monthIncome: new FormControl(0, [Validators.required]),
      workSeniority: new FormControl(undefined, [Validators.required]),
      homeType: new FormControl(undefined, [Validators.required]),
      ownVehicle: new FormControl(false, [Validators.required]),
    });

    this.preApprovalForm.valueChanges.subscribe(
      (formValue) => (this.creditRequestStepper.alreadyPreApproved = false)
    );
  }

  ngOnInit(): void { }

  async handleSelectedCustomerChange(customerData: {
    customer: Customer;
    customerCreditConditions: CreditStatusFranchisedResponseModel;
  }): Promise<void> {
    this.currentCustomer = customerData.customer;
    this.currentCustomerCreditConditions =
      customerData.customerCreditConditions;

    await this.fetchHomeTypes();
    this.backFormToInitialState();

    if (this.creditRequestStepper) {this.creditRequestStepper.resetStepper();}

    const customerNeedUpdateResponse = (await this.customerService
      .customerNeedUpdate(this.currentCustomer.code,this.configService.readSelectedBranchAndSalesOrg().branch.code)
      .toPromise())
      .response;


    this.localStorageService.set('customerVerifyData',JSON.stringify(customerNeedUpdateResponse.verifyData));

    if (customerNeedUpdateResponse.customerNeedUpdate) {

      const userWantsToUpdateCustomer = await this.posConfirmationService.showAsync(
        'Actualización de datos',
        `Este cliente necesita actualización de datos ¿Desea ir a actualización de clientes?, ${customerNeedUpdateResponse.message}`,
        ModalDialogType.DANGER,
      );

      if(userWantsToUpdateCustomer){
        this.router.navigate(['/customer/update', this.currentCustomer.code]);
      }else{

        const selectedReason = await this.reasonSelectModalService.show('verifyCustomer');

        const requestToSaveReasonLog:ReasonLogRequest = {
          branchCode:this.configService.readSelectedBranchAndSalesOrg().branch.code,
          component:'CUSTOMERS',
          customerCode:this.currentCustomer.code,
          description:selectedReason?.description || "",
          reasonCode:selectedReason?.itemCode || "RA",
          sellerCode:(await this.authService.readProfile()).username,
          salesOrgCode:this.configService.readSelectedBranchAndSalesOrg().salesOrg.code,
          yesOrNo:'NO'
        };
        this.customerVerificationService.saveReasonToNotUpdateCustomer(requestToSaveReasonLog).toPromise();
      }

    }
  }

  private backFormToInitialState(): void {
    this.preApprovalForm.patchValue({
      amountToBill: 0,
      monthIncome: 0,
      workSeniority: undefined,
      homeType: undefined,
      ownVehicle: false,
    });
  }

  async fetchHomeTypes(): Promise<void> {
    this.homeTypes = await this.onBaseService
      .readOnBaseCatalogueObject(
        OnBaseCatalogueType.HOME,
        this.currentCustomer.mainPersonalInfo?.personality
      )
      .toPromise();
  }

  validateCustomerStatus() {
    return this.currentCustomerCreditConditions.estadoCliente != "BLQ";
  }
}
