import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionListComponent } from './promotion-list.component';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { PosPromotionsDetailsModalService } from 'src/app/@core/services/pos-promotions-details-modal.service';
@NgModule({
  declarations: [PromotionListComponent],
  imports: [
    CommonModule,
    PosTableModule

  ],
  providers: [PosPromotionsDetailsModalService],
  exports: [PromotionListComponent]
})
export class PromotionListModule { }
