import { CustomerType, DigitalSignatureRequestStatusInOrder, OrderDiscountStatus, OrderProcessingStatus, OrderStatus, OrderType } from "../@core/constants";
import { Discount } from "./discount";
import { OrderEntry } from "./order-entry";
import { Totals } from "./totals";

export class Order {

    code: string;
    originalOrderCode: string;
    branchCode: string;
    salesOrgCode: string;
    salesRequestNumber: string;
    routeCode: string;
    routeDescription: string;
    discounts: Array<Discount>;
    orderType: OrderType;
    invoiceCode: string;
    invoiceNumber: string;
    receiptCode: string;
    vendor: string;
    vendorName: string;
    shippingCost: number;
    insuranceCost: number;
    cancellationReason: string;
    cancellationReasonCode: string;
    exonerationReason: string;
    exonerationDocumentId: string;
    exonerationDocumentExpirationDate: string;
    branchName: string;
    customerCode: string;
    customerFiscalId: string;
    customerName: string;
    customerLastName: string;
    customerType: CustomerType;
    customerCredit: number;
    status: OrderStatus;
    processingStatus: OrderProcessingStatus;
    processingApproverUserCode: string;
    discountStatus: OrderDiscountStatus;
    discountApproverUserCode: string;
    discountDate: string;
    discountNotes: string;
    timeStamp: string;
    orderEntryList: Array<OrderEntry>;
    potentialPromotions: Set<string>;
    appliedPromotions: Set<string>;
    currency: string;
    totals: Totals;
    updated: boolean;
    condition: string;
    extraFinancingPercentage: number;
    customerCredityType: string;
    creditProcedure: string;
    printingAuthorizationKey: string;
    originalInvoiceDate: string;
    originalExternalInvoices: string;
    reserved: boolean;
    repairOrder: string;
    repairSerial: string;
    repairMakeId: string;
    repairColor: string;
    repairKms: string;
    repairModel: string;
    paymentCondition: string;
    validDate: string;
    dummy: boolean;
    invoiceNumberSD: string;
    repairshopOrderSubType: string;
    exemptionDocumentId: string;
    contractNumber: string;
    firstTime: string;
    fullName: string;
    netTotal: string;
    rejectedReason: string;
    isThirtyDays: boolean;
    orderCondition?: number;
    physicalEstablishmentCode: string;
    processingStatusDigitalSignature: DigitalSignatureRequestStatusInOrder;
    paymentResponsible: string;
    paymentResponsibleName: string;
    digitalSignature: boolean;
    observations: string;

    constructor() {
        this.code = "";
        this.originalOrderCode = "";
        this.branchCode = "";
        this.salesOrgCode = "";
        this.salesRequestNumber = "";
        this.routeCode = "";
        this.routeDescription = "";
        this.discounts = [];
        this.orderType = OrderType.REGULAR;
        this.invoiceCode = "";
        this.invoiceNumber = "";
        this.receiptCode = "";
        this.vendor = "";
        this.vendorName = "";
        this.shippingCost = 0;
        this.insuranceCost = 0;
        this.cancellationReason = "";
        this.cancellationReasonCode = "";
        this.exonerationReason = "";
        this.exonerationDocumentId = "";
        this.exonerationDocumentExpirationDate = "";
        this.branchName = "";
        this.customerCode = "";
        this.customerFiscalId = "";
        this.customerName = "";
        this.customerLastName = "";
        this.customerType = CustomerType.RETAIL;
        this.customerCredit = 0;
        this.status = OrderStatus.NONE;
        this.processingStatus = OrderProcessingStatus.NONE;
        this.processingApproverUserCode = "";
        this.discountStatus = OrderDiscountStatus.NONE;
        this.discountApproverUserCode = "";
        this.discountDate = "";
        this.discountNotes = "";
        this.timeStamp = "";
        this.orderEntryList = [];
        this.potentialPromotions = new Set<string>();
        this.appliedPromotions = new Set<string>();
        this.currency = "";
        this.totals = new Totals();
        this.updated = false;
        this.condition = "";
        this.extraFinancingPercentage = 0;
        this.customerCredityType = "";
        this.creditProcedure = "";
        this.printingAuthorizationKey = "";
        this.originalInvoiceDate = "";
        this.originalExternalInvoices = "";
        this.reserved = false;
        this.repairOrder = "";
        this.repairSerial = "";
        this.repairMakeId = "";
        this.repairColor = "";
        this.repairKms = "";
        this.repairModel = "";
        this.paymentCondition = "";
        this.validDate = "";
        this.dummy = false;
        this.invoiceNumberSD = "";
        this.repairshopOrderSubType = "";
        this.exemptionDocumentId = "";
        this.contractNumber = "";
        this.firstTime = "";
        this.fullName = "";
        this.netTotal = "";
        this.rejectedReason = "";
        this.isThirtyDays = false;
        this.physicalEstablishmentCode = "";
        this.processingStatusDigitalSignature = DigitalSignatureRequestStatusInOrder.NA;
        this.paymentResponsible = "";
        this.paymentResponsibleName = "";
        this.digitalSignature = false;
        this.observations = "";
    }
}