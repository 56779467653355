import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VerifyCustomerValidateTokenType } from 'src/app/@core/constants';
import { VerifyCustomerCheckResponseData } from 'src/app/models/response/VerifyCustomerCheckResponse';

@Component({
  selector: 'app-pos-verify-customer',
  templateUrl: './pos-verify-customer.component.html',
  styleUrls: ['./pos-verify-customer.component.sass'],
})
export class PosVerifyCustomerComponent implements OnInit {
  @Input() customerDataToVerify: VerifyCustomerCheckResponseData | undefined;
  @Input() customerCode: string = '';
  @Output() verificationComplete = new EventEmitter<boolean>(false);
  verifyEmailType = VerifyCustomerValidateTokenType.EMAIL;
  verifyPhoneType = VerifyCustomerValidateTokenType.PHONE;

  emailVerified = false;
  phoneVerified = false;
  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  cancel() {
    this.bsModalRef.hide();
    this.verificationComplete.emit(false);
    this.verificationComplete.complete();
  }

  handleVerificationComplete(success: boolean, verifyTokenType:VerifyCustomerValidateTokenType) {
    if(verifyTokenType == VerifyCustomerValidateTokenType.EMAIL){
      this.emailVerified = success;
    }

    if(verifyTokenType == VerifyCustomerValidateTokenType.PHONE){
      this.phoneVerified = success;
    }

    if(this.customerDataToVerify?.email.email != undefined && this.customerDataToVerify?.phone.phone_number != undefined && this.emailVerified && this.phoneVerified){
      this.verificationComplete.emit(success);
      this.verificationComplete.complete();
      this.cancel();
    }

    if(this.customerDataToVerify?.email.email == undefined && this.customerDataToVerify?.phone.phone_number != undefined && !this.emailVerified && this.phoneVerified){
      this.verificationComplete.emit(success);
      this.verificationComplete.complete();
      this.cancel();
    }

    if(this.customerDataToVerify?.email.email != undefined && this.customerDataToVerify?.phone.phone_number == undefined && this.emailVerified && !this.phoneVerified){
      this.verificationComplete.emit(success);
      this.verificationComplete.complete();
      this.cancel();
    }

  }
}
