<div class="card" [ngClass]="{
  'border-danger':
    !verifyCustomerData?.email?.verified &&
    !verifyCustomerData?.phone?.verified,
  'border-success':
    verifyCustomerData?.email?.verified &&
    verifyCustomerData?.phone?.verified,
  'border-warning':
    !verifyCustomerData?.email?.verified ||
    !verifyCustomerData?.phone?.verified
}">
  <div class="card-header d-flex align-items-center justify-content-between">
    <div>
      <i class="fa fa-address-book"></i> Información de contacto
    </div>
    <div>
      Verificado
    </div>
  </div>
  <div class="card-body">
    <div class="row align-items-center">
      <div class="col-sm-12 d-flex justify-content-between align-items-center">
        <span class="d-flex align-items-center">
          <i class="fa fa-phone "></i>
          <span class="ml-2">{{
            verifyCustomerData?.phone?.phone_number || ""
            }}</span>
        </span>
        <span *ngIf="verifyCustomerData?.phone?.verified; else notVerifyIcon">
          <i class="fa fa-check text-success"></i>
        </span>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-sm-12 d-flex justify-content-between align-items-center">
        <span class="d-flex align-items-center">
          <i class="fa fa-envelope "></i>
          <span class="ml-2">{{ verifyCustomerData?.email?.email || "" }}</span>
        </span>
        <span *ngIf="verifyCustomerData?.email?.verified; else notVerifyIcon">
          <i class="fa fa-check text-success"></i>
        </span>
      </div>
    </div>
  </div>
</div>
<!-- icono a mostrar si no se ha verificado correo o telefono-->
<ng-template #notVerifyIcon>
  <span>
    <i class="fa fa-times text-danger"></i>
  </span>
</ng-template>
<!-- <button class="form control btn btn-outline-primary">
            Verificar
          </button> -->
