import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackOfficeOrderListComponent } from './back-office-order-list.component';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';



@NgModule({
  declarations: [BackOfficeOrderListComponent],
  imports: [
    CommonModule,
    PosTableModule
  ]
})
export class BackOfficeOrderListModule { }
