<form #exonerationForm="ngForm" novalidate (ngSubmit)="exonerationForm.valid && confirm()">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Exoneración de Impuestos</h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="text-input"><b>Número Documento</b></label>
        <div class="col-md-9">
          <input type="text" id="text-input" name="text-input" class="form-control"
            [(ngModel)]="taxExemption.documentId" #documentId="ngModel" required
            [class.is-invalid]="(documentId.touched || documentId.dirty) && documentId.invalid">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="text-input"><b>Tipo</b></label>
        <div class="col-md-9">
          <select id="reason" name="reason" class="form-control" [(ngModel)]="reasonId" #reason="ngModel" required
            [class.is-invalid]="(reason.touched || reason.dirty) && reason.invalid">
            <option *ngFor="let reason of reasons" [value]="reason.code">{{reason.reason}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="text-input"><b>Fecha expiración</b></label>
        <div class="col-md-9">
          <input class="form-control" id="date" type="date" name="date-input" placeholder="date" #date="ngModel"
            [(ngModel)]="taxExemption.date" [class.is-invalid]="(date.touched || date.dirty) && date.invalid" required
            [min]="maxDate | date:'yyyy-MM-dd'">
        </div>
      </div>
    </div>
    <div class="modal-footer">

      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
      <button type="button" class="btn btn-danger" (click)="remove()">Eliminar</button>
      <button type="submit" class="btn btn-primary">Confirmar</button>
    </div>
  </div><!-- /.modal-content -->
</form>
