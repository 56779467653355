import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'pos-confirmation',
  templateUrl: './pos-confirmation.component.html',
  styleUrls: ['./pos-confirmation.component.sass']
})
export class PosConfirmationComponent implements OnInit {
  @Output() userResponse = new EventEmitter<boolean>();

  public title: string = "";

  public message: string = "";

  public yesFn: (() => any) = (() => { });

  public noFn: (() => any) = (() => { });

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
  }

  confirm() {
    this.yesFn();
    this.userResponse.emit(true);
    this.userResponse.complete();
    this.bsModalRef.hide();
  }
  cancel() {
    this.noFn();
    this.userResponse.emit(false);
    this.userResponse.complete();
    this.bsModalRef.hide();
  }
}
