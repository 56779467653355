<div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Stock:</h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table class="table">
        <thead>
          <th>Código</th>
          <th>Cantidad</th>
        </thead>
        <tbody>
          <tr *ngFor="let product of stock">
            <td>{{product.productCode}}</td>
            <td>{{product.quantityNumber}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
    </div>
  </div>