import { Payment } from "../payment";

export class PaymentsRequestModel {

	registerCode: string;
	branchCode: string;
	salesOrgCode: string;
	destinationSalesOrgCode: string;
	documentNo: string;
	createdOn: Date;
	orderCode: string;
	invoiceCode: string;
	replicateInExternalSystem: boolean;
	receiptCode: string;
	payments: Array<Payment>;
	isForActiveCreditNote: boolean;
	reference: string;

	constructor() {
		this.registerCode = "";
		this.branchCode = "";
		this.salesOrgCode = "";
		this.destinationSalesOrgCode = "";
		this.documentNo = "";
		this.createdOn = new Date();
		this.orderCode = "";
		this.invoiceCode = "";
		this.replicateInExternalSystem = false;
		this.receiptCode = "";
		this.payments = new Array<Payment>();
		this.isForActiveCreditNote = false;
		this.reference = "";
	}
}