<form [formGroup]="discountForm" novalidate (ngSubmit)="discountForm.valid && confirm()">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Descuentos</h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="discountType"><b>Tipo de Descuento</b></label>
        <div class="col-md-9">
          <select type="text" id="discountType" name="discountType" class="form-control" formControlName="discountType"
            [class.is-invalid]="(discountForm.get('discountType')?.touched || discountForm.get('discountType')?.dirty) && discountForm.get('discountType')?.invalid"
            (change)="changeType()">
            <option value="NONE">Seleccione un tipo de Descuento</option>
            <option value="PERCENTAGE">Descuento Porcentual %</option>
            <option value="ABSOLUTE">Descuento Absoluto</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="discountValue"><b>Valor</b></label>
        <div class="col-md-9">
          <input id="discountValue" name="discountValue" type="number" class="form-control"
            formControlName="discountValue"
            [class.is-invalid]="(discountForm.get('discountValue')?.touched || discountForm.get('discountValue')?.dirty) && discountForm.get('discountValue')?.invalid">

        </div>
      </div>
    </div>
    <div class="modal-footer">

      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
      <button type="button" class="btn btn-danger" (click)="remove()">Eliminar</button>
      <button type="submit" class="btn btn-primary">Confirmar</button>
    </div>
  </div><!-- /.modal-content -->
</form>