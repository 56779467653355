import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Big } from 'big.js';
import { ToastrService } from 'ngx-toastr';
import { DiscountType, ModalDialogType } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { PosDiscountModalService } from 'src/app/@core/services/pos-discount-modal.service';
import { PosPromotionsModalService } from 'src/app/@core/services/pos-promotions-modal.service';
import { PosSerialModalService } from 'src/app/@core/services/pos-serial-modal.service';
import { PosSupervisorLoginService } from 'src/app/@core/services/pos-supervisor-login.service';
import { ColumnTable } from 'src/app/models/column-table';
import { Discount } from 'src/app/models/discount';
import { Filter } from 'src/app/models/filter';
import { FilterTable } from 'src/app/models/filter-table';
import { ProductFilter } from 'src/app/models/filters/product-filter';
import { PromotionFilter } from 'src/app/models/filters/promotion-filter';
import { Franchise } from 'src/app/models/franchised';
import { Order } from 'src/app/models/order';
import { Paging } from 'src/app/models/page';
import { ProductCart } from 'src/app/models/product-cart';
import { Promotion } from 'src/app/models/promotion';
import { OrderRequest } from 'src/app/models/requests/order-request';
import { Stock } from 'src/app/models/stock';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { ProductTableModel } from 'src/app/models/table-models/product-table';
import { Tax } from 'src/app/models/tax';
import { TaxExemption } from 'src/app/models/tax-exemption';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { ParamsService } from 'src/app/services/params.service';
import { ProductsService } from 'src/app/services/products.service';
import { PromotionsService } from 'src/app/services/promotions.service';
import { TaxesService } from 'src/app/services/taxes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'cart-step',
  templateUrl: './cart-step.component.html',
  styleUrls: ['./cart-step.component.sass'],
})
export class CartStepComponent implements OnInit, OnChanges {
  @Input()
  productsCart: Paging<Array<ProductCart>> = new Paging([]);
  products: Paging<Array<ProductTableModel>> = new Paging([]);

  @Input()
  orderSimulate: Order = new Order();
  private taxes: Array<Tax> = [];
  promotions: Paging<Array<Promotion>> = new Paging([]);
  franchised = new Franchise();

  @Input()
  activePromotions: Paging<Array<Promotion>> = new Paging([]);
  @Input()
  customer: CustomerTableModel = new CustomerTableModel();

  @Input()
  seller: CustomerTableModel = new CustomerTableModel();

  @Input()
  taxExemption: TaxExemption = new TaxExemption();

  @Input()
  orderDiscount: Discount = new Discount();

  @Input()
  orderTypeId: number = 0;

  @Input()
  observations: string = '';

  @Output()
  getOrder = new EventEmitter<{
    order: Order;
    orderRequest?: OrderRequest;
    productsCart: Paging<Array<ProductCart>>;
  }>();

  @Output()
  getTableFormExternal = new EventEmitter();

  @Output() getCustomerStepInfo = new EventEmitter<any>();

  @Output()
  removeDiscount = new EventEmitter();
  params: { [value: string]: string } = {};

  orderRequest: OrderRequest = new OrderRequest();

  constructor(
    private productsService: ProductsService,
    private taxesService: TaxesService,
    private orderService: OrderService,
    private configService: ConfigurationService,
    private posSerialModalService: PosSerialModalService,
    private discountModalService: PosDiscountModalService,
    private promotionService: PromotionsService,
    private confirmService: PosConfirmationService,
    private promotionListModalService: PosPromotionsModalService,
    private paramsService: ParamsService,
    private toastrService: ToastrService,
    private oauthService: AuthService,
    private supervisorService: PosSupervisorLoginService
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.franchised =
      this.configService.readSelectedBranchAndSalesOrg().branch.franquicia;
    this.orderTypeId = this.orderTypeId || this.orderSimulate.orderCondition!;
    if (this.getOnChangesValidations(changes)) {
      await this.getOrderFromServer(this.productsCart.content);
    }
    this.params = (await this.paramsService.readParams().toPromise()).response;
  }
  getOnChangesValidations(changes: SimpleChanges) {
    let result =
      (changes.taxExemption &&
        changes.taxExemption.currentValue &&
        !changes.taxExemption.isFirstChange()) ||
      (changes.orderDiscount &&
        changes.orderDiscount.currentValue &&
        !changes.orderDiscount.isFirstChange());

    return result;
  }

  async ngOnInit(): Promise<void> {
    this.taxes = (
      await this.taxesService.readTaxes().toPromise()
    ).response.content;
    await this.getProductData({ page: 0, pageSize: 5, value: {} });
    this.franchised =
      this.configService.readSelectedBranchAndSalesOrg().branch.franquicia;
    this.orderTypeId = this.orderTypeId || this.orderSimulate.orderCondition!;
  }

  getProductCartHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('code', 'Opciones', {
        type: 'action',
        actions: [
          {
            fn: async (val: ProductCart) => {
              await this.removeProductCart(val);
            },
            icon: 'fa fa-times text-danger',
            isDisabled: false,
            tooltip: 'Eliminar Producto',
          },
          {
            fn: async (val: ProductCart) => {
              await this.addDiscount(val);
            },
            icon: 'fa fa-percent',
            isDisabled: false,
            tooltip: 'Agregar descuento',
            isPrivileged: true,
            privilege: 'setDiscountFranchised',
          },
        ],
        iconClass: 'fa fa-cogs',
        isIcon: true,
      }),
      new ColumnTable('amount', '#', {
        type: 'number',
        isIcon: true,
        iconClass: 'fa fa-shopping-cart',
        editable: true,
        required: true,
      }),
      new ColumnTable('productCode', 'Código', { isSmall: true }),
      new ColumnTable('description', 'Descripción', { isSmall: true }),
      // new ColumnTable("price", "Precio", "number"),
      // new ColumnTable("tax", "Impuesto", "number"),
      new ColumnTable('serial', 'Serie', {
        type: 'badge',
        cellFn: async (row: ProductCart) => {
          if (row.requiredSerial) {
            await this.addSerialNumber(row);
          }
        },
        cellTooltip: 'Modificar Serie',
      }),
      new ColumnTable('price', 'Sub-Total', { type: 'currency' }),
      new ColumnTable('discountTotal', 'Descuento', { type: 'currency' }),
      new ColumnTable('total', '', { type: 'hidden' }),
      new ColumnTable('taxCode', '', { type: 'hidden' }),
      new ColumnTable('requiredSerial', '', { type: 'hidden' }),
      new ColumnTable('discount', '', { type: 'hidden' }),
      new ColumnTable('referenceCode', '', { type: 'hidden' }),
      new ColumnTable('isService', '', { type: 'hidden' }),
      //new ColumnTable("price", "", "hidden"),
    ];
  }
  async addDiscount(val: ProductCart): Promise<void> {
    const userProfile = await this.oauthService.readProfileFromAccessToken(
      this.oauthService.currentSuperTokenValue.length > 0
        ? this.oauthService.currentSuperTokenValue
        : this.oauthService.readTokenFromStorage().token
    );

    const rolesResponse = await this.oauthService.readRoles(userProfile.roles);

    let maxReplacementDiscountPercentage = 0;
    let maxEquipmentDiscountPercentage = 0;

    const rolesWithDiscount = rolesResponse.response.content.filter(
      (r) =>
        r.equipmentDiscountPercentage > 0 && r.replacementDiscountPercentage > 0
    );

    if (rolesWithDiscount.length > 0) {
      maxReplacementDiscountPercentage = Math.max(
        ...rolesWithDiscount.map((r) => r.replacementDiscountPercentage)
      );
      maxEquipmentDiscountPercentage = Math.max(
        ...rolesWithDiscount.map((r) => r.equipmentDiscountPercentage)
      );
    }

    await this.discountModalService.show(val.discount, (discount: Discount) => {
      let productCart =
        this.productsCart.content.find((p) => p.code == val.code) ||
        new ProductCart();

      let maxDiscountToValidate = 0;

      if (productCart.requiredSerial) {
        maxDiscountToValidate = maxEquipmentDiscountPercentage;
      } else {
        maxDiscountToValidate = maxReplacementDiscountPercentage;
      }

      if (
        this.getPercetangeDiscount(discount, productCart.price) >
        maxDiscountToValidate
      ) {
        this.toastrService.warning(`El descuento excede el maximo permitido`);
        return;
      }

      productCart.discount = discount;
      productCart.discountTotal =
        this.getDiscount(discount, productCart.price) * productCart.amount;
      this.productsCart = new Paging(this.productsCart.content);
    });
  }
  getPercetangeDiscount(discount: Discount, price: number): number {
    let result = 0;

    if (discount.discountType == DiscountType.ABSOLUTE) {
      result = Number.parseFloat(
        Big(discount.discountValue || 0)
          .div(price)
          .mul(100)
          .toFixed(2)
      );
    } else if (discount.discountType == DiscountType.PERCENTAGE) {
      result = Number.parseFloat(Big(discount.discountValue || 0).toFixed(2));
    }

    return result;
  }
  getDiscount(discount: Discount, price: number): number {
    let result = 0;
    if (discount.discountType == DiscountType.PERCENTAGE) {
      result = Number.parseFloat(
        Big(price)
          .mul(Big(discount.discountValue || 0).div(100))
          .toFixed(2)
      );
    } else if (discount.discountType == DiscountType.ABSOLUTE) {
      result = Number.parseFloat(Big(discount.discountValue || 0).toFixed(2));
    }
    return result;
  }

  private async addSerialNumber(row: ProductCart): Promise<void> {
    await this.posSerialModalService
      .show(row, (serial: Stock, product: ProductCart) => {
        let index = this.productsCart.content.findIndex(
          (f) => f.code == product.code
        );
        this.productsCart.content[index].serial = serial.serialNumber;
        this.productsCart = new Paging<Array<ProductCart>>(
          this.productsCart.content
        ).setValues(this.productsCart);
      })
      .catch((x) => {
        this.toastrService.warning(x, '!¡Advertencia!');
      });
  }

  async saveProductCart(table: Array<ProductCart>): Promise<void> {
    await this.getOrderFromServer(table);
    this.productsCart.content = table;
  }


  private async getOrderFromServer(table: ProductCart[]) {
    const orderRequest = new OrderRequest().buildOrderRequest(
      table,
      this.taxes,
      this.customer,
      this.seller,
      this.configService.readSelectedBranchAndSalesOrg().branch.code,
      this.configService.readSelectedBranchAndSalesOrg().salesOrg.code,
      this.taxExemption,
      this.orderDiscount,
      this.activePromotions.content.map((m) => m.code),
      this.configService.readPhysicalEstablishment().code,
      this.observations,
      this.orderSimulate.orderType,
      this.orderSimulate.status,
      this.orderTypeId,
      this.franchised
    );

    this.orderRequest = orderRequest;

    const orderFromServer = (
      await this.orderService.createOrderSimulate(orderRequest).toPromise()
    ).response;

    orderFromServer.orderCondition =
      this.orderTypeId || this.orderSimulate.orderCondition;
    orderFromServer.code = this.orderSimulate.code;

    // orderFromServer.orderEntryList.forEach((o) => {
    //   const pIndex = this.orderSimulate.orderEntryList.findIndex(
    //     (i) => i.productCode === o.productCode
    //   );

    //   if (pIndex >= 0) {
    //     const orderItem = this.orderSimulate.orderEntryList[pIndex];
    //     if(orderItem.serialNumber === o.serialNumber){
    //       o.isFirstTimeInOrder = false;
    //       o.term =orderItem.term;
    //       o.maxTerm = orderItem.maxTerm;
    //       o.minTerm = orderItem.minTerm;
    //       o.rate = orderItem.rate;
    //     }else{
    //       o.isFirstTimeInOrder = true;
    //     }
    //   } else {
    //     o.isFirstTimeInOrder = true;
    //   }
    // });

    this.orderSimulate = orderFromServer;

    await this.getPromotionData({
      page: 0,
      pageSize: 5,
      value: { promotionCodes: [] },
    });

    this.getOrder.emit({
      order: this.orderSimulate,
      productsCart: new Paging(table),
      orderRequest: this.orderRequest,
    });

    this.getCustomerStepInfo.emit({ creditInfo: true });
  }

  getProductFilters(): Array<FilterTable> {
    return [
      new FilterTable('text', 'Búsqueda General'),
      new FilterTable('ean', 'EANs'),
      new FilterTable('hasExistence', 'Existencia', { type: 'switch' }),
    ];
  }
  getProductsCart() {
    return new Paging(
      this.orderSimulate.orderEntryList.map((m) => {
        return (
          new ProductCart()
            .setCode(parseInt(m.code))
            .setProductCode(m.productCode)
            .setDescription(m.productName)
            .setTotal(m.price.amount)
            //.setPrice(m.totals.grossTotal)
            .setPrice(m.price.amount)
            .setDiscountTotal(m.totals.discountTotal)
            //.setStock(m.carStock || 0)
            .setAmount(m.totalQuantity)
            .setTax(m.tax.value)
            .setTaxCode(m.tax.code)
            .setSerial(m.serialNumber)
            .setDiscount(
              m.discounts.find((f) => !f.promotionCode) || new Discount()
            )
            .setRequiredSerial(m.serialProfile)
        );
      })
    );
  }
  async getProductData(params: Filter<ProductFilter>): Promise<void> {
    let result = (await this.productsService.readProducts(params).toPromise())
      .response;

    this.products = new Paging(
      result.content.map((m) => {
        let model = new ProductTableModel();
        let tax = this.taxes.find((w) => w.code == m.taxCode) || new Tax();
        let price = this.getPriceWithoutTax(m.price.amount, tax);
        return model
          .setCode(m.code)
          .setDescription(m.description)
          .setTotal(m.price.amount)
          .setPrice(price)
          .setStock(m.carStock || 0)
          .setTax(tax.value)
          .setTaxCode(tax.code)
          .setRequiredSerial(m.serialProfile)
          .setFeatures(m.features);
      })
    ).setValues(result);
  }

  private getPriceWithoutTax(price: number, tax: Tax) {
    return Number.parseFloat(
      Big(price)
        .div(Big(1).plus(Big(tax.value).div(100)))
        .toFixed(2)
    );
  }

  async productRowClick(product: ProductTableModel): Promise<void> {
    let productCart = new ProductCart()
      .setCode(this.getOrderEntryCode(this.productsCart.content))
      .setProductCode(product.code)
      .setDescription(product.description)
      .setTax(product.tax)
      .setTotal(product.total)
      .setPrice(product.price)
      .setAmount(1)
      .setTaxCode(product.taxCode)
      .setSerial('')
      .setRequiredSerial(product.requiredSerial);

    let plateProductCart = await this.addPlateProducts(product, productCart);
    this.productsCart.content = plateProductCart;

    this.productsCart = new Paging<ProductCart[]>(this.productsCart.content);
    if (product.requiredSerial) {
      await this.addSerialNumber(productCart);
    }
  }

  private async addPlateProducts(
    product: ProductTableModel,
    productCart: ProductCart
  ): Promise<Array<ProductCart>> {
    let result: Array<ProductCart> = [];
    result.push(...this.productsCart.content);
    result.push(productCart);
    if (product.features.MATRICULA1) {
      let plateService1 = this.params['plate-service-product-1'];
      let plateService2 = this.params['plate-service-product-2'];

      let plateServiceProduct1 = (
        await this.productsService.readProduct(plateService1).toPromise()
      ).response;
      let plateServiceProduct2 = (
        await this.productsService.readProduct(plateService2).toPromise()
      ).response;

      let taxplateProduct1 =
        this.taxes.find((f) => f.code == plateServiceProduct1.taxCode) ||
        new Tax();
      let taxplateProduct2 =
        this.taxes.find((f) => f.code == plateServiceProduct2.taxCode) ||
        new Tax();

      const priceForPlate1 = (await this.orderService.getPriceForVehicleRegister(product.code, this.configService.readSelectedBranchAndSalesOrg().branch.code).toPromise()).response;

      result.push(
        new ProductCart()
          .setCode(this.getOrderEntryCode(result))
          .setProductCode(plateServiceProduct1.code)
          .setDescription(plateServiceProduct1.description)
          .setTax(taxplateProduct1.value)
          .setTotal(priceForPlate1)
          .setPrice(priceForPlate1)
          .setAmount(1)
          .setTaxCode(plateServiceProduct1.taxCode)
          .setSerial('')
          .setRequiredSerial(plateServiceProduct1.requiresSerial)
          .setReferenceCode(productCart.code)
          .setIsService(plateServiceProduct1.isService)
      );

      result.push(
        new ProductCart()
          .setCode(this.getOrderEntryCode(result))
          .setProductCode(plateServiceProduct2.code)
          .setDescription(plateServiceProduct2.description)
          .setTax(taxplateProduct2.value)
          .setTotal(plateServiceProduct2.price.amount)
          .setPrice(plateServiceProduct2.price.amount)
          .setAmount(1)
          .setTaxCode(plateServiceProduct2.taxCode)
          .setSerial('')
          .setRequiredSerial(plateServiceProduct2.requiresSerial)
          .setReferenceCode(productCart.code)
          .setIsService(plateServiceProduct2.isService)
      );
    }
    return result;
  }

  async removeProductCart(product: ProductCart) {
    if (product.isService) {
      let plateService1 = this.params['plate-service-product-1'];
      let plateService2 = this.params['plate-service-product-2'];

      if (product.productCode == plateService1 || product.productCode == plateService2) {
        await Swal.fire(`¡Espera un Momento!`, `Para continuar, necesitamos que el jefe de tienda ingrese sus credenciales.`, `info`);
        const supervisorApproveInfo = await this.supervisorService.showAsync(['ApproveRemovePlateProducts']);
        if (supervisorApproveInfo.isValid) {
          let result: Array<ProductCart> = [];
          result = this.productsCart.content.filter((f) => f.code != product.code);
          this.productsCart = new Paging<ProductCart[]>(result);
        }
        return;
      }
    }


    let result = this.productsCart.content.filter(
      (f) => f.code != product.code
    );

    result = result.filter((f) => f.referenceCode != product.code);
    if (Array.from(this.orderSimulate.appliedPromotions).length > 0) {
      await this.confirmService.show(
        'Advertencia',
        'Esta orden cuenta con promociones, al eliminar el producto se eliminarán las promociones, ¿Está seguro de continuar?',
        ModalDialogType.WARNING,
        async () => {
          await this.deletePromotions(
            Array.from(this.orderSimulate.appliedPromotions)
          );
          this.orderDiscount = new Discount();
          this.removeDiscount.emit();
          result
            .filter((f) => !f.discount.promotionCode)
            .forEach((f) => {
              f.discount = new Discount();
            });
          this.productsCart = new Paging<ProductCart[]>(result);
        }
      );
    } else {
      this.orderDiscount = new Discount();
      this.removeDiscount.emit();
      result
        .filter((f) => !f.discount.promotionCode)
        .forEach((f) => {
          f.discount = new Discount();
        });
      this.productsCart = new Paging<ProductCart[]>(result);
    }
  }

  getOrderEntryCode(productsCart: ProductCart[]): number {
    if (productsCart.length == 0) {
      return 1;
    }

    let index = productsCart.length - 1;

    return productsCart[index].code + 1;
  }

  getTableForm(tableForm: FormGroup) {
    this.getTableFormExternal.emit(tableForm);
  }

  async getPromotionData(filters: Filter<PromotionFilter>): Promise<void> {
    filters.value.promotionCodes = Array.from(
      this.orderSimulate.potentialPromotions
    );
    this.promotions = (
      await this.promotionService.readPromotionsList(filters).toPromise()
    ).response;
    console.log(this.promotions);
  }

  getPromotionTableFilters() {
    return [
      new FilterTable('searchText', 'Búsqueda General'),
      new FilterTable('promotionCode', 'Código de Promoción'),
    ];
  }

  async promotionRowClick(promotion: Promotion): Promise<void> {
    let result = (
      await this.promotionService
        .applyPromotionSimulate(this.orderSimulate, [promotion.code])
        .toPromise()
    ).response;

    this.orderSimulate = result.order;
    this.productsCart.content.forEach((f) => {
      let entry = this.orderSimulate.orderEntryList.find(
        (x) => x.code == f.code.toString()
      );
      f.discount = entry?.discounts.find((f) => f.discountType) || f.discount;
      f.discountTotal = entry?.totals.discountTotal || 0;
    });
    this.activePromotions.content = result.promotions;

    this.orderRequest.appliedPromotions.push(promotion.code);

    this.getOrder.emit({
      order: this.orderSimulate,
      productsCart: this.productsCart,
      orderRequest: this.orderRequest,
    });
  }

  async removePromotionRowClick(promotion: Promotion): Promise<void> {
    await this.confirmService.show(
      '¡Advertencia!',
      `¿Esta seguro de eliminar la promocion ${promotion.code} - ${promotion.description} de la orden?`,
      ModalDialogType.INFO,
      async () => {
        await this.deletePromotions([promotion.code]);
      }
    );
  }

  private async deletePromotions(promotions: Array<string>) {
    let result = (
      await this.promotionService
        .deletePromotionsSimulate(this.orderSimulate, promotions)
        .toPromise()
    ).response;

    this.orderSimulate = result.order;
    this.activePromotions.content = result.promotions;
    this.productsCart.content.forEach((f) => {
      f.discount = new Discount();
      f.discountTotal = 0;
    });

    this.orderRequest.appliedPromotions =
      this.orderRequest.appliedPromotions.filter((p) => p !== promotions[0]);

    this.getOrder.emit({
      order: this.orderSimulate,
      orderRequest: this.orderRequest,
      productsCart: this.productsCart,
    });
  }

  async showPromotionList(): Promise<void> {
    await this.promotionListModalService.show();
  }
}
