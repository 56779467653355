import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './product-list.component';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';



@NgModule({
  declarations: [ProductListComponent],
  imports: [
    CommonModule,
    PosTableModule
  ]
})
export class ProductListModule { }
