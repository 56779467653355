<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-sm-12 container-actions-buttons">
            <button *appHasAccess="'addPAC'" type="button" class="btn btn-light" (click)="handlePacClick()">Ingresar
                PAC</button>
            <div *appHasAccess="'raiseLimit'">
                <button *ngIf="this.currentCustomerCreditConditions.creditLineActive" (click)="handleRaiseLimitClick()"
                    type="button" class="btn btn-light w-100">Aumentar Limites</button>
            </div>
            <button *appHasAccess="'agreement'" type="button" class="btn btn-light"
                (click)="handleCvnClick()">Convenio</button>
            <button type="button" class="btn btn-light"
                (click)="handleInvoiceClick()">Facturar</button>
            <button type="button" class="btn btn-light" appPrivilege="showViewAdvance"
                (appPrivilegeClick)="toAdvance()">Anticipos</button>
        </div>
    </div>
</div>