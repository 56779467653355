import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BiometryFingerPrintActiveRequest } from '../models/request/biometry-finger-print-active-request';
import { BiometryValidationRequest } from '../models/request/biometry-validation-request';
import { BiometryFingerPrintCaptureRequest } from '../models/request/BiometryFingerPrintCaptureRequest';
import { ResponseModel } from '../models/response-model';
import { ActiveFingerPrintResponse } from '../models/response/ActiveFingerPrintResponse';
import { BiometryValidationResponse } from '../models/response/biometry-validation-response';
import { CaptureFingerPrintResponse } from '../models/response/CaptureFingerPrintResponse';

@Injectable({
  providedIn: 'root'
})
export class BiometryService {
  private url = `${environment.url}/biometry`;
  private codeSecurity = "Lu9S%u637t&5";

  constructor(private http: HttpClient) { }

  validateBiometry(request: BiometryValidationRequest): Observable<ResponseModel<BiometryValidationResponse>> {
    let params = new HttpParams();

    return this.http.post<ResponseModel<BiometryValidationResponse>>(this.url, request);
  }

  activeFingerprint(): Observable<ActiveFingerPrintResponse> {
    let url = `${environment.fingerPrintDeviceUrl}/FingerPrint/activeFingerPrint`;

    return this.http.post<ActiveFingerPrintResponse>(url, new BiometryFingerPrintActiveRequest(this.codeSecurity));
  }

  captureFingerprint(device: string): Observable<CaptureFingerPrintResponse> {
    let url = `${environment.fingerPrintDeviceUrl}/FingerPrint/captureFingerPrint`;

    return this.http.post<CaptureFingerPrintResponse>(url, new BiometryFingerPrintCaptureRequest(this.codeSecurity, device));
  }
}
