<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Promociones</h4>
    <button type="button" class="close" (click)="cancel()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <promotion-details [promotion]="promotion" [accumulableWith]="accumulableWith" [buyProducts]="buyProducts"
      [creditProcedures]="creditProcedures" [getProducts]="getProducts" [salesOrgs]="salesOrgs"></promotion-details>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
  </div>
</div><!-- /.modal-content -->
