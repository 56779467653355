import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/response-model';
import { Paging } from '../models/page';
import { Bank } from '../models/bank';

@Injectable( {
  providedIn: 'root'
} )
export class BankService {
  private url = `${environment.url}/banks`;

  constructor( private http: HttpClient ) { }

  getBankList(): Observable<ResponseModel<Paging<Array<Bank>>>> {

    return this.http.get<ResponseModel<Paging<Array<Bank>>>>( this.url );
  }
}
