import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OrderTypeStepComponent } from './order-type-step.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';


@NgModule({
  declarations: [OrderTypeStepComponent],
  imports: [
    CommonModule,
    FormsModule,
    ButtonsModule
  ],
  exports: [OrderTypeStepComponent]
})
export class OrderTypeStepModule { }
