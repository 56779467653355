export class Tax {
    code: string;
    description: string;
    value: number;

    constructor() {
        this.code = "";
        this.description = "";
        this.value = 0.0;
    }
}