import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TaxExemption } from 'src/app/models/tax-exemption';
import { PosExemptionModalComponent } from 'src/app/shared/pos-exemption-modal/pos-exemption-modal.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosExemptionModalService {
  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  show(taxExemption: TaxExemption, confirmAction: (value: TaxExemption) => any): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.modalRef = this.modalService.show(PosExemptionModalComponent,
        {
          ignoreBackdropClick: true
        })

      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY}`)
      this.modalRef.content.taxExemption = taxExemption;
      this.modalRef.content.reasonId = taxExemption.reason.code;
      this.modalRef.content.confirmAction = confirmAction;
      resolve(true);
    })
  }
}
