import { Personality, PromotionPaymentType, PromotionType } from "../@core/constants";
import { Buy } from "./buy";
import { Get } from "./get";

export class Promotion {
    code: string;
    branchCode: string;
    description: string;
    type: PromotionType;
    paymentType: PromotionPaymentType;
    creditPromoType: string;
    startDate: string;
    starDateRaw: Date;
    endDate: string;
    endDateRaw: Date;
    startTime: string;
    endTime: string;
    personality: Personality;
    maxArrears: string;
    minArrears: string;
    accumulableWith: Array<string>;
    limit: number;;
    creditProcedures: Array<string>;
    maxAge: number;;
    minAge: number;;
    currency: string;
    salesOrgs: Array<string>;
    customerCodes: Array<string>;
    buy: Buy;
    get: Get;

    constructor() {
        this.code = "";
        this.branchCode = "";
        this.description = "";
        this.type = PromotionType.GENERAL;
        this.paymentType = PromotionPaymentType.EITHER;
        this.creditPromoType = "";
        this.startDate = "";
        this.endDate = "";
        this.startTime = "";
        this.endTime = "";
        this.personality = Personality.PERSON;
        this.maxArrears = "";
        this.minArrears = "";
        this.accumulableWith = [];
        this.limit = 0;
        this.creditProcedures = [];
        this.maxAge = 0;
        this.minAge = 0;
        this.currency = "";
        this.salesOrgs = [];
        this.customerCodes = [];
        this.buy = new Buy();
        this.get = new Get();
        this.starDateRaw = new Date();
        this.endDateRaw = new Date();
    }
}