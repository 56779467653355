<div class="animated fadeIn rounded">
  <div class="card">
    <div class="card-header">
      <i class="fa fa-archive"></i> Productos
      <div class="card-header-actions btn-group">
        <!-- <button class="btn btn-sm btn-success" type="button"><i class="fa fa-floppy-o"></i>&nbsp;Guardar Orden</button> -->
        <button class="btn btn-sm btn-primary" type="button" (click)="showPromotions()"><i
            class="fa fa-percent"></i>&nbsp;Promociones</button>
      </div>
    </div>
    <div class="card-body">
      <pos-table tableFormId="productTableId" [headers]="getProductTableColumns()" [filters]="getProductFilterColumns()"
        [data]="products" [itemsPerPage]="10" (dataChange)="getProductData($event)">
      </pos-table>
    </div>
  </div>
</div>
