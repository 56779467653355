import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnTable } from 'src/app/models/column-table';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceLine } from 'src/app/models/invoice-line';
import { Paging } from 'src/app/models/page';
import { Payment } from 'src/app/models/payment';
import { ProductCart } from 'src/app/models/product-cart';

@Component({
  selector: 'credit-invoice-details-step',
  templateUrl: './credit-invoice-details-step.component.html',
  styleUrls: ['./credit-invoice-details-step.component.sass']
})
export class CreditInvoiceDetailsStepComponent implements OnInit {

  @Input()
  invoice: Invoice = new Invoice();
  productsCart: Paging<Array<ProductCart>> = new Paging([]);

  @Output() doPayment = new EventEmitter<{ payments: Array<Payment>, paymentsAmount: number, invoiceDetails: Array<InvoiceLine>, id: string, loanCode: string }>();

  @Input()
  id: string = "";

  constructor() { }

  ngOnInit(): void {
    this.loadProductsCart();
  }

  getProductCartHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable("amount", "#", { type: "number", iconClass: "fa fa-shopping-cart", isIcon: true }),
      new ColumnTable("productCode", "Código", { isSmall: true }),
      new ColumnTable("description", "Descripción", { isSmall: true }),
      new ColumnTable("serial", "Serie", { type: "badge" }),
      new ColumnTable("price", "Sub-Total", { type: "currency" }),
      new ColumnTable("discountTotal", "Descuento", { type: "currency" }),
      new ColumnTable("taxTotal", "Impuesto", { type: "currency" }),
      new ColumnTable("total", "Total", { type: "currency" })
    ]
  }

  loadProductsCart() {
    this.productsCart = new Paging(this.invoice.deliveryInvoiceLines.map(o => {
      let result = new ProductCart();
      result.productCode = o.productCode;
      result.description = o.productName;
      result.amount = o.quantity
      result.serial = o.serialNumber;
      result.price = o.totals.grossTotal;
      result.discountTotal = o.totals.discountTotal;
      result.taxTotal = o.totals.taxTotal;
      result.total = o.totals.netTotal;

      return result;
    }))
  }

  getPayment(payments: { payments: Array<Payment>, paymentsAmount: number, id: string }) {
    this.doPayment.emit({ payments: payments.payments, id: payments.id, paymentsAmount: payments.paymentsAmount, invoiceDetails: this.invoice.deliveryInvoiceLines, loanCode: this.invoice.loanCode });
  }
}
