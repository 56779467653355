<div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="close" (click)="cancel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{message}}</p>

      <textarea class="form-control" [ngClass]="valdiateObservations() ? 'is-invalid' : 'is-valid'" 
       [(ngModel)]="observations" rows="10" required></textarea>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
      <button type="button" class="btn btn-primary" [disabled]="valdiateObservations()" (click)="confirm()">Confirmar</button>
    </div>
  </div>