import { JobInfo } from '../customer-job-info';

export class CustomerUpdateJobInfoRequest {
  jobInfoList: Array<JobInfo>;
  personality: string;
  countryId: string;
  houseType: string;
  ownCar: boolean;

  constructor() {
    this.jobInfoList = new Array();
    this.personality = '';
    this.countryId = '';
    this.houseType = '';
    this.ownCar = false;
  }
}
