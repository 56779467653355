export class ExchangeRate {
    fromCurrency: string;

    toCurrency: string;

    //yyyy-MM-dd HH:mm:ss
    validFrom: Date;

    rate: number;

    constructor() {
        this.fromCurrency = "";
        this.toCurrency = "";
        this.validFrom = new Date();
        this.rate = 0.0;
    }

}