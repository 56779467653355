import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../models/filter';
import { PromotionFilter } from '../models/filters/promotion-filter';
import { Order } from '../models/order';
import { Paging } from '../models/page';
import { Promotion } from '../models/promotion';
import { ResponseModel } from '../models/response-model';
import { OrderApplyPromotions } from '../models/response/order-apply-promotions';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {
  private url = `${environment.url}/promotions`;
  constructor(private http: HttpClient, private configService: ConfigurationService, private datePipe: DatePipe) { }

  readPromotionsList(filter: Filter<PromotionFilter>) {
    let params = new HttpParams();
    params = params.set("page", filter.page.toString())
      .set("pageSize", filter.pageSize.toString())
      .set("branchCode", this.configService.readSelectedBranchAndSalesOrg().branch.code);
    params = filter.value.promotionCode ? params.set("promotionCode", filter.value.promotionCode) : params;
    params = filter.value.searchText ? params.set("searchText", filter.value.searchText) : params;

    return this.http.post<ResponseModel<Paging<Array<Promotion>>>>(`${environment.url}/promotionsListPost`, filter.value.promotionCodes, { params });
  }

  readPromotions(filter: Filter<PromotionFilter>) {
    let params = new HttpParams();
    params = params.set("page", filter.page.toString())
      .set("pageSize", filter.pageSize.toString())
      .set("branchCode", this.configService.readSelectedBranchAndSalesOrg().branch.code);


    if (filter.value.current) {
      let endDate = new Date();
      endDate.setHours(23, 59, 59);
      //yyyy-MM-dd HH:mm:ssZ
      params = params.set("endDate", this.datePipe.transform(endDate, "yyyy-MM-dd HH:mm:ssZ") || "");
    }
    params = filter.value.promotionCode ? params.set("code", filter.value.promotionCode) : params;
    params = filter.value.searchText ? params.set("text", filter.value.searchText) : params;

    return this.http.get<ResponseModel<Paging<Array<Promotion>>>>(`${environment.url}/promotions`, { params });
  }

  readPromotion(promotionCode: string) {
    let params = new HttpParams();
    params = params.set("promotionCode", promotionCode).set("branchCode", this.configService.readSelectedBranchAndSalesOrg().branch.code);
    return this.http.get<ResponseModel<Promotion>>(`${environment.url}/promotion`, { params });
  }
  applyPromotionSimulate(order: Order, promotionCodes: Array<string>): Observable<ResponseModel<OrderApplyPromotions>> {
    let body = { order, promotionCodes };
    return this.http.post<ResponseModel<OrderApplyPromotions>>(`${environment.url}/orders/promotions`, body);
  }

  deletePromotionsSimulate(order: Order, promotionCodes: Array<string>): Observable<ResponseModel<OrderApplyPromotions>> {
    let body = { order, promotionCodes };

    return this.http.post<ResponseModel<OrderApplyPromotions>>(`${environment.url}/orders/deletePromotions`, body);

  }
}
