import { Component, Input, OnInit } from '@angular/core';
import { Totals } from 'src/app/models/totals';

@Component({
  selector: 'pos-totals',
  templateUrl: './pos-totals.component.html',
  styleUrls: ['./pos-totals.component.sass']
})
export class PosTotalsComponent implements OnInit {

  @Input() totals: Totals = new Totals();
  constructor() { }

  ngOnInit(): void {
  }

}
