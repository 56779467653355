import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../models/filter';
import { ProductFilter } from '../models/filters/product-filter';
import { Paging } from '../models/page';
import { Product } from '../models/product';
import { ResponseModel } from '../models/response-model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private url = `${environment.url}/products`;
  constructor(private http: HttpClient, private configService: ConfigurationService) { }

  readProducts(filter: Filter<ProductFilter>): Observable<ResponseModel<Paging<Array<Product>>>> {
    let params = new HttpParams();
    params = params
      .set("page", filter.page.toString() || "0")
      .set("pageSize", filter.pageSize.toString() || "20")
      .set("branchCode", this.configService.readSelectedBranchAndSalesOrg().branch.code)
      .set("salesOrg", this.configService.readSelectedBranchAndSalesOrg().salesOrg.code);

    params = filter.value.text ? params.set("text", filter.value.text) : params;
    params = filter.value.ean ? params.set("ean", filter.value.ean) : params;
    params = filter.value.hasExistence ? params.set("hasExistence", "true") : params;
    return this.http.get<ResponseModel<Paging<Array<Product>>>>(this.url, { params });
  }

  readProduct(productCode: string, branchCode?: string) {
    let params = new HttpParams();
    params = params
      .set("productCode", productCode)
      .set("branchCode", branchCode || this.configService.readSelectedBranchAndSalesOrg().branch.code)

    return this.http.get<ResponseModel<Product>>(`${environment.url}/product`, { params });
  }
}
