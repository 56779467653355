import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosCustomerUpdateModalComponent } from './pos-customer-update-modal.component';
import { PosCustomerUpdateModule } from '../pos-customer-update/pos-customer-update.module';
import { ModalModule } from 'ngx-bootstrap/modal';



@NgModule({
  declarations: [PosCustomerUpdateModalComponent],
  imports: [
    CommonModule,
    PosCustomerUpdateModule,
    ModalModule
  ],
  exports:[PosCustomerUpdateModalComponent]
})
export class PosCustomerUpdateModalModule { }
