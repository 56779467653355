import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoyaltyPointsCustomer } from '../models/loyalty-points-customer';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable( {
  providedIn: 'root'
} )
export class LoyaltyPointsService {
  private url = `${environment.url}/loyaltyPoints`;

  constructor( private http: HttpClient ) { }

  getLoyaltyPointsByCustomerCode( customerCode: string ): Observable<ResponseModel<Paging<Array<LoyaltyPointsCustomer>>>> {
    let params = new HttpParams();
    params = params.appendAll( { customerCode } );
    return this.http.get<ResponseModel<Paging<Array<LoyaltyPointsCustomer>>>>( this.url, { params } );
  }
}
