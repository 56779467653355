import { CustomerUpdateContact } from "./CustomerUpdateContact";
import { CustomerUpdateData } from "./CustomerupdateData";

export class CustomerUpdate {
    source: string;
    newPartnerData: CustomerUpdateData;
    contacts: CustomerUpdateContact[];
    observations: String;
    constructor() {
        this.source = "";
        this.newPartnerData = new CustomerUpdateData();
        this.contacts = new Array<CustomerUpdateContact>();
        this.observations = "";
    }
}



