import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paging } from '../models/page';
import { StockRequestModel } from '../models/requests/stock-request-model';
import { ResponseModel } from '../models/response-model';
import { StockResponseModel } from '../models/response/stockResponseModel';
import { Stock } from '../models/stock';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  private url = `${environment.url}/stock`;
  constructor(private http: HttpClient, private configService: ConfigurationService) { }

  readStocks(productCode: string, used?: boolean): Observable<ResponseModel<Paging<Array<Stock>>>> {

    let params = new HttpParams();
    params = params.set("productCode", productCode)
      .set("branchCode", this.configService.readSelectedBranchAndSalesOrg().branch.code)
      .set("page", "0")

      .set("pageSize", "5000");

    params = used != undefined ? (used ? params.set("used", "true") : params.set("used", "false")) : params;

    return this.http.get<ResponseModel<Paging<Array<Stock>>>>(this.url, { params })
  }

  readSelectedStocks(stockRequestModel: StockRequestModel): Observable<ResponseModel<Array<StockResponseModel>>> {
    return this.http.post<ResponseModel<Array<StockResponseModel>>>(`${this.url}/validate`, stockRequestModel);
  }
}
