import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paging } from '../models/page';
import { Register } from '../models/register';
import { ResponseModel } from '../models/response-model';
import { AutomaticRegisterClosing } from '../models/response/automaticRegisterClosingResponseModel';
import { ConfigurationService } from './configuration.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private url = `${environment.url}/registers`;
  private urlAutomaticRegisterClosing = `${environment.url}/automaticRegisterClosing`;
  constructor(
    private http: HttpClient
  ) { }

  readRegisters(): Observable<ResponseModel<Paging<Array<Register>>>> {
    let params = new HttpParams();
    params = params
      .set("page", "0")
      .set("pageSize", "100000");

    return this.http.get<ResponseModel<Paging<Array<Register>>>>(this.url, { params });
  }

  readRegister(id: string): Observable<ResponseModel<Register>> {
    const path = `${this.url}/${id}`
    return this.http.get<ResponseModel<Register>>(path);
  }

  readRegisterClosingFails(page: number, pageSize: number, branchCode: string, status: string, regionCodes: string): Observable<ResponseModel<Paging<Array<AutomaticRegisterClosing>>>> {
    let params = new HttpParams();
    params = params
      .set("page", `${page}`)
      .set("pageSize", `${pageSize}`);

    if (branchCode) {
      params = params.set("branchCode", branchCode);
    }

    if (status) {
      params = params.set("status", status);
    }

    if (regionCodes) {
      params = params.set("regionCode", regionCodes);
    }

    return this.http.get<ResponseModel<Paging<Array<AutomaticRegisterClosing>>>>(this.urlAutomaticRegisterClosing, { params });
  }
}
