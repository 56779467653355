import { CashCreditInformation } from '../credit-cash-information';
import { CreditInformation } from '../credit-information';

export class CreditStatusFranchisedResponseModel {
  codError: string;
  messageError: string;
  customerCode: string;
  estadoCliente: string;
  mensajeTipoAccion: string;
  creditLineActive:boolean;

  constructor() {
    this.codError = ``;
    this.messageError = ``;
    this.customerCode = ``;
    this.estadoCliente = ``;
    this.mensajeTipoAccion = ``;
    this.creditLineActive = false;
  }
}
