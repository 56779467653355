import { Discount } from "./discount";

export class ProductCart {
    code: number;
    productCode: string;
    description: string;
    tax: number;
    taxCode: string;
    price: number;
    total: number;
    serial: string;
    amount: number;
    returnedAmount: number;
    requiredSerial: boolean;
    discount: Discount;
    discountTotal: number;
    taxTotal: number;
    referenceCode: number;
    applyDelivery?: boolean;
    isService:boolean;
    // downPayment: number;
    // term: number;
    // rate: number;

    constructor() {
        this.code = 0;
        this.productCode = "";
        this.description = "";
        this.tax = 0.0;
        this.price = 0.0;
        this.total = 0.0;
        this.serial = "";
        this.amount = 0;
        this.returnedAmount = 0;
        this.taxCode = "";
        this.requiredSerial = false;
        this.discount = new Discount();
        this.discountTotal = 0;
        this.taxTotal = 0;
        this.referenceCode = 0;
        this.applyDelivery = false;
        this.isService = false;
    }

    setCode(code: number): ProductCart {
        this.code = code;
        return this;
    }

    setProductCode(productCode: string): ProductCart {
        this.productCode = productCode;
        return this;
    }

    setDescription(description: string): ProductCart {
        this.description = description;
        return this;
    }

    setTax(tax: number): ProductCart {
        this.tax = tax;
        return this;
    }

    setPrice(precio: number): ProductCart {
        this.price = precio;
        return this;
    }

    setTotal(total: number): ProductCart {
        this.total = total;
        return this;
    }

    setSerial(serial: string): ProductCart {
        this.serial = serial;
        return this;
    }

    setApplyDelivery(applyDelivery: boolean) {
        this.applyDelivery = applyDelivery;
        return this;
    }

    setAmount(amount: number): ProductCart {
        this.amount = amount;
        return this;
    }

    setTaxCode(taxCode: string): ProductCart {
        this.taxCode = taxCode;
        return this;
    }

    setRequiredSerial(requiredSerial: boolean) {
        this.requiredSerial = requiredSerial;
        return this;
    }

    setDiscount(discount: Discount) {
        this.discount = discount;
        return this;
    }

    setDiscountTotal(discountValue: number) {
        this.discountTotal = discountValue;
        return this;
    }

    setTaxTotal(taxTotal: number) {
        this.taxTotal = taxTotal;
        return this;
    }

    setReferenceCode(referencCode: number): ProductCart {
        this.referenceCode = referencCode;
        return this;
    }

    setIsService(isService:boolean){
      this.isService = isService;
      return this;
    }
}
