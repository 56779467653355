export class Stock {
    productCode: string;
    branchCode: string;
    quantity: number;
    quantityUnit: string;
    serialNumber: string;
    equipmentCode: string;
    equipmentType: string;
    used: boolean;
    availableForSale: boolean;
    constructor() {
        this.productCode = "";
        this.branchCode = "";
        this.quantity = 0;
        this.quantityUnit = "";
        this.serialNumber = "";
        this.equipmentCode = "";
        this.equipmentType = "";
        this.used = false;
        this.availableForSale = false;
    }
}