import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceStepperComponent } from './invoice-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { PosStepperModule } from 'src/app/shared/pos-stepper/pos-stepper.module';
import { InvoiceDetailsStepModule } from './invoice-details-step/invoice-details-step.module';
import { ReceiptDetailsStepComponent } from './receipt-details-step/receipt-details-step.component';
import { ReceiptDetailsStepModule } from './receipt-details-step/receipt-details-step.module';
import { PayInvoiceStepComponent } from './pay-invoice-step/pay-invoice-step.component';
import { PayInvoiceStepModule } from './pay-invoice-step/pay-invoice-step.module';

@NgModule({
  declarations: [InvoiceStepperComponent],
  imports: [
    CommonModule,
    CdkStepperModule,
    PosStepperModule,
    InvoiceDetailsStepModule,
    ReceiptDetailsStepModule,
    PayInvoiceStepModule
  ],
  exports: [InvoiceStepperComponent]
})
export class InvoiceStepperModule { }
