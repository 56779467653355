import { VerifyCustomerValidateTokenRequest } from './../../../../models/request/verify-customer-validate-token-request';
import { PosVerifyCustomerService } from 'src/app/services/pos-verify-customer.service';
import { VerifyCustomerSendTokenRequest } from './../../../../models/request/verify-customer-send-token-request';
import { VerifyCustomerCheckResponseData } from 'src/app/models/response/VerifyCustomerCheckResponse';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { VerifyCustomerValidateTokenType } from 'src/app/@core/constants';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerVerificationService } from 'src/app/services/customer-verification.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customer-verify-phone-and-email',
  templateUrl: './customer-verify-phone-and-email.component.html',
  styleUrls: ['./customer-verify-phone-and-email.component.sass'],
})
export class CustomerVerifyPhoneAndEmailComponent implements OnInit, OnDestroy {
  @Input()
  verifyTokenType: VerifyCustomerValidateTokenType =
    VerifyCustomerValidateTokenType.EMAIL;
  @Input() customerDataToVerify: VerifyCustomerCheckResponseData | undefined;
  @Input() customerCode = '';
  @Output() verificationComplete: EventEmitter<boolean> = new EventEmitter(
    false
  );

  tokenForm: FormGroup;
  numDigits: number = 6;

  hasRequestToken: boolean = false;
  hasResendToken: boolean = false;
  hasVerifyToken: boolean = false;

  // variables para timer
  private unsubscribe$ = new Subject<void>();
  timeRemaining: number = 90; // Tiempo en segundos (1 minuto 30 segundos)

  constructor(
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private customerVerificationService: CustomerVerificationService,
    private toastr: ToastrService
  ) {
    this.tokenForm = this.formBuilder.group({});
    for (let i = 0; i < this.numDigits; i++) {
      this.tokenForm.addControl(
        i.toString(),
        this.formBuilder.control(null, Validators.required)
      );
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    // Limpiar el temporizador al destruir el componente
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async verifyToken() {
    // Lógica para verificar el token
    const verifyCustomerValidateTokenRequest: VerifyCustomerValidateTokenRequest =
      {
        token: this.getFormValueToken(),
        type: this.verifyTokenType,
      };
    const validateTokenResponse = await this.customerVerificationService
      .validateToken(this.customerCode, verifyCustomerValidateTokenRequest)
      .toPromise();

    if (validateTokenResponse.response.success) {
      this.toastr.success(
        'Se verifico la informacion exitosamente.',
        'Verificacion de datos'
      );
      this.hasVerifyToken = true;
      this.verificationComplete.emit(validateTokenResponse.response.success);
      this.verificationComplete.complete();
    }

  }

  async resendToken() {
    // Lógica para reenviar el token
    this.hasResendToken = true;

    document.getElementById('token0')?.focus(); // Enfocar el primer input

    const responseResendToken = await this.customerVerificationService
      .reSendTokenToVerifyCustomer(this.customerCode, this.verifyTokenType)
      .toPromise();

    if (responseResendToken.response.success) {
      this.toastr.success(
        responseResendToken.response.message,
        'Verificacion de datos'
      );
      this.timeRemaining = 90;
      this.startTimer();
    } else {
      this.toastr.warning(
        responseResendToken.response.message,
        'Verificacion de datos'
      );
    }
  }

  async sendToken() {
    let verifyCustomerSendTokenRequest: VerifyCustomerSendTokenRequest = {
      branchCode:
        this.configurationService.readSelectedBranchAndSalesOrg().branch.code,
      salesOrg:
        this.configurationService.readSelectedBranchAndSalesOrg().salesOrg.code,
    };

    if (this.verifyTokenType == VerifyCustomerValidateTokenType.EMAIL) {
      verifyCustomerSendTokenRequest = {
        ...verifyCustomerSendTokenRequest,
        email: this.customerDataToVerify?.email?.email,
      };
    }

    if (this.verifyTokenType == VerifyCustomerValidateTokenType.PHONE) {
      verifyCustomerSendTokenRequest = {
        ...verifyCustomerSendTokenRequest,
        phone: this.customerDataToVerify?.phone?.phone_number,
      };
    }

    const sendTokenResponse = await this.customerVerificationService
      .sendTokenToVerifyCustomer(
        this.customerCode,
        verifyCustomerSendTokenRequest
      )
      .toPromise();

    if (this.verifyTokenType === VerifyCustomerValidateTokenType.EMAIL) {
      if (!sendTokenResponse.response.data.email.status) {
        this.toastr.warning(
          sendTokenResponse.response.data.email.message,
          'Verificacion de datos'
        );
      } else {
        this.toastr.success(
          sendTokenResponse.response.data.email.message,
          'Verificacion de datos'
        );
        this.hasRequestToken = true;
        this.startTimer();
      }
    }

    if (this.verifyTokenType === VerifyCustomerValidateTokenType.PHONE) {
      if (!sendTokenResponse.response.data.phone.status) {
        this.toastr.warning(
          sendTokenResponse.response.data.phone.message,
          'Verificacion de datos'
        );
      } else {
        this.toastr.success(
          sendTokenResponse.response.data.phone.message,
          'Verificacion de datos'
        );
        this.hasRequestToken = true;
        this.startTimer();
      }
    }
  }

  getControls(): AbstractControl[] {
    return Object.values(this.tokenForm.controls);
  }

  getFormValueToken(): string {
    const tokenValue = Object.values(this.tokenForm.value).join('');
    return tokenValue;
  }

  onInput(event: any, index: number) {
    // Obtener el valor actual del input
    let value = event.target.value;

    // Si el valor no es un dígito, borrarlo
    if (!/^[0-9]$/.test(value)) {
      value = '';
    }
    // Actualizar el valor del input
    this.tokenForm.get(index.toString())?.setValue(value);
    // Si se ingresó un dígito y no estamos en el último input, pasar al siguiente input
    if (value && index < this.numDigits - 1) {
      const nextInput = event.target.nextElementSibling;
      if (nextInput && nextInput instanceof HTMLInputElement) {
        nextInput.focus();
      }
    }
  }

  onKeyPress(event: any, index: number) {
    let value = event.target.value;
    if (event.key === 'Backspace' && !value && index > 0) {
      const prevInput = event.target.previousElementSibling;
      if (prevInput && prevInput instanceof HTMLInputElement) {
        prevInput.focus();
      }
    }
  }

  startTimer() {
    interval(1000) // Emitir un valor cada segundo
      .pipe(
        takeUntil(this.unsubscribe$) // Detener el intervalo cuando se destruya el componente
      )
      .subscribe(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          // Si el tiempo restante es 0, detener el temporizador
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
          // Activar el botón de reenviar
          this.hasResendToken = false;
        }
      });
  }

  formatTimeRemaining(): string {
    const minutes: number = Math.floor(this.timeRemaining / 60);
    const remainingSeconds: number = this.timeRemaining % 60;
    const minutesText: string = minutes === 1 ? 'minuto' : 'minutos';
    const secondsText: string = remainingSeconds === 1 ? 'segundo' : 'segundos';
    return `${minutes} ${minutesText} ${remainingSeconds} ${secondsText}`;
  }
}
