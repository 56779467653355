<div class="modal-header">
    <h5 class="modal-title"><i class="fa fa-list-alt"></i> Requerimientos para {{this.creditPlan?.name?.toLowerCase()}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <accordion [isAnimated]="true">
        <accordion-group [isOpen]="i === 0" *ngFor="let document of this.creditPlan?.requiredDocuments; let i = index">
            <span accordion-heading>
                <i class="fa fa-id-card"></i> {{document.onbaseDoc.name}}
            </span>
            <ul>
                <li *ngFor="let od of document.optionalDocuments">
                    {{od}}
                </li>
            </ul>
        </accordion-group>
    </accordion>
</div>