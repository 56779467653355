import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomerTableModel } from 'src/app/models/table-models/customer-table';
import { PosCreateCustomerModalComponent } from 'src/app/shared/pos-create-customer-modal/pos-create-customer-modal.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosCreateCustomerModalService {

  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) { }

  show(confirmAction: (customer: CustomerTableModel) => any) {
    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(PosCreateCustomerModalComponent);
      this.modalRef.setClass(`modal-dialog modal-lg ${ModalDialogType.PRIMARY}`);
      this.modalRef.content.confirmAction = confirmAction;
      resolve(true);
    });
  }
}
