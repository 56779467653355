import { LinkCategory } from "../@core/constants";
import { Condition } from "./condition";

export class Buy {
    linkCategory: LinkCategory;
    totalMinimum: number;
    totalMaximum: number;
    rate: number;
    maxTerm: number;
    minTerm: number;
    downPayment: number;
    gracePeriod: number;
    noInterest: boolean;
    conditions: Array<Condition>;

    constructor() {
        this.linkCategory = LinkCategory.AND;
        this.totalMinimum = 0;
        this.totalMaximum = 0;
        this.rate = 0;
        this.maxTerm = 0;
        this.minTerm = 0;
        this.downPayment = 0;
        this.gracePeriod = 0;
        this.noInterest = false;
        this.conditions = [];
    }
}