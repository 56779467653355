import { ExonerationReason } from "./exoneration-reason";

export class TaxExemption {
    reason: ExonerationReason;
    documentId: string;
    date: string;

    constructor() {
        this.reason = new ExonerationReason();
        this.documentId = "";
        this.date = "";
    }

    static build(reason: ExonerationReason, documentId: string, date: string): TaxExemption {
        let result = new TaxExemption();
        result.reason = reason;
        result.documentId = documentId;
        result.date = date;
        return result;
    }
}