import { NgModule } from '@angular/core';
import { SalesComponent } from './sales.component';
import { RouterModule, Routes } from '@angular/router';
import { CreateSalesComponent } from './create-sales/create-sales.component';
import { Role } from 'src/app/@core/constants';
import { ModifySalesComponent } from './modify-sales/modify-sales.component';
import { PosPromotionsModalComponent } from 'src/app/shared/pos-promotions-modal/pos-promotions-modal.component';
import { PromotionListComponent } from '../promotions/promotion-list/promotion-list.component';


const routes: Routes = [
  {
    path: '',
    component: SalesComponent,
    children: [
      {
        path: '',
        component: CreateSalesComponent,
        data: {
          title: "Ventas",
          id: 'CreateSalesComponent_sales'
        }
      },

      {
        path: ':code',
        component: ModifySalesComponent,
        data: {
          title: "Modificar Venta",
          id: 'ModifySalesComponent_sales_code'
        }
      }

    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule { }
