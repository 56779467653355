import {OrderType} from "../@core/constants";
import { ReceiptLine } from "./receipt-line";
import { Totals } from "./totals";

export class Receipt{
    code: string;
	timeStamp: string;
	sellerName: string;
	taxRegistration: string;
	taxFreeOrderNumber: string;
	taxFreeRecordNumber: string;
	secretaryOfAgricultureAndLivestockRegistry: string;
	cancellationCode: string;
	guaranteeConditions: string;
	generatedBytes: number;
	bytesDiscount: number;
	creditArrangementNumber: string;
	orderCode: string;
	orderType: OrderType;
	originalOrderCode: string;
	salesOrg: string;
	salesOrgName: string;
	salesOrgPhone: number;
	condition:string;
	branchCode:string;
	branchName:string;
	branchAddress:string;
	customerCode:string;
	customerLastName:string;
	customerName:string;
	customerFiscalId:string;
	registerCode:string;
	currency:string;
	serialNumber:string;
	number: number;
	sequence: number;
	initialDate: Date;
	endDate: Date;
	printingAuthorizationKey: string;
	extraFinancingPercentage: number;
	details: Array<ReceiptLine>;
	totals: Totals;
	partialDelivery: boolean;
	groupingNo:string;
	amountUsedAsPayment: number;
	sDownPaymentReceipt: boolean;
	loanCode: string;
	applyTo: string;
	observations: string;
	debitNote: boolean;
	contractNo: string;

    constructor() {
        this.code = "";
	    this.timeStamp = "";
	    this.sellerName = "";
	    this.taxRegistration = "";
	    this.taxFreeOrderNumber = "";
	    this.taxFreeRecordNumber = "";
	    this.secretaryOfAgricultureAndLivestockRegistry = "";
	    this.cancellationCode = "";
	    this.guaranteeConditions = "";
	    this.generatedBytes = 0;
	    this.bytesDiscount = 0;
	    this.creditArrangementNumber = "";
	    this.orderCode = "";
	    this.orderType = OrderType.CREDIT;
	    this.originalOrderCode = "";
	    this.salesOrg = "";
	    this.salesOrgName = "";
	    this.salesOrgPhone = 0;
	    this.condition = "";
	    this.branchCode = "";
	    this.branchName = "";
	    this.branchAddress = "";
	    this.customerCode = "";
	    this.customerLastName = "";
	    this.customerName = "";
	    this.customerFiscalId = "";
	    this.registerCode = "";
	    this.currency = "";
	    this.serialNumber = "";
        this.serialNumber = "";
	    this.number = 0;
	    this.sequence = 0;
	    this.initialDate = new Date();
	    this.endDate = new Date();
	    this.printingAuthorizationKey = "";
	    this.extraFinancingPercentage = 0;
	    this.details = new Array<ReceiptLine>();
	    this.totals = new Totals();
	    this.partialDelivery = false;
	    this.groupingNo = "";
	    this.amountUsedAsPayment = 0;
	    this.sDownPaymentReceipt = false;
	    this.loanCode = "";
	    this.applyTo = "";
	    this.observations = "";
	    this.debitNote = false;
	    this.contractNo = "";
    }
}