import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './configuration.service';

@Injectable( {
  providedIn: 'root'
} )
export class OpenItemsService {
  private url = `${environment.url}/fi/openItems`;

  constructor( private http: HttpClient, private configurationService: ConfigurationService ) { }

  retrieveOpenItems( date: string, customerCode: string ): Observable<any> {

    const salesOrgObject = this.configurationService.readSelectedBranchAndSalesOrg().salesOrg;
    const salesOrg = salesOrgObject.code;

    const body = {
      date,
      customerCode,
      salesOrg
    };

    return this.http.post( this.url, JSON.stringify( body ) );
  }
}
