import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-office-orders',
  templateUrl: './back-office-orders.component.html',
  styleUrls: ['./back-office-orders.component.sass']
})
export class BackOfficeOrdersComponent implements OnInit {

  constructor() { }
  
  ngOnInit(): void {
  }
}