import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PosSupervisorLoginComponent } from 'src/app/shared/pos-supervisor-login/pos-supervisor-login.component';
import { ModalDialogType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PosSupervisorLoginService {

  private modalRef?: BsModalRef;
  constructor(private modalService: BsModalService) {
    this.modalService.config.backdrop = "static";
  }

  show(confirmAction: (() => any), privileges: Array<string>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.modalRef = this.modalService.show(PosSupervisorLoginComponent)

      this.modalRef.setClass(`modal-dialog ${ModalDialogType.PRIMARY}`)
      this.modalRef.content.confirmAction = confirmAction;
      this.modalRef.content.privileges = privileges;
      resolve(confirmAction);
    })
  }

  async showAsync(privileges: Array<string>) {
    this.modalRef = this.modalService.show(PosSupervisorLoginComponent, {
        initialState: {
          isJustForLogin: false,
          disabledUserName: false,
          privileges,
          validateAllprivileges:false
        },
        class: `modal-dialog ${ModalDialogType.PRIMARY}`
      });

    const loginInformation = await this.modalRef.content!.loginInformation.toPromise();

    return loginInformation;
  }
}
