import { Discount } from "./discount";
import { Price } from "./price";
import { Subtotals } from "./subtotals";
import { Tax } from "./tax";

export class OrderEntry {
    appliedPromotionCodes: Map<string, string>;
    code: string;
    deliveredProducedQuantity: number;
    deliveredQuantity: number;
    discounts: Array<Discount>;
    isForDelivery: boolean;
    margin: number;
    price: Price;
    producedQuantity: number;
    productCode: string;
    productName: string;
    quantityUnit: number;
    quantityUnitText: number;
    returnedQuantity: number;
    serialNumber: string;
    associatedSerialNumber: string;
    services: Array<string>;
    tax: Tax;
    ticketId: string;
    totalQuantity: number;
    totals: Subtotals;
    weight: string;
    rateType: string;
    rate: number | null;
    downPayment: number | null;
    term: number;
    minTerm: number | null;
    maxTerm: number | null;
    deliveryBranch: string;
    mobileRechargePhone: string;
    creditPromotionCode: string;
    extraWarrantyInvoiceCode: string;
    extraWarrantyInvoiceLine: string;
    associatedOrder: string;
    associatedOrderEntry: string;
    originalTax: Tax;
    isPriceChange: boolean;
    setCode: string;
    serialProfile: boolean;
    netTotal: number;
    grossTotal: number;
    amountPrice: number;
    dataSourceComboBox?: Map<string,string>;
    isFirstTimeInOrder:boolean;
    constructor() {
        this.appliedPromotionCodes = new Map<string, string>();
        this.code = "";
        this.deliveredProducedQuantity = 0;
        this.deliveredQuantity = 0;
        this.discounts = [];
        this.isForDelivery = false;
        this.price = new Price();
        this.margin = 0;
        this.producedQuantity = 0;
        this.productCode = "";
        this.productName = "";
        this.quantityUnit = 0;
        this.quantityUnitText = 0;
        this.returnedQuantity = 0;
        this.serialNumber = "";
        this.associatedSerialNumber = "";
        this.services = [];
        this.tax = new Tax();
        this.originalTax = new Tax();
        this.ticketId = "";
        this.totalQuantity = 0;
        this.deliveryBranch = "";
        this.mobileRechargePhone = "";
        this.creditPromotionCode = "";
        this.extraWarrantyInvoiceCode = "";
        this.extraWarrantyInvoiceLine = "";
        this.associatedOrder = "";
        this.associatedOrderEntry = "";
        this.weight = "";
        this.rateType = "";
        this.rate = 0;
        this.downPayment = 0;
        this.term = 0;
        this.minTerm = 0;
        this.maxTerm = 0;
        this.isPriceChange = false;
        this.serialProfile = false;
        this.setCode = "";
        this.totals = new Subtotals();
        this.netTotal = 0.0;
        this.grossTotal = 0.0;
        this.amountPrice = 0.0;
        this.dataSourceComboBox = new Map();
        this.isFirstTimeInOrder = true;
    }
}
