<div class="animated fadeIn rounded">
    <div class="card">
      <div class="card-header">
        <i class="cil-info"></i> Detalle de producto
      </div>
      <div class="card-body">
        <product-info [product] = "product" ></product-info>
      </div>
    </div>
</div>

