import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromotionDetailsComponent } from './promotion-details.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PosListModule } from 'src/app/shared/pos-list/pos-list.module';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';



@NgModule({
  declarations: [PromotionDetailsComponent],
  imports: [
    CommonModule,
    AccordionModule,
    PosListModule,
    PosTableModule
  ],
  exports: [PromotionDetailsComponent]
})
export class PromotionDetailsModule { }
