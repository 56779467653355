<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Agregar pago</h4>
    <button type="button" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="mb-5" *ngIf="paymentMethod.style === 'CARD'">
      <div class="md-radio  md-radio-inline" *ngFor="let card of creditCardTypesArray">
        <input name="cardTypeGroup" type="radio" id="{{card.code}}" [checked]="creditCardTypesArray.indexOf(card) === 0"
          (change)="switchCardType($event, card.code)" />
        <label htmlFor="{{card.code}}">{{card.nameToShow}}</label>
      </div>
    </div>

    <div class="payment-method">
      <p>Método de pago</p>
      <select id="payment" name="payment" class="form-control" [(ngModel)]="paymentMethod.paymentMethodCode">
        <option *ngFor="let payment of paymentMethod.methods" [value]="payment.code">{{payment.name}}</option>
      </select>

      <div class="mt-3 form">
        <div class="item">
          <p>Por pagar: <span>{{ (totalAmount > paymentsAmount ? ( totalAmount - paymentsAmount ) : 0.00) |
              currency:'HNL': 'code' :'1.0-2' }}</span></p>
        </div>

        <div class="" *ngIf="paymentMethod.style === 'CARD'">
          <p>Total Pago: {{totalWithIsvPaymentCard | currency:'HNL':'code':'1.0-2'}}</p>
          <p>Total sin ISV: {{totalPaymentCard | currency:'HNL':'code':'1.0-2'}}</p>
          <p>Total ISV: {{totalIsvPaymentCard | currency:'HNL':'code':'1.0-2'}}</p>
        </div>

        <div class="mb-2" *ngIf="paymentMethod.code === 'PL'">
          <p>Puntos en cuenta: {{totalPointsOfSelectedClient}}</p>
          <p>Maximo utilizable: {{maxQuantityPointsCanUse}} </p>
          <p>Monto por pagar en Puntos:
            {{(maxQuantityPointsCanUse>(totalAmount - paymentsAmount)) ? (totalAmount - paymentsAmount) :
            maxQuantityPointsCanUse }}
          </p>
          <p>Monto en HNL: {{ totalAmount }} </p>
        </div>

        <div class="" *ngIf="paymentMethod.currency === 'USD'">
          <p>Monto por pagar en USD: {{ ( exchangeRate ? getPaymentUSD() : 0 ) }}</p>
        </div>

        <div class="mb-2" *ngIf="paymentMethod.code === 'CH'">
          <select id="payment" name="payment" class="form-control" [(ngModel)]="paymentMethod.bank">
            <option [ngValue]="undefined" disabled="true">Banco</option>
            <option *ngFor="let bank of bankList" [value]="bank.code">{{bank.name}}</option>
          </select>
        </div>

        <div class="mb-2"
          *ngIf="paymentMethod.code !== CASH_CODE && paymentMethod.code !== CASH_CODE_USD && paymentMethod.code !== 'PL' && paymentMethod.code !== 'CT' && paymentMethod.code !== '' && paymentMethod.style !== 'CREDIT'">

          <input type="text" [(ngModel)]="paymentMethod.identifier" class="form-control"
            [placeholder]="paymentMethod.style === 'CARD' ? 'Código de autorización' : 'Identificador'">
        </div>

        <div class="mb-2" *ngIf="paymentMethod.style === 'CARD' || paymentMethod.code === 'TD'">
          <input type="text" [(ngModel)]="paymentMethod.number" class="form-control"
            placeholder="Ultimos 4 dígitos de la tarjeta">
        </div>

        <div class="mb-2" *ngIf="paymentMethod.style === 'CARD' || paymentMethod.code === 'TD'">
          <input type="text" [(ngModel)]="paymentMethod.personName" class="form-control" placeholder="Nombre">
        </div>

        <div class="input-group mb-3">
          <input [(ngModel)]="amount" (keypress)="onlyNumbers($event)" (keyup)="calculateISVPayment($event)"
            name="amount" type="number" class="form-control" placeholder="Monto" required>
        </div>

        <button type="submit" class="btn btn-primary form-control"
          [disabled]="paymentMethod.currency === 'USD' && !exchangeRate" (click)="addPayment()">
          Agregar pago
        </button>
      </div>
    </div>
  </div>
</div>