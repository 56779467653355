import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Branch } from 'src/app/models/branch';
import { Menu } from 'src/app/models/menu';
import { SalesOrg } from 'src/app/models/sales-org';
import { Token } from 'src/app/models/token';
import { CaptchaService } from 'src/app/services/captcha.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  public form = {
    username: '',
    password: '',
    captcha: '',
    captchaId: ''
  };
  public salesOrgs: Array<SalesOrg> = [];
  public salesOrg: string = '';
  public branches: any = {};
  public filteredBranches: Array<Branch> = [];
  public branch: string = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    private configurationService: ConfigurationService,
    private captchaService: CaptchaService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.loadConfiguration();
  }

  loadConfiguration() {
    this.salesOrgs = this.configurationService.readSalesOrg();
    this.branches = this.configurationService.readBranch();
    this.salesOrg =
      this.configurationService.readSelectedBranchAndSalesOrg().salesOrg.code;
    this.branch =
      this.configurationService.readSelectedBranchAndSalesOrg().branch.code;
    this.filteredBranches = this.branches[this.salesOrg] || [];
  }

  async onSubmit(): Promise<void> {
    const { username, password, captcha, captchaId } = this.form;

    const captchaResult = await this.captchaService.readIsValid(captchaId, captcha, "LoginSalesforce").toPromise();
    if (!captchaResult.response) {
      this.toastrService.error("Captcha Incorrecto");
      return;
    }
    let token = (await this.authService
      .readToken(username, password)
      .toPromise().catch((error: HttpErrorResponse) => {
        if (error.status == 403) {
          this.goToChangePassword(username);
        }
      })) as string;
    this.authService.saveTokenInStorage(token);

    let user = await this.authService.readProfile();

    this.authService.saveProfileInStorage(user);
    this.configurationService.createSelectedBranchAndSalesOrg({
      salesOrg: this.salesOrgs.find((f) => f.code == this.salesOrg),
      branch: this.branches[this.salesOrg]
        ? this.branches[this.salesOrg].find(
          (f: { code: any }) => f.code == this.branch
        )
        : {},
    });
    await this.authService.savePrivilegesInStorage();
    await this.authService.saveAccessInStorage();
    await this.authService.saveMenusInStorage();

    const menus: Array<Menu> = Array.from(JSON.parse(localStorage.getItem('menus') || JSON.stringify({})));

    if (menus.length > 0) {
      this.router.navigateByUrl(menus[0].redirectTo);
    } else {
      this.router.navigateByUrl('');
    }
  }

  filterBranches() {
    if (!this.salesOrg) {
      this.filteredBranches = [];
    }
    this.filteredBranches = this.branches[this.salesOrg];
  }

  goToChangePassword(user?: string) {
    this.router.navigate(["changepassword", user || ""])
  }

  goToChangeForgetPassword() {
    this.router.navigate(["changeforgetpassword"])
  }

  getCaptchaId(id: string) {
    this.form.captchaId = id;
  }

}
