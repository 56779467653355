import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {

  passwordForm: FormGroup = new FormGroup({});
  captchaId: string = "";
  constructor(private authService: AuthService, private toastrService: ToastrService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    let currentUser = this.route.snapshot.params.code || "";


    this.passwordForm = new FormGroup({
      username: new FormControl("", Validators.required),
      current: new FormControl('', Validators.required),
      new: new FormControl('', Validators.required),
      confirmation: new FormControl('', [Validators.required]),
      captcha: new FormControl('', Validators.required)
    });

    if (currentUser.length > 0) {

      this.passwordForm.get("username")?.disable();
      this.passwordForm.get("username")?.markAsDirty();
      this.passwordForm.get("username")?.markAsTouched();
      this.passwordForm.get("username")?.setValue(currentUser);
    }

    this.passwordForm.setValidators(() => this.passwordMatchValidator());
  }

  async onSubmit(): Promise<void> {
    const value = this.passwordForm.getRawValue();

    await this.authService.changePassword(value.username, value.current, value.new, this.captchaId, value.captcha).toPromise();
    this.toastrService.success('Contraseña cambiada con éxito');
    this.passwordForm.reset();
    this.router.navigate(["login"]);
  }

  private passwordMatchValidator(): ValidationErrors | null {
    return this.passwordForm.get('confirmation')?.value === this.passwordForm.get('new')?.value ? null : { mismatch: true };
  }

  getCaptchaId(captchaId: string) {
    this.captchaId = captchaId;
  }
}
