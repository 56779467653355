import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password.component';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { PosCaptchaModule } from 'src/app/shared/pos-captcha/pos-captcha.module';



@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PosCaptchaModule
  ],
  providers: [AuthService, ToastrModule],
  exports: [ChangePasswordComponent]
})
export class ChangePasswordModule { }
