<div class="app-body animated fadeIn">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="card">
        <div class="card-header bg-primary">
          <i class="fa fa-edit"></i>Configración
        </div>
        <div class="card-body">
          <form #f="ngForm" (ngSubmit)="f.form.valid && saveChanges()" novalidate>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="physicalEstablishmentLabel">Establecimiento Físico</label>
              <div class="col-md-9">
                <ng-select name="physicalEstablishment" [(ngModel)]="physicalEstablishment" [selectOnTab]="true"
                  (change)="changePhysicalEstablishment($event)" required>
                  <ng-option></ng-option>
                  <ng-option *ngFor="let establishment of establishments.content" [value]="establishment.code">
                    [{{establishment.code}}]{{establishment.name}}</ng-option>
                </ng-select>

              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="register">Caja</label>
              <div class="col-md-9">
                <ng-select name="register" [selectOnTab]="true" [(ngModel)]="register" required>
                  <ng-option *ngFor="let register of registers" [value]="register.code">
                    [{{register.code}}]{{register.name}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="salesOrg">Organizaciones</label>
              <div class="col-md-9">
                <ng-select name="salesOrg" [items]="salesOrgs.content" bindLabel="name" [multiple]="true"
                  [(ngModel)]="salesOrg" [closeOnSelect]="false" [selectOnTab]="true" (change)="selectSalesOrg($event)"
                  required>
                </ng-select>
              </div>
            </div>
            <div class="form-group row" *ngFor="let s of salesOrg">
              <label class="col-md-3 col-form-label" for="branch">Sucurusales [{{s.code}}]{{s.name}}</label>
              <div class="col-md-9">
                <ng-select [name]="'branch'+s.code" [multiple]="true" [closeOnSelect]="false" [selectOnTab]="true"
                  [(ngModel)]="branch[s.code]" (change)="selectBranch(s, $event)" required>
                  <ng-option *ngFor="let b of filterBranches(s)" class="" [value]="b.code">[{{b.code}}]{{b.name}}
                  </ng-option>
                </ng-select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="regions">Región</label>
              <div class="col-md-9">
                <ng-select name="region" [(ngModel)]="regionSelected" [items]="regions" bindLabel="name" [multiple]="true" [closeOnSelect]="false" [selectOnTab]="true" required>
                </ng-select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label" for="select1">Supervisor por defecto en autorizaciones</label>
              <div class="col-md-9">
                <ng-select name="username" [(ngModel)]="username" [selectOnTab]="true" required>
                  <ng-option *ngFor="let user of users.content" [value]="user.username">
                    [{{user.username}}]{{user.fullname}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="form-actions">
              <button type="submit" class="btn btn-success">Guardar Cambios</button>
              <button type="button" class="btn btn-danger" (click)="resetConfig()">Limpiar</button>
              <button type="button" class="btn btn-secondary" (click)="cancel()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</div>
