import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosDiscountModalComponent } from './pos-discount-modal.component';
import { PosDiscountModalService } from 'src/app/@core/services/pos-discount-modal.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [PosDiscountModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  providers: [PosDiscountModalService],
  exports: [PosDiscountModalComponent]
})
export class PosDiscountModalModule { }
