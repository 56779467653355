import { DatePipe } from '@angular/common';
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PosSupervisorLoginService } from 'src/app/@core/services/pos-supervisor-login.service';
import { ColumnTable } from 'src/app/models/column-table';
import { FilterTable } from 'src/app/models/filter-table';
import { Paging } from 'src/app/models/page';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'pos-table',
  templateUrl: './pos-table.component.html',
  styleUrls: ['./pos-table.component.sass'],
})
export class PosTableComponent implements OnInit, OnChanges {
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private authService: AuthService,
    private supervisorService: PosSupervisorLoginService
  ) { }

  @Input()
  public headers: Array<ColumnTable> = [];

  @Input()
  public filters: Array<FilterTable> = [];

  @Input()
  public data: Paging<Array<any>> = new Paging([]);

  @Input()
  public badgeSuccessValues: Array<string> | string = [];
  @Input()
  public badgeWarningValues: Array<string> | string = [];
  @Input()
  public badgeSecondaryValues: Array<string> | string = [];
  @Input()
  public badgeDangerValues: Array<string> | string = [];

  @Input()
  public itemsPerPage: number = 20;

  @Input()
  public tableFormId: string = 'tf';

  @Input()
  public pagination: boolean = true;

  @Output()
  public dataChange = new EventEmitter<any>();

  @Output()
  public dataSave = new EventEmitter<any>();

  @Output()
  public rowClick = new EventEmitter<any>();

  public currentPage = 1;

  public filterForm?: FormGroup;
  public tableForm?: FormGroup;
  public Object = Object;
  private isFiltered = false;

  public currentIndex?: number;

  @Output()
  public getTableForm = new EventEmitter();

  @Input()
  public disableHighlight: boolean = false;
  ngOnInit(): void {
    let filterColumns = this.filters.reduce(
      (ac, a) => ({ ...ac, [a.code]: '' }),
      {}
    );
    this.filterForm = this.formBuilder.group(filterColumns);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.headers &&
      changes.headers.isFirstChange() &&
      changes.headers.currentValue.length > 0
    ) {
      this.updateTable();
    }
    if (
      changes.data &&
      !changes.data.isFirstChange() /*&& changes.data.currentValue.content.length > 0*/
    ) {
      this.updateTable();
      this.onSubmit();
      // if (!this.isEqual(changes.data.previousValue.content, changes.data.currentValue.content)) {

      // }
    }
  }

  updateTable() {
    let tableArray = new Map<any, any>();
    this.data.content.forEach((f, dataIndex) => {
      this.headers.forEach((h, headerIndex) => {
        let value = f[h.code];
        let required = h.required
          ? Validators.required
          : Validators.nullValidator;
        let formControl = new FormControl(value, required);
        if (h.type == 'date') {
          formControl = new FormControl(
            this.datePipe.transform(value, 'yyyy-MM-dd'),
            required
          );
        }
        if (h.type == 'number' && h.editable && h.required) {
          formControl = new FormControl(value, [Validators.min(1), required]);
        }
        tableArray.set(`${h.code}-${dataIndex}-${headerIndex}`, formControl);
      });
    });

    this.tableForm = this.formBuilder.group(Object.fromEntries(tableArray));
  }

  public getRowData(item: any, field: ColumnTable) {
    return field.valueMap.get(item[field.code]) || item[field.code];
  }

  public obtainBadgeClass(header: ColumnTable, value: string) {
    if (this.badgeSuccessValues.includes(value)) {
      return 'badge badge-success';
    } else if (this.badgeWarningValues.includes(value)) {
      return 'badge badge-warning';
    } else if (this.badgeSecondaryValues.includes(value)) {
      return 'badge badge-secondary';
    } else if (this.badgeDangerValues.includes(value)) {
      return 'badge badge-danger';
    }

    return 'badge badge-success';
  }

  public pageSelected(event: any) {
    this.filterAction(event.page - 1);
  }

  public filterAction(page?: number) {
    let value = {
      value: this.filterForm ? this.filterForm.value : {},
      page: page != undefined ? page : this.currentPage - 1,
      pageSize: this.itemsPerPage,
    };
    this.dataChange.emit(value);
    this.updateTable();
    //this.isFiltered = true;
  }
  trackByIndex(index: number, value: any) {
    return index;
  }

  trackByValue(custom: any, index: number, value: any) {
    return custom;
  }

  onSubmit(): void {
    let result = this.getTableDataMap();
    let resultArray = Array.from(result.values());
    this.dataSave.emit(resultArray);

    if (resultArray.length > 0) {
      this.tableForm?.markAsTouched();
      this.tableForm?.markAsDirty();
    } else {
      this.tableForm?.markAsUntouched();
      this.tableForm?.markAsPristine();
    }
    this.getTableForm.emit(this.tableForm);
  }

  rowFn(value: any, itemIndex: number) {
    this.currentIndex = itemIndex;
    this.rowClick.emit(value);
  }

  getTableDataMap(): Map<any, any> {
    //{'2000', Object}

    let result = Object.keys(this.tableForm?.value);
    let map = new Map<any, any>();
    result.forEach((f) => {
      let a = f.split('-')[0];
      let b = f.split('-')[1];

      let val = map.get(b) || {};

      Object.assign(val, { [a]: this.tableForm?.value[f] });
      map.set(b, val);
    });

    return map;
  }

  isEqual(previous: any, current: any) {
    let previousArray = previous as any[];
    let currentArray = current as any[];
    let result = true;
    let headerCodes = this.headers.map((h) => h.code);

    headerCodes.forEach((f) => {
      let previousByHeaderCodeValues = previousArray.map((m) => m[f]);
      let currentByHeaderCodeValues = currentArray.map((m) => m[f]);
      let validationResult =
        previousByHeaderCodeValues.length == currentByHeaderCodeValues.length &&
        previousByHeaderCodeValues.every((v, i) => {
          return v == currentByHeaderCodeValues[i];
        });

      if (!validationResult) {
        result = validationResult;
        return;
      }
    });
    return result;
  }

  async doPrivilegedAction(
    action: {
      fn: (param: any) => any;
      icon: string;
      tooltip: string;
      isDisabled: boolean;
      disabledFn?: (param: any) => boolean;
      isPrivileged?: boolean;
      privilege?: string;
    },
    item: any
  ) {
    if (this.authService.hasPrivilege(action.privilege || '')) {
      action.fn(item);
    } else {
      await this.supervisorService.show(() => {
        action.fn(item);
      }, [action.privilege || '']);
    }
  }

  getKeyListFromMap(keys: Iterable<any>): Array<any> {
    return Array.from(keys);
  }
}
