<div class="align-items-center mb-3">
  <div class="col-md-3 col-sm-12">
    <form [formGroup]="searchForm" (ngSubmit)="handleSubmit()" #searchTemplateForm="ngForm">
      <label for="txtCustomerCode">Codigo de cliente</label>
      <div class="form-group input-group">
        <input type="text" class="form-control" id="txtCustomerCode" formControlName="searchCriteria">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="handleSearchCustomerClick()"><i
              class="fa fa-search"></i></button>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-6 col-sm-12">
    <div class="row" [hidden]="hideButtons">
      <div class="col-md-4">
        <button
          id="btnSearchCustomer"
          class="btn btn-primary w-100"
          [routerLink]="['/customer/create']"
        >
          Crear
        </button>
      </div>
      <div class="col-md-4">
        <button
          id="btnSearchCustomer"
          class="btn btn-primary w-100"
          [routerLink]="['/customer/update', this.currentCustomer.code]"
          [disabled]="this.currentCustomer.code === ''"
        >
          Actualizar Datos
        </button>
      </div>
    </div>
  </div>
</div>
