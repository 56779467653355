import { Component, Input, OnInit } from '@angular/core';
import { Paging } from 'src/app/models/page';
import { Product } from 'src/app/models/product';
import { Tax } from 'src/app/models/tax';
import { TaxesService } from 'src/app/services/taxes.service';

@Component({
  selector: 'product-info',
  templateUrl: './product-info.component.html'
})
export class ProductInfoComponent implements OnInit {

  @Input() product = new Product();
  private taxes: Paging<Array<Tax>> =new Paging([]);
  public tax: Tax = new Tax();
  public featureProperties: string[] = [];
  
  constructor(private taxService: TaxesService) { 
  }

  async ngOnInit(): Promise<void> {
    this.taxes = (await this.taxService.readTaxes().toPromise()).response;
    this.tax = this.taxes.content.find(t => t.code == this.product.taxCode) as Tax;
    this.featureProperties = Object.keys(this.product.features);
  }

}
