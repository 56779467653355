export class CustomerUpdateData {
    firstName: string;
    middleName: string;
    firstLastName: string;
    secondLastName: string;
    civilStatus: String;
    documentNumber: string;
    documentType: string;
    mobilePhone: string;
    homePhone: string;
    workPhoneNumber: string;
    extension: string;
    email: string;
    stateProvince: String;
    municipality: String;
    district: String;
    addressLine1: String;
    addressLine2: String;
    addressLine3: String;
    addressLine4: String;
    addressLine5: String;
    companyName: string;
    workPosition: string;
    workDepartment: string;
    companyStateProvince: String;
    companyMunicipality: String;
    companyDistrict: String;
    companyAddressLine1: String;
    companyAddressLine2: String;
    companyAddressLine3: String;
    companyAddressLine4: String;
    companyAddressLine5: String;

    constructor() {
        this.firstName = "";
        this.middleName = "";
        this.firstLastName = "";
        this.secondLastName = "";
        this.civilStatus = "";
        this.documentNumber = "";
        this.documentType = "";
        this.mobilePhone = "";
        this.homePhone = "";
        this.workPhoneNumber = "";
        this.extension = "";
        this.email = "";
        this.stateProvince = "";
        this.municipality = "";
        this.district = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.addressLine3 = "";
        this.addressLine4 = "";
        this.addressLine5 = "";
        this.companyName = "";
        this.workPosition = "";
        this.companyStateProvince = "";
        this.companyMunicipality = "";
        this.companyDistrict = "";
        this.companyAddressLine1 = "";
        this.companyAddressLine2 = "";
        this.companyAddressLine3 = "";
        this.companyAddressLine4 = "";
        this.companyAddressLine5 = "";
        this.workDepartment = "";
    }
}