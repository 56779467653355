import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from 'src/app/@core/constants';
import { PromotionDetailsComponent } from './promotion-details/promotion-details.component';
import { PromotionListComponent } from './promotion-list/promotion-list.component';
import { PromotionsComponent } from './promotions.component';

const routes: Routes = [
  {
    path: '',
    component: PromotionsComponent,
    children: [
      {
        path: '',
        component: PromotionListComponent,
        data: {
          title: "Listado de Promociones",
          id: 'PromotionListComponent_promotions'
        }
      },
      {
        path: ':code',
        component: PromotionDetailsComponent,
        data: {
          title: "Listado de Promociones",
          id: 'PromotionDetailsComponent_promotions_code'
        }
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PromotionsRoutingModule { }
