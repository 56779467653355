import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterTable } from 'src/app/models/filter-table';
import { Paging } from 'src/app/models/page';
import { PosListConfig } from './pos-list-config';

@Component({
  selector: 'pos-list',
  templateUrl: './pos-list.component.html',
  styleUrls: ['./pos-list.component.sass']
})
export class PosListComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }

  @Input()
  public data: Paging<Array<any>> = new Paging([]);

  @Input()
  public config: PosListConfig = {};

  @Input()
  public filters: Array<FilterTable> = [];

  @Output()
  public dataChange = new EventEmitter<any>();

  @Input()
  public pagination: boolean = true;
  public currentPage = 1;

  public filterForm: FormGroup = this.formBuilder.group([]);

  @Input()
  public itemsPerPage: number = 10;

  @Output()
  public rowClick = new EventEmitter<any>();
  ngOnInit(): void {
    let filterColumns = this.filters.reduce((ac, a) => ({ ...ac, [a.code]: '' }), {});
    this.filterForm = this.formBuilder.group(filterColumns);
  }

  getTitle(item: any) {
    return this.config?.title ? item[this.config?.title] : "";
  }

  getDescription(item: any) {
    return this.config?.description ? item[this.config?.description] : "";
  }

  getSmallTitle(item: any) {
    return this.config?.smallTitle ? item[this.config?.smallTitle] : "";
  }

  getBadge(item: any) {
    return this.config?.badge ? item[this.config?.badge] : "";
  }

  filterAction(page?: number) {
    let value = { value: this.filterForm ? this.filterForm.value : {}, page: page != undefined ? page : (this.currentPage - 1), pageSize: this.itemsPerPage };
    this.dataChange.emit(value);
  }

  public pageSelected(event: any) {
    this.filterAction(event.page - 1);
  }

  trackByIndex(index: number, value: any) {
    return index;
  }

  rowFn(value: any) {
    this.rowClick.emit(value);
  }

}
