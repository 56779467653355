import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-pos-order-observations',
  templateUrl: './pos-order-observations.component.html',
  styleUrls: ['./pos-order-observations.component.sass']
})
export class PosOrderObservationsComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder) { }

  observations: string = "";
  isDisabled: boolean = false;
  private confirmAction: (value: string) => any = (value: string) => { };

  observationForm: FormGroup = this.formBuilder.group(
    {
      observationText: new FormControl("", [Validators.required, Validators.max(800)]),
    }
  );
  ngOnInit(): void {
  }

  load() {
    this.observationForm.get("observationText")?.setValue(this.observations);
    if (this.isDisabled) {
      this.observationForm.disable();
    }
  }

  confirm() {

    this.confirmAction(this.observationForm.get("observationText")?.value);
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }

}
