import { CreditCalculatorStepComponent } from './../credit-calculator-step/credit-calculator-step.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditConditionStepComponent } from './credit-condition-step.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PosCreditCalendarModule } from 'src/app/shared/pos-credit-calendar/pos-credit-calendar.module';
import { LoanService } from 'src/app/services/loan.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [CreditConditionStepComponent],
  imports: [CommonModule, ReactiveFormsModule, PosCreditCalendarModule, AccordionModule.forRoot(), NgxMaskModule],
  providers: [LoanService],
  exports: [CreditConditionStepComponent],
})
export class CreditConditionStepModule { }
