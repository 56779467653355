export class PaymentMethodType {
  bankAccounts: any;
  code: string;
  currency: string;
  displayName: string;
  loyaltyPoints: boolean;
  name: string;
  paymentTypeCode: string;
  pointValue: number;

  constructor() {
    this.code = "";
    this.currency = "";
    this.displayName = "";
    this.loyaltyPoints = false;
    this.name = "";
    this.paymentTypeCode = "";
    this.pointValue = 0;
  }
}
