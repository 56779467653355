import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayInvoiceStepComponent } from './pay-invoice-step.component';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { PosTotalsModule } from 'src/app/shared/pos-totals/pos-totals.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PosConfirmationModule } from 'src/app/shared/pos-confirmation/pos-confirmation.module';



@NgModule({
  declarations: [PayInvoiceStepComponent],
  imports: [
    CommonModule,
    PosTableModule,
    PosTotalsModule,
    AccordionModule,
    PosConfirmationModule
  ],
  exports: [PayInvoiceStepComponent]
})
export class PayInvoiceStepModule { }
