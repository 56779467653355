import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosSupervisorLoginComponent } from './pos-supervisor-login.component';
import { AuthService } from 'src/app/services/auth.service';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PosSupervisorLoginService } from '../../@core/services/pos-supervisor-login.service';



@NgModule({
  declarations: [PosSupervisorLoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot()
  ],
  providers: [AuthService, PosSupervisorLoginService],
  exports: [PosSupervisorLoginComponent]
})
export class PosSupervisorLoginModule { }
