import { ResponseModel } from './../../../models/response-model';
import { OrderCompleteProcess } from './../../../models/order-complete-process';
import { LoanIntermediary } from './../../../models/loan-intermediary';
import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import Big from 'big.js';
import { Customer } from 'src/app/models/customer';
import { CustomerCreditValidation } from 'src/app/models/customer-credit-validation';
import { Loan } from 'src/app/models/loan';
import { Order } from 'src/app/models/order';
import { OrderEntry } from 'src/app/models/order-entry';
import { Promotion } from 'src/app/models/promotion';
import { OrderService } from 'src/app/services/order.service';
import { PromotionsService } from 'src/app/services/promotions.service';
import { SelectEndorsementService } from '../select-endorsement/select-endorsement.service';
import { OrderRequest } from './../../../models/requests/order-request';
import { CreditConditionStepComponent } from './../credit-condition-step/credit-condition-step.component';
import { RoleType } from 'src/app/@core/constants';
import { DateUtils } from 'src/app/@core/utils/date-utils';

@Component({
  selector: 'app-credit-calculator-step',
  templateUrl: './credit-calculator-step.component.html',
  styleUrls: ['./credit-calculator-step.component.sass'],
})
export class CreditCalculatorStepComponent
  implements OnInit, AfterViewInit, OnChanges {
  // @ViewChild('creditConditionStep')
  // components!: QueryList<CreditConditionStepComponent>;
  @Input()
  order: Order = new Order();
  @Input()
  orderRequest: OrderRequest = new OrderRequest();
  @Input()
  customerCreditConditions: CustomerCreditValidation = new CustomerCreditValidation();
  @Input()
  isEditingMode: boolean = false;
  @Input()
  result: ResponseModel<OrderCompleteProcess> | null = null;

  @Output()
  creditConditionsChange = new EventEmitter<{
    loans: Array<Loan>;
    endorsementIntermediary: Customer | null;
  }>();

  formCreditConditions: FormArray = new FormArray([]);

  @Input()
  loans: Array<Loan> = [];

  @Input()
  orderTypeId: number = 0;

  @Input()
  calculatedFeesFormArray = new FormArray([]);

  tableForm: FormArray = new FormArray([]);

  promotions: Array<Promotion> = [];

  endorsementIntermediary: Customer | null = null;

  get isEndorsementNotSelected(): Boolean {
    return !this.endorsementIntermediary;
  }

  @Input()
  isPromotionOrDiscountApplied: boolean = false;

  constructor(
    private readonly orderService: OrderService,
    private readonly promotionService: PromotionsService,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly selectEndorsementService: SelectEndorsementService
  ) {
    this.tableForm = fb.array([]);
    this.formCreditConditions = fb.array([]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { order, orderRequest } = changes;

    if (order && orderRequest) {
      const areThereItemsInOrder =
        (order.currentValue as Order).orderEntryList.length > 0;
      const areThereItemsInOrderRequest =
        (orderRequest.currentValue as OrderRequest).orderEntryList.length > 0;

      if (areThereItemsInOrder && areThereItemsInOrderRequest) {
        this.calculatedFeesFormArray.reset();
        this.simulateOrder(false, 'creditCalculatorComponent');
      }
    }
  }

  ngAfterViewInit(): void { }

  ngOnInit(): void { }

  public async simulateOrder(
    isFirstTime: boolean,
    origin: String,
    simulateFromServer?: boolean
  ): Promise<void> {
    if (
      origin === 'table' ||
      origin === 'createSalesComponent' ||
      origin === 'creditCalculatorComponent'
    ) {
      if (this.isEditingMode === true) {
        this.orderRequest.orderCondition =
          this.order.orderCondition || this.orderTypeId;
      } else {
        this.order.orderCondition = this.orderRequest.orderCondition;
      }

      this.orderRequest.orderEntryList.forEach((p, i) => {
        const orderP = this.order.orderEntryList[i];
        if (orderP.isFirstTimeInOrder === true && origin !== 'table') {
          p.maxTerm = null;
          p.minTerm = null;
          p.rate = null;
          p.term = null;
          p.downPayment = null;
        } else {
          p.maxTerm = orderP.maxTerm === 0 ? null : orderP.maxTerm;
          p.minTerm = orderP.minTerm === 0 ? null : orderP.minTerm;
          p.rate = orderP.rate === 0 ? null : orderP.rate;
          p.term = orderP.term === 0 ? null : orderP.term;
          p.downPayment = orderP.downPayment === 0 ? null : orderP.downPayment;
        }
      });

      if (this.isEditingMode !== true) {
        this.result = await this.orderService
          .simulateOrderWithLoans(this.orderRequest)
          .toPromise();
      }

      if (this.isEditingMode === true) {
        this.result = await this.orderService
          .simulateOrderWithLoans(this.orderRequest)
          .toPromise();
      }

      this.result?.response.loans.forEach((l, i) => {
        if (this.loans[i]) {
          l.code = this.loans[i].code;
          l.parameters.downPayment = this.loans[i].parameters.downPayment;
          l.parameters.downPaymentPercentage =
            this.loans[i].parameters.downPaymentPercentage;
          l.parameters.startDate = this.loans[i].parameters.startDate;
          l.parameters.startDateRaw = this.loans[i].parameters.startDateRaw;
          l.parameters.calculatedDate = this.loans[i].parameters.calculatedDate;
        }
      });

      this.loans = this.result?.response.loans || new Array();

      this.buildTermsList(this.loans);

      this.tableForm = this.builFormArray(this.loans);

      await this.loadAppliedPromotions();

      this.result?.response.order.orderEntryList.forEach(
        (p: OrderEntry, index: number) => {
          this.orderRequest.orderEntryList[index].term = p.term;
          this.orderRequest.orderEntryList[index].maxTerm = p.maxTerm;
          this.orderRequest.orderEntryList[index].minTerm = p.minTerm;
          this.orderRequest.orderEntryList[index].rate = p.rate;
          this.orderRequest.orderEntryList[index].downPayment = p.downPayment;

          this.order.orderEntryList[index].term = p.term;
          this.order.orderEntryList[index].maxTerm = p.maxTerm;
          this.order.orderEntryList[index].minTerm = p.minTerm;
          this.order.orderEntryList[index].rate = p.rate;
          this.order.orderEntryList[index].downPayment = p.downPayment;
        }
      );

      this.loans.forEach((l, i) =>
        this.distriDownpayment(i, {
          downpaymentValue: l.parameters.downPayment,
          downpaymentPercentage: l.parameters.downPaymentPercentage,
        })
      );
    }
  }

  private buildTermsList(loans: Array<Loan>): void {
    this.loans.forEach((l) =>
      l.products.forEach((p) => {
        const termsList: Map<string, string> = new Map();

        for (let i = p.minTerm; i! <= p.maxTerm!; i!++) {
          termsList.set(`${i}`, `${i} ${i === 1 ? 'Mes' : 'Meses'}`);
        }

        p.dataSourceComboBox = termsList;
      })
    );
  }

  builFormArray(loans: Array<Loan>): FormArray {
    const formArray = this.fb.array([]);

    this.loans.forEach((loan: Loan, index: number) => {
      this.addCreditConditionsForm();

      formArray.push(
        this.fb.group({
          loanIndex: [index],
          products: this.fb.array(
            loan.products.map((product: OrderEntry, pIndex: number) => {
              const downPayment = this.fb.control(product.downPayment);
              downPayment.disable();
              return this.fb.group({
                code: [product.code],
                productCode: [product.productCode],
                productName: [product.productName],
                price: [product.amountPrice],
                downPayment,
                term: [product.term],
                policy: [product.creditPromotionCode || '1'],
                isService: [this.isService(product.code, index, pIndex)],
              });
            })
          ),
        })
      );
    });

    return formArray;
  }

  getFormGroupByIndex(index: number): FormGroup {
    return this.tableForm.at(index) as FormGroup;
  }

  getFormGroupOfProducts(index: number): FormArray {
    const formGroup = this.getFormGroupByIndex(index);
    return formGroup.controls.products as FormArray;
  }

  castAbstractControlToFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  getArrayOfKeysFromMap(map: Map<any, any> | undefined): Array<any> {
    if (map) {
      return Array.from(map.keys());
    }
    return new Array();
  }

  isService(code: string, loanIndex: number, productIndex: number): boolean {
    let isService = false;

    const loan = this.loans[loanIndex];

    isService = loan.products
      .map((p) => p.services.includes(code))
      .some((p) => p === true);

    return isService;
  }

  async handleTermChange(lIndex: number, pIndex: number): Promise<void> {
    const term = (this.tableForm.controls[lIndex] as FormGroup).getRawValue()
      .products[pIndex].term;

    (
      (this.tableForm.controls[lIndex] as FormGroup).controls
        .products as FormArray
    ).controls.forEach((c, cIndex: number) => {
      if (c.value.isService) {
        if (
          this.loans[lIndex].products[pIndex].services.includes(c.value.code)
        ) {
          c.patchValue({ term });
        }
      }
    });

    this.orderRequest.orderEntryList[pIndex].term = Number(term);

    await this.simulateOrder(false, 'table');
  }

  async loadAppliedPromotions(): Promise<void> {
    const customerPolicyPromotion = new Promotion();
    (customerPolicyPromotion.code = '1'),
      (customerPolicyPromotion.description = 'Política de cliente');

    const promotions: Array<Promotion> = [];

    promotions.push(customerPolicyPromotion);

    for (const promoCode of this.order.appliedPromotions) {
      const promotion = await this.promotionService
        .readPromotion(promoCode)
        .toPromise();

      promotions.push(promotion.response);
    }

    this.promotions = promotions;
  }

  getTotalToFinance(): number {
    const totalWithOutDownpayment = this.loans.reduce(
      (acc, act) => acc + act.totalFinanced,
      0
    );
    const downPaymentCurrency = this.getTotalDownPayment();

    return Number(
      Big(totalWithOutDownpayment).minus(Big(downPaymentCurrency)).round(2)
    );
  }

  addCreditConditionsForm(): void {
    this.formCreditConditions.push(
      this.fb.group({
        term: new FormControl(),
        downPaymentPercent: new FormControl(),
        downPaymenyCurrency: new FormControl(),
        initialDate: new FormControl(),
        firstFeeDate: new FormControl(),
        fee: new FormControl(),
      })
    );
  }

  getTotalDownPayment(): number {
    return this.loans.reduce((acc, act) => {
      return acc + (act.parameters.downPayment || 0);
    }, 0);
  }

  getCreditConditionsForm(index: number): FormGroup {
    return this.formCreditConditions.controls[index] as FormGroup;
  }

  async handleQuotaCalculated(loanIndex: number, loan: Loan): Promise<void> {
    loan.products = this.loans[loanIndex].products;
    loan.code = this.loans[loanIndex].code;

    this.loans[loanIndex] = loan;

    this.creditConditionsChange.emit({
      loans: this.loans,
      endorsementIntermediary: this.endorsementIntermediary,
    });
  }

  handleTermChangeEmit(loanIndex: number, term: String): void {
    const productsFormArray = this.tableForm
      .at(loanIndex)
      .get('products') as FormArray;

    for (const [i, productFormGroup] of productsFormArray.controls.entries()) {
      productFormGroup.get('term')?.setValue(term);

      this.orderRequest.orderEntryList[i].term = Number(term);
      const orderEntry =
        this.order.orderEntryList.find(
          (o) => o.code === productFormGroup.get('code')?.value
        ) || new OrderEntry();
      if (orderEntry !== null || orderEntry !== undefined) {
        orderEntry.term = Number(term);
      }
    }
  }

  handleDownpaymentChange(
    loanIndex: number,
    downpayment: {
      downpaymentValue: number;
      downpaymentPercentage: number;
    }
  ): void {
    this.distriDownpayment(loanIndex, downpayment);
  }

  private distriDownpayment(
    loanIndex: number,
    downpayment: { downpaymentValue: number; downpaymentPercentage: number }
  ): void {
    (
      this.tableForm.at(loanIndex).get('products') as FormArray
    ).controls.forEach((c, pIndex) => {
      c.get('downPayment')?.setValue(
        Big(this.loans[loanIndex].products[pIndex].netTotal)
          .mul(Big(downpayment.downpaymentPercentage).div(100))
          .round(2)
      );

      this.loans[loanIndex].products[pIndex].downPayment = Number(
        Big(this.loans[loanIndex].products[pIndex].netTotal)
          .mul(Big(downpayment.downpaymentPercentage).div(100))
          .round(2)
      );
    });

    this.loans[loanIndex].parameters.downPayment = downpayment.downpaymentValue;
    this.loans[loanIndex].parameters.downPaymentPercentage =
      downpayment.downpaymentPercentage;

    const totalFinanced =
      this.calculateTotalFinancedByLoanIndex(loanIndex);

    this.loans[loanIndex].amount = totalFinanced;
    this.loans[loanIndex].parameters.amount = totalFinanced;
    this.loans[loanIndex].totalFinanced = totalFinanced;
  }

  private calculateTotalFinancedByLoanIndex(loanIndex: number): number {
    let totalFinanced = 0;

    for (const orderEntry of this.order.orderEntryList) {
      const loanProduct =
        this.loans[loanIndex].products.find(
          (p) => p.code === orderEntry.code
        ) || new OrderEntry();

      if (orderEntry.code === loanProduct.code) {
        totalFinanced += orderEntry.totals.netTotal;
      }
    }
    return totalFinanced;
  }

  handleFirstPaymentDateChange(
    loanIndex: number,
    firstPaymentDate: Date
  ): void {
    if (DateUtils.isValidDate(firstPaymentDate)) {
      this.loans[loanIndex].parameters.startDate =
        this.datePipe.transform(firstPaymentDate, 'YYYYMMdd') || '';
      this.loans[loanIndex].parameters.startDateRaw = firstPaymentDate;
    }
  }

  openEndorsementSelection() {
    this.selectEndorsementService.show(
      this.order.customerCode,
      (customer: Customer) => {
        this.endorsementIntermediary = customer;
        this.creditConditionsChange.emit({
          loans: this.loans,
          endorsementIntermediary: this.endorsementIntermediary,
        });
      }
    );
  }

  handlePolicyChange(loanIndex: number, productIndex: number): void {
    const formArrayOfProducts = this.tableForm
      .at(loanIndex)
      .get('products') as FormArray;

    const policySelected = formArrayOfProducts
      .at(productIndex)
      .get('policy')?.value;

    const promotion = this.promotions.find((p) => p.code === policySelected);

    this.loans[loanIndex].rate = `${promotion?.buy.rate || this.customerCreditConditions.rate
      }`;

    this.orderRequest.orderEntryList.forEach((o, i) => {
      if (promotion?.code === '1') {
        o.creditPromotionCode = '';
        o.rate = null;
        o.minTerm = null;
        o.maxTerm = null;
        o.downPayment = null;

        this.order.orderEntryList[i].creditPromotionCode = '';
        this.order.orderEntryList[i].rate = null;
        this.order.orderEntryList[i].minTerm = null;
        this.order.orderEntryList[i].maxTerm = null;
        this.order.orderEntryList[i].downPayment = null;
      } else {
        if (promotion?.buy.conditions.length === 0) {
          o.creditPromotionCode = policySelected;
          this.order.orderEntryList[i].creditPromotionCode = policySelected;
        }

        promotion?.buy.conditions.forEach((c) => {
          if (c.productCodes.length === 0) {
            o.creditPromotionCode = policySelected;
            this.order.orderEntryList[i].creditPromotionCode = policySelected;
          }

          if (c.productCodes.includes(o.productCode)) {
            o.creditPromotionCode = policySelected;
            this.order.orderEntryList[i].creditPromotionCode = policySelected;
          }
        });
      }
    });

    // (
    //   (this.tableForm.controls[loanIndex] as FormGroup).controls
    //     .products as FormArray
    // ).controls.forEach((c, cIndex: number) => {
    //   if (c.value.isService) {
    //     if (
    //       this.loans[loanIndex].products[productIndex].services.includes(c.value.code)
    //     ) {
    //       c.patchValue({ policy:policySelected });
    //     }
    //   }
    // });

    this.simulateOrder(false, 'table');
  }

  getAppliedPromotion(loanIndex: number): Promotion {
    const formArrayProducts = this.tableForm
      .at(loanIndex)
      .get('products') as FormArray;

    const promotionCode = formArrayProducts.at(0).get('policy')?.value;

    return (
      this.promotions.find((p) => p.code === promotionCode) || new Promotion()
    );
  }
}
