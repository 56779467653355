import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { City } from '../models/city';
import { Paging } from '../models/page';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private url = `${environment.url}/cities`;
  
  constructor(private http: HttpClient) {
    
  }

  readCities(countryCode:string,regionCode:string):Observable<ResponseModel<Paging<Array<City>>>>
  {
    let params = new HttpParams();
    params = params
      .set("countryCode", countryCode)
      .set("regionCode", regionCode)
      .set("pageSize", "50000");
    return this.http.get<ResponseModel<Paging<Array<City>>>>(this.url, { params: params });
  }
}