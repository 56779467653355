import { PrintType } from './../@core/constants';
export class DigitalSignature {
  code: string;
  orderCode: string;
  processingStatus: string;
  processingMessage: string;
  requestNumber: number;
  phone: string;
  documents: DigitalSignatureDocument[];

  constructor() {
    this.code = '';
    this.orderCode = '';
    this.processingStatus = '';
    this.processingMessage = '';
    this.requestNumber = 0;
    this.phone = '';
    this.documents = [];
  }
}

export class DigitalSignatureDocument {
  code: string;
  documentType: DigitalSignatureDocumentType;
  fileName: string;
  processingStatusDigitalSignatureDocument: string;
  contractNumber: string;
  print: boolean;
  printType: PrintType;


  constructor() {
    this.code = '';
    this.fileName = '';
    this.documentType = DigitalSignatureDocumentType.NONE;
    this.processingStatusDigitalSignatureDocument = '';
    this.contractNumber = '';
    this.print = false;
    this.printType = PrintType.ORIGINAL;
  }
}

export enum DigitalSignatureDocumentType {
  NONE = '', INVOICE = 'INVOICE', PROMISSORY_NOTE = 'PROMISSORY_NOTE', DOMINANCE_TRADITION = 'DOMINANCE_TRADITION', LOAN = 'LOAN', PAYMENT_PLAN = 'PAYMENT_PLAN', DEBIT_NOTE = 'DEBIT_NOTE', LOAN_APPENDIX = 'LOAN_APPENDIX'
}
