<pos-stepper #cdkStepper (selectionChange)="handleChangeStep($event)">
    <cdk-step label="Pre Aprobación" [stepControl]="preApprovalForm" [optional]="false"
        *ngIf="this.currentCustomer.mainPersonalInfo?.personality !== 'LEGAL_ENTITY'">
        <app-customer-pre-approval-form [preApprovalForm]="preApprovalForm" [currentCustomer]="this.currentCustomer"
            [homeTypes]="this.homeTypes" (preApprovalChange)="handlePreApprovalInfoChange($event,cdkStepper)"
            #customerPreApprovalFormComponent>
        </app-customer-pre-approval-form>
    </cdk-step>
    <cdk-step label="Planes Pre Aprobados" [stepControl]="preApprovalForm" [optional]="false">
        <app-customer-credit-plans [currentCustomer]="this.currentCustomer"
            [currentCustomerCreditStatus]="this.currentCustomerCreditConditions" [creditPlans]="plans"
            #customerCreditPlansComponent>
        </app-customer-credit-plans>
    </cdk-step>
</pos-stepper>