import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalDialogType } from 'src/app/@core/constants';
import { PosConfirmationService } from 'src/app/@core/services/pos-confirmation.service';
import { ColumnTable } from 'src/app/models/column-table';
import { Order } from 'src/app/models/order';
import { Paging } from 'src/app/models/page';
import { Payment } from 'src/app/models/payment';
import { PaymentMethod } from 'src/app/models/payment-method';
import { PaymentMethodType } from 'src/app/models/payment-method-type';
import { ProductCart } from 'src/app/models/product-cart';
import { PaymentsRequestModel } from 'src/app/models/request/PaymentRequestModel';
import { PaymentTransactionRequestModel } from 'src/app/models/request/PaymentTransactionRequestModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-pay-credit-invoice-step',
  templateUrl: './pay-credit-invoice-step.component.html',
  styleUrls: ['./pay-credit-invoice-step.component.sass']
})
export class PayCreditInvoiceStepComponent implements OnInit, OnChanges {

  constructor(
    private confirmService: PosConfirmationService,
    private paymentMethodService: PaymentService,
    private invoiceService: InvoiceService,
    private toasterService: ToastrService,
    private router: Router,
    private configurationService:ConfigurationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadProductsCart();
    this.loadPayments();
  }

  async ngOnInit(): Promise<void> {
    this.paymentMethods = (await this.paymentMethodService.getPaymentMethods(this.configurationService.readSelectedBranchAndSalesOrg().salesOrg.code).toPromise()).response.content;
    // this.loadProductsCart();
  }


  paymentMethods: Array<PaymentMethodType> = [];
  productsCart = new Paging<Array<ProductCart>>([]);

  @Input()
  paymentRequest: Array<PaymentTransactionRequestModel> = [];

  @Input()
  payments = new Paging<Array<{ name: string, amount: number, identifier: string }>>([]);

  @Input()
  order: Order = new Order();

  getProductCartHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable("amount", "#", { type: "number", iconClass: "fa fa-shopping-cart", isIcon: true }),
      new ColumnTable("productCode", "Código", { isSmall: true }),
      new ColumnTable("description", "Descripción", { isSmall: true }),
      new ColumnTable("serial", "Serie", { type: "badge" }),
      new ColumnTable("price", "Sub-Total", { type: "currency" }),
      new ColumnTable("discountTotal", "Descuento", { type: "currency" }),
      new ColumnTable("taxTotal", "Impuesto", { type: "currency" }),
      new ColumnTable("total", "Total", { type: "currency" })
    ]
  }

  getPaymentHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable('name', 'Tipo Pago'),
      new ColumnTable('amount', 'Total', { type: 'currency' }),
      //new ColumnTable('applied', 'Aplicado', { type: 'currency' }),
      new ColumnTable('identifier', 'Identificador')
    ];
  }
  loadProductsCart() {
    this.productsCart = new Paging(this.order.orderEntryList.map(o => {
      let result = new ProductCart();
      result.productCode = o.productCode;
      result.description = o.productName;
      result.amount = o.totalQuantity
      result.serial = o.serialNumber;
      result.price = o.totals.grossTotal;
      result.discountTotal = o.totals.discountTotal;
      result.taxTotal = o.totals.taxTotal;
      result.total = o.totals.netTotal;

      return result;
    }))
  }

  async pay() {
    await this.confirmService.show("Pagar", "Está seguro de confirmar los pagos?", ModalDialogType.SUCCESS, async () => {
      var request = Array.from(new Set(this.paymentRequest));
      var response = await this.invoiceService.proccessTransaction(request).toPromise();
      if (response) {
        this.toasterService.success("Factura creada con éxito", "Facturación");
        this.router.navigate(["backOfficeOrders", this.order.code], {
          state: {
            order: this.order,
            print: true
          }
        });
      };
    });
  }

  loadPayments() {
    this.payments = new Paging(this.paymentRequest.flatMap(m => [...m.paymentsRequestModel.payments, m.chargeNotePaymentsRequestModel.payments]).flatMap(m => m).map(x => {
      var method = this.getPaymentMethod(x.paymentMethodCode).name;
      return {
        amount: x.amount,
        name: `${x.amount < 0 ? 'Cambio ' : ''}${method}`,
        identifier: x.receiptNumber
      }
    }));


  }
  getPaymentMethod(paymentMethodCode: string): PaymentMethodType {
    return this.paymentMethods.find(f => f.code == paymentMethodCode) || new PaymentMethodType();
  }
}
