import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptDetailsStepComponent } from './receipt-details-step.component';
import { PosTotalsModule } from 'src/app/shared/pos-totals/pos-totals.module';
import { PosTableModule } from 'src/app/shared/pos-table/pos-table.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PosPaymentMethodModule } from 'src/app/shared/pos-payment-method/pos-payment-method.module';



@NgModule({
  declarations: [ReceiptDetailsStepComponent],
  imports: [
    CommonModule,
    PosTotalsModule,
    PosTableModule,
    AccordionModule,
    PosPaymentMethodModule
  ],
  exports: [ReceiptDetailsStepComponent]
})
export class ReceiptDetailsStepModule { }
