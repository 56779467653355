import { Role, UserStatus } from "../@core/constants"

export class User {
    username: string
    fullname: string
    branchCodes: Set<string>;
    roles: Set<Role>;
    status: UserStatus;

    constructor() {
        this.username = "";
        this.fullname = "";
        this.branchCodes = new Set<string>();
        this.roles = new Set<Role>();
        this.status = UserStatus.INACTIVE;
    }
}