import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../models/response-model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) { }
  private url = `${environment.url}/transaction`;

  processTransaction(code: string): Observable<ResponseModel<any>> {
    return this.http.post<ResponseModel<any>>(`${this.url}/process/${code}`, {});
  }
}
