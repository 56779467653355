import { OrderAttachmentType } from "../@core/constants";
import { Order } from "./order";

export class OrderAttachment {
    type: OrderAttachmentType;
    data: string;
    filename: string;
    order: Order;
    constructor() {
        this.type = OrderAttachmentType.FISCALID;
        this.data = "";
        this.filename = "";
        this.order = new Order();
    }
}