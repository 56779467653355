export class PreApproveCustomerParams {
  customerCode: string;
  fiscalId: string;
  fiscalIdType: string;
  branchCode: string;
  limitRule: string;
  salesOrg: string;
  sellerCode: string;
  menu: string;
  amountToInvoice: number;
  downPayment: number;
  onbaseProcedureCode:string;

  constructor() {
    this.customerCode = '';
    this.fiscalId = '';
    this.fiscalIdType = '';
    this.branchCode = '';
    this.limitRule = '';
    this.salesOrg = '';
    this.sellerCode = '';
    this.menu = '';
    this.amountToInvoice = 0;
    this.downPayment = 0;
    this.onbaseProcedureCode = '';
  }
}
