<div class="animated fadeIn rounded">
  <div class="card">
    <div class="card-header">
      <i class="cil-object-group"></i> Aprobación de orden
      <div class="card-header-actions btn-group" dropdown placement="bottom left">
        <button class="btn btn-sm btn-success" type="button" (click)="printReports()"><i
            class="fa fa-file-o"></i>&nbsp;Imprimir Documentos
        </button>
        <button class="btn btn-sm btn-primary" type="button" appPrivilege="invoiceObservations"
          (appPrivilegeClick)="showObservations()"><i class="fa fa-edit"></i>&nbsp;Observaciones
        </button>
      </div>
    </div>
    <div class="card-body">

      <accordion [isAnimated]="true">
        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-check'></i> Aprobación de orden: {{orderApprovalInfo.order.code}}
          </span>
          <div class="form-group row" *ngIf="showPaymentResponsible()">
            <label class="col-md-2 col-form-label pr-0" for="region"><b>Responsable del pago: </b></label>
            <div class="col-md-10">
              <input type="text" disabled [ngModel]="franchised.name" class="form-control">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label pr-0"><b>Entrega a domicilio:</b></label>
            <div class="col-md-10">
              <ui-switch size="small" name="isDelivery" [disabled]="orderApprovalInfo.order.status == invoiced"
                [(ngModel)]="isDelivery"></ui-switch>
            </div>
          </div>

          <stock-selection [stockRequest]="stockRequestModel" [branch]="branch" [disabled]="!isDelivery"
            (selectedBranchEmitter)="selectedDeliveryBranch($event)"></stock-selection>

          <div class="form-group row">
            <div class="col-md-4">
              <button type="button" (click)="approve()" [disabled]="validateApproval()"
                class="btn btn-success mr-2">Aprobar</button>
              <button type="button" (click)="reject()" [disabled]="validateApproval()"
                class="btn btn-danger">Rechazar</button>
            </div>
          </div>
        </accordion-group>

        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-users'></i> Información General
          </span>
          <div class="form-group row">
            <label class="col-md-3 col-form-label"><b>Cliente:</b></label>
            <div class="col-md-9">
              <input type="text" id="costumerName" name="text-input" class="form-control" disabled
                [(ngModel)]="orderApprovalInfo.order.customerName">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input"><b>Vendedor:</b></label>
            <div class="col-md-9">
              <input type="text" id="vendorName" name="text-input" class="form-control"
                [(ngModel)]="orderApprovalInfo.order.vendorName" disabled>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input"><b>Documento Fiscal:</b></label>
            <div class="col-md-9">
              <input type="text" id="fiscalDocument" name="text-input" class="form-control"
                [(ngModel)]="orderApprovalInfo.order.customerFiscalId" disabled>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input"><b>Estado:</b></label>
            <div class="col-md-9 text-left">
              <span
                [ngClass]="'badge large-font-size '+getOrderStatusClass(orderApprovalInfo.order.status)">{{getOrderStatusText(orderApprovalInfo.order.status)}}</span>
            </div>

          </div>
          <div class="form-group row" [hidden]="orderApprovalInfo.order.status != 'REJECTED'">
            <label class="col-md-3 col-form-label" for="text-input"><b>Razón de Rechazo</b></label>
            <div class="col-md-9 text-left">
              <h4>{{orderApprovalInfo.order.rejectedReason}}</h4>
            </div>

          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input"><b>Tipo de Orden:</b></label>
            <div class="col-md-9 text-left">
              <span
                [ngClass]="'badge large-font-size '+getOrderTypeClass(orderApprovalInfo.order.orderType)">{{getOrderTypeText(orderApprovalInfo.order.orderType)}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-3 col-form-label" for="text-input"><b>Condición:</b></label>
            <div class="col-md-9">
              <span
                class="badge large-font-size badge-success">{{getConditionText(orderApprovalInfo.order.orderCondition!)}}</span>
            </div>
          </div>
          <div class="form-group row" *ngFor="let loanItem of orderApprovalInfo.loans">
            <label class="col-md-3 col-form-label" for="text-input"><b>Número de Contrato:</b></label>
            <div class="col-md-9 mt-1">
              <h5>{{loanItem.contractNumber}}</h5>
            </div>
          </div>
          <div class="form-group row" *ngIf="showNeedDigitalSignature(orderApprovalInfo.order.status)">
            <label class="col-md-3 col-form-label" for="text-input"><b>Necesita firma digital:</b></label>
            <div class="col-md-9">
              <span
                class="badge large-font-size badge-success">{{getDigitalSignatureBadge(orderApprovalInfo.order.digitalSignature)}}</span>
            </div>
          </div>
        </accordion-group>

        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-file-text'></i> Transacciones:
          </span>
          <pos-table tableFormId="transactionsTable" [disableHighlight]="true" [data]="transactions"
            [headers]="getTransactionsHeaders()" [filters]="[]" [itemsPerPage]="10" [pagination]="false"
            [badgeSuccessValues]="['FINISHED']" [badgeWarningValues]="['NEW']" [badgeDangerValues]="['LOANS',
          'DOWNPAYMENT_RECEIPT',
          'DOCUMENT_ATTACHMENTS','INVOICES',
                                    'NOTE_CHARGE',
                                    'NOTE_CHARGE_PAYMENT',
                                    'PAYMENTS',
                                    'NO_STATUS']">
          </pos-table>
        </accordion-group>

        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-file-text'></i> Facturas:
          </span>
          <pos-table tableFormId="invoiceTable" [disableHighlight]="true" [data]="invoices"
            [headers]="getInvoicesHeaders()" [filters]="[]" [itemsPerPage]="10" [pagination]="false">
          </pos-table>
        </accordion-group>

        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='fa fa-file-text'></i> Recibos:
          </span>
          <pos-table tableFormId="receiptTable" [disableHighlight]="true" [data]="receipts"
            [headers]="getReceiptsHeaders()" [filters]="[]" [itemsPerPage]="10" [pagination]="false">
          </pos-table>
        </accordion-group>

        <accordion-group [isOpen]="true">
          <span accordion-heading>
            <i class='cil-cart'></i> Orden
          </span>
          <pos-totals [totals]="orderApprovalInfo.order.totals"></pos-totals>
          <div class="row">
            <div class="col-12">
              <pos-table tableFormId="productsCartTable" [disableHighlight]="true" [data]="orderEntryList"
                [headers]="getProductCartHeaders()" [filters]="[]" [itemsPerPage]="10" [pagination]="false">
              </pos-table>
            </div>
          </div>
        </accordion-group>
        <div *ngIf="orderApprovalInfo.order.orderCondition !== 4">
          <accordion-group [isOpen]="true" *ngFor="let loan of loans">
            <span accordion-heading>
              <i class='fa fa-credit-card'></i> Condiciones de crédito
            </span>
            <div class="row">
              <div class="col-6 text-left">
                <h6>Fecha primera Cuota:</h6>
              </div>
              <div class="col-6 text-left">
                {{dateUtils.getDateFromStringWithoutTime(loan.parameters.startDate) | date: 'longDate'}}
              </div>

            </div>
            <div class="row">
              <div class="col-6 text-left">
                <h6>Plazo:</h6>
              </div>
              <div class="col-6 text-left">
                {{loan.parameters.term}}
              </div>

            </div>
            <div class="row">
              <div class="col-6 text-left">
                <h6>Prima %:</h6>
              </div>
              <div class="col-6 text-left">
                {{loan.parameters.downPaymentPercentage | number: '1.2-2'}}%
              </div>

            </div>
            <div class="row">
              <div class="col-6 text-left">
                <h6>Prima:</h6>
              </div>
              <div class="col-6 text-left">
                {{loan.parameters.downPayment | currency: 'HNL ':'symbol' : '1.2-2'}}
              </div>

            </div>
            <div class="row">
              <div class="col-6 text-left">
                <h6>Cuota:</h6>
              </div>
              <div class="col-6 text-left">
                {{getFeeWithInterest(loan) | currency: 'HNL ':'symbol' : '1.2-2'}}
              </div>

            </div>
          </accordion-group>
        </div>
      </accordion>

      <pos-payment-details [payments]="payments"></pos-payment-details>

    </div>
  </div>
</div>