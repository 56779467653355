import { OriginLoan } from '../@core/constants';
import { InterestFee } from './interest-fee';
import { LoanCondition } from './loan-condition';
import { LoanIntermediary } from './loan-intermediary';
import { LoanReceiptInvoiceDetail } from './loan-receipt-invoice-detail';
import { OrderEntry } from './order-entry';
import { Paging } from './page';
import { LoanSimulationRequest } from './requests/loan-simulation-request';

export class Loan {
  code: string;
  contractNumber: string;
  salesOrg: string;
  status: string;
  productClass: string;
  customerCode: string;
  loanClass: string;
  amount: number;
  currency: string;
  term: string;
  authorizationDate: string;
  region: string;
  branchCode: string;
  promotion: string;
  invoices: Array<LoanReceiptInvoiceDetail>;
  sellerCode: string;
  sellerName: string;
  totalFinanced: number;
  validDate: string;
  amortizationClass: string;
  periodStartingDate: string;
  periodEndDate: string;
  created: Boolean;
  creationDate: string;
  returningDate: string;
  agreementEndDate: string;
  conditions: Array<LoanCondition>;
  intermediaries: Array<LoanIntermediary>;
  isInsured: boolean;
  arrearPercentage: string;
  totalInterests: string;
  signedInCity: string;
  signedInDepartment: string;
  rate: string;
  orderCode: string;
  parameters: LoanSimulationRequest;
  lastPaymentDate: string;
  creditBalance: number;
  contractCanceled: string;
  disputeCase: string;
  interestFee: Array<InterestFee>;
  quotaPaidMT: string;
  originLoan: OriginLoan;

  //Custom
  delayedAmount: number;

  // for credit logic

  products: Array<OrderEntry> = [];

  constructor() {
    this.code = '';
    this.contractNumber = '';
    this.salesOrg = '';
    this.status = '';
    this.productClass = '';
    this.customerCode = '';
    this.loanClass = '';
    this.amount = 0.0;
    this.currency = '';
    this.term = '';
    this.authorizationDate = '';
    this.region = '';
    this.branchCode = '';
    this.promotion = '';
    this.invoices = [];
    this.sellerCode = '';
    this.sellerName = '';
    this.totalFinanced = 0.0;
    this.validDate = '';
    this.amortizationClass = '';
    this.periodStartingDate = '';
    this.periodEndDate = '';
    this.created = false;
    this.creationDate = '';
    this.returningDate = '';
    this.agreementEndDate = '';
    this.conditions = [];
    this.intermediaries = [];
    this.isInsured = false;
    this.arrearPercentage = '';
    this.totalInterests = '';
    this.signedInCity = '';
    this.signedInDepartment = '';
    this.rate = '';
    this.orderCode = '';
    this.parameters = new LoanSimulationRequest();
    this.lastPaymentDate = '';
    this.creditBalance = 0.0;
    this.contractCanceled = '';
    this.disputeCase = '';
    this.interestFee = [];
    this.quotaPaidMT = '';
    this.originLoan = OriginLoan.POS;
    this.creationDate = '';
    this.periodStartingDate = '';
    this.delayedAmount = 0;
  }
}
